import {
  DatePicker,
  DefaultButton,
  Dropdown,
  Label,
  Stack,
  Text,
} from "@fluentui/react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { strings } from "../../../config/datePickerConfig";
import useExportCaisseDesDepots from "../../../hooks/useExportCaisseDesDepots";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import validations from "../../../utils/validations";
import Loader from "../../../Common/Loader/Loader";
import usePorteurQuery from "../../../hooks/usePorteurQuery";

function ExportCaisseDesDepots() {
  const [errors, setErrors]: any = useState({});
  const [datasExport, setDatasExport] = useState({
    porteur: undefined,
    date_debut: DateTime.fromJSDate(new Date("2021-07-01")).toUTC().toISO(),
    date_fin: DateTime.fromJSDate(new Date(Date.now())).toUTC().toISO(),
  });
  const { isLoadingPorteurs, isRefetchingPorteurs, porteursList } =
    usePorteurQuery();
  const {
    actions,
    isLoadingExportCaisseDesDepots,
    dateLastExport,
    generationStarted,
  } = useExportCaisseDesDepots();
  const { actions: actionsModal } = useCustomModal();
  const handleChangeDatasExport = (e: any) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setDatasExport({ ...datasExport, [name]: value });
  };

  const validateDatasExport = (dates: any) => {
    let validationErrors: any = {};
    const validationExport = validations.validateDatasExportForm(dates);
    if (validationExport?.error) {
      validationExport.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message;
      });
    }
    setErrors(validationErrors);
    let isValid = Object.keys(validationErrors).length === 0;
    return isValid;
  };

  const onExportCaisseDesDepot = () => {
    if (validateDatasExport(datasExport)) {
      actions.onExportCaisseDesDepot(datasExport);
    } else {
      toast.error("Le formulaire comporte des erreurs.");
    }
  };

  useEffect(() => {
    actions.onGetInformationLastUpdate();
  }, []);

  if (generationStarted) {
    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <p>
          L'exportation de vos données est en cours et prendra du temps. Vous
          obtiendrez le résultat de l'exportation par email une fois que
          celle-ci sera terminée.
        </p>
      </Stack>
    );
  }

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Text style={{ color: "#6f6f6f", fontSize: 13 }}>
        Veuillez sélectionner le porteur des certifications souhaité et les
        dates limites pour exporter votre fichier au format attendu par la
        Caisse des Dépôts. Les résultats comprendront les candidats certifiés
        sur les certifications du porteur choisi aux dates des jurys comprises
        entre les bornes sélectionnées.
        <br />
        <span
          style={{
            color: "rgb(224, 5, 0)",
          }}
        >
          <strong>Attention : </strong>
          Veuillez bien vérifier que vos certifications inscrites au RNCP ou au
          RS comportent les mentions exactes sur les porteurs (numéros de
          contrat et de fiche cliente)
        </span>
      </Text>

      {isLoadingPorteurs || isRefetchingPorteurs ? (
        <Loader />
      ) : (
        <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
          <Stack.Item>
            <Dropdown
              required
              selectedKey={
                datasExport.porteur
                  ? (datasExport.porteur as any).key
                  : undefined
              }
              label="Nom du porteur de la certification :"
              placeholder="Sélectionnez un porteur"
              options={porteursList}
              onChange={(e: any, options: any) => {
                if (options) {
                  handleChangeDatasExport({
                    target: {
                      name: "porteur",
                      value: options.key,
                    },
                  });
                }
              }}
              styles={commonInputStyles.dropdownStyle}
            />
          </Stack.Item>
          <Stack.Item>
            <Label
              htmlFor="date_debut"
              required
              styles={commonInputStyles.label}
            >
              Exporter les candidats certifiés à partir de :
            </Label>
            <DatePicker
              formatDate={(date?: any): any =>
                date ? `${tools.reformateDateFromJsDate(date)}` : null
              }
              strings={strings}
              showGoToToday={false}
              minDate={new Date("2021-07-01")}
              maxDate={new Date()}
              value={
                datasExport.date_debut
                  ? new Date(datasExport.date_debut)
                  : undefined
              }
              styles={commonInputStyles.dataPicker}
              onSelectDate={(date: any) => {
                if (date) {
                  handleChangeDatasExport({
                    target: {
                      name: "date_debut",
                      value: DateTime.fromJSDate(date).toUTC().toISO(),
                    },
                  });
                }
              }}
              placeholder="dd/mm/yyyy"
              allowTextInput
              parseDateFromString={(dateStr: any) =>
                tools.parseDateFromStringDatePicker(dateStr)
              }
            />
            <ErrorMessage errorMessage={errors?.date_debut} />
          </Stack.Item>

          <Stack.Item>
            <Label htmlFor="date_fin" required styles={commonInputStyles.label}>
              Jusqu'à :
            </Label>
            <DatePicker
              formatDate={(date?: any): any =>
                date ? `${tools.reformateDateFromJsDate(date)}` : null
              }
              strings={strings}
              minDate={new Date("2021-07-01")}
              maxDate={new Date()}
              showGoToToday={false}
              value={
                datasExport.date_fin
                  ? new Date(datasExport.date_fin)
                  : undefined
              }
              styles={commonInputStyles.dataPicker}
              onSelectDate={(date: any) => {
                if (date) {
                  handleChangeDatasExport({
                    target: {
                      name: "date_fin",
                      value: DateTime.fromJSDate(date).toUTC().toISO(),
                    },
                  });
                }
              }}
              placeholder="dd/mm/yyyy"
              allowTextInput
              parseDateFromString={(dateStr: any) =>
                tools.parseDateFromStringDatePicker(dateStr)
              }
            />
            <ErrorMessage errorMessage={errors?.date_fin} />
          </Stack.Item>

          {dateLastExport === false ? (
            <Text>
              Aucun export pourt la Caisse des Dépôts a été effectué sur cette
              branche.
            </Text>
          ) : (
            <Text>
              Date du dernier export pour la Caisse des Dépôts :{" "}
              {dateLastExport}
            </Text>
          )}

          <Stack.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <DefaultButton
              styles={commonButtonStyles.defaultButton}
              onClick={actionsModal.closeModal}
            >
              Annuler
            </DefaultButton>
            <LoadButton
              text={"Exporter les candidatures"}
              type="button"
              onClick={onExportCaisseDesDepot}
              isLoading={isLoadingExportCaisseDesDepots}
            />
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
}

export default ExportCaisseDesDepots;
