import { Stack, Text, TextField } from "@fluentui/react";
import { useState } from "react";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles, commonInputStyles } from "../../../styles";

function RefuseDemandeHabilitationModal({ demandes, onRefuseDemande }: any) {
  const [commentaire, setCommentaire] = useState("");
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickRefuseButton = () => {
    onRefuseDemande({
      demandes: demandes.map((demande: any) => demande.uuid),
      commentaire,
    });
    closeModal();
  };

  return (
    <Stack>
      <Text style={{ color: "#979797" }}>
        Veuillez renseigner un commentaire pour la(les) demande(s)
        d'habilitation sélectionnée(s) justifiant le refus.
      </Text>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          label="Commentaire :"
          placeholder="Ex : commentaire"
          multiline
          required
          rows={6}
          maxLength={3000}
          value={commentaire}
          onChange={(e) => setCommentaire((e.target as any).value)}
        />
      </Stack.Item>

      <LoadButton
        //isLoading={isLoadingRefuseDemandeHabilitations}
        text="Refuser"
        onClick={onClickRefuseButton}
        style={{ margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </Stack>
  );
}

export default RefuseDemandeHabilitationModal;
