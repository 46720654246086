import { Panel,PanelType } from "@fluentui/react";
import useCustomPanel from "./useCustomPanel"

function CustomPanel(
) {

    const { isOpenPanel, contentPanel, actions } = useCustomPanel();

    return (
       <Panel 
            isLightDismiss
            isOpen={isOpenPanel}
            onDismiss={actions.closeModal}
            closeButtonAriaLabel="Close"
            headerText={contentPanel.header}
            type={PanelType.large}
            //isFooterAtBottom={true}
            //onRenderFooterContent={contentPanel.footer}
       >
            <div>
                {contentPanel.body}
            </div>
       </Panel>
    )
}

export default CustomPanel