import { IMessageBarStyles } from "@fluentui/react";

const MessageBarStyles: IMessageBarStyles = {
  root: {
    background: "transparent",
    color: "grey",
    fontStyle: "italic",
  },
  text: {
    fontSize: 16,
  },
};

export const ImportantMessageBarStyles: IMessageBarStyles = {
  root: {
    background: "transparent",
    color: "red",
    fontStyle: "italic",
  },
  text: {
    fontSize: 16,
  },
};

export const MessageBarErrorStyles: IMessageBarStyles = {
  root: {
    background: "red",
    color: "white",
    fontStyle: "italic",
  },
  text: {
    fontSize: 18,
    fontWeight: "bold",
  },
  icon: {
    color: "white",
    fontWeight: "bold",
  },
};
export default MessageBarStyles;
