import { useState } from "react";

function useCritereDynamicList({
  attr,
  value,
  defaultValue,
  name,
  validateItem = () => {},
  onChange = () => {},
}: any) {
  const [terms, setTerms] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [isRemovingCritere, setIsRemovingCritere] = useState(false);

  const actions = {
    handleChangeTerms: (e: any) => {
      setTerms(e.target.value);
    },
    onKeyDown: (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        actions.onAddTerm();
      }
    },
    onAddTerm: () => {
      if (!terms) return false;
      const newItem = { [attr]: terms };
      const validationResult = validateItem(newItem, value);

      if (validationResult.error) {
        setErrors(validationResult.error);
      } else {
        const addedValue = [...value, { ...defaultValue, ...newItem }];
        onChange({ target: { name, value: addedValue } });
        setTerms("");
        setErrors({});
      }
    },
    onUpdateStatutItem: (itemIndex: number, checked: boolean) => {
      onChange({
        target: {
          name,
          value: [
            ...value.map((v: any, vk: number) =>
              vk !== itemIndex ? v : { ...v, actif: checked }
            ),
          ],
        },
      });
    },
    onRemoveItem: (itemIndex: number) => {
      setIsRemovingCritere(true);
      onChange({
        target: {
          name,
          value: [...value.filter((v: any, vk: number) => vk !== itemIndex)],
        },
      });
    },
  };

  const items = value.map((critere: any) => ({
    ...critere,
    actif: critere.actif ?? true,
  }));

  return { terms, errors, isRemovingCritere, actions, items };
}

export default useCritereDynamicList;
