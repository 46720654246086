import { Stack } from '@fluentui/react'
import { useEffect } from 'react';
import Loader from '../../Common/Loader/Loader'
import BackLink from '../../Common/BackLink/BackLink';
import useGeneralPagePrivate from './useGeneralPagePrivate';
import Page from "../../Common/Page/Page";

function GeneralPagePrivate(type: any) {

    const { page, isStaticPage, initializeStaticPage } = useGeneralPagePrivate();

    useEffect(() => {
        const initPage = async () => {
            await initializeStaticPage(type)
        };
        initPage()
    }, [])

    return (
        <Stack>

            <BackLink/>

            {isStaticPage
                ?
                <Page
                    title={(page as any).titre}
                    explain=''
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `${(page as any).contenu}`,
                        }}
                    />

                </Page>
                :
                <Stack horizontal horizontalAlign='center' verticalAlign='center'>
                    <Loader />
                </Stack>
            }
        </Stack>

    )
}

export default GeneralPagePrivate