import { useForm } from "react-hook-form";
import useDomaine from "../../hooks/domaine/useDomaine"
import useDomaineQuery from "../../hooks/domaine/useDomaineQuery";

function useDomaineDetail() {
    const domaineProps = useDomaine();
    const { domaine, isLoading, isRefetching } = useDomaineQuery(domaineProps);

    const reactHookProps = useForm(
        {
            mode: 'onBlur',
            reValidateMode: 'onSubmit'
        }
    )

    const actions = {
        saveDomaine: (data: any) => {
            if (!(data as any).uuid && !domaine?.id) {
                domaineProps.actions.postDomaine(data)
            } else {
                domaineProps.actions.putDomaine(data)
            }
        }
    }

    return {
        domaine,
        domaineProps,
        reactHookProps,
        actions,
        isLoadingDomaine: isLoading || isRefetching
    }
}

export default useDomaineDetail