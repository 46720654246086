import {
  DefaultButton,
  DefaultSpacing,
  Image,
  ImageFit,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect } from "react";
import HeaderPublic from "../../Common/Header/HeaderPublic";
import useAuth from "../../hooks/useAuth";
import useNav, { isOfflineAtom } from "../../hooks/useNav";
import tools from "../../utils/tools";
import useHome from "./useHome";
import { useAtom } from "jotai";
import Loader from "../../Common/Loader/Loader";

function SelectBranch() {
  const { auth, actions: authActions, isLoadingLogin }: any = useAuth();
  const { branches: allBranches } = useHome();
  const branches = auth?.user?.mes_branches?.sort((a: any, b: any) =>
    a.nom.localeCompare(b.nom)
  );
  const { actions } = useNav();
  const [isOffline] = useAtom(isOfflineAtom);

  useEffect(() => {
    if (branches?.length == 1) {
      onSelectBranche(branches[0]);
      //setUserSelectedBranch(data.mes_branches[0]);
    }
  }, [branches]);

  const onSelectBranche = (branche: any) => {
    const brancheRef = allBranches?.data.find((e: any) => e.id == branche.id);
    actions.changeBranch({ ...branche, ...brancheRef });
  };

  // if (userSelectedBranch) {
  //   if (location.pathname === "/") {
  //     const homePage = auth.user.est_administrateur
  //       ? homePageByProfile["ADMINISTRATEUR_BRANCHE"]
  //       : homePageByProfile[
  //           (tools.findIn(auth.user.mes_branches, userSelectedBranch.id, "id") as any)
  //             ?.profils[0] as any
  //         ];
  //     console.log("homePage", homePage);
  //     return <Navigate to={homePage} />;
  //   } else {
  //     console.log("location.pathname", location.pathname);
  //   }
  // }

  if (isLoadingLogin) return <Loader />;

  const uniqueBranches = tools.getUnique(branches, "id");
  return (
    <>
      <HeaderPublic />
      <div style={{ marginTop: DefaultSpacing.l2, minHeight: "90vh" }}>
        <Stack horizontalAlign="center">
          {uniqueBranches?.length == 0 && (
            <>
              <Text
                variant="medium"
                className="ms-fontWeight-bold"
                style={{ textAlign: "center", marginBottom: 15 }}
              >
                Votre profil utilisateur n'est rattaché à aucune branche active.
                <br />
                Veuillez contacter un administrateur.
              </Text>
              <DefaultButton onClick={authActions.logout}>
                Revenir à l'écran de connexion
              </DefaultButton>
            </>
          )}
          {uniqueBranches?.length > 0 && (
            <>
              <Text
                variant="medium"
                className="ms-fontWeight-bold"
                style={{ textAlign: "center" }}
              >
                Veuillez sélectionner la branche sur laquelle vous voulez gérer
                des candidatures :
              </Text>

              <ul
                style={{ marginTop: DefaultSpacing.l2, paddingLeft: 0 }}
                className="contentUseAllWidthSm"
              >
                {uniqueBranches
                  .filter((e: any) => {
                    if (isOffline) {
                      if (
                        localStorage.getItem("selectedBranchId") &&
                        localStorage.getItem("selectedBranchId") == e.id
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((e: any) => {
                    let brancheRef: any = tools.findIn(
                      allBranches.data,
                      e.id,
                      "id"
                    );
                    return (
                      e.active && (
                        <li
                          key={e.id}
                          style={{
                            width: "100%",
                            listStyle: "none",
                            marginBottom: 10,
                          }}
                        >
                          <Stack
                            horizontal
                            verticalAlign="center"
                            horizontalAlign="space-between"
                          >
                            <Stack
                              styles={{
                                root: { marginRight: DefaultSpacing.m },
                              }}
                            >
                              <Image
                                style={{ width: 40, height: 40 }}
                                src={brancheRef.logo_url}
                                maximizeFrame
                                imageFit={ImageFit.contain}
                                alt={e.nom}
                              />
                            </Stack>
                            <Stack
                              horizontal
                              verticalAlign="center"
                              horizontalAlign="space-between"
                              styles={{
                                root: {
                                  width: "100%",
                                  borderBottom: "2px solid #e5e5e5",
                                  paddingBottom: 5,
                                },
                              }}
                            >
                              <Text className="ms-fontWeight-bold">
                                {e.nom}
                              </Text>
                              <PrimaryButton
                                style={{
                                  width: 157,
                                  minWidth: 157,
                                  padding: "0 6px",
                                }}
                                text="Choisir cette branche"
                                onClick={() => onSelectBranche(e)}
                              />
                            </Stack>
                          </Stack>
                        </li>
                      )
                    );
                  })}
              </ul>
              <DefaultButton onClick={authActions.logout}>
                Se déconnecter
              </DefaultButton>
            </>
          )}
        </Stack>
      </div>
    </>
  );
}

export default SelectBranch;
