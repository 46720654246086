import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import { GroupOfContext } from "../useGroupOfDetail";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";

function EntitiesLinkedSection() {
  const { reactHookProps, errors }: any = useContext(GroupOfContext);
  const { setValue, control } = reactHookProps;

  const of_autres_branches = reactHookProps.watch("ofs_hors_branche");

  return (
    <FormSection sectionTitle="OF liés au groupe d’OF">
      <InfoMessage
        infoMessage={`La suppression d'un numéro de SIRET de cette liste détachera ce SIRET du numéro de SIREN pour toutes les branches de Certifi@ où ce SIRET existe. De même, l'ajout d'un numéro de SIRET à cette liste rattachera ce SIRET au numéro de SIREN pour toutes les branches de Certifi@ où ce SIRET existe.`}
      />

      <Controller
        render={({ field: { name, value, onBlur, ref } }) => (
          <SelectManyFieldAsync
            label="Sélectionnez les OF liés à ce groupe :"
            placeholder="Recherchez un OF par son nom ou son SIRET"
            resourceURI={`${API_URL}api/administration/ofs/chercher-disponibles`}
            addQueryURL={(resourceURI: any, terms: any) =>
              `${resourceURI}?recherche=${terms}`
            }
            method="GET"
            renderItem={(item: any) => item.raison_sociale + " - " + item.siret}
            renderValue={(item: any) =>
              item.raison_sociale + " - " + item.siret
            }
            dataIndex="uuid"
            name={name}
            value={value}
            onBlur={onBlur}
            fieldRef={ref}
            onChange={(e: any) => {
              setValue(name, e.target.value, {
                shouldValidate: true,
              });
            }}
            defaultValue={{}}
            errorMessage={(errors.ref_idccs as any)?.message}
            handleResponse={(response: any) => [...response.data]}
            disabledSelectedItem={(item: any) => item.groupe_of}
            infoMessageDisabledItem={(item: any) =>
              `La structure ${item.raison_sociale} fait déjà partie d'un autre groupe. Veuillez d'abord la retirer du groupe avant de l'ajouter à un nouveau`
            }
          />
        )}
        control={control}
        name="ofs"
      />
      {of_autres_branches?.length > 0 && (
        <div>
          <p>Des OFs d'autres branches sont également liés à ce groupe :</p>
          <ul>
            {of_autres_branches.map((of: any) => (
              <li key={of.uuid}>
                {of.raison_sociale}{" "}
                <span className="text-muted">- {of.siret}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </FormSection>
  );
}

export default EntitiesLinkedSection;
