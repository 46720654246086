import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";

import SelectFieldAsync from "../SelectField/SelectFieldAsync/SelectFieldAsync";
import { commonInputStyles } from "../../styles/index";
import { API_URL } from "../../config/config";

function FormAdress({
  control,
  errors = {},
  varToWatch,
  required = false,
  disabled,
  moreDetail = true,
  onPrefillCityAndRegion = () => {},
}: any) {
  return (
    <>
      {moreDetail && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  required={required}
                  styles={commonInputStyles.textField}
                  label="Adresse :"
                  type="text"
                  {...field}
                  placeholder="Rue, adresse de boite postale, nom de société"
                  errorMessage={errors.adresse?.message}
                  disabled={disabled}
                />
              )}
              defaultValue=""
              control={control}
              name="adresse"
            />
          </Stack.Item>

          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Complément d'adresse :"
                  type="text"
                  {...field}
                  placeholder="Appartement, suite, l'unité, batîment, étage, etc"
                  errorMessage={errors.complement_adresse?.message}
                  disabled={disabled}
                />
              )}
              defaultValue=""
              control={control}
              name="complement_adresse"
            />
          </Stack.Item>
        </>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref, onChange } }) => (
            <SelectFieldAsync
              required={required}
              label="Code postal :"
              placeholder="Recherchez un code postal en tapant les premiers chiffres"
              resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?code_postal=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.code_postal + " | " + item.nom_commune
              }
              renderValue={(item: any) => item}
              dataIndex="uuid"
              name={name}
              value={value}
              fieldRef={ref}
              onChange={(e: any) => {
                onChange(e.target.value.code_postal || "");
                if (e.target.value.nom_region) {
                  onPrefillCityAndRegion(e.target.value);
                }
              }}
              defaultValue=""
              errorMessage={(errors.code_postal as any)?.message}
              handleResponse={(response: any, terms: any) => {
                return response.data.data.length > 0
                  ? response.data.data
                  : [{ code_postal: terms, nom_commune: "Inconnu" }];
              }}
              disabled={disabled}
              maxLength={5}
            />
          )}
          defaultValue=""
          control={control}
          name="code_postal"
        />
      </Stack.Item>

      {varToWatch && (
        <>
          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref, onChange } }) => (
                <SelectFieldAsync
                  required={required}
                  label="Région :"
                  placeholder="Veuillez sélectionner ou inscrire votre région"
                  resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?nom_region=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => item}
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  fieldRef={ref}
                  onChange={onChange}
                  defaultValue=""
                  fetchOnLoad={true}
                  errorMessage={(errors.region as any)?.message}
                  handleResponse={(response: any, terms: any) => {
                    const regionsSingle = Array.from(
                      new Set(response.data.data.map((x: any) => x.nom_region))
                    );

                    return regionsSingle.length > 0 ? regionsSingle : [terms];
                  }}
                  disabled={disabled}
                />
              )}
              defaultValue=""
              control={control}
              name="region"
            />
          </Stack.Item>

          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref, onChange } }) => (
                <SelectFieldAsync
                  required={required}
                  label="Ville :"
                  placeholder="Veuillez sélectionner ou inscrire votre ville"
                  resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?nom_commune=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => item}
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  fieldRef={ref}
                  onChange={onChange}
                  defaultValue=""
                  fetchOnLoad={true}
                  errorMessage={(errors.ville as any)?.message}
                  handleResponse={(response: any, terms: any) => {
                    const citySingle = Array.from(
                      new Set(response.data.data.map((x: any) => x.nom_commune))
                    );

                    return citySingle.length > 0 ? citySingle : [terms];
                  }}
                  disabled={disabled}
                />
              )}
              defaultValue=""
              control={control}
              name="ville"
            />
          </Stack.Item>
        </>
      )}
    </>
  );
}

export default FormAdress;
