import { atom, useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import useOfflineCandidaturePile from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import { isOfflineAtom } from "../../hooks/useNav";
import { useNavigate } from "react-router-dom";
import {CHANGE_IS_OFFLINE} from "../../config/offlineConfig";

const sortAtom = atom("");

function useOfflineCandidatureList() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [sort, setSort] = useAtom(sortAtom);
  const [, setIsOffline] = useAtom(isOfflineAtom)
  let navigate = useNavigate();

  const {
    actions: actionsPile,
    candidaturesToDownloadList,
  } = useOfflineCandidaturePile();

  const actions = {
    changeFilters: (newFilters: any) => {
      actionsPile.getAllCandidaturesToDownload(newFilters);
    },
    sortColumnBo: (name: string) => {
      const sortArray = sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);
      setSort(sortJoin);
      actionsPile.onUpdateCandidatureList(
        candidaturesToDownloadList.sort(
          tools.fieldSorterBo(sortJoin.split(","))
        )
      );
    },
    onNavigateToOfflineCandidatureSync:()=>{
      setIsOffline(true);
      CHANGE_IS_OFFLINE(true);
      navigate('/admin/offline/candidature-sync')
    }
  };

  return {
    userSelectedBranch,
    sort,
    actions,
  };
}

export default useOfflineCandidatureList;
