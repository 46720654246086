import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import { commonButtonStyles } from "../../../styles";
import useTheme from "../../../Common/useTheme";
import { useContext } from "react";
import { SubGroupCompanyContext } from "../useSubGroupCompanyDetail";
import LoadLink from "../../../Common/LoadLink/LoadLink";

function UsersInformations() {
  const { primaryColor } = useTheme();
  const { actions, reactHookProps }: any = useContext(SubGroupCompanyContext);

  const items = reactHookProps.watch("utilisateurs") || [];

  const columns = [
    {
      key: "1",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item.nom}</Text>,
    },
    {
      key: "2",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item.prenom}</Text>,
    },
    {
      key: "3",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.email}</Text>,
    },
    {
      key: "4",
      name: "Numéro de téléphone",
      fieldName: "telephone_portable",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.telephone_portable}</Text>,
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) =>
        item.isNew ? (
          <Stack>
            <LoadLink
              styles={commonButtonStyles.buttonLink}
              onClick={() => actions.onRemoveUser(item)}
              text={"Supprimer"}
              isLoading={false}
            />
          </Stack>
        ) : null,
    },
  ];

  return (
    <FormSection sectionTitle="Informations des utilisateurs">
      <Stack horizontal>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          style={{ color: primaryColor, borderColor: primaryColor }}
          text="Créer un nouvel utilisateur"
          onClick={() => actions.openUserFormModal()}
        />
      </Stack>

      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
      />
    </FormSection>
  );
}

export default UsersInformations;
