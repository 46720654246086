import { Stack } from '@fluentui/react'
import { useEffect } from 'react';
import Loader from '../../Common/Loader/Loader'
import BackLink from '../../Common/BackLink/BackLink';
import useGeneralPagePublic from './useGeneralPagePublic';
import Page from '../../Common/Page/Page';

function GeneralPagePublic(type:any) {

    const { page, isStaticPage, initializeStaticPage } = useGeneralPagePublic();

    useEffect(() => {
        const initPage = async () => {
            await initializeStaticPage(type)
        };
        initPage()
    }, [])

    return (
        <Stack
            horizontalAlign='center'
            style={{ backgroundColor: '#F3F6F9'}}
        >

            <Stack
                style={{maxWidth:'1024px', width:'100%'}}
            >
                 <BackLink  />

                 {isStaticPage
                ?
                <Page
                    title={(page as any).titre}
                    explain=''
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: `${(page as any).contenu}`,
                        }}
                    />

                </Page>
                :
                <Stack horizontal horizontalAlign='center' verticalAlign='center'>
                    <Loader />
                </Stack>
            }
            </Stack>
            
        </Stack>

    )
}

export default GeneralPagePublic