import { useAtom } from "jotai";
import { toast } from "react-toastify";
import certificationAdapters from "../../adapters/certificationAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import useCertificationMutations from "./useCertificationMutations";

function useCertification() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { certificationUuid, setCertificationUuid, mutations, isToggleActivationUuid, setIsToggleActivationUuid } = useCertificationMutations();

  const actions = {
    setUpdateCertificationUuid: (data: any) => {
      setCertificationUuid(data);
    },
    postCertification: async (certification: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData, 
        certificationAdapters.transformForAPI(certification),
        null
      )
      mutations.PostCertification.mutate(formData);
    },
    postCertificationOnDomaine: async (certification: any) => {
      mutations.PostCertificationOnDomaine.mutate(certificationAdapters.transformOnDomaineForAPI(certification));
    },
    putCertification: async (certification: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData, 
        certificationAdapters.transformForAPI(certification),
        null
      )
      mutations.PutCertification.mutate(formData);
    },
    deleteCertification: async (certificationUuid?: string) => {
      toast.info(`En cours de développement`);
      // mutations.DeleteCertification.mutate(certificationUuid);
    },
    toggleCertificationActivation: (certification: any) => {
      setIsToggleActivationUuid(certification.uuid)
      if (certification.active) {
        mutations.ToggleCertificationDesactivate.mutate(certification.uuid);
      } else {
        mutations.ToggleCertificationActivate.mutate(certification.uuid);
      }
    },
  };

  const {
    isLoading: isLoadingPostCertification,
    isError: isErrorPostCertification,
    error: errorPostCertification,
    isSuccess: isSuccessPostCertification,
  } = mutations.PostCertification;

  const {
    isLoading: isLoadingPostOnDomaineCertification,
  } = mutations.PostCertificationOnDomaine;

  const {
    isLoading: isLoadingPatchCertification,
    isError: isErrorPatchCertification,
    error: errorPatchCertification,
    isSuccess: isSuccessPatchCertification,
  } = mutations.PutCertification;

  return {
    userSelectedBranch,
    certificationUuid,
    isLoading: isLoadingPostCertification || isLoadingPatchCertification,
    hasError: isErrorPostCertification || isErrorPatchCertification,
    isToggleActivationUuid,
    errorPostCertification,
    isSuccessPostCertification,
    errorPatchCertification,
    isSuccessPatchCertification,
    actions,
    isLoadingPostOnDomaineCertification,
  };
}

export default useCertification;
