import {
  Checkbox,
  DefaultButton,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useState } from "react";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";

function AddUserToSubGroupModal({ onAddUser }: any) {
  const { actions } = useCustomModal();

  const [errors, setErrors]: any = useState({});
  const [newUser, setNewUser] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone_portable: "",
    envoyer_mot_de_passe: false,
  });

  const handleChangeNewUser = (name: any, value: any) => {
    setErrors({ ...errors, [name]: "" });
    setNewUser({ ...newUser, [name]: value });
  };

  const onSaveUser = () => {
    //NEED MODIFICATION: AJOUT DE LA VALIDATION
    onAddUser(newUser);
    actions.closeModal();
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 25 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          type="text"
          label="Nom :"
          placeholder="Ex: Dupont"
          required
          name="nom"
          onChange={(e: any) => handleChangeNewUser("nom", e.target.value)}
          value={newUser.nom}
          errorMessage={errors.nom}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          type="text"
          label="Prénom :"
          placeholder="Ex: Pierre"
          required
          name="prenom"
          onChange={(e: any) => handleChangeNewUser("prenom", e.target.value)}
          value={newUser.prenom}
          errorMessage={errors.prenom}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          type="email"
          label="Email :"
          placeholder="Ex: pierre.dupont@exemple.com"
          required
          name="email"
          onChange={(e: any) => handleChangeNewUser("email", e.target.value)}
          value={newUser.email}
          errorMessage={errors.email}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          label="Numéro de le téléphone :"
          type="text"
          placeholder="Ex: 0600000000"
          name="telephone_portable"
          onChange={(e: any) =>
            handleChangeNewUser("telephone_portable", e.target.value)
          }
          value={newUser.telephone_portable}
          errorMessage={errors.telephone_portable}
        />
      </Stack.Item>

      <Stack.Item>
        <Checkbox
          styles={commonInputStyles.checkbox}
          label="Envoyer le mot de passe à la validation de la création du compte ?"
          checked={newUser.envoyer_mot_de_passe}
          onChange={(e: any, ch) =>
            handleChangeNewUser("envoyer_mot_de_passe", e.target.checked)
          }
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>

        <PrimaryButton
          onClick={() => onSaveUser()}
          styles={commonButtonStyles.buttonSubmit}
          iconProps={{ iconName: "Add" }}
        >
          Ajouter
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
}

export default AddUserToSubGroupModal;
