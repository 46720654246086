import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { RiAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import CreateLink from "../../Common/CreateLink/CreateLink";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import FormSection from "../../Common/FormSection/FormSection";
import HabilitationOfDetailList from "../../Common/HabilitationOfDetailList/HabilitationOfDetailList";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import UtilisateursLinkToStructure from "../../Common/UtilisateursLinkToStructure/UtilisateursLinkToStructure";
import ofAdapters from "../../adapters/ofAdapters";
import {
  StructureProvider,
  StructureType,
} from "../../providers/StructureProvider/StructureProvider";
import EntitiesLinkedSection from "./FormSections/EntitiesLinkedSection";
import GeneralInformationsSection from "./FormSections/GeneralInformationsSection";
import useOfDetail, { OfContext } from "./useOfDetail";
import Can from "../../Common/Can/Can";
import { branchAccessEnum } from "../../config/accessEnum";

function OfDetail() {
  const { uuid } = useParams();
  const {
    of,
    ofProps,
    reactHookProps,
    actions: actionsPage,
    isLoadingOf,
    isChecked,
    refetch,
    optionsFilters,
    habilitations,
    isLoadingHabilitations,
    isRefetchingHabilitations,
  } = useOfDetail();
  const {
    isLoading,
    hasError,
    errorPostOf,
    errorPutOf,
    actions,
    userSelectedBranch,
    isSaved,
  } = ofProps;

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
    register,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid != "creer") {
      actions.setUpdateOfUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (of?.uuid) {
      reset(ofAdapters.transformForBO(of));
    }
  }, [of]);

  useEffect(() => {
    var apiErrors = (errorPostOf as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostOf]);

  useEffect(() => {
    var apiErrors = (errorPutOf as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutOf]);

  const onSubmit = (e: any) => {
    if (watch("uuid")) {
      setValue("_method", "PUT");
    }
    clearErrors();
    handleSubmit(actionsPage.saveOf)(e);
  };

  return (
    <OfContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        errors,
        userSelectedBranch,
        isChecked,
        uuid,
        of,
        isSaved,
      }}
    >
      <Stack>
        <BackLink />

        <Page
          title={!of?.uuid ? "Ajouter un OF" : "Editer un OF"}
          explain={
            !of?.uuid
              ? "Veuillez créer un organisme de formation rattaché à votre branche"
              : "Veuillez modifier l'organisme de formation rattaché à votre branche"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingOf}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: "POST",
                })}
              />

              <GeneralInformationsSection />
              {(isChecked || uuid !== "creer") && (
                <>
                  <EntitiesLinkedSection />

                  <StructureProvider
                    structureType={StructureType.OF}
                    structureUuid={uuid as any}
                    users={of?.utilisateurs_lies}
                    referent={of?.referent}
                    structureRefetch={refetch}
                  >
                    <UtilisateursLinkToStructure sectionTitle="Utilisateurs liés à l'organisme de formation" />
                  </StructureProvider>

                  {uuid !== "creer" && (
                    <Can I={branchAccessEnum.HABILITATION_SIMPLE_OF}>
                      {isLoadingHabilitations || isRefetchingHabilitations ? (
                        <Loader />
                      ) : (
                        <FormSection sectionTitle={"Habilitations de l'OF"}>
                          <CreateLink
                            text="Créer une habilitation"
                            to={`/admin/habilitations-of/creer?siret=${of?.siret}&ofUuid=${uuid}&ofRs=${of?.raison_sociale}`}
                          />

                          <HabilitationOfDetailList
                            habilitationsData={habilitations}
                            optionsFilters={optionsFilters}
                            actions={actionsPage}
                            isOfDetail={true}
                          />
                        </FormSection>
                      )}
                    </Can>
                  )}

                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <CancelButton />

                    <LoadButton
                      text={!of?.uuid ? "Créer l'OF" : "Modifier l'OF"}
                      isLoading={isLoading}
                      icon={<RiAddFill size={38} />}
                      style={{ fontWeight: "bold" }}
                    />
                  </Stack>
                </>
              )}
            </form>

            {hasError && (
              <PageMessage
                message={
                  ((errorPostOf as any) || (errorPutOf as any)).response.data
                    .message
                }
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </OfContext.Provider>
  );
}

export default OfDetail;
