import { MessageBar, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { toast } from "react-toastify";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { checkUtilisateurExistsRequest } from "../../services/userServices";
import { MessageBarStyles, commonInputStyles } from "../../styles";

const SearchExistingUser = ({ reactHookProps, actions, isChecked }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<any>([]);

  const actionsPage = {
    onKeyDown: (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        reactHookProps.handleSubmit(actionsPage.onVerify)(e);
      }
    },
    onVerify: async () => {
      setIsLoading(true);
      try {
        const userExist = await checkUtilisateurExistsRequest(
          reactHookProps.watch("email")
        );
        actions.setUpdateIsCheck(true, userExist[0]);
        setSearchResult(userExist);
      } catch (e: any) {
        toast.error(
          e.response?.data?.message ||
            "Une erreur est survenue lors de la vérification de l'email"
        );
      }
      setIsLoading(false);
    },
  };

  const {
    formState: { errors },
    control,
  } = reactHookProps;

  useEffect(() => {
    actions.setUpdateIsCheck(false);
    actions.resetAutocompleteInput();
    setSearchResult([]);
  }, [reactHookProps.watch("email")]);

  const getBlocsInfoMessage = () => {
    if (!isChecked) {
      return "La vérification de l'existence de l'utilisateur est obligatoire pour poursuivre la création.";
    }
    if (searchResult?.length > 0) {
      return "Un utilisateur similaire existe déjà en base de données sur la(les) branche(s) :";
    } else {
      return "Aucun utilisateur avec ces informations n'a été trouvé en base de données.";
    }
  };

  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Email :"
              type="email"
              {...field}
              placeholder="Ex : john.doe@gmail.com"
              errorMessage={(errors.email as any)?.message}
              onKeyDown={actionsPage.onKeyDown}
            />
          )}
          defaultValue=""
          control={control}
          name="email"
        />
      </Stack.Item>

      <LoadButton
        isLoading={isLoading}
        text="Vérifier l'existence du candidat"
        onClick={actionsPage.onVerify}
        type="button"
        style={{ maxWidth: 270 }}
      />

      <MessageBar styles={MessageBarStyles}>
        {getBlocsInfoMessage()}
        {searchResult?.length > 0 && (
          <ul>
            {searchResult[0]["branches"].map((b: any) => (
              <li>{b.nom}</li>
            ))}
          </ul>
        )}
      </MessageBar>
    </>
  );
};

export default SearchExistingUser;
