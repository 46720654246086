import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { atom, useAtom } from 'jotai'
import { getBranchRequest } from "../../services/branchServices";
import useBranch from "../../hooks/useBranch";
import { IFormInputs } from "./types";
import { useForm } from "react-hook-form";
import { postContactRequest } from "../../services/contactAdminServices";
import { useState } from "react";
import { getPageBranchRequest } from "../../services/publicServices";

const isDescriptionAtom = atom(false);
const descriptionAtom = atom({})

function useContactAdmin() {
  const branchProps = useBranch();
  const [isLoading, setIsLoadingTo] = useState(false);

  const [isDescription, setIsDescription] = useAtom(isDescriptionAtom);
  const [description, setDescription] = useAtom(descriptionAtom)

  const { data: branch } = useQuery(
    ["branches", branchProps.branchId],
    () => getBranchRequest(branchProps.branchId),
    {
      enabled: !!branchProps.branchId,
      retry: 0,
    }
  );

  const reactHookProps = useForm<IFormInputs>({
    mode: "onBlur",
  });

  const actions = {
    submitForm: async (data: IFormInputs) => {
      const formData: any = new FormData();
      formData.append("motif", data.motif.toString());
      formData.append("corps", data.besoin);
      formData.append("motif_libelle", data.autre_motif);
      data.files?.forEach((file: any) => {
        formData.append("files[]", file);
      });
      try {
        setIsLoadingTo(true);
        await postContactRequest(formData);
        reactHookProps.reset();
        toast.success("Votre demande a été envoyée");
        setIsLoadingTo(false);
      } catch (err: any) {
        setIsLoadingTo(false);
        toast.error(`${err.message}`);
      }
    },
    initializeDescription: async () =>{
      const response = await getPageBranchRequest('DESCRIPTION_AIDE');
      setDescription(response);
      setIsDescription(true)
    }
  };

  return {
    branch,
    branchProps,
    actions,
    reactHookProps,
    isLoading,
    isDescription,
    description
  };
}

export default useContactAdmin;
