import { PrimaryButton, Stack, Text } from "@fluentui/react";
import {
  branchAccessEnum,
  userAccessEnum,
} from "../../../../config/accessEnum";
import { commonButtonStyles } from "../../../../styles";
import CandidatureInfoHeaderWrapper from "../../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import { statutAvisFormateurEtEvaluation } from "../AvisFormateurEtEvaluation";

function InviterSecondEvaluateurButton({
  candidature,
  pageActions,
  accessActions,
  isSecondEvaluateur,
}: any) {
  if (
    isSecondEvaluateur ||
    !accessActions.canI({
      action: branchAccessEnum.POSSIBILITE_D_INVITER_UN_SECOND_EVALUATEUR,
    }).granted ||
    !accessActions.canI({
      action: userAccessEnum.INVITER_SECOND_EVALUATEUR,
    }).granted ||
    !statutAvisFormateurEtEvaluation.includes(candidature.statut)
  ) {
    return null;
  }

  return (
    <CandidatureInfoHeaderWrapper className="ms-Grid-col ms-sm12 ms-lg6">
      <Stack horizontalAlign="start">
        {accessActions.canI({
          action: branchAccessEnum.OBLIGER_AVIS_SECOND_EVALUATEUR,
        }).granted ? (
          <Text style={{ marginBottom: 15 }}>
            Vous êtes dans l'obligation d'inviter un second évaluateur via le
            bouton ci-dessous avant de renseigner votre évaluation et de cliquer
            sur valider en bas de page
          </Text>
        ) : (
          <Text style={{ marginBottom: 15 }}>
            Si vous souhaitez inviter un second évaluateur, veuillez l'inviter
            via le bouton ci-dessous avant de renseigner votre évaluation et de
            cliquer sur valider en bas de page
          </Text>
        )}

        <PrimaryButton
          styles={commonButtonStyles.buttonSubmit}
          onClick={pageActions.openAddSecondEvaluateurModal}
        >
          Inviter un second évaluateur
        </PrimaryButton>
      </Stack>
    </CandidatureInfoHeaderWrapper>
  );
}

export default InviterSecondEvaluateurButton;
