import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
import { IS_OFFLINE, OFFLINE_ROUTE, getOfflineStorage } from "../config/offlineConfig";

const eCertifApi = axiosInstance;

export const branchURI = "administration/branches";

export const getBranchesRequest = async (filters: any, options?: any) => {
  const filterType = {
    nom: { type: "string" },
  };

  const queryFiltersUrl = filters ? tools.generateFilterUrl(filters, filterType) : "";
  const queryOptionsUrl = options ? tools.generateOptionUrl(options) : "";

  const response = await eCertifApi.get(
    `${branchURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getBranchRequest = async (branchId: any) => {
  const response = await eCertifApi.get(`${branchURI}/${branchId}`);
  return response.data;
};

export const postBranchRequest = async (branch: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${branchURI}`, branch, xsrfHeader);
  return response.data;
};

export const putBranchRequest = async (branch: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${branchURI}/${branch.get("id")}`, branch, xsrfHeader);
  return response.data;
};

export const patchActivateBranchRequest = async (branchId: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(`${branchURI}/${branchId}/activer`, xsrfHeader);
  return response.data;
};

export const patchDesactivateBranchRequest = async (branchId: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(`${branchURI}/${branchId}/desactiver`, xsrfHeader);
  return response.data;
};

export const getBranchSettings = async () => {
  const url: string = "administration/parametrage/branche";
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(url);
  localStorage.setItem("paramBranch", JSON.stringify(response.data));
  return response.data;
};

export const putBranchSettings = async (branch: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(`administration/parametrage/branche`, branch, xsrfHeader);
  return response.data;
};

export const getBranchRolesSettings = async () => {
  const url: string = "administration/parametrage/acces";
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);

  const response = await eCertifApi.get(url);
  localStorage.setItem("paramAccess", JSON.stringify(response.data));
  return response.data;
};

export const putBranchRolesSetting = async (settings: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.put(`administration/parametrage/acces`, settings, xsrfHeader);
  return response.data;
};
