import { Checkbox, DefaultButton, MessageBar, Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import useTheme from "../../../../Common/useTheme";
import { branchAccessEnum } from "../../../../config/accessEnum";
import { API_URL } from "../../../../config/config";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";
import useInfoProfilUser from "../../../../hooks/useInfoProfilUser";
import {
  commonButtonStyles,
  commonInputStyles,
  MessageBarStyles,
} from "../../../../styles";
import { PartiesPrenantesContext } from "../usePartiesPrenantes";

const SelectOfFormSection = ({ disabled }: any) => {
  const { actions, reactHookProps, userSelectedBranch, candidature }: any =
    useContext(PartiesPrenantesContext);
  const { actions: accessActions } = useAccessSettings();
  const { primaryColor } = useTheme();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = reactHookProps;

  /*
  Si les habilitations des OFS sont actives => seul un utilisateur ENTREPRISE | GROUPE_ENTREPRISE | SOUS_GROUPE_ENTREPRISE peut créer une demande habilitation via la modal.
  */
  const isUserCanRenseigerOf =
    (accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted &&
      !isAnyKindOfAdmin) ||
    !accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted;
  const canCreateNewOf =
    !accessActions.canI({
      action: branchAccessEnum.ACTIVER_DEMANDE_HABILITATION,
    }).granted &&
    (accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted ||
      accessActions.canI({
        action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
      }).granted ||
      accessActions.canI({
        action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
      }).granted);

  return (
    <FormSection sectionTitle="OF lié à la candidature">
      <Controller
        defaultValue={true}
        name="rattache_of"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            styles={commonInputStyles.checkbox}
            label="Le candidat est-il rattaché à un OF ? :"
            checked={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      />
      {(watch("rattache_of") ?? true) && (
        <>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="OF lié à la candidature :"
                placeholder="Recherchez un OF par SIRET ou Raison sociale (ex : 12312312312312 ou OF1)"
                resourceURI={`${API_URL}api/administration/ofs/chercher-pour-candidature`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  userSelectedBranch.gestion_par_domaine
                    ? `${resourceURI}?recherche=${terms}&domaine_uuid=${candidature.domaine.uuid}&certification=${candidature.certification.uuid}`
                    : `${resourceURI}?recherche=${terms}&certification=${candidature.certification.uuid}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                errorMessage={(errors[name] as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                required
                disabled={disabled}
              />
            )}
            control={control}
            name="of_lie"
          />
          {!disabled && isUserCanRenseigerOf && (
            <Can
              I={
                branchAccessEnum.AUTORISER_ENTREPRISE_RENSEIGNER_OF_A_L_INSCRIPTION
              }
            >
              <Stack horizontal>
                {canCreateNewOf ? (
                  <MessageBar className="bg-info" styles={MessageBarStyles}>
                    Le paramètre de branche "La branche autorise la demande
                    d'habilitations" n'est pas activé. Il est donc impossible de
                    rattacher de nouvel organisme de formation.
                  </MessageBar>
                ) : (
                  <DefaultButton
                    styles={commonButtonStyles.defaultButton}
                    style={{ color: primaryColor, borderColor: primaryColor }}
                    text="Je ne trouve pas l'OF lié au candidat"
                    onClick={() => actions.openOfFormModal()}
                  />
                )}
              </Stack>
            </Can>
          )}
          {!disabled &&
            accessActions.canI({
              action: branchAccessEnum.HABILITATION_SIMPLE_OF,
            }).granted &&
            isAnyKindOfAdmin && (
              <MessageBar styles={MessageBarStyles}>
                Si vous souhaitez ajouter un organisme de formation qui ne
                possède pas l'habilitation pour la certification de cette
                candidature, vous pouvez créer l'habilitation en dehors de cette
                candidature.
              </MessageBar>
            )}
        </>
      )}
    </FormSection>
  );
};

export default SelectOfFormSection;
