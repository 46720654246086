import { DefaultButton, Dropdown, Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import formSectionStyles from '../../../Common/FormSection/FormSection.styles'
import { commonButtonStyles, commonInputStyles } from '../../../styles'
import useCustomModal from '../../../Common/Overlays/CustomModal/useCustomModal';
import LoadButton from '../../../Common/LoadButton/LoadButton';
import { useQuery } from 'react-query';
import { userSelectedBranchAtom } from '../../../atoms/branchAtom';
import { useAtom } from 'jotai';
import { getDomaineRequest, getDomainesRequest } from '../../../services/domaineServices';
import useCertification from '../../../hooks/certifications/useCertification';

function CreateCertificationDomaine() {
    const { actions } = useCustomModal();
    const { actions: certificationActions, isLoadingPostOnDomaineCertification } = useCertification()
    const [userSelectedBranch] = useAtom(userSelectedBranchAtom)
    const [certificationTemplates, setCertificationTemplates] = useState([])
    const [newCertification, setNewCertification] = useState({
        domaine: null,
        certification_template: null,
    });

    const handleChangeNewCertification = (e: any) => {
        const { name, value } = e.target;
        setNewCertification({ ...newCertification, [name]: value });
    };

    const { data: domaines } = useQuery(
        ["domaines", userSelectedBranch], getDomainesRequest,
        {
            enabled: userSelectedBranch.gestion_par_domaine,
            retry: 0,
            refetchOnWindowFocus: false,
        }
    );
    const domainesFromApi = domaines?.data.map((domaine: any) => ({
        id: domaine.uuid,
        key: domaine.uuid,
        text: `${domaine.nom}`,
    })) || []

    useEffect(() => {
        const getDomaine = async () => {
            if ((newCertification.domaine as any)?.id) {
                const domaine = await getDomaineRequest((newCertification.domaine as any).id)
                const certificationTemplatesFromApi: any = domaine?.certifications?.map((template: any) => ({
                    id: template.uuid,
                    key: template.uuid,
                    text: `${template.libelle} - ${template.type_libelle}`,
                })) || []
                setCertificationTemplates(certificationTemplatesFromApi)
            }
        }
        getDomaine()
    }, [newCertification.domaine])

    const onSave = () => {
        certificationActions.postCertificationOnDomaine(newCertification)
    }

    return (
        <Stack
            style={{ ...formSectionStyles.formContainer, minWidth: 400, paddingBottom: 12 }}
            tokens={{ childrenGap: 15 }}
        >
            <Stack.Item>
                <Dropdown
                    required
                    label="Domaine :"
                    selectedKey={
                        newCertification.domaine ? (newCertification.domaine as any).key : undefined
                    }
                    placeholder="Sélectionnez le domaine"
                    options={domainesFromApi}
                    onChange={(e: any, options: any) => {
                        handleChangeNewCertification({ target: { name: "domaine", value: options } });
                    }}
                    styles={commonInputStyles.dropdownStyle}
                />
            </Stack.Item>

            {newCertification.domaine &&
                <Stack.Item>
                    <Dropdown
                        required
                        label="Certification à utilisé comme modèle :"
                        selectedKey={
                            newCertification.certification_template ? (newCertification.certification_template as any).key : undefined
                        }
                        placeholder="Sélectionnez la certification"
                        options={certificationTemplates}
                        onChange={(e: any, options: any) => {
                            handleChangeNewCertification({ target: { name: "certification_template", value: options } });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                    />
                </Stack.Item>
            }

            <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
                <DefaultButton styles={commonButtonStyles.defaultButton} onClick={actions.closeModal}>
                    Annuler
                </DefaultButton>

                {(newCertification.domaine && newCertification.certification_template) &&
                    <LoadButton
                        text={"Créer"}
                        onClick={onSave}
                        type="button"
                        isLoading={isLoadingPostOnDomaineCertification}
                    />
                }

            </Stack.Item>

        </Stack>
    )
}

export default CreateCertificationDomaine