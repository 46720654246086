import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Label,
  MessageBar,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import Can from "../../../Common/Can/Can";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadLink from "../../../Common/LoadLink/LoadLink";
import Loader from "../../../Common/Loader/Loader";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import { userAccessEnum } from "../../../config/accessEnum";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";
import useConstants from "../../../hooks/useConstants";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import { MessageBarStyles, commonButtonStyles } from "../../../styles";
import tools from "../../../utils/tools";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import BlocSelectionRecap from "../ChoixBlocs/BlocSelectionRecap/BlocSelectionRecap";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import useUrlParams from "../useUrlParams";
import StatutDocuments from "./Sections/StatutDocuments";
import UpdateGenerateDocuments from "./Sections/UpdateGenerateDocuments/UpdateGenerateDocuments";
import useResultatsCandidature, {
  isLoadingCandidatureAtom,
} from "./useResultatsCandidature";

function ResultatsCandidature({
  statutGeneration,
  checkDocumentGenerationStatus,
}: any) {
  const { isSuperAdmin, isAnyKindOfAdmin } = useInfoProfilUser();
  const {
    candidature,
    candidatureSelectableBlocs,
    reactHookProps,
    isUpdatedDocuments,
    actions,
    isDecisionCancelable,
    isFailureToUpdateDocument,
  } = useResultatsCandidature();
  const { actions: candidatureActions, generalConditionnalDisplayVariables } =
    useCandidature();
  const { isAdmin } = useInfoProfilUser();
  const { isFirstStatutBeforeOrEqualSecondStatus } = useCandidatureWorkflow();
  const { id_candidature } = useUrlParams();
  const { constants } = useConstants();
  const { labels } = useLabelSettings();
  const { dynamicFields } = useDynamicFields();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadingCandidature] = useAtom(isLoadingCandidatureAtom);
  const { refrechCandidature } = useFetchCandidature(reactHookProps);

  const canUserViewResult =
    isAdmin ||
    generalConditionnalDisplayVariables.isPresidentForThisCandidature ||
    generalConditionnalDisplayVariables.isMembreForThisCandidature ||
    candidature.jury?.est_cloture === true;

  const items: any =
    candidature.documents
      ?.filter((d: any) => d.collection !== "FICHE_INDIVIDUELLE")
      .map((d: any) => ({
        id: d.collection,
        key: d.collection,
        nom: (
          tools.findIn(
            constants["CANDIDATURES"]["DOCUMENTS_TYPES"],
            d.collection,
            "id"
          ) as any
        ).text,
        multiple: d.multiple,
        blocName: d.multiple ? d.name : "",
        file_url: d.file_url,
      })) ?? [];

  const columns: any = [
    {
      key: "1",
      name: "Nom du document",
      fieldName: "nom",
      minWidth: 150,
      maxWidth: 800,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>{item.nom}</Text>
          {item.multiple && <Text>{item.blocName}</Text>}
        </Stack>
      ),
    },
    {
      key: "2",
      name: "Télécharger",
      fieldName: "file_url",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <LoadLink
          href={item.file_url}
          styles={commonButtonStyles.buttonLink}
          text={"Télécharger"}
        />
      ),
    },
  ];

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        isFirstStatutBeforeOrEqualSecondStatus(
          candidature.statut,
          "identification_parties_prenantes"
        ),
        dynamicFields
      )
    );
  };

  useEffect(() => {
    //!isUpdatedDocument -> attendre la fin de la regeneration des documents et non le debut de la regeneration des documents
    if (
      id_candidature &&
      !isUpdatedDocuments &&
      !isFirstLoad &&
      !isFailureToUpdateDocument
    ) {
      fetchModifiedCandidature();
    }
    if (isFirstLoad) {
      setIsFirstLoad(false);
    }
  }, [isUpdatedDocuments, isFailureToUpdateDocument]);

  const canSeeDocumentActionButtons = isAnyKindOfAdmin;

  if (isLoadingCandidature) return <Loader />;

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>
      {canUserViewResult ? (
        <>
          <OptionsBlocsSection
            candidature={candidature}
            statutCandidature={StatutCandidature.RESULTATS_JURY}
            showDecisions={true}
            reactHookProps={reactHookProps}
            disabled={true}
          />

          {candidature.avis_formateur && (
            <FormSection sectionTitle="Avis du formateur">
              <Label>Avis laissé par le fomateur :</Label>
              {candidature.avis_formateur?.avis}
            </FormSection>
          )}
          {candidature.avis_second_evaluateur && (
            <FormSection
              sectionTitle={labels.AVIS_SECOND_EVALUATEUR_MENTION_AVIS}
            >
              <Label>Avis laissé par le second évaluateur :</Label>
              {candidature.avis_second_evaluateur.avis}
            </FormSection>
          )}
          {(candidature.commentaire_candidat_evaluation ||
            candidature.commentaire_candidat_positionnement) && (
            <FormSection sectionTitle="Avis du candidat">
              <Stack horizontal wrap>
                {candidature.commentaire_candidat_positionnement && (
                  <Stack className="ms-Grid-col ms-sm12 ms-lg6">
                    <Label>
                      Avis laissé par le candidat au positionnement :
                    </Label>
                    {candidature.commentaire_candidat_positionnement}
                  </Stack>
                )}

                {candidature.commentaire_candidat_evaluation && (
                  <Stack className="ms-Grid-col ms-sm12 ms-lg6">
                    <Label>Avis laissé par le candidat à l'évaluation :</Label>
                    {candidature.commentaire_candidat_evaluation}
                  </Stack>
                )}
              </Stack>
            </FormSection>
          )}

          <Stack.Item style={{ marginTop: 24 }}>
            <BlocSelectionRecap
              blocs_competences_passes={candidature.blocs_competences_passes}
              blocs_competences={candidatureSelectableBlocs}
              nb_blocs_au_choix_a_valider={
                candidature.certification?.nb_blocs_au_choix_a_valider
              }
              blocs_competences_updated={reactHookProps.watch(
                "blocs_competences_passes"
              )}
              showValidation
              finalResult={candidature.resultat}
            />
          </Stack.Item>

          {isDecisionCancelable && (
            <Stack.Item style={{ marginTop: 24 }}>
              <DefaultButton
                styles={commonButtonStyles.deleteButton}
                onClick={actions.cancelDecisionPresident}
              >
                Annuler la décision du président
              </DefaultButton>
            </Stack.Item>
          )}

          <UpdateGenerateDocuments candidature={candidature} />

          <Can
            I={[
              userAccessEnum.TELECHARGER_PARCHEMIN,
              userAccessEnum.ACCEDER_DUPLICATA_PARCHEMIN,
              userAccessEnum.TELECHARGER_FICHE_DECISION,
              userAccessEnum.TELECHARGER_DUPLICATA_FICHE_DECISION,
              userAccessEnum.TELECHARGER_ATTESTATIONS_BLOCS_COMPETENCES,
            ]}
          >
            <FormSection sectionTitle="Documents de la certification">
              {(!candidature.jury?.est_cloture || isSuperAdmin) && (
                <>
                  <StatutDocuments
                    candidature={candidature}
                    isUpdatedDocuments={isUpdatedDocuments}
                    actions={actions}
                    canSeeDocumentActionButtons={canSeeDocumentActionButtons}
                    statutGeneration={statutGeneration}
                    checkDocumentGenerationStatus={
                      checkDocumentGenerationStatus
                    }
                    refrechCandidature={refrechCandidature}
                  />
                  {candidature.documents_generes_le ? (
                    <span>
                      {`Date de dernière génération : ${tools.reformateDateFromJsDate(
                        candidature.documents_generes_le
                      )}`}
                    </span>
                  ) : null}
                </>
              )}

              {items.length > 0 &&
                statutGeneration !== "GENERATION_EN_COURS" && (
                  <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    items={items}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                )}
            </FormSection>
          </Can>
        </>
      ) : (
        <MessageBar styles={MessageBarStyles}>
          La candidature est en attente de décision du jury.
        </MessageBar>
      )}
    </Stack>
  );
}

export default ResultatsCandidature;
