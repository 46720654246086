import { mergeStyleSets } from "@fluentui/merge-styles";

export interface IComponentClassNames {
  table: string;
  tr: string;
  trLight: string;
  leftCell: string;
  leftCellLight: string;
  rightCell: string;
  typeBloc: string;
}

export const getClassNames = (): IComponentClassNames => {
  return mergeStyleSets({
    table: {
      borderCollapse: "collapse",
      // backgroundColor: "#fff",
      width: "100%",
    },
    tr: {
      ":not(:first-of-type)": {
        borderTop: "1px solid #dbdbdb",
      },
    },
    trLight: {
      ":not(:first-of-type)": {
        borderTop: "1px solid #dbdbdb",
      },
    },
    leftCell: {
      width: "35%",
      borderRight: "1px solid #dbdbdb",
      padding: 10,
      //verticalAlign: "top",
      fontSize: 13,
      textAlign: "right",
    },
    leftCellLight: {
      width: "35%",
      borderRight: "1px solid rgb(228 228 228)",
      padding: 10,
      //verticalAlign: "top",
      fontSize: 13,
      textAlign: "right",
    },
    rightCell: {
      width: "65%",
      textAlign: "left",
      padding: 10,
      height: 1,
    },
    typeBloc: {
      padding: "4px 18px",
      backgroundColor: "#000",
      color: "#fff",
      borderRadius: "15px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxHeight: 25,
      minWidth: 55,
    },
  });
};
