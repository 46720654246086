import { ChoiceGroup, Stack } from "@fluentui/react";
import { Controller } from "react-hook-form";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SectionTitle from "../../../Common/SectionTitle/SectionTitle";
import { commonInputStyles } from "../../../styles";
import BlocSelection from "../Common/BlocSelection/BlocSelection";
import BlocSelectionRecap from "./BlocSelectionRecap/BlocSelectionRecap";
import { ChoixBlocsProvider, useChoixBlocs } from "./useChoixBlocs";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { userAccessEnum } from "../../../config/accessEnum";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import Can from "../../../Common/Can/Can";
import useFetchCandidature from "../useFetchCandidature";
import { useAtom } from "jotai";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";

const ChoixBlocs = () => {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const {
    candidature,
    reactHookProps,
    blocsFromSelectedOption,
    actions,
    isSaving,
  } = useChoixBlocs();
  const {
    isReferentielStillEditable,
    isThisPageStatusBeforeCandidatureStatus,
  } = useCandidatureWorkflow();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(
    reactHookProps,
    (candidatureData: any) => {
      if (
        (!candidatureData.blocs_competences_passes ||
          candidatureData.blocs_competences_passes?.length == 0) &&
        candidatureData.statut == "choix_blocs"
      ) {
        actions.resetBlocsFromOptionChanged(null);
      }
    }
  );

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    reactHookProps.handleSubmit(actions.onSave)(e);
  };

  //Si la candidature est dans un statut ultérieur à choix_blocs et que son référentiel est toujours modifiable, il faut la permission MODIFIER_CHOIX_BLOCS_CERTIF pour les modifier (admin), sinon il faut juste la permission CHOIX_BLOCS_CERTIF
  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, [
        "identification_parties_prenantes",
      ])
      ? userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.CHOIX_BLOCS_CERTIF;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  return !candidature?.certification ? null : (
    <ChoixBlocsProvider
      value={{
        reactHookProps,
        actions,
        candidature,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection
            certification={candidature.certification}
            extraInformation
          />
        </CandidatureInfoHeaderWrapper>

        <form onSubmit={onSubmit}>
          <FormSection sectionTitle="Sélection des blocs de compétences passés par le candidat">
            {candidature.certification?.avec_options && (
              <Stack.Item>
                <Controller
                  render={({ field: { value, name } }) => {
                    return (
                      <ChoiceGroup
                        label="Quelle option souhaitez-vous choisir pour la candidature ?"
                        styles={commonInputStyles.textField}
                        selectedKey={value ? value : 1}
                        options={candidature?.certification?.options.map(
                          (o: any) => ({
                            ...o,
                            text: o.option,
                            key: o.uuid,
                          })
                        )}
                        required
                        onChange={(e: any, options: any) => {
                          reactHookProps.setValue(name, options.key, {
                            shouldValidate: true,
                          });
                          actions.resetBlocsFromOptionChanged(options.key);
                        }}
                        name={name}
                        disabled={inputsDisabled}
                      />
                    );
                  }}
                  control={reactHookProps.control}
                  name="option_selectionnee"
                />
              </Stack.Item>
            )}
            {candidature.certification?.avec_options &&
            !reactHookProps.watch("option_selectionnee") ? (
              <p className="text-info">
                Veuillez d'abord sélectionner une option.
              </p>
            ) : (
              <Stack.Item>
                <BlocSelection
                  nb_blocs_au_choix_a_valider={
                    candidature.certification.nb_blocs_au_choix_a_valider
                  }
                  blocs_competences={blocsFromSelectedOption}
                  selectedBlocs={
                    reactHookProps.watch("blocs_competences_passes") || []
                  }
                  onChange={(newBlocs: any) => {
                    reactHookProps.setValue(
                      "blocs_competences_passes",
                      newBlocs
                    );
                    reactHookProps.clearErrors("blocs_competences_passes");
                  }}
                  disabled={inputsDisabled}
                />
              </Stack.Item>
            )}
          </FormSection>
          <Stack.Item>
            <SectionTitle title="Résumé de la sélection"></SectionTitle>
            <BlocSelectionRecap
              blocs_competences_passes={
                reactHookProps.watch("blocs_competences_passes") || []
              }
              blocs_competences={blocsFromSelectedOption || []}
              nb_blocs_au_choix_a_valider={
                candidature.certification.nb_blocs_au_choix_a_valider
              }
            />
          </Stack.Item>
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>

            <Can I={[applicableEditPermission]}>
              <LoadButton
                text={"Enregistrer le choix des options et blocs passés"}
                isLoading={isSaving}
              />
            </Can>
          </Stack>
        </form>
      </Stack>
    </ChoixBlocsProvider>
  );
};

export default ChoixBlocs;
