import { Link, Stack, Text } from "@fluentui/react";
import { FaCheckDouble } from "react-icons/fa";

function SignUpSuccess() {
  return (
    <Stack
      style={{ width: "100%", minHeight: "80vh" }}
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 20 }}
    >
      <FaCheckDouble style={{ fontSize: 50, color: "#0AA204" }} />
      <Text variant={"mediumPlus"} style={{ color: "#0AA204" }}>
        Votre demande d'inscription a été envoyée.
      </Text>
      <Link href="/" style={{ display: "flex", alignItems: "center" }}>
        Revenir sur la page de connexion
      </Link>
    </Stack>
  );
}

export default SignUpSuccess;
