import { Stack } from "@fluentui/react";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import HabilitationOfDetailList from "../../Common/HabilitationOfDetailList/HabilitationOfDetailList";
import HabilitationUtilisateurDetailList from "../../Common/HabilitationUtilisateurDetailList/HabilitationUtilisateurDetailList";
import Page from "../../Common/Page/Page";
import { branchAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import MesHabilitationsListFilters from "./MesHabilitationsListFilters/MesHabilitationsListFilters";
import useMesHabilitationsList from "./useMesHabilitationsList";

function MesHabilitationsList() {
  const {
    actions: pageActions,
    optionsFilters,
    habilitationsOF,
    habilitationsUtilisateur,
  } = useMesHabilitationsList();
  const { actions: accessActions } = useAccessSettings();

  return (
    <Stack>
      <Page title="Mes habilitations" explain="Gérer mes habilitations">
        <Can I={branchAccessEnum.ACTIVER_DEMANDE_HABILITATION}>
          <Stack horizontal>
            <Can I={branchAccessEnum.HABILITATION_SIMPLE_OF}>
              <CancelButton
                text="Demander une habilitation"
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("OF");
                }}
              />
            </Can>

            <Can I={branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR}>
              <CancelButton
                text={"Demander une habilitation en tant qu'évaluateur"}
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("EVALUATEUR");
                }}
              />
            </Can>

            <Can I={branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR}>
              <CancelButton
                text={"Demander une habilitation en tant que positionneur"}
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("POSITIONNEUR");
                }}
              />
            </Can>
          </Stack>
        </Can>

        <MesHabilitationsListFilters />

        {accessActions.canI({
          action: branchAccessEnum.HABILITATION_SIMPLE_OF,
        }).granted ? (
          <HabilitationOfDetailList
            habilitationsData={habilitationsOF ?? []}
            optionsFilters={optionsFilters}
            isDashboard={true}
            isPaginate={true}
            actions={pageActions}
          />
        ) : (
          <HabilitationUtilisateurDetailList
            habilitationsData={habilitationsUtilisateur ?? []}
            optionsFilters={optionsFilters}
            isDashboard={true}
            isPaginate={true}
            actions={pageActions}
          />
        )}
      </Page>
    </Stack>
  );
}

export default MesHabilitationsList;
