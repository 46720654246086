import { atom, useAtom } from "jotai";
import {
  BaseSlots,
  createTheme,
  getColorFromString,
  isDark,
  ThemeGenerator,
  themeRulesStandardCreator,
} from "@fluentui/react";

export const primaryColorAtom = atom("#3a50df");
export const textColorAtom = atom("#000000");
export const backgroundColorAtom = atom("#ffffff");
export const customThemeAtom = atom(null);

const useTheme = () => {
  const [primaryColor, setPrimaryColor] = useAtom(primaryColorAtom);

  const [customTheme, setCustomTheme] = useAtom(customThemeAtom);

  const generateTheme = (primary?: string) => {
    let color = primary || "#3a50df";
    setPrimaryColor(color);
    setCustomTheme(generateThemeFromPrimary(color));
  };
  const generateThemeFromPrimary = (primary: any | null): any => {
    const themeRules = themeRulesStandardCreator();
    const colors = {
      pColor: getColorFromString(primary || "#3a50df")!,
      tColor: getColorFromString("#000000")!,
      bColor: getColorFromString("#ffffff")!,
    };

    const currentIsDark = isDark(themeRules[BaseSlots[BaseSlots.backgroundColor]].color!);

    ThemeGenerator.insureSlots(themeRules, currentIsDark);
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.primaryColor]],
      colors.pColor,
      currentIsDark,
      true,
      true
    );
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.foregroundColor]],
      colors.tColor,
      currentIsDark,
      true,
      true
    );
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.backgroundColor]],
      colors.bColor,
      currentIsDark,
      true,
      true
    );

    const themeAsJson: {
      [key: string]: string;
    } = ThemeGenerator.getThemeAsJson(themeRules);

    const finalTheme = createTheme({
      ...{ palette: themeAsJson },
      defaultFontStyle: {
        fontFamily: "Roboto, Arial, sans-serif",
        //fontWeight: 'regular'
      },
      isInverted: currentIsDark,
    });
    return finalTheme;
  };

  return {
    customTheme,
    primaryColor,
    successColor: "green",
    dangerColor: "red",
    warningColor: "#df793a",
    generateTheme,
  };
};

export default useTheme;
