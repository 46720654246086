import { Route, Routes, useLocation } from "react-router-dom";
import { routesConfig } from "./config/routesConfig";
import Layout from "./Common/Layout/Layout";
import RequireAuth from "./Common/RequireAuth/RequireAuth";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";

import { axiosInstance } from "./config/axiosConfig";
import RequireBranchSelection from "./Common/RequireBranchSelection";
import RequireAccess from "./Common/RequireAccess/RequireAccess";
import HeaderPublic from "./Common/Header/HeaderPublic";
import HeaderPrivate from "./Common/Header/HeaderPrivate";
import RequireBranchEntities from "./Common/RequireBranchEntities/RequireBranchEntities";
import GlobalLoader from "./Common/GlobalLoader/GlobalLoader";
import RequireCGUAcceptance from "./Common/RequireCGUAcceptance";

function AppRoutes() {
  const { getUserQuery, actions } = useAuth();

  axiosInstance.interceptors.request.use(
    function (config): any {
      return config;
    },
    function (error) {
      console.log("Err from request: ", error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status == 401) {
        if (
          error.response.config.url !== "user" &&
          error.response.config.url !== "me"
        ) {
          actions.openCookieExpiredModal();
        }
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getUserQuery();
  }, []);

  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Layout />}>
        {routesConfig.map((route) => {
          const uniqKey = `route${route.path}`;
          if (route.type == "private") {
            return (
              <>
                <Route key={uniqKey} element={<RequireAuth />}>
                  <Route element={<GlobalLoader />}>
                    <Route element={<RequireBranchSelection />}>
                      <Route element={<RequireCGUAcceptance />}>
                        <Route element={<RequireBranchEntities />}>
                          <Route
                            element={
                              <RequireAccess
                                allowedAccess={route.allowedAccess}
                              />
                            }
                          >
                            <Route
                              path={route.path}
                              element={
                                <>
                                  <HeaderPrivate />
                                  {route.element}
                                </>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </>
            );
          }
          return (
            <Route
              key={uniqKey}
              path={route.path}
              element={
                <>
                  <HeaderPublic />
                  {route.element}
                </>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
}

export default AppRoutes;
