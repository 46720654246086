import { Label } from "@fluentui/react";
import { commonInputStyles } from "../../styles";
import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";

const Editor = require("ckeditor5-custom-build/build/ckeditor");

function TextEditor({ name, label, control, onChange }: any) {
  return (
    <>
      <Label required={true} styles={commonInputStyles.label}>
        {label}
      </Label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onBlur, value } }) => (
          <CKEditor
            editor={Editor}
            data={value}
            onReady={(editor: any) => {
              editor.editing.view.change((writer: any) => {
                writer.setStyle(
                  "min-height",
                  "190px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor: any) => {
              const data = editor.getData();
              onChange(data);
            }}
            onBlur={onBlur}
          />
        )}
      />
    </>
  );
}

//setValue(name, data, { shouldValidate: true });

export default TextEditor;
