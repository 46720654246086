import { useAtom } from "jotai";
import { useQuery } from "react-query";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import useSubGroupCompany from "../../hooks/subGroupCompany/useSubGroupCompany";
import {
  getExportSubGroupCompanies,
  getSubGroupCompaniesRequest,
} from "../../services/subGroupCompanyServices";
import tools from "../../utils/tools";
import { useForm } from "react-hook-form";
import { atomWithReset } from "jotai/utils";
import { useState } from "react";
import { toast } from "react-toastify";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  nom: "",
  utilisateur: "",
  branche_liee: { id: 0, nom: "Toutes les branches", value: undefined, key: 0 },
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useSubGroupCompanyList() {
  const subGroupCompanyProps = useSubGroupCompany();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();
  const [isLoadingExportSubGroupCompany, setIsLoadingExportSubGroupCompany] =
    useState(false);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: subGroupCompanies, isLoading: isLoadingSubGroupCompanies } =
    useQuery(
      [
        "subGroupCompanies",
        optionsFilters,
        subGroupCompanyProps.userSelectedBranch,
      ],
      () => getSubGroupCompaniesRequest(filters, optionsFilters),
      { retry: 0 }
    );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteSubGroupCompanyModal: (subGroupCompany: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={subGroupCompany.nom}
            deleteFunction={() =>
              subGroupCompanyProps.actions.deleteSubGroupCompany(
                subGroupCompany.uuid
              )
            }
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportSubGroupCompanies: async () => {
      setIsLoadingExportSubGroupCompany(true);
      try {
        const firstResponse: any = await getExportSubGroupCompanies(
          filters
        ).catch((e: any) => {
          console.log("ERR", e);
          toast.error(
            "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
          );
          setIsLoadingExportSubGroupCompany(false);
        });
        if (!firstResponse) return;
        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });
        setIsLoadingExportSubGroupCompany(false);
      } catch (e: any) {
        setIsLoadingExportSubGroupCompany(false);
      }
    },
  };

  return {
    subGroupCompanies,
    subGroupCompanyProps,
    isLoadingSubGroupCompanies,
    isLoadingExportSubGroupCompany,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
  };
}

export default useSubGroupCompanyList;
