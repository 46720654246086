import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useCompetenceBlockList from "./useCompetenceBlockList";
import CompetenceBlockListFilters from "./CompetenceBlockListFilters/CompetenceBlockListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import tools from "../../utils/tools";

import { commonButtonStyles } from "../../styles/index";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";

function CompetenceBlockList() {
  const { competenceBlocks, competenceBlockProps, optionsFilters, actions: actionsPage } = useCompetenceBlockList();

  const {
    userSelectedBranch,
    actions,
    isToggleActivationUuid
  } = competenceBlockProps

  const items = competenceBlocks?.data || [];

  const columns = [
    {
      key: "1",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("libelle");
      },
      onRender: (item: any) => <Text>{item.libelle}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "libelle"),
    },

    {
      key: "2",
      name: "Certifications rattachées",
      fieldName: "certifications",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => item["certifications"].map((certif: any, i: number) => <Stack key={i}><Text>{certif.libelle}</Text></Stack>),
    },

    {
      key: "3",
      name: "Branche créatrice",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => <Text>{item.branche_creatrice.nom}</Text>,
    },
    {
      key: "4",
      name: "Actif",
      fieldName: "actif",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item["actif"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn('actif')
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'actif')
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        if (userSelectedBranch.id == item.branche_creatrice.id) {
          return (
            <Stack horizontal wrap>
              <Link
                to={`/admin/blocs-de-competences/${item.uuid}`}
                as={RouterLink}
                style={{ marginLeft: 5, marginTop: 5 }}
                styles={commonButtonStyles.buttonLink}
              >
                Editer
              </Link>
              <Link
                onClick={() => actionsPage.openDeleteCompetenceBlockModal(item)}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Supprimer
              </Link>

              <LoadLink
                styles={item.actif ? commonButtonStyles.disableButtonLink : commonButtonStyles.activeButtonLink}
                onClick={() => actions.toggleCompetenceBlockActivation(item)}
                isLoading={isToggleActivationUuid == item.uuid}
                text={item.actif ? "Désactiver" : "Activer"}
              />

            </Stack>
          );
        }
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des blocs de compétences de vos référentiels"
        explain="Veuillez gérer les blocs de compétences de vos référentiels"
      >
        <Stack style={{ marginTop: 25 }}>
          <CreateLink text="Créer un bloc de compétences" to="/admin/blocs-de-competences/creer" />
        </Stack>

        <CompetenceBlockListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={competenceBlocks?.meta.current_page || 1}
          totalPages={Math.ceil(competenceBlocks?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CompetenceBlockList;
