import { DefaultButton, Label, PrimaryButton, Stack, TextField, Toggle } from "@fluentui/react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import CheckboxListInput from "../../../Common/CheckboxListInput/CheckboxListInput";
import FileInput from "../../../Common/FileInput/FileInput";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useConstants from "../../../hooks/useConstants";
import { commonInputStyles, commonButtonStyles } from "../../../styles";
import { v4 as uuidv4 } from 'uuid';
import validations from "../../../schemas/certificationSchema";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";

const AddDocumentForm = ({ onAddDocument, onUpdateDocument, document: initialDocument }: any) => {
  const { actions } = useCustomModal();
  const { constants } = useConstants();
  const [document, setDocument] = useState(
    initialDocument || {
      nom: "",
      description: "",
      file: null,
      file_url: "",
      profils_autorises_en_lecture: [],
      types_candidature_autorises_en_ajout: [],
      obligatoire: false,
      option: "",
      boUuid: uuidv4()
    }
  );

  const [errorDocument,setErrorDocument] =useState(
    {
      nom: "",
      file: "",
      profils_autorises_en_lecture: "",
      types_candidature_autorises_en_ajout: "",
      obligatoire: "",
    }
  )

 

  const handleChangeDocument = (e: any) => {
    const { name, value } = e.target;
    if (name == 'file') {
      setDocument({ ...document, [name]: value[0] });
    } else {
      setDocument({ ...document, [name]: value });
    }
    setErrorDocument((prev)=>({...prev,[name]:""}))
  };

  const onSaveDocument = () => {
    const validationDocument = validations.validateAddDocumentForm(document)
    if (validationDocument?.error) {
      validationDocument.error.details.map((item)=>{
        setErrorDocument((prev)=> ({...prev, [(item.context as any)?.label]: item.message}))
      })
    } else {
      if (initialDocument?.boUuid) {
        onUpdateDocument(document);
      } else {
        onAddDocument(document);
      }
      actions.closeModal();
    }
  };

  return (
    <Stack
      style={{ ...formSectionStyles.formContainer, minWidth: 400, paddingBottom: 12 }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="nom"
          label="Libellé :"
          type="text"
          placeholder="Ex : Mon document"
          onChange={handleChangeDocument}
          required
          value={document.nom}
          errorMessage={errorDocument.nom}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="description"
          label="Description :"
          type="text"
          placeholder="Ex : Ma description"
          onChange={handleChangeDocument}
          value={document.description}
        />
      </Stack.Item>
      <Stack.Item>
        <Label styles={commonInputStyles.label} required>Modèle :</Label>
        <FileInput
          name="file"
          file={document.file ? [document.file] : []}
          existingFileURL={document.file_url}
          onUpdateFile={handleChangeDocument}
          onDeleteFile={() => { 
            if(document.file_url){
              handleChangeDocument({ target: { name: "file_url", value: "" } }) 
            } 
          }}
          acceptedFileTypes={["PDF", "WORD", "EXCEL"]}
        />
        <ErrorMessage errorMessage={errorDocument.file} />
      </Stack.Item>
      <Stack.Item>
        <CheckboxListInput
          required={true}
          label="Autorisation profils en lecture :"
          items={constants['CERTIFICATIONS']['PROFIL_TYPES']}
          itemIndex="key"
          itemLabel="text"
          name="profils_autorises_en_lecture"
          value={document.profils_autorises_en_lecture}
          onChange={handleChangeDocument}
        />
        <ErrorMessage errorMessage={errorDocument.profils_autorises_en_lecture} />
      </Stack.Item>
      <Stack.Item>
        <CheckboxListInput
          required={true}
          label="Autorisation ajout type de candidature :"
          items={constants['CERTIFICATIONS']['CANDIDATURE_TYPES']}
          itemIndex="key"
          itemLabel="text"
          name="types_candidature_autorises_en_ajout"
          value={document.types_candidature_autorises_en_ajout}
          onChange={handleChangeDocument}
        />
        <ErrorMessage errorMessage={errorDocument.types_candidature_autorises_en_ajout} />
      </Stack.Item>
      <Stack.Item>
        <Label htmlFor="active" required styles={commonInputStyles.label}>
          Document obligatoire :
        </Label>
        <Toggle
          styles={commonInputStyles.toggleInput}
          onText="Oui"
          offText="Non"
          onChange={(e, checked): any =>
            handleChangeDocument({ target: { name: "obligatoire", value: checked } })
          }
          checked={document.obligatoire}
        />
        <ErrorMessage errorMessage={errorDocument.obligatoire} />
      </Stack.Item>
      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>
        <PrimaryButton
          onClick={onSaveDocument}
          styles={commonButtonStyles.buttonSubmit}
          iconProps={{ iconName: "Add" }}
        >
          Enregistrer le document
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};

export default AddDocumentForm;
