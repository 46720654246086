import { CommandButton, Label, MessageBar, Stack } from "@fluentui/react";
import { useContext } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import FileInput from "../../../../Common/FileInput/FileInput";
import SectionTitle from "../../../../Common/SectionTitle/SectionTitle";
import { commonInputStyles } from "../../../../styles";
import { TransmissionDossierContext } from "../useTransmissionDossier";
import useAuth from "../../../../hooks/useAuth";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../../atoms/branchAtom";
import useMiseEnConfirmite from "../../Common/MiseEnConformite/useMiseEnConfirmite";

const DocumentsSection = ({ documents }: any) => {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { candidature, reactHookProps, actions }: any = useContext(
    TransmissionDossierContext
  );
  const { auth } = useAuth();
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const profilsOnThisBranch: any = auth?.user?.mes_branches?.find(
    (branche: any) => branche.id == userSelectedBranch?.id
  );

  //&& doc.profils_autorises_en_lecture
  //         .find((profil: any) => (profil.value && profilsOnThisBranch?.profils?.find((myProfil: any) => myProfil == profil.key)) || profilsOnThisBranch?.profils?.length == 0))
  // )

  return (
    <Stack>
      <SectionTitle title="Documents de la certification" />
      {documents.length == 0 && (
        <MessageBar>
          Aucun document n'est à envoyer pour cette certification
        </MessageBar>
      )}
      <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
        {documents.map((doc: any, docKey: any) => {
          const documentExistant = reactHookProps
            .watch("certification_documents")
            ?.find((d: any) => d.collection == doc.uuid && !d.file_deleted);
          const readOnly = documentExistant?.uuid && !isEditMode;
          //const fileRef = tools.findIn()
          return candidature.statut == "transmission_dossier" ||
            readOnly ||
            isEditMode ? (
            <div
              style={{
                background: "#f7f7f7",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #eaeaea",
                boxShadow: "1px 1px 4px -4px black",
              }}
            >
              <Label
                required={doc.obligatoire}
                styles={commonInputStyles.label}
              >
                {doc.nom} :
              </Label>
              {doc.description && (
                <div style={{ color: "#7d7d7d", fontSize: 12 }}>
                  {doc.description || "-"}
                </div>
              )}

              {!readOnly ? (
                <CommandButton href={doc.file_url} download target="_blank">
                  <AiOutlineCloudDownload style={{ marginRight: 5 }} />
                  Télécharger le modèle
                </CommandButton>
              ) : (
                <div style={{ marginBottom: 10 }}></div>
              )}

              <FileInput
                name={`certification_documents`}
                file={documentExistant ? [documentExistant.file] : []}
                existingFileURL={
                  documentExistant ? documentExistant?.file_url : ""
                }
                //onUpdateFile={(e: any) => actions.onChangeFile({ ...doc, newFile: e.target.value })}
                onUpdateFile={(e: any) =>
                  e.target.value.length == 0
                    ? actions.onRemoveFile(doc.uuid)
                    : actions.onChangeFile(doc.uuid, e.target.value)
                }
                acceptedFileTypes={["ANY"]}
                readOnly={readOnly}
              />
            </div>
          ) : (
            <></>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default DocumentsSection;
