import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { getCandidatRequest } from "../../services/candidatServices";
import { authAtom } from "../useAuth";

function useCandidatQuery(candidatProps: any) {
  const [auth] = useAtom(authAtom);

  const { data: candidat, isLoading } = useQuery(
    ["profilCandidat", candidatProps.candidatUuid],
    () => getCandidatRequest(candidatProps.candidatUuid, auth.user.uuid),
    {
      enabled: !!candidatProps.candidatUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    candidat,
    isLoading,
  };
}

export default useCandidatQuery;
