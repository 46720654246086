import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import useDemandeInscriptionStructureList from "../useDemandeInscriptionStructureList";
import { Controller } from "react-hook-form";
import useConstants from "../../../hooks/useConstants";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import TextResult from "../../../Common/TextResult/TextResult";
import { ModelType } from "../../SignUp/ProfileInformation/ProfileInformation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function DemandeInscriptionStructureListFilters() {
  const location = useLocation();

  const { constants } = useConstants();
  const {
    demandeInscriptionStructure,
    isLoadingDemandeInscriptionStructure,
    actions,
    reactHookProps,
  } = useDemandeInscriptionStructureList();

  const { handleSubmit, control, setValue } = reactHookProps;

  const typeStructureSort = [
    { id: undefined, text: "Tous les types", key: "all", value: undefined },
    ...constants?.DEMANDE_INSCRIPTION?.PROFILS_AUTORISES?.map((cst: any) => ({
      ...cst,
      value: ModelType[cst.id.toLowerCase()],
    })),
  ];

  const statutListSort = [
    { id: undefined, text: "Tous les statuts", key: "all" },
    ...constants.DEMANDE_INSCRIPTION_STRUCTURE.STATUTS,
  ];

  const totalDemandeInscriptionStructure =
    demandeInscriptionStructure?.meta.total || 0;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  useEffect(() => {
    const parsed: any = queryString.parse(location.search);
    if (parsed["statut"]) {
      setValue("statut", {
        key: parsed["statut"],
        id: parsed["statut"],
        text: constants.DEMANDE_INSCRIPTION_STRUCTURE.STATUTS.find(
          (statut: any) => statut.key == parsed["statut"]
        )?.text,
      });
      handleSubmit(actions.changeFilters)();
    }
  }, []);

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  selectedKey={value ? value.key : undefined}
                  label="Statut :"
                  placeholder="Sélectionnez un statut"
                  options={statutListSort}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={statutListSort[0]}
              control={control}
              name="statut"
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="raison_sociale"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Raison Sociale :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="siret"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="SIRET :"
                  styles={commonInputStyles.textField}
                  type="text"
                  value={value}
                  onChange={(e: any, newValue: any) => {
                    if (!newValue || newValue.length <= 14) {
                      onChange(e.target.value);
                    }
                  }}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="siren"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="SIREN :"
                  styles={commonInputStyles.textField}
                  type="text"
                  value={value}
                  onChange={(e: any, newValue: any) => {
                    if (!newValue || newValue.length <= 14) {
                      onChange(e.target.value);
                    }
                  }}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  selectedKey={value ? value.key : undefined}
                  label="Type de structure :"
                  placeholder="Sélectionnez un type"
                  options={typeStructureSort}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={typeStructureSort[0]}
              control={control}
              name="type_structure"
            />
          </Stack.Item>
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingDemandeInscriptionStructure}
                text="Rechercher"
              />
            </Stack.Item>

            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text="Réinitialiser ma recherche"
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalDemandeInscriptionStructure} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default DemandeInscriptionStructureListFilters;
