import { toast } from "react-toastify";
import InscriptionCandidaturesJuryModal from "../InscriptionJuryModal/InscriptionCandidaturesJuryModal";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";

function useJuryList() {
  const { actions: modalActions } = useCustomModal();
  //add query jurylist

  const actions = {
    onVisualize: (jury: any) => {
      toast.info("En cours de développement");
    },
    onInscrireCandidat: (jury: any) => {
      modalActions.openModal({
        header: "Inscription de candidatures au jury de certification",
        body: <InscriptionCandidaturesJuryModal jury={jury} onSaved={(jury: any) => {}} />,
        fullWidth: true,
      });
    },
  };

  return {
    actions,
  };
}

export default useJuryList;
