import { useMutation, useQueryClient } from 'react-query';
import { deleteDomaineRequest, patchActivateDomaineRequest, patchDesactivateDomaineRequest, postDomaineRequest, putDomaineRequest } from '../../services/domaineServices';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function useDomaineMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [domaineUuid, setDomaineUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null)

  const mutations = {
    PostDomaine: useMutation(postDomaineRequest,
      {
        onSuccess: (data) => {
          toast.success("Le domaine a été créé avec succès.");
          setDomaineUuid(data.uuid);
          queryClient.invalidateQueries("domaines");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
              `Une erreur est survenue lors de l'enregistrement du domaine : ${error?.response?.data?.message}`
            );
        },
      }
    ),
    PutDomaine: useMutation(putDomaineRequest, {
      onSuccess: () => {
        toast.success("Le domaine a été mis à jour avec succès.");
        queryClient.invalidateQueries("domaines");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
            `Une erreur est survenue lors de la mise à jour du domaine : ${error?.response?.data?.message}`
          );
      },
    }),
    ToggleDomaineActivate: useMutation(patchActivateDomaineRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("Le domaine a été activé avec succès");
        queryClient.invalidateQueries("domaines")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
    ToggleDomaineDesactivate: useMutation(patchDesactivateDomaineRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("Le domaine a été désactivé avec succès");
        queryClient.invalidateQueries("domaines")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
    DeleteDomaine: useMutation(deleteDomaineRequest, {
      onSuccess: () => queryClient.invalidateQueries("domaines"),
    }),
  }

  return {
    domaineUuid,
    setDomaineUuid,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    mutations
  }
}

export default useDomaineMutations