import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useInitialize from "../../hooks/useInitialize";
import Login from "../../Pages/Login/Login";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";

const RequireAuth = () => {
  const { isAuth, isLoadingGetUser } = useAuth();
  const { isInitialize } = useInitialize();

  if (isLoadingGetUser) {
    return <FullscreenLoader />;
  }
  return isAuth ? isInitialize ? <Outlet /> : <FullscreenLoader /> : <Login />;
};

export default RequireAuth;
