import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import { commonInputStyles } from "../../../styles";

function MyStructureGroupeOfForm({ reactHookProps }: any) {
  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = reactHookProps;

  return (
    <FormSection sectionTitle="Informations générales">
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Saisissez la raison sociale de la structure"
              type="text"
              {...field}
              placeholder="Raison sociale"
              errorMessage={(errors.nom as any)?.message}
              required
            />
          )}
          defaultValue=""
          control={control}
          name="nom"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Saisissez le numéro SIREN"
              type="text"
              placeholder="N° SIREN à 9 chiffres sans espace"
              errorMessage={(errors.siret as any)?.message}
              required
              disabled={true}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= 9) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={control}
          name="siren"
        />
      </Stack.Item>

      <FormAdress
        control={control}
        errors={errors}
        varToWatch={watch("code_postal") ?? false}
        required={true}
        onPrefillCityAndRegion={(data: any) => {
          setValue("ville", data.nom_commune);
          setValue("region", data.nom_region);
        }}
      />
    </FormSection>
  );
}

export default MyStructureGroupeOfForm;
