import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import Can from "../../Common/Can/Can";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import SuiviCohorteExplain from "../../Common/SuiviCohorteExplain/SuiviCohorteExplain";
import SuiviCohorteTextUpdatedAt from "../../Common/SuiviCohorteTextUpdatedAt/SuiviCohorteTextUpdatedAt";
import useTheme from "../../Common/useTheme";
import { userAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useConstants from "../../hooks/useConstants";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import SuiviCohortesListFilters from "./SuiviCohortesListFilters/SuiviCohortesListFilters";
import useSuiviCohortesList from "./useSuiviCohortesList";

function SuiviCohortesList() {
  const { branchSettings }: any = useAccessSettings();
  const { labels } = useLabelSettings();
  const { constants } = useConstants();
  const { primaryColor } = useTheme();
  const {
    suiviCohortes,
    actions: actionsPage,
    optionsFilters,
  } = useSuiviCohortesList();
  const items: any = suiviCohortes?.data || [];

  const paramBranchSuiviCohorteActif =
    tools.whichParametreSuiviCohorteActif(branchSettings);

  const columns: any = [
    {
      key: "1",
      name: "Nom de naissance",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom"),
    },
    {
      key: "2",
      name: "Nom d'usage",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom_usage}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom_usage");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom_usage"),
    },
    {
      key: "3",
      name: "Prénom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.prenom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.prenom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "Email",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.email}</Text>,
    },
    {
      key: "5",
      name: "Numéro de tel.",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.telephone_portable}</Text>,
    },
    {
      key: "6",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
    },
    {
      key: "7",
      name: "Jury",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.jury?.libelle}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("jury.libelle");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "jury.libelle"),
    },
    {
      key: "8",
      name: "Date",
      fieldName: "date_jury",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.jury?.date_jury)}</Stack>
      ),
      onColumnClick: () => {
        actionsPage.sortColumn("jury.date_jury");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "jury.date_jury"),
    },
    {
      key: "9",
      name: "Suivi 6 mois",
      fieldName: "firstSuivi",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const sixMonthSuivi = item.suivi_cohortes.find(
          (s: any) => s.mois_apres_certification == 6
        );
        return sixMonthSuivi ? (
          <Stack verticalAlign="center">
            <Text
              style={
                sixMonthSuivi.statut == 1
                  ? { color: "#667667", padding: "0 3px" }
                  : { color: "green", padding: "0 3px" }
              }
            >
              {
                (
                  tools.findIn(
                    constants.CANDIDATURES.STATUT_SUIVI_COHORTE,
                    sixMonthSuivi.statut,
                    "id"
                  ) as any
                )?.text
              }
            </Text>
            <Can
              I={[
                userAccessEnum.CREER_UTILISATEUR,
                userAccessEnum.MODIFIER_SUIVI_COHORTES,
              ]}
            >
              <Link
                onClick={() => actionsPage.openSuiviCohorteModal(sixMonthSuivi)}
                styles={commonButtonStyles.buttonLink}
              >
                {true ? "Renseigner le suivi" : "Modifier le suivi"}
              </Link>
            </Can>
          </Stack>
        ) : null;
      },
    },
    {
      key: "10",
      name: "Suivi 24 mois",
      fieldName: "secondSuivi",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const twentyFourSuivi = item.suivi_cohortes.find(
          (s: any) => s.mois_apres_certification == 24
        );
        return twentyFourSuivi ? (
          <Stack verticalAlign="center">
            <Text
              style={
                twentyFourSuivi.statut == 1
                  ? { color: "#667667", padding: "0 3px" }
                  : { color: "green", padding: "0 3px" }
              }
            >
              {
                (
                  tools.findIn(
                    constants.CANDIDATURES.STATUT_SUIVI_COHORTE,
                    twentyFourSuivi.statut,
                    "id"
                  ) as any
                )?.text
              }
            </Text>
            <Can
              I={[
                userAccessEnum.CREER_UTILISATEUR,
                userAccessEnum.MODIFIER_SUIVI_COHORTES,
              ]}
            >
              <Link
                onClick={() =>
                  actionsPage.openSuiviCohorteModal(twentyFourSuivi)
                }
                styles={commonButtonStyles.buttonLink}
              >
                {true ? "Renseigner le suivi" : "Modifier le suivi"}
              </Link>
            </Can>
          </Stack>
        ) : null;
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion du suivi des cohortes"
        explain={
          <SuiviCohorteExplain
            paramBrancheSuiviCohorteActif={paramBranchSuiviCohorteActif}
          />
        }
      >
        <SuiviCohorteTextUpdatedAt
          paramBrancheSuiviCohorteActif={paramBranchSuiviCohorteActif}
        />

        <Stack horizontal wrap verticalAlign="center" style={{ marginTop: 25 }}>
          <Can I={userAccessEnum.EXPORTER_SUIVI_COHORTES_FRANCE_COMPETENCES}>
            <DefaultButton
              type="button"
              text="Exporter le suivi des cohortes"
              styles={commonButtonStyles.defaultButton}
              style={{ color: primaryColor, borderColor: primaryColor }}
              onClick={actionsPage.openExportCohorteModal}
            />
          </Can>
        </Stack>

        <SuiviCohortesListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={suiviCohortes?.meta.current_page || 1}
          totalPages={Math.ceil(suiviCohortes?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default SuiviCohortesList;
