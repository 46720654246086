import { useQuery } from "react-query";
import { getHabilitationsOfDetailRequest } from "../../services/habilitationServices";

function useHabilitationOfQuery({ ofProps, optionsFilters }: any) {
  const {
    data: habilitations,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ["habilitations", ofProps.ofUuid, optionsFilters],
    () => getHabilitationsOfDetailRequest(ofProps.ofUuid, optionsFilters),
    {
      enabled: !!ofProps.ofUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    habilitations,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useHabilitationOfQuery;
