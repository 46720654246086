const domaineAdapters = {
    transformForAPI: (data: any) => {

        let transformed: any = {
            ...data,
            ofs:data.ofs?.map((of:any)=>of.uuid) || [],
            certifications:data.certifications?.map((certification:any)=>certification.uuid),
            evaluateur:data.evaluateur?.uuid,
        }

        return transformed
    },
    transformForBO: (data: any) => {

        let transformed: any = {
            ...data,
        }

        return transformed
    },
}

export default domaineAdapters;