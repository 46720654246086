const subGroupCompanyAdapters: any = {
  transformForAPI: (data: any) => {
    const transformed: any = {
      sous_groupe: {
        nom: data.nom,
        type: "SOUS_GROUPE_ENTREPRISE",
        groupe: {
          type: 22,
          id: data.groupeEntreprise.uuid,
        },
        entites: data.entreprises.map((ent: any) => ({
          id: ent.uuid,
          type: 10,
        })),
      },
      utilisateurs: data.utilisateurs
        ?.filter((user: any) => user.isNew)
        .map((user: any) => ({
          ...user,
          profils: [
            {
              profil: "SOUS_GROUPE_ENTREPRISE",
              sous_groupe: {
                type: "SOUS_GROUPE_ENTREPRISE",
                nom: data.nom,
                groupe: {
                  type: 22,
                  id: data.groupeEntreprise.uuid,
                },
              },
            },
          ],
        })),
    };

    if (data.uuid) {
      transformed.sous_groupe.uuid = data.uuid;
      transformed.utilisateurs = data.utilisateurs
        ?.filter((user: any) => user.isNew)
        .map((user: any) => ({
          ...user,
          profils: [
            {
              profil: "SOUS_GROUPE_ENTREPRISE",
              sous_groupe: {
                type: "SOUS_GROUPE_ENTREPRISE",
                uuid: data.uuid, // need to be added to avoid new sous groupe
                nom: data.nom,
                groupe: {
                  type: 22,
                  id: data.groupeEntreprise.uuid,
                },
              },
            },
          ],
        }));
    }

    return transformed;
  },
  transformForBO: (data: any) => {
    const transformed: any = {
      ...data,
      groupeEntreprise: {
        raison_sociale: data.attributes?.groupe?.raison_sociale,
        siren: data.attributes?.groupe?.siren,
        uuid: data.attributes?.groupe?.uuid,
      },
      entreprises: data.attributes?.entites?.map((entreprise: any) => {
        return {
          nom: entreprise?.nom,
          siret: entreprise?.siret,
          uuid: entreprise?.uuid,
        };
      }),
      utilisateurs: data.attributes?.utilisateurs.map((user: any) => ({
        ...user,
        isNew: false,
      })),
    };
    return transformed;
  },
};

export default subGroupCompanyAdapters;
