import { Checkbox, Label } from "@fluentui/react";
import { commonInputStyles } from "../../styles";

const CheckboxListInput = ({
  required=false,
  items,
  onChange,
  label,
  name,
  itemIndex,
  itemLabel,
  value = [],
}: any) => {
  const onChangeList = (e: any) => {
    const { checked, name: itemValue } = e.target;
    const itemRef = items.find((it: any) => it[itemIndex] == itemValue);
    onChange({
      target: {
        name,
        value: checked
          ? [...value, itemRef]
          : value.filter((v: any) => v[itemIndex] !== itemRef[itemIndex]),
      },
    });
  };

  return (
    <div>
      <Label styles={commonInputStyles.label} required={required}>{label}</Label>
      {items.map((item: any) => (
        <div style={{ marginBottom: 3 }}>
          <Checkbox
            label={item[itemLabel]}
            name={item[itemIndex]}
            onChange={onChangeList}
            defaultChecked={value?.find((it: any) => it[itemIndex] == item[itemIndex])}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckboxListInput;
