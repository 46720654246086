import { Stack } from "@fluentui/react";

function ExportAsyncMessage() {
  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <p>
        L'exportation de vos données est en cours et prendra du temps. Vous
        obtiendrez un lien de téléchargement du fichier d'export par email une
        fois qu'il sera prêt.
      </p>
    </Stack>
  );
}

export default ExportAsyncMessage;
