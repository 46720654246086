import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import useAuth from "./useAuth";

function useInfoProfilUser() {
  const { auth } = useAuth();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const isSuperAdmin = auth.user.est_administrateur;

  const isAdmin =
    auth.user.est_administrateur ||
    userSelectedBranch?.profils?.includes("ADMINISTRATEUR_BRANCHE");

  const isAnyKindOfAdmin =
    auth.user.est_administrateur ||
    userSelectedBranch?.profils?.includes("ADMINISTRATEUR_BRANCHE") ||
    userSelectedBranch?.profils?.includes("ADMINISTRATEUR_DOMAINE");

  return { isSuperAdmin, isAdmin, isAnyKindOfAdmin };
}

export default useInfoProfilUser;
