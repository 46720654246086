import { DefaultButton, MessageBar, Stack } from "@fluentui/react";
import { useContext } from "react";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import useTheme from "../../../../Common/useTheme";
import { MessageBarStyles, commonButtonStyles } from "../../../../styles";
import FormationList from "../FormationList/FormationList";
import { ParcoursCandidatContext } from "../useParcoursCandidat";

function FormationInitiale({
  applicableEditPermission,
  isEditMode = false,
}: any) {
  const { actions, reactHookProps, inputsDisabled }: any = useContext(
    ParcoursCandidatContext
  );
  const { watch } = reactHookProps;
  const { primaryColor } = useTheme();

  return (
    <FormSection sectionTitle="Formation initiale">
      <Can I={[applicableEditPermission]}>
        <Stack.Item>
          <DefaultButton
            onClick={() => actions.openAddFormation(null, true)}
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
          >
            Ajouter une formation initiale
          </DefaultButton>
        </Stack.Item>
      </Can>

      {watch("formations_initiales")?.length > 0 ? (
        <FormationList
          formations={watch("formations_initiales") || []}
          isInitial={true}
          openFormation={actions.openAddFormation}
        />
      ) : (
        <MessageBar styles={MessageBarStyles}>Aucune entrée</MessageBar>
      )}
    </FormSection>
  );
}

export default FormationInitiale;
