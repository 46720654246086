import {
  DefaultSpacing,
  Dropdown,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import queryString from "query-string";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import FileInput from "../../Common/FileInput/FileInput";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import useConstants from "../../hooks/useConstants";
import { commonInputStyles } from "../../styles";
import useContactAdmin from "./useContactAdmin";

export enum MotifDemandeAide {
  MOTIF_DEMANDER_HABILITATION_OF = 11,
}

function ContactAdmin() {
  const { id } = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const {
    constants: { CONTACT },
  } = useConstants();
  const motifOptions = CONTACT.MOTIFS_DEMANDE_AIDE_ESPACE_UTILISATEUR?.map(
    (e: any) => ({
      ...e,
      key: e.id,
    })
  );
  const {
    actions,
    branchProps,
    isLoading,
    reactHookProps: {
      handleSubmit,
      formState: { errors },
      watch,
      control,
      setValue,
    },
    isDescription,
    description,
  } = useContactAdmin();
  const { actions: branchActions } = branchProps;

  useEffect(() => {
    if (id !== "creer") {
      branchActions.setUpdateBranchId(id);
    }
  }, [id]);

  useEffect(() => {
    const initPage = async () => {
      await actions.initializeDescription();
    };
    initPage();

    if (parsed?.motif) {
      setValue("motif", Number(parsed.motif));
    }
  }, []);

  const watchAutre = watch("motif");

  return (
    <Page
      title="Demande d'aide"
      explain={
        "Veuillez remplir le formulaire et envoyer votre demande d'aide à votre administrateur de branche"
      }
    >
      <Stack styles={{ root: { paddingTop: DefaultSpacing.l1 } }}>
        <Stack style={{ width: "80%" }} tokens={{ childrenGap: 15 }}>
          {isDescription ? (
            <div
              dangerouslySetInnerHTML={{
                __html: `${(description as any).contenu}`,
              }}
            />
          ) : (
            <Loader />
          )}
        </Stack>
        <form onSubmit={handleSubmit(actions.submitForm)}>
          <Stack
            styles={{ root: { marginTop: DefaultSpacing.l2 } }}
            tokens={{ childrenGap: 20 }}
          >
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-col ms-sm16 ms-md6">
                <Controller
                  render={({ field }) => (
                    <Dropdown
                      options={motifOptions}
                      styles={commonInputStyles.dropdownStyle}
                      selectedKey={field.value}
                      {...field}
                      onChange={(_e, option) => {
                        field.onChange(option?.key);
                      }}
                      required
                      label="Motifs de demande d'aide:"
                      errorMessage={(errors.motif as any)?.message}
                    />
                  )}
                  control={control}
                  defaultValue={1}
                  name="motif"
                />
              </div>
              {watchAutre ===
                motifOptions.find((e: any) => e.text === "Autre").key && (
                <div className="ms-Grid-col ms-sm16 ms-md6">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        styles={commonInputStyles.textField}
                        required
                        label="Précisez le motif :"
                        type="text"
                        placeholder="Mon autre motif"
                        errorMessage={(errors[field.name] as any)?.message}
                        {...field}
                      />
                    )}
                    defaultValue=""
                    control={control}
                    name="autre_motif"
                  />
                </div>
              )}
            </div>
            <Stack>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    required
                    label="Veuillez préciser votre besoin :"
                    placeholder="3000 caractères max"
                    multiline
                    rows={6}
                    maxLength={3000}
                    {...field}
                  />
                )}
                defaultValue=""
                control={control}
                name="besoin"
              />
            </Stack>
            <Label>Ajouter un ou plusieurs fichiers à votre demande :</Label>
            {/* <Controller
              name="files"
              control={control}
              render={({ field: { value, name } }) => (
                <input
                  type="file"
                  name={name}
                  multiple
                  onChange={(e) => setValue(name, e.target.files)}
                />
              )}
            /> */}

            <FileInput
              name="files"
              file={watch("files") ? watch("files") : []}
              onUpdateFile={(e: any) => {
                setValue("files", e.target.value);
              }}
              acceptedFileTypes={["PNG", "JPG", "JPEG"]}
              multipleFiles
            />
            <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
              <LoadButton
                text={"Envoyer la demande d'aide"}
                isLoading={isLoading}
              />
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Page>
  );
}

export default ContactAdmin;
