import { Stack } from "@fluentui/react";
import { ReactNode } from "react";
import useTheme from "../useTheme";
import formSectionStyles from "./FormSection.styles";
type FormSectionProps = {
  children: ReactNode;
  sectionTitle: string;
  className?: string;
  noBorder?: any;
};
function FormSection({ children, sectionTitle, className = "" }: FormSectionProps) {
  const { primaryColor } = useTheme();

  return (
    <fieldset
      className={className}
      style={{
        ...formSectionStyles.container.root,
        borderColor: primaryColor,
      }}
    >
      <legend style={{ ...formSectionStyles.sectionTitle.root, color: primaryColor}}>
        {sectionTitle}
      </legend>
      <Stack styles={formSectionStyles.formContainer} tokens={{ childrenGap: 15 }}>
        {children}
      </Stack>
    </fieldset>
  );
}

export default FormSection;
