import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { stateList, userStateList } from "../../../fakeData/State.data";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import useGroupCompanyList from "../useGroupCompanyList";

function GroupeCompanyFilters() {
  const { groupCompanies, actions, isLoadingGroupCompanies, reactHookProps } =
    useGroupCompanyList();

  const totalGroupCompanies = groupCompanies?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontal
          horizontalAlign="start"
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="raison_sociale"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Raison Sociale :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="siren"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="SIREN :"
                  styles={commonInputStyles.textField}
                  type="text"
                  value={value}
                  onChange={(e: any, newValue: any) => {
                    if (!newValue || newValue.length <= 9) {
                      onChange(e.target.value);
                    }
                  }}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Groupe d'entreprise actif :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionner un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="actif"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Groupe d'entreprise multibranche :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="Sélectionner un état"
                  options={userStateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={userStateList[0]}
              control={control}
              name="interbranche"
            />
          </Stack.Item>
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingGroupCompanies}
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalGroupCompanies} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default GroupeCompanyFilters;
