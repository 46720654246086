import { axiosInstance } from "../config/axiosConfig";
import { IS_OFFLINE, OFFLINE_ROUTE, getOfflineStorage } from "../config/offlineConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const dynamicFieldsURI = "administration/champs-complementaires";

export const getDynamicFiedsRequest = async () => {
  const url: string = `${dynamicFieldsURI}/lister`;
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);

  const response = await eCertifApi.get(url);
  localStorage.setItem("dynamicFields", JSON.stringify(response.data.data));
  return response.data.data;
};

export const postDynamicFiedsRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${dynamicFieldsURI}/creer`, data, xsrfHeader);

  return response.data;
};

export const putDynamicFiedsRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${dynamicFieldsURI}/${data.get("uuid")}`,
    data,
    xsrfHeader
  );

  return response.data;
};

export const patchActivateDynamicFieldRequest = async (dynamicFieldUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${dynamicFieldsURI}/${dynamicFieldUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateDynamicFieldRequest = async (dynamicFieldUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${dynamicFieldsURI}/${dynamicFieldUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};
