//DELETE quand tous les entité auront la meme logique pour les ADRESSES
const groupOfAdapters = {
    transformForAPI: (data: any) => {

        let transformed: any = {
            ...data,
            adresse: {
                uuid:data.adresse_uuid,
                adresse: data.adresse,
                complement_adresse: data.complement_adresse,
                code_postal: data.code_postal,
                region: data.region,
                ville: data.ville,
            },
            ofs:data.ofs?.map((item: any) => item.uuid),
        }

        return transformed
    },
    transformForBO: (data: any) => {

        let transformed: any = {
            ...data,
            adresse_uuid: data.adresse.uuid,
            adresse: data.adresse.adresse,
            complement_adresse: data.adresse.complement_adresse,
            code_postal: data.adresse.code_postal,
            region: data.adresse.region,
            ville: data.adresse.ville,
        }

        return transformed
    }
}

export default groupOfAdapters;