import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useDemandeInscriptionStructure from "../../hooks/demandeInscriptionStructure/useDemandeInscriptionStructure";
import { getDemandeInscriptionsStructureRequest } from "../../services/demandeInscriptionServices";
import tools from "../../utils/tools";
import RefuserInscriptionModal from "../DemandeInscriptionList/RefuserInscriptionModal/RefuserInscriptionModal";
import ValiderDemandeIncriptionStructureMasseModal from "./ValiderDemandeIncriptionStructureMasseModal/ValiderDemandeIncriptionStructureMasseModal";

export const initialFilters = {
  statut: { id: undefined, text: "Tous les statuts", key: "all" },
  raison_sociale: "",
  siret: "",
  siren: "",
  type_structure: { id: undefined, text: "Tous les types", key: "all" },
};

export const filtersAtom = atomWithReset<any>(initialFilters);
export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useDemandeInscriptionStructureList() {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const { actions: modalActions } = useCustomModal();

  const demandeInscriptionStructureProps: any =
    useDemandeInscriptionStructure();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const {
    data: demandeInscriptionStructure,
    isLoading: isLoadingDemandeInscriptionStructure,
  } = useQuery(
    ["demandeInscriptionStructure", optionsFilters, userSelectedBranch],
    () => getDemandeInscriptionsStructureRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    openRefuserDemandeInscriptionStructure: (structure: any) => {
      modalActions.openModal({
        header: `Inscription structure refusée : ${structure.raison_sociale}`,
        body: (
          <RefuserInscriptionModal
            onRefuserInscription={(commentaire: any) =>
              demandeInscriptionStructureProps.actions.refuserDemandeInscription(
                structure,
                commentaire
              )
            }
          />
        ),
      });
    },
    openValiderDemandeInscriptionStructureEnMasse: (structures: any[]) => {
      const demandeInscriptionCanBeValidated = structures.filter(
        (user: any) => user.statut == "En attente"
      );
      modalActions.openModal({
        header: `Inscriptions valider en masse`,
        body: (
          <ValiderDemandeIncriptionStructureMasseModal
            structures={demandeInscriptionCanBeValidated}
            onValiderInscriptions={() =>
              demandeInscriptionStructureProps.actions.validerDemandeInscriptionStructureEnMasse(
                demandeInscriptionCanBeValidated
              )
            }
          />
        ),
      });
    },
  };
  return {
    demandeInscriptionStructureProps,
    reactHookProps,
    actions,
    demandeInscriptionStructure,
    isLoadingDemandeInscriptionStructure,
    optionsFilters,
  };
}

export default useDemandeInscriptionStructureList;
