import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useDemandeInscription from "../../hooks/demandeInscription/useDemandeInscription";
import { getUsersDemandeInscriptionRequest } from "../../services/userServices";
import tools from "../../utils/tools";
import RefuserInscriptionModal from "./RefuserInscriptionModal/RefuserInscriptionModal";
import AccepterIncriptionMasseModal from "./ValiderIncriptionMasseModal/ValiderIncriptionMasseModal";
import ValiderIncriptionMasseModal from "./ValiderIncriptionMasseModal/ValiderIncriptionMasseModal";

export const initialFilters = {
  statut: { id: undefined, text: "Tous les statuts", key: "all" },
  nom: "",
  prenom: "",
  email: "",
  profil: { id: undefined, text: "Tous les rôles", key: "all" },
  raison_sociale: "",
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: 1,
  searchAt: "",
});
function useDemandeInscriptionList() {
  const userProps = useDemandeInscription();
  const { actions: modalActions } = useCustomModal();

  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: usersProfils, isLoading: isLoadingUsers } = useQuery(
    ["usersInscription", optionsFilters, userProps.userSelectedBranch],
    () => getUsersDemandeInscriptionRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    openRefuserInscription: (user: any) => {
      modalActions.openModal({
        header: `Inscription refusée : ${user.nom} ${user.prenom}`,
        body: (
          <RefuserInscriptionModal
            user={user}
            onRefuserInscription={(commentaire: any) =>
              userProps.actions.refuserInscription(user, commentaire)
            }
          />
        ),
      });
    },
    openValiderInscriptionEnMasse: (items: any) => {
      const demandeInscriptionCanBeValidated = items.filter(
        (user: any) => user.statut == "En attente"
      );
      modalActions.openModal({
        header: `Inscriptions valider en masse`,
        body: (
          <ValiderIncriptionMasseModal
            users={demandeInscriptionCanBeValidated}
            onValiderInscriptions={() =>
              userProps.actions.validerInscriptionEnMasse(
                demandeInscriptionCanBeValidated
              )
            }
          />
        ),
      });
    },
  };

  return {
    reactHookProps,
    usersProfils,
    userProps,
    isLoadingUsers,
    optionsFilters,
    actions,
  };
}

export default useDemandeInscriptionList;
