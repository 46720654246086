import { useAtom } from "jotai";
import React from "react";
import { isOfflineAtom } from "../../hooks/useNav";

const OfflineBanner = () => {
  const [isOffline] = useAtom(isOfflineAtom);

  if (!isOffline) return null;
  return (
    <div
      style={{
        width: "100%",
        background: "#f6dfdf",
        color: "red",
        fontSize: "12px",
        fontWeight: "bold",
        padding: "5px",
        textAlign: "right",
      }}
    >
      Vous êtes en mode hors ligne, ne rechargez pas la page sinon vous perdrez l'accès à
      l'application.
    </div>
  );
};

export default OfflineBanner;
