import { DefaultButton, Stack } from "@fluentui/react";
import Can from "../../../../Common/Can/Can";
import CancelButton from "../../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import Loader from "../../../../Common/Loader/Loader";
import {
  branchAccessEnum,
  userAccessEnum,
} from "../../../../config/accessEnum";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import useInfoProfilUser from "../../../../hooks/useInfoProfilUser";
import { commonButtonStyles } from "../../../../styles";
import BoutonEnregistrerMEC from "../../Common/MiseEnConformite/BoutonEnregistrerMEC";
import OptionsBlocsSection from "../../Common/OptionsBlocsSection";
import { statutAvisFormateurEtEvaluation } from "../AvisFormateurEtEvaluation";
import BoutonDemanderMEC from "../../Common/MiseEnConformite/BoutonDemanderMEC";

function EvaluationForm({
  candidature,
  fetchCandidature,
  reactHookProps,
  pageActions,
  mecActions,
  accessActions,
  isOffline,
  isOpenedInModal,
  isEditMode,
  isSaving,
  conditionnalDisplayVariable,
  applicableEditPermissionEvaluation,
  inputsDisabledEvaluation,
  inputsDisabledSecondeEvaluation,
}: any) {
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  const isSecondEvaluateurInvited = accessActions.canI({
    action: [branchAccessEnum.OBLIGER_AVIS_SECOND_EVALUATEUR],
  }).granted
    ? candidature.second_evaluateur?.uuid
    : true;

  const onSubmitEvaluation = (
    e: any,
    isEnregistrerEvaluation: boolean = false
  ) => {
    if (isEditMode === StatutCandidature.EVALUATION) {
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) => {
        const evaluationObject = {
          ...data,
          evaluation: data.blocs_competences_passes.map((b: any) => ({
            uuid: b.candidature_bloc_competence_uuid,
            decision: b.decision_evaluation,
            commentaire_evaluation: b.commentaire_evaluation,
            competences_associees: b.competences.map((c: any) => ({
              uuid: c.uuid,
              decision: c.decision_evaluation,
              criteres_evaluation: accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      libelle: l.libelle,
                      decision: l.decision_evaluation?.toString(),
                    }))
                  : null //waiting api
                : null,
            })),
          })),
        };
        mecActions.onSaveMEC(evaluationObject);
      })(e);
    } else {
      if (
        !isEnregistrerEvaluation &&
        !isOffline &&
        !window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        return false;
      }
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) =>
        pageActions.onSaveEvaluation(data, isEnregistrerEvaluation)
      )(e);
    }
  };

  return (
    <Stack>
      {!isOffline && (
        <Stack>
          {inputsDisabledEvaluation &&
            (conditionnalDisplayVariable.isEvaluateurThisCandidature ||
              isAnyKindOfAdmin) && (
              <Can I={userAccessEnum.SAISIR_EVALUATION}>
                <BoutonDemanderMEC
                  text="Corriger l'évaluation"
                  demanderMECText="Demander une mise en conformité de l'évaluation"
                  statut={StatutCandidature.EVALUATION}
                  statut_candidature={candidature.statut}
                  onAfterValidate={fetchCandidature.refrechCandidature}
                  createurUuid={candidature.createur?.uuid}
                />
              </Can>
            )}

          {inputsDisabledSecondeEvaluation &&
            conditionnalDisplayVariable.isSecondEvaluateurThisCandidature && (
              <BoutonDemanderMEC
                text="Corriger la seconde évaluation"
                demanderMECText="Demander une mise en conformité de la seconde évaluation"
                statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
                statut_candidature={candidature.statut}
                onAfterValidate={fetchCandidature.refrechCandidature}
                createurUuid={candidature.createur?.uuid}
              />
            )}
        </Stack>
      )}
      <form>
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={inputsDisabledEvaluation}
          onValidate={pageActions.onValidateEvaluvation}
          onInvalidate={pageActions.onInvalidateEvaluvation}
        />

        <DynamicFieldForm
          statut={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={inputsDisabledEvaluation}
        />
      </form>
      {isOffline ? (
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          {conditionnalDisplayVariable.isEvaluateurThisCandidature &&
            !isOpenedInModal && <CancelButton text={"Retour"} />}

          {(conditionnalDisplayVariable.isEvaluateurThisCandidature ||
            isAnyKindOfAdmin) &&
            isSecondEvaluateurInvited && (
              <LoadButton
                text={"Valider l'évaluation"}
                isLoading={isSaving}
                type="button"
                onClick={(e: any) => onSubmitEvaluation(e, false)}
              />
            )}
        </Stack>
      ) : (
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
          wrap
        >
          {!isOpenedInModal && <CancelButton text="Retour" />}

          {reactHookProps.watch("second_evaluateur") &&
            statutAvisFormateurEtEvaluation.includes(candidature.statut) && (
              <Can I={userAccessEnum.INVITER_SECOND_EVALUATEUR}>
                {isSaving ? (
                  <Loader />
                ) : (
                  <DefaultButton
                    styles={commonButtonStyles.defaultButton}
                    onClick={pageActions.onCancelInvitationSecondEvaluateur}
                  >
                    Annuler l'invitation du second évaluateur
                  </DefaultButton>
                )}
              </Can>
            )}

          <Can I={applicableEditPermissionEvaluation}>
            <>
              {(conditionnalDisplayVariable.isEvaluateurThisCandidature ||
                isAnyKindOfAdmin) &&
                isSecondEvaluateurInvited && (
                  <>
                    <LoadButton
                      text={"Enregistrer l'évaluation"}
                      type="button"
                      onClick={(e: any) => onSubmitEvaluation(e, true)}
                      isLoading={isSaving}
                    />
                    <LoadButton
                      text={"Valider l'évaluation"}
                      isLoading={isSaving}
                      type="button"
                      onClick={(e: any) => onSubmitEvaluation(e, false)}
                    />
                  </>
                )}
            </>
          </Can>

          {isEditMode === StatutCandidature.EVALUATION && (
            <BoutonEnregistrerMEC
              onClick={(e: any) => onSubmitEvaluation(e, false)}
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
              typeBtn="button"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default EvaluationForm;
