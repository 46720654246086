import {
  Checkbox,
  DefaultButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import useTheme from "../../../../Common/useTheme";
import { branchAccessEnum } from "../../../../config/accessEnum";
import { API_URL } from "../../../../config/config";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import { PartiesPrenantesContext } from "../usePartiesPrenantes";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";

const SelectEntrepriseFormSection = ({ disabled }: any) => {
  const { actions, reactHookProps }: any = useContext(PartiesPrenantesContext);
  const { primaryColor } = useTheme();
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = reactHookProps;
  const { actions: accessActions } = useAccessSettings();

  return (
    <FormSection sectionTitle="Entreprise liée à la candidature">
      {watch("non_rattache_entreprise") &&
        !accessActions.canI({
          action:
            branchAccessEnum.PARTIES_PRENANTES_RENDRE_ENTREPRISE_FACULTATIVE,
        }).granted && (
          <Stack.Item>
            <Text>La candidature a été créée sans entreprise</Text>
          </Stack.Item>
        )}

      {(!watch("non_rattache_entreprise") ?? true) && (
        <>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Entreprise liée à la candidature :"
                placeholder="Recherchez une entreprise par SIRET ou Raison sociale (ex : 12312312312312 ou enterprise1)"
                resourceURI={`${API_URL}api/administration/entreprises/chercher-pour-candidature`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.nom}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.nom}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                errorMessage={(errors[name] as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                required
                disabled={disabled}
              />
            )}
            control={control}
            name="entreprise_liee"
          />
          {!disabled && (
            <Can
              I={
                branchAccessEnum.AUTORISER_OF_RENSEIGNER_ENTREPRISE_A_L_INSCRIPTION
              }
            >
              <Stack horizontal>
                <DefaultButton
                  styles={commonButtonStyles.defaultButton}
                  style={{ color: primaryColor, borderColor: primaryColor }}
                  text="Je ne trouve pas l'entreprise liée au candidat"
                  onClick={() => actions.openEntrepriseFormModal()}
                />
              </Stack>
            </Can>
          )}
        </>
      )}

      <Can I={branchAccessEnum.PARTIES_PRENANTES_RENDRE_ENTREPRISE_FACULTATIVE}>
        <Controller
          defaultValue={false}
          name="non_rattache_entreprise"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              styles={commonInputStyles.checkbox}
              label="Le candidat n'a pas d'entreprise"
              checked={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </Can>
    </FormSection>
  );
};

export default SelectEntrepriseFormSection;
