import { Dropdown, Label, Stack } from "@fluentui/react";
import FormSection from "../../../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../../../styles";
import useUpdateGenerateDocuments from "./useUpdateGenerateDocuments";
import FileInput from "../../../../../Common/FileInput/FileInput";
import LoadButton from "../../../../../Common/LoadButton/LoadButton";

export const documentForCandidatureValideEnum = {
  PARCHEMIN: "PARCHEMIN",
  PARCHEMIN_DUPLICATA: "PARCHEMIN_DUPLICATA",
  PARCHEMIN_CERTIFICATION_DELIVREE: "PARCHEMIN_CERTIFICATION_DELIVREE",
  PARCHEMIN_CERTIFICATION_DELIVREE_DUPLICATA:
    "PARCHEMIN_CERTIFICATION_DELIVREE_DUPLICATA",
};

export const documentCertificationDeliverEnum = {
  PARCHEMIN_CERTIFICATION_DELIVREE: "PARCHEMIN_CERTIFICATION_DELIVREE",
  PARCHEMIN_CERTIFICATION_DELIVREE_DUPLICATA:
    "PARCHEMIN_CERTIFICATION_DELIVREE_DUPLICATA",
};

function UpdateGenerateDocuments({ candidature }: any) {
  const {
    documentsCanBeUpload,
    reactHookProps,
    actions,
    isLoading,
    isSuperAdmin,
  } = useUpdateGenerateDocuments();
  const { setValue, control, watch, handleSubmit, register } = reactHookProps;

  if (!isSuperAdmin) {
    return null;
  }

  const typeThaTChooseCertificationDelivered = [3, 6];
  var documentsOptions = documentsCanBeUpload.filter(
    (d: any) => d.key != "select"
  );
  if (candidature.resultat != "VALIDE") {
    const documentsIdForCandidatureValide = Object.keys(
      documentForCandidatureValideEnum
    ).map((v) => v);

    documentsOptions = documentsOptions.filter(
      (d: any) => !documentsIdForCandidatureValide.includes(d.id)
    );
  } else {
    if (
      !typeThaTChooseCertificationDelivered.includes(
        candidature?.certification.type
      )
    ) {
      const documentsIdCertificationDeliver = Object.keys(
        documentCertificationDeliverEnum
      ).map((v) => v);

      documentsOptions = documentsOptions.filter(
        (d: any) => !documentsIdCertificationDeliver.includes(d.id)
      );
    }
  }

  const onSubmit = (e: any) => {
    handleSubmit(actions.onSubmit)(e);
  };

  return (
    <form onSubmit={onSubmit}>
      <FormSection sectionTitle="Dépôt de document">
        <input
          type="hidden"
          {...register("_method", {
            value: "PATCH",
          })}
        />

        <Stack.Item>
          <Controller
            render={({ field: { name, value } }) => (
              <Dropdown
                selectedKey={value ? (value as any).key : undefined}
                label="Type de document :"
                placeholder="Sélectionnez un type"
                options={documentsOptions}
                calloutProps={{ doNotLayer: true }}
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                styles={commonInputStyles.dropdownStyle}
              />
            )}
            defaultValue={documentsCanBeUpload[0]}
            control={control}
            name="type"
          />
        </Stack.Item>

        <Stack.Item>
          <Controller
            control={control}
            name="document"
            render={({ field: { onChange, name, value } }) => (
              <>
                <Label styles={commonInputStyles.label}>Document :</Label>
                <FileInput
                  name={name}
                  file={watch(name) ? watch(name) : []}
                  onUpdateFile={(e: any) => {
                    setValue(name, e.target.value);
                  }}
                  onDeleteFile={() => setValue(name, [])}
                  acceptedFileTypes={["PDF"]}
                />
              </>
            )}
          />
        </Stack.Item>

        <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
          <LoadButton
            text={"Valider"}
            isLoading={isLoading}
            style={{ fontWeight: "bold" }}
          />
        </Stack>
      </FormSection>
    </form>
  );
}

export default UpdateGenerateDocuments;
