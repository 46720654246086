const InfoMessage = ({ infoMessage }: any) => {
    return infoMessage ? (
      <p
        className="ms-TextField-errorMessage"
        style={{ color: "rgb(204, 102, 0)", fontSize: 12, margin: 0, paddingTop: 5 }}
      >
        {infoMessage}
      </p>
    ) : null;
  };
  
  export default InfoMessage;
  