import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import { candidaturesListAtom } from "../Pages/CandidatJuryList/useCandidatureJuryList";
import { useState } from "react";
import { getDocumentsJuryCandidats } from "../services/juryServices";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

function useGenerateCandidatureDocumentsJury() {
  const [userSelectedBranch]: any = useAtom(userSelectedBranchAtom);
  const [candidaturesList]: any = useAtom(candidaturesListAtom);
  const [isLoadingFileKey, setIsLoadingFileKey]: any = useState(null);
  const [downloadedFileKeys, setDownloadedFileKeys]: any = useState([]);

  const actions = {
    onDownloadDocument: async (
      jury_uuid: any,
      document: any,
      groupage: any
    ) => {
      setIsLoadingFileKey(document.key);
      const candidaturesUuid = candidaturesList.map((c: any) => c.uuid);
      try {
        const documentObjectRequest = {
          branch_id: userSelectedBranch.id,
          jury_associe: jury_uuid,
          type_document: document.id,
          candidatures: candidaturesUuid,
          groupage,
        };
        const response = await getDocumentsJuryCandidats(
          documentObjectRequest
        ).catch((e: any) => {
          toast.error("Votre demande ne contient aucun document");
        });
        if (response.headers["content-disposition"]) {
          const filename = response.headers["content-disposition"]
            .split("=")[1]
            .replace(/["]/g, "");
          if (filename) {
            fileDownload(response.data, filename);
          }
        } else {
          toast.success(
            "La génération du document est en cours, un lien de téléchargement vous sera envoyé par email dans quelques instants."
          );
        }
        setDownloadedFileKeys((u: any) => [...u, document.key]);
        setIsLoadingFileKey(null);
      } catch (e: any) {
        console.log(e);
        setIsLoadingFileKey(null);
      }
    },
  };

  return { actions, isLoadingFileKey, downloadedFileKeys };
}

export default useGenerateCandidatureDocumentsJury;
