import { axiosInstance } from "../config/axiosConfig";
import { IS_OFFLINE, OFFLINE_ROUTE, getOfflineStorage } from "../config/offlineConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const labelSettingsURI = "administration/libelles";

export const getLabelSettingsRequest = async () => {
  const url: string = `${labelSettingsURI}/lister`;
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(`${labelSettingsURI}/lister`);
  localStorage.setItem("libelles", JSON.stringify(response.data));
  return response.data;
};

export const putLabelSettingsRequest = async (type: any, data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${labelSettingsURI}`,
    { type: type, valeur: data, _method: "PUT" },
    xsrfHeader
  );
  return response.data;
};
