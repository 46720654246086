import { axiosInstance } from "../config/axiosConfig";

const useDownloadFile = () => {
  const downloadFile = async (fileURL: string) => {
    return await axiosInstance.get(fileURL, { responseType: "blob" });
  };

  return downloadFile;
};

export default useDownloadFile;
