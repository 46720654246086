import { Stack, TextField } from "@fluentui/react";
import { DateTime } from "luxon";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import CancelButton from "../../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import FormSection from "../../../../Common/FormSection/FormSection";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import { userAccessEnum } from "../../../../config/accessEnum";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../../hooks/candidature/useCandidatureWorkflow";
import { commonInputStyles } from "../../../../styles";
import BoutonEnregistrerMEC from "../../Common/MiseEnConformite/BoutonEnregistrerMEC";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";

function SecondeEvaluationForm({
  candidature,
  fetchCandidature,
  reactHookProps,
  pageActions,
  mecActions,
  accessActions,
  isOffline,
  isOpenedInModal,
  isEditMode,
  isSaving,
  conditionnalDisplayVariable,
  applicableEditSecondeEvaluationPermissions,
  inputsDisabledSecondeEvaluation,
}: any) {
  const { labels } = useLabelSettings();

  const onSubmitSecondEvaluation = (e: any) => {
    if (isEditMode === StatutCandidature.AVIS_SECOND_EVALUATEUR) {
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit((data: any) => {
        const secondEvaluationObject = {
          ...data,
          statut_a_modifier: "avis_second_evaluateur",
        };
        mecActions.onSaveMEC(secondEvaluationObject);
      })(e);
    } else {
      if (
        !isOffline &&
        !window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        return false;
      }
      reactHookProps.clearErrors();
      reactHookProps.handleSubmit(pageActions.onSaveSecondEvaluation)(e);
    }
  };

  const notificationInvitationSecondEvaluateur = candidature.second_evaluateur
    ? candidature.second_evaluateur.notifications
        .filter((n: any) => n.data.candidature == candidature.uuid)
        .find(
          (n: any) =>
            n.type == "InviterSecondEvaluateur" ||
            n.type == "CreationInvitationSecondEvaluateur"
        )
    : null;
  const dateMaxToGiveInvitationSecondEvaluateur =
    notificationInvitationSecondEvaluateur
      ? DateTime.fromISO(notificationInvitationSecondEvaluateur.created_at)
          .startOf("day")
          .plus({ day: 30 })
      : null;
  const howManyDaysToGiveAvis = dateMaxToGiveInvitationSecondEvaluateur
    ? dateMaxToGiveInvitationSecondEvaluateur.diff(
        DateTime.now().startOf("day"),
        ["days"]
      )
    : null;

  if (!reactHookProps.watch("second_evaluateur")) {
    return null;
  }

  return (
    <Stack>
      <form>
        <FormSection sectionTitle={labels.AVIS_SECOND_EVALUATEUR_TITRE}>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Avis du second évaluateur (3000 caractères max) :"
                placeholder="Ex : commentaire"
                multiline
                required
                rows={6}
                maxLength={3000}
                {...field}
                disabled={inputsDisabledSecondeEvaluation}
              />
            )}
            control={reactHookProps.control}
            name="avis"
          />
        </FormSection>

        <DynamicFieldForm
          statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
          reactHookProps={reactHookProps}
          disabled={inputsDisabledSecondeEvaluation}
        />
      </form>
      {isOffline ? (
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          {!isOpenedInModal && <CancelButton text={"Retour"} />}

          <Can I={applicableEditSecondeEvaluationPermissions}>
            {conditionnalDisplayVariable.isSecondEvaluateurThisCandidature &&
              !inputsDisabledSecondeEvaluation && (
                <LoadButton
                  isDisabled={
                    (howManyDaysToGiveAvis as any)?.toObject().days < 0
                  }
                  text={"Valider la seconde évaluation"}
                  isLoading={isSaving}
                />
              )}
          </Can>
        </Stack>
      ) : (
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
          wrap
        >
          {!isOpenedInModal && <CancelButton text="Retour" />}

          <Can I={applicableEditSecondeEvaluationPermissions}>
            {conditionnalDisplayVariable.isSecondEvaluateurThisCandidature &&
              !inputsDisabledSecondeEvaluation && (
                <LoadButton
                  isDisabled={
                    (howManyDaysToGiveAvis as any)?.toObject().days < 0
                  }
                  text={"Valider la seconde évaluation"}
                  isLoading={isSaving}
                  type="button"
                  onClick={(e: any) => onSubmitSecondEvaluation(e)}
                />
              )}
          </Can>

          {isEditMode === StatutCandidature.AVIS_SECOND_EVALUATEUR && (
            <BoutonEnregistrerMEC
              onClick={(e: any) => onSubmitSecondEvaluation(e)}
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
              typeBtn="button"
            />
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default SecondeEvaluationForm;
