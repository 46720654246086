import {
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useContext } from "react";
import {
  StructureContext,
  StructureType,
} from "../../providers/StructureProvider/StructureProvider";
import { MessageBarStyles, commonButtonStyles } from "../../styles";
import BooleanValue from "../BooleanValue/BooleanValue";
import FormSection from "../FormSection/FormSection";
import LoadLink from "../LoadLink/LoadLink";

const rolesToStructureType: any = {
  [StructureType.ENTREPRISE]: "Entreprise",
  [StructureType.OF]: "OF",
  [StructureType.GROUPE_ENTREPRISE]: "Groupe entreprise",
  [StructureType.GROUPE_OF]: "Groupe OF",
};

function UtilisateursLinkToStructure({
  sectionTitle = "",
}: {
  sectionTitle: string;
}) {
  const {
    users,
    actions,
    isToggleReferentUuid,
    isToggleActivationUuid,
    canHaveMoreReferent,
    structureType,
  }: any = useContext(StructureContext);

  console.log("structureType", structureType);

  const columns = [
    {
      key: "1",
      name: "Rôle(s)",
      fieldName: "role",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.est_administrateur && <Text>Administrateur système</Text>}
          {item.roles?.map((role: any, i: number) => (
            <Text key={i}>{role}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "2",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <Text>{item.nom}</Text>,
    },
    {
      key: "3",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <Text>{item.prenom}</Text>,
    },
    {
      key: "4",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.email}</Text>,
    },
    {
      key: "5",
      name: "Multibranche",
      fieldName: "est_multibranches",
      minWidth: 40,
      maxWidth: 80,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.est_multibranches} />,
    },
    {
      key: "6",
      name: "Actif",
      fieldName: "actif",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.actif} />,
    },
    {
      key: "7",
      name: "Référent",
      fieldName: "referent",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => (
        <BooleanValue value={item?.isReferent ?? false} />
      ),
    },
    {
      key: "8",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            {!item.isReferent && (
              <LoadLink
                styles={
                  item.actif
                    ? commonButtonStyles.disableButtonLink
                    : commonButtonStyles.activeButtonLink
                }
                onClick={() => actions.toggleUserActivation(item)}
                isLoading={isToggleActivationUuid == item.uuid}
                text={item.actif ? "Désactiver" : "Réactiver"}
              />
            )}

            {((item.actif &&
              canHaveMoreReferent &&
              item.roles.includes(rolesToStructureType[structureType])) ||
              item.isReferent) && (
              <LoadLink
                styles={
                  item.isReferent
                    ? commonButtonStyles.disableButtonLink
                    : commonButtonStyles.activeButtonLink
                }
                onClick={() => actions.toggleUserReferent(item)}
                isLoading={isToggleReferentUuid == item.uuid}
                text={
                  item.isReferent
                    ? "Enlever comme référent"
                    : "Désigner comme référent"
                }
              />
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <FormSection sectionTitle={sectionTitle}>
      {users.length == 0 ? (
        <MessageBar styles={MessageBarStyles}>
          Aucun utilisateur est lié à cette structure.
        </MessageBar>
      ) : (
        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={users}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
      )}
    </FormSection>
  );
}

export default UtilisateursLinkToStructure;
