import { useContext } from "react";
import { API_URL } from "../../../config/config";
import AddBlocsForm from "../AddBlocsForm/AddBlocsForm";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import CompetenceBlockListDnd from "../CompetenceBlockListDnd/CompetenceBlockListDnd";

const BlocksContainer = ({ isUsingExistingReferential, selectedBlocks, option }: any) => {
  const { conditionnalDisplayVariables:{queryUrlSearchBlockInterBranch} }: any =
    useContext(CertificationContext);
  return (
    <>
      {!isUsingExistingReferential && (
        <AddBlocsForm
          selectedBlocks={selectedBlocks}
          forOption={option?.option || null}
          method="GET"
          resourceURI={`${API_URL}api/administration/certifications/bloc-competences/lister-blocs-actifs`}
          addQueryURL={(resourceURI:any,terms:any)=>`${resourceURI}?terms=${terms}${queryUrlSearchBlockInterBranch}`}
          handleResponse={(response: any, terms: any)=>response.data.data}
        />
      )}
      {selectedBlocks?.length > 0 && (
        <CompetenceBlockListDnd
          selectedBlocks={selectedBlocks}
          forOption={option?.option || null}
        />
      )}
    </>
  );
};

export default BlocksContainer;
