const sideNavStyles = {
  outerStyle: {
    root: {
      "::-webkit-scrollbar-track": {
        //"-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "10px",
        // backgroundColor: "#F5F5F5",
      },
      "::-webkit-scrollbar": {
        width: 7,
        // backgroundColor: "#F5F5F5",
      },
      "::-webkit-scrollbar-thumb": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
        backgroundColor: "#adadad",
        borderRadius: "20px",
      },
    },
  },
  brandStyle: {
    root: {
      fontSize: 24,
      margin: "20px 0px",
      fontWeight: "bold",
    },
  },
};

export default sideNavStyles;
