import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import subGroupCompanyAdapters from "../../adapters/subGroupCompanyAdapters";
import BackLink from "../../Common/BackLink/BackLink";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import { userAccessEnum } from "../../config/accessEnum";
import useSubGroupCompanyDetail, { SubGroupCompanyContext } from "./useSubGroupCompanyDetail";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import GeneralInformationsSection from "./FormSections/GeneralInformationsSection"
import UsersInformations from "./FormSections/UsersInformations";

function SubGroupCompanyDetail() {
  const { sous_groupe_entreprise_uuid } = useParams();
  const {
    subGroupCompany,
    subGroupCompanyProps,
    reactHookProps,
    isLoadingSubGroupCompany,
    actions: actionsPage,
  } = useSubGroupCompanyDetail();
  const {
    actions,
    errorPostSubGroupCompany,
    errorPatchSubGroupCompany,
    isLoading,
    userSelectedBranch
  } = subGroupCompanyProps;
  const {
    reset,
    clearErrors,
    handleSubmit,
    setError,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (sous_groupe_entreprise_uuid !== "creer") {
      actions.setUpdateSubGroupCompanyUuid(sous_groupe_entreprise_uuid);
    }
  }, [sous_groupe_entreprise_uuid]);

  useEffect(() => {
    if (subGroupCompany?.uuid) {
      reset(subGroupCompanyAdapters.transformForBO(subGroupCompany));
    }
  }, [subGroupCompany]);

  useEffect(() => {
    var apiErrors = (errorPostSubGroupCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostSubGroupCompany]);

  useEffect(() => {
    var apiErrors = (errorPatchSubGroupCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPatchSubGroupCompany]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.saveSubGroupCompany)(e);
  };

  return (
    <SubGroupCompanyContext.Provider
      value={{
        actions:actionsPage,
        reactHookProps,
        userSelectedBranch,
      }}
    >
      <Stack>
        <BackLink />
        <Page
          title="Détail du sous-groupe"
          explain={!subGroupCompany?.uuid
            ? "Veuillez créer un nouveau sous groupe"
            : "Veuillez modifier les informations du sous groupe"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingSubGroupCompany}>
            <form onSubmit={onSubmit}>
              <GeneralInformationsSection />
              <UsersInformations />
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginTop: 20 }}
              >
                <CancelButton />
                <Can
                  I={[
                    userAccessEnum.MODIFIER_SOUS_GROUPE,
                    userAccessEnum.CREER_SOUS_GROUPE,
                  ]}
                >
                  <LoadButton
                    isLoading={isLoading}
                    text={!subGroupCompany?.uuid ? "Créer" : "Modifier"}
                  />
                </Can>
              </Stack>
            </form>
          </DetailLoader>
        </Page>
      </Stack>
    </SubGroupCompanyContext.Provider>
  );
}

export default SubGroupCompanyDetail;
