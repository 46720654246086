import { useAtom } from "jotai";
import { BsCheck } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { StatutDecisionMembre } from "../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../hooks/useAuth";
import tools from "../../utils/tools";

const AvisMembreDisplay = ({ candidature }: any) => {
  const { auth } = useAuth();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const isMembreJury = userSelectedBranch.profils.includes("MEMBRE_JURY");
  const userUuid = auth.user.uuid;
  const isPresidentJury = userUuid == candidature?.jury?.president_jury_uuid;

  let transmissionAvis = null;
  if (isPresidentJury) {
    return transmissionAvis;
  }

  if (isMembreJury) {
    if (candidature.avis_jury) {
      const myAvis = candidature.avis_jury.find(
        (avis: any) => avis.membre_jury?.uuid == userUuid
      );
      if (myAvis) {
        transmissionAvis = (
          <div className="text-green">
            <span style={{ display: "flex", alignItems: "center" }}>
              <BsCheck /> Avis transmis
            </span>
            (
            {
              (tools.findIn(StatutDecisionMembre, myAvis.avis, "id") as any)
                .text
            }
            )
          </div>
        );
      } else {
        transmissionAvis = (
          <span
            style={{ display: "flex", alignItems: "center" }}
            className="text-orange"
          >
            <IoIosClose /> Avis non transmis
          </span>
        );
      }
    }
  }

  return transmissionAvis;
};

export default AvisMembreDisplay;
