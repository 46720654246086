import { Outlet } from "react-router-dom";
import useBranchEntities from "../../hooks/useBranchEntities";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";

const RequireBranchEntities = () => {
  const { isLoading } = useBranchEntities();

  return !isLoading ? <Outlet /> : <FullscreenLoader />;
};

export default RequireBranchEntities;
