export enum StatutJuryEnum {
  OUVERT = 1,
  EN_ANALYSE = 2,
  EN_SEANCE = 3,
  CLOTURE = 4,
}

export const statutInfos: any = {
  [StatutJuryEnum.CLOTURE]: {
    text: "Clôturé",
    color: "red",
  },
  [StatutJuryEnum.OUVERT]: {
    text: "Ouvert",
    color: "green",
  },
  [StatutJuryEnum.EN_SEANCE]: {
    text: "En séance",
    color: "#ffc107",
  },
  [StatutJuryEnum.EN_ANALYSE]: {
    text: "En analyse",
    color: "#007bff",
  },
};
