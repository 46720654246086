import { useState } from 'react';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
    postUserRequest,
    putUserRequest,
    patchActivateUserRequest,
    patchDesactivateUserRequest,
    sendPasswordRequest,
} from '../../services/userServices'

function useUserMutations() {
    const queryClient = useQueryClient();
    let navigate = useNavigate();

    const [userUuid, setUserUuid] = useState(null);
    const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null)
    const [isSendingPasswordUuid, setIsSendingPasswordUuid] = useState(null)

    const mutations = {
        PostUser: useMutation(postUserRequest,
            {
                onSuccess: (data) => {
                    toast.success("L'utilisateur a été créé avec succès.");
                    setUserUuid(data.uuid);
                    queryClient.invalidateQueries("usersProfil");
                },
                onError: (error: any) => {
                    !error.response.status || error.response.status >= 500
                        ? navigate(`/erreur`)
                        : toast.error(
                            `Une erreur est survenue lors de la création de l'utilisateur: ${error?.response?.data?.message}`
                        );
                },
            }
        ),
        PutUser: useMutation(putUserRequest, {
            onSuccess: () => {
                toast.success("L'utilisateur a été mis à jour avec succès.");
                queryClient.invalidateQueries("usersProfil");
            },
            onError: (error: any) => {
                !error.response.status || error.response.status >= 500
                    ? navigate(`/erreur`)
                    : toast.error(
                        `Une erreur est survenue lors de la mise à jour de l'utilisateur : ${error?.response?.data?.message}`
                    );
            },
        }),
        ToggleUserActivate: useMutation(patchActivateUserRequest, {
            onSuccess: () => {
                setIsToggleActivationUuid(null)
                toast.success("L'utilisateur a été réactivé avec succès");
                queryClient.invalidateQueries('usersProfil')
            },
            onError: (error: any) => {
                toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
                setIsToggleActivationUuid(null)
            }
        }),
        ToggleUserDesactivate: useMutation(patchDesactivateUserRequest, {
            onSuccess: () => {
                setIsToggleActivationUuid(null)
                toast.success("L'utilisateur a été désactivé avec succès");
                queryClient.invalidateQueries('usersProfil')
            },
            onError: (error: any) => {
                toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
                setIsToggleActivationUuid(null)
            }
        }),
        SendPassword: useMutation(sendPasswordRequest, {
            onSuccess: ({ response, user }) => {
                setIsSendingPasswordUuid(null)
                user.is_verified_account
                    ? toast.success("Le lien de réinitialisation du mot de passe a été envoyé")
                    : toast.success("Le mot de passe a été envoyé pour la première fois à l'utilisateur");
            },
            onError: (error: any) => {
                setIsSendingPasswordUuid(null)
                !error.response.status || error.response.status >= 500
                    ? navigate(`/erreur`)
                    : toast.error(
                        `Une erreur est survenue lors de l'envoie du mot de passe : ${error?.response?.data?.message}`
                    );
            },
        })
    }

    return {
        userUuid,
        setUserUuid,
        mutations,
        isToggleActivationUuid,
        setIsToggleActivationUuid,
        isSendingPasswordUuid,
        setIsSendingPasswordUuid,
    }
}

export default useUserMutations