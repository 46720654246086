const companyAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      ...data,
      ref_idcc: data.ref_idcc?.id ?? null,
      adresse: {
        uuid: data.adresse_uuid,
        adresse: data.adresse,
        complement_adresse: data.complement_adresse,
        code_postal: data.code_postal,
        region: data.region,
        ville: data.ville,
      },
      ofs: data.ofs?.map((item: any) => item.uuid),
      groupe_entreprise: data.groupe_entreprise?.uuid,
      sous_groupe: data.sous_groupe?.uuid,
      positionneurs: data.positionneurs?.map((item: any) => item.uuid),
      evaluateurs: data.evaluateurs?.map((item: any) => item.uuid),
    };

    return transformed;
  },
  transformForBO: (data: any) => {
    let transformed: any = {
      ...data,
      adresse_uuid: data.adresse.uuid,
      adresse: data.adresse.adresse,
      complement_adresse: data.adresse.complement_adresse,
      code_postal: data.adresse.code_postal,
      region: data.adresse.region,
      ville: data.adresse.ville,
    };

    return transformed;
  },
};

export default companyAdapters;
