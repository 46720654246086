import { DirectionalHint, IconButton, TooltipHost } from "@fluentui/react";

function Tooltip({
  id,
  content,
  title = "Info",
  iconName = "Info",
  ariaLabel = "Info",
  colors = { classic: undefined, hovered: undefined },
}: {
  id: string;
  content: any;
  title?: string;
  iconName?: string;
  ariaLabel?: string;
  colors?: any;
}) {
  return (
    <TooltipHost
      content={content}
      id={id}
      calloutProps={{ gapSpace: 0 }}
      styles={{ root: { display: "inline-block" } }}
      directionalHint={DirectionalHint.topLeftEdge}
    >
      <IconButton
        aria-describedby={id}
        id={id}
        iconProps={{ iconName: iconName }}
        title={title}
        styles={{
          rootHovered: { color: colors.hovered },
          root: { color: colors.classic },
        }}
        ariaLabel={ariaLabel}
      />
    </TooltipHost>
  );
}

export default Tooltip;
