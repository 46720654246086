import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import { commonInputStyles } from "../../../styles";

function OfProfileInformation({ reactHookProps }: any) {
  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Raison sociale :"
              type="text"
              {...field}
              placeholder="Ex : Ma société"
              required
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="raison_sociale"
        />
      </Stack.Item>

      <FormAdress
        control={reactHookProps.control}
        required={true}
        varToWatch={reactHookProps.watch("code_postal") ?? false}
        onPrefillCityAndRegion={(data: any) => {
          reactHookProps.setValue("ville", data.nom_commune);
          reactHookProps.setValue("region", data.nom_region);
        }}
      />

      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              label={`Saisissez le SIREN de rattachement`}
              type="text"
              placeholder={`N° SIREN à 9 chiffres sans espace`}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= 9) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="siren_rattachement"
        />
      </Stack.Item>
    </>
  );
}

export default OfProfileInformation;
