import {
  Link,
  MessageBar,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import {
  MessageBarStyles,
  commonButtonStyles,
  commonInputStyles,
} from "../../../../styles";
import { CandidatureContext } from "../useCreationCandidature";

const SearchCandidatSection = ({ disabled, enabledInputs }: any) => {
  const {
    reactHookProps,
    conditionnalDisplayVariables,
    isChecked,
    searchResult,
    actions,
  }: any = useContext(CandidatureContext);

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = reactHookProps;

  const getBlocsInfoMessage = () => {
    if (searchResult?.length > 0) {
      return "Un candidat similaire existe déjà en base de données :";
    } else {
      return "Aucun candidat avec ces informations n'a été trouvé en base de données.";
    }
  };

  useEffect(() => {
    actions.setUpdateIsCheck(false);
  }, [
    watch("candidat.nom"),
    watch("candidat.prenom"),
    watch("candidat.email"),
  ]);

  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Nom de naissance :"
              type="text"
              {...field}
              placeholder="Ex : Doe"
              errorMessage={(errors.candidat?.nom as any)?.message}
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.nom"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Prénom :"
              type="text"
              {...field}
              placeholder="Ex : John"
              errorMessage={(errors.candidat?.prenom as any)?.message}
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.prenom"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Email :"
              type="email"
              {...field}
              placeholder="Ex : john.doe@gmail.com"
              errorMessage={(errors.candidat?.email as any)?.message}
              onKeyDown={actions.onKeyDown}
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.email"
        />
      </Stack.Item>
      <PrimaryButton
        onClick={(e: any) => {
          reactHookProps.clearErrors();
          handleSubmit(actions.onVerify)(e);
        }}
        disabled={disabled && !enabledInputs.includes("candidat.email")}
        styles={commonButtonStyles.buttonSubmit}
        style={{ maxWidth: 270 }}
      >
        Vérifier l'existence du candidat
      </PrimaryButton>
      {!isChecked && (
        <MessageBar styles={MessageBarStyles}>
          La vérification de l'existence du candidat est obligatoire pour
          poursuivre la création de la candidature.
        </MessageBar>
      )}

      {(isChecked || disabled) && (
        <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
          {!watch("candidat.uuid") && (
            <MessageBar styles={MessageBarStyles}>
              {getBlocsInfoMessage()}
            </MessageBar>
          )}
          {searchResult?.length > 0 && (
            <>
              {searchResult.map((item: any, index: any) => (
                <Stack
                  horizontal
                  verticalAlign="center"
                  style={{ marginLeft: 30, marginBottom: 10 }}
                  key={`profil${index}`}
                >
                  <p
                    style={{
                      color: "grey",
                      fontStyle: "italic",
                      margin: "5px 0px 0px",
                      fontSize: 16,
                    }}
                  >
                    {item.nom} {item.prenom} - {item.email}
                  </p>
                  <Link
                    onClick={() => actions.onUseExistingProfile(item)}
                    styles={commonButtonStyles.buttonLink}
                    style={{ marginLeft: 20, marginTop: 5 }}
                  >
                    Cliquez ici pour reprendre ce profil existant
                  </Link>
                </Stack>
              ))}
            </>
          )}
        </Stack.Item>
      )}
    </>
  );
};

export default SearchCandidatSection;
