import { PrimaryButton, Text } from "@fluentui/react";
import { commonButtonStyles } from "../../../../styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";

function CloseJuryModal({ jury, actions }: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickCloturerButton = () => {
    actions.putCloseJury();
    closeModal();
  };

  return (
    <>
      <Text
        variant="medium"
        style={{ margin: "10px 0px", textAlign: "center" }}
      >
        Voulez-vous vraiment clôturer le jury ? <br />{" "}
        <strong>{jury.libelle}</strong>
      </Text>
      <PrimaryButton
        text="Clôturer"
        onClick={onClickCloturerButton}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </>
  );
}

export default CloseJuryModal;
