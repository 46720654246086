import Page from "../../../../Common/Page/Page";
import {
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import LoadLink from "../../../../Common/LoadLink/LoadLink";
import { MessageBarStyles, commonButtonStyles } from "../../../../styles";
import useDocumentsWithMyPermission from "../../../../hooks/useDocumentsWithMyPermission";

function JuryFilesModal({ jury }: any) {
  const { actions: documentActions } = useDocumentsWithMyPermission({});

  const documentsJuryObject = documentActions.getDocumentsJuryWithMyPermission({
    jury: jury,
  });

  const listColumns = [
    {
      key: "nom",
      name: "Nom du document",
      fieldName: "nom",
      minWidth: 300,
      onRender: (item: any) => <Text>{item.nom}</Text>,
    },
    {
      key: "column2",
      name: "Télécharger",
      fieldName: "telecharger",
      minWidth: 200,
      onRender: (item: any) =>
        item.file_url ? (
          <LoadLink
            href={item.file_url}
            styles={commonButtonStyles.buttonLink}
            text={"Télécharger"}
          />
        ) : (
          <div>En cours de génération</div>
        ),
    },
  ];

  return (
    <Page
      title={"Documents du jury "}
      explain={"Veuillez télécharger les documents du jury"}
    >
      <Stack tokens={{ childrenGap: 25 }}>
        {!documentsJuryObject.isGenerationTermine && (
          <Stack>
            <MessageBar styles={MessageBarStyles}>
              Il semblerait que des documents soient en cours de génération. Si
              le problème persiste, contactez un administrateur.
            </MessageBar>
          </Stack>
        )}
        {documentsJuryObject.documents.length > 0 && (
          <Stack>
            <DetailsList
              layoutMode={DetailsListLayoutMode.justified}
              items={documentsJuryObject.documents}
              columns={listColumns}
              selectionMode={SelectionMode.none}
            />
          </Stack>
        )}
      </Stack>
    </Page>
  );
}

export default JuryFilesModal;
