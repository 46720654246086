import { Text } from "@fluentui/react";

const BooleanValue = ({ value }) => {
  return value ? (
    <Text style={{ color: "#0AA204" }}>Oui</Text>
  ) : (
    <Text style={{ color: "#DF793A" }}>Non</Text>
  );
};

export default BooleanValue;
