export interface IStyles {
  hero: Object;
  container: Object;
}
export const getStyles = (hereBackground: string) => ({
  hero: {
    root: {
      width: "100%",
      height: "192px",
      background: hereBackground || "#939393",
      backgrounRepeat: "no-repeat",
      marginBottom: 30,
      position: "relative",
    },
  },
  container: {
    root: {
      margin: "10px 40px",
    },
  },
});
