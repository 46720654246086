function SuiviCohorteExplain({ paramBrancheSuiviCohorteActif }: any) {
  if (!paramBrancheSuiviCohorteActif) {
    return null;
  }

  const paramSuiviCohortInfos: any = {
    SUIVI_COHORTES_MODEL_OF_CANDIDAT: {
      text: "En tant qu'organisme de formation, vous pilotez le suivi de cohortes des candidatures qui vous sont attachées. Les candidats peuvent directement renseigner leur suivi grâce au formulaire reçu par mail. Vous avez également la possibilité de compléter les candidatures par vos soins.",
      boldText: "",
    },
    SUIVI_COHORTES_MODEL_OF: {
      text: "En tant qu'organisme de formation, vous pilotez le suivi de cohortes des candidatures qui vous sont attachées.",
      boldText:
        'Les candidatures avec un statut du suivi "à renseigner" doivent être complétées par vos soins.',
    },
    SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT: {
      text: "En tant qu'entreprise, vous pilotez le suivi de cohortes des candidatures qui vous sont attachées. Les candidats peuvent directement renseigner leur suivi grâce au formulaire reçu par mail. Vous avez également la possibilité de compléter les candidatures par vos soins.",
      boldText: "",
    },
    SUIVI_COHORTES_MODEL_ENTREPRISE: {
      text: "En tant qu'entreprise, vous pilotez le suivi de cohortes des candidatures qui vous sont attachées.",
      boldText:
        'Les candidatures avec un statut du suivi "à renseigner" doivent être complétées par vos soins.',
    },
  };

  return (
    <>
      {paramSuiviCohortInfos[paramBrancheSuiviCohorteActif.parametre]["text"]}
      <br />
      <strong>
        {
          paramSuiviCohortInfos[paramBrancheSuiviCohorteActif.parametre][
            "boldText"
          ]
        }
      </strong>
    </>
  );
}

export default SuiviCohorteExplain;
