import { DatePicker, Dropdown, Label, Stack } from "@fluentui/react";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { RiAddFill } from "react-icons/ri";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import FileInput from "../../Common/FileInput/FileInput";
import FormSection from "../../Common/FormSection/FormSection";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import SelectFieldAsync from "../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import ErrorMessage from "../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { API_URL } from "../../config/config";
import { strings } from "../../config/datePickerConfig";
import useCertificationList from "../../hooks/certifications/useCertificationList";
import { commonInputStyles } from "../../styles";
import tools from "../../utils/tools";
import useHabilitationOfDetail from "./useHabilitationOfDetail";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function HabilitationOfDetail() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { reactHookProps, actions, habilitationProps } =
    useHabilitationOfDetail();
  const {
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  } = useCertificationList();
  const { errorPostHabilitationOf } = habilitationProps;
  const {
    control,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors },
    setError,
  } = reactHookProps;

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actions.onSubmit)(e);
  };

  useEffect(() => {
    var apiErrors = (errorPostHabilitationOf as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostHabilitationOf]);

  useEffect(() => {
    if (parsed?.siret) {
      setValue("organisme_de_formation", {
        siret: parsed.siret,
        raison_sociale: parsed.ofRs,
        uuid: parsed.ofUuid,
      });
    }
  }, []);

  return (
    <Stack>
      <BackLink />

      <Page
        title={"Veuillez créer une habilitation"}
        explain={"Veuillez créer une nouvelle habilitation rattachée à une OF"}
      >
        {isLoadingCertifications || isRefetchingCertifications ? (
          <Loader />
        ) : (
          <form onSubmit={onSubmit}>
            <FormSection sectionTitle="Informations de l'habilitation">
              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectFieldAsync
                      label="Organisme de formation :"
                      placeholder="Recherchez un OF en tapant les premières lettres de sa raison sociale ou son SIRET"
                      resourceURI={`${API_URL}api/administration/ofs/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?recherche=${terms}&actif=true&paginate=false`
                      }
                      method="GET"
                      renderItem={(item: any) =>
                        item.raison_sociale + " - " + item.siret
                      }
                      renderValue={(item: any) =>
                        item.raison_sociale + " - " + item.siret
                      }
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      fieldRef={ref}
                      required={true}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{}}
                      disabled={parsed?.siret}
                      errorMessage={
                        (errors.organisme_de_formation as any)?.message
                      }
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="organisme_de_formation"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        required
                        selectedKey={value ? value.key : undefined}
                        label="Certification :"
                        placeholder="Sélectionnez une certification"
                        options={certificationsFromApi}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={null}
                  control={control}
                  name="certification"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { value, name } }) => (
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      ariaLabel="dd/mm/yyyy"
                      value={value}
                      onSelectDate={(date: any) =>
                        date ? setValue(name, date) : null
                      }
                      styles={commonInputStyles.textField}
                      label="Date de début"
                      isRequired
                      strings={strings}
                      formatDate={(date: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  )}
                  control={control}
                  name="date_debut"
                />
                <ErrorMessage
                  errorMessage={(errors.date_debut as any)?.message}
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { value, name } }) => (
                    <DatePicker
                      placeholder="dd/mm/yyyy"
                      ariaLabel="dd/mm/yyyy"
                      value={value}
                      onSelectDate={(date: any) =>
                        date ? setValue(name, date) : null
                      }
                      styles={commonInputStyles.textField}
                      label="Date de fin"
                      isRequired
                      strings={strings}
                      formatDate={(date: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  )}
                  control={control}
                  name="date_fin"
                />
                <ErrorMessage
                  errorMessage={(errors.date_fin as any)?.message}
                />
              </Stack.Item>

              <Stack.Item>
                <Label styles={commonInputStyles.label}>
                  Convention d'habilitation :
                </Label>
                <FileInput
                  name="documents"
                  nameDisplay="convention_habilitation.pdf"
                  file={watch("documents") ? watch("documents")[0]?.file : []}
                  onUpdateFile={(newFile: any) => {
                    setValue("documents", [
                      { file: newFile.target.value, type: "convention" },
                    ]);
                  }}
                  onDeleteFile={() => setValue("documents", undefined)}
                  existingFileURL={
                    watch("documents") ? watch("documents")[0]?.file_url : null
                  }
                  acceptedFileTypes={["PDF"]}
                />
              </Stack.Item>
            </FormSection>
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              <CancelButton />

              <LoadButton
                text={"Valider"}
                isLoading={false}
                icon={<RiAddFill size={38} />}
                style={{ fontWeight: "bold" }}
              />
            </Stack>
          </form>
        )}
      </Page>
    </Stack>
  );
}

export default HabilitationOfDetail;
