const infoCardStyles = (primaryColor: any) => ({
  root: {
    outline: `1px solid #e0e0e0`,
    ":hover": {
      outline: `1px solid ${primaryColor}`,
    },
  },
});

export default infoCardStyles;
