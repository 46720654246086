import { useForm } from "react-hook-form";
import useSubGroupOf from "../../hooks/subGroupOf/useSubGroupOf"
import useSubGroupOfQuery from "../../hooks/subGroupOf/useSubGroupOfQuery";

function useSubGroupOfDetail() {
    const subGroupOfProps = useSubGroupOf();
    const {subGroupOf, isLoading, isRefetching} = useSubGroupOfQuery(subGroupOfProps);

    const reactHookProps = useForm(
        {
          mode: 'onBlur',
          //resolver:joiResolver()
        }
      )
    
      const actions = {
        //futur: modifier sous-groupe entreprise
      }

    return {
        subGroupOf,
        subGroupOfProps,
        reactHookProps,
        actions,
        isLoadingSubGroupOf: isLoading || isRefetching
    }
}

export default useSubGroupOfDetail