import { DatePicker, Label, Stack, TextField } from "@fluentui/react";
import { DateTime } from "luxon";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import CancelButton from "../../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import FormSection from "../../../../Common/FormSection/FormSection";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import { strings } from "../../../../config/datePickerConfig";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import { commonInputStyles } from "../../../../styles";
import tools from "../../../../utils/tools";
import BoutonEnregistrerMEC from "../../Common/MiseEnConformite/BoutonEnregistrerMEC";
import BoutonDemanderMEC from "../../Common/MiseEnConformite/BoutonDemanderMEC";
import { userAccessEnum } from "../../../../config/accessEnum";

function AvisFormateurForm({
  candidature,
  fetchCandidature,
  reactHookProps,
  pageActions,
  mecActions,
  isSaving,
  isEditMode,
  isOpenedInModal,
  applicableEditPermissionAvisFormateur,
  inputsDisabledAvisFormateur,
}: any) {
  const editableInputNames =
    mecActions.onGetEditableInputNames("avis_formateur");
  const onSaveAvisFormateur = (data: any, estEnregistre: boolean) => {
    if (isEditMode) {
      mecActions.onSaveMEC({
        ...data,
        avis_formateur: {
          id: data.avis_formateur_id ?? null,
          nom: data.nom_formateur,
          prenom: data.prenom_formateur,
          email: data.email_formateur,
          date_debut_formation: data.date_debut_formation,
          date_fin_formation: data.date_fin_formation,
          avis: data.avis_formateur,
          est_enregistre: estEnregistre,
        },
      });
    } else {
      pageActions.onSaveAvisFormateur(data, estEnregistre);
    }
  };
  const onSubmitAvisFormateur = (e: any, estEnregistre: any) => {
    reactHookProps.clearErrors();

    reactHookProps.handleSubmit((data: any) =>
      onSaveAvisFormateur(data, estEnregistre)
    )(e);
  };

  return (
    <Stack>
      <Stack>
        {inputsDisabledAvisFormateur && (
          <Can I={[userAccessEnum.MODIFIER_AVIS_FORMATEUR]}>
            <BoutonDemanderMEC
              text="Corriger l'avis formateur"
              demanderMECText="Demander une mise en conformité de l'avis formateur"
              statut={StatutCandidature.AVIS_FORMATEUR}
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
              createurUuid={candidature.createur?.uuid}
            />
          </Can>
        )}
      </Stack>
      <form>
        <FormSection sectionTitle="Avis du formateur">
          <Stack
            style={{ marginBottom: 24 }}
            horizontalAlign="start"
            horizontal
            wrap
            className="ms-Grid"
          >
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label={"Nom :"}
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : Doe"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors as any).avis_formateur
                          ?.nom as any
                      )?.message
                    }
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="nom_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Prénom :"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : John"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.prenom as any
                      )?.message
                    }
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="prenom_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>
            <Stack.Item
              className="ms-Grid-col ms-sm12"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Email :"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    {...field}
                    placeholder="Ex : john.doe@email.com"
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.email as any
                      )?.message
                    }
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(field.name)
                    }
                  />
                )}
                name="email_formateur"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Label
                htmlFor="date_debut_formation"
                styles={commonInputStyles.label}
              >
                Date de début de formation du candidat :
              </Label>
              <Controller
                render={({ field: { onChange, name, value } }) => (
                  <DatePicker
                    formatDate={(date?: any): any =>
                      date ? `${tools.reformateDateFromJsDate(date)}` : null
                    }
                    strings={strings}
                    showGoToToday={false}
                    maxDate={new Date()}
                    value={
                      reactHookProps.watch("date_debut_formation")
                        ? new Date(reactHookProps.watch("date_debut_formation"))
                        : undefined
                    }
                    styles={commonInputStyles.dataPicker}
                    onSelectDate={(date: any) => {
                      if (date) {
                        onChange(
                          DateTime.fromJSDate(date)
                            .set({ hour: 12 })
                            .toUTC()
                            .toISO()
                        );
                      }
                    }}
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(name)
                    }
                    placeholder="dd/mm/yyyy"
                    allowTextInput
                    parseDateFromString={(dateStr: any) =>
                      tools.parseDateFromStringDatePicker(dateStr)
                    }
                  />
                )}
                name="date_debut_formation"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Label
                htmlFor="date_fin_formation"
                styles={commonInputStyles.label}
              >
                Date de fin de formation du candidat:
              </Label>
              <Controller
                render={({ field: { onChange, name, value } }) => (
                  <DatePicker
                    formatDate={(date?: any): any =>
                      date ? `${tools.reformateDateFromJsDate(date)}` : null
                    }
                    strings={strings}
                    showGoToToday={false}
                    maxDate={new Date()}
                    value={
                      reactHookProps.watch("date_fin_formation")
                        ? new Date(reactHookProps.watch("date_fin_formation"))
                        : undefined
                    }
                    styles={commonInputStyles.dataPicker}
                    onSelectDate={(date: any) => {
                      if (date) {
                        onChange(
                          DateTime.fromJSDate(date)
                            .set({ hour: 12 })
                            .toUTC()
                            .toISO()
                        );
                      }
                    }}
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(name)
                    }
                    placeholder="dd/mm/yyyy"
                    allowTextInput
                    parseDateFromString={(dateStr: any) =>
                      tools.parseDateFromStringDatePicker(dateStr)
                    }
                  />
                )}
                name="date_fin_formation"
                control={reactHookProps.control}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Avis du formateur (6000 caractères max) :"
                    placeholder="Ex : avis du formateur"
                    onRenderLabel={({ label }: any) => (
                      <Stack horizontal verticalAlign="center">
                        {label} &nbsp;
                        <i
                          style={{
                            fontWeight: 300,
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (requis pour la validation de l'avis)
                        </i>
                      </Stack>
                    )}
                    multiline
                    rows={6}
                    maxLength={6000}
                    {...field}
                    disabled={
                      inputsDisabledAvisFormateur &&
                      !editableInputNames.includes(field.name)
                    }
                    errorMessage={
                      (
                        (reactHookProps.formState.errors.avis_formateur as any)
                          ?.avis as any
                      )?.message
                    }
                  />
                )}
                control={reactHookProps.control}
                name="avis_formateur"
              />
            </Stack.Item>
          </Stack>
        </FormSection>

        <DynamicFieldForm
          statut={StatutCandidature.AVIS_FORMATEUR}
          reactHookProps={reactHookProps}
          disabled={inputsDisabledAvisFormateur && !isEditMode}
        />
      </form>
      <Stack
        horizontal
        horizontalAlign="space-between"
        style={{ marginTop: 20 }}
        wrap
      >
        {!isOpenedInModal && <CancelButton text="Retour" />}

        <Can I={applicableEditPermissionAvisFormateur}>
          <LoadButton
            text={"Enregistrer l'avis formateur"}
            isLoading={isSaving}
            type="button"
            onClick={(e: any) => onSubmitAvisFormateur(e, true)}
          />

          <LoadButton
            text={"Valider l'avis formateur"}
            isLoading={isSaving}
            type="button"
            onClick={(e: any) => onSubmitAvisFormateur(e, false)}
          />
        </Can>

        {isEditMode === StatutCandidature.AVIS_FORMATEUR && (
          <BoutonEnregistrerMEC
            onClick={(e: any) => onSubmitAvisFormateur(e, false)}
            reactHookProps={reactHookProps}
            onAfterValidate={fetchCandidature.refrechCandidature}
            typeBtn="button"
          />
        )}
      </Stack>
    </Stack>
  );
}

export default AvisFormateurForm;
