import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import myStructureAdapters from "../../adapters/myStructureAdapters";
import MyStructureEntrepriseForm from "./MyStructureEntrepriseForm/MyStructureEntrepriseForm";
import MyStructureGroupeEntrepriseForm from "./MyStructureGroupeEntrepriseForm/MyStructureGroupeEntrepriseForm";
import MyStructureGroupeOfForm from "./MyStructureGroupeOfForm/MyStructureGroupeOfForm";
import MyStructureOfForm from "./MyStructureOfForm/MyStructureOfForm";
import useMyStructureDetail from "./useMyStructureDetail";

function MyStructureDetail() {
  const { type, uuid } = useParams();
  const {
    structure,
    reactHookProps,
    actions: actionsPage,
    myStructureProps,
    isLoadingStructure,
  } = useMyStructureDetail({
    type,
  });
  const {
    actions,
    errorPatchMyStructure,
    isLoadingPatchMyStructure,
    isErrorPatchMyStructure,
  } = myStructureProps;

  const { reset, clearErrors, handleSubmit, setError } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid != "creer") {
      actions.setUpdateStructureUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (structure?.uuid) {
      reset(myStructureAdapters.transformForBO(structure));
    }
  }, [structure?.uuid]);

  useEffect(() => {
    var apiErrors = (errorPatchMyStructure as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPatchMyStructure]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.saveStructure)(e);
  };

  return (
    <Page
      title="Informations de ma structure"
      explain={"Vous pouvez mettre à jour les informations de votre structure"}
    >
      <DetailLoader isLoadingDetail={isLoadingStructure}>
        <form onSubmit={onSubmit}>
          {type == "entreprise" && (
            <MyStructureEntrepriseForm reactHookProps={reactHookProps} />
          )}
          {type == "organisme_de_formation" && (
            <MyStructureOfForm reactHookProps={reactHookProps} />
          )}
          {type == "groupe_entreprise" && (
            <MyStructureGroupeEntrepriseForm reactHookProps={reactHookProps} />
          )}
          {type == "groupe_of" && (
            <MyStructureGroupeOfForm reactHookProps={reactHookProps} />
          )}
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <CancelButton />

            <LoadButton
              isLoading={isLoadingPatchMyStructure}
              text={"Valider les modifications"}
            />
          </Stack>
        </form>

        {isErrorPatchMyStructure && (
          <PageMessage
            message={(errorPatchMyStructure as any).response.data.message}
            type="error"
          />
        )}
      </DetailLoader>
    </Page>
  );
}

export default MyStructureDetail;
