import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  postCompanyRequest,
  putCompanyRequest,
  deleteCompanyRequest,
  patchActivateCompanyRequest,
  patchDesactivateCompanyRequest,
} from "../../services/companyServices";

function useCompanyMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [companyUuid, setCompanyUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const mutations = {
    PostCompany: useMutation(postCompanyRequest, {
      onSuccess: (data) => {
        toast.success("L'entreprise a été créée avec succès.");
        setCompanyUuid(data.uuid);
        setIsSaved(true);
        // Invalidate and refetch
        queryClient.invalidateQueries("companies");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement de l'entreprise : ${error?.message}`
            );
      },
    }),
    PutCompany: useMutation(putCompanyRequest, {
      onSuccess: () => {
        toast.success("L'entreprise a été mise à jour avec succès.");
        queryClient.invalidateQueries("companies");
        setIsSaved(true);
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour de l'entreprise : ${error?.message}`
            );
      },
    }),
    DeleteCompany: useMutation(deleteCompanyRequest, {
      onSuccess: () => queryClient.invalidateQueries("companies"),
    }),
    ToggleCompanyActivate: useMutation(patchActivateCompanyRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("L'entreprise a été activée avec succès.");
        queryClient.invalidateQueries("companies");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
    ToggleCompanyDesactivate: useMutation(patchDesactivateCompanyRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("L'entreprise a été désactivée avec succès.");
        queryClient.invalidateQueries("companies");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
  };

  return {
    companyUuid,
    setCompanyUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    isSaved,
  };
}

export default useCompanyMutations;
