import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { useAtom } from "jotai";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import TextResult from "../../../Common/TextResult/TextResult";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { API_URL } from "../../../config/config";
import useConstants from "../../../hooks/useConstants";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import useCandidatureJuryList, {
  initialFilters,
} from "../useCandidatureJuryList";

function CandidatJuryListFilters() {
  const { labels } = useLabelSettings();
  const { constants } = useConstants();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [isFilterShow, setIsFilterShow] = useState(true);

  const { candidaturesList, isLoadingCandidatures, actions } =
    useCandidatureJuryList();

  const totalCandidatures = candidaturesList.length;

  const { handleSubmit, control, reset, setValue, watch } = useForm<any>({
    mode: "onBlur",
    defaultValues: initialFilters,
  });

  const onReset = () => {
    reset();
    handleSubmit(actions.changeFilters)();
  };

  const resultatStatutList = [
    { key: 0, text: "Tous" },
    ...constants.CANDIDATURES.RESULTATS_STATUTS,
  ];

  const onWrapDefaultLabelRenderer = (
    props: any,
    defaultRender: any
  ): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <span>{defaultRender(props)}</span>
        <Tooltip
          id={"nom-tooltip"}
          content={"Vous pouvez rechercher par nom d'usage ou nom de naissance"}
        />
      </Stack>
    );
  };

  return (
    <FormSection sectionTitle={labels.VISUALISATION_JURY_FILTRE}>
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        {isFilterShow && (
          <Stack
            style={{ marginBottom: 24 }}
            horizontalAlign="start"
            horizontal
            wrap
            className="ms-Grid"
          >
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                name="nom"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label={"Nom :"}
                    onRenderLabel={onWrapDefaultLabelRenderer}
                    type="text"
                    placeholder="Ex: Dupont"
                    {...field}
                  />
                )}
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                name="prenom"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Prénom :"
                    type="text"
                    placeholder="Ex: Jean"
                    {...field}
                  />
                )}
              />
            </Stack.Item>

            <Stack.Item
              align="start"
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl4"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <Dropdown
                    label="Résultats de la certification :"
                    selectedKeys={value ? value.map((v: any) => v.key) : null}
                    placeholder="Sélectionnez un ou plusieurs résultats"
                    options={constants.CANDIDATURES.RESULTATS_STATUTS}
                    calloutProps={{ doNotLayer: true }}
                    onChange={(e: any, options: any) => {
                      let actualValue = watch(name);

                      actualValue.find((o: any) => o.key == options.key)
                        ? (actualValue = actualValue.filter(
                            (o: any) => o.key != options.key
                          ))
                        : (actualValue = [...actualValue, options]);

                      setValue(name, actualValue, { shouldValidate: true });
                    }}
                    styles={commonInputStyles.dropdownStyle}
                    multiSelect
                  />
                )}
                defaultValue={resultatStatutList[0]}
                control={control}
                name="resultat"
              />
            </Stack.Item>

            {/* <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Domaine :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionnez un domaine"
                  options={domaineList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={domaineList[0]}
              control={control}
              name="domaine"
            />
          </Stack.Item> */}

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-xl6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="Entreprises liées :"
                    placeholder="Recherchez une entreprise (ex : entreprise)"
                    resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?nom=${terms}`
                    }
                    method="GET"
                    renderItem={(item: any) => item.nom}
                    renderValue={(item: any) => item.nom}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    //errorMessage={(errors.ref_idcc as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                //defaultValue={undefined}
                control={control}
                name="entreprises"
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-xl6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="Organismes de formation liés :"
                    placeholder="Recherchez un OF (ex : OF)"
                    resourceURI={`${API_URL}api/administration/ofs/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?raison_sociale=${terms}`
                    }
                    method="GET"
                    renderItem={(item: any) => item.raison_sociale}
                    renderValue={(item: any) => item.raison_sociale}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    //errorMessage={(errors.ref_idcc as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                //defaultValue={undefined}
                control={control}
                name="ofs"
              />
            </Stack.Item>

            <Stack.Item
              className="ms-Grid-col ms-sm12"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="Certifications :"
                    placeholder="Recherchez une certification (ex : certif)"
                    resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?branche_creatrice_id=${userSelectedBranch.id}&libelle=${terms}`
                    }
                    method="GET"
                    renderItem={(item: any) => item.libelle}
                    renderValue={(item: any) => item.libelle}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={{}}
                    //errorMessage={(errors.ref_idcc as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                control={control}
                name="certifications"
              />
            </Stack.Item>
          </Stack>
        )}

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack
            tokens={{ childrenGap: 15 }}
            horizontal
            wrap
            verticalAlign="center"
          >
            {isFilterShow && (
              <Stack.Item>
                <LoadButton
                  isLoading={isLoadingCandidatures}
                  text="Rechercher"
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text={
                  isFilterShow ? "Cacher les filtres" : "Afficher les filtres"
                }
                onClick={() => {
                  setIsFilterShow(!isFilterShow);
                }}
              />
            </Stack.Item>
            {isFilterShow && (
              <Stack.Item>
                <DefaultButton
                  disabled={isLoadingCandidatures}
                  styles={commonButtonStyles.defaultButton}
                  text="Réinitialiser ma recherche"
                  onClick={onReset}
                />
              </Stack.Item>
            )}
          </Stack>

          <TextResult total={totalCandidatures} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default CandidatJuryListFilters;
