import {
  DefaultButton,
  DefaultSpacing,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import FormSection from "../../Common/FormSection/FormSection";
import JuryStatusBadge from "../../Common/JuryStatusBadge/JuryStatusBadge";
import Loader from "../../Common/Loader/Loader";
import useTheme from "../../Common/useTheme";
import { userAccessEnum } from "../../config/accessEnum";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import { IStyles, getStyles } from "./index.styles";
import InfoCard from "./InfoCard/InfoCard";
import { useDashboard } from "./useDashboard";

function Dashboard() {
  const [styles, setStyles] = useState<IStyles | undefined>();
  const { primaryColor } = useTheme();
  const navigate = useNavigate();
  const {
    branchInformations,
    isLoadingIndicateurs,
    isLoadingJuryList,
    juryList,
    actions: dashBoardActions,
    dashboardData,
  } = useDashboard();

  useEffect(() => {
    setStyles(getStyles(primaryColor));
    dashBoardActions.getIndicateursDashboard();
  }, []);

  const prochainJuryColumns = [
    {
      key: "1",
      name: "Date",
      fieldName: "date",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{tools.formatDate(item.date_jury)}</Text>,
    },
    {
      key: "2",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <JuryStatusBadge statut={item.statut} />,
    },
    {
      key: "3",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.libelle}</Text>,
    },
    {
      key: "4",
      name: "Date limite d'inscription",
      fieldName: "date_limite_inscription",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => (
        <Text>{tools.formatDate(item.date_limite_inscription_candidat)}</Text>
      ),
    },
    {
      key: "5",
      name: "Places restantes",
      fieldName: "nombre_de_places_restantes",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.nombre_de_places_restantes}</Text>,
    },
  ];

  return (
    <Stack style={{ background: "#fcfcfc" }}>
      <Stack
        styles={styles?.hero}
        style={
          branchInformations.headband
            ? {
                background: `url(${branchInformations.headband}) repeat-x`,
                backgroundSize: "auto 100%",
              }
            : { padding: "20px 0px" }
        }
        horizontalAlign="center"
        verticalAlign="center"
        horizontal
      >
        <DefaultButton
          onClick={() => navigate("/admin/demande_aide")}
          styles={commonButtonStyles.defaultButton}
          style={{
            color: primaryColor,
            borderColor: primaryColor,
            position: "absolute",
            right: "2%",
            top: "7%",
          }}
        >
          Besoin d'aide ?
        </DefaultButton>
        <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          style={{
            backgroundImage:
              "linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 35%,rgba(255,255,255,0.8) 65% ,rgba(255,255,255,0) 100%)",
            backgroundColor: "transparent",
            width: "100%",
            padding: "10px 0px",
          }}
        >
          <Stack styles={{ root: { marginRight: DefaultSpacing.m } }}>
            {branchInformations.logo && (
              <img src={branchInformations.logo} width={60} />
            )}
          </Stack>
          <Text variant="xLargePlus" style={{ color: "black" }}>
            {branchInformations.nom}
          </Text>
        </Stack>
      </Stack>

      <Stack styles={styles?.container}>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <div
            dangerouslySetInnerHTML={{ __html: branchInformations.description }}
            style={{ width: "100%" }}
          />
        </Stack>

        {isLoadingIndicateurs ? (
          <Loader />
        ) : (
          <>
            <Stack tokens={{ childrenGap: 30 }} style={{ marginTop: 20 }}>
              {dashboardData.map((section: any) =>
                section.data.length == 0 ? null : (
                  <Stack tokens={{ childrenGap: 10 }}>
                    <Text style={{ fontWeight: "bold", fontSize: 15 }}>
                      {section.title}
                    </Text>

                    {section.explain && (
                      <Stack.Item>
                        <Text>{section.explain}</Text>
                      </Stack.Item>
                    )}

                    <Stack
                      horizontal
                      style={{ width: "100%", padding: 0 }}
                      className="ms-Grid"
                      tokens={{ childrenGap: 12 }}
                      wrap
                    >
                      {section.data.map((data: any) => (
                        <InfoCard
                          libelle={data.libelle}
                          linkTo={data.linkTo}
                          value={data.value ?? 0}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )
              )}
            </Stack>

            {isLoadingJuryList ? (
              <Loader />
            ) : (
              <Stack>
                <FormSection sectionTitle="Prochains jurys">
                  <Can I={userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION}>
                    <Stack horizontal>
                      <CancelButton
                        text="Inscrire mes candidatures aux jurys"
                        onClick={dashBoardActions.inscriptionCandidaturesJury}
                      />
                    </Stack>
                  </Can>
                  <DetailsList
                    className="contentUseAllWidthMdSm"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    items={juryList || []}
                    columns={prochainJuryColumns}
                    selectionMode={SelectionMode.none}
                  />
                </FormSection>
              </Stack>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default Dashboard;
