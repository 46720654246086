import { useAtom } from "jotai";
import { atomWithReset, useResetAtom } from "jotai/utils";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getDemandeHabilitationsOfRequest } from "../../services/demandeHabilitationService";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ValidDemandeHabilitationModal from "./ValidDemandeHabilitationModal/ValidDemandeHabilitationModal";
import RefuseDemandeHabilitationModal from "./RefuseDemandeHabilitationModal/RefuseDemandeHabilitationModal";
import useDemandeHabilitation from "../../hooks/demandeHabilitation/useDemandeHabilitation";

export const initialFilters = {
  statut: { id: undefined, text: "Tous", key: "all" },
  certification: { id: undefined, text: "Tous", key: "all" },
  of: null,
};

export const filtersAtom = atomWithReset<any>(initialFilters);
export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

export const demandeHabilitationOfSelectedAtom = atomWithReset([]);

function useDemandeHabilitationOfList() {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const [demandeHabilitationOfSelected, setDemandeHabilitationOfSelected]: any =
    useAtom(demandeHabilitationOfSelectedAtom);
  const resetDemandeHabilitationSelected = useResetAtom(
    demandeHabilitationOfSelectedAtom
  );
  const { actions: demandeHabilitationActions } = useDemandeHabilitation();

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const {
    data: demandeHabilitationsOf,
    isLoading: isLoadingDemandeHabilitationsOf,
  } = useQuery(
    ["demandeHabilitationsOf", optionsFilters, userSelectedBranch],
    () => getDemandeHabilitationsOfRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    resetDemandeHabilitationSelected: () => {
      resetDemandeHabilitationSelected();
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onSelectDemandeHabilitation: (demande: any, value: any) => {
      if (value) {
        setDemandeHabilitationOfSelected([
          ...demandeHabilitationOfSelected,
          demande,
        ]);
        return;
      }

      setDemandeHabilitationOfSelected(
        demandeHabilitationOfSelected.filter((d: any) => d.uuid != demande.uuid)
      );
    },
    onSelectAllDemandeHabilitation: () => {
      if (demandeHabilitationOfSelected.length == 0) {
        const demandeHabilitationEnAttente =
          demandeHabilitationsOf?.data.filter(
            (d: any) => d.statut == "EN_ATTENTE"
          );

        setDemandeHabilitationOfSelected(demandeHabilitationEnAttente);
        return;
      }
      setDemandeHabilitationOfSelected([]);
    },
    onValidDemandeHabilitation: (
      demandes: any,
      isMultiple: boolean = false
    ) => {
      modalActions.openModal({
        header: "Valider une(des) demande(s) d'habilitation",
        body: (
          <ValidDemandeHabilitationModal
            demandes={isMultiple ? demandes : [demandes]}
            onValidDemande={(data: any) =>
              demandeHabilitationActions.onValidDemandeOf(data)
            }
          />
        ),
      });
    },
    onRefuseDemandeHabilitation: (
      demandes: any,
      isMultiple: boolean = false
    ) => {
      modalActions.openModal({
        header: "Refuser une demande d'habilitation",
        body: (
          <RefuseDemandeHabilitationModal
            demandes={isMultiple ? demandes : [demandes]}
            onRefuseDemande={(data: any) =>
              demandeHabilitationActions.onRefuseDemandeOf(data)
            }
          />
        ),
      });
    },
  };
  return {
    demandeHabilitationsOf,
    demandeHabilitationOfSelected,
    actions,
    optionsFilters,
    isLoadingDemandeHabilitationsOf,
    reactHookProps,
  };
}

export default useDemandeHabilitationOfList;
