import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
const eCertifApi = axiosInstance;

const groupOfURI = "administration/groupe-ofs";

export const getGroupOfsRequest = async (filters: any, options: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siren: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);
  const response = await eCertifApi.get(
    `${groupOfURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getGroupOfRequest = async (groupOfUuid: any) => {
  const response = await eCertifApi.get(`${groupOfURI}/${groupOfUuid}`);
  return response.data;
};

export const postGroupOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${groupOfURI}`, data, xsrfHeader);
  return response.data;
};

export const putGroupOfRequest = async (groupOf: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${groupOfURI}/${groupOf.uuid}`,
    groupOf,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateGroupOfRequest = async (groupOfUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${groupOfURI}/${groupOfUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateGroupOfRequest = async (groupOfUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${groupOfURI}/${groupOfUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteGroupOfRequest = async (groupOfUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${groupOfURI}/${groupOfUuid}`,
    xsrfHeader
  );
  return response;
};

export const getExportGroupOfs = async (filters: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siren: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/groupe-ofs?paginate=false${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
