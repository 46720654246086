import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { getHabilitationUtilisateurRequest } from "../../services/habilitationServices";
import tools from "../../utils/tools";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ImportEntities from "../../Common/ImportEntities/ImportEntities";
import { entityTypes } from "../../Common/ImportEntities/useImportEntities";
import RemoveHabilitationModal from "./RemoveHabilitationModal/RemoveHabilitationModal";

export const initialFilters = {
  statut: { id: undefined, text: "Tous", key: "all" },
  certification: { id: undefined, text: "Tous", key: "all" },
  positionneur: null,
  evaluateur: null,
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useHabilitationUtilisateurList() {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm<any>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: habilitations, isLoading: isLoadingHabilitations } = useQuery(
    ["habilitations", optionsFilters, userSelectedBranch],
    () => getHabilitationUtilisateurRequest(filters, optionsFilters),
    {
      retry: 0,
    }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.onChangeOptionFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onImportHabilitations: () => {
      modalActions.openModal({
        body: (
          <ImportEntities
            entityType={entityTypes.HABILITATION_UTILISATEUR}
            isAsync
          />
        ),
        fullWidth: true,
      });
    },
    onRemoveHabilitation: (habilitation: any[]) => {
      modalActions.openModal({
        header: "Retirer une habilitation",
        body: <RemoveHabilitationModal habilitation={habilitation} />,
      });
    },
  };
  return {
    habilitations,
    actions,
    reactHookProps,
    filters,
    optionsFilters,
    isLoadingHabilitations,
  };
}

export default useHabilitationUtilisateurList;
