const branchDetailStyles = {
    logoPreviewContainer: {
        root: {
            width: '200px',
            maxHeight: '200px',
            boxShadow: '0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%)',
            margin: '10px 10px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    toolTip: {
        root: {
            display: 'inline-block',
            margin: '0 5px',
        },
    },
    calloutToolTip: {
        gapSpace: 0,
        beakWidth: 0,
    },
    iconInfo: {
        color: '#226EBE',
        marginTop:5,
        fontSize: 16,
    }
}

export default branchDetailStyles;