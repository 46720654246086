import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";

const eCertifApi = axiosInstance;

const competenceBlockURI = "administration/certifications/bloc-competences";

export const getCompetenceBlocksRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    libelle: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    branche_creatrice: { type: "select", keyValue: "id", valueNotFilter: 0 },
    certifications_rattachees: { type: "array", keyValue: "uuid" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${competenceBlockURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getCompetenceBlockRequest = async (competenceBlockUuid: any) => {
  const response = await eCertifApi.get(
    `${competenceBlockURI}/${competenceBlockUuid}`
  );
  return response.data;
};

export const postCompetenceBlockRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${competenceBlockURI}`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putCompetenceBlockRequest = async (competenceBlock: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${competenceBlockURI}/${competenceBlock.get("uuid")}`,
    competenceBlock,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateCompetenceBlockRequest = async (
  competenceBlockUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${competenceBlockURI}/${competenceBlockUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateCompetenceBlockRequest = async (
  competenceBlockUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${competenceBlockURI}/${competenceBlockUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteCompetenceBlockRequest = async (
  competenceBlockUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${competenceBlockURI}/${competenceBlockUuid}`,
    xsrfHeader
  );
  return response;
};
