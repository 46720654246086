import React, { useCallback } from "react";
import useDynamicFields from "../../hooks/dynamicFields/useDynamicFields";
import DynamicField from "../DynamicField/DynamicField";
import FormSection from "../FormSection/FormSection";

const DynamicFieldForm = ({
  statut,
  reactHookProps,
  disabled = false,
}: any) => {
  const { dynamicFields } = useDynamicFields();

  const fields = React.useMemo(() => {
    return dynamicFields.filter(
      (field: any) =>
        field.statut_candidature.key === statut && field.actif == true
    );
  }, [dynamicFields, statut]);

  const onChangeField = useCallback((e: any) => {
    const { name, value } = e.target;
    const champsComplementaires = reactHookProps.watch(
      "champs_complementaires"
    );
    if (champsComplementaires.find((c: any) => c.uuid == name)) {
      reactHookProps.setValue(
        `champs_complementaires`,
        champsComplementaires.map((c: any) =>
          c.uuid == name ? { ...c, value } : c
        )
      );
    } else {
      reactHookProps.setValue(`champs_complementaires`, [
        ...champsComplementaires,
        { uuid: name, value },
      ]);
    }
  }, []);

  const onDeleteField = useCallback((e: any) => {
    const { name } = e.target;
    const champsComplementaires = reactHookProps.watch(
      "champs_complementaires"
    );
    if (champsComplementaires.find((c: any) => c.uuid == name)) {
      reactHookProps.setValue(
        `champs_complementaires`,
        champsComplementaires.map((c: any) =>
          c.uuid == name ? { ...c, file_url: "" } : c
        )
      );
    }
  }, []);

  let values = reactHookProps.watch("champs_complementaires") || [];

  return fields.length > 0 ? (
    <FormSection sectionTitle="Informations complémentaires">
      {fields.map((field: any) => {
        const fieldValue = values.find((c: any) => c.uuid == field.uuid);
        return (
          <DynamicField
            onChange={onChangeField}
            value={fieldValue ? fieldValue.value : ""}
            disabled={disabled}
            {...field}
            onDeleteFile={field.type == "FILE" ? onDeleteField : null}
            fichier_url={field.type == "FILE" ? fieldValue?.file_url : ""}
          />
        );
      })}
    </FormSection>
  ) : null;
};

export default DynamicFieldForm;
