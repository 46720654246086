import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { commonInputStyles } from "../../../styles";

function FileDropZone({onFileDrop,authorizedTypes,acceptedFileTypes = [],multipleFiles}: any) {
    const onDrop = (files: any) => {
        // Do something with the files
        if (files.length > 0) {
          let hasErrors = false;
          Array.from(files).forEach((file: any) => {
            if (!authorizedTypes.includes(file.type)) {
              toast.error(`${file.name} n'a pas le bon format `);
              hasErrors = true;
            }
          });
          if (!hasErrors) {
            if (multipleFiles) {
              onFileDrop(files);
            } else {
              onFileDrop([files[0]]);
            }
          }
        } else {
          toast.error("Aucun fichier valide n'a été choisi");
        }
      };
      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
      });
    
      const acceptForInput = acceptedFileTypes.map((item: any) => "." + item.toLowerCase()).join(",");
    
      return (
        <div
          style={{
            border: `1px dashed grey`,
            borderRadius: 5,
            background:
              commonInputStyles.textField.root.selectors[".ms-TextField-fieldGroup"].backgroundColor,
            textAlign: "center",
            cursor: "pointer",
            padding: 20,
            height: 64,
          }}
          className="d-center"
          {...getRootProps()}
        >
          <input {...getInputProps()} accept={acceptForInput} />
          {isDragActive ? (
            <p>Déposez le fichier ici ...</p>
          ) : (
            <div>
              Glissez-déposez {multipleFiles ? "des fichiers" : "un fichier"}
              {!(acceptForInput == ".any")
                ? acceptedFileTypes.map((item: any) => " " + item.toLowerCase()).join(", ")
                : ""}{" "}
              <br />
              ou cliquez pour parcourir vos dossiers <br />
            </div>
          )}
        </div>
      );
}

export default FileDropZone