import { Stack, Text, TextField} from "@fluentui/react"
import forgetPasswordStyles from "./ForgetPassword.styles";
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from "@hookform/resolvers/joi";
import { forgetPasswordSchema } from '../../schemas/authSchema';
import { useEffect } from 'react'
import useAuth from "../../hooks/useAuth";
import LoadButton from "../../Common/LoadButton/LoadButton";
import BackLink from "../../Common/BackLink/BackLink";

import { commonInputStyles } from '../../styles/index'

function ForgetPassword() {

    const {
        isLoadingForgetPassword,
        isSuccessForgetPassword,
        isErrorForgetPassword,
        errorForgetPassword,
        actions,
    } = useAuth();

    const {
        handleSubmit,
        formState: { errors },
        setError,
        control,
    } = useForm({
        mode: "onBlur",
        resolver: joiResolver(forgetPasswordSchema),
    });

    useEffect(() => {
        var apiErrors = (errorForgetPassword as any)?.response?.data;
        if (apiErrors?.errors) {
            for (var key in apiErrors.errors) {
                setError(key as any, { type: "focus", message: apiErrors.errors[key] });
            }
        }
    }, [errorForgetPassword]);

    return (
        <Stack
            style={{ width: "100%", minHeight: "90vh" }}
            horizontalAlign="center"
            verticalAlign="center"
        >

            <Stack
                className="ms-sm12 ms-lg6 ms-xxl3"
            >
                <BackLink style={{ margin: '20px 0px' }} />

                <Text variant={"xLarge"}>MOT DE PASSE OUBLIÉ</Text>

                {!isSuccessForgetPassword ?
                    <>
                        <Text variant={"mediumPlus"} style={{ margin: "20px 0px", textAlign: "justify" }}>
                            Veuillez saisir votre adresse email afin de recevoir un lien
                            de récupération pour réinitialiser votre mot de passe.
                        </Text>

                        <form
                            onSubmit={handleSubmit(actions.forgetPassword)}
                        >
                            <Stack
                                style={{ marginBottom: 20 }}
                                tokens={forgetPasswordStyles.stackTokens}
                            >

                                <Stack.Item>
                                    <Controller
                                        render={({ field }) => (
                                            <TextField
                                                label="Email :"
                                                styles={commonInputStyles.textField}
                                                type="email"
                                                {...field}
                                                errorMessage={(errors.email as any)?.message}
                                            />
                                        )}
                                        defaultValue=""
                                        control={control}
                                        name="email"
                                    />
                                </Stack.Item>

                                {isErrorForgetPassword &&
                                    <Stack.Item>
                                        <Text style={{ color: 'red' }} >{errorForgetPassword.response.data.message}</Text>
                                    </Stack.Item>
                                }

                                <LoadButton
                                    isLoading={isLoadingForgetPassword}
                                    text='M’envoyer un lien de récupération'
                                />



                            </Stack>
                        </form>
                    </>
                    :
                    <>
                        <Text variant={"mediumPlus"} style={{ margin: "20px 0px", textAlign: "justify", color:"#0AA204" }}>
                        Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse mail.
                        </Text>
                    </>
                }




            </Stack>


        </Stack >
    )
}

export default ForgetPassword