import { Text } from "@fluentui/react";

const Badge = ({ text, color, style = {}, textStyle = {} }: any) => {
  return (
    <div
      style={{
        borderRadius: 9999,
        padding: "5px 20px",
        background: color,
        display: "inline-flex",
        ...style,
      }}
    >
      <Text
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: 16,
          ...textStyle,
        }}
      >
        {text}
      </Text>
    </div>
  );
};

export default Badge;
