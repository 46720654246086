import { Stack, Text } from "@fluentui/react";
import useTheme from "../useTheme";

function InputMessage({ message, type='error' }: any) {
    const {successColor} = useTheme()
    const getColorFromType = () => {
        switch (type) {
            case "success":
                return successColor;
            case "error":
                return "rgb(164, 38, 44)";
        }
    };

    return (
        <Stack style={{ width: "100%"}}>
            <Text variant={"small"} style={{ color: getColorFromType() }}>
                {message}
            </Text>
        </Stack>
    )
}

export default InputMessage