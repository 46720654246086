import queryString from "query-string";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  PostContactAdminInterbranchesBodyType,
  postContactRequestInterbranches,
} from "../../services/contactAdminServices";
import {
  getBranchesRequest,
  getConstantRequest,
} from "../../services/publicServices";
import { IFormInputs } from "./types";

function useContactAdminPublic() {
  const [isLoading, setIsLoadingTo] = useState(false);
  const location = useLocation();

  const { data: branches, isLoading: loadingBranches } = useQuery(
    "branches",
    getBranchesRequest,
    {
      retry: 0,
    }
  );

  const { data: constants, isLoading: loadingConstants } = useQuery(
    ["motifs"],
    () => getConstantRequest()
  );

  const parsed: any = queryString.parse(location.search);
  const reactHookProps = useForm<IFormInputs>({
    mode: "onBlur",
    defaultValues: { branche: parsed?.branche || branches?.data[0]?.id },
  });

  const actions = {
    submitForm: async (data: IFormInputs) => {
      const body: PostContactAdminInterbranchesBodyType = {
        motif: data.motif,
        corps: data.besoin,
        motif_libelle: data.autre_motif,
        nom: data.nom,
        prenom: data.prenom,
        numero: data.numero_telephone,
        email: data.email,
        branche: data.branche,
      };

      try {
        setIsLoadingTo(true);
        await postContactRequestInterbranches(body);
        reactHookProps.reset();
        toast.success("Votre demande a été envoyée");
        setIsLoadingTo(false);
      } catch (err: any) {
        setIsLoadingTo(false);
        toast.error(`${err.message}`);
      }
    },
  };

  return {
    branches,
    actions,
    reactHookProps,
    isLoading,
    constants: constants,
    loadingConstants: loadingConstants || loadingBranches,
  };
}

export default useContactAdminPublic;
