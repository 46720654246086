export const candidatFakeData = {
  image:
    "https://scontent.ftun9-1.fna.fbcdn.net/v/t39.30808-6/266377294_4891230114274190_2716499569967616158_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=09cbfe&_nc_ohc=txpwHRVdieAAX-cEim3&_nc_ht=scontent.ftun9-1.fna&oh=00_AfBYqn0aJbUXASd7Z_pOxOmHhGnnjTW1ZxzX-d6RwPBgLg&oe=636FB274",
  nom: "Dupont",
  prenom: "Gérard",
  date_de_naissance: "01/01/1960",
  address: "4 Rue des 4 tours 94250 Gentilly, France",
  email: "candidat@test.com",
  telephone_professionnel: "0606060606",
  telephone_portable: "0606060607",
  obtainedCertifications: [
    {
      branche: "Polyvia",
      type: "CCQR",
      libelle: "Certification 1",
      date: "dd/mm/yyyy",
      status: "Validé",
      isEquivalence: true,
    },
  ],
  candidature: [
    {
      entreprise: "Entreprise 1",
      of: "OF 1",
      certification: "Certification 1",
      statut: "En cours",
      jury: "Jury 1",
    },
  ],
};

export const certificationFakeData = {
  title: "Certification1",
  description:
    "Eu cupidatat Lorem irure ad commodo quis nulla ea culpa aliquip amet pariatur Lorem duis. Culpa dolore ex ea cupidatat magna.Culpa non et aute non Lorem Lorem velit eu. Sit amet aliqua id eu aute voluptate voluptate. Magna nulla aliquip anim elit cupidatat exercitation sint incididunt laborum nostrud ipsum. Consequat sint elit dolor enim eiusmod ut nulla sint qui eu velit consectetur ullamco. Ut veniam eu dolor anim sunt nostrud.",
  type: "CCQR",
  enregistrement: "RNCP",
  numero_enregistrement: "12345",
  blocs_necessaire: 8,
  blocs_certifie: 8,
  blocs_imposes: [
    {
      title: "Bloc1",
      description: "Irure deserunt culpa aliqua occaecat qui.",
    },
    {
      title: "Bloc2",
      description: "Irure deserunt culpa aliqua occaecat qui.",
    },
    {
      title: "Bloc3",
      description: "Irure deserunt culpa aliqua occaecat qui.",
    },
  ],
  blocs_choix: [
    {
      title: "Bloc1",
      description: "Irure deserunt culpa aliqua occaecat qui.",
    },
    {
      title: "Bloc2",
      description: "Irure deserunt culpa aliqua occaecat qui.",
    },
    {
      title: "Bloc3",
      description: "Irure deserunt culpa aliqua occaecat qui.",
      selectionne: true,
    },
  ],
};
