import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import tools from "../../utils/tools";
import PlatformUserListFilters from "./PlatformUserListFilters/PlatformUserListFilters";
import usePlatformUserList from "./usePlatformUserList";

function PlatformUserList() {
  const {
    usersProfils,
    actions: actionsPage,
    optionsFilters,
  } = usePlatformUserList();

  const items = usersProfils?.data || [];

  const columns = [
    {
      key: "1",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      onRender: (item: any) => <Text>{item.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "2",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("prenom");
      },
      onRender: (item: any) => <Text>{item.prenom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "prenom"),
    },
    {
      key: "3",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("email");
      },
      onRender: (item: any) => <Text>{item.email}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "email"),
    },
    {
      key: "4",
      name: "Actif",
      fieldName: "actif",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.actif} />,
    },
    {
      key: "5",
      name: "Branches",
      fieldName: "branches",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.branches?.map((branche: any) => (
            <Text>{branche.nom}</Text>
          ))}
        </Stack>
      ),
    },
  ];

  return (
    <Stack>
      <Page
        title="Utilisateurs de la plateforme"
        explain="Gérer les utilisateurs de la plateforme"
      >
        <PlatformUserListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={usersProfils?.meta.current_page}
          totalPages={Math.ceil(usersProfils?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default PlatformUserList;
