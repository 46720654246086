import { useQuery } from "react-query";
import {
  getCompetenceRequest,
} from "../../services/competenceServices";
import useCompetence from '../../hooks/useCompetence'

function useCompetenceDetail() {
  const competenceProps = useCompetence();

  const { data: competence, isLoading, isRefetching } = useQuery(
    ["competences", competenceProps.competenceUuid],
    () => getCompetenceRequest(competenceProps.competenceUuid),
    { 
      enabled: !!competenceProps.competenceUuid,
      retry:0,
      refetchOnWindowFocus:false,
    }
  );

  const actions = {
    saveCompetence: (data: any) => {
      if (!(data as any).uuid) {
        competenceProps.actions.postCompetence(data);
      } else {
        competenceProps.actions.putCompetence(data);
      }
    },
  };

  return {
    competence,
    competenceProps,
    actions,
    isLoadingCompetence: isLoading || isRefetching
  };
}

export default useCompetenceDetail;
