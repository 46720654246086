import { PrimaryButton, Stack, Text } from "@fluentui/react";
import { branchAccessEnum, userAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import CandidatureInfoHeaderWrapper from "../../Pages/CandidatureWorkflow/Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import useInviterEvaluateurButton from "./useInviterEvaluateurButton";
import { commonButtonStyles } from "../../styles";

function InviterEvaluateurButton({
  candidature,
  refreshCandidature = () => {},
}: any) {
  const { actions: pageActions } = useInviterEvaluateurButton({
    refreshCandidature,
  });
  const { actions: accessActions } = useAccessSettings();

  if (
    candidature.evaluateur ||
    !accessActions.canI({
      action: branchAccessEnum.IDENTIFIER_EVALUATEUR_JUSTE_AVANT_EVALUATION,
    }).granted ||
    !accessActions.canI({
      action: userAccessEnum.IDENTIFIER_EVALUATEUR,
    }).granted
  ) {
    return null;
  }

  return (
    <CandidatureInfoHeaderWrapper className="ms-Grid-col ms-sm12 ms-lg6">
      <Stack horizontalAlign="start">
        <Text style={{ marginBottom: 15 }}>
          Si vous souhaitez inviter un évaluateur, veuillez l'inviter via le
          bouton ci-dessous
        </Text>
        <PrimaryButton
          styles={commonButtonStyles.buttonSubmit}
          onClick={(e: any) => pageActions.openAddEvaluateurModal(candidature)}
        >
          Inviter un évaluateur
        </PrimaryButton>
      </Stack>
    </CandidatureInfoHeaderWrapper>
  );
}

export default InviterEvaluateurButton;
