import { DatePicker, Label, Stack, Text } from "@fluentui/react";
import { useState } from "react";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { strings } from "../../../config/datePickerConfig";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";

function ValidDemandeHabilitationModal({ demandes, onValidDemande }: any) {
  const [dateDemande, setDateDemande] = useState({
    date_debut: new Date(Date.now()),
    date_fin: null,
  });
  const {
    actions: { closeModal },
  } = useCustomModal();

  const handleChangeDate = (e: any) => {
    const { name, value } = e.target;
    setDateDemande({ ...dateDemande, [name]: value });
  };

  const onClickValidButton = () => {
    onValidDemande({
      ...dateDemande,
      demandes: demandes.map((demande: any) => demande.uuid),
    });
    closeModal();
  };

  return (
    <Stack>
      <Text style={{ color: "#979797" }}>
        Veuillez renseigner les dates de début et de fin des demande(s)
        d'habilitation sélectionnée(s) pour valider la(les) habilitation(s).
      </Text>

      <Stack.Item>
        <Label htmlFor="date_debut" required styles={commonInputStyles.label}>
          Date de début :
        </Label>
        <DatePicker
          formatDate={(date?: any): any =>
            date ? `${tools.reformateDateFromJsDate(date)}` : null
          }
          strings={strings}
          showGoToToday={false}
          value={
            dateDemande.date_debut
              ? new Date(dateDemande.date_debut)
              : undefined
          }
          styles={commonInputStyles.dataPicker}
          onSelectDate={(date: any) => {
            if (date) {
              handleChangeDate({
                target: {
                  name: "date_debut",
                  value: new Date(date),
                },
              });
            }
          }}
          placeholder="dd/mm/yyyy"
          allowTextInput
          parseDateFromString={(dateStr: any) =>
            tools.parseDateFromStringDatePicker(dateStr)
          }
        />
      </Stack.Item>

      <Stack.Item>
        <Label htmlFor="date_fin" required styles={commonInputStyles.label}>
          Date de fin :
        </Label>
        <DatePicker
          formatDate={(date?: any): any =>
            date ? `${tools.reformateDateFromJsDate(date)}` : null
          }
          strings={strings}
          showGoToToday={false}
          value={
            dateDemande.date_fin ? new Date(dateDemande.date_fin) : undefined
          }
          styles={commonInputStyles.dataPicker}
          onSelectDate={(date: any) => {
            if (date) {
              handleChangeDate({
                target: {
                  name: "date_fin",
                  value: new Date(date),
                },
              });
            }
          }}
          placeholder="dd/mm/yyyy"
          allowTextInput
          parseDateFromString={(dateStr: any) =>
            tools.parseDateFromStringDatePicker(dateStr)
          }
        />
      </Stack.Item>

      <LoadButton
        //isLoading={isLoadingValidDemandeHabilitations}
        text="Valider"
        onClick={onClickValidButton}
        style={{ margin: "10px 0px" }}
        styles={commonButtonStyles.validateButton}
      />
    </Stack>
  );
}

export default ValidDemandeHabilitationModal;
