import { atom, useAtom } from "jotai";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../../hooks/useAuth";
import tools from "../../../utils/tools";
import { isConsultCandidaturesListAtom } from "../../CandidatJuryList/useCandidatureJuryList";
import ValidationDecisionMembreJuryModal from "./ValidationDecisionMembreJuryModal/ValidationDecisionMembreJuryModal";

export const DecisionJury = React.createContext({});
export const DecisionJuryProvider = ({ value, children }: any) => {
  return (
    <DecisionJury.Provider value={value}>{children}</DecisionJury.Provider>
  );
};

const isSavedAvisJuryAtom = atom(false);
//const isViewMembreAsAdminAtom = atom(true)

export const useDecisionJury = () => {
  //const [isViewMembreAsAdmin, setIsViewMembreAsAdmin] = useAtom(isViewMembreAsAdminAtom)
  const [isSaving, setIsSaving] = useState(false);
  const [isSavedAvisJury, setIsSavedAvisJury] = useAtom(isSavedAvisJuryAtom);
  const {
    candidature,
    candidatureSelectableBlocs,
    actions: candidatureActions,
    generalConditionnalDisplayVariables,
  } = useCandidature();
  const [isConsultCandidaturesList] = useAtom(isConsultCandidaturesListAtom);
  const { isPresidentForThisCandidature, isMembreForThisCandidature } =
    generalConditionnalDisplayVariables;

  const { auth }: any = useAuth();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const conditionnalDisplayVariables = {
    canRightDecisionPresident:
      auth.user.est_administrateur ||
      (isPresidentForThisCandidature &&
        tools.isThisStartOfDateBeforeOrEqualStartOfToday(
          candidature.jury?.date_acces_membres_jury
        )),
    canRightDecisionMembre:
      isMembreForThisCandidature &&
      tools.isThisStartOfDateBeforeOrEqualStartOfToday(
        candidature.jury?.date_acces_membres_jury
      ),
    userViewingPageUuid: auth.user.uuid,
    // isAlreadyGiveAvis: candidature.avis_jury?.find(
    //   (a: any) => a.membre_jury.uuid == auth.user.uuid
    // ),
  };

  const actions = {
    onSaveMembre: async (data: any) => {
      setIsSaving(true);

      const avisMembreViewvingPage = data.avis_jury.find(
        (m: any) =>
          m.membre_jury.uuid == conditionnalDisplayVariables.userViewingPageUuid
      );
      const avisMembreObject = {
        avis: avisMembreViewvingPage.avis.toUpperCase(), // to fix constant API ?
        commentaire: avisMembreViewvingPage.commentaire,
      };

      try {
        await candidatureActions.postAvisMembreJury(
          avisMembreObject,
          candidature.uuid,
          reactHookProps
        );
        setIsSavedAvisJury(true);
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
      }

      setIsSaving(false);
    },
    onSavePresident: async (data: any, isGoToNextCandidature: boolean) => {
      setIsSaving(true);

      const presidentJuryObject = {
        ...data,
        decision_jury: data.blocs_competences_passes.map((b: any) => ({
          uuid: b.candidature_bloc_competence_uuid,
          decision: b.decision_president,
          commentaire_president: b.commentaire_president,
          competences_associees: b.competences.map((c: any) => ({
            uuid: c.uuid,
            decision: c.decision_president,
          })),
        })),
      };

      try {
        await candidatureActions.putCandidatureFormData(
          presidentJuryObject,
          STATUT_PAGE,
          reactHookProps,
          isGoToNextCandidature
        );
        setIsSavedAvisJury(true);
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
      }
      setIsSaving(false);
    },
    onChangeDecisionMembre: (e: any) => {
      const { name, value } = e.target;
      const decisionMembresPrevious = reactHookProps.watch("avis_jury");
      if (
        decisionMembresPrevious.find(
          (m: any) =>
            m.membre_jury.uuid ==
            conditionnalDisplayVariables.userViewingPageUuid
        )
      ) {
        reactHookProps.setValue(
          "avis_jury",
          (reactHookProps.watch("avis_jury") || []).map((avis: any) =>
            avis.membre_jury.uuid ==
            conditionnalDisplayVariables.userViewingPageUuid
              ? { ...avis, [name]: value }
              : avis
          )
        );
      } else {
        reactHookProps.setValue("avis_jury", [
          {
            membre_jury: {
              uuid: conditionnalDisplayVariables.userViewingPageUuid,
            },
            [name]: value,
          },
        ]);
      }
    },
    openDecisionMembreJuryModal: (data: any) => {
      modalActions.openModal({
        header: "Valider mon avis",
        body: (
          <ValidationDecisionMembreJuryModal
            avis={data}
            reactHookProps={reactHookProps}
          />
        ),
        fullWidth: true,
      });
    },
    preFillDecisionPresidentJury: () => {
      const preFillAvisPresidentJuryBloc = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_president: b.decision_evaluation,
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_president: c.decision_evaluation,
          })),
        }));
      reactHookProps.setValue(
        "blocs_competences_passes",
        preFillAvisPresidentJuryBloc
      );
    },
    changeAdminVision: () => {},
    onValidate: () => {
      const validateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_president: "VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_president: "VALIDE",
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", validateAll);
    },
    onInvalidate: () => {
      const invalidateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_president: "NON_VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_president: "NON_VALIDE",
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", invalidateAll);
    },
  };

  return {
    isSaving,
    candidature,
    reactHookProps,
    actions,
    candidatureSelectableBlocs,
    conditionnalDisplayVariables,
    generalConditionnalDisplayVariables,
    isSavedAvisJury,
    setIsSavedAvisJury,
    candidatureActions,
    isConsultCandidaturesList,
    // isViewMembreAsAdmin,
    // setIsViewMembreAsAdmin
  };
};
