import { CommandButton, Stack } from "@fluentui/react";
import { FaDownload } from "react-icons/fa";
import FileDownloadLink from "../FileDownloadLink/FileDownloadLink";

export default function FileDisplay({
  file,
  onRemoveFile = () => {},
  existingFileURL,
  disabled,
  readOnly,
}: any) {
  var fileExt: any = "";
  var fileName: any = "";

  if (existingFileURL) {
    const urlInstance = new URLSearchParams(existingFileURL);
    fileName = urlInstance.get("file_name");
    fileExt = urlInstance.get("file_name")?.split(".")[1];
  } else {
    let fname = file?.name
      ? file.name
      : "Fichier corrompu, veuillez le supprimer.ERREUR";
    fileExt = !existingFileURL ? fname.split(".") : existingFileURL.split(".");
    fileName = !existingFileURL ? fname : existingFileURL.split("/");
    fileExt = fileExt[fileExt.length - 1];
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 250,
      }}
    >
      <FileDownloadLink existingFileURL={existingFileURL} file={file}>
        <Stack.Item
          style={{
            textTransform: "uppercase",
            fontSize: 24,
            textAlign: "center",
          }}
        >
          {fileExt}
        </Stack.Item>
        <Stack.Item>
          <strong>{fileName}</strong>
        </Stack.Item>
        {existingFileURL && !file && (
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            style={{ marginTop: 15, textDecoration: "none", color: "white" }}
          >
            <FaDownload size={28} />
            <strong style={{ fontSize: 18, marginTop: 10 }}>Télécharger</strong>
          </Stack>
        )}
      </FileDownloadLink>

      {!readOnly && (
        <Stack horizontalAlign="center" tokens={{ childrenGap: 5 }}>
          <CommandButton disabled={disabled} onClick={onRemoveFile}>
            Supprimer ce document
          </CommandButton>
        </Stack>
      )}
    </div>
  );
}
