import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useDemandeInscriptionMutations from "./useDemandeInscriptionMutations";

function useDemandeInscription() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { mutations, setIsToggleInscriptionUuid, isToggleInscriptionUuid } =
    useDemandeInscriptionMutations();

  const actions = {
    validerInscription: async (user: any) => {
      setIsToggleInscriptionUuid(user.uuid);
      mutations.ValiderInscription.mutate(user.uuid);
    },
    validerInscriptionEnMasse: async (users: any) => {
      mutations.ValiderInscriptionEnMasse.mutate(users.map((u: any) => u.uuid));
    },
    refuserInscription: async (user: any, commentaire: any) => {
      mutations.RefuserInscription.mutate({
        userUuid: user.uuid,
        commentaire,
      });
    },
  };

  const {
    isLoading: isLoadingAccepterInscription,
    isError: isErrorAccepterInscription,
    error: errorAccepterInscription,
    isSuccess: isSuccessAccepterInscription,
  } = mutations.ValiderInscription;

  const {
    isLoading: isLoadingRefuserInscription,
    isError: isErrorRefuserInscription,
    error: errorRefuserInscription,
    isSuccess: isSuccessRefuserInscription,
  } = mutations.RefuserInscription;

  return {
    userSelectedBranch,
    isLoading: isLoadingAccepterInscription || isLoadingRefuserInscription,
    hasError: isErrorAccepterInscription || isErrorRefuserInscription,
    errorAccepterInscription,
    isSuccessAccepterInscription,
    errorRefuserInscription,
    isSuccessRefuserInscription,
    actions,
    isToggleInscriptionUuid,
  };
}

export default useDemandeInscription;
