import {
  Stack,
  TextField,
  DefaultButton,
  Text,
  Link,
  MessageBar,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";
import useTheme from "../../../../Common/useTheme";
import {
  commonInputStyles,
  commonButtonStyles,
  MessageBarStyles,
} from "../../../../styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../../config/config";
import { verifyCompanySIRET } from "../../../../services/companyServices";
import ErrorMessage from "../../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { toast } from "react-toastify";
import validations from "../../../../utils/validations";

export function AddEntrepriseForm({ onAddEntreprise }: any) {
  const { actions } = useCustomModal();
  const { primaryColor } = useTheme();
  const [searchResult, setSearchResult]: any = useState([]);
  const [errors, setErrors]: any = useState({});
  const [existenceVerified, setExistenceVerified] = useState(false);
  const canCreateNewEntreprise =
    searchResult.length == 0 && existenceVerified && !errors.siret;
  const [newEntreprise, setNewEntreprise] = useState({
    nom: "",
    siret: "",
    ref_idcc: "",
    adresse: "",
    complement_adresse: "",
    code_postal: "",
    region: "",
    ville: "",
  });

  const handleChangeNewEntreprise = (name: any, value: any) => {
    if (name == "siret") {
      setExistenceVerified(false);
    }
    setErrors({ ...errors, [name]: "" });
    setNewEntreprise({ ...newEntreprise, [name]: value });
  };

  const validateEntreprise = (entreprise: any) => {
    let validationErrors: any = {};
    const validationOf = validations.validateEntreprise(entreprise);
    if (validationOf?.error) {
      validationOf.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message;
      });
    }
    setErrors(validationErrors);
    let isValid = Object.keys(validationErrors).length === 0;
    if (!isValid) {
      toast.error("Le formulaire comporte des erreurs.");
    }
    return isValid;
  };

  const onSaveEntreprise = (data?: any) => {
    if (data) {
      var entrepriseExistante = {
        ...data,
        adresse: data.adresse?.adresse,
        complement_adresse: data.adresse?.complement_adresse,
        code_postal: data.adresse?.code_postal,
        region: data.adresse?.region,
        ville: data.adresse?.ville,
      };
    }
    if (validateEntreprise(entrepriseExistante ?? newEntreprise)) {
      onAddEntreprise(data || newEntreprise);
      actions.closeModal();
    }
  };

  const onVerifySiret = async () => {
    setErrors({});
    try {
      const data: any = await verifyCompanySIRET(newEntreprise.siret);

      setSearchResult(data?.uuid ? [data] : []);
      setExistenceVerified(true);
    } catch (err: any) {
      if (err?.response?.data?.message) {
        setErrors({ siret: err.response.data.message });
      }
    }
  };

  const getBlocsInfoMessage = () => {
    if (searchResult.length > 0) {
      return "Une Entreprise possédant ce SIRET existe déjà en base de données :";
    } else {
      return "Aucune Entreprise avec ce SIRET n'a été trouvée en base de données. Vous pouvez en créer une en renseignant les champs ci-dessous.";
    }
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Text style={{ color: "#979797" }}>
        Sélectionnez ou ajoutez une entreprise afin de pouvoir y accéder dans
        les parties prenantes de la candidature.
      </Text>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          type="text"
          label="SIRET (14 chiffres) :"
          placeholder="Ex: 12312312312312"
          required
          name="siret"
          onChange={(e: any, newValue: any) => {
            if (!newValue || newValue.length <= 14) {
              handleChangeNewEntreprise("siret", e.target.value);
            }
          }}
          value={newEntreprise.siret}
        />
        <ErrorMessage errorMessage={errors.siret} />
      </Stack.Item>

      <Stack.Item>
        <DefaultButton
          onClick={onVerifySiret}
          styles={commonButtonStyles.defaultButton}
          style={{
            color: primaryColor,
            borderColor: primaryColor,
            maxWidth: 270,
          }}
        >
          Vérifier l'existence du SIRET
        </DefaultButton>
      </Stack.Item>

      <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
        {existenceVerified && searchResult.length === 0 && (
          <MessageBar styles={MessageBarStyles}>
            {getBlocsInfoMessage()}
          </MessageBar>
        )}
        {searchResult.length > 0 && (
          <>
            {searchResult.map((item: any, index: any) => (
              <Stack
                horizontal
                verticalAlign="center"
                style={{ marginLeft: 30, marginBottom: 10 }}
                key={`profil${index}`}
              >
                <p
                  style={{
                    color: "grey",
                    fontStyle: "italic",
                    margin: "5px 0px 0px",
                    fontSize: 16,
                  }}
                >
                  {item.nom} - {item.siret}
                </p>
                <Link
                  onClick={() => onSaveEntreprise(item)}
                  styles={commonButtonStyles.buttonLink}
                  style={{ marginLeft: 20, marginTop: 5 }}
                >
                  Cliquez ici pour utiliser cette entreprise existante
                </Link>
              </Stack>
            ))}
          </>
        )}
      </Stack.Item>
      {canCreateNewEntreprise && (
        <>
          <Stack.Item>
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Raison sociale :"
              type="text"
              placeholder="Ex: Ma société"
              name="nom"
              onChange={(e: any) =>
                handleChangeNewEntreprise("nom", e.target.value)
              }
              value={newEntreprise.nom}
              errorMessage={errors.nom}
            />
          </Stack.Item>
          <Stack.Item>
            <SelectFieldAsync
              label="Sélectionnez un IDCC :"
              placeholder="Recherchez un IDCC en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              renderValue={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              dataIndex="id"
              name="ref_idcc"
              value={newEntreprise.ref_idcc}
              required={true}
              onChange={(e: any) => {
                handleChangeNewEntreprise("ref_idcc", e.target.value);
              }}
              defaultValue={null}
              errorMessage={errors.ref_idcc}
              handleResponse={(response: any) => [...response.data.data]}
            />
          </Stack.Item>
          <Stack.Item>
            <TextField
              required={true}
              styles={commonInputStyles.textField}
              label="Adresse :"
              type="text"
              placeholder="Rue, adresse de boite postale, nom de société"
              name="adresse"
              onChange={(e: any) =>
                handleChangeNewEntreprise("adresse", e.target.value)
              }
              value={newEntreprise.adresse}
              errorMessage={errors.adresse}
            />
          </Stack.Item>

          <Stack.Item>
            <TextField
              styles={commonInputStyles.textField}
              label="Complément d'adresse :"
              type="text"
              placeholder="Appartement, suite, l'unité, batîment, étage, etc"
              name="complement_adresse"
              onChange={(e: any) =>
                handleChangeNewEntreprise("complement_adresse", e.target.value)
              }
              value={newEntreprise.complement_adresse}
            />
          </Stack.Item>

          <Stack.Item>
            <SelectFieldAsync
              required={true}
              label="Code postal :"
              placeholder="Recherchez un code postal en tapant les premiers chiffres"
              resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?code_postal=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.code_postal + " | " + item.nom_commune
              }
              renderValue={(item: any) => item}
              dataIndex="uuid"
              onChange={(e: any) => {
                handleChangeNewEntreprise(
                  "code_postal",
                  e.target.value.code_postal
                );
                setNewEntreprise((prev: any) => ({
                  ...prev,
                  region: e.target.value.nom_region,
                  ville: e.target.value.nom_commune,
                }));
              }}
              defaultValue=""
              handleResponse={(response: any, terms: any) => {
                return response.data.data.length > 0
                  ? response.data.data
                  : [{ code_postal: terms, nom_commune: "Inconnu" }];
              }}
              name="code_postal"
              value={newEntreprise.code_postal}
              errorMessage={errors.code_postal}
            />
          </Stack.Item>
        </>
      )}
      {newEntreprise.code_postal && (
        <>
          <Stack.Item>
            <SelectFieldAsync
              required={true}
              label="Région :"
              placeholder="Veuillez sélectionner ou inscrire votre région"
              resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom_region=${terms}`
              }
              method="GET"
              renderItem={(item: any) => item}
              renderValue={(item: any) => item}
              dataIndex="uuid"
              onChange={(e: any) =>
                handleChangeNewEntreprise("region", e.target.value)
              }
              defaultValue=""
              fetchOnLoad={true}
              handleResponse={(response: any, terms: any) => {
                const regionsSingle = Array.from(
                  new Set(response.data.data.map((x: any) => x.nom_region))
                );

                return regionsSingle.length > 0 ? regionsSingle : [terms];
              }}
              name="region"
              value={newEntreprise.region}
              errorMessage={errors.region}
            />
          </Stack.Item>

          <Stack.Item>
            <SelectFieldAsync
              required={true}
              label="Ville :"
              placeholder="Veuillez sélectionner ou inscrire votre ville"
              resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom_commune=${terms}`
              }
              method="GET"
              renderItem={(item: any) => item}
              renderValue={(item: any) => item}
              dataIndex="uuid"
              onChange={(e: any) =>
                handleChangeNewEntreprise("ville", e.target.value)
              }
              defaultValue=""
              fetchOnLoad={true}
              handleResponse={(response: any, terms: any) => {
                const citySingle = Array.from(
                  new Set(response.data.data.map((x: any) => x.nom_commune))
                );

                return citySingle.length > 0 ? citySingle : [terms];
              }}
              name="ville"
              value={newEntreprise.ville}
              errorMessage={errors.ville}
            />
          </Stack.Item>
        </>
      )}
      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>
        {canCreateNewEntreprise && (
          <PrimaryButton
            onClick={() => onSaveEntreprise()}
            styles={commonButtonStyles.buttonSubmit}
            iconProps={{ iconName: "Add" }}
          >
            Ajouter l'entreprise
          </PrimaryButton>
        )}
      </Stack.Item>
    </Stack>
  );
}
