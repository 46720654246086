import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const updateAccountInformations = async (data: any) => {
    const xsrfHeader = await getCSRFCookieRequest();
    const response = await eCertifApi.patch(
      "me",
      data,
      xsrfHeader
    );
  
    return response.data;
  }