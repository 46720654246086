import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

enum StructureRoutesService {
  "entreprise" = "administration/entreprises",
  "organisme_de_formation" = "administration/ofs",
  "groupe_entreprise" = "administration/groupe-entreprises",
  "groupe_of" = "administration/groupe-ofs",
}

export type TStructureType =
  | "entreprise"
  | "organisme_de_formation"
  | "groupe_entreprise"
  | "groupe_of";

export const getMyStructureRequest = async ({
  structureType,
  structureUuid,
}: {
  structureType: TStructureType;
  structureUuid: string | null;
}) => {
  const response = await eCertifApi.get(
    `${StructureRoutesService[structureType]}/${structureUuid}`
  );
  return response.data;
};

export const putMyStructureRequest = async ({
  structureType,
  structure,
}: {
  structureType: TStructureType;
  structure: any;
}) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${StructureRoutesService[structureType]}/ma-structure/${structure.uuid}`,
    structure,
    xsrfHeader
  );
  return response.data;
};
