import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const userURI = "utilisateur/utilisateurs";

export const getUsersRequest = async (filters: any, options: any) => {
  const filterType = {
    role: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "roles[]",
    },
    nom: { type: "string" },
    prenom: { type: "string" },
    email: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    est_multibranches: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${userURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getAllUsersRequest = async (filters: any, options: any) => {
  const filterType = {
    nom: { type: "string" },
    prenom: { type: "string" },
    email: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    branches: { type: "array", keyValue: "id" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${userURI}/chercher-tous?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getUserRequest = async (userUuid: any) => {
  const response = await eCertifApi.get(`${userURI}/${userUuid}`);
  return response.data;
};

export const postUserRequest = async (user: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${userURI}`, user, xsrfHeader);
  return response.data;
};

export const putUserRequest = async (user: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${userURI}/${user.get("uuid")}`,
    user,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateUserRequest = async (userUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${userURI}/${userUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateUserRequest = async (userUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${userURI}/${userUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const sendPasswordRequest = async (user: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${userURI}/envoyer-mdp`,
    user,
    xsrfHeader
  );
  return {
    response: response.data,
    user,
  };
};

export const verifyUserEmail = async (email: any, role: any) => {
  const response = await eCertifApi.get(
    `${userURI}/chercher?email=${email}&roles[]=${role}`
  );
  //const response = await eCertifApi.get(`${userURI}/verifier-email?email=${user.email}`); WAITING API
  return response.data;
};

export const checkUtilisateurExistsRequest = async (userEmail: any) => {
  const response = await eCertifApi.get(
    `${userURI}/verifier?email=${userEmail}`
  );
  return response.data;
};

export const getUsersDemandeInscriptionRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    nom: { type: "string" },
    prenom: { type: "string" },
    email: { type: "string" },
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    profil: { type: "select", keyValue: "id", valueNotFilter: undefined },
    nom_structure: { type: "string" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `utilisateur/demandes-inscriptions/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getExportUsersExceptCandidats = async (filters: any) => {
  const filterType = {
    role: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "roles[]",
    },
    nom: { type: "string" },
    prenom: { type: "string" },
    email: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    est_multibranches: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    await eCertifApi.get(
      `exports/utilisateurs?paginate=false${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
