import { DefaultButton, Label, Stack, TextField } from "@fluentui/react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import tools from "../../../../utils/tools";

const AddOptionsForm = ({ options, onUpdateOptions }: any) => {
  const onChangeOption = (newOption: any, key: any) => {
    onUpdateOptions(options.map((opt: any, optKey: any) => (optKey == key ? newOption : opt)));
  };

  const onAddOption = () => {
    onUpdateOptions([...options, { key: "", text: "" }]);
  };

  const onDeleteOption = (optionKey: any) => {
    onUpdateOptions(options.filter((opt: any, key: any) => key !== optionKey));
  };

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack.Item>
        <Label>Options disponibles à la sélection ({options.length})</Label>
      </Stack.Item>
      {options.map((option: any, optionKey: any) => (
        <Stack.Item key={`option${optionKey}`} align="stretch" grow={2}>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{ width: "100%", alignItems: "flex-end" }}
          >
            <Stack.Item style={{ width: "100%" }}>
              <TextField
                required
                styles={commonInputStyles.textField}
                label={`Valeur de l'option ${parseInt(optionKey) + 1} :`}
                placeholder=""
                name="name"
                onChange={(e: any) =>
                  onChangeOption(
                    { ...option, text: e.target.value, key: tools.normalize(e.target.value) },
                    optionKey
                  )
                }
                value={option.text}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.deleteButtonLink}
                style={{ border: 0 }}
                onClick={() => onDeleteOption(optionKey)}
              >
                <IoMdCloseCircleOutline size={20} />
              </DefaultButton>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      ))}

      <Stack.Item>
        <DefaultButton onClick={onAddOption} styles={commonButtonStyles.defaultButton}>
          Ajouter une option
        </DefaultButton>
      </Stack.Item>
    </Stack>
  );
};

export default AddOptionsForm;
