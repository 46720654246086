import { useEffect, useState } from "react";
import Page from "../../../Common/Page/Page";
import useSuiviCohortes from "../../../hooks/suiviCohortes/useSuiviCohortes";
import FormulaireSuiviCohortes from "../FormulaireSuiviCohortes/FormulaireSuiviCohortes";
import { getConstantRequest } from "../../../services/publicServices";
import tools from "../../../utils/tools";

const PageSuiviCandidat = () => {
  const { actions } = useSuiviCohortes();
  const [constants, setConstants] = useState({});

  useEffect(() => {
    const getConstantsPublic = async () => {
      const response = await getConstantRequest();
      setConstants({
        CANDIDATURES: {
          SITUATION_APRES_CERTIFICATION: tools.addKeyToObjectInArray(
            response.CANDIDATURES.SITUATION_APRES_CERTIFICATION,
            "id"
          ),
          TYPES_CONTRAT: tools.addKeyToObjectInArray(
            response.CANDIDATURES.TYPES_CONTRAT,
            "id"
          ),
        },
      });
    };
    getConstantsPublic();
  }, []);

  return (
    <Page
      title="Suivi des cohortes"
      explain="Veuillez renseigner les champs ci-dessous."
    >
      <FormulaireSuiviCohortes
        onSave={actions.onSavePublic}
        constants={constants}
        isPrivate={false}
      />
    </Page>
  );
};

export default PageSuiviCandidat;
