import { useAtom } from "jotai";
import habilitationAdapters from "../../adapters/habilitationAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import useHabilitationMutation from "./useHabilitationMutation";

function useHabilitation() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { mutations, isSaved } = useHabilitationMutation();

  const actions = {
    postHabilitationOf: async (of: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        habilitationAdapters.transformForAPIOf(of),
        null
      );
      mutations.PostHabilitationOf.mutate(formData);
    },
    postHabilitationUtilisateur: async (utilisateur: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        habilitationAdapters.transformForAPIUtilisateur(utilisateur),
        null
      );
      mutations.PostHabilitationUtilisateur.mutate(formData);
    },
    removeHabilitations: async (data: any) => {
      mutations.RemoveHabilitations.mutate(data);
    },
  };

  const {
    isLoading: isLoadingPostHabilitationOf,
    isError: isErrorPostHabilitationOf,
    error: errorPostHabilitationOf,
    isSuccess: isSuccessPostHabilitationOf,
  } = mutations.PostHabilitationOf;

  const {
    isLoading: isLoadingPostHabilitationUtilisateur,
    isError: isErrorPostHabilitationUtilisateur,
    error: errorPostHabilitationUtilisateur,
    isSuccess: isSuccessPostHabilitationUtilisateur,
  } = mutations.PostHabilitationUtilisateur;

  const { isLoading: isLoadingRemoveHabilitations } =
    mutations.RemoveHabilitations;

  return {
    userSelectedBranch,
    isLoadingPostHabilitationOf,
    isLoadingPostHabilitationUtilisateur,
    isErrorPostHabilitationOf,
    isErrorPostHabilitationUtilisateur,
    isLoadingRemoveHabilitations,
    errorPostHabilitationOf,
    errorPostHabilitationUtilisateur,
    isSuccessPostHabilitationOf,
    isSuccessPostHabilitationUtilisateur,
    actions,
    isSaved,
  };
}

export default useHabilitation;
