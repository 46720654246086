import { atom, useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { toast } from "react-toastify";
import useDomaineMutations from "./useDomaineMutations";
import domaineAdapters from "../../adapters/domaineAdapters";
import { getDomainesRequest } from "../../services/domaineServices";
import { authAtom } from "../useAuth";

export const domainesAccessAtom = atom(null);

function useDomaine() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [, setDomainesAccess] = useAtom(domainesAccessAtom);
  const {
    domaineUuid,
    setDomaineUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
  } = useDomaineMutations();
  const [auth] = useAtom(authAtom);

  const actions = {
    getDomainesForUser: async (user: any, userEntites: any) => {
      const domaines = await getDomainesRequest();

      let domainesAccessList: any = [];
      if (
        user.profils.length == 0 ||
        user.profils?.includes("ADMINISTRATEUR_BRANCHE")
      ) {
        domainesAccessList = domaines.data.map((dom: any) => ({
          ...dom,
          key: dom.uuid,
          id: dom.uuid,
          text: dom.nom,
        }));
      } else {
        if (user.profils.includes("ADMINISTRATEUR_DOMAINE")) {
          const adminDomaineInfo = userEntites.domaine[0];
          domainesAccessList = [
            {
              ...adminDomaineInfo,
              key: adminDomaineInfo.uuid,
              id: adminDomaineInfo.uuid,
              text: adminDomaineInfo.nom,
            },
          ];
        }
        if (user.profils.includes("OF")) {
          //a modifier quand profil multi branche
          const allOfLinked = (auth.user as any).profils.find(
            (p: any) => p.profil == "OF"
          ).entites;
          const userEntitesUuid = allOfLinked.map(
            (of: any) => of.attributes.uuid
          );
          domaines.data.map((dom: any) => {
            if (dom.ofs.some((of: any) => userEntitesUuid.includes(of.uuid))) {
              domainesAccessList.push({
                ...dom,
                key: dom.uuid,
                id: dom.uuid,
                text: dom.nom,
              });
            }
          });
        }
      }

      setDomainesAccess(domainesAccessList);
    },
    setUpdateDomaineUuid: (data: any) => {
      setDomaineUuid(data);
    },
    postDomaine: async (domaine: any) => {
      mutations.PostDomaine.mutate(domaineAdapters.transformForAPI(domaine));
    },
    putDomaine: async (domaine: any) => {
      mutations.PutDomaine.mutate(domaineAdapters.transformForAPI(domaine));
    },
    toggleDomaineActivation: (domaine: any) => {
      setIsToggleActivationUuid(domaine.uuid);
      if (domaine.actif) {
        mutations.ToggleDomaineDesactivate.mutate(domaine.uuid);
      } else {
        mutations.ToggleDomaineActivate.mutate(domaine.uuid);
      }
    },
    deleteDomaine: async (domaineUuid: string) => {
      toast.info(`En cours de développement`);
      // mutations.DeleteDomaine.mutate(domaineUuid);
    },
  };

  const {
    isLoading: isLoadingPostDomaine,
    isError: isErrorPostDomaine,
    error: errorPostDomaine,
    isSuccess: isSuccessPostDomaine,
  } = mutations.PostDomaine;

  const {
    isLoading: isLoadingPutDomaine,
    isError: isErrorPutDomaine,
    error: errorPutDomaine,
    isSuccess: isSuccessPutDomaine,
  } = mutations.PutDomaine;

  return {
    userSelectedBranch,
    domaineUuid,
    isLoading: isLoadingPostDomaine || isLoadingPutDomaine,
    hasError: isErrorPostDomaine || isErrorPutDomaine,
    errorPostDomaine,
    isSuccessPostDomaine,
    errorPutDomaine,
    isSuccessPutDomaine,
    isToggleActivationUuid,
    actions,
  };
}

export default useDomaine;
