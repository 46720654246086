import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";

function RefuserInscriptionModal({
  user,
  onRefuserInscription = () => {},
}: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const [commentaire, setCommentaire] = useState("");

  const onSubmit = async () => {
    onRefuserInscription(commentaire);
    closeModal();
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          required
          styles={commonInputStyles.textField}
          label={"Justification du refus :"}
          multiline
          rows={6}
          maxLength={3000}
          name={"commentaire"}
          value={commentaire}
          onChange={(e: any) => setCommentaire(e.target.value)}
        />
      </Stack.Item>
      <Stack.Item
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PrimaryButton
          text="Refuser"
          onClick={onSubmit}
          style={{ width: "200px", margin: "10px 0px" }}
          styles={commonButtonStyles.deleteButton}
          disabled={!commentaire}
        />
      </Stack.Item>
    </Stack>
  );
}

export default RefuserInscriptionModal;
