import { Stack } from "@fluentui/react";
import SectionTitle from "../../../Common/SectionTitle/SectionTitle";
import CandidatInformationsPreview from "../../../Common/CandidatInformationsPreview/CandidatInformationsPreview";

function CandidatSection({ candidat }: any) {
  return (
    <Stack style={{ marginBottom: "0.5rem" }}>
      <SectionTitle
        title="Candidat"
        style={{
          fontSize: 18,
          marginTop: 0,
          marginBottom: 0,
        }}
      />
      <CandidatInformationsPreview
        candidat={candidat}
        extraInformation={false}
      />
    </Stack>
  );
}

export default CandidatSection;
