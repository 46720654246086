import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import SubGroupOfListFilters from "./SubGroupOfListFilters/SubGroupOfListFilters";
import useSubGroupOfList from "./useSubGroupOfList";
import Can from "../../Common/Can/Can";
import { userAccessEnum } from "../../config/accessEnum";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useTheme from "../../Common/useTheme";

function SubGroupOfList() {
  const {
    subGroupOfs,
    optionsFilters,
    actions: actionsPage,
    isLoadingExportSubGroupOf,
  } = useSubGroupOfList();
  const { primaryColor } = useTheme();

  const items = subGroupOfs?.data || [];
  const columns = [
    {
      key: "1",
      name: "Sous-groupe",
      fieldName: "nom",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "2",
      name: "Nom(s) utilisateur(s)",
      fieldName: "nom_utilisateurs",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.utilisateurs?.map((utilisateur: any, i: number) => (
            <Text key={i}>{utilisateur.nom}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "3",
      name: "Emails) des utilisateur(s)",
      fieldName: "email_utilisateurs",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.utilisateurs?.map((utilisateur: any, i: number) => (
            <Text key={i}>{utilisateur.email}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "4",
      name: "Branche(s) liée(s)",
      fieldName: "branches_liees",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.branches_liees?.map((branche_liee: any, i: number) => (
            <Text key={i}>{branche_liee.nom}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Can I={userAccessEnum.MODIFIER_SOUS_GROUPE}>
              <Link
                to={`/admin/sous-groupes-ofs/${item.uuid}`}
                as={RouterLink}
                style={{ marginLeft: 5, marginTop: 5 }}
                styles={commonButtonStyles.buttonLink}
              >
                Editer
              </Link>
            </Can>
            <Link
              onClick={() => actionsPage.openDeleteSubGroupOfModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>
            <Link
              onClick={() => toast.info("En cours de développement")}
              style={{ marginLeft: 5, marginTop: 5 }}
              styles={commonButtonStyles.buttonLink}
            >
              Envoyer le mot de passe
            </Link>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des sous-groupes d'OFs"
        explain="Veuillez gérer les sous-groupes d'OF qui vous sont rattachés"
      >
        <Stack
          horizontal
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          {/* <CreateLink text="Créer un nouveau sous-groupe d'OFs" to="#" /> */}
          <Can I={userAccessEnum.EXPORTER_UTILISATEURS_ET_ENTITES}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les données sous-groupes d'OFs"
                isLoading={isLoadingExportSubGroupOf}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportSubGroupOf}
                primary={false}
              />
            </div>
          </Can>
        </Stack>

        <SubGroupOfListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={subGroupOfs?.meta.current_page || 1}
          totalPages={Math.ceil(subGroupOfs?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default SubGroupOfList;
