import { Outlet, useLocation } from "react-router-dom";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";

import { useAtom } from "jotai";
import SelectBranch from "../../Pages/Login/SelectBranch";
import queryString from "query-string";
import { useEffect } from "react";
import useNav from "../../hooks/useNav";
import useAuth from "../../hooks/useAuth";
import useAddQueryParamsOnRefresh from "../../hooks/useAddQueryParamsOnRefresh";

import { allBranchesAtom } from "../../Pages/Login/useHome";

const RequireBranchSelection = () => {
  const [userSelectedBranch]: any = useAtom(userSelectedBranchAtom);
  const [allBranches] = useAtom(allBranchesAtom);
  const location = useLocation();
  const { actions: navActions } = useNav();
  const { auth } = useAuth();
  useAddQueryParamsOnRefresh();

  const parsed = queryString.parse(location.search);

  useEffect(() => {
    if (parsed?.branchId) {
      if (userSelectedBranch?.id !== parsed?.branchId) {
        const desiredBranch: any = auth.user.mes_branches.find(
          (branch: any) => branch.id == parsed.branchId
        );
        if (desiredBranch) {
          const brancheRef: any = allBranches?.data.find(
            (e: any) => e.id == desiredBranch.id
          );
          navActions.changeBranch({ ...desiredBranch, ...brancheRef });
        }
      }
    }
  }, []);

  return userSelectedBranch || location.pathname == "/select-branch" ? (
    <Outlet />
  ) : (
    <SelectBranch />
  );
};

export default RequireBranchSelection;
