import tools from "../utils/tools";

const candidatAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      uuid: data.uuid,
      nom: data.nom,
      nom_usage: data.nom_usage,
      prenom: data.prenom,
      date_de_naissance: tools.formatDate(data.date_de_naissance),
      civilite: data.civilite?.id || 3,
      email: data.email,
      telephone_portable: data.telephone_portable?.replaceAll(" ", ""),
      telephone_professionnel: data.telephone_professionnel?.replaceAll(
        " ",
        ""
      ),
      ne_etranger: data.candidat.ne_etranger,
      utilisateur_uuid: data.utilisateur_uuid,
      adresse: {
        adresse: data.adresse,
        complement_adresse: data.complement_adresse,
        code_postal: data.code_postal,
        region: data.region,
        ville: data.ville,
      },
    };

    if (data.adresse_uuid) {
      transformed.adresse.uuid = data.adresse_uuid;
    }

    if (data.candidat.ne_etranger) {
      transformed.pays_naissance = data.candidat.pays_naissance;
    } else {
      transformed.code_postal_naissance = data.candidat.code_postal_naissance;
      transformed.region_naissance = data.candidat.region_naissance;
      transformed.ville_naissance = data.candidat.ville_naissance;
    }

    return transformed;
  },
  transformForBO: (data: any, constants: any) => {
    let transformed: any = {
      ...data,
      civilite: tools.findIn(constants["CIVILITE"], data.civilite, "id"),
      telephone_professionnel: data.telephone_professionnel || "",
      telephone_portable: data.telephone_portable || "",
      date_de_naissance: data.date_de_naissance,
      adresse_uuid: data.adresse?.uuid,
      adresse: data.adresse?.adresse,
      complement_adresse: data.adresse?.complement_adresse,
      code_postal: data.adresse?.code_postal,
      region: data.adresse?.region,
      ville: data.adresse?.ville,
      candidat: {
        ne_etranger: data.ne_etranger,
        pays_naissance: data.pays_naissance,
        code_postal_naissance: data.code_postal_naissance,
        region_naissance: data.region_naissance,
        ville_naissance: data.ville_naissance,
      },
    };

    return transformed;
  },
};

export default candidatAdapters;
