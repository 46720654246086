import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import Page from "../../Common/Page/Page";
import { commonButtonStyles } from "../../styles";
import useTheme from "../../Common/useTheme";
import useExportCaisseDesDepots from "../../hooks/useExportCaisseDesDepots";
import Pagination from "../../Common/Pagination/Pagination";
import Loader from "../../Common/Loader/Loader";
import tools from "../../utils/tools";
import DownloadFileLink from "../../Common/DownloadFileLink/DownloadFileLink";

function ExportCaisseDepotPage() {
  const { primaryColor } = useTheme();
  const {
    actions: actionsExport,
    exportCaisseDepotsData,
    isLoadingExportCaisseDepotsData,
    optionsFilters,
  } = useExportCaisseDesDepots();

  const items: any = exportCaisseDepotsData?.data || [];

  const columns = [
    {
      key: "1",
      name: "Identifiant",
      fieldName: "identifiant",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => <Text>{item.uuid}</Text>,
    },
    {
      key: "2",
      name: "Date de création",
      fieldName: "date_limite_inscription_candidat",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.created_at)}</Stack>
      ),
      onColumnClick: () => {
        actionsExport.sortColumn("date_creation");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "date_creation"),
    },
    {
      key: "3",
      name: "Date de début de l'export",
      fieldName: "date_limite_inscription_candidat",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_debut)}</Stack>
      ),
      onColumnClick: () => {
        actionsExport.sortColumn("date_debut");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "date_debut"),
    },
    {
      key: "4",
      name: "Date de fin de l'export",
      fieldName: "date_limite_inscription_candidat",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_fin)}</Stack>
      ),
      onColumnClick: () => {
        actionsExport.sortColumn("date_fin");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "date_fin"),
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        if (!item.url) {
          return null;
        }
        return (
          <DownloadFileLink
            fileUrl={item.url}
            fileName={item.nom_fichier}
            styles={commonButtonStyles.buttonLink}
            text={"Télécharger"}
          />
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Export pour la Caisse des dépôts"
        explain="En tant que certificateur, vous êtes dans l’obligation de transmettre les données des personnes, que vous avez certifiées sur vos certifications inscrites au RNCP ou au RS, dans un délai de 3 mois maximum suite à leur certification. Le fichier proposé par Certifi@ est au format attendu par la Caisse des dépôts et ne contient que vos candidats certifiés sur vos certifications inscrites au RNCP ou au RS."
      >
        <Stack horizontal wrap verticalAlign="center" style={{ marginTop: 25 }}>
          <DefaultButton
            type="button"
            text="Exporter pour la Caisse des Dépôts"
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
            onClick={actionsExport.openExportCaisseDesDepot}
          />
        </Stack>

        {isLoadingExportCaisseDepotsData ? (
          <Loader />
        ) : (
          <>
            <DetailsList
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              items={items}
              columns={columns}
            />

            <Pagination
              changePage={actionsExport.changePage}
              page={exportCaisseDepotsData?.meta.current_page || 1}
              totalPages={
                Math.ceil(exportCaisseDepotsData?.meta.total / 15) || 1
              }
            />
          </>
        )}
      </Page>
    </Stack>
  );
}

export default ExportCaisseDepotPage;
