import { Stack, DefaultButton, TextField, Dropdown } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCertificationList from "../useCertificationList";
import { commonInputStyles, commonButtonStyles } from "../../../styles/index";

import { stateList } from "../../../fakeData/State.data";
import { API_URL } from "../../../config/config";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import useConstants from "../../../hooks/useConstants";
import { useState } from "react";
import { useQuery } from "react-query";
import { getDomainesRequest } from "../../../services/domaineServices";

function CertificationListFilters() {
  const { certificationProps, certifications, isLoadingCertifications, actions, reactHookProps } = useCertificationList();
  const { userSelectedBranch } = certificationProps
  const { constants } = useConstants();
  const [showFilters, setShowFilters] = useState(false);
  const certificationTypes = [
    { key: 0, text: "Tous les types" },
    ...constants["CERTIFICATIONS"]["TYPES"],
  ];

  const { data: domaines } = useQuery(
    ["domaines", userSelectedBranch], getDomainesRequest,
    {
      enabled: userSelectedBranch.gestion_par_domaine,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
  const domainesFromApi = domaines?.data.map((domaine: any) => ({
    id: domaine.uuid,
    key: domaine.uuid,
    text: `${domaine.nom}`,
  })) || []

  const totalCertifications = certifications?.meta.total || 0;

  const { handleSubmit, control, setValue, watch } = reactHookProps

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack style={{ marginBottom: 24 }} horizontalAlign="start" horizontal wrap>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="libelle"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Libellé de la certification :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Certification active :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionnez un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="active"
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Type de certification :"
                  selectedKey={value ? (value as any).key : null}
                  placeholder="Sélectionnez un type"
                  options={certificationTypes}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={certificationTypes[0]}
              control={control}
              name="type"
            />
          </Stack.Item>
          {(watch("type") as any)?.id == 12 && (
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                name="type_libelle"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Libellé du type :"
                    styles={commonInputStyles.textField}
                    type="text"
                    {...field}
                    defaultValue=""
                  />
                )}
              />
            </Stack.Item>
          )}
          {userSelectedBranch.gestion_par_domaine && (
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <Dropdown
                    placeholder="Sélectionnez un domaine"
                    label="Domaines :"
                    selectedKeys={value ? value.map((domaine: any) => domaine.key) : null}
                    options={domainesFromApi}
                    styles={commonInputStyles.dropdownStyle}
                    calloutProps={{ doNotLayer: true }}
                    onChange={(e: any, options: any) => {
                      let actualValue = watch(name);

                      actualValue.find((o: any) => o.key == options.key)
                        ? actualValue = actualValue.filter((o: any) => o.key != options.key)
                        : actualValue = [...actualValue, options]

                        setValue(name, actualValue, { shouldValidate: true })
                    }}
                    multiSelect
                  />
                )}
                control={control}
                name="domaines"
              />
            </Stack.Item>
          )}

          {showFilters && (
            <Stack.Item className="ms-Grid-col ms-sm12 ms-lg6" style={{ paddingRight: 20, marginTop: 20 }}>
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="Blocs de compétences liés à la certification :"
                    placeholder="Recherchez un bloc de compétence en tapant les premières lettres de son nom"
                    resourceURI={`${API_URL}api/administration/certifications/bloc-competences/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?libelle=${terms}`
                    }
                    method="GET"
                    renderItem={(item: any) => item.libelle}
                    renderValue={(item: any) => item.libelle}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    //errorMessage={(errors.ref_idcc as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                //defaultValue={undefined}
                control={control}
                name="blocs_competences"
              />
            </Stack.Item>
          )}
        </Stack>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="end" wrap>
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingCertifications} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={showFilters ? "Recherche simplifiée" : "Recherche avancée"}
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalCertifications} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default CertificationListFilters;
