import { toast } from "react-toastify";
import { getCandidatureRequest, getCandidaturesRequest } from "../../services/candidatureService";
import { atom, useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useAuth from "../useAuth";
import { isOfflineAtom } from "../useNav";
import { API_URL, APP_URL } from "../../config/config";
import { CHANGE_IS_OFFLINE } from "../../config/offlineConfig";
import { useNavigate } from "react-router-dom";
import useEvaluationObject from "./useEvaluationObject";
import candidatureAdapters from "../../adapters/candidatureAdapters";
import useConstants from "../useConstants";
import useDynamicFields from "../dynamicFields/useDynamicFields";

export const SYNCHRO_STATUS: any = {
  NOT_SYNCHRONIZED: "NOT_SYNCHRONIZED",
  SYNCHRONIZING: "SYNCHRONIZING",
  SYNCHRONIZED: "SYNCHRONIZED",
};
export const SYNCHRO_STATUS_NAMES: any = {
  NOT_SYNCHRONIZED: "Non synchronisées",
  SYNCHRONIZING: "En cours de synchronisation",
  SYNCHRONIZED: "Synchronisées",
};
export const SYNCHRO_STATUS_NAMES_SING: any = {
  NOT_SYNCHRONIZED: "Non synchronisée",
  SYNCHRONIZING: "En cours de synchronisation",
  SYNCHRONIZED: "Synchronisée",
};
export const SYNCHRO_STATUS_COLORS: any = {
  NOT_SYNCHRONIZED: "#b93504",
  SYNCHRONIZING: "blue",
  SYNCHRONIZED: "green",
};
export const SYNCHRO_STATUS_ICONS: any = {
  NOT_SYNCHRONIZED: "StreamingOff",
  SYNCHRONIZING: "SyncToPC",
  SYNCHRONIZED: "CloudUpload",
};

export const TREATMENT_STATUS: any = {
  NOT_TREATED: "NOT_TREATED",
  TREATED: "TREATED",
};
export const TREATMENT_STATUS_NAMES: any = {
  NOT_TREATED: "Non traitée",
  TREATED: "Traitée",
};
export const TREATMENT_STATUS_COLORS: any = {
  NOT_TREATED: "grey",
  TREATED: "green",
};
export const TREATMENT_STATUS_ICONS: any = {
  NOT_TREATED: "HourGlass",
  TREATED: "CheckMark",
};

export const initialFilters: any = {
  nom: "",
  prenom: "",
  entreprises: [],
  ofs: [],
  certifications: { id: undefined, text: "Toutes", key: "all" },
  en_attente_de_ma_part: false,
  domaines: [],
};

const optionsFiltersAtom = atomWithReset({
  sort: "",
  paginate: true,
});

const candidaturesToDownloadListAtom = atom([]);
const candidaturesToDownloadTotalAtom = atom(0);
const isLoadingCandidaturesToDownloadAtom = atom(false);
const isLoadingFirstCandidaturesToDownloadAtom = atom(false);
const isDownloadingCandidatureAtom: any = atom([]);
const candidaturesFilteredFromPileAtom = atom([]);
const isSynchronizingCandidaturesAtom = atom(false);
const isPlayingSynchronizationAtom = atom(false);
const offlineFieldsAtom = atom({});

function useOfflineCandidaturePile() {
  const navigate = useNavigate();
  const { saveEvaluationObject } = useEvaluationObject();
  const [optionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { auth } = useAuth();
  const [isOffline, setIsOffline] = useAtom(isOfflineAtom);
  const [isDownloadingCandidature, setIsDownloadingCandidature]: any = useAtom(
    isDownloadingCandidatureAtom
  );
  const { constants } = useConstants();
  const { dynamicFields } = useDynamicFields();

  const [candidaturesToDownloadList, setCandidaturesToDownloadList]: any = useAtom(
    candidaturesToDownloadListAtom
  );
  const [candidaturesFilteredFromPile, setCandidaturesFilteredFromPile]: any = useAtom(
    candidaturesFilteredFromPileAtom
  );
  const [candidaturesToDownloadTotal, setCandidaturesToDownloadTotal] = useAtom(
    candidaturesToDownloadTotalAtom
  );
  const [isLoadingCandidaturesToDownload, setIsLoadingCandidaturesToDownload] = useAtom(
    isLoadingCandidaturesToDownloadAtom
  );
  const [isLoadingFirstCandidaturesToDownload, setIsLoadingFirstCandidaturesToDownload]: any =
    useAtom(isLoadingFirstCandidaturesToDownloadAtom);

  const [isSynchronizingCandidatures, setIsSynchronizingCandidatures] = useAtom(
    isSynchronizingCandidaturesAtom
  );
  const [isPlayingSynchronization, setIsPlayingSynchronization] = useAtom(
    isPlayingSynchronizationAtom
  );
  const [offlineFields, setOfflineFields]: any = useAtom(offlineFieldsAtom);

  const actions = {
    onUpdateCandidatureList: (candidatureList: any) => {
      setCandidaturesToDownloadList(candidatureList);
    },
    onSelectCandidature: async (cand: any, checked: any) => {
      setIsDownloadingCandidature((prev: any) => [...prev, cand.uuid]);
      if (checked) {
        try {
          const response = await getCandidatureRequest(cand.uuid);

          localStorage.setItem(
            "candidatures",
            JSON.stringify([
              ...actions.getCandidaturesEntryFromLocalStorage(),
              {
                localstorage_key: `${cand.uuid}-${auth.user.uuid}`,
                candidature_uuid: cand.uuid,
                branche_uuid: userSelectedBranch.id,
                name: `${cand.candidat.nom} - ${cand.certification.libelle}`,
                url_client: `admin/candidature/${cand.uuid}/evaluation`,
                url_api: `api/candidature/candidatures/${cand.uuid}`,
                request_type: "POST",
                synchro_status: SYNCHRO_STATUS.NOT_SYNCHRONIZED,
                treatment_status: TREATMENT_STATUS.NOT_TREATED,
                error: "",
              },
            ])
          );
          localStorage.setItem(
            `${cand.uuid}-${auth.user.uuid}`,
            JSON.stringify(
              candidatureAdapters.transformForBO(response, constants, false, dynamicFields)
            )
          );
        } catch (err: any) {
          toast.error("Une erreur est survenue lors de la récupération de la candidature");
        }
      } else {
        localStorage.setItem(
          "candidatures",
          JSON.stringify(
            actions
              .getCandidaturesEntryFromLocalStorage()
              .filter((c: any) => c.candidature_uuid != cand.uuid)
          )
        );
        localStorage.removeItem(`${cand.uuid}-${auth.user.uuid}`);
      }
      setIsDownloadingCandidature((prev: any) => [...prev.filter((c: any) => c != cand.uuid)]);
      actions.getAllCandidatureFromLocalStorage();
    },
    getCandidaturesEntryFromLocalStorage: () => {
      const candidaturesDownloaded = localStorage.getItem("candidatures");
      return candidaturesDownloaded ? JSON.parse(candidaturesDownloaded) : [];
    },
    onDownloadOfflineCandidats: () => {
      toast.info("En cours de développement");
    },
    getAllCandidaturesToDownload: async (overrideFilters: any = initialFilters) => {
      setIsLoadingCandidaturesToDownload(true);
      setIsLoadingFirstCandidaturesToDownload(true);
      const response = await getCandidaturesRequest(
        {
          ...overrideFilters,
          statuts: [{ key: "evaluation" }, { key: "avis_second_evaluateur" }],
        },
        optionsFilters
      );
      if (!response.error) {
        setCandidaturesToDownloadList(response.data);
        setCandidaturesToDownloadTotal(response?.meta?.total);
        setIsLoadingFirstCandidaturesToDownload(false);
        const howManyCall = Math.ceil(response.meta.total / 15) - 1;
        if (howManyCall == 0) {
          setIsLoadingCandidaturesToDownload(false);
          setCandidaturesToDownloadTotal(0);
        } else {
          var allPromises: any = [];
          for (let i = 0; i < howManyCall; i++) {
            allPromises.push(
              getCandidaturesRequest(
                {
                  ...overrideFilters,
                  statuts: [{ key: "evaluation" }, { key: "avis_second_evaluateur" }],
                },
                { ...optionsFilters, page: i + 2 }
              ).then((data: any) => {
                setCandidaturesToDownloadList((prev: any) => [...prev, ...data.data]);
              })
            );
          }
          Promise.all(allPromises).finally(() => {
            setIsLoadingCandidaturesToDownload(false);
            setCandidaturesToDownloadTotal(0);
          });
        }
      } else {
        toast.error(response.error);
        setIsLoadingFirstCandidaturesToDownload(false);
        setCandidaturesToDownloadTotal(0);
      }
    },
    getAllCandidatureFromLocalStorage: () => {
      const allCandidatures = JSON.parse(localStorage.getItem("candidatures") as any);
      const candidaturesFiltered = allCandidatures?.filter(
        (c: any) =>
          c.branche_uuid == userSelectedBranch.id &&
          c.localstorage_key.includes(`${auth.user.uuid}`)
      );

      candidaturesFiltered
        ? setCandidaturesFilteredFromPile(candidaturesFiltered)
        : setCandidaturesFilteredFromPile([]);
    },
    getCandidatureFromLocalStorage: (localstorage_key: any) => {
      const response: any = localStorage.getItem(localstorage_key);
      return JSON.parse(response);
    },
    updateCandidaturesInLocalStorage: (candidatureUuid: any, data: any) => {
      const allCandidatures = JSON.parse(localStorage.getItem("candidatures") as any);
      const updatedCandidatures = allCandidatures.map((c: any) => {
        if (c.localstorage_key == `${candidatureUuid}-${auth.user.uuid}`) {
          return { ...c, ...data };
        }
        return c;
      });
      localStorage.setItem("candidatures", JSON.stringify(updatedCandidatures));
      setCandidaturesFilteredFromPile((u: any) => updatedCandidatures);
    },
    setLocalCandatureNotTreated: (candidatureUuid: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        treatment_status: TREATMENT_STATUS.NOT_TREATED,
      });
    },
    setLocalCandatureTreated: (candidatureUuid: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        treatment_status: TREATMENT_STATUS.TREATED,
      });
    },
    setLocalCandatureNotSynchronized: (candidatureUuid: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        treatment_status: SYNCHRO_STATUS.NOT_SYNCHRONIZED,
      });
    },
    setLocalCandatureSynchronized: (candidatureUuid: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        synchro_status: SYNCHRO_STATUS.SYNCHRONIZED,
      });
    },
    setLocalCandatureSynchronizing: (candidatureUuid: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        synchro_status: SYNCHRO_STATUS.SYNCHRONIZING,
      });
    },
    setLocalCandatureError: (candidatureUuid: any, error: any) => {
      actions.updateCandidaturesInLocalStorage(candidatureUuid, {
        synchro_status: SYNCHRO_STATUS.NOT_SYNCHRONIZED,
        error,
      });
    },

    onRemoveCandidatureFromPile: (candidatureUuid: any, withConfirm = true) => {
      const locaStorageIdentifier = `${candidatureUuid}-${auth.user.uuid}`;
      if (
        withConfirm &&
        !window.confirm(
          "Êtes-vous certain(e) de vouloir supprimer cette candidature de la file d'attente hors ligne ?"
        )
      ) {
        return false;
      }
      setCandidaturesFilteredFromPile(
        candidaturesFilteredFromPile.filter((c: any) => c.localstorage_key != locaStorageIdentifier)
      );

      localStorage.removeItem(locaStorageIdentifier);
      localStorage.setItem(
        "candidatures",
        JSON.stringify(
          candidaturesFilteredFromPile.filter(
            (c: any) => c.localstorage_key != locaStorageIdentifier
          )
        )
      );
    },
    onSaveEvaluation: (cand: any) => {
      if (cand.champs_complementaires?.length > 0) {
        cand.champs_complementaires.forEach((champ: any) => {
          setOfflineFields((prev: any) => ({
            ...prev,
            [cand.uuid]: {
              ...prev[cand.uuid],
              [champ.uuid]: champ.value,
            },
          }));
        });
      }
      localStorage.setItem(`${cand.uuid}-${auth.user.uuid}`, JSON.stringify(cand));
      actions.setLocalCandatureTreated(cand.uuid);
      navigate("/admin/offline/candidature-sync");
    },
    getLocalCandidatureByUuid: (candidatureUuid: any) => {
      if (!localStorage.getItem(`${candidatureUuid}-${auth.user.uuid}`)) {
        throw new Error("Candidature non trouvée");
      }
      let rawCandidature = JSON.parse(
        localStorage.getItem(`${candidatureUuid}-${auth.user.uuid}`) as any
      );
      if (rawCandidature.champs_complementaires?.length > 0) {
        rawCandidature.champs_complementaires = rawCandidature.champs_complementaires.map(
          (champ: any) => {
            if (
              offlineFields[rawCandidature.uuid] &&
              offlineFields[rawCandidature.uuid][champ.uuid]
            ) {
              return { ...champ, value: offlineFields[rawCandidature.uuid][champ.uuid] };
            }
            return champ;
          }
        );
      }
      return rawCandidature;
    },
    getSynchronizableCandidatures: () => {
      return candidaturesFilteredFromPile.filter(
        (c: any) =>
          c.synchro_status == SYNCHRO_STATUS.NOT_SYNCHRONIZED &&
          c.treatment_status == TREATMENT_STATUS.TREATED
      );
    },
    resetSynchronizingCandidatures: () => {
      const synchronizingCandidatures = candidaturesFilteredFromPile.filter(
        (c: any) => c.synchro_status == SYNCHRO_STATUS.SYNCHRONIZING
      );
      synchronizingCandidatures.forEach((c: any) => {
        actions.setLocalCandatureNotSynchronized(c.candidature_uuid);
      });
    },
    resetCandidaturesErrors: () => {
      candidaturesFilteredFromPile.forEach((c: any) => {
        actions.setLocalCandatureError(c.candidature_uuid, "");
      });
    },
    resetCandidatureErrors: (candidatureUuid: any) => {
      actions.setLocalCandatureError(candidatureUuid, "");
    },
    onRemoveSynchronizedCandidatures: () => {
      const synchronizedCandidatures = candidaturesFilteredFromPile.filter(
        (c: any) => c.synchro_status == SYNCHRO_STATUS.SYNCHRONIZED
      );
      synchronizedCandidatures.forEach((c: any) => {
        actions.onRemoveCandidatureFromPile(c.candidature_uuid, false);
      });
    },
    onSyncAllCandidaturesFromPile: async () => {
      if (!navigator.onLine) {
        toast.error("Vous devez être connecté à internet pour synchroniser les candidatures");
        return;
      }
      const candidaturesToSync = actions.getSynchronizableCandidatures();
      if (candidaturesToSync.length == 0) {
        toast.info("Toutes les candidatures traitées sont synchronisées");
        actions.onRemoveSynchronizedCandidatures();
        return false;
      }
      navigate("/admin/offline/candidature-sync");
      actions.resetCandidaturesErrors();
      actions.onRemoveSynchronizedCandidatures();
      setIsOffline(false);
      CHANGE_IS_OFFLINE(false);
      setIsPlayingSynchronization(true);
    },

    onSyncCandidatureFromPile: async (cand: any) => {
      const candidatureData = actions.getLocalCandidatureByUuid(cand.candidature_uuid);
      if (!candidatureData) {
        toast.error(
          "Une erreur est survenue lors de la synchronisation de la candidature, candidature absente du stockage local."
        );
        actions.stopSynchronization();
        return;
      }

      setIsSynchronizingCandidatures((c: any) => true);
      actions.setLocalCandatureError(candidatureData.uuid, "");
      actions.setLocalCandatureSynchronizing(candidatureData.uuid);
      const result = await saveEvaluationObject(candidatureData);
      if (result.success) {
        actions.setLocalCandatureSynchronized(candidatureData.uuid);
        actions.onRemoveCandidatureFromPile(candidatureData.uuid, false);
      } else {
        actions.setLocalCandatureError(candidatureData.uuid, result.errors);
        actions.stopSynchronization();
        setIsOffline(true);
        CHANGE_IS_OFFLINE(true);
        toast.error(
          "La synchronisation d'une candidature a échoué, veuillez corriger les erreurs puis validez à nouveau l'évaluation."
        );
      }
      setIsSynchronizingCandidatures((c: any) => false);
    },
    stopSynchronization: () => {
      setIsPlayingSynchronization(false);
    },
    playSynchronization: async () => {
      if (isOffline || !navigator.onLine || !isPlayingSynchronization) {
        return false;
      }
      const candidaturesToSync = actions.getSynchronizableCandidatures();
      if (candidaturesToSync.length > 0) {
        actions.onSyncCandidatureFromPile(candidaturesToSync[0]);
      } else {
        toast.info("Toutes les candidatures traitées sont synchronisées");

        actions.stopSynchronization();
      }
    },
    getCandidatureErrorFormated: (cand: any) => {
      if (cand?.error?.message) {
        return cand.error.message;
      }
      return "";
    },
  };
  return {
    isOffline,
    actions,
    auth,
    candidaturesToDownloadList,
    candidaturesToDownloadTotal,
    isLoadingCandidaturesToDownload,
    isLoadingFirstCandidaturesToDownload,
    isDownloadingCandidature,
    candidaturesFilteredFromPile,
    isSynchronizingCandidatures,
    setIsSynchronizingCandidatures,
    isPlayingSynchronization,
    setIsPlayingSynchronization,
  };
}

export default useOfflineCandidaturePile;
