import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import useJuryDetail from "../useJuryDetail";

const ORIGIN_INPUT_NAMES: any = {
  president_jury_uuid: "Président du jury",
  vice_president_jury_uuid: "Vice-président du jury",
  membres: "Membre du jury",
};

export default function ParticipantsFormSection() {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const { conditionsInputDisabled } = useJuryDetail();

  const displayErrorAlreadySelected = ({
    originInput,
    isSelectedInMembres = false,
    isSelectedInVicePresident = false,
    isSelectedInPresident = false,
  }: any) => {
    let targetEntityName = "";
    if (isSelectedInMembres) {
      targetEntityName = "Membres du jury";
    }
    if (isSelectedInVicePresident) {
      targetEntityName = "Vice-président du jury";
    }
    if (isSelectedInPresident) {
      targetEntityName = "Président du jury";
    }
    if (targetEntityName) {
      toast.error(
        `Cet utilisateur ne peut pas être nommé ${ORIGIN_INPUT_NAMES[originInput]} car il est déjà sélectionné dans ${targetEntityName}.`
      );
    }
  };

  const checkUserAlreadySelected = (originInput: string, userUuid: any) => {
    const membresUuids = watch("membres")?.map((m: any) => m.uuid);
    let isSelectedInMembres = false;
    let isSelectedInVicePresident = false;
    let isSelectedInPresident = false;
    if (!userUuid) return false;

    switch (originInput) {
      case "president_jury_uuid":
        isSelectedInMembres = membresUuids?.includes(userUuid?.uuid);
        isSelectedInVicePresident =
          userUuid?.uuid === watch("vice_president_jury_uuid")?.uuid;
        break;
      case "vice_president_jury_uuid":
        isSelectedInPresident =
          userUuid?.uuid === watch("president_jury_uuid")?.uuid;
        isSelectedInMembres = membresUuids?.includes(userUuid?.uuid);
        break;
      case "membres":
        isSelectedInPresident = userUuid?.find(
          (u: any) => u.uuid === watch("president_jury_uuid")?.uuid
        );
        isSelectedInVicePresident = userUuid?.find(
          (u: any) => u.uuid === watch("vice_president_jury_uuid")?.uuid
        );
        break;
      default:
        return false;
    }

    displayErrorAlreadySelected({
      originInput,
      isSelectedInMembres,
      isSelectedInVicePresident,
      isSelectedInPresident,
    });

    return (
      isSelectedInMembres || isSelectedInVicePresident || isSelectedInPresident
    );
  };

  return (
    <FormSection sectionTitle="Participants du jury">
      <Controller
        render={({ field: { name, value, onBlur, ref, onChange } }) => (
          <SelectFieldAsync
            label="Président du jury :"
            placeholder="Recherchez un membre du jury qui prendra le rôle de président du jury (ex: Jean Dupont)"
            resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
            addQueryURL={(resourceURI: any, terms: any) =>
              `${resourceURI}?roles[]=MEMBRE_JURY&nom_complet=${terms}`
            }
            method="GET"
            renderItem={(item: any) => item.nom + " " + item.prenom}
            renderValue={(item: any) => item.nom + " " + item.prenom}
            dataIndex="uuid"
            name={name}
            value={value}
            onBlur={onBlur}
            fieldRef={ref}
            onChange={(e: any) => {
              if (!checkUserAlreadySelected(name, e.target.value)) {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }
            }}
            defaultValue={null}
            errorMessage={(errors.president_jury_uuid as any)?.message}
            handleResponse={(response: any) => [...response.data.data]}
            disabled={conditionsInputDisabled.president_jury_uuid}
          />
        )}
        defaultValue=""
        control={control}
        name="president_jury_uuid"
      />
      <Controller
        render={({ field: { name, value, onBlur, ref, onChange } }) => (
          <SelectFieldAsync
            label="Vice-président du jury :"
            placeholder="Recherchez un membre du jury qui prendra le rôle de vice-président du jury (ex: Jean Dupont)"
            resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
            addQueryURL={(resourceURI: any, terms: any) =>
              `${resourceURI}?roles[]=MEMBRE_JURY&nom_complet=${terms}`
            }
            method="GET"
            renderItem={(item: any) => item.nom + " " + item.prenom}
            renderValue={(item: any) => item.nom + " " + item.prenom}
            dataIndex="uuid"
            name={name}
            value={value}
            onBlur={onBlur}
            fieldRef={ref}
            onChange={(e: any) => {
              if (!checkUserAlreadySelected(name, e.target.value)) {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }
            }}
            defaultValue={null}
            errorMessage={(errors.vice_president_jury_uuid as any)?.message}
            handleResponse={(response: any) => [...response.data.data]}
            disabled={conditionsInputDisabled.vice_president_jury_uuid}
          />
        )}
        defaultValue=""
        control={control}
        name="vice_president_jury_uuid"
      />
      <Controller
        render={({ field: { name, value, onBlur, ref, onChange } }) => (
          <SelectManyFieldAsync
            required
            label="Membres du jury :"
            placeholder="Recherchez des utilisateurs membre du jury (ex: Jean Dupont)"
            resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
            addQueryURL={(resourceURI: any, terms: any) =>
              `${resourceURI}?roles[]=MEMBRE_JURY&nom_complet=${terms}`
            }
            method="GET"
            renderItem={(item: any) => item.nom + " " + item.prenom}
            renderValue={(item: any) => item.nom + " " + item.prenom}
            dataIndex="uuid"
            name={name}
            value={value}
            onBlur={onBlur}
            fieldRef={ref}
            onChange={(e: any) => {
              if (!checkUserAlreadySelected(name, e.target.value)) {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }
            }}
            defaultValue={null}
            errorMessage={(errors.membres as any)?.message}
            handleResponse={(response: any) => [...response.data.data]}
            //disabled={conditionsInputDisabled.membres}
          />
        )}
        control={control}
        name="membres"
      />
    </FormSection>
  );
}
