import { axiosInstance } from "../config/axiosConfig";
import queryString from "query-string";
import tools from "../utils/tools";

const eCertifApi = axiosInstance;
const exportCaisseDesDepotsURI = "candidature/exports";

export const getExportCaisseDesDepots = async (filters: any) => {
  const queryFilters = queryString.stringify(filters);

  const response = await eCertifApi.get(
    `${exportCaisseDesDepotsURI}/caisse-des-depots?${queryFilters}`
    // {
    //   responseType: "blob",
    // }
  );
  return response;
};

export const getInformationLastExportCaisseDesDepots = async () => {
  const response = await eCertifApi.get(
    `${exportCaisseDesDepotsURI}/caisse-des-depots/information-dernier-export`
  );
  return response.data.data;
};

export const getExportCaisseDepotsDataRequest = async (options: any) => {
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `exports/chercher?type=CAISSE_DES_DEPOTS&${queryOptionsUrl}`
  );

  return response.data;
};
