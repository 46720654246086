import { MessageBar } from "@fluentui/react";
import { useAtom } from "jotai";
import BranchSelection from "./BranchSelection/BranchSelection";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import ProfileInformation from "./ProfileInformation/ProfileInformation";
import StepContainer from "./StepContainer/StepContainer";
import useSignUp, { SignUpProvider, apiErrorsAtom } from "./useSignUp";
import SignUpSuccess from "./SignUpSuccess/SignUpSuccess";

function SignUp() {
  const signUpLogic = useSignUp();
  const [apiErrors]: any = useAtom(apiErrorsAtom);

  const stepComponent: any = {
    1: <BranchSelection />,
    2: <PersonalInformation />,
    3: <ProfileInformation />,
    4: <SignUpSuccess />,
  };

  return (
    <SignUpProvider
      value={{
        ...signUpLogic,
      }}
    >
      <div style={{ minHeight: "90vh" }}>
        <StepContainer>
          {stepComponent[signUpLogic.currentStepId]}
          {apiErrors && (
            <MessageBar
              messageBarType={1}
              messageBarIconProps={{ iconName: "Warning" }}
            >
              {apiErrors}
            </MessageBar>
          )}
        </StepContainer>
      </div>
    </SignUpProvider>
  );
}

export default SignUp;
