import { atom, useAtom } from "jotai";
import { branchSettingsAtom } from "../../hooks/branchSettings/useAccessSettings";

import WorkflowContent from "./WorkflowContent";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useCandidature from "../../hooks/candidature/useCandidature";

import useUrlParams from "./useUrlParams";

import useMiseEnConfirmite from "./Common/MiseEnConformite/useMiseEnConfirmite";

export const isOpenedInModalAtom = atom(false);

const CandidatureWorkflow = (props: any) => {
  const [branchSettings] = useAtom(branchSettingsAtom);

  const { isListCandidatures, id_candidature, statut_candidature } =
    useParams();
  const { actions } = useCandidature();

  const { setUrlParams, id_candidature: id } = useUrlParams();
  const [isUrlParamsSet, setIsUrlParamsSet] = useState(false);
  const [, setIsOpenedInModal] = useAtom(isOpenedInModalAtom);

  const { mecActions } = useMiseEnConfirmite();

  useEffect(() => {
    if (!isListCandidatures) {
      actions.resetIsConsultCandidaturesList();
    }

    //si on ouvre dans une modale, on passe en props les params normalement dans l'url
    if (props.id_candidature && props.statut_candidature) {
      setUrlParams({
        id_candidature: props.id_candidature,
        statut_candidature: props.statut_candidature,
        isEmbeded: true,
      });
      setIsOpenedInModal(true);
    } else {
      setUrlParams({
        id_candidature,
        statut_candidature,
        isEmbeded: false,
      });
      setIsOpenedInModal(false);
    }
    setIsUrlParamsSet(true);

    mecActions.onDeactivateMEC();
  }, []);

  if (!isUrlParamsSet || !id) return null;

  return !branchSettings ? null : <WorkflowContent />;
};

export default CandidatureWorkflow;
