import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  postCertificationRequest,
  putCertificationRequest,
  deleteCertificationRequest,
  patchActivateCertificationRequest,
  patchDesactivateCertificationRequest,
  postCertificationOnDomaineRequest,
} from "../../services/certificationServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";

function useCertificationMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const {actions:actionsModal} = useCustomModal()

  const [certificationUuid, setCertificationUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);


  const mutations = {
    PostCertification: useMutation(postCertificationRequest,
      {
        onSuccess: (data) => {
          toast.success("La certification a été créée avec succès.");
          setCertificationUuid(data.uuid);
          // Invalidate and refetch
          queryClient.invalidateQueries("certifications");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
              `Une erreur est survenue lors de l'enregistrement de la certification : ${error?.response?.data?.message}`
            );
        },
      }
    ),
    PostCertificationOnDomaine: useMutation(postCertificationOnDomaineRequest,
      {
        onSuccess: (data) => {
          actionsModal.closeModal()
          toast.success("La certification a été créée avec succès sur le domaine.");
          setCertificationUuid(data.uuid);
          navigate(`/admin/certifications/${data.uuid}`)
          // Invalidate and refetch
          queryClient.invalidateQueries("certifications");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
              `Une erreur est survenue lors de l'enregistrement de la certification sur le domaine : ${error?.response?.data?.message}`
            );
        },
      }
    ),
    PutCertification: useMutation(putCertificationRequest, {
      onSuccess: () => {
        toast.success("La certification a été mise à jour avec succès.");
        queryClient.invalidateQueries("certifications");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
            `Une erreur est survenue lors de la mise à jour de la certification : ${error?.response?.data?.message}`
          );
      },
    }),
    DeleteCertification: useMutation(deleteCertificationRequest, {
      onSuccess: () => queryClient.invalidateQueries("certifications"),
    }),
    ToggleCertificationActivate: useMutation(patchActivateCertificationRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("La certification a été activée avec succès.");
        queryClient.invalidateQueries("certifications")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
    ToggleCertificationDesactivate: useMutation(patchDesactivateCertificationRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("La certification a été désactivée avec succès.");
        queryClient.invalidateQueries("certifications")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
  };

  return {
    certificationUuid,
    setCertificationUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
  };
}

export default useCertificationMutations;
