import { Stack, TextField } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import { commonInputStyles } from "../../../styles";
import { GroupOfContext } from "../useGroupOfDetail";
import SearchExistingStructure from "../../../Common/SearchExistingStructure/SearchExistingStructure";
import BranchesInfo from "../../../Common/BranchesInfo/BranchesInfo";

function GeneralInformationSection() {
  const { reactHookProps, errors, uuid, isChecked, actions, groupOf }: any =
    useContext(GroupOfContext);
  const { control, watch, setValue } = reactHookProps;

  return (
    <FormSection sectionTitle="Informations principales du Groupe d'OF">
      {uuid === "creer" && (
        <SearchExistingStructure
          reactHookProps={reactHookProps}
          isChecked={isChecked}
          actions={actions}
          searchExistingUrl={"administration/groupe-ofs/existe"}
          keyToCheck={"siren"}
          acceptedChararacterNumber={9}
        />
      )}

      {(isChecked || uuid !== "creer") && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Raison sociale :"
                  type="text"
                  {...field}
                  placeholder="Ex : Mon groupe d'of"
                  errorMessage={(errors[field.name] as any)?.message}
                  required
                />
              )}
              defaultValue=""
              control={control}
              name="raison_sociale"
            />
          </Stack.Item>

          {uuid !== "creer" && (
            <>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="SIREN (9 chiffres) :"
                      type="text"
                      placeholder="Ex : 123412123"
                      errorMessage={(errors[name] as any)?.message}
                      disabled={true}
                      required
                      value={value}
                      onChange={(e: any, newValue: any) => {
                        if (!newValue || newValue.length <= 9) {
                          onChange(e.target.value);
                        }
                      }}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="siren"
                />
              </Stack.Item>
              <BranchesInfo
                data={groupOf}
                branchSentence={
                  "Ce groupe d'organisme de formation existe sur les branches :"
                }
                noBranchSentence={
                  "Ce groupe d'organisme de formation est présent uniquement sur cette branche"
                }
              />
            </>
          )}

          <FormAdress
            control={control}
            errors={errors}
            required={true}
            varToWatch={watch("code_postal") ?? false}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />
        </>
      )}
    </FormSection>
  );
}

export default GeneralInformationSection;
