import {
  MessageBar,
  Pivot,
  PivotItem,
  Stack,
  TextField,
} from "@fluentui/react";

import { Controller } from "react-hook-form";
import CancelButton from "../../Common/CancelButton/CancelButton";
import Loader from "../../Common/Loader/Loader";
import Page from "../../Common/Page/Page";
import { commonInputStyles, MessageBarStyles } from "../../styles";
import useLabelSettingsDetail from "./useLabelSettingsDetail";
import InputDescriptionCandidature from "./InputDescriptionCandidature/InputDescriptionCandidature";

function LabelSettingsDetail() {
  const {
    labelSettings,
    reactHookProps,
    actions,
    isLoadingLabelSettings,
    isSavingLabel,
  } = useLabelSettingsDetail();
  const { control, setValue, watch } = reactHookProps;

  if (labelSettings.length <= 0) return null;

  const labelSettingsGenerals: any = [];
  const labelSettingsCandidatureDescription: any = [];

  labelSettings.forEach((libelle: any) => {
    libelle.est_html
      ? labelSettingsCandidatureDescription.push(libelle)
      : labelSettingsGenerals.push(libelle);
  });

  console.log("isLoadingLabelSettings", isLoadingLabelSettings);

  return (
    <Stack>
      <Page
        title={"Paramétrage des labels"}
        explain={"Veuillez renseigner les labels de la branche"}
      >
        {isLoadingLabelSettings && <Loader />}

        <Pivot aria-label="Libelle dynamique">
          <PivotItem key={`labels_generaux`} headerText={`Labels généraux`}>
            <Stack style={{ marginTop: 20, marginBottom: 20 }}>
              <MessageBar styles={MessageBarStyles}>
                Les valeurs des libellés listés ci-dessous sont enregistrées au
                fur et à mesure que vous les modifiez.
              </MessageBar>
            </Stack>
            <Stack>
              {labelSettingsGenerals.map((label: any, i: number) => {
                return (
                  <Stack.Item key={i}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          label={`${label.description} :`}
                          styles={commonInputStyles.textField}
                          placeholder={"Entrez une valeur"}
                          type="text"
                          {...field}
                          onKeyDown={(e: any) => actions.save(label, e)}
                        />
                      )}
                      defaultValue=""
                      name={label.type}
                      control={control}
                    />
                  </Stack.Item>
                );
              })}
            </Stack>
          </PivotItem>
          <PivotItem
            key={`descriptions_sous_etapes_candidatures`}
            headerText={`Descriptions des sous étapes d'une candidature`}
          >
            <Stack tokens={{ childrenGap: 10 }}>
              {labelSettingsCandidatureDescription.map((label: any) => {
                return (
                  <InputDescriptionCandidature
                    key={label.type}
                    control={control}
                    label={label}
                    isSaving={isSavingLabel}
                    onChange={(data: any) => {
                      setValue(label.type, data, {
                        shouldValidate: true,
                      });
                    }}
                    onClick={() => {
                      actions.save(
                        label,
                        {
                          target: { value: watch(label.type) },
                        },
                        true
                      );
                    }}
                  />
                );
              })}
            </Stack>
          </PivotItem>
        </Pivot>
        <Stack
          horizontal
          horizontalAlign="space-between"
          style={{ marginTop: 20 }}
        >
          <CancelButton />
        </Stack>
      </Page>
    </Stack>
  );
}

export default LabelSettingsDetail;
