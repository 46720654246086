import {
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import DemandeHabilitationBadge from "../../Common/DemandeHabilitationBadge/DemandeHabilitationBadge";
import LoadLink from "../../Common/LoadLink/LoadLink";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import useConstants from "../../hooks/useConstants";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import DemandeHabilitationOfListFilters from "./DemandeHabilitationOfListFilters/DemandeHabilitationOfListFilters";
import useDemandeHabilitationOfList from "./useDemandeHabilitationOfList";

function DemandeHabilitationOfList() {
  const {
    demandeHabilitationOfSelected,
    demandeHabilitationsOf,
    actions: actionsPage,
    optionsFilters,
  } = useDemandeHabilitationOfList();
  const { constants } = useConstants();
  const { labels } = useLabelSettings();

  useEffect(() => {
    return () => {
      actionsPage.resetDemandeHabilitationSelected();
    };
  }, []);

  const items = demandeHabilitationsOf?.data || [];

  const columns = [
    {
      key: "1",
      name: "",
      fieldName: "select",
      minWidth: 50,
      maxWidth: 50,
      onRenderHeader: () => <BiSelectMultiple />,
      onRender: (item: any) => {
        const isDemandeHabilitationEnAttente = item.statut == "EN_ATTENTE";

        return isDemandeHabilitationEnAttente ? (
          <Checkbox
            key={item.uuid}
            onChange={(e: any, checked: any) => {
              actionsPage.onSelectDemandeHabilitation(item, checked);
            }}
            checked={
              demandeHabilitationOfSelected.find(
                (d: any) => d.uuid == item.uuid
              )
                ? true
                : false
            }
          />
        ) : null;
      },
      onColumnClick: () => {
        actionsPage.onSelectAllDemandeHabilitation();
      },
    },
    {
      key: "2",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <DemandeHabilitationBadge
          statut={
            (
              tools.findIn(
                constants.DEMANDE_HABILITATION?.STATUTS,
                item.statut,
                "id"
              ) as any
            )?.id
          }
        />
      ),
    },
    {
      key: "3",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("certification");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters?.sort, "certification"),
    },
    {
      key: "4",
      name: "OF",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of?.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("of");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters?.sort, "of"),
    },
    {
      key: "5",
      name: "Date de début",
      fieldName: "date_debut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.date_debut
            ? tools.reformateDateFromJsDate(item.date_debut)
            : null}
        </Stack>
      ),
    },
    {
      key: "6",
      name: "Date de fin",
      fieldName: "date_fin",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.date_fin ? tools.reformateDateFromJsDate(item.date_fin) : null}
        </Stack>
      ),
    },
    {
      key: "7",
      name: "Date de la demande",
      fieldName: "demande_le",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.demande_le)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        actionsPage.sortColumn("date_creation");
      },
      ...tools.sorted(optionsFilters?.sort, "date_creation"),
    },
    {
      key: "8",
      name: "Responsable",
      fieldName: "statueePar",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {item?.statuee_par?.nom} {item?.statuee_par?.prenom}
        </Text>
      ),
    },
    {
      key: "9",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.commentaire}</Text>,
    },
    {
      key: "10",
      name: "Actions",
      fieldName: "actions",
      maxWidth: 100,
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const isHabilitationHaveDocument = item.documents.length > 0;
        const isDemandeHabilitationEnAttente = item.statut == "EN_ATTENTE";

        return (
          <Stack horizontal wrap>
            {isDemandeHabilitationEnAttente && (
              <>
                <LoadLink
                  styles={commonButtonStyles.activeButtonLink}
                  onClick={() => actionsPage.onValidDemandeHabilitation(item)}
                  text={"Valider"}
                />
                <LoadLink
                  styles={commonButtonStyles.disableButtonLink}
                  onClick={() => actionsPage.onRefuseDemandeHabilitation(item)}
                  text={"Refuser"}
                />
              </>
            )}

            {isHabilitationHaveDocument && (
              <LoadLink
                href={item.documents[0].file_url}
                styles={commonButtonStyles.buttonLink}
                text={"Voir document"}
              />
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des demandes d'habilitations"
        explain="Veuillez gérer les demandes d'habilitations"
      >
        <DemandeHabilitationOfListFilters />
        {demandeHabilitationOfSelected.length > 1 && (
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton
              onClick={() =>
                actionsPage.onValidDemandeHabilitation(
                  demandeHabilitationOfSelected,
                  true
                )
              }
              styles={commonButtonStyles.defaultButton}
            >
              Valider la sélection
            </DefaultButton>
          </Stack>
        )}
        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
        <Pagination
          changePage={actionsPage.changePage}
          page={demandeHabilitationsOf?.meta.current_page || 1}
          totalPages={Math.ceil(demandeHabilitationsOf?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default DemandeHabilitationOfList;
