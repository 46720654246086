import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useCompanyList from "./useCompanyList";
import CompanyListFilters from "./CompanyListFilters/CompanyListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import tools from "../../utils/tools";

import { commonButtonStyles } from "../../styles/index";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";
import OpenImportButton from "../../Common/OpenImportButton/OpenImportButton";
import { entityTypes } from "../../Common/ImportEntities/useImportEntities";
import { userAccessEnum } from "../../config/accessEnum";
import Can from "../../Common/Can/Can";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useTheme from "../../Common/useTheme";

function CompanyList() {
  const {
    companies,
    companyProps,
    optionsFilters,
    actions: actionsPage,
    isLoadingExportCompany,
  } = useCompanyList();
  const { primaryColor } = useTheme();

  const { actions, isToggleActivationUuid } = companyProps;

  const items = companies?.data || [];

  const columns = [
    {
      key: "1",
      name: "Raison sociale",
      fieldName: "nom",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      onRender: (item: any) => <Text>{item.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "2",
      name: "SIRET",
      fieldName: "siret",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("siret");
      },
      onRender: (item: any) => <Text>{item.siret}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "siret"),
    },
    {
      key: "3",
      name: "Groupe lié",
      fieldName: "groupe_entreprise",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Text>{item.groupe_entreprise?.raison_sociale}</Text>
      ),
    },
    {
      key: "4",
      name: "Active",
      fieldName: "active",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item["active"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn("active");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "active"),
    },
    {
      key: "5",
      name: "Multibranche",
      fieldName: "interbranches",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.interbranches} />,
    },
    {
      key: "6",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Link
              to={`/admin/entreprises/${item.uuid}`}
              as={RouterLink}
              style={{ marginLeft: 5, marginTop: 5 }}
              styles={commonButtonStyles.buttonLink}
            >
              Editer
            </Link>
            <Link
              onClick={() => actionsPage.openDeleteCompanyModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>

            <LoadLink
              styles={
                item.active
                  ? commonButtonStyles.disableButtonLink
                  : commonButtonStyles.activeButtonLink
              }
              onClick={() => actions.toggleCompanyActivation(item)}
              isLoading={isToggleActivationUuid == item.uuid}
              text={item.active ? "Désactiver" : "Activer"}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page title="Liste des entreprises" explain="Gérer vos entreprises">
        <Stack
          horizontal
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          <CreateLink
            text="Créer une entreprise"
            to="/admin/entreprises/creer"
          />
          <Can I={userAccessEnum.EXPORTER_UTILISATEURS_ET_ENTITES}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les données entreprises"
                isLoading={isLoadingExportCompany}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportCompanies}
                primary={false}
              />
            </div>
          </Can>
          <OpenImportButton entityType={entityTypes.ENTREPRISE} />
        </Stack>
        <CompanyListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={companies?.meta.current_page || 1}
          totalPages={Math.ceil(companies?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CompanyList;
