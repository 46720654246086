import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

const ofURI = "administration/import-entites";

export const validateImportRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${ofURI}/valider`, data, xsrfHeader);
  return response.data;
};
export const confirmImportRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${ofURI}/confirmer`, data, xsrfHeader);
  return response.data;
};
