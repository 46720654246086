import {mergeStyleSets} from '@fluentui/react'

const customModalStyles = {
    contentStyles :mergeStyleSets( {
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [{
            flex: '1 1 auto', 
            display: 'flex',
            alignItems: 'center',
            padding: '0px 24px',
            fontSize:18,
            fontWeight:500,
        }],
        body: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            padding: '0px 24px',
        },
    }),
    cancelIcon:{ 
        iconName: 'Cancel' 
    },
}

export default customModalStyles;
