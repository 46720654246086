import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import TextResult from "../../../Common/TextResult/TextResult";
import { userStateList } from "../../../fakeData/State.data";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import tools from "../../../utils/tools";
import usePlatformUserList from "../usePlatformUserList";

function PlatformUserListFilters() {
  const {
    usersProfils,
    isLoadingUsers,
    actions,
    reactHookProps,
    allBranches,
    isLoadingBranches,
  } = usePlatformUserList();
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    actions.getAllBranches();
  }, []);

  const branchList = tools.addKeyToObjectInArray(allBranches, "id");

  const totalUsers = usersProfils?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => setShowFilters((s) => !s);

  return isLoadingBranches ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Nom :"
                  type="text"
                  placeholder="Saisissez un nom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Prénom :"
                  type="text"
                  placeholder="Saisissez un prénom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Email :"
                  id="email"
                  type="email"
                  placeholder="Saisissez un email"
                  {...field}
                />
              )}
            />
          </Stack.Item>
          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="Utilisateur actif :"
                      selectedKey={value ? value.key : undefined}
                      placeholder="Sélectionner un état"
                      options={userStateList}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={userStateList[0]}
                  control={control}
                  name="actif"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onChange } }) => (
                    <Dropdown
                      label="Branches :"
                      selectedKeys={value ? value.map((v: any) => v.key) : []}
                      placeholder="Sélectionnez un ou plusieurs branches"
                      options={branchList}
                      onChange={(e: any, options: any) => {
                        let actualValue = reactHookProps.watch(name);

                        actualValue.find((o: any) => o.key == options.key)
                          ? (actualValue = actualValue.filter(
                              (o: any) => o.key != options.key
                            ))
                          : (actualValue = [...actualValue, options]);

                        reactHookProps.setValue(name, actualValue, {
                          shouldValidate: true,
                        });
                      }}
                      onRenderOption={(option: any) => option.nom}
                      onRenderTitle={(option: any) =>
                        option.map((branche: any) => branche.nom).join(", ")
                      }
                      styles={commonInputStyles.dropdownStyle}
                      multiSelect
                    />
                  )}
                  defaultValue={[]}
                  control={control}
                  name="branches"
                />
              </Stack.Item>
            </>
          )}
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingUsers} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text="Réinitialiser ma recherche"
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalUsers} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default PlatformUserListFilters;
