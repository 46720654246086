import { axiosInstance } from "../config/axiosConfig";
import { IUser } from "../interfaces/userInterface";
import {
  getOfflineStorage,
  IS_OFFLINE,
  OFFLINE_ROUTE,
} from "../config/offlineConfig";

const eCertifApi = axiosInstance;

export const getCSRFCookieRequest = async () => {
  await eCertifApi.get("csrf-cookie");

  const value = `; ${document.cookie}`;
  const parts = value.split(`; XSRF-TOKEN=`) || [];

  let token;
  if (parts.length === 2) {
    token = parts.pop()?.split(";")?.shift();
  }
  if (!token) token = "";
  return {
    headers: {
      "X-XSRF-TOKEN": decodeURIComponent(token),
    },
  };
};

export const loginRequest = async (user: IUser) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post("login", user, xsrfHeader);

  return response.data;
};

export const logoutRequest = async () => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post("logout", null, xsrfHeader);

  return response.data;
};

export const checkUserConnectedRequest = async () => {
  const url: string = "me";

  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(url);
  localStorage.setItem("me", JSON.stringify(response.data));
  return response.data;
};
export const getUserProfilesRequest = async () => {
  const url: string = "me/mes-entites";

  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(url);
  localStorage.setItem("mesEntites", JSON.stringify(response.data.data));
  return response.data.data;
};

export const forgetPasswordRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post("forgot-password", data, xsrfHeader);

  return response.data;
};

export const checkTokenRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post("check-reset-token", data, xsrfHeader);

  return response.data;
};

export const resetPasswordRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post("reset-password", data, xsrfHeader);

  return response.data;
};

export const getUserInfoOnSelectedBranch = async () => {
  const response = await eCertifApi.get("utilisateur/utilisateurs/me");
  return response.data;
};
