import { useQuery } from "react-query";
import {
  getCompetenceBlocksRequest,
} from "../../services/competenceBlockServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { atom, useAtom } from "jotai";
import tools from "../../utils/tools";

import useCompetenceBlock from "../../hooks/useCompetenceBlock";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { stateList } from "../../fakeData/State.data";
import { useForm } from "react-hook-form";
import { ICompetenceBlockFilters } from "../../interfaces/competenceBlockInterface";
import { atomWithReset } from "jotai/utils";

export const isIinitialFetchOnThisBrancheAtom = atomWithReset(true)

const initialFiltersNotAtom = {
  libelle: "",
  actif: stateList[0],
  certifications_rattachees: [],
};

const initialFiltersAtom = atom<any>((get) => ({...initialFiltersNotAtom,branche_creatrice:{...get(userSelectedBranchAtom), key:get(userSelectedBranchAtom).id}}))
export const overwrittenFiltersAtom = atomWithReset(null)
const filtersAtom = atom(
  (get) => get(overwrittenFiltersAtom) ?? get(initialFiltersAtom),
  (get, set, update:any) => {
    const isStillFiltered = (update.actif?.value || update.libelle || update.certifications_rattachees || update.branche_creatrice?.id != get(initialFiltersAtom).branche_creatrice.id ) ? true : false
    if(Object.keys(update).length>1 && isStillFiltered){
      set(overwrittenFiltersAtom, {...update})
    }else{
      set(overwrittenFiltersAtom, null)
    }
  }
)

export const optionsFiltersAtom = atomWithReset<any>({
  sort: "",
  page: 1,
  paginate: true
})

function useCompetenceBlockList() {

  const competenceBlockProps = useCompetenceBlock()
  const [initialFilters]= useAtom(initialFiltersAtom)
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom)
  const [isIinitialFetchOnThisBranch, setIsInitialFetchOnThisBranch] = useAtom(isIinitialFetchOnThisBrancheAtom);

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm<ICompetenceBlockFilters>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: competenceBlocks, isLoading: isLoadingCompetenceBlocks } = useQuery(
    ["competenceBlocks", filters, optionsFilters, competenceBlockProps.userSelectedBranch],
    () => {
      let apiFilters = filters;
      if (isIinitialFetchOnThisBranch) {
        actions.resetFilters();
        setIsInitialFetchOnThisBranch(false);
      }
      return getCompetenceBlocksRequest(apiFilters, optionsFilters);
    },
    { retry: 0, refetchOnWindowFocus: false }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteCompetenceBlockModal: (competenceBlock: any) => {
      modalActions.openModal({
        body: <DeleteConfirmDialog
          libelle={competenceBlock.libelle}
          deleteFunction={() => competenceBlockProps.actions.deleteCompetenceBlock(competenceBlock.uuid)}
        />,
      })
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters)
      setOptionsFilters({ ...optionsFilters, page: 1 });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);
      setOptionsFilters({ ...optionsFilters, sort: sortJoin })
    },
  };

  return {
    competenceBlocks,
    competenceBlockProps,
    isLoadingCompetenceBlocks,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps
  };
}

export default useCompetenceBlockList;
