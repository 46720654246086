import { useQuery } from "react-query";
import { getCompetencesRequest } from "../../services/competenceServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { atom, useAtom } from "jotai";
import tools from "../../utils/tools";

import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useCompetence from "../../hooks/useCompetence";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { stateList } from "../../fakeData/State.data";
import { useForm } from "react-hook-form";
import { ICompetenceFilters } from "../../interfaces/competenceInterface";
import { atomWithReset } from "jotai/utils";

export const isIinitialFetchOnThisBrancheAtom = atomWithReset(true)

export const initialFiltersNotAtom = {
  libelle: "",
  active: stateList[0],
  competence_cle: stateList[0],
  blocs_rattaches: [],
};

const initialFiltersAtom = atom<any>((get) => ({...initialFiltersNotAtom,branche_creatrice:{...get(userSelectedBranchAtom), key:get(userSelectedBranchAtom).id}}))
export const overwrittenFiltersAtom = atomWithReset(null)
const filtersAtom = atom(
  (get) => get(overwrittenFiltersAtom) ?? get(initialFiltersAtom),
  (get, set, update:any) => {
    const isStillFiltered = (update.active?.value || update.libelle || update.blocs_rattaches || update.competence_cle?.value || update.branche_creatrice?.id != get(initialFiltersAtom).branche_creatrice.id ) ? true : false
    if(Object.keys(update).length>1 && isStillFiltered){
      set(overwrittenFiltersAtom, {...update})
    }else{
      set(overwrittenFiltersAtom, null)
    }
  }
)

export const optionsFiltersAtom = atomWithReset<any>({
  sort: "",
  page: 1,
  paginate: true,
});

function useCompetenceList() {
  const competenceProps = useCompetence();
  const [initialFilters]= useAtom(initialFiltersAtom)
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [isIinitialFetchOnThisBranch, setIsInitialFetchOnThisBranch] = useAtom(isIinitialFetchOnThisBrancheAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm<ICompetenceFilters>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: competences, isLoading: isLoadingCompetences } = useQuery(
    ["competences", filters, optionsFilters, competenceProps.userSelectedBranch],
    () => {
      let apiFilters = filters;
      if (isIinitialFetchOnThisBranch) {
        actions.resetFilters();
        setIsInitialFetchOnThisBranch(false);
      }
      return getCompetencesRequest(apiFilters, optionsFilters);
    },
    { retry: 0, refetchOnWindowFocus: false }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteCompetenceModal: (competence: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={competence.libelle}
            deleteFunction={() => competenceProps.actions.deleteCompetence(competence.uuid)}
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({ ...optionsFilters, page: 1 });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);
      setOptionsFilters({ ...optionsFilters, sort: sortJoin });
    },
  };

  return {
    competences,
    competenceProps,
    isLoadingCompetences,
    optionsFilters,
    modalActions,
    actions,
    filters,
    reactHookProps,
  };
}

export default useCompetenceList;
