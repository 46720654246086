import { statutInfos } from "../../interfaces/juryStatut";
import Badge from "../Badge/Badge";

function JuryStatusBadge({ statut }: { statut: any }) {
  return (
    <Badge
      text={statutInfos[statut].text}
      color={statutInfos[statut].color}
      textStyle={{ color: "#ffffff" }}
    />
  );
}

export default JuryStatusBadge;
