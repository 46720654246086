import { Stack } from "@fluentui/react";
import React, { useContext } from "react";
import SectionTitle from "../../../Common/SectionTitle/SectionTitle";
import CertificationInfos from "./CertificationInfos/CertificationInfos";

function CertificationSection({
  certification,
  extraInformation = false,
}: any) {
  return (
    <Stack>
      <SectionTitle
        title="Certification"
        style={{
          fontSize: 18,
          marginBottom: 0,
          marginTop: 0,
        }}
      />
      <CertificationInfos
        certification={certification}
        extraInformation={extraInformation}
      />
    </Stack>
  );
}

export default CertificationSection;
