import { Link, Spinner, SpinnerSize } from "@fluentui/react";
import axios from "axios";
import fileDownload from "js-file-download";
import { useState } from "react";
import { toast } from "react-toastify";

const DownloadFileLink = ({ fileUrl, fileName, styles, text }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!fileUrl || !fileName) return null;

  const getRender = () => {
    if (isLoading) {
      return <Spinner size={SpinnerSize.small} />;
    }
    return <>{text}</>;
  };

  const onDownloadFile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(fileUrl, {
        responseType: "blob",
      });
      fileDownload(response.data, fileName);
    } catch (err) {
      toast.error(
        "Une erreur est survenue lors du téléchargement, veuillez réessayer."
      );
    }
    setIsLoading(false);
  };

  return (
    <Link
      styles={styles}
      style={{ marginLeft: 5, marginTop: 5 }}
      onClick={onDownloadFile}
    >
      {getRender()}
    </Link>
  );
};

export default DownloadFileLink;
