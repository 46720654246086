const rolesManagementAdapters = {
  transformForAPI: (newData: any, previousData: any) => {
    let transformed = previousData
      .filter((acces: any) => acces.est_parametrable && !acces.est_desactive)
      .map((acces: any) => {
        if (newData?.accesModified) {
          var accesModified = acces;
          newData.accesModified.map((newAcces: any) => {
            if (
              acces.profil == newAcces.profil &&
              acces.acces == newAcces.acces
            ) {
              accesModified = { ...acces, est_accessible: newAcces.value };
            }
          });
          return accesModified;
        } else {
          return acces;
        }
      });

    return { acces_branche: transformed };
  },
};

export default rolesManagementAdapters;
