import {
  CommandButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useContext } from "react";
import { ParcoursCandidatContext } from "../useParcoursCandidat";
import tools from "../../../../utils/tools";

function ExperienceList({ experiences = [], openExperience, isProfessionel, inputsDisabled }: any) {
  const { actions }: any = useContext(ParcoursCandidatContext);

  const columns: IColumn[] = [
    {
      key: `1${isProfessionel ? "ep" : "ee"}`,
      name: "Fonction",
      fieldName: "intitule",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: `2${isProfessionel ? "ep" : "ee"}`,
      name: "Raison sociale",
      fieldName: "raison_sociale",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
    },
    {
      key: `3${isProfessionel ? "ep" : "ee"}`,
      name: "Missions",
      fieldName: "missions",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) =>
        item.missions?.map((mission: any) => (
          <Stack key={`miss${mission.libelle}`}>
            <Text>{mission.libelle}</Text>
          </Stack>
        )),
    },
    {
      key: `4${isProfessionel ? "ep" : "ee"}`,
      name: "Responsabilités",
      fieldName: "responsabilites",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) =>
        item.responsabilites?.map((responsabilite: any) => (
          <Stack key={`resp${responsabilite.libelle}`}>
            <Text>{responsabilite.libelle}</Text>
          </Stack>
        )),
    },
    {
      key: `5${isProfessionel ? "ep" : "ee"}`,
      name: "Date de début",
      fieldName: "date_debut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Stack>{tools.formatDate(item.date_debut)}</Stack>,
    },
    {
      key: `6${isProfessionel ? "ep" : "ee"}`,
      name: "Date fin",
      fieldName: "date_fin",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Stack>{tools.formatDate(item.date_fin)}</Stack>,
    },
    {
      key: `7${isProfessionel ? "ep" : "ee"}`,
      name: "Actions",
      fieldName: "actions",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack horizontal wrap>
          <CommandButton onClick={() => openExperience(item, isProfessionel, inputsDisabled)}>Editer</CommandButton>
          {!inputsDisabled &&
            <CommandButton onClick={() => actions.onRemoveExperience(item, isProfessionel)}>
              Supprimer
            </CommandButton>
          }
        </Stack>
      ),
    },
  ];

  return (
    <DetailsList
      layoutMode={DetailsListLayoutMode.justified}
      items={experiences}
      columns={columns}
      selectionMode={SelectionMode.none}
      styles={{
        root: {
          selectors: {
            ".ms-DetailsHeader": {
              backgroundColor: "rgba(255,255,255,0)",
            },
            ".ms-DetailsRow": {
              backgroundColor: "rgba(255,255,255,0)",
            },
          },
        },
      }}
    />
  );
}

export default ExperienceList;
