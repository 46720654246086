import { Stack } from "@fluentui/react";
import { useParams } from "react-router-dom";

import BackLink from "../../Common/BackLink/BackLink";

import useCompanyDetail, { CompanyContext } from "./useCompanyDetail";

import Page from "../../Common/Page/Page";

import { useEffect } from "react";
import PageMessage from "../../Common/PageMessage/PageMessage";

import { RiAddFill } from "react-icons/ri";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import companyAdapters from "../../adapters/companyAdapters";
import EntitiesLinkedSection from "./FormSections/EntitiesLinkedSection";
import GeneralInformationSection from "./FormSections/GeneralInformationSection";
import UtilisateursLinkToStructure from "../../Common/UtilisateursLinkToStructure/UtilisateursLinkToStructure";
import {
  StructureProvider,
  StructureType,
} from "../../providers/StructureProvider/StructureProvider";

function CompanyDetail() {
  const { uuid } = useParams();
  const {
    company,
    companyProps,
    reactHookProps,
    actions: actionsPage,
    isLoadingCompany,
    isChecked,
    refetch,
  } = useCompanyDetail();
  const {
    isLoading,
    hasError,
    errorPatchCompany,
    errorPostCompany,
    actions,
    isSaved,
  } = companyProps;
  const {
    reset,
    watch,
    clearErrors,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    register,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateCompanyUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (company?.uuid) {
      reset(companyAdapters.transformForBO(company));
    }
  }, [company]);

  useEffect(() => {
    var apiErrors = (errorPostCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostCompany]);

  useEffect(() => {
    var apiErrors = (errorPatchCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPatchCompany]);

  const onSubmit = (e: any) => {
    if (watch("uuid")) {
      setValue("_method", "PUT");
    }
    clearErrors();
    handleSubmit(actionsPage.saveCompany)(e);
  };

  return (
    <CompanyContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        errors,
        isChecked,
        uuid,
        company,
        isSaved,
      }}
    >
      <Stack>
        <BackLink />

        <Page
          title={
            !company?.uuid
              ? "Veuillez créer une entreprise"
              : "Modifier une entreprise"
          }
          explain={
            !company?.uuid
              ? "Veuillez créer une nouvelle entreprise qui sera intégrée à l'application"
              : "Veuillez modifier l’entreprise"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingCompany}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: "POST",
                })}
              />

              <GeneralInformationSection />
              {(isChecked || uuid !== "creer") && (
                <>
                  <EntitiesLinkedSection />

                  <StructureProvider
                    structureType={StructureType.ENTREPRISE}
                    structureUuid={uuid as any}
                    users={company?.utilisateurs_lies}
                    referent={company?.referent}
                    structureRefetch={refetch}
                  >
                    <UtilisateursLinkToStructure sectionTitle="Utilisateurs liés à l'entreprise" />
                  </StructureProvider>

                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <CancelButton />

                    <LoadButton
                      text={
                        !company?.uuid
                          ? "Créer l'entreprise"
                          : "Modifier l'entreprise"
                      }
                      isLoading={isLoading}
                      icon={<RiAddFill size={38} />}
                      style={{ fontWeight: "bold" }}
                    />
                  </Stack>
                </>
              )}
            </form>

            {hasError && (
              <PageMessage
                message={
                  ((errorPostCompany as any) || (errorPatchCompany as any))
                    .response.data.message
                }
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </CompanyContext.Provider>
  );
}

export default CompanyDetail;
