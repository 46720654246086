import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import {
  getDictionnaireModeleLettreRequest,
  getModeleLettreDetailRequest,
} from "../../services/modelesLettreServices";
import useModeleLettre from "../../hooks/modeleLettre/useModeleLettre";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import ResetModeleLettreModal from "./ResetModeleLettreModal/ResetModeleLettreModal";

function useModelesLettreDetail() {
  const [modeleLettreId, setModeleLettreId] = useState(null);
  const modeleLettreProps = useModeleLettre();

  const { actions: modalActions } = useCustomModal();

  const {
    data: modele,
    isLoading: isLoadingGetModelLettre,
    isRefetching: isRefetchingGetModelLettre,
  } = useQuery(
    ["modelesLettre", modeleLettreId],
    () => getModeleLettreDetailRequest(modeleLettreId),
    {
      enabled: !!modeleLettreId,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dictionnaireModeleDeLettre,
    isLoading: isLoadingDictionnaire,
    isRefetching: isRefetchingDictionnaire,
  } = useQuery(
    ["dictionnaireModelesLettre", modeleLettreId],
    () => getDictionnaireModeleLettreRequest(modele?.type),
    {
      enabled: !!modele,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: modele,
  });

  const actions = {
    setUpdateModeleLettreId: (data: any) => {
      setModeleLettreId(data);
    },
    putModelLettre: (data: any) => {
      modeleLettreProps.actions.putModeleLettre(data);
    },
    openResetModeleLettreModal: (modeleLettre: any) => {
      modalActions.openModal({
        body: (
          <ResetModeleLettreModal
            modeleLettre={modeleLettre}
            onValidate={() => actions.onResetModeleLette(modeleLettre.uuid)}
          />
        ),
      });
    },
    onResetModeleLette: async (modeleLettreUuid: any) => {
      modeleLettreProps.actions.putResetModeleLettre(modeleLettreUuid);
    },
  };

  return {
    actions,
    reactHookProps,
    modele,
    dictionnaireModeleDeLettre,
    isLoadingDictionnaire: isLoadingDictionnaire || isRefetchingDictionnaire,
    modeleLettreProps,
    isLoadingGet: isLoadingGetModelLettre || isRefetchingGetModelLettre,
  };
}

export default useModelesLettreDetail;
