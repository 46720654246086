import {
  Stack,
  Text,
  Link,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Can from "../../Common/Can/Can";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import { userAccessEnum } from "../../config/accessEnum";
import { commonButtonStyles } from "../../styles/index";
import tools from "../../utils/tools";
import SubGroupCompanyListFilters from "./SubGroupCompanyListFilters/SubGroupCompanyListFilters";
import useSubGroupCompanyList from "./useSubGroupCompanyList";
import CreateLink from "../../Common/CreateLink/CreateLink";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useTheme from "../../Common/useTheme";

function SubGroupCompanyList() {
  const {
    subGroupCompanyProps,
    subGroupCompanies,
    optionsFilters,
    actions: actionsPage,
    isLoadingExportSubGroupCompany,
  } = useSubGroupCompanyList();
  const { primaryColor } = useTheme();
  const { userSelectedBranch } = subGroupCompanyProps;
  const items = subGroupCompanies?.data || [];

  const columns = [
    {
      key: "1",
      name: "Sous-groupe",
      fieldName: "nom",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "2",
      name: "Nom(s) utilisateur(s)",
      fieldName: "nom_utilisateurs",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.attributes.utilisateurs?.map((utilisateur: any, i: number) => (
            <Text key={i}>{utilisateur.nom}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "3",
      name: "Email(s) des utilisateur(s)",
      fieldName: "email_utilisateurs",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {item.attributes.utilisateurs?.map((utilisateur: any) => (
            <Text>{utilisateur.email}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "4",
      name: "Branche créatrice",
      fieldName: "branches_creatrice",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>{item.branche_creatrice.nom}</Text>
        </Stack>
      ),
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Can I={userAccessEnum.MODIFIER_SOUS_GROUPE}>
              <Link
                to={`/admin/sous-groupes-entreprises/${item.uuid}`}
                as={RouterLink}
                style={{ marginLeft: 5, marginTop: 5 }}
                styles={commonButtonStyles.buttonLink}
              >
                Editer
              </Link>
            </Can>
            <Link
              onClick={() => actionsPage.openDeleteSubGroupCompanyModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des sous-groupes d'entreprises"
        explain="Veuillez gérer les sous-groupes d'entreprises qui vous sont rattachés "
      >
        <Stack
          horizontal
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          <CreateLink
            text="Créer un nouveau sous-groupe d'entreprises"
            to="/admin/sous-groupes-entreprises/creer"
          />
          <Can I={userAccessEnum.EXPORTER_UTILISATEURS_ET_ENTITES}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les données sous-groupes d'entreprises"
                isLoading={isLoadingExportSubGroupCompany}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportSubGroupCompanies}
                primary={false}
              />
            </div>
          </Can>
        </Stack>
        {!userSelectedBranch.profils.includes("GROUPE_ENTREPRISE") && (
          <SubGroupCompanyListFilters />
        )}

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={subGroupCompanies?.meta.current_page || 1}
          totalPages={Math.ceil(subGroupCompanies?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default SubGroupCompanyList;
