import { Outlet } from "react-router-dom";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";

import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import {
  accepterCguRequest,
  getPageBranchRequest,
  refuserCguRequest,
} from "../../services/publicServices";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { commonButtonStyles } from "../../styles";
import { isOfflineAtom } from "../../hooks/useNav";
import { DateTime } from "luxon";
import useAuth from "../../hooks/useAuth";
import LoadButton from "../LoadButton/LoadButton";
import { toast } from "react-toastify";

const branchCGUAtom = atom(null);
const userValidCGUDuringThisSessionAtom = atom([]);

const RequireCGUAcceptance = () => {
  const [userSelectedBranch, setUserSelectedBranch]: any = useAtom(
    userSelectedBranchAtom
  );
  const [branchCGU, setBranchCGU]: any = useAtom(branchCGUAtom);
  const [isLoadingCGU, setIsLoadingCGU] = useState(false);
  const [isLoadingAcceptCGU, setIsLoadingAcceptCGU] = useState(false);
  const [userValidCGUDuringThisSession, setUserValidCGUDuringThisSession]: any =
    useAtom(userValidCGUDuringThisSessionAtom);
  const [isOffline] = useAtom(isOfflineAtom);
  const { auth } = useAuth();

  const getBranchCGU = async () => {
    setIsLoadingCGU(true);
    const response = await getPageBranchRequest("CGU");
    setBranchCGU((u: any) => ({
      ...u,
      [userSelectedBranch.id]: response,
    }));
    setIsLoadingCGU(false);
  };

  const hasAcceptedLastCGU = () => {
    const branchCguAcceptance = branchCGU[userSelectedBranch.id];
    const cguAcceptedOnThisBranch: any = auth?.user?.cgu_acceptees?.find(
      (cgu: any) => cgu.branch_id == userSelectedBranch.id
    );
    if (
      !branchCguAcceptance ||
      !cguAcceptedOnThisBranch?.acceptee_le ||
      DateTime.fromISO(branchCguAcceptance.updated_at) >=
        DateTime.fromISO(cguAcceptedOnThisBranch?.acceptee_le)
    ) {
      return;
    }
    setUserValidCGUDuringThisSession((prev: any) => [
      ...prev,
      userSelectedBranch.id,
    ]);
  };

  useEffect(() => {
    if ((!branchCGU || !branchCGU[userSelectedBranch.id]) && !isOffline)
      getBranchCGU();
    if (branchCGU) hasAcceptedLastCGU();
  }, [branchCGU]);

  const onAcceptCGU = async (isAcceptingCgu: boolean) => {
    setIsLoadingAcceptCGU(true);
    if (isAcceptingCgu) {
      try {
        await accepterCguRequest(branchCGU[userSelectedBranch.id].id);
        setUserValidCGUDuringThisSession((prev: any) => [
          ...prev,
          userSelectedBranch.id,
        ]);
      } catch (e: any) {
        toast.error(e);
      }
    } else {
      try {
        await refuserCguRequest(branchCGU[userSelectedBranch.id].id);
        setUserSelectedBranch(null);
      } catch (e: any) {
        toast.error(e);
      }
    }
    setIsLoadingAcceptCGU(false);
  };

  if (isOffline) {
    return <Outlet />;
  }

  if (isLoadingCGU || !branchCGU || !branchCGU[userSelectedBranch?.id]) {
    return (
      <FullscreenContainer horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} label="Vérification des CGU..." />
      </FullscreenContainer>
    );
  }

  return userValidCGUDuringThisSession.includes(userSelectedBranch.id) ? (
    <Outlet />
  ) : (
    <FullscreenContainer>
      <h1 style={{ marginBottom: 0 }}>
        {branchCGU[userSelectedBranch.id].titre}
      </h1>
      <p>
        <i>
          Veuillez lire et accepter les CGU de la branche. Défilez jusqu'en bas
          pour accepter.
        </i>
      </p>
      <div
        style={{
          height: "80vh",
          overflow: "auto",
          width: "100%",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: branchCGU[userSelectedBranch.id].contenu,
          }}
        ></div>
        <Stack horizontal style={{ marginTop: 40 }}>
          <LoadButton
            isLoading={isLoadingAcceptCGU}
            styles={commonButtonStyles.validateButton}
            onClick={() => onAcceptCGU(true)}
            style={{ marginRight: 20 }}
            text="Accepter les CGU et accéder à la plateforme"
          />

          <LoadButton
            isLoading={isLoadingAcceptCGU}
            styles={commonButtonStyles.deleteButton}
            onClick={() => onAcceptCGU(false)}
            style={{ marginRight: 20 }}
            text="Refuser les CGU (vous ne pourrez pas accéder à la plateforme)"
          />
        </Stack>
      </div>
    </FullscreenContainer>
  );
};

const FullscreenContainer = ({
  verticalAlign = "start",
  horizontalAlign = "start",
  children,
}: any) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "90vw",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: verticalAlign,
        alignItems: horizontalAlign,
        zIndex: "999",
        background: "white",
        padding: "5vw 5vh",
      }}
    >
      {children}
    </div>
  );
};

export default RequireCGUAcceptance;
