import { Icon, Link, Stack } from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import { commonButtonStyles } from "../../styles";
import useOfflineCandidaturePile, {
  SYNCHRO_STATUS,
  SYNCHRO_STATUS_COLORS,
  SYNCHRO_STATUS_ICONS,
  SYNCHRO_STATUS_NAMES_SING,
  TREATMENT_STATUS,
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
  TREATMENT_STATUS_NAMES,
} from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import LoadLink from "../../Common/LoadLink/LoadLink";
import tools from "../../utils/tools";
import SyncItem from "../../Common/SynchronizationWidget/SyncItem/SyncItem";

const OfflineCandidatureItem = ({ item }: any) => {
  const { actions: actionsPile } = useOfflineCandidaturePile();

  return (
    <div
      style={{
        width: "max(300px, calc(33% - 33px))",
        outline: "1px solid #dfdfdf",
        padding: "10px",
        boxShadow: "1px 1px 4px -2px grey",
        background: "#fcfcfc",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div>
          <Stack horizontal wrap horizontalAlign="end">
            <SyncItem
              text={TREATMENT_STATUS_NAMES[item.treatment_status]}
              color={TREATMENT_STATUS_COLORS[item.treatment_status]}
              qty=""
              icon={TREATMENT_STATUS_ICONS[item.treatment_status]}
              showText
              style={{ fontSize: 12 }}
            />
            <SyncItem
              text={SYNCHRO_STATUS_NAMES_SING[item.synchro_status]}
              color={SYNCHRO_STATUS_COLORS[item.synchro_status]}
              qty=""
              icon={SYNCHRO_STATUS_ICONS[item.synchro_status]}
              showText
              style={{ fontSize: 12 }}
            />
          </Stack>
          <div style={{ fontWeight: "bold" }}>
            <Link
              to={`/admin/candidature/${item.candidature_uuid}/evaluation`}
              as={RouterLink}
              styles={commonButtonStyles.buttonLink}
              style={{ fontWeight: "bold", display: "block" }}
            >
              <div>
                <Stack
                  horizontal
                  horizontalAlign="start"
                  verticalAlign="center"
                >
                  <Icon iconName="PartyLeader" style={{ marginRight: 5 }} />
                  {tools.capitalize(item.name.split("-")[0])}
                </Stack>
              </div>
              <div>{item.name.split("-")[1]}</div>
            </Link>
          </div>

          {item.error && (
            <div
              style={{
                color: "red",
                display: "flex",
                alignItems: "start",
                background: "#fee8e8",
                padding: 5,
              }}
            >
              Erreur de synchronisation : <br />
              {actionsPile.getCandidatureErrorFormated(item)}
            </div>
          )}
        </div>
        <div style={{ marginTop: 20 }}>
          <Stack horizontal wrap horizontalAlign="space-between">
            <Link
              onClick={() =>
                actionsPile.onRemoveCandidatureFromPile(item.candidature_uuid)
              }
              styles={commonButtonStyles.buttonLink}
              style={{ color: "red" }}
            >
              Retirer
            </Link>

            {item.treatment_status == TREATMENT_STATUS.NOT_TREATED ||
            item.synchro_status == SYNCHRO_STATUS.SYNCHRONIZED ? (
              <div></div>
            ) : (
              <LoadLink
                styles={commonButtonStyles.buttonLink}
                onClick={() => actionsPile.onSyncCandidatureFromPile(item)}
                //isLoading={isToggleActivationUuid == item.uuid}
                isLoading={false}
                text={"Synchroniser"}
              />
            )}
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default OfflineCandidatureItem;
