import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
const eCertifApi = axiosInstance;

const subGroupCompanyfURI = "administration/sous-groupes";

export const getSubGroupCompaniesRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    nom: { type: "string" },
    utilisateur: { type: "string" },
    branche_liee: { type: "select", keyValue: "id", valueNotFilter: 0 },
  };
  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${subGroupCompanyfURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}&type=SOUS_GROUPE_ENTREPRISE`
  );
  return response.data;
};

export const getSubGroupCompanyRequest = async (subGroupCompanyUuid: any) => {
  const response = await eCertifApi.get(
    `${subGroupCompanyfURI}/${subGroupCompanyUuid}`
  );
  return response.data;
};

export const deleteSubGroupCompanyRequest = async (
  subGroupCompanyUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${subGroupCompanyfURI}/${subGroupCompanyUuid}`,
    xsrfHeader
  );
  return response;
};

export const postSubGroupCompanyRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${subGroupCompanyfURI}`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putSubGroupCompanyRequest = async (subGroupCompany: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${subGroupCompanyfURI}/${subGroupCompany.sous_groupe.uuid}`,
    subGroupCompany,
    xsrfHeader
  );
  return response.data;
};

export const getExportSubGroupCompanies = async (filters: any) => {
  const filterType = {
    nom: { type: "string" },
    utilisateur: { type: "string" },
    branche_liee: { type: "select", keyValue: "id", valueNotFilter: 0 },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/sous-groupes?paginate=false&type=SOUS_GROUPE_ENTREPRISE${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
