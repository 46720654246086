import { Stack,Text } from "@fluentui/react"

function ErrorNotCatch() {
  return (
    <Stack
      style={{ width: "100%", minHeight: "90vh", background: "#e8e8e8" }}
      horizontalAlign="center"
      verticalAlign="center"
    >
        <Text variant="xxLarge">Oups</Text>
        <Text variant="large"style={{textAlign:'center'}}>
            Une erreur est survenue. <br/> 
            Veuillez vous rendre sur notre <br/>
            <a href="/">
                page d'accueil
            </a> et réessayer.
        </Text>
    </Stack>
  )
}

export default ErrorNotCatch