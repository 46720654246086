import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import useFetchCandidature from "../useFetchCandidature";
import useUrlParams from "../useUrlParams";
import AvisFormateurForm from "./AvisFormateurForm/AvisFormateurForm";
import EvaluationForm from "./EvaluationForm/EvaluationForm";
import InviterSecondEvaluateurButton from "./InviterSecondEvaluateurButton/InviterSecondEvaluateurButton";
import SecondeEvaluationForm from "./SecondeEvaluationForm/SecondeEvaluationForm";
import useAvisFormateurEtEvaluation from "./useAvisFormateurEtEvaluation";
import InviterEvaluateurButton from "../../../Common/InviterEvaluateurButton/InviterEvaluateurButton";
import { useAtom } from "jotai";
import { isSuccessToInviteEvaluateurAtom } from "../../../Common/InviterEvaluateurButton/useInviterEvaluateurButton";

export const statutAvisFormateurEtEvaluation = ["evaluation", "avis_formateur"];

function AvisFormateurEtEvaluation() {
  const { id_candidature } = useUrlParams();
  const {
    conditionnalDisplayVariable,
    candidature,
    reactHookProps,
    actions: pageActions,
    isSaving,
    isOffline,
    isEditMode,
    isOpenedInModal,
    candidatureActions,
    accessActions,
    mecActions,
    isSuccessEnregistrement,
    isSuccessToInviteSecondEvaluateur,
    isRemovedSecondEvaluateur,
    applicableEditPermissionEvaluation,
    applicableEditPermissionAvisFormateur,
    applicableEditSecondeEvaluationPermissions,
    inputsDisabledEvaluation,
    inputsDisabledAvisFormateur,
    inputsDisabledSecondeEvaluation,
    fetchModifiedCandidature,
  } = useAvisFormateurEtEvaluation({ id_candidature });
  const [isSuccessToInviteEvaluateur] = useAtom(
    isSuccessToInviteEvaluateurAtom
  );

  const fetchCandidature = useFetchCandidature(reactHookProps);

  useEffect(() => {
    if (
      id_candidature &&
      (isSuccessEnregistrement ||
        isSuccessToInviteSecondEvaluateur ||
        isRemovedSecondEvaluateur ||
        isSuccessToInviteEvaluateur)
    ) {
      fetchModifiedCandidature();
    }
  }, [
    isSuccessEnregistrement,
    isSuccessToInviteSecondEvaluateur,
    isRemovedSecondEvaluateur,
    isSuccessToInviteEvaluateur,
  ]);

  useEffect(() => {
    return () => candidatureActions.resetCandidature();
  }, []);

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>

      {!isOffline && (
        <>
          <InviterEvaluateurButton candidature={candidature} />
          <InviterSecondEvaluateurButton
            candidature={candidature}
            pageActions={pageActions}
            accessActions={accessActions}
            isSecondEvaluateur={reactHookProps.watch("second_evaluateur")}
          />
        </>
      )}

      <EvaluationForm
        candidature={candidature}
        fetchCandidature={fetchCandidature}
        reactHookProps={reactHookProps}
        pageActions={pageActions}
        mecActions={mecActions}
        accessActions={accessActions}
        isOffline={isOffline}
        isOpenedInModal={isOpenedInModal}
        isEditMode={isEditMode}
        isSaving={isSaving}
        conditionnalDisplayVariable={conditionnalDisplayVariable}
        applicableEditPermissionEvaluation={applicableEditPermissionEvaluation}
        inputsDisabledEvaluation={inputsDisabledEvaluation}
        inputsDisabledSecondeEvaluation={inputsDisabledSecondeEvaluation}
      />
      <SecondeEvaluationForm
        candidature={candidature}
        fetchCandidature={fetchCandidature}
        reactHookProps={reactHookProps}
        pageActions={pageActions}
        mecActions={mecActions}
        accessActions={accessActions}
        isOffline={isOffline}
        isOpenedInModal={isOpenedInModal}
        isEditMode={isEditMode}
        isSaving={isSaving}
        conditionnalDisplayVariable={conditionnalDisplayVariable}
        applicableEditSecondeEvaluationPermissions={
          applicableEditSecondeEvaluationPermissions
        }
        inputsDisabledSecondeEvaluation={inputsDisabledSecondeEvaluation}
      />
      {!isOffline && (
        <>
          {conditionnalDisplayVariable.doitAvoirAvisFormateur && (
            <AvisFormateurForm
              candidature={candidature}
              fetchCandidature={fetchCandidature}
              reactHookProps={reactHookProps}
              pageActions={pageActions}
              mecActions={mecActions}
              isSaving={isSaving}
              isEditMode={isEditMode}
              isOpenedInModal={isOpenedInModal}
              applicableEditPermissionAvisFormateur={
                applicableEditPermissionAvisFormateur
              }
              inputsDisabledAvisFormateur={inputsDisabledAvisFormateur}
            />
          )}
        </>
      )}
    </Stack>
  );
}

export default AvisFormateurEtEvaluation;
