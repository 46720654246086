import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { putLabelSettingsRequest } from "../../services/labelSettingServices";
import useLabelSettings from "./useLabelSettings";
import { toast } from "react-toastify";

let saveTimeout: any = null;

function useLabelSettingsDetail() {
  const [isLoadingLabelSettings] = useState(false);
  const [isSavingLabel, setIsSavingLabel] = useState(false);
  const { labelSettings, setLabelSettings }: any = useLabelSettings();
  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const actions = {
    save: async (label: any, event: any, isDescription: boolean = false) => {
      clearTimeout(saveTimeout);
      saveTimeout = setTimeout(async () => {
        setIsSavingLabel(true);
        try {
          const savedInput = await putLabelSettingsRequest(
            label.type,
            event.target.value
          );
          setLabelSettings((prevLabelSettings: any) => {
            const newLabelSettings = [...prevLabelSettings];
            const index = newLabelSettings.findIndex(
              (l: any) => l.type === label.type
            );
            newLabelSettings[index] = savedInput;
            return newLabelSettings;
          });
          if (isDescription) {
            toast.success("La description a été mise à jour avec succès");
          }
          setIsSavingLabel(false);
        } catch (err: any) {
          if (err.response?.data) {
            console.log(err.response.data);
          }
          setIsSavingLabel(false);
        }
      }, 300);
    },
  };

  useEffect(() => {
    if (labelSettings?.length > 0) {
      const toObject: any = {};
      labelSettings.forEach((label: any) => {
        toObject[label.type] = label.valeur;
      });
      reactHookProps.reset(toObject);
    }
  }, [labelSettings]);

  return {
    reactHookProps,
    labelSettings,
    actions,
    isLoadingLabelSettings,
    isSavingLabel,
  };
}

export default useLabelSettingsDetail;
