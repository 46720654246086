import { Label, Stack, TextField, Toggle } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../styles";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import { useContext } from "react";
import { BranchContext } from "../useBranchDetail";

function GeneralInformationsSection() {

    const { reactHookProps }:any = useContext(
        BranchContext
      );
    
      const {formState:{errors}, control, setValue } = reactHookProps

  return (
    <FormSection sectionTitle="Informations principales de la branche">
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      required
                      label="Nom de la branche :"
                      type="text"
                      {...field}
                      placeholder="Ex : MaBranche"
                      errorMessage={(errors.nom as any)?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="nom"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Label
                        htmlFor="active"
                        required
                        styles={commonInputStyles.label}
                      >
                        Activer les domaines sur la branche :
                      </Label>
                      <Toggle
                        styles={commonInputStyles.toggleInput}
                        onText="Oui"
                        offText="Non"
                        onChange={(e, checked) => onChange(checked)}
                        checked={value}
                        id="gestion_par_domaine"
                      />
                    </>
                  )}
                  defaultValue={false}
                  control={control}
                  name="gestion_par_domaine"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Label
                        htmlFor="active"
                        required
                        styles={commonInputStyles.label}
                      >
                        Activer la branche :
                      </Label>
                      <Toggle
                        styles={commonInputStyles.toggleInput}
                        onText="Oui"
                        offText="Non"
                        onChange={(e, checked) => onChange(checked)}
                        checked={value}
                        id="active"
                      />
                    </>
                  )}
                  defaultValue={false}
                  control={control}
                  name="active"
                />
              </Stack.Item>

              <Stack.Item style={{ width: "100%" }}>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Sélectionnez un ou plusieurs IDCC(s) :"
                      placeholder="Recherchez un IDCC(s) en tapant les premières lettres de son nom"
                      resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?recherche=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) =>
                        item.idcc + " | " + item.titre_convention
                      }
                      renderValue={(item: any) =>
                        item.idcc + " | " + item.titre_convention
                      }
                      dataIndex="id"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      required={true}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{}}
                      errorMessage={(errors.ref_idccs as any)?.message}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="ref_idccs"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      required
                      label="Email de l’administrateur principal :"
                      type="email"
                      {...field}
                      placeholder="Ex : john.doe@gmail.com"
                      errorMessage={(errors.admin as any)?.email?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="admin.email"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      required
                      label="Prénom de l’administrateur principal :"
                      type="text"
                      {...field}
                      placeholder="Ex : John"
                      errorMessage={(errors.admin as any)?.prenom?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="admin.prenom"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      required
                      label="Nom de l’administrateur principal :"
                      type="text"
                      {...field}
                      placeholder="Ex : Doe"
                      errorMessage={(errors.admin as any)?.nom?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="admin.nom"
                />
              </Stack.Item>
            </FormSection>
  )
}

export default GeneralInformationsSection