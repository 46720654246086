import { Stack, Text } from "@fluentui/react";
import { CopyCardStyles } from "./CopyCard.styles";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";

function CopyCard({
  text,
  value,
  id,
}: {
  text: string;
  value: string;
  id: string;
}) {
  return (
    <Stack
      key={id}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={CopyCardStyles}
      tokens={{ childrenGap: 5 }}
      onClick={() => {
        navigator.clipboard
          .writeText(value)
          .then(() =>
            toast.success(
              `${text} copiée dans le presse-papier et collez (ctrl+v) à l’endroit désiré`
            )
          );
      }}
    >
      <Stack.Item>
        <Text>{text}</Text>
      </Stack.Item>
      <Stack.Item>
        <FaCopy size={15} />
      </Stack.Item>
    </Stack>
  );
}

export default CopyCard;
