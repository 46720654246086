import { FontSizes, Icon, Link, Stack, Text } from "@fluentui/react";
import { BiUser, BiLink } from "react-icons/bi";
import { BsPhone, BsTelephone } from "react-icons/bs";
import { IoIosAt } from "react-icons/io";
import useTheme from "../useTheme";
import tools from "../../utils/tools";
import { candidatInformationsStyles } from "./CandidatInformationsPreview.styles";
import { commonButtonStyles } from "../../styles";
import Badge from "../Badge/Badge";
import CreateLink from "../CreateLink/CreateLink";
import { Link as RouterLink } from "react-router-dom";
import useCandidature from "../../hooks/candidature/useCandidature";
import { useAtom } from "jotai";
import { isOfflineAtom } from "../../hooks/useNav";
import { isOpenedInModalAtom } from "../../Pages/CandidatureWorkflow/CandidatureWorkflow";

//definir firstTime -> badge Show or No Show
//definir url fiche individuelle du candidat -> download ? page candidat ?

const CandidatInformationsPreview = ({
  candidat,
  isCandidat = false,
  extraInformation = true,
}: any) => {
  const { primaryColor } = useTheme();
  const { candidature } = useCandidature();
  const [isOffline] = useAtom(isOfflineAtom);
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const date_naissance = new Date(candidat?.date_de_naissance);

  const linkToProfilCandidat = isCandidat
    ? `${candidat?.uuid}/profil`
    : "profil";

  let isProfilIncomplet = true;
  if (
    candidat &&
    candidat.nom_usage &&
    candidat.adresse &&
    candidat.date_de_naissance &&
    candidat.telephone_portable &&
    ((candidat.ne_etranger && candidat.pays_naissance) ||
      (!candidat.ne_etranger &&
        candidat.code_postal_naissance &&
        candidat.ville_naissance &&
        candidat.region_naissance))
  ) {
    isProfilIncomplet = false;
  }

  if (candidat) {
    return (
      <Stack>
        <Stack
          horizontal
          verticalAlign="center"
          wrap
          tokens={{ childrenGap: 10 }}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            verticalAlign="center"
            styles={
              extraInformation
                ? candidatInformationsStyles.imgContainerExtraInformation
                : candidatInformationsStyles.imgContainer
            }
            style={{ background: primaryColor }}
          >
            <Icon iconName="user" />
            <BiUser size={extraInformation ? 80 : 10} color={"white"} />
          </Stack>

          <Stack verticalAlign="center" wrap>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 20 }}
              style={extraInformation ? { marginBottom: 10 } : {}}
              wrap
            >
              <Stack.Item style={{ display: "flex", alignItems: "center" }}>
                <Text
                  className="ms-fontWeight-bold"
                  style={{ fontSize: 16 }}
                >{`${candidat.prenom} ${candidat.nom} ${
                  candidat.nom_usage && candidat.nom !== candidat.nom_usage
                    ? `(${candidat.nom_usage})`
                    : ""
                }`}</Text>
              </Stack.Item>

              {!isOffline && !isOpenedInModal && (
                <Stack.Item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: primaryColor,
                  }}
                >
                  <BiLink size={25} />
                  <Link
                    as={RouterLink}
                    to={`/admin/fiche-candidat/${
                      candidature?.candidat.uuid || candidat.uuid
                    }`}
                    styles={commonButtonStyles.buttonLink}
                    style={{
                      padding: 0,
                      color: "inherit",
                      paddingLeft: 5,
                      fontWeight: 600,
                    }}
                  >
                    Fiche individuelle du candidat
                  </Link>
                  {isProfilIncomplet &&
                    candidature.statut == "transmission_dossier" && (
                      <Text
                        style={{
                          marginLeft: 10,
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        Profil incomplet
                      </Text>
                    )}
                </Stack.Item>
              )}

              {candidat?.firstTime && (
                <Stack.Item>
                  <Badge
                    text="Premier passage sur la certification"
                    color="#0AA204"
                  />
                </Stack.Item>
              )}
            </Stack>

            {extraInformation && (
              <>
                {candidat?.date_de_naissance ? (
                  <Stack.Item>
                    <Text>
                      Né(e) le {tools.reformateDateFromJsDate(date_naissance)}
                    </Text>
                  </Stack.Item>
                ) : null}

                <Stack.Item>
                  <Text>
                    {candidat.adresse?.adresse} {candidat.adresse?.code_postal}{" "}
                    {candidat.adresse?.ville}
                  </Text>
                </Stack.Item>

                <Stack
                  horizontal
                  verticalAlign="center"
                  style={{ color: primaryColor, marginTop: 10 }}
                  tokens={{ childrenGap: 20 }}
                  wrap
                >
                  <Stack.Item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IoIosAt size={25} />
                    <Link
                      href={`mailto:${candidat.email}`}
                      styles={commonButtonStyles.buttonLink}
                      style={{
                        padding: 0,
                        color: "inherit",
                        paddingLeft: 5,
                        fontSize: FontSizes.size16,
                      }}
                    >
                      {candidat.email}
                    </Link>
                  </Stack.Item>

                  {candidat.telephone_portable && (
                    <Stack.Item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BsPhone size={20} />
                      <Link
                        href={`tel:${candidat.telephone_portable}`}
                        styles={{
                          root: {
                            color: "inherit",
                            fontSize: FontSizes.size16,
                            paddingLeft: 5,
                          },
                        }}
                      >
                        {tools.formatPhoneNumber(candidat.telephone_portable)}
                      </Link>
                      <Text
                        className="text-info"
                        style={{
                          paddingLeft: 8,
                          fontWeight: "400",
                          fontStyle: "italic",
                        }}
                      >
                        mobile
                      </Text>
                    </Stack.Item>
                  )}

                  {candidat.telephone_professionnel && (
                    <Stack.Item
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BsTelephone size={20} />
                      <Link
                        href={`tel:${candidat.telephone_professionnel}`}
                        styles={{
                          root: {
                            color: "inherit",
                            fontSize: FontSizes.size16,
                            paddingLeft: 5,
                          },
                        }}
                      >
                        {tools.formatPhoneNumber(
                          candidat.telephone_professionnel
                        )}
                      </Link>
                      <Text
                        className="text-info"
                        style={{
                          paddingLeft: 8,
                          fontWeight: "400",
                          fontStyle: "italic",
                        }}
                      >
                        professionnel
                      </Text>
                    </Stack.Item>
                  )}

                  <Stack.Item>
                    <CreateLink
                      text="Editer le profil"
                      to={linkToProfilCandidat}
                    />
                  </Stack.Item>

                  <Stack.Item>
                    <Stack style={{ color: "#6f6f6f", fontStyle: "italic" }}>
                      <Stack.Item>Date de dernière modification : </Stack.Item>
                      <Stack.Item>
                        {tools.formatDate(candidat.modifie_le)}
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        {!extraInformation && (
          <Stack>
            {candidature.cree_le && (
              <Stack.Item>
                <Text>
                  {`Date de création du dossier : 
                ${tools.formatDate(candidature.cree_le)}`}
                </Text>
              </Stack.Item>
            )}
            {candidature.evaluation_le && (
              <Stack.Item>
                <Text>
                  {`Date de l'évaluation : 
                 ${tools.reformateDateFromJsDate(candidature.evaluation_le)}`}
                </Text>
              </Stack.Item>
            )}
            {candidature.demande_inscription_jury_le && (
              <Stack.Item>
                <Text>
                  {`Date de la dernière inscription au jury de certification : 
                ${tools.formatDate(candidature.demande_inscription_jury_le)}`}
                </Text>
              </Stack.Item>
            )}
          </Stack>
        )}
        {!candidat.telephone_portable && extraInformation && (
          <Stack.Item>
            <p style={{ color: "red" }}>
              Le profil du candidat n'a pas encore été renseigné, veuillez
              cliquer sur le bouton "Editer le profil" ci-dessus et compléter le
              formulaire.
            </p>
          </Stack.Item>
        )}
      </Stack>
    );
  }
  return <></>;
};

export default CandidatInformationsPreview;
