import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteGroupOfRequest, patchActivateGroupOfRequest, patchDesactivateGroupOfRequest, postGroupOfRequest, putGroupOfRequest } from "../../services/groupOfServices";

function useGroupOfMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [groupOfUuid, setGroupOfUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);

  const mutations = {
    PostGroupOf: useMutation(postGroupOfRequest,
      {
        onSuccess: (data) => {
          toast.success("Le groupe d'OF a été créé avec succès.");
          setGroupOfUuid(data.uuid);
          queryClient.invalidateQueries("groupOfs");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
              `Une erreur est survenue lors de l'enregistrement du groupe d'OF : ${error?.response?.data?.message}`
            );
        },
      }
    ),
    PutGroupOf: useMutation(putGroupOfRequest, {
      onSuccess: () => {
        toast.success("Le groupe d'OF a été mis à jour avec succès.");
        queryClient.invalidateQueries("groupOfs");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
            `Une erreur est survenue lors de la mise à jour du groupe d'OF : ${error?.response?.data?.message}`
          );
      },
    }),
    DeleteGroupOf: useMutation(deleteGroupOfRequest, {
      onSuccess: () => queryClient.invalidateQueries('groupOfs'),
    }),
    ToggleGroupOfActivate: useMutation(patchActivateGroupOfRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("Le groupe d'OF a été activé avec succès.");
        queryClient.invalidateQueries('groupOfs')
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
    ToggleGroupOfDesactivate: useMutation(patchDesactivateGroupOfRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("Le groupe d'OF a été désactivé avec succès.");
        queryClient.invalidateQueries('groupOfs')
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    })
  }

  return {
    groupOfUuid,
    setGroupOfUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
  }
}

export default useGroupOfMutations