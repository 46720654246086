import { Link as RouterLink } from "react-router-dom";
import { ISideNavItemProps } from "../../interfaces/sidenavInterface";
import useNav from "../../hooks/useNav";

function SideNavItem({
  path,
  title,
  icon,
  isActive,
  isReduced = false,
}: ISideNavItemProps) {
  const { actions } = useNav();

  return (
    <li key={`routelink${title}`} style={{ width: "100%" }}>
      <RouterLink
        to={path}
        className={`sideNavItem ${isActive ? "selectedNavItem" : ""}`}
        onClick={actions.toggleNav}
      >
        <span className="sideNavItemIcon d-center">{icon}</span>
        {!isReduced && <span className="sideNavItemText">{title}</span>}
      </RouterLink>
    </li>
  );
}

export default SideNavItem;
