import { Stack,Text } from "@fluentui/react"

import {commonButtonStyles} from '../../styles/index'
function FourHundred() {
  return (
    <Stack
      style={{ width: "100%", minHeight: "90vh", background: "#e8e8e8" }}
      horizontalAlign="center"
      verticalAlign="center"
    >
        <Text variant="xxLarge">404 PAGE NOT FOUND</Text>

        <a href='/' style={
         {...commonButtonStyles.buttonLink.root, width: 300, margin: "20px 0px" }}
         >
            Retour à la page d'accueil
          </a>
    </Stack>
  )
}

export default FourHundred