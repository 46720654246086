export const styles = {
  container: {
    root: {
      padding: 20,
      border: "1px solid rgb(215 215 215)",
      borderRadius: 8,
      marginBottom: 20,
    },
  },
  header: {
    color: "#6F6F6F",
    top: "-0.6rem",
    position: "relative",
    backgroundColor: "#fff",
    display: "inline",
    marginRight: 50,
    width: "25%",
  },
};
