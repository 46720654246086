import { Stack } from "@fluentui/react";
import Loader from "../Loader/Loader";
import useTheme from "../useTheme";

function DetailLoader({ children, isLoadingDetail, customText='En cours de chargement ...' }: any) {
  const { primaryColor } = useTheme();

  return (
    <>
      {isLoadingDetail && (
        <Stack
          style={{
            position: "fixed",
            top: "50px",
            right: "21px",
            padding: "10px",
            zIndex: "10",
            backgroundColor: "#3b50df",
            color: "white",
            borderRadius: "5px",
          }}
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
        >
          <Loader />
          <span style={{ marginLeft: 10 }}>{customText}</span>
        </Stack>
      )}
      {children}
    </>
  );
}

export default DetailLoader;
