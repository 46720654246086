import { atom, useAtom } from "jotai";
import { useQuery } from "react-query";
import { getJuryRequest } from "../../services/juryServices";

export const juryInfoAtom = atom({});

function useJuryQuery(juryProps: any) {
  const [, setJuryInfo] = useAtom(juryInfoAtom);

  const {
    data: jury,
    isLoading: isLoadingJury,
    isRefetching: isRefetchingJury,
  } = useQuery(
    ["jurys", juryProps.juryUuid],
    () => getJuryRequest(juryProps.juryUuid),
    {
      enabled: !!juryProps.juryUuid,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setJuryInfo(data);
      },
    }
  );

  return {
    jury,
    isLoadingJury,
    isRefetchingJury,
  };
}

export default useJuryQuery;
