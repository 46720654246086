import { Checkbox, Dropdown, Stack, TextField } from "@fluentui/react";
import { useEffect } from "react";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { commonInputStyles } from "../../../styles";
import useFormulaireSuiviCohortes from "./useFormulaireSuiviCohortes";

const FormulaireSuiviCohortes = ({
  initialFormState,
  onSave = () => {},
  style = {},
  constants = [],
  isPrivate = true,
}: any) => {
  const { formState, actions } = useFormulaireSuiviCohortes({ onSave });

  useEffect(() => {
    if (initialFormState) {
      actions.initializeFormState(initialFormState);
    }
  }, [initialFormState]);

  return (
    <Stack>
      {isPrivate && (
        <Stack style={{ display: "inline" }}>
          Lien :{" "}
          <a
            target="_blank"
            href={`${process.env.REACT_APP_APP_URL}/suivi_cohorte?token=${initialFormState.uuid}`}
            rel="noreferrer"
          >
            {process.env.REACT_APP_APP_URL}/suivi_cohorte?token=
            {initialFormState.uuid}
          </a>
        </Stack>
      )}
      <Stack tokens={{ childrenGap: 20 }} style={style}>
        <Stack.Item>
          <Dropdown
            label="Situation après certification"
            placeholder="Sélectionnez un élément"
            options={
              constants.CANDIDATURES
                ? constants.CANDIDATURES.SITUATION_APRES_CERTIFICATION
                : []
            }
            onChange={(e: any, options: any) => {
              actions.handleChangeFormState({
                target: {
                  name: "situation_apres_certification",
                  value: options,
                },
              });
            }}
            styles={commonInputStyles.dropdownStyle}
            required
            selectedKey={
              formState.situation_apres_certification
                ? (formState.situation_apres_certification as any).key
                : null
            }
          />
        </Stack.Item>
        <Stack.Item>
          <Dropdown
            label="Type de contrat"
            placeholder="Sélectionnez un élément"
            options={
              constants.CANDIDATURES ? constants.CANDIDATURES.TYPES_CONTRAT : []
            }
            onChange={(e: any, options: any) => {
              actions.handleChangeFormState({
                target: {
                  name: "type_contrat",
                  value: options,
                },
              });
            }}
            styles={commonInputStyles.dropdownStyle}
            selectedKey={
              formState.type_contrat
                ? (formState.type_contrat as any).key
                : null
            }
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            styles={commonInputStyles.textField}
            label="Intitulé du poste occupé :"
            type="text"
            name="intitule_poste_occupe"
            placeholder="Ex : Mon poste"
            value={formState.intitule_poste_occupe}
            onChange={actions.handleChangeFormState}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            styles={commonInputStyles.textField}
            label="Nom de l'entreprise :"
            type="text"
            name="nom_entreprise"
            placeholder="Ex : Mon entreprise"
            value={formState.nom_entreprise}
            onChange={actions.handleChangeFormState}
          />
        </Stack.Item>
        <Stack.Item>
          <TextField
            styles={commonInputStyles.textField}
            label="Rémunération annuelle brute (en €) :"
            type="text"
            name="remuneration_brute_annuelle"
            placeholder="Ex : 25000"
            value={formState.remuneration_brute_annuelle}
            onChange={actions.handleChangeFormState}
          />
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            styles={commonInputStyles.checkbox}
            label="Avez-vous un statut de cadre ?"
            name="statut_cadre"
            checked={formState.statut_cadre}
            onChange={actions.handleChangeFormState}
          />
        </Stack.Item>
      </Stack>
      <Stack horizontalAlign="end" tokens={{ childrenGap: 20 }}>
        <LoadButton text="Enregistrer" onClick={actions.handleSave} />
      </Stack>
    </Stack>
  );
};

export default FormulaireSuiviCohortes;
