import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useJury from "../../../hooks/jury/useJury";
import { getJuryAvailableForCandidature } from "../../../services/juryServices";

const filtersAtom = atom<any>({});

function useInscriptionJury() {
  const {
    candidature,
    actions: candidatureActions,
    isSuccessModifyCandidatureJury,
    isLoadingModifyCandidatureJury,
    isSuccessRejectCandidature,
    isSuccessAcceptCandidature,
    isLoadingAcceptCandidature,
    isLoadingRejectCandidature,
    generalConditionnalDisplayVariables,
  } = useCandidature();
  const {
    actions: juryActions,
    isSuccessJuryAddCandidats,
    isLoadingPutJuryAddCandidats,
  } = useJury();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [isAdminModifiedJury, setIsAdminModifiedJury] = useState(false);

  const reactHookProps = useForm({ mode: "onBlur" /*resolver:joiResolver()*/ });

  const { data: juryList, isLoading: isLoadingJuryList } = useQuery(
    ["jurys", filters],
    () => getJuryAvailableForCandidature(filters),
    { retry: 0 }
  );

  const actions = {
    setUpdateIsAdminModifiedJury: (value: any) => {
      setIsAdminModifiedJury(value);
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
    },
    onSave: async (data: any, onAfterSaved: any = () => {}) => {
      const candidats = { candidatures: [data.uuid] };
      const response = await juryActions.putJuryAddCandidats(
        data.jury,
        candidats
      );
      onAfterSaved();
    },
  };

  return {
    isAdminModifiedJury,
    candidature,
    actions,
    reactHookProps,
    juryList,
    candidatureActions,
    isSuccessModifyCandidatureJury,
    isLoadingModifyCandidatureJury,
    isSuccessJuryAddCandidats,
    isSuccessRejectCandidature,
    isSuccessAcceptCandidature,
    isLoadingAcceptCandidature,
    isLoadingRejectCandidature,
    isLoadingPutJuryAddCandidats,
  };
}

export default useInscriptionJury;
