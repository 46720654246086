import { MessageBar, Stack, TextField } from "@fluentui/react";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { MessageBarStyles, commonInputStyles } from "../../styles";
import LoadButton from "../LoadButton/LoadButton";
import usePublicSearchExistingStructure from "./usePublicSearchExistingStructure";

type PublicSearchExistingStructureProps = {
  reactHookProps: UseFormReturn;
  isChecked: boolean;
  isExistant: boolean;
  actions: any;
  keyToCheck: string;
  acceptedChararacterNumber: 14 | 9;
  structureType: any;
};

function PublicSearchExistingStructure({
  reactHookProps,
  isChecked,
  isExistant,
  actions,
  keyToCheck,
  acceptedChararacterNumber,
  structureType,
}: PublicSearchExistingStructureProps) {
  const {
    actions: actionsPage,
    keyToCheckToString,
    isLoading,
  } = usePublicSearchExistingStructure({
    reactHookProps,
    keyToCheck,
    structureType,
    contextActions: actions,
  });

  const {
    formState: { errors },
    control,
  } = reactHookProps;

  useEffect(() => {
    actions.setUpdateIsCheck(false, false);
  }, [reactHookProps.watch(keyToCheck)]);

  const getBlocsInfoMessage = () => {
    if (!isChecked) {
      return (
        <MessageBar styles={MessageBarStyles}>
          La vérification de l'existence du {keyToCheckToString} est obligatoire
          pour poursuivre la création.
        </MessageBar>
      );
    }

    if (isExistant) {
      return (
        <MessageBar styles={MessageBarStyles}>
          Une structure a été trouvée. Cliquez sur le bouton "Envoyer ma demande
          d'inscription" pour valider votre demande d'inscription sur la
          plateforme.
        </MessageBar>
      );
    }

    return (
      <MessageBar styles={MessageBarStyles}>
        Aucune structure n'a été trouvée sur la plateforme. Veuillez renseigner
        les champs ci-dessous pour faire une demande d'inscription de structure.
      </MessageBar>
    );
  };

  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label={`Saisissez le ${keyToCheckToString}`}
              type="text"
              placeholder={`N° ${keyToCheckToString} à ${acceptedChararacterNumber} chiffres sans espace`}
              errorMessage={(errors[keyToCheck] as any)?.message}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= acceptedChararacterNumber) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={control}
          name={keyToCheck}
        />
      </Stack.Item>

      <LoadButton
        isLoading={isLoading}
        text="Vérifier l'existence"
        onClick={actionsPage.onVerify}
        type="button"
        style={{ maxWidth: 270 }}
      />

      {getBlocsInfoMessage()}
    </>
  );
}

export default PublicSearchExistingStructure;
