import { Stack, Checkbox, Text } from "@fluentui/react";
import Page from "../../Common/Page/Page";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
// import ScrollableTable from "../../Common/ScrollableTable/ScrollableTable";
import { IColumnType } from "../../Common/ScrollableTable/types";
// import RolesManagementFilters from "./RolesManagementFilters/RolesManagementFilters";
import useRolesManagement, {
  RolesManagementContext,
} from "./useRolesManagement";
import { commonInputStyles } from "../../styles";
import tools from "../../utils/tools";
import useConstants from "../../hooks/useConstants";
import { Controller } from "react-hook-form";
import Accordion from "../../Common/Accordion/Accordion";
import Tooltip from "../../Common/Tooltip/Tooltip";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";

const profilOrder = [
  "ADMINISTRATEUR_BRANCHE",
  "ADMINISTRATEUR_DOMAINE",
  "CONSULTATION",
  "ENTREPRISE",
  "SOUS_GROUPE_ENTREPRISE",
  "GROUPE_ENTREPRISE",
  "OF",
  "SOUS_GROUPE_OF",
  "GROUPE_OF",
  "CONSULTATION_OF",
  "POSITIONNEUR",
  "EVALUATEUR",
  "SECOND_EVALUATEUR",
  "MEMBRE_JURY",
  "CANDIDAT",
];

const categorieOrder = [
  "Fonctionnalités générales",
  "Gestion des entités",
  "Création du candidat",
  "Repérage",
  "Avis formateur",
  "Évaluation du candidat",
  "Jury de certification",
  "Suivi de cohortes",
  "Exports / Statistiques",
];

function RolesManagement() {
  const {
    branchRolesSettings,
    isLoadingBranchRolesSettings,
    actions,
    reactHookProps,
    filters,
  }: any = useRolesManagement();
  const { branchSettings } = useAccessSettings();
  const { handleSubmit, control } = reactHookProps;
  const { constants } = useConstants();

  console.log("branchSettings", branchSettings);
  //column ------------------
  const columnFormatted = constants.MATRICES.PROFILS_UTILISATEURS.sort(
    (a: any, b: any) => {
      const aOrder = profilOrder.indexOf(a.id);
      const bOrder = profilOrder.indexOf(b.id);
      return aOrder - bOrder;
    }
  )
    .filter((profil: any) => {
      if (
        filters?.roles == undefined ||
        Object.values(filters?.roles).length == 0
      ) {
        return true;
      } else {
        return filters.roles.find((role: any) => role.id == profil.id);
      }
    })
    .map((profil: any) => {
      return {
        key: profil.id,
        title: profil.text,
        // render: (_: any, item: any) => {
        //   if (typeof item[_.key] === "object" && item[_.key] !== null) {
        //     return (
        //       <Controller
        //         name={item[_.key].profil + item[_.key].acces}
        //         control={control}
        //         render={({ field: { value } }) => (
        //           <Checkbox
        //             styles={{
        //               ...commonInputStyles.checkbox,
        //               root: { justifyContent: "center" },
        //             }}
        //             checked={value ?? item[_.key].value}
        //             disabled={item[_.key].disabled}
        //             onChange={(e: any, checked: any) =>
        //               actions.onChangeCheckbox(_.key, item.key, checked)
        //             }
        //           />
        //         )}
        //       />
        //     );
        //   } else {
        //     return <></>;
        //   }
        // },
      };
    });

  console.log("columnFormatted", columnFormatted);

  const columns: IColumnType<any>[] = [
    {
      key: "profil_action",
      title: "Action/Profil",
    },
    ...columnFormatted,
  ];

  //data ------------------
  const allActions = Array.from(
    new Set(
      branchRolesSettings
        ?.filter((setting: any) => {
          if (
            filters?.categories?.length == undefined ||
            filters?.categories?.length == 0
          ) {
            return true;
          } else {
            return filters.categories.find(
              (categorie: any) => categorie.text == setting.acces_categorie
            );
          }
        })
        .map((x: any) => x.acces)
    )
  );

  const rolesSettingsFormatted = allActions.map((accesId: any) => {
    const onlySettingDisplay = branchRolesSettings.filter(
      (setting: any) => setting.acces == accesId
    );
    const response: any = {};
    onlySettingDisplay.forEach((setting: any) => {
      response[setting.profil] = {
        value: setting.est_accessible,
        disabled: !setting.est_parametrable || setting.est_desactive,
        profil: setting.profil,
        acces: setting.acces,
      };
    });
    return response;
  });

  const data: any = allActions.map((acces: any, index: any) => {
    const setting: any = tools.findIn(branchRolesSettings, acces, "acces");
    return {
      key: setting.acces,
      profil_action: setting.acces_label,
      categorie: setting.acces_categorie,
      parametres_lies: setting.parametres_lies,
      ...rolesSettingsFormatted[index],
    };
  });

  const categoriesList = tools.addKeyToObjectInArray(
    Array.from(
      new Set(branchRolesSettings?.map((x: any) => x.acces_categorie))
    ).map((x: any) => {
      return { text: x };
    })
  );

  console.log("data", data);

  return (
    <RolesManagementContext.Provider
      value={{ branchRolesSettings, actions, filters }}
    >
      <Page
        title="Gérer les multi-rôles & étapes utilisateurs"
        explain={
          "Veuillez assembler les rôles des profils utilisateurs de votre branche "
        }
      >
        {categoriesList
          .sort((a: any, b: any) => {
            const aOrder = categorieOrder.indexOf(a.text);
            const bOrder = categorieOrder.indexOf(b.text);
            return aOrder - bOrder;
          })
          .map((categorie: any) => {
            return (
              <Accordion
                title={categorie.text}
                key={categorie.text}
                noImg
                defaultIsOpen={false}
                styles={{
                  wrapper: {
                    background: "#ebebeb",
                    padding: "5px 5px 5px 8px",
                    borderRadius: 5,
                    cursor: "pointer",
                  },

                  content: {
                    root: {
                      paddingLeft: 10,
                    },
                  },
                }}
                content={
                  <>
                    {data
                      .filter(
                        (setting: any) => setting.categorie == categorie.text
                      )
                      .sort((a: any, b: any) =>
                        a.profil_action.localeCompare(b.profil_action)
                      )
                      .map((setting: any) => {
                        const contentToolTip = (
                          <Stack>
                            Cet accès est lié au(x) paramètre(s) de branche :
                            <ul>
                              {setting.parametres_lies
                                ?.map(
                                  (impact: any) =>
                                    (
                                      tools.findIn(
                                        branchSettings,
                                        impact,
                                        "parametre"
                                      ) as any
                                    ).label
                                )
                                .sort(
                                  (accessA: any, accessB: any) =>
                                    accessA.split(" ")[0].split(".")[1] -
                                    accessB.split(" ")[0].split(".")[1]
                                )
                                .map((labelImpact: any) => (
                                  <li>{labelImpact}</li>
                                ))}
                            </ul>
                          </Stack>
                        );
                        return (
                          <Accordion
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {setting.profil_action}
                                {setting.parametres_lies?.length > 0 && (
                                  <Tooltip
                                    id={setting.profil + setting.acces}
                                    content={contentToolTip}
                                    iconName={"UserWarning"}
                                    colors={{
                                      classic: "#D40000",
                                      hovered: "#960000",
                                    }}
                                  />
                                )}
                              </div>
                            }
                            key={setting.profil + setting.acces}
                            noImg
                            defaultIsOpen={false}
                            styles={{
                              wrapper: {
                                background: "#f5f5f5",
                                paddingLeft: 10,
                                borderBottom: "1px solid silver",
                                cursor: "pointer",
                                borderRadius: 5,
                                marginBottom: 2,
                              },
                              content: {
                                root: { paddingBottom: 10 },
                              },
                            }}
                            content={
                              <>
                                {columnFormatted.map(
                                  (column: any, i: number) => {
                                    if (
                                      typeof setting[column.key] === "object" &&
                                      setting[column.key] !== null
                                    ) {
                                      return (
                                        <div
                                          style={{ display: "flex" }}
                                          key={`li${column.key}${setting.key}${i}`}
                                        >
                                          <Controller
                                            name={`${column.key}${setting.key}`}
                                            control={control}
                                            render={({ field: { value } }) => (
                                              <Checkbox
                                                styles={{
                                                  ...commonInputStyles.checkbox,
                                                  root: {
                                                    justifyContent: "center",
                                                  },
                                                }}
                                                checked={
                                                  value ??
                                                  setting[column.key].value
                                                }
                                                disabled={
                                                  setting[column.key].disabled
                                                }
                                                onChange={(
                                                  e: any,
                                                  checked: any
                                                ) =>
                                                  actions.onChangeCheckbox(
                                                    column.key,
                                                    setting.key,

                                                    checked
                                                  )
                                                }
                                              />
                                            )}
                                          />

                                          <span>{column.title}</span>
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </>
                            }
                          />
                        );
                      })}
                  </>
                }
              />
            );
          })}

        {/* <RolesManagementFilters /> */}

        <form onSubmit={handleSubmit(actions.saveCurrentBranchRolesSettings)}>
          {/* <ScrollableTable data={data} columns={columns} /> */}
          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <CancelButton />

            <LoadButton
              text={"Enregistrer les multi-roles"}
              isLoading={isLoadingBranchRolesSettings}
            />
          </Stack>
        </form>
      </Page>
    </RolesManagementContext.Provider>
  );
}

export default RolesManagement;
