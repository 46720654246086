import { useQuery } from "react-query";
import { getGroupCompanyRequest } from "../../services/groupCompanyServices";

function useGroupCompanyQuery(groupComapnyProps: any) {
  const {
    data: groupCompany,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ["groupCompanies", groupComapnyProps.groupCompanyUuid],
    () => getGroupCompanyRequest(groupComapnyProps.groupCompanyUuid),
    {
      enabled: !!groupComapnyProps.groupCompanyUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    groupCompany,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useGroupCompanyQuery;
