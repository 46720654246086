import { DefaultButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { commonButtonStyles } from "../../styles/index";
import useTheme from "../useTheme";

function CancelButton({ onClick = null, text = "Annuler", style={} }: any) {
  const navigate = useNavigate();
  const { primaryColor } = useTheme();

  return (
    <DefaultButton
      onClick={onClick ? onClick : () => navigate(-1)}
      styles={commonButtonStyles.defaultButton}
      style={{ color: primaryColor, borderColor: primaryColor, ...style }}
      className="buttonHeight"
    >
      {text}
    </DefaultButton>
  );
}

export default CancelButton;
