import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";
import tools from "../utils/tools";

const eCertifApi = axiosInstance;

export const generalStaticPagesURI = "administration/pages-generales";
export const branchStaticPagesURI = "administration/pages-generales-branche";

//--------- Branch page services
export const getBranchStaticPages = async (filters: any, options: any) => {
  const filterType = {
    titre: { type: "string" },
  };

  const queryFiltersUrl = filters
    ? tools.generateFilterUrl(filters, filterType)
    : "";
  const queryOptionsUrl = options ? tools.generateOptionUrl(options) : "";

  const response = await eCertifApi.get(
    `${branchStaticPagesURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getBranchStaticPage = async (pageId: any) => {
  const response = await eCertifApi.get(`${branchStaticPagesURI}/${pageId}`);
  return response.data;
};

export const putBranchStaticPage = async (dataPage: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.put(
    `${branchStaticPagesURI}/${dataPage.id}`,
    dataPage,
    xsrfHeader
  );
  return response.data;
};

//--------- General page services
export const getStaticPages = async (filters: any, options: any) => {
  const filterType = {
    titre: { type: "string" },
  };

  const queryFiltersUrl = filters
    ? tools.generateFilterUrl(filters, filterType)
    : "";
  const queryOptionsUrl = options ? tools.generateOptionUrl(options) : "";

  const response = await eCertifApi.get(
    `${generalStaticPagesURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getStaticPage = async (pageId: any) => {
  const response = await eCertifApi.get(`${generalStaticPagesURI}/${pageId}`);
  return response.data;
};

export const putStaticPage = async (dataPage: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.put(
    `${generalStaticPagesURI}/${dataPage.id}`,
    dataPage,
    xsrfHeader
  );
  return response.data;
};
