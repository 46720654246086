import { ChoiceGroup, Slider, Stack, Text } from "@fluentui/react"
import { useState } from "react";
import { Controller } from "react-hook-form";
import useConstants from "../../hooks/useConstants";
import useTheme from "../useTheme";

const slideStyle = {
    root: {
        zIndex: 2,
        selectors: {
            ".ms-Slider-slideBox": {
                padding: 0,
            },
            ".ms-Slider-active": {
                backgroundColor: 'rgb(183, 183, 183)',
                height: '2px'
            },
            ".ms-Slider-inactive": {
                backgroundColor: 'rgb(183, 183, 183)',
                height: '2px'
            },
        }
    }
}

function EvaluationInput({
    modalites_evaluation,
    inputName,
    onChange = () => { },
    disabled = false,
    reactHookProps,
    defaultValue,
}: any) {

    const { primaryColor } = useTheme()
    const {constants} = useConstants()
    const { control } = reactHookProps;
    const [scoreValue, setScoreValue] = useState(defaultValue)
    const typeModaliteEvaluation: 1 | 2 | 3 | 4 | 5 = modalites_evaluation?.type;

    var options: any = []
    var graduationDivider: any = []
    if (typeModaliteEvaluation == 1) {
        options = constants['CANDIDATURES']['DECISIONS_EVALUATION']
    } else if (typeModaliteEvaluation < 5) {
        Object.keys(modalites_evaluation?.notations).map((key: any, index: any) =>
            index != Object.keys(modalites_evaluation?.notations).length - 1 ? options.push(
                {
                    key: modalites_evaluation?.notations[key],
                    text: modalites_evaluation?.notations[key],
                    styles: { root: { marginRight: 10, marginTop: 0 } },
                }
            ) : options.push(
                {
                    key: modalites_evaluation?.notations[key],
                    text: modalites_evaluation?.notations[key],
                    styles: { root: { marginTop: 0 } },
                }
            )
        )
    } else {
        const numberGraduation = (modalites_evaluation?.note_sur_score / modalites_evaluation?.granularite_score)
        const widthStack = (100 / numberGraduation);
        for (var i = 0; i < numberGraduation; i++) {
            graduationDivider.push(
                <Stack key={i} style={{ height: 10, width: `${widthStack}%`, borderRight: '1px solid rgb(183, 183, 183)', borderLeft: i == 0 ? '1px solid rgb(183, 183, 183)' : 'none', position: 'relative' }}>
                    <span style={{ position: 'absolute', bottom: -20, transform: "translateX(-50%)", color: 'rgb(100, 100, 100)', fontSize: 11 }}>{i * modalites_evaluation?.granularite_score}</span>
                    {(i + 1) * modalites_evaluation?.granularite_score == modalites_evaluation?.note_sur_score &&
                        <span style={{ position: 'absolute', bottom: -20, right: -5, color: 'rgb(100, 100, 100)', fontSize: 11 }}>{(i + 1) * modalites_evaluation?.granularite_score}</span>
                    }
                </Stack>
            )
        }
    }

    return (
        <Stack style={typeModaliteEvaluation == 5 ? { marginRight: "40px" } : {}}>
            {typeModaliteEvaluation < 5
                ? <Controller
                    control={control}
                    name={inputName}
                    render={({ field: { name, value } }) => (
                        <ChoiceGroup
                            disabled={disabled}
                            styles={{ flexContainer: { display: "flex", flexWrap:'wrap' } }}
                            name={name}
                            onChange={(e: any, option: any) => onChange(option.key)}
                            required={true}
                            options={options}
                            selectedKey={defaultValue ?? null}
                        />
                    )}
                />
                : <Controller
                    control={control}
                    name={inputName}
                    render={({ field: { name, value } }) => (
                        <Stack horizontal verticalAlign="center">
                            <Stack style={{ position: 'relative', width: '100%' }}>
                                <Slider
                                    step={modalites_evaluation?.granularite_score}
                                    min={0}
                                    max={modalites_evaluation?.note_sur_score}
                                    onChanged={(e: any, value: any) => {
                                        setScoreValue(value)
                                        onChange(value)
                                    }}
                                    styles={slideStyle}
                                    showValue={false}
                                    defaultValue={defaultValue}
                                />
                                <Stack horizontal style={{ position: 'absolute', width: '100%', zIndex: 1, top: '27%' }}>
                                    {graduationDivider}
                                </Stack>
                            </Stack>

                            <Text style={{ width: 0, marginBottom: 2, marginLeft: 10, color: primaryColor }}>{scoreValue}/{modalites_evaluation?.note_sur_score}</Text>
                        </Stack>
                    )}
                />

            }
        </Stack>
    )
}

export default EvaluationInput