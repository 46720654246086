import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../../hooks/useAuth";

export const Positionnement = React.createContext({});
export const PositionnementProvider = ({ value, children }: any) => {
  return (
    <Positionnement.Provider value={value}>{children}</Positionnement.Provider>
  );
};
export const usePositionnement = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { actions: accessActions } = useAccessSettings();
  const { auth }: any = useAuth();
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const conditionnalDisplayVariable = {
    isPositionneurThisCandidature:
      candidature.positionneur?.uuid == auth.user.uuid ||
      (candidature.createur?.uuid == auth.user.uuid &&
        accessActions.canI({
          action:
            branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT,
        }).granted),
  };

  const actions = {
    onSave: async (data: any, isEnregistrerPositionnement: boolean) => {
      setIsSaving(true);
      const positionnementObject = {
        ...data,
        isEnregistrerPositionnement,
        positionnement: data.blocs_competences_passes.map((b: any) => ({
          uuid: b.candidature_bloc_competence_uuid,
          decision: b.decision_positionnement,
          commentaire_positionnement: b.commentaire_positionnement,
          competences_associees: accessActions.canI({
            action: branchAccessEnum.POSITIONNEMENT_PAR_COMPETENCE,
          }).granted
            ? b.competences.map((c: any) => ({
                uuid: c.uuid,
                decision: c.decision_positionnement,
              }))
            : null,
        })),
      };
      await candidatureActions.putCandidatureFormData(
        positionnementObject,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
    onValidate: () => {
      const validateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_positionnement: "MAITRISE",
          competences: accessActions.canI({
            action: branchAccessEnum.POSITIONNEMENT_PAR_COMPETENCE,
          }).granted
            ? b.competences?.map((c: any) => ({
                ...c,
                decision_positionnement: "MAITRISE",
              }))
            : b.competences,
        }));
      reactHookProps.setValue("blocs_competences_passes", validateAll);
    },
    onInvalidate: () => {
      const invalidateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_positionnement: "BESOIN_DE_FORMATION",
          competences: accessActions.canI({
            action: branchAccessEnum.POSITIONNEMENT_PAR_COMPETENCE,
          }).granted
            ? b.competences?.map((c: any) => ({
                ...c,
                decision_positionnement: "BESOIN_DE_FORMATION",
              }))
            : b.competences,
        }));
      reactHookProps.setValue("blocs_competences_passes", invalidateAll);
    },
  };

  // useEffect(() => {
  //   reactHookProps.setValue(
  //     "blocs_competences_passes",
  //     candidature.certification.blocs_competences.filter(
  //       (bloc: any) =>
  //         bloc.option == (reactHookProps.watch("option_selectionnee")?.key || null) &&
  //         bloc.statut == 1
  //     )
  //   );
  // }, [reactHookProps.watch("option_selectionnee")]);

  return {
    candidature,
    reactHookProps,
    actions,
    conditionnalDisplayVariable,
    isSaving,
  };
};
