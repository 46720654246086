import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import certificationAdapters from "../../adapters/certificationAdapters";
import useConstants from "../../hooks/useConstants";
import { CertificationContext } from "./CertificationFormContext/CertificationFormContext";
import DocumentsSection from "./FormSections/DocumentsSection";
import GeneralInformationsSection from "./FormSections/GeneralInformationsSection";
import ReferentialSection from "./FormSections/ReferentialSection";
import useCertificationDetail from "./useCertificationDetail";

function CertificationDetail() {
  const { uuid } = useParams();
  const {
    certification,
    certificationProps,
    reactHookProps,
    actions: actionsPage,
    conditionnalDisplayVariables,
    isLoadingCertification,
  } = useCertificationDetail();

  const {
    isLoading,
    hasError,
    errorPatchCertification,
    errorPostCertification,
    actions,
  } = certificationProps;

  const { constants } = useConstants();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
    register,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  const { blocs_de_competence, isUsingExistingReferential, hasOptions } =
    conditionnalDisplayVariables;

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateCertificationUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (certification?.uuid) {
      reset(
        certificationAdapters.transformForBO(
          certification,
          constants["CERTIFICATIONS"]
        )
      );
    }
  }, [certification]);

  //A combiner avec les errorPatchCertification
  useEffect(() => {
    var apiErrors = (errorPostCertification as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostCertification]);

  //A combiner avec les errorPostCertification
  useEffect(() => {
    var apiErrors = (errorPatchCertification as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPatchCertification]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.saveCertification)(e);
  };

  return (
    <CertificationContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        errors,
        allBlocks: blocs_de_competence,
        blocksDisabled: isUsingExistingReferential,
        nbBlockDisabled: isUsingExistingReferential,
        conditionnalDisplayVariables,
      }}
    >
      <Stack>
        <BackLink />

        <Page
          title={
            !certification?.uuid
              ? "Ajouter une certification"
              : "Editer une certification"
          }
          explain={
            !certification?.uuid
              ? "Veuillez créer votre certification"
              : "Veuillez modifier votre certification"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingCertification}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: !certification?.uuid ? "POST" : "PUT",
                })}
              />
              <GeneralInformationsSection />
              <ReferentialSection />
              <DocumentsSection />
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginTop: 20 }}
              >
                <CancelButton />

                <LoadButton
                  text={!certification?.uuid ? "Créer" : "Modifier"}
                  isLoading={isLoading}
                />
              </Stack>
            </form>

            {hasError && (
              <PageMessage
                message={
                  (
                    (errorPostCertification as any) ||
                    (errorPatchCertification as any)
                  ).response.data.message
                }
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </CertificationContext.Provider>
  );
}

export default CertificationDetail;
