import { Stack, Link } from "@fluentui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

function BackLink({ style, onClick, text = "Retour" }: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = (e: any) => {
    if (onClick) {
      onClick(e);
    } else {
      // Split the current path into segments
      const pathSegments = location.pathname.split("/");
      // Remove the last segment
      pathSegments.pop();

      // Join the remaining segments back into a string
      let newPath = pathSegments.join("/") + location.search; // Include the search string
      console.log("newPath", newPath);
      if (!newPath) newPath = "/"; // If the new path is empty, set it to the root path ("/"

      // Navigate to the new path
      navigate(newPath);
    }
  };

  return (
    <div style={{ background: "#fcfcfc" }}>
      <Link
        onClick={onClickHandler}
        style={{
          textDecoration: "none",
          fontWeight: 400,
          margin: "20px 30px 0px",
          ...style,
        }}
      >
        <Stack horizontal style={{ fontSize: 17 }} verticalAlign="center">
          <FaArrowLeft size={15} style={{ marginRight: 6 }} />
          {text}
        </Stack>
      </Link>
    </div>
  );
}

export default BackLink;
