import { useQuery } from "react-query";
import { getCompanyRequest } from "../../services/companyServices";

function useCompanyQuery(companyProps: any) {
  const {
    data: company,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ["companies", companyProps.companyUuid],
    () => getCompanyRequest(companyProps.companyUuid),
    {
      enabled: !!companyProps.companyUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    company,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useCompanyQuery;
