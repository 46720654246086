import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
const eCertifApi = axiosInstance;

const subGroupOfURI = "administration/sous-groupes";

export const getSubGroupOfsRequest = async (filters: any, options: any) => {
  const filterType = {
    nom: { type: "string" },
    utilisateur: { type: "string" },
    branche_liee: { type: "select", keyValue: "id", valueNotFilter: 0 },
    certifications_liees: { type: "array", keyValue: "uuid" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);
  const response = await eCertifApi.get(
    `${subGroupOfURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}&type=SOUS_GROUPE_OF`
  );
  return response.data;
};

export const getSubGroupOfRequest = async (subGroupOfUuid: any) => {
  const response = await eCertifApi.get(`${subGroupOfURI}/${subGroupOfUuid}`);
  return response.data;
};

export const deleteSubGroupOfRequest = async (subGroupOfUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${subGroupOfURI}/${subGroupOfUuid}`,
    xsrfHeader
  );
  return response;
};

export const getExportSubGroupOf = async (filters: any) => {
  const filterType = {
    nom: { type: "string" },
    utilisateur: { type: "string" },
    branche_liee: { type: "select", keyValue: "id", valueNotFilter: 0 },
    certifications_liees: { type: "array", keyValue: "uuid" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/sous-groupes?paginate=false&type=SOUS_GROUPE_OF${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
