import { Stack, Toggle } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CustomImageInput from "../../Common/CustomImageInput/CustomImageInput";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import DictionnaireData, {
  DictionnaireDataDropdown,
} from "../../Common/DictionnaireData/DictionnaireData";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import TextEditor from "../../Common/TextEditor/TextEditor";
import { commonInputStyles } from "../../styles";
import useModelesLettreDetail from "./useModelesLettreDetail";

function ModelesLettreDetail() {
  const { id } = useParams();
  const {
    actions: pageActions,
    modele,
    reactHookProps,
    isLoadingDictionnaire,
    dictionnaireModeleDeLettre,
    modeleLettreProps,
    isLoadingGet,
  } = useModelesLettreDetail();
  const {
    isLoadingPut,
    isErrorPutModeleLettre,
    errorPutModeleLettre,
    actions,
    isLoadingPreviewUuid,
  } = modeleLettreProps;
  const { reset, handleSubmit, control, setValue, watch, register } =
    reactHookProps;

  const [isDisabledPreview, setIsDisabledPreview] = useState(false);

  useEffect(() => {
    pageActions.setUpdateModeleLettreId(id);
    return () => reset();
  }, []);

  useEffect(() => {
    reset(modele);
    setIsDisabledPreview(false);
  }, [modele]);

  const togglePreview = () => {
    if (!isDisabledPreview) {
      setIsDisabledPreview(true);
    }
  };

  return (
    <Stack>
      <BackLink />

      <Page
        title="Personnalisation des couriers"
        explain={`Vous pouvez modifier le modèle de lettre : ${modele?.titre}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          <DictionnaireData
            dictionnaire={dictionnaireModeleDeLettre}
            isLoadingDictionnaire={isLoadingDictionnaire}
          />
          <DictionnaireDataDropdown
            dictionnaire={dictionnaireModeleDeLettre}
            isLoadingDictionnaire={isLoadingDictionnaire}
          />
          <div className="detailFormPersonnalisation">
            <DetailLoader isLoadingDetail={isLoadingGet}>
              <form onSubmit={handleSubmit(pageActions.putModelLettre)}>
                <Stack tokens={{ childrenGap: 5 }}>
                  <input
                    type="hidden"
                    {...register("_method", {
                      value: "PUT",
                    })}
                  />
                  <Stack.Item>
                    <TextEditor
                      name={"emetteur"}
                      label={"Émetteur"}
                      control={control}
                      onChange={(data: any) => {
                        setValue("emetteur", data, { shouldValidate: true });
                        togglePreview();
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <TextEditor
                      name={"destinataire"}
                      label={"Destinataire"}
                      control={control}
                      onChange={(data: any) => {
                        setValue("destinataire", data, {
                          shouldValidate: true,
                        });
                        togglePreview();
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <TextEditor
                      name={"contenu"}
                      label={"Contenu de la lettre"}
                      control={control}
                      onChange={(data: any) => {
                        setValue("contenu", data, {
                          shouldValidate: true,
                        });
                        togglePreview();
                      }}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Controller
                      render={({ field: { onChange, name, value } }) => (
                        <CustomImageInput
                          name={name}
                          label="Signature :"
                          onChange={(e: any) => {
                            onChange((e.target as any).files[0]);
                            setValue(`${name}_deleted`, false);
                          }}
                          isFile={value ? true : false}
                          file={value || watch("signature_url")}
                          alt="Aperçu de la signature"
                          isEditable={true}
                          isDeleted={watch(`${name}_deleted`) ? true : false}
                          onClick={() => {
                            setValue(`${name}_deleted`, true);
                            setValue(`${name}`, {});
                            (document.getElementById(`${name}`) as any).value =
                              "";
                            togglePreview();
                          }}
                        />
                      )}
                      control={control}
                      name="signature"
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <Toggle
                          styles={commonInputStyles.toggleInput}
                          label="Afficher le logo :"
                          inlineLabel
                          onText="Oui"
                          offText="Non"
                          onChange={(e, checked) => {
                            onChange(checked);
                          }}
                          checked={value}
                        />
                      )}
                      defaultValue={false}
                      control={control}
                      name="afficher_logo"
                    />
                  </Stack.Item>
                </Stack>

                <Stack
                  horizontal
                  horizontalAlign="end"
                  style={{ marginTop: 20 }}
                  tokens={{ childrenGap: 10 }}
                >
                  <LoadButton
                    type="button"
                    primary={false}
                    text={"Pré-visualiser"}
                    onClick={() => actions.onPreviewModeleLette(modele)}
                    isLoading={isLoadingPreviewUuid != null}
                    isDisabled={isDisabledPreview}
                  />

                  <LoadButton
                    type="button"
                    primary={false}
                    text={"Restaurer"}
                    onClick={() =>
                      pageActions.openResetModeleLettreModal(modele)
                    }
                    isLoading={isLoadingPut}
                  />

                  <LoadButton isLoading={isLoadingPut} text={"Valider"} />
                </Stack>
              </form>
              {isErrorPutModeleLettre && (
                <PageMessage
                  message={(errorPutModeleLettre as any).response.data.message}
                  type="error"
                />
              )}
            </DetailLoader>
          </div>
        </div>
      </Page>
    </Stack>
  );
}

export default ModelesLettreDetail;
