import {
  ChoiceGroup,
  DatePicker,
  Label,
  MaskedTextField,
  Stack,
  TextField,
} from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormSection from "../../../../Common/FormSection/FormSection";
import { CandidatureContext } from "../useCreationCandidature";

import { DateTime } from "luxon";
import BirthInformationFormSection from "../../../../Common/BirthInformationFormSection/BirthInformationFormSection";
import InputMessage from "../../../../Common/InputMessage/InputMessage";
import ResidenceInformationFormSection from "../../../../Common/ResidenceInformationFormSection/ResidenceInformationFormSection";
import { strings } from "../../../../config/datePickerConfig";
import useConstants from "../../../../hooks/useConstants";
import { commonInputStyles } from "../../../../styles/index";
import tools from "../../../../utils/tools";

function GeneralInformationsSection({
  disabled,
  enabledInputs = [],
  withContainer = true,
  requiredInputs = [],
}: any) {
  const { constants } = useConstants();
  const { reactHookProps }: any = useContext(CandidatureContext);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = reactHookProps;

  const content = (
    <>
      <Stack.Item style={{ width: "50%" }}>
        <Controller
          render={({ field: { value, name } }) => (
            <ChoiceGroup
              label="Civilité :"
              styles={commonInputStyles.textField}
              selectedKey={value ? value.key : 3}
              options={constants["CIVILITE"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              name={name}
              disabled={disabled && !enabledInputs.includes(name)}
              // required
            />
          )}
          control={control}
          name="candidat.civilite"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              // required
              label="Nom d'usage :"
              type="text"
              {...field}
              placeholder="Ex : Doe"
              errorMessage={(errors.candidat?.nom_usage as any)?.message}
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.nom_usage"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { onChange } }) => (
            <>
              <Label
                htmlFor="candidat.date_de_naissance"
                styles={commonInputStyles.label}
              >
                Date de naissance :
              </Label>
              <DatePicker
                formatDate={(date?: any): any =>
                  date ? `${tools.reformateDateFromJsDate(date)}` : null
                }
                strings={strings}
                showGoToToday={false}
                disabled={
                  disabled &&
                  !enabledInputs.includes("candidat.date_de_naissance")
                }
                value={
                  watch("candidat.date_de_naissance")
                    ? new Date(watch("candidat.date_de_naissance"))
                    : undefined
                }
                styles={commonInputStyles.dataPicker}
                onSelectDate={(date: any) => {
                  if (date) {
                    onChange(DateTime.fromJSDate(date).toISO());
                  }
                }}
                placeholder="dd/mm/yyyy"
                allowTextInput
                parseDateFromString={(dateStr: any) =>
                  tools.parseDateFromStringDatePicker(dateStr)
                }
              />
              {errors.candidat?.date_de_naissance && (
                <InputMessage
                  message={(errors.candidat.date_de_naissance as any)?.message}
                />
              )}
            </>
          )}
          defaultValue={undefined}
          control={control}
          name="candidat.date_de_naissance"
        />
      </Stack.Item>

      <BirthInformationFormSection
        control={control}
        onResetValue={(e: any, checked: any) => {
          setValue("candidat.pays_naissance", "");
          setValue("candidat.code_postal_naissance", "");
          setValue("candidat.region_naissance", "");
          setValue("candidat.ville_naissance", "");
        }}
        codePostalvarToWatch={watch("candidat.code_postal_naissance") ?? false}
        neEtrangervarToWatch={!watch("candidat.ne_etranger") ?? false}
        disabled={disabled}
        errors={errors}
        enabledInputs={enabledInputs}
        withContainer={false}
        required={requiredInputs.includes("candidat.code_postal_naissance")}
        onPrefillCityAndRegion={(data: any) => {
          setValue("candidat.region_naissance", data.nom_region);
          setValue("candidat.ville_naissance", data.nom_commune);
        }}
      />

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <MaskedTextField
              styles={commonInputStyles.textField}
              mask="99 99 99 99 99"
              // required
              label="Téléphone portable :"
              {...field}
              onChange={(ev: any, v: any) =>
                setValue("candidat.telephone_portable", v)
              }
              errorMessage={
                (errors.candidat?.telephone_portable as any)?.message
              }
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.telephone_portable"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <MaskedTextField
              styles={commonInputStyles.textField}
              mask="99 99 99 99 99"
              label="Téléphone professionnel :"
              {...field}
              onChange={(ev: any, v: any) =>
                setValue("candidat.telephone_professionnel", v)
              }
              errorMessage={
                (errors.candidat?.telephone_professionnel as any)?.message
              }
              disabled={disabled && !enabledInputs.includes(field.name)}
            />
          )}
          defaultValue=""
          control={control}
          name="candidat.telephone_professionnel"
        />
      </Stack.Item>
      <ResidenceInformationFormSection
        required={requiredInputs.includes("candidat.code_postal")}
        control={control}
        varToWatch={watch("code_postal") ?? false}
        errors={errors?.adresse}
        disabled={disabled && !enabledInputs.includes("adresse")}
        withContainer={false}
        onPrefillCityAndRegion={(data: any) => {
          setValue("ville", data.nom_commune);
          setValue("region", data.nom_region);
        }}
      />
    </>
  );

  return withContainer ? (
    <FormSection sectionTitle="Informations du candidat">{content}</FormSection>
  ) : (
    content
  );
}

export default GeneralInformationsSection;
