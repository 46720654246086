import Joi from "joi";


export const staticPagesSchema = Joi.object({
  titre: Joi.string()
    .required()
    .messages({
      "string.empty": "Veuillez renseigner un titre",
      "any.required": "Veuillez renseigner un titre",
    }),
  contenu: Joi.string()
    .required()
    .messages({
      "string.empty": "Veuillez ajouter du contenu",
      "any.required": "Veuillez ajouter du contenu",
    }),
}).unknown(true);
