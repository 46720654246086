import Badge from "../Badge/Badge";

function HabilitationStatutBadge({ statut }: { statut: string }) {
  const statutInfos: any = {
    A_VENIR: {
      text: "A venir",
      color: "#007bff",
    },
    ACTIVE: {
      text: "Active",
      color: "green",
    },
    EXPIREE: {
      text: "Expirée",
      color: "#ffc107",
    },
    RETIREE: {
      text: "Retirée",
      color: "red",
    },
  };

  return (
    <Badge
      text={statutInfos[statut].text}
      color={statutInfos[statut].color}
      textStyle={{ color: "#ffffff" }}
    />
  );
}

export default HabilitationStatutBadge;
