import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import CreateLink from "../../Common/CreateLink/CreateLink";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import { commonButtonStyles } from "../../styles/index";
import tools from "../../utils/tools";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import { entityTypes } from "../../Common/ImportEntities/useImportEntities";
import LoadLink from "../../Common/LoadLink/LoadLink";
import OpenImportButton from "../../Common/OpenImportButton/OpenImportButton";
import GroupeCompanyFilters from "./GroupeCompanyFilters/GroupeCompanyFilters";
import useGroupCompanyList from "./useGroupCompanyList";
import Can from "../../Common/Can/Can";
import { userAccessEnum } from "../../config/accessEnum";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useTheme from "../../Common/useTheme";

function GroupCompanyList() {
  const {
    groupCompanies,
    groupCompanyProps,
    optionsFilters,
    actions: actionsPage,
    isLoadingExportGroupCompany,
  } = useGroupCompanyList();
  const { primaryColor } = useTheme();
  const { actions, isToggleActivationUuid } = groupCompanyProps;

  const items = groupCompanies?.data || [];

  const columns = [
    {
      key: "1",
      name: "Raison sociale",
      fieldName: "raison_sociale",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("raison_sociale");
      },
      ...tools.sorted(optionsFilters.sort, "raison_sociale"),
    },
    {
      key: "2",
      name: "SIREN",
      fieldName: "siren",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.siren}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("siren");
      },
      ...tools.sorted(optionsFilters.sort, "siren"),
    },
    {
      key: "3",
      name: "Entreprises liées",
      fieldName: "entreprises",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Stack>
          {item.entreprises?.map((entreprise: any, i: number) => (
            <Text key={i}>{entreprise.nom}</Text>
          ))}
        </Stack>
      ),
    },
    {
      key: "4",
      name: "Actif",
      fieldName: "actif",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <BooleanValue value={item["actif"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn("actif");
      },
      ...tools.sorted(optionsFilters.sort, "actif"),
    },
    {
      key: "5",
      name: "Multibranche",
      fieldName: "interbranches",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.interbranches} />,
    },
    {
      key: "6",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Link
              to={`/admin/groupes-entreprises/${item.uuid}`}
              as={RouterLink}
              style={{ marginLeft: 5, marginTop: 5 }}
              styles={commonButtonStyles.buttonLink}
            >
              Editer
            </Link>
            <Link
              onClick={() => actionsPage.openDeleteGroupCompanyModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>

            <LoadLink
              styles={
                item.actif
                  ? commonButtonStyles.disableButtonLink
                  : commonButtonStyles.activeButtonLink
              }
              onClick={() => actions.toggleGroupCompanyActivation(item)}
              isLoading={isToggleActivationUuid == item.uuid}
              text={item.actif ? "Désactiver" : "Activer"}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des groupes d'entreprises"
        explain="Veuillez gérer les groupes d'entreprises rattachés à votre branche"
      >
        <Stack
          horizontal
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          <CreateLink
            text="Créer un Groupe d'Entreprises"
            to="/admin/groupes-entreprises/creer"
          />
          <Can I={userAccessEnum.EXPORTER_UTILISATEURS_ET_ENTITES}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les données des groupes d'entreprises"
                isLoading={isLoadingExportGroupCompany}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportGroupCompanies}
                primary={false}
              />
            </div>
          </Can>
          <OpenImportButton entityType={entityTypes.GROUPE_ENTREPRISE} />
        </Stack>
        <GroupeCompanyFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={groupCompanies?.meta.current_page || 1}
          totalPages={Math.ceil(groupCompanies?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default GroupCompanyList;
