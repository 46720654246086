import { Stack, TextField } from '@fluentui/react'
import { useParams } from 'react-router-dom'
import useDomaineDetail from './useDomaineDetail';
import { useEffect } from 'react';
import domaineAdapters from '../../adapters/domaineAdapters';
import BackLink from '../../Common/BackLink/BackLink';
import Page from '../../Common/Page/Page';
import DetailLoader from '../../Common/DetailLoader/DetailLoader';
import CancelButton from '../../Common/CancelButton/CancelButton';
import LoadButton from '../../Common/LoadButton/LoadButton';
import { RiAddFill } from 'react-icons/ri';
import PageMessage from '../../Common/PageMessage/PageMessage';
import FormSection from '../../Common/FormSection/FormSection';
import { Controller } from 'react-hook-form';
import { commonInputStyles } from '../../styles';
import SelectManyFieldAsync from '../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync';
import { API_URL } from '../../config/config';
import SelectFieldAsync from '../../Common/SelectField/SelectFieldAsync/SelectFieldAsync';

function DomaineDetail() {
    const { uuid } = useParams();
    const { domaine, domaineProps, reactHookProps, actions: actionsPage, isLoadingDomaine } = useDomaineDetail();
    const { isLoading, hasError, errorPostDomaine, errorPutDomaine, actions, userSelectedBranch } = domaineProps
    const { handleSubmit, formState: { errors }, setError, reset, clearErrors, control, setValue } = reactHookProps

    useEffect(() => {
        return () => reset()
    }, [])

    useEffect(() => {
        if (uuid != "creer") {
            actions.setUpdateDomaineUuid(uuid)
        }
    }, [uuid])

    useEffect(() => {
        if (domaine?.uuid) {
            reset(domaineAdapters.transformForBO(domaine))
        }
    }, [domaine])

    useEffect(() => {
        var apiErrors = (errorPostDomaine as any)?.response?.data;
        if (apiErrors?.errors) {
            for (var key in apiErrors.errors) {
                setError(key as any, { type: "focus", message: apiErrors.errors[key] });
            }
        }
    }, [errorPostDomaine]);

    useEffect(() => {
        var apiErrors = (errorPutDomaine as any)?.response?.data;
        if (apiErrors?.errors) {
            for (var key in apiErrors.errors) {
                setError(key as any, { type: "focus", message: apiErrors.errors[key] });
            }
        }
    }, [errorPutDomaine])

    const onSubmit = (e: any) => {
        clearErrors();
        handleSubmit(actionsPage.saveDomaine)(e)
    }

    return (
        <Stack>
            <BackLink />

            <Page
                title={!domaine?.uuid ? "Ajouter un domaine" : "Editer un domaine"}
                explain={!domaine?.uuid
                    ? "Veuillez créer un domaine rattaché à votre branche"
                    : "Veuillez modifier un domaine rattaché à votre branche"
                }
            >
                <DetailLoader isLoadingDetail={isLoadingDomaine}>
                    <form onSubmit={onSubmit}>
                        <FormSection sectionTitle="Informations principales du domaine">
                            <Stack.Item>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            styles={commonInputStyles.textField}
                                            label="Nom du domaine :"
                                            type="text"
                                            {...field}
                                            placeholder="Ex : Mon domaine"
                                            errorMessage={(errors[field.name] as any)?.message}
                                            required
                                        />
                                    )}
                                    defaultValue=""
                                    control={control}
                                    name="nom"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    render={({ field: { name, value, onBlur, ref } }) => (
                                        <SelectManyFieldAsync
                                            label="Sélectionnez les OF liés à ce domaine :"
                                            placeholder="Recherchez un OF par son nom"
                                            resourceURI={`${API_URL}api/administration/ofs/chercher`}
                                            addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?raison_sociale=${terms}&actif=true`}
                                            method="GET"
                                            renderItem={(item: any) => item.raison_sociale + " - " + item.siret}
                                            renderValue={(item: any) => item.raison_sociale + " - " + item.siret}
                                            dataIndex="uuid"
                                            name={name}
                                            value={value}
                                            onBlur={onBlur}
                                            fieldRef={ref}
                                            onChange={(e: any) => {
                                                setValue(name, e.target.value, {
                                                    shouldValidate: true,
                                                });
                                            }}
                                            defaultValue={{}}
                                            errorMessage={(errors.ref_idccs as any)?.message}
                                            handleResponse={(response: any) => [...response.data.data]}
                                        />
                                    )}
                                    control={control}
                                    name="ofs"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    render={({ field: { name, value, onBlur, ref } }) => (
                                        <SelectManyFieldAsync
                                            label="Sélectionnez les certifications liées au domaine :"
                                            placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                                            resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                                            addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?libelle=${terms}&paginate=false&branche_creatrice_id=${userSelectedBranch.id}`}
                                            method="GET"
                                            renderItem={(item: any) => item.libelle}
                                            renderValue={(item: any) => item.libelle}
                                            dataIndex="uuid"
                                            name={name}
                                            value={value}
                                            onBlur={onBlur}
                                            fieldRef={ref}
                                            onChange={(e: any) => {
                                                setValue(name, e.target.value, { shouldValidate: true });
                                            }}
                                            defaultValue={{}}
                                            handleResponse={(response: any) => [...response.data.data]}
                                            required={true}
                                        />
                                    )}
                                    control={control}
                                    name="certifications"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    render={({ field: { name, value, onBlur, ref } }) => (
                                        <SelectFieldAsync
                                            label={`Évaluateur sélectionné(e) par défaut dans les parties prenantes des certifications de ce domaine :`}
                                            placeholder="Recherchez un évaluateur en tapant les premières lettres de son nom"
                                            resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
                                            addQueryURL={(resourceURI: any, terms: any) =>
                                                `${resourceURI}?nom=${terms}&roles[]=EVALUATEUR`
                                              }
                                            method="GET"
                                            renderItem={(item: any) => item.nom + " " + item.prenom}
                                            renderValue={(item: any) => item.nom + " " + item.prenom}
                                            dataIndex="uuid"
                                            name={name}
                                            value={value}
                                            onBlur={onBlur}
                                            fieldRef={ref}
                                            onChange={(e: any) => {
                                                setValue(name, e.target.value, { shouldValidate: true });
                                            }}
                                            defaultValue={null}
                                            errorMessage={(errors.evaluateur as any)?.message}
                                            handleResponse={(response: any) => [...response.data.data]}
                                            required={true}
                                        />
                                    )}
                                    control={control}
                                    name="evaluateur"
                                />
                            </Stack.Item>
                        </FormSection>
                        <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
                            <CancelButton />

                            <LoadButton text={!domaine?.uuid ? "Créer le domaine" : "Modifier le domaine"} isLoading={isLoading} icon={<RiAddFill size={38} />} style={{ fontWeight: 'bold' }} />
                        </Stack>
                    </form>

                    {hasError && (
                        <PageMessage
                            message={
                                ((errorPostDomaine as any) || (errorPutDomaine as any)).response.data
                                    .message
                            }
                            type="error"
                        />
                    )}
                </DetailLoader>
            </Page>

        </Stack>
    )
}

export default DomaineDetail