import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";

export const TransmissionDossierContext = React.createContext({});
export const TransmissionDossierProvider = ({ value, children }: any) => {
  <TransmissionDossierContext.Provider value={value}>
    {children}
  </TransmissionDossierContext.Provider>;
};

function useTransmissionDossier() {
  const [isSaving, setIsSaving] = useState(false);
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const {
    candidature,
    candidatureSelectableBlocs,
    actions: candidatureActions,
  } = useCandidature();
  const { isEditMode } = useMiseEnConfirmite();
  const reactHookProps: any = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
    defaultValues: {
      documents_certification: [],
    },
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const actions = {
    onSave: async (data: any) => {
      if (
        window.confirm(
          "Attention : veuillez vérifier que les informations et les documents ajoutés sont bien corrects : aucune modification ne pourra être réalisée"
        )
      ) {
        setIsSaving(true);
        await candidatureActions.putCandidatureFormData(
          data,
          STATUT_PAGE,
          reactHookProps
        );
        setIsSaving(false);
      }
    },
    // onChangeFile: (newFile: any) => {
    //   reactHookProps.setValue("documents_certification",
    //     (reactHookProps.watch("documents_certification") as any).map((doc: any) =>
    //       doc.uuid === newFile.uuid ? newFile : doc
    //     )
    //   );
    // },
    onChangeFile: (docUuid: any, file: any) => {
      const documentsArray: any =
        reactHookProps.watch("certification_documents") || [];

      if (
        documentsArray.find(
          (d: any) => d.certification_document_uuid == docUuid
        )
      ) {
        documentsArray.map((d: any) =>
          d.certification_document_uuid == docUuid
            ? { ...d, file: file[0], file_deleted: false }
            : d
        );
      } else {
        documentsArray.push({
          certification_document_uuid: docUuid,
          collection: docUuid,
          file: file[0],
        });
      }
      reactHookProps.setValue("certification_documents", documentsArray);
    },
    onRemoveFile: (docUuid: any) => {
      const certification_documents = reactHookProps.watch(
        "certification_documents"
      );

      const documentsArray: any = certification_documents.map((d: any) =>
        d.collection == docUuid
          ? { ...d, file_deleted: true, file_url: null }
          : d
      );
      reactHookProps.setValue("certification_documents", documentsArray);
    },
  };

  return {
    candidature,
    isSaving,
    candidatureSelectableBlocs,
    reactHookProps,
    actions,
  };
}

export default useTransmissionDossier;
