import { useQuery } from "react-query";
import {
  getCompaniesRequest,
  getExportCompanies,
} from "../../services/companyServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { useAtom } from "jotai";
import tools from "../../utils/tools";
import useCompany from "../../hooks/company/useCompany";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { useForm } from "react-hook-form";
import { stateList } from "../../fakeData/State.data";
import { atomWithReset } from "jotai/utils";
import { useState } from "react";
import { toast } from "react-toastify";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  nom: "",
  siret: "",
  active: stateList[0],
  interbranche: stateList[0],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useCompanyList() {
  const companyProps = useCompany();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [isLoadingExportCompany, setIsLoadingExportCompany] = useState(false);

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: companies, isLoading: isLoadingCompanies } = useQuery(
    ["companies", optionsFilters, companyProps.userSelectedBranch],
    () => {
      return getCompaniesRequest(filters, optionsFilters);
    },
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteCompanyModal: (company: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={company.nom}
            deleteFunction={() =>
              companyProps.actions.deleteCompany(company.uuid)
            }
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportCompanies: async () => {
      setIsLoadingExportCompany(true);
      try {
        const firstResponse: any = await getExportCompanies(filters).catch(
          (e: any) => {
            console.log("ERR", e);
            toast.error(
              "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
            );
            setIsLoadingExportCompany(false);
          }
        );
        if (!firstResponse) return;
        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });
        setIsLoadingExportCompany(false);
      } catch (e: any) {
        setIsLoadingExportCompany(false);
      }
    },
  };

  return {
    companies,
    companyProps,
    isLoadingCompanies,
    isLoadingExportCompany,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
  };
}

export default useCompanyList;
