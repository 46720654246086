const loginStyles = {
  stackTokens: {
    childrenGap: 25,
  },
  inputStyle: {
    root: {
      width: "300px",
      selectors: {
        ".ms-TextField-fieldGroup": {
          backgroundColor: "#f8f8f8",
          borderRadius: 5,
          border: "1px solid #CED5DA",
          height: "48px",
        },
        ".ms-TextField-field": {
          fontSize: "16px",
        },
        ".ms-Label": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  container: {
    root: {
      background: "#e8e8e8",
      minHeight: "90vh",
    },
  },
  branchesList: {
    root: {
      height: "100%",
      padding: "40px 30px",
      selectors: {
        ":after": {
          content: "oook",
        },
      },
    },
  },
  branchDetail: {
    root: {
      width: "150px",
      maxHeight: "150px",
      border: "1px solid black",
      margin: "10px 10px",

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};

export default loginStyles;
