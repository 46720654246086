import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import MesDemandesHabilitationsListFilters from "./MesDemandesHabilitationsListFilters/MesDemandesHabilitationsListFilters";
import useMesDemandesHabilitationsList from "./useMesDemandesHabilitationsList";
import Page from "../../Common/Page/Page";
import Can from "../../Common/Can/Can";
import { branchAccessEnum } from "../../config/accessEnum";
import CancelButton from "../../Common/CancelButton/CancelButton";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import Pagination from "../../Common/Pagination/Pagination";
import DemandeHabilitationBadge from "../../Common/DemandeHabilitationBadge/DemandeHabilitationBadge";
import tools from "../../utils/tools";
import useConstants from "../../hooks/useConstants";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";

function MesDemandesHabilitationsList() {
  const { constants } = useConstants();
  const { labels } = useLabelSettings();

  const {
    actions: pageActions,
    demandeHabilitationsOf,
    demandeHabilitationsUtilisateur,
    optionsFilters,
  } = useMesDemandesHabilitationsList();
  const { actions: accessActions } = useAccessSettings();

  const demandeHabilitation = accessActions.canI({
    action: branchAccessEnum.HABILITATION_SIMPLE_OF,
  }).granted
    ? demandeHabilitationsOf
    : demandeHabilitationsUtilisateur;

  const columns = [
    {
      key: "1",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <DemandeHabilitationBadge
          statut={
            (
              tools.findIn(
                constants.DEMANDE_HABILITATION?.STATUTS,
                item.statut,
                "id"
              ) as any
            )?.id
          }
        />
      ),
    },
    {
      key: "2",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
      onColumnClick: () => {
        pageActions.sortColumn("certification");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters?.sort, "certification"),
    },
    {
      key: "3",
      name: "Rôle",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const profilObject: any = tools.findIn(
          constants.MATRICES.PROFILS_UTILISATEURS,
          item.profil,
          "id"
        );
        return <Text>{profilObject?.text}</Text>;
      },
    },
    {
      key: "4",
      name: "Date de la demande",
      fieldName: "demande_le",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.demande_le)}</Stack>
      ),
      showSortIconWhenUnsorted: true,
      onColumnClick: () => {
        pageActions.sortColumn("date_creation");
      },
      ...tools.sorted(optionsFilters?.sort, "date_creation"),
    },
    {
      key: "5",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.commentaire}</Text>,
    },
  ].filter((col) =>
    accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted
      ? col.name != "Rôle"
      : col
  );

  return (
    <Stack>
      <Page title="Mes habilitations" explain="Gérer mes habilitations">
        <Can I={branchAccessEnum.ACTIVER_DEMANDE_HABILITATION}>
          <Stack horizontal>
            <Can I={branchAccessEnum.HABILITATION_SIMPLE_OF}>
              <CancelButton
                text="Demander une habilitation"
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("OF");
                }}
              />
            </Can>

            <Can I={branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR}>
              <CancelButton
                text={"Demander une habilitation en tant qu'évaluateur"}
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("EVALUATEUR");
                }}
              />
            </Can>

            <Can I={branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR}>
              <CancelButton
                text={"Demander une habilitation en tant que positionneur"}
                onClick={() => {
                  pageActions.onOpenDemandeHabilitationModal("POSITIONNEUR");
                }}
              />
            </Can>
          </Stack>
        </Can>

        <MesDemandesHabilitationsListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={demandeHabilitation?.data ?? []}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={pageActions.changePage}
          page={demandeHabilitation?.meta?.current_page || 1}
          totalPages={Math.ceil(demandeHabilitation?.meta?.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default MesDemandesHabilitationsList;
