import { Dropdown, Stack } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import { commonInputStyles } from "../../../styles";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { useAtom } from "jotai";
import { getDomainesRequest } from "../../../services/domaineServices";

export const AdminDomaineRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const {
    // handleSubmit,
    formState: { errors },
    // setError,
    control,
    // reset,
    setValue,
    // watch,
    // register,
  } = useFormContext();

  // const domainStateRadioOptions: IChoiceGroupOption[] = [
  //   { key: "domaine_existant", text: "Domaine existant" },
  //   { key: "nouveau_domaine", text: "Nouveau domaine" },
  // ];
  const { data: domaines } = useQuery(
    ["domaines", userSelectedBranch],getDomainesRequest,
    {
      enabled:userSelectedBranch.gestion_par_domaine,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const domainesFromApi = domaines?.data.map((domaine: any) => ({
    id: domaine.uuid,
    uuid: domaine.uuid,
    key: domaine.uuid,
    text: `${domaine.nom}`,
  })) || []

  return (
    <ProfileAddionalInformations
      name="ADMINISTRATEUR_DOMAINE"
      sectionTitle="Informations du profil Administrateur de domaine"
      watchRoles={watchRoles}
    >
      <Stack>
        {/* <Controller
          {...register("adminDomaineProfile.brancheType")}
          control={control}
          defaultValue={null}
          render={({ field: { name, value } }) => (
            <ChoiceGroup
              options={domainStateRadioOptions}
              label="Souhaitez-vous rattacher cet utilisateur à un domaine existant ou à un nouveau domaine ? :"
              required={true}
              name={name}
              checked={value}
              onChange={(
                ev?: FormEvent<HTMLInputElement | HTMLElement>,
                option?: IChoiceGroupOption
              ) => setValue("adminDomaineProfile.brancheType", option?.key)}
            />
          )}
        /> */}

        {/* {watch("adminDomaineProfile.brancheType") == "nouveau_domaine" && (
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Nom du nouveau domaine auquel rattaché l'administrateur de domaine : "
                type="text"
                {...field}
                placeholder="Ex: domaine 1"
                errorMessage={((errors.adminDomaineProfile as any)?.domaine as any)?.message}
              />
            )}
            defaultValue=""
            control={control}
            name="adminDomaineProfile.domaine"
          />
        )} */}


        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <>
              <Dropdown
                placeholder="Choisissez un domaine"
                label="Sélectionnez un domaine existant :"
                options={domainesFromApi}
                styles={commonInputStyles.dropdownStyle}
                required
                onChange={(e: any, options: any) => {
                  setValue('adminDomaineProfile.domaine',options)
                }}
                selectedKey={value ? value.uuid : null}
              />
              <ErrorMessage errorMessage={((errors.adminDomaineProfile as any)?.domaine as any)?.message} />
            </>
          )}
          defaultValue={undefined}
          control={control}
          name="adminDomaineProfile.domaine"
        />

      </Stack>
    </ProfileAddionalInformations>
  );
};
