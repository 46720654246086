import {
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  TextField,
} from "@fluentui/react";
import { FormEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import { commonInputStyles } from "../../../styles";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import CustomImageInput from "../../../Common/CustomImageInput/CustomImageInput";

export const MembreJuryRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();

  const domainStateRadioOptions: IChoiceGroupOption[] = [
    {
      key: "syndicat_existant",
      text: "Syndicat / Organisation patronale existant",
    },
    {
      key: "nouveau_syndicat",
      text: "Nouveau syndicat / organisation patronale",
    },
  ];
  return (
    <ProfileAddionalInformations
      name="MEMBRE_JURY"
      sectionTitle="Informations du profil Membre du jury"
      watchRoles={watchRoles}
    >
      <Stack>
        <Controller
          name="membreJuryProfile.typeSyndicat"
          control={control}
          render={({ field: { name, value } }) => (
            <ChoiceGroup
              options={domainStateRadioOptions}
              label="Souhaitez-vous rattacher cet utilisateur à un syndicat / organisation patronale existant(e) ou en créer un(e) nouveau (nouvelle) ? :"
              required={true}
              selectedKey={value ?? null}
              name={name}
              onChange={(
                ev?: FormEvent<HTMLInputElement | HTMLElement>,
                option?: IChoiceGroupOption
              ) => {
                if (option?.key == "syndicat_existant") {
                  setValue("membreJuryProfile.nouveau_syndicat_nom", "");
                } else {
                  setValue("membreJuryProfile.syndicat", null);
                }
                setValue("membreJuryProfile.typeSyndicat", option?.key);
              }}
            />
          )}
        />
        {watch("membreJuryProfile.typeSyndicat") == "nouveau_syndicat" && (
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Nom du nouveau syndicat ou de la nouvelle organisation patronale auquel rattacher le membre du jury : "
                type="text"
                {...field}
                placeholder="Ex: syndicat 1"
                errorMessage={
                  (
                    (errors.membreJuryProfile as any)
                      ?.nouveau_syndicat_nom as any
                  )?.message
                }
              />
            )}
            defaultValue=""
            control={control}
            name="membreJuryProfile.nouveau_syndicat_nom"
          />
        )}

        {watch("membreJuryProfile.typeSyndicat") == "syndicat_existant" && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Syndicat / Organisation patronale :"
                placeholder="Recherchez un syndicat ou une organisation patronale par son nom (ex : Syndicat 1)"
                resourceURI={`${API_URL}api/references/ref-syndicat/chercher-syndicat`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>{item.syndicat} </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>{item.syndicat} </div>
                    </div>
                  );
                }}
                dataIndex="id"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={{}}
                errorMessage={
                  ((errors.membreJuryProfile as any)?.syndicat as any)?.message
                }
                handleResponse={(response: any) => [...response.data]}
                required
              />
            )}
            control={control}
            name="membreJuryProfile.syndicat"
          />
        )}
        <Controller
          render={({ field: { onChange, name, value } }) => (
            <CustomImageInput
              name={name}
              label="Signature :"
              onChange={(e: any) => {
                onChange((e.target as any).files[0]);
                setValue(`${name}_deleted`, false);
              }}
              //errorMessage={(errors.logo as any)?.message}
              isFile={value ? true : false}
              file={value || watch("membreJuryProfile.file_url")}
              alt="Aperçu de la signature"
              isEditable={true}
              isDeleted={watch(`${name}_deleted`) ? true : false}
              onClick={() => {
                setValue(`${name}_deleted`, true);
                setValue(`${name}`, {});
                (document.getElementById(`${name}`) as any).value = "";
              }}
            />
          )}
          control={control}
          name="membreJuryProfile.file"
        />
      </Stack>
    </ProfileAddionalInformations>
  );
};
