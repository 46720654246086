import { Stack } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";

const ViewMembersDecisions = ({ decisions, allMembres }: any) => {
  const { constants } = useConstants();
  const decisionCounts = {
    favorables: 0,
    defavorables: 0,
    sans_avis: 0,
  };

  decisions.forEach((decision: any) => {
    switch (decision.avis) {
      case "FAVORABLE":
        decisionCounts.favorables = decisionCounts.favorables + 1;
        break;
      case "DEFAVORABLE":
        decisionCounts.defavorables = decisionCounts.defavorables + 1;
        break;
      case "SANS_AVIS":
        decisionCounts.sans_avis = decisionCounts.sans_avis + 1;
        break;
      default:
        break;
    }
  });

  const getDecisionBadge = (decision: any) => {
    let className = "";
    switch (decision.avis) {
      case "FAVORABLE":
        className = "text-green";
        break;
      case "DEFAVORABLE":
        className = "text-orange";
        break;
      case "SANS_AVIS":
        className = "text-info";
        break;
      default:
        break;
    }
    return (
      <span className={className} style={{ fontWeight: "bold" }}>
        {
          (
            tools.findIn(
              constants["CANDIDATURES"]["AVIS_JURY_TYPES"],
              decision.avis,
              "id"
            ) as any
          ).text
        }
      </span>
    );
  };

  return (
    <FormSection sectionTitle="Décisions des membres du jury">
      <Stack.Item style={{ fontWeight: "bold" }}>
        <span className="text-green">
          {decisionCounts.favorables} favorable(s)
        </span>
        ,{" "}
        <span className="text-orange">
          {decisionCounts.defavorables} défavorable(s)
        </span>
        , <span className="">{decisionCounts.sans_avis} sans avis</span>
        {allMembres.length > decisions.length ? (
          <>
            ,{" "}
            <span className="">
              {allMembres.length - decisions.length} non renseigné(s)
            </span>
            .
          </>
        ) : (
          <>.</>
        )}
      </Stack.Item>
      <Stack.Item>
        {decisions.map((decision: any, key: number) => (
          <div className={`decision${key}`}>
            <span style={{ fontWeight: "bold" }}>
              {decision.membre_jury.prenom} {decision.membre_jury.nom}
            </span>{" "}
            - {getDecisionBadge(decision)}
            {decision.commentaire && (
              <>
                {" "}
                - <span className="text-info">{decision.commentaire}</span>
              </>
            )}
          </div>
        ))}
        {allMembres.length > decisions.length &&
          allMembres
            .filter(
              (m: any) =>
                !decisions.find((d: any) => d.membre_jury.uuid == m.uuid)
            )
            .map((m: any, key: number) => (
              <div className={`decision${key}`}>
                <span style={{ fontWeight: "bold" }}>
                  {m.prenom} {m.nom}
                </span>{" "}
                - Non renseigné
              </div>
            ))}
      </Stack.Item>
    </FormSection>
  );
};

export default ViewMembersDecisions;
