import {
  ChoiceGroup,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { API_URL } from "../../config/config";
import { commonButtonStyles, MessageBarStyles } from "../../styles";
import LoadButton from "../LoadButton/LoadButton";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";
import SelectManyFieldAsync from "../SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import useTheme from "../useTheme";
import { useQuery } from "react-query";
import { getJuryAvailableForCandidature } from "../../services/juryServices";
import tools from "../../utils/tools";
import {
  patchModifyCandidatureJuryRequest,
  putModifyManyCandidaturesJuryRequest,
} from "../../services/candidatureService";
import { toast } from "react-toastify";
import useJury from "../../hooks/jury/useJury";
import { AiOutlinePlus } from "react-icons/ai";

function ModificationCandidaturesJuryModal({
  candidatures,
  onSaved,
  isModification = true,
  isModificationEnMasse = false,
}: any) {
  const { primaryColor } = useTheme();
  const { actions } = useCustomModal();
  const [isLoadingInscription, setIsLoadingInscription] = useState(false);
  const [selectedJury, setSelectedJury]: any = useState(null);
  const [selectedCandidatures, setSelectedCandidatures]: any =
    useState(candidatures);
  const { actions: juryActions } = useJury();
  const { data: juryList } = useQuery(
    ["jurys", {}],
    () => getJuryAvailableForCandidature({}),
    { retry: 0 }
  );

  useEffect(() => {
    setSelectedCandidatures(candidatures);
  }, [candidatures]);

  const onSelectJury = (jury: any) => {
    setSelectedJury(jury);
  };

  const onSaveJury = async () => {
    setIsLoadingInscription(true);
    try {
      if (isModification) {
        if (selectedCandidatures.length > 1 || isModificationEnMasse) {
          //modification du jury de plusieurs candidatures
          const res = await putModifyManyCandidaturesJuryRequest({
            candidaturesUuid: selectedCandidatures.map((c: any) => c.uuid),
            juryUuid: selectedJury.uuid,
            isModificationEnMasse: isModificationEnMasse,
          });
        } else {
          ////modification du jury d'une candidature
          const res = await patchModifyCandidatureJuryRequest({
            candidatureUuid: selectedCandidatures[0].uuid,
            jury: { jury: selectedJury.uuid },
          });
        }
      } else {
        const res = await juryActions.putJuryAddCandidats(selectedJury, {
          candidatures: selectedCandidatures.map((c: any) => c.uuid),
        });
      }
      onSaved(selectedJury);
      actions.closeModal();
    } catch (err: any) {
      if (err.response?.data?.message) {
        toast.error(err.response?.data?.message);
      } else {
        toast.error("Une erreur est survenue");
      }
    }
    setIsLoadingInscription(false);
  };

  const items: any = juryList || [];
  const columns = [
    {
      key: "1",
      name: "Date",
      fieldName: "date_jury",
      minWidth: 50,
      maxWidth: 50,
      isResizable: false,
      onRender: (item: any) => (
        <Stack>{tools.formatDate(item.date_jury)}</Stack>
      ),
    },
    {
      key: "2",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Stack>{item.libelle}</Stack>,
    },
    {
      key: "3",
      name: "Date limite d'inscription",
      fieldName: "date_limite_inscription_candidat",
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.formatDate(item.date_limite_inscription_candidat)}</Stack>
      ),
    },
    {
      key: "4",
      name: "Places restantes",
      fieldName: "nombre_de_places_restantes",
      minWidth: 130,
      maxWidth: 130,
      isResizable: true,
      onRender: (item: any) => <Stack>{item.nombre_de_places_restantes}</Stack>,
    },
    {
      key: "5",
      name: "Sélectionner",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <ChoiceGroup
            options={[{ key: `${item.uuid}`, text: "" }]}
            selectedKey={
              selectedJury?.uuid == item.uuid ? selectedJury.uuid : null
            }
            onChange={() => onSelectJury(item)}
          />
        </Stack>
      ),
    },
  ];

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Text style={{ color: "#979797" }}>
        Veuillez choisir un nouveau jury pour la(les) candidature(s)
        sélectionnée(s)
      </Text>

      <SelectManyFieldAsync
        label="Candidatures sélectionnées :"
        placeholder=""
        resourceURI={`${API_URL}api/candidature/candidatures/chercher`}
        addQueryURL={(resourceURI: any, terms: any) =>
          `${resourceURI}?nom=${terms}&statut=inscription_jury`
        }
        method="GET"
        renderItem={(item: any) =>
          `${item.candidat?.nom} ${item.candidat?.prenom} - ${item.certification?.libelle} (${item.certification?.type_libelle})`
        }
        renderValue={(item: any) =>
          `${item.candidat?.nom} ${item.candidat?.prenom} - ${item.certification?.libelle} (${item.certification?.type_libelle})`
        }
        dataIndex="uuid"
        onChange={(e: any) => {
          setSelectedCandidatures(e.target.value);
        }}
        defaultValue={{}}
        value={selectedCandidatures}
        handleResponse={(response: any) => [
          ...response.data.data.filter((c: any) => !c.jury),
        ]}
        //disabled={candidatures?.length > 0}
      />

      <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
        <Stack.Item>
          <LoadButton isLoading={false} text="Rechercher" />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton
            styles={commonButtonStyles.defaultButton}
            text="Réinitialiser ma recherche"
            //onClick={}
          />
        </Stack.Item>
      </Stack>

      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {selectedJury && selectedCandidatures.length > 0 && (
        <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
          <MessageBar styles={MessageBarStyles}>
            Inscription de{" "}
            <span style={{ color: primaryColor }}>
              {selectedCandidatures.length}
            </span>{" "}
            candidature(s) au jury «{" "}
            <span style={{ color: primaryColor }}>{selectedJury.libelle}</span>{" "}
            » du «{" "}
            <span style={{ color: primaryColor }}>
              {tools.formatDate(selectedJury.date_jury)}
            </span>{" "}
            »
          </MessageBar>
        </Stack.Item>
      )}

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>
        {selectedJury && selectedCandidatures.length > 0 && (
          <LoadButton
            isLoading={isLoadingInscription}
            onClick={onSaveJury}
            icon={<AiOutlinePlus style={{ marginRight: 10 }} />}
            text={
              isModificationEnMasse
                ? "Changer le jury dans les demandes sélectionnées"
                : "Valider l’inscription des candidatures au jury sélectionné"
            }
          />
        )}
      </Stack.Item>
    </Stack>
  );
}

export default ModificationCandidaturesJuryModal;
