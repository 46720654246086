import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  postJuryRequest,
  putCloseJury,
  putJuryAddCandidats,
  putJuryRequest,
  postRegenerateDocuments,
  deleteJuryRequest,
} from "../../services/juryServices";

function useJuryMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [juryUuid, setJuryUuid] = useState(null);
  const mutations = {
    PostJury: useMutation(postJuryRequest, {
      onSuccess: (data) => {
        toast.success("Le jury a été créé avec succès.");
        setJuryUuid(data.uuid);
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement du jury : ${error?.message}`
            );
      },
    }),
    PutJury: useMutation(putJuryRequest, {
      onSuccess: () => {
        toast.success("Le jury a été mise à jour avec succès.");
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour du jury : ${error?.response?.data?.message}`
            );
      },
    }),
    PutJuryAddCandidats: useMutation(putJuryAddCandidats, {
      onSuccess: (data) => {
        toast.success("Le(s) candidat(s) a été ajouté avec succès au jury.");
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement du jury : ${error?.response?.data?.message}`
            );
      },
    }),
    PostRegenerateDocuments: useMutation(postRegenerateDocuments, {
      onSuccess: (data) => {
        toast.success(
          "La re-génération des documents est en cours. Vous recevrez un mail une fois l'action terminée."
        );
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(`${error?.response?.data?.message}`);
      },
    }),
    PutCloseJury: useMutation(putCloseJury, {
      onSuccess: (data) => {
        toast.success(
          "La clôture du jury est en cours. Vous recevrez un mail une fois l'action terminée."
        );
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(`${error?.response?.data?.message}`);
      },
    }),
    DeleteJury: useMutation(deleteJuryRequest, {
      onSuccess: () => {
        toast.success("Le jury a été supprimé avec succès.");
        queryClient.invalidateQueries("jurys");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(`${error?.response?.data?.message}`);
      },
    }),
  };

  return {
    juryUuid,
    setJuryUuid,
    mutations,
  };
}

export default useJuryMutations;
