import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";
import queryString from "query-string";

import tools from "../utils/tools";

const eCertifApi = axiosInstance;

const suiviCohorteURI = "candidature/suivi-cohortes";

export const getSuiviCohortesRequest = async (filters: any, options: any) => {
  const filterType = {
    nom_candidat: { type: "string" },
    prenom_candidat: { type: "string" },
    email_candidat: { type: "string" },
    certification: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "certification",
    },
    jury: { type: "string" },
    annee_jury: { type: "string" },
    statut_6_mois: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
    statut_24_mois: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${suiviCohorteURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getSuiviCohorteRequest = async (suiviUuid: any) => {
  const response = await eCertifApi.get(`${suiviCohorteURI}/${suiviUuid}`);
  return response.data;
};

export const putPublicSuiviCohorteRequest = async (suivi: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `public/suivi-cohortes`,
    suivi,
    xsrfHeader
  );

  return response;
};

export const putBoSuiviCohortesRequest = async (suivi: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.put(
    `${suiviCohorteURI}/${suivi.uuid}`,
    suivi,
    xsrfHeader
  );
  return response;
};

export const getExportSuiviCohorte = async (filters: any) => {
  const queryFilters = queryString.stringify(filters);

  const response = await eCertifApi.get(
    `${suiviCohorteURI}/exporter?${queryFilters}`,
    //NECESSAIRE OU ERREUR LORS DE LA DECOMPRESSION DU ZIP
    {
      responseType: "blob",
    }
  );
  return response;
};
