import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  patchGiveUpCandidatureRequest,
  patchAcceptCandidatureRequest,
  patchRejectCandidatureRequest,
  postCandidatureRequest,
  patchModifyCandidatureJuryRequest,
  patchDesinscrireCandidatureRequest,
} from "../../services/candidatureService";
import {
  candidaturesListAtom,
  isConsultCandidaturesListAtom,
} from "../../Pages/CandidatJuryList/useCandidatureJuryList";
import { useAtom } from "jotai";

function useCandidatureMutations() {
  const [isConsultCandidaturesList] = useAtom(isConsultCandidaturesListAtom);
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [candidatureUuid, setCandidatureUuid] = useState(null);
  const [candidaturesList, setCandidaturesList]: any =
    useAtom(candidaturesListAtom);

  const mutations = {
    PostCandidature: useMutation(postCandidatureRequest, {
      onSuccess: (data) => {
        toast.success("La candidature a été créé avec succès.");
        //a modifier -> page profil du candidat
        //navigate("/admin/accueil");
        queryClient.invalidateQueries("candidatures");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la création de la candidature : ${error?.response?.data?.message}`
            );
      },
    }),
    PatchGiveUpCandidature: useMutation(patchGiveUpCandidatureRequest, {
      onSuccess: (resp: any) => {
        if (candidaturesList.length > 0) {
          const uuid_candidature_giveup =
            resp.request.responseURL.split("/")[6]; //[update] return from API in data ?
          const candidatureInList = candidaturesList.find(
            (c: any) => c.uuid == uuid_candidature_giveup
          );
          if (candidatureInList) {
            setCandidaturesList(
              candidaturesList.filter(
                (c: any) => c.uuid != uuid_candidature_giveup
              )
            );
          }
        }
        toast.success("La candidature a été abandonée avec succès.");
        queryClient.invalidateQueries("candidatures");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'abandon de la candidature : ${error?.response?.data?.message}`
            );
      },
    }),

    PatchAcceptCandidatureRequest: useMutation(patchAcceptCandidatureRequest, {
      onSuccess: (data) => {
        if (isConsultCandidaturesList) {
          const candidatureUpdated = candidaturesList.find(
            (c: any) => c.uuid == data.uuid
          );
          setCandidaturesList(
            candidaturesList.map((c: any) =>
              c.uuid == candidatureUpdated.uuid
                ? {
                    ...data,
                    isTreated: true,
                  }
                : c
            )
          );
        }
        toast.success("La candidature a été validée avec succès.");
        queryClient.invalidateQueries("candidatures");
        queryClient.invalidateQueries("validateCandidature");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'acceptation de la candidature : ${error?.response?.data?.message}`
            );
      },
    }),
    PatchRejectCandidatureRequest: useMutation(patchRejectCandidatureRequest, {
      onSuccess: (data, variables) => {
        if (isConsultCandidaturesList) {
          const candidatureUpdated = candidaturesList.find(
            (c: any) => c.uuid == variables
          );
          setCandidaturesList(
            candidaturesList.map((c: any) =>
              c.uuid == candidatureUpdated.uuid
                ? {
                    ...c,
                    isTreated: true,
                  }
                : c
            )
          );
        }
        toast.success("La candidature a été rejetée avec succès.");
        queryClient.invalidateQueries("candidatures");
        queryClient.invalidateQueries("validateCandidature");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors du refus de la candidature : ${error?.response?.data?.message}`
            );
      },
    }),
    PatchDesinscrireCandidatureRequest: useMutation(
      patchDesinscrireCandidatureRequest,
      {
        onSuccess: (data, variables) => {
          toast.success("La candidature a été rejetée avec succès.");
          queryClient.invalidateQueries("candidatures");
          queryClient.invalidateQueries("validateCandidature");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la désinscription de la candidature : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PatchModifyCandidatureJuryRequest: useMutation(
      patchModifyCandidatureJuryRequest,
      {
        onSuccess: (data) => {
          toast.success("Le jury de la candidature a été modifié avec succès.");
          queryClient.invalidateQueries("candidatures");
          queryClient.invalidateQueries("validateCandidature");
          queryClient.invalidateQueries("jurys");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la création de la candidature : ${error?.response?.data?.message}`
              );
        },
      }
    ),
  };

  return {
    candidatureUuid,
    setCandidatureUuid,
    mutations,
  };
}

export default useCandidatureMutations;
