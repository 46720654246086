import { PrimaryButton, Text } from "@fluentui/react";
import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import userAdapters from "../../adapters/userAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import useConstants from "../useConstants";
import useUserMutations from "./useUserMutations";

function useUser() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const {
    userUuid,
    setUserUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    isSendingPasswordUuid,
    setIsSendingPasswordUuid,
  } = useUserMutations();
  const { constants } = useConstants();
  const { actions: modalActions } = useCustomModal();

  const actions = {
    setUpdateUserUuid: (data: any) => {
      setUserUuid(data);
    },
    postUser: async (user: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        userAdapters.transformForAPI(user, constants),
        null
      );
      mutations.PostUser.mutate(formData);
    },
    putUser: async (user: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        userAdapters.transformForAPI(user, constants),
        null
      );
      mutations.PutUser.mutate(formData);
    },
    toggleUserActivation: (user: any) => {
      setIsToggleActivationUuid(user.uuid);
      if (user.actif) {
        mutations.ToggleUserDesactivate.mutate(user.uuid);
      } else {
        mutations.ToggleUserActivate.mutate(user.uuid);
      }
    },
    sendPassword: (data: any) => {
      modalActions.openModal({
        body: (
          <>
            <Text
              variant="medium"
              style={{ marginBottom: 15, textAlign: "center" }}
            >
              L’utilisateur recevra un lien de création de mot de passe ou de
              réinitialisation de mot de passe s’il en possédait déjà un.
            </Text>
            <PrimaryButton
              text="Envoyer l'invitation à créer un mot de passe"
              onClick={() => {
                setIsSendingPasswordUuid(data.uuid);
                mutations.SendPassword.mutate(data);
                modalActions.closeModal();
              }}
              styles={commonButtonStyles.buttonSubmit}
            />
          </>
        ),
      });
    },
    sendPasswordConfirm: () => {},
    showlog: (data: any) => {
      toast.info(`En cours de développement`);
    },
  };

  const {
    isLoading: isLoadingPostUser,
    isError: isErrorPostUser,
    error: errorPostUser,
    isSuccess: isSuccessPostUser,
  } = mutations.PostUser;

  const {
    isLoading: isLoadingPutUser,
    isError: isErrorPutUser,
    error: errorPutUser,
    isSuccess: isSuccessPutUser,
  } = mutations.PutUser;

  return {
    userSelectedBranch,
    userUuid,
    isLoading: isLoadingPostUser || isLoadingPutUser,
    hasError: isErrorPostUser || isErrorPutUser,
    errorPostUser,
    isSuccessPostUser,
    errorPutUser,
    isSuccessPutUser,
    actions,
    isSendingPasswordUuid,
    isToggleActivationUuid,
  };
}

export default useUser;
