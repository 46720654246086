import { Stack, Text } from "@fluentui/react";
import useConstants from "../../hooks/useConstants";
import tools from "../../utils/tools";
import Badge from "../Badge/Badge";

function WaitingMECBadge({ candidature }: any) {
  const { constants } = useConstants();

  const waitingMEC = candidature?.mise_en_conformites?.filter(
    (f: any) => f.statut === "EN_ATTENTE_DE_MODIFICATION"
  );

  if (waitingMEC && waitingMEC.length === 0) {
    return null;
  }

  return (
    <Stack horizontal wrap>
      <Text
        style={{
          color: "grey",
          fontSize: 12,
        }}
      >
        Étapes à corriger :
      </Text>
      {waitingMEC.map((mer: any, i: any) => {
        return (
          <div key={i} style={{ marginLeft: 5 }}>
            <Badge
              text={`${
                (
                  tools.findIn(
                    constants.CANDIDATURES.STATUTS,
                    mer.candidature_statut,
                    "id"
                  ) as any
                )?.text
              }`}
              style={{ padding: "0px 5px", marginBottom: 3 }}
              textStyle={{ fontSize: 12 }}
              color="red"
            />
          </div>
        );
      })}
    </Stack>
  );
}

export default WaitingMECBadge;
