import { PrimaryButton, Stack, Text } from "@fluentui/react";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles } from "../../../styles";

function ValiderDemandeIncriptionStructureMasseModal({
  structures,
  onValiderInscriptions = () => {},
}: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onSubmit = () => {
    onValiderInscriptions();
    closeModal();
  };

  return (
    <Stack horizontalAlign="center">
      <Stack.Item>
        <Text
          variant="medium"
          style={{ margin: "10px 0px", textAlign: "center" }}
        >
          Attention, vous allez valider les demandes d’inscription des
          structures suivantes :
        </Text>
        <ul>
          {structures.map((structure: any) => (
            <li>{structure.raison_sociale}</li>
          ))}
        </ul>
      </Stack.Item>
      <PrimaryButton
        text="Valider"
        onClick={onSubmit}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.validateButton}
      />
    </Stack>
  );
}

export default ValiderDemandeIncriptionStructureMasseModal;
