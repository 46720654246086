import useAuth from "../useAuth";

const useCreateurCandidature = () => {
  const { auth }: any = useAuth();

  const isMyCompany = (companyUuid: string) => {
    return auth.user.profils
      .filter((p: any) => p.profil == "ENTREPRISE")
      .some((p: any) => p.entites.find((e: any) => e.id == companyUuid));
  };
  const isMyOF = (OFUuid: string) => {
    return auth.user.profils
      .filter((p: any) => p.profil == "OF")
      .some((p: any) => p.entites.find((e: any) => e.id == OFUuid));
  };

  const isCreateurCandidature = ({ ofUuid, companyUuid }: any) => {
    return isMyOF(ofUuid) || isMyCompany(companyUuid);
  };

  return {
    isMyOF,
    isCreateurCandidature,
  };
};

export default useCreateurCandidature;
