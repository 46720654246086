import { useState } from "react";
import { axiosInstance } from "../../config/axiosConfig";
import { toast } from "react-toastify";

function usePublicSearchExistingStructure({
  reactHookProps,
  keyToCheck,
  structureType,
  contextActions,
}: any) {
  const [isLoading, setIsLoading] = useState(false);

  const keyToCheckToString = keyToCheck.toUpperCase();

  const actions = {
    onKeyDown: (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        reactHookProps.handleSubmit(actions.onVerify)(e);
      }
    },
    onVerify: async () => {
      setIsLoading(true);
      try {
        const structureExist = await axiosInstance.post(
          `public/structure/existe`,
          {
            structure_type: structureType,
            cle: reactHookProps.watch(keyToCheck),
          }
        );
        contextActions.setUpdateIsCheck(true, structureExist.data);
      } catch (e: any) {
        toast.error(
          e.response?.data?.message ||
            `Une erreur est survenue lors de la vérification du ${keyToCheckToString}`
        );
      }
      setIsLoading(false);
    },
  };

  return {
    actions,
    keyToCheckToString,
    isLoading,
  };
}

export default usePublicSearchExistingStructure;
