import { atom, useAtom } from "jotai";
import useQuery from "../../../hooks/useQuery";

const formStateAtom = atom({
  situation_apres_certification: null,
  intitule_poste_occupe: "",
  type_contrat: null,
  nom_entreprise: "",
  statut_cadre: false,
  remuneration_brute_annuelle: "",
});

const useFormulaireSuiviCohortes = ({ onSave }: any) => {
  const [formState, setFormState] = useAtom(formStateAtom);
  let query = useQuery()

  const actions = {
    handleChangeFormState: (e: any) => {
      const { name, value, type } = e.target;
      let realValue = type == "checkbox" ? e.target.checked : value;
      setFormState((prev) => ({ ...prev, [name]: realValue }));
    },
    initializeFormState: (initialFormState: any) => {
      setFormState(initialFormState);
    },
    handleSave: () => {
      onSave(formState,query.get('token'))
    },
  };

  return {
    formState,
    actions,
  };
};

export default useFormulaireSuiviCohortes;
