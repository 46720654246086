import { useQuery } from "react-query";
import {
  getMyStructureRequest,
  TStructureType,
} from "../../services/myStructureService";
import { QueryClientStructure } from "./useMyStructureMutations";

function useMyStructureQuery({
  structureUuid,
  structureType,
}: {
  structureType: TStructureType;
  structureUuid: string | null;
}) {
  const {
    data: structure,
    isLoading: isLoadingStructure,
    isRefetching: isRefetchingStructure,
    refetch: refetchStructure,
  } = useQuery(
    [QueryClientStructure[structureType], structureUuid],
    () =>
      getMyStructureRequest({
        structureType,
        structureUuid,
      }),
    {
      enabled: !!structureUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );
  return {
    structure,
    isLoadingStructure,
    isRefetchingStructure,
    refetchStructure,
  };
}

export default useMyStructureQuery;
