import { useQuery } from "react-query";
import { getPorteursRequest } from "../services/certificationServices";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../atoms/branchAtom";

function usePorteurQuery() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const {
    data: porteurs,
    isLoading: isLoadingPorteurs,
    isRefetching: isRefetchingPorteurs,
  } = useQuery(
    ["porteurs", userSelectedBranch],
    () => {
      return getPorteursRequest(
        {},
        { sort: "", page: 1, paginate: false, searchAt: "" }
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const porteursList =
    porteurs?.data.map((porteur: any) => ({
      id: porteur.uuid,
      key: porteur.uuid,
      text: `${porteur.nom} | ${porteur.numero_fiche_client} | ${porteur.numero_contrat}`,
    })) || [];

  return { isLoadingPorteurs, isRefetchingPorteurs, porteursList };
}

export default usePorteurQuery;
