import { useQuery } from "react-query"
import { getSubGroupCompanyRequest } from "../../services/subGroupCompanyServices"

function useSubGroupCompanyQuery(subGroupCompanyProps: any) {

    const { data: subGroupCompany, isLoading, isRefetching } = useQuery(
        ['subGroupCompanies', subGroupCompanyProps.subGroupCompanyUuid],
        () => getSubGroupCompanyRequest(subGroupCompanyProps.subGroupCompanyUuid),
        {
            enabled: !!subGroupCompanyProps.subGroupCompanyUuid,
            retry: 0,
            refetchOnWindowFocus:false,
        }
    )

    return {
        subGroupCompany,
        isLoading, 
        isRefetching
    }
}

export default useSubGroupCompanyQuery