import { Stack } from "@fluentui/react";
import { useAtom } from "jotai";
import { Controller, useFormContext } from "react-hook-form";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { branchAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import Can from "../../../Common/Can/Can";
import { useParams } from "react-router-dom";
import CreateLink from "../../../Common/CreateLink/CreateLink";
import HabilitationUtilisateurDetailList from "../../../Common/HabilitationUtilisateurDetailList/HabilitationUtilisateurDetailList";
import useEvaluateurRoleForm from "../hooks/useEvaluateurRoleForm";
import Loader from "../../../Common/Loader/Loader";

export const EvaluateurRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const { uuid } = useParams();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: accesActions } = useAccessSettings();
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  const {
    habilitations,
    actions,
    optionsFilters,
    isLoadingEvaluateurHabilitation,
    isRefetchingEvaluateurHabilitation,
  } = useEvaluateurRoleForm(uuid);
  return (
    <ProfileAddionalInformations
      name="EVALUATEUR"
      sectionTitle="Informations du profil Evaluateur"
      watchRoles={watchRoles}
    >
      <Stack>
        {!accesActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
        }).granted && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectManyFieldAsync
                label="Certifications liées à cet utilisateur :"
                placeholder="Recherchez des certifications par nom (ex: Certification 1)"
                resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?libelle=${terms}&branche_creatrice_id=${userSelectedBranch.id}`
                }
                method="GET"
                renderItem={(item: any) => item.libelle}
                renderValue={(item: any) => item.libele}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={[]}
                errorMessage={
                  ((errors.evaluateurProfile as any)?.certifications as any)
                    ?.message
                }
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="evaluateurProfile.certifications"
          />
        )}
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Entreprises liées à cet utilisateur :"
              placeholder="Recherchez des entreprises par SIRET ou par nom (ex: 12312312311111 ou Entreprise 1)"
              resourceURI={`${API_URL}api/administration/entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={[]}
              errorMessage={
                ((errors.evaluateurProfile as any)?.entreprise as any)?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              required={
                accesActions.canI({
                  action:
                    branchAccessEnum.OBLIGER_LIAISON_EVALUATEUR_ET_ENTREPRISE,
                }).granted
              }
            />
          )}
          control={control}
          name="evaluateurProfile.entreprise"
        />
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="OF liés à cet utilisateur :"
              placeholder="Recherchez des OF par SIRET ou Raison sociale (ex : 123123123123123 ou OF1)"
              resourceURI={`${API_URL}api/administration/ofs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={[]}
              errorMessage={
                ((errors.evaluateurProfile as any)?.ofs as any)?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              required={
                accesActions.canI({
                  action: branchAccessEnum.OBLIGER_LIAISON_EVALUATEUR_ET_OF,
                }).granted
              }
            />
          )}
          control={control}
          name="evaluateurProfile.ofs"
        />
        {uuid !== "creer" && (
          <Can I={branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR}>
            <Stack tokens={{ childrenGap: 10 }} style={{ marginTop: 10 }}>
              {isLoadingEvaluateurHabilitation ||
              isRefetchingEvaluateurHabilitation ? (
                <Loader />
              ) : (
                <>
                  <CreateLink
                    text="Créer une habilitation"
                    to={`/admin/habilitations-utilisateur/creer?nom=${watch(
                      "nom"
                    )}&prenom=${watch(
                      "prenom"
                    )}&userUuid=${uuid}&userRole=EVALUATEUR`}
                  />

                  <HabilitationUtilisateurDetailList
                    habilitationsData={habilitations}
                    optionsFilters={optionsFilters}
                    actions={actions}
                    isUserDetail={true}
                  />
                </>
              )}
            </Stack>
          </Can>
        )}
      </Stack>
    </ProfileAddionalInformations>
  );
};
