import {
  DefaultButton,
  Dropdown,
  Label,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import FileInput from "../../../Common/FileInput/FileInput";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { useState } from "react";
import Loader from "../../../Common/Loader/Loader";
import useDemandeHabilitationModal from "./useDemandeHabilitationModal";

interface DemandeHabilitationProps {
  entityOrProfil: "OF" | "EVALUATEUR" | "POSITIONNEUR";
  onAfterSave?: any;
}

function DemandeHabilitationModal({
  entityOrProfil,
  onAfterSave = () => {},
}: DemandeHabilitationProps) {
  const { actions } = useCustomModal();
  const {
    actions: actionsPage,
    entiteOfLieProfilOf,
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  } = useDemandeHabilitationModal();
  const [demandeHabilitation, setDemandeHabilitation] = useState({
    certification: null,
    documents: null,
    organisme_de_formation: null,
  });

  const handleChangeDemandeHabilitation = (e: any) => {
    const { name, value } = e.target;
    if (name == "documents") {
      const newDemandeHabilitation = {
        ...demandeHabilitation,
        [name]: [{ file: value, type: "convention" }],
      };
      setDemandeHabilitation((f: any) => newDemandeHabilitation);
    } else {
      setDemandeHabilitation({ ...demandeHabilitation, [name]: value });
    }
  };

  const onSaveDemandeHabilitation = () => {
    actionsPage.onDemandeHabilitation({
      entityOrProfil,
      data: demandeHabilitation,
    });
    actions.closeModal();
    onAfterSave();
  };

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 800,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      {entityOrProfil == "OF" && entiteOfLieProfilOf.length > 1 && (
        <Stack.Item>
          <Dropdown
            required
            selectedKey={
              demandeHabilitation.organisme_de_formation
                ? (demandeHabilitation.organisme_de_formation as any).key
                : undefined
            }
            label="Organisme de formation :"
            placeholder="Sélectionnez un organisme de formation"
            options={entiteOfLieProfilOf.map((entite: any) => ({
              id: entite.attributes.uuid,
              key: entite.attributes.uuid,
              text: `${entite.attributes.raison_sociale} - ${entite.attributes.siret}`,
            }))}
            onChange={(e: any, options: any) => {
              handleChangeDemandeHabilitation({
                target: { name: "organisme_de_formation", value: options },
              });
            }}
            styles={commonInputStyles.dropdownStyle}
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <Dropdown
          required
          selectedKey={
            demandeHabilitation.certification
              ? (demandeHabilitation.certification as any).key
              : undefined
          }
          label="Certification :"
          placeholder="Sélectionnez une certification"
          options={certificationsFromApi}
          onChange={(e: any, options: any) => {
            handleChangeDemandeHabilitation({
              target: { name: "certification", value: options },
            });
          }}
          styles={commonInputStyles.dropdownStyle}
        />
      </Stack.Item>

      <Stack.Item>
        <Label styles={commonInputStyles.label}>
          Convention d'habilitation :
        </Label>
        <FileInput
          name="documents"
          nameDisplay="convention_habilitation.pdf"
          file={
            demandeHabilitation.documents
              ? (demandeHabilitation.documents[0] as any)?.file
              : []
          }
          existingFileURL={
            demandeHabilitation.documents
              ? (demandeHabilitation.documents[0] as any)?.file_url
              : null
          }
          onUpdateFile={handleChangeDemandeHabilitation}
          acceptedFileTypes={["PDF"]}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>

        <PrimaryButton
          onClick={onSaveDemandeHabilitation}
          styles={commonButtonStyles.buttonSubmit}
          iconProps={{ iconName: "Add" }}
        >
          Valider
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
}

export default DemandeHabilitationModal;
