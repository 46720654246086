import { Breadcrumb as BreadcrumbFluent, IBreadcrumbItem } from "@fluentui/react";
import { Link as RouterLink, useMatch } from "react-router-dom";
import { routesConfig } from "../../config/routesConfig";
import useTheme from "../useTheme";
import breadCrumbStyle from "./Bradcrumb.styles";

const matchRouteDefinition = (routesDefinition: any) => {
  const routeInfoArray: any = [];
  routesDefinition.forEach((routeDefinition: any) => {
    const match = useMatch(routeDefinition.path);
    if (match) {
      routeInfoArray.push(match);
    }
  });

  const routeInfo = routeInfoArray.find((route: any) => route.pattern.path !== "*");
  return routeInfo?.pattern?.path || "/";
};

function Breadcrumb() {
  const { primaryColor } = useTheme();
  const pathname = matchRouteDefinition(routesConfig);

  const pathnames = pathname.split("/").filter((path: any) => path);
  var statusConnect = pathnames.shift();

  const items: IBreadcrumbItem[] = pathnames.map((item: any, i: number) => {
    const pathTo = `${statusConnect}/${pathnames.slice(0, i + 1).join("/")}`;
    let name = routesConfig.filter((route) => route.path === pathTo)[0]?.title;
    if (pathnames.length === i + 1) {
      return { text: name, key: `${i}`, isCurrentItem: true, style: { color: primaryColor } };
    }
    return {
      text: name,
      key: `${i}`,
      onRender: () => (
        <RouterLink to={pathTo} style={{ color: primaryColor }}>
          {name}
        </RouterLink>
      ),
    };
  });

  return (
    <BreadcrumbFluent
      className="breadcrumbResponsive"
      styles={breadCrumbStyle}
      items={items.filter((item) => item && item.text)}
      maxDisplayedItems={5}
      ariaLabel="Breadcrumb with items"
      overflowAriaLabel="More links"
    />
  );
}

export default Breadcrumb;
