import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import { getUserProfilesRequest } from "../services/authServices";
import useDomaine from "./domaine/useDomaine";

export const branchEntitiesAtom: any = atom(null);
export const selectedBranchIdAtom: any = atom(null);

const useBranchEntities = () => {
  const [isLoading, setIsLoading]: any = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useAtom(selectedBranchIdAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: domainesActions } = useDomaine();

  const [branchEntities, setBranchEntities] = useAtom(branchEntitiesAtom);

  useEffect(() => {
    if (userSelectedBranch?.id != selectedBranchId) {
      setSelectedBranchId(userSelectedBranch?.id);
      getBranchEntities();
    }
  }, [userSelectedBranch?.id]);

  const getBranchEntities = async () => {
    //récupére les entités liées à l'utilisateur sur la branche
    setIsLoading(true);
    const data = await getUserProfilesRequest();
    // const transformedData = {
    //   entreprise: data.entreprises,
    //   organisme_de_formation: data.ofs,
    // };
    if (userSelectedBranch.gestion_par_domaine) {
      domainesActions.getDomainesForUser(userSelectedBranch, data);
    }
    setBranchEntities(data);

    setIsLoading(false);
  };

  return {
    branchEntities,
    isLoading: !branchEntities || isLoading,
    getBranchEntities,
  };
};

export default useBranchEntities;
