import { useAtom } from "jotai";
import { accountInformationsAtom } from "../useSignUp";

const SelectedAccount = ({ children }: any) => {
  const [accountInformations]: any = useAtom(accountInformationsAtom);

  return (
    <div>
      <p>
        Informations du compte :{" "}
        <strong>
          {accountInformations?.prenom} {accountInformations?.nom}{" "}
          {accountInformations?.email} {accountInformations?.telephone}
        </strong>
      </p>
      {children}
    </div>
  );
};

export default SelectedAccount;
