import Joi from "joi";
import validations from "../utils/validations";

export const branchSchema = Joi.object({
  _method: Joi.string().required(),
  nom: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un nom de branche" }),
  logo: Joi.object(),
  couleur_primaire: Joi.object({
    color: Joi.string()
      .required()
      .regex(/#{1}[a-fA-F0-9]{6}$/)
      .messages({
        "any.required": "Veuillez sélectionner une couleur primaire",
        "string.base": "Veuillez sélectionner une couleur primaire",
        "string.empty": "Veuillez sélectionner une couleur primaire",
        "string.pattern.base": "Veuillez entrer une couleur en hexadecimal",
      }),
  })
    .required()
    .messages({ "any.required": "Veuillez sélectionner une couleur primaire" })
    .unknown(true),
  active: Joi.boolean().required().default(false),
  gestion_par_domaine: Joi.boolean().required().default(false),
  afficher_logo_sur_documents: Joi.boolean().required().default(false),
  admin: Joi.object({
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        "string.empty": "Veuillez renseigner un email",
        "string.email":
          "Veuillez respecter le format de l'email (exemple@domaine.fr)",
      }),
    prenom: Joi.string()
      .required()
      .messages({ "string.empty": "Veuillez renseigner un prénom" }),
    nom: Joi.string()
      .required()
      .messages({ "string.empty": "Veuillez renseigner un nom" }),
  }).unknown(true),
  headband: Joi.object(),
  defaut_email_expediteur: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner un email",
      "string.email":
        "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
  email_reponse: Joi.string()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner un email",
      "string.email":
        "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
  adresse: Joi.string().allow("", null),
  complement_adresse: Joi.string().allow("", null),
  code_postal: Joi.string().allow("", null),
  region: Joi.string().allow("", null),
  ville: Joi.string().allow("", null),
  emails_contact: Joi.array()
    .items(
      Joi.object().keys({
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required(),
      })
    )
    .min(1)
    .messages({
      "any.required": "Veuillez ajouter au moins un email de contact",
      "array.min": "Veuillez ajouter au moins un email de contact",
    }),
  ref_idccs: Joi.array()
    .required()
    .items(
      Joi.object()
        .keys({
          id: Joi.number().required(),
        })
        .unknown(true)
    )
    .min(1)
    .messages({
      "any.required": "Veuillez ajouter au moins un IDCC",
      "array.min": "Veuillez ajouter au moins un IDCC",
    }),
}).unknown(true);

export const validateContactEmailList = (item: any, value: any) => {
  let validationErrors = {};
  const val1 = validations.validateEmail(item.email);
  if (val1.error) {
    const newErrors = {} as any;
    val1.error.details.forEach((error: any) => {
      newErrors[error.context.key] = error.message;
    });
    validationErrors = { error: newErrors };
  }
  const val2 = validations.checkForDoublons(value, item.email, "email");
  if (val2) {
    validationErrors = {
      error: { email: "Cet email existe déjà dans la liste" },
    };
  }
  return validationErrors;
};
