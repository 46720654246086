import { DefaultButton, Stack } from "@fluentui/react";
import Can from "../../Common/Can/Can";
import CreateLink from "../../Common/CreateLink/CreateLink";
import HabilitationOfDetailList from "../../Common/HabilitationOfDetailList/HabilitationOfDetailList";
import Page from "../../Common/Page/Page";
import useTheme from "../../Common/useTheme";
import { userAccessEnum } from "../../config/accessEnum";
import { commonButtonStyles } from "../../styles";
import HabilitationOfListFilters from "./HabilitationOfListFilters/HabilitationOfListFilters";
import useHabilitationOfList from "./useHabilitationOfList";

function HabilitationOfList() {
  const { primaryColor } = useTheme();
  const {
    habilitations,
    actions: actionsPage,
    optionsFilters,
  } = useHabilitationOfList();

  const items = habilitations?.data ?? [];

  return (
    <Stack>
      <Page
        title="Gestion des habilitations des OF"
        explain="Veuillez gérer les habilitations"
      >
        <Stack
          horizontal
          wrap
          verticalAlign="center"
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          <CreateLink
            text="Créer une habilitation"
            to="/admin/habilitations-of/creer"
          />

          <Can I={userAccessEnum.PARAMETRER_HABILITATIONS}>
            <div style={{ height: 46 }}>
              <DefaultButton
                text="Créer des habilitations en masse"
                styles={commonButtonStyles.defaultButton}
                style={{ color: primaryColor, borderColor: primaryColor }}
                onClick={actionsPage.onImportHabilitations}
              />
            </div>
          </Can>
        </Stack>

        <HabilitationOfListFilters />

        {[...items]?.filter(
          (habilitation: any) => habilitation.statut == "ACTIVE"
        ).length > 1 && (
          <Stack>
            <Stack.Item>
              <DefaultButton
                onClick={() => actionsPage.onRemoveHabilitation(items, true)}
                styles={commonButtonStyles.defaultButton}
              >
                Retirer des habilitations
              </DefaultButton>
            </Stack.Item>
          </Stack>
        )}

        <HabilitationOfDetailList
          habilitationsData={habilitations}
          actions={actionsPage}
          optionsFilters={optionsFilters}
        />
      </Page>
    </Stack>
  );
}

export default HabilitationOfList;
