import { DefaultButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { commonButtonStyles } from "../../styles";

type LoadButtonProps = {
  text: string,
  isLoading?: boolean,
  isDisabled?:boolean,
  onClick?: any,//should be MouseEventHandler by I don't know how the code will behave
  style?: Object,
  type?: string,
  icon?: any,
  primary?:boolean
  styles?: null|Object,
}

function LoadButton({ text, isLoading, onClick = () => { }, type = "submit", style, icon="", isDisabled = false, primary=true,styles=null }: LoadButtonProps) {
  const getRender = () => {
    if (isLoading) {
      return <Spinner size={SpinnerSize.small} />;
    }
    return (<>
      {icon}
      {text}
    </>

    );
  };

  if(primary){
    return (
      <PrimaryButton
        type={type}
        onClick={onClick}
        disabled={isLoading || isDisabled}
        styles={styles ?? commonButtonStyles.buttonSubmit}
        style={style}
        className="buttonHeight"
      >
        {getRender()}
      </PrimaryButton>
    );
  }
  return (
    <DefaultButton
      type={type}
      onClick={onClick}
      disabled={isLoading || isDisabled}
      styles={commonButtonStyles.defaultButton}
      style={style}
      className="buttonHeight"
    >
      {getRender()}
    </DefaultButton>
  );

}

export default LoadButton;
