import { Label, TextField } from "@fluentui/react";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { commonInputStyles } from "../../../styles";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";

const ABCNotationForm = ({ label, name, initialValue = {}, value = {} }: any) => {
  const { reactHookProps }: any = useContext(CertificationContext);
  const { setValue } = reactHookProps;
  const [currentValue, setCurrentValue] = useState(initialValue);
  useEffect(() => {
    setCurrentValue(initialValue);
  }, [initialValue]);

  const handleChangeValue = (e: any) => {
    const { name: targetName, value: targetValue } = e.target;
    const newValue = { ...currentValue, [targetName]: targetValue };
    setCurrentValue(newValue);
    setValue(name, newValue);
  };

  return (
    <div>
      <Label>{label}</Label>
      {Object.keys(initialValue).map((letter: string, i: number) => {
        return currentValue.hasOwnProperty(letter) ? (
          <TextField
            key={i}
            styles={commonInputStyles.textField}
            label={`${letter} :`}
            type="text"
            name={letter}
            placeholder="Ex : appréciation"
            onChange={handleChangeValue}
            required
            value={initialValue[letter]}
          />
        ) : null;
      })}
    </div>
  );
};

export default ABCNotationForm;
