import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  patchActivateModeleLettreRequest,
  patchDesactivateModeleLettreRequest,
  putModeleLettreRequest,
  putResetModeleLettreRequest,
} from "../../services/modelesLettreServices";
import { useNavigate } from "react-router-dom";

function useModeleLettreMutations() {
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);

  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutations = {
    PutModeleLettre: useMutation(putModeleLettreRequest, {
      onSuccess: () => {
        toast.success("Le modèle de lettre a été mis à jour avec succès.");
        queryClient.invalidateQueries("modelesLettre");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour du modèle de lettre : ${error?.message}`
            );
      },
    }),
    PutResetModeleLettre: useMutation(putResetModeleLettreRequest, {
      onSuccess: () => {
        toast.success(
          "Le modèle de lettre a été remis par défaut avec succès."
        );
        queryClient.invalidateQueries("modelesLettre");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour du modèle de lettre : ${error?.message}`
            );
      },
    }),
    ToggleModeleLettreActivate: useMutation(patchActivateModeleLettreRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("Le modèle de lettre a été activé avec succès.");
        queryClient.invalidateQueries("modelesLettre");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
    ToggleModeleLettreDesactivate: useMutation(
      patchDesactivateModeleLettreRequest,
      {
        onSuccess: () => {
          setIsToggleActivationUuid(null);
          toast.success("Le modèle de lettre a été désactivé avec succès.");
          queryClient.invalidateQueries("modelesLettre");
        },
        onError: (error: any) => {
          toast.error(
            `Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`
          );
          setIsToggleActivationUuid(null);
        },
      }
    ),
  };
  return {
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
  };
}

export default useModeleLettreMutations;
