import { PrimaryButton, Stack } from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";

import { RiAddFill } from "react-icons/ri";

import { commonButtonStyles } from "../../styles";

function CreateLink({ text, to }: any) {
  return (
    <Stack horizontalAlign="start">
      <RouterLink to={to}>
        <PrimaryButton styles={commonButtonStyles.buttonSubmit}>
          <RiAddFill size={38} />
          <span style={{ fontWeight: 500, marginRight: 6 }}>{text}</span>
        </PrimaryButton>
      </RouterLink>
    </Stack>
  );
}

export default CreateLink;
