import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Can from "../../Common/Can/Can";
import CandidatInformationsPreview from "../../Common/CandidatInformationsPreview/CandidatInformationsPreview";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import FormSection from "../../Common/FormSection/FormSection";
import Page from "../../Common/Page/Page";
import useTheme from "../../Common/useTheme";
import {
  documentsPermissionsLabels,
  permissionsByCandidatureStatus,
} from "../../config/accessEnum";
import { certificationFakeData } from "../../fakeData/Candidat.data";
import useCandidatureWorkflow from "../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../hooks/useAuth";
import useConstants from "../../hooks/useConstants";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import FicheSynthetique from "./Components/FicheSynthetique";
import useFicheCandidat from "./useFicheCandidat";

function FicheCandidat() {
  const { uuid_candidat } = useParams();
  const { labels }: any = useLabelSettings();
  const { primaryColor } = useTheme();
  const { auth }: any = useAuth();
  const { constants } = useConstants();
  const {
    candidat,
    candidatProps,
    isLoading,
    actions: actionsPage,
    isCandidat,
  } = useFicheCandidat();
  const { actions } = candidatProps;
  const navigate = useNavigate();
  const [isShowFiche, setShowFicheTo] = useState(false);
  const { isFirstStatutBeforeOrEqualSecondStatus, STATUTS_CANDIDATURE } =
    useCandidatureWorkflow();

  useEffect(() => {
    const user = auth.user;
    if (uuid_candidat) {
      actions.setUpdateCandidatUuid(uuid_candidat);
    } else {
      if (user.uuid && user.roles.includes("Candidat")) {
        actions.setUpdateCandidatUuid(user.uuid);
        actionsPage.setUpdateIsCandidat(true);
      }
    }
  }, []);

  const columnsCertifications = [
    {
      name: "Branche ",
      fieldName: "branche",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.branche}</Text>,
    },
    {
      name: "Type ",
      fieldName: "type",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification.type_libelle}</Text>,
    },
    {
      name: "Libellé ",
      fieldName: "libelle",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification.libelle}</Text>,
    },
    {
      name: "Date jury ",
      fieldName: "date",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <Text>{tools.formatDate(item?.jury?.date_jury)}</Text>
      ),
    },
    {
      name: "Statut ",
      fieldName: "status",
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const resultat: any = tools.findIn(
          constants["CANDIDATURES"]["RESULTATS_STATUTS"],
          item.resultat,
          "id"
        );
        return resultat ? (
          <Text className="ms-fontWeight-bold">{resultat.text}</Text>
        ) : null;
      },
    },
    // {
    //   name: "Par équivalence ",
    //   fieldName: "isEquivalence",
    //   minWidth: 150,
    //   maxWidth: 400,
    //   isResizable: true,
    //   onRender: (item: any) =>
    //     item.isequivalence ? (
    //       <Text className="ms-fontWeight-bold">Oui</Text>
    //     ) : (
    //       <Text className="ms-fontWeight-bold">Non</Text>
    //     ),
    // },
    {
      name: "Actions ",
      fieldName: "actions",
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: any) =>
        item.documents ? (
          <Stack>
            {item.documents?.map((d: any) => {
              const documentInformation =
                documentsPermissionsLabels[d.collection];
              return documentInformation ? (
                <Can I={documentInformation.permission}>
                  <Link href={d.file_url} download>
                    {documentInformation.label
                      ? labels[documentInformation.label]
                      : documentInformation.nom}
                  </Link>
                </Can>
              ) : null;
            })}
            {/* <Link onClick={() => setShowFicheTo(true)}>Fiche synthétique</Link>
          <Link>{labels.FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_FICHE_SYNTHETIQUE_PAR_CHEMIN}</Link>
          <Link>{labels.FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_FICHE_DUPLICATA_PAR_CHEMIN}</Link>
          <Link>{labels.FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_FICHE_PRECISION}</Link>
          <Link>{labels.FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_DUPLICATA_FICHE_PRECISION}</Link> */}
          </Stack>
        ) : null,
    },
  ];

  const columnscandidature = [
    {
      name: "Entreprise ",
      fieldName: "entreprise",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Text>{item.entreprise_liee?.nom}</Text>,
    },
    {
      name: "OF ",
      fieldName: "of",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Text>{item.of_lie?.raison_sociale}</Text>,
    },
    {
      name: "Statut ",
      fieldName: "statut",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {
            (
              tools.findIn(
                constants["CANDIDATURES"]["STATUTS"],
                item.statut,
                "id"
              ) as any
            ).text
          }
        </Text>
      ),
    },
    {
      name: labels.FICHE_INDIVIDUELLE_ONGLET_JURY,
      fieldName: "jury",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>{item.jury?.libelle}</Text>
          {item.jury && <Text>{tools.formatDate(item.jury?.date_jury)}</Text>}
        </Stack>
      ),
    },
    {
      name: "Actions ",
      fieldName: "actions",
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: any) => {
        const myActions = STATUTS_CANDIDATURE.map(
          (statut: any, index: number) => {
            if (
              index > 0 &&
              isFirstStatutBeforeOrEqualSecondStatus(statut.id, item.statut)
            ) {
              return (
                <Can I={permissionsByCandidatureStatus[statut.id]}>
                  <Link
                    // a modifie -> changer la route
                    to={`/admin/candidature/${item.uuid}/${statut.id}`}
                    as={RouterLink}
                  >
                    {statut.ficheText}
                  </Link>
                </Can>
              );
            }

            return null;
          }
        ).filter((t: any) => t);

        return (
          <Stack>
            {myActions.length > 0 && myActions[myActions.length - 1]}
            {/* <Link onClick={() => toast.info("En cours de dévelopment")}>
              {labels.FICHE_INDIVIDUELLE_CANDIDATURE_VOIR_CANDIDATURE}
            </Link>
            <Link onClick={() => toast.info("En cours de dévelopment")}>
              {labels.FICHE_INDIVIDUELLE_CANDIDATURE_VOIR_CERTIFICATION}
            </Link> */}

            {/* {isFirstStatutBeforeOrEqualSecondStatus(item.statut, "transmission_dossier") && (
              <Link onClick={() => toast.info("En cours de dévelopment")}>
                {labels.FICHE_INDIVIDUELLE_CANDIDATURE_COMPLETER_INSCRIPTION}
              </Link>
            )} */}

            {/* <Link style={{ color: "red" }} onClick={() => toast.info("En cours de dévelopment")}>
              {labels.FICHE_INDIVIDUELLE_CANDIDATURE_SUPPRIMER_CANDIDATURE}
            </Link> */}
            {/* <Link onClick={() => toast.info("En cours de dévelopment")}>
              {labels.FICHE_INDIVIDUELLE_CANDIDATURE_DUPLIQUER_CANDIDATURE}
            </Link> */}
          </Stack>
        );
      },
    },
  ];
  //const obtainedCertifications = candidatFakeData.obtainedCertifications;
  return (
    <Stack>
      <Page
        title={labels.FICHE_INDIVIDUELLE_TITRE}
        explain={labels.FICHE_INDIVIDUELLE_SOUS_TITRE}
        headerButton={
          <DefaultButton
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
            onClick={() => navigate("/admin/demande_aide")}
          >
            {labels.FICHE_INDIVIDUELLE_BESOIN_D_AIDE}
          </DefaultButton>
        }
      >
        <DetailLoader
          isLoadingDetail={isLoading}
          customText={`Chargement de la fiche en cours`}
        >
          <Stack tokens={{ childrenGap: 25 }}>
            <CandidatInformationsPreview
              candidat={candidat}
              isCandidat={isCandidat}
            />

            <FormSection
              sectionTitle={labels.FICHE_INDIVIDUELLE_CERTIFICATION_OBTENUES}
            >
              <DetailsList
                layoutMode={DetailsListLayoutMode.justified}
                items={
                  candidat?.candidatures.filter(
                    (c: any) => c.statut == "resultats_jury"
                  ) || []
                }
                columns={columnsCertifications.map((e, i) => ({
                  ...e,
                  key: `${i}`,
                }))}
                selectionMode={SelectionMode.none}
              />
            </FormSection>

            <FormSection sectionTitle={labels.FICHE_INDIVIDUELLE_CANDIDATURE}>
              {/* <div style={{ width: "30%" }}>
              <LoadButton text={"Créer une candidature"} />
            </div> */}
              <DetailsList
                layoutMode={DetailsListLayoutMode.justified}
                items={candidat?.candidatures || []}
                columns={columnscandidature.map((e, i) => ({
                  ...e,
                  key: `${i}`,
                }))}
                selectionMode={SelectionMode.none}
              />
            </FormSection>
          </Stack>
          <FicheSynthetique
            isShown={isShowFiche}
            setIsShownTo={setShowFicheTo}
            certification={certificationFakeData}
          />
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default FicheCandidat;
