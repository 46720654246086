import { useForm } from "react-hook-form";
import useHabilitation from "../../hooks/habilitation/useHabilitation";

function useHabilitationUtilisateurDetail() {
  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });
  const habilitationProps = useHabilitation();

  const actions = {
    onSubmit: (data: any) => {
      habilitationProps.actions.postHabilitationUtilisateur(data);
    },
  };

  return { reactHookProps, actions, habilitationProps };
}

export default useHabilitationUtilisateurDetail;
