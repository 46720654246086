import {
  DefaultSpacing,
  Dropdown,
  MaskedTextField,
  Spinner,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { Controller } from "react-hook-form";
import BackLink from "../../Common/BackLink/BackLink";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { commonInputStyles } from "../../styles";
import useContactAdminPublic from "./useContactAdminPublic";
import { useNavigate } from "react-router-dom";

const containerStyle = {
  root: {
    width: "45%",
    backgroundColor: "#fff",
    padding: "10px 5%",
    borderRadius: 8,
    boxShadow: "0 0 4px -2px black",
    margin: "20px 0 40px 0",
  },
};

function ContactAdminPublic() {
  const {
    actions,
    branches,
    isLoading,
    constants,
    loadingConstants,
    reactHookProps: {
      handleSubmit,
      formState: { errors },
      watch,
      control,
    },
  } = useContactAdminPublic();
  const navigate = useNavigate();

  const motifOptions =
    constants?.CONTACT.MOTIFS_DEMANDE_AIDE_INTERBRANCHES?.map((e: any) => ({
      ...e,
      key: e.id,
    }));

  const branchesOptions = branches?.data.map((e: any) => ({
    ...e,
    text: e.nom,
    key: e.id,
  }));

  const watchAutre = watch("motif");
  if (loadingConstants) return <Spinner></Spinner>;
  return (
    <Stack
      styles={{ root: { backgroundColor: "#f2f2f2" } }}
      horizontalAlign="center"
    >
      <Stack styles={containerStyle}>
        <Stack>
          <BackLink
            style={{ position: "relative", left: 0, top: -10, marginLeft: 0 }}
            onClick={() => navigate(-1)}
          />
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Text variant="xLarge">Demande d'aide</Text>
          </Stack>
          <Text variant="mediumPlus" style={{ color: "#6F6F6F" }}>
            Veuillez remplir le formulaire et envoyer votre demande d'aide
          </Text>
        </Stack>

        <Stack>
          <form onSubmit={handleSubmit(actions.submitForm)}>
            <Stack
              styles={{
                root: {
                  marginTop: DefaultSpacing.m,
                  marginBottom: DefaultSpacing.m,
                },
              }}
              tokens={{ childrenGap: 20 }}
            >
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <Dropdown
                        options={branchesOptions}
                        styles={commonInputStyles.dropdownStyle}
                        selectedKey={field.value}
                        {...field}
                        onChange={(_e, option) => {
                          field.onChange(option?.key);
                        }}
                        required
                        label="Veuillez sélectionner la branche professionnelle correspondant à votre demande:"
                        errorMessage={(errors.motif as any)?.message}
                      />
                    )}
                    control={control}
                    defaultValue={branchesOptions[0]?.key}
                    name="branche"
                  />
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        styles={commonInputStyles.textField}
                        required
                        label="Nom :"
                        type="text"
                        placeholder="Ex: Doe"
                        errorMessage={(errors[field.name] as any)?.message}
                        {...field}
                      />
                    )}
                    defaultValue=""
                    control={control}
                    name="nom"
                  />
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        styles={commonInputStyles.textField}
                        required
                        label="Prenom :"
                        type="text"
                        placeholder="Ex: Jhon"
                        errorMessage={(errors[field.name] as any)?.message}
                        {...field}
                      />
                    )}
                    defaultValue=""
                    control={control}
                    name="prenom"
                  />
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <TextField
                        styles={commonInputStyles.textField}
                        required
                        label="Email :"
                        type="email"
                        placeholder="Ex: Doe@email.com"
                        errorMessage={(errors[field.name] as any)?.message}
                        {...field}
                      />
                    )}
                    defaultValue=""
                    control={control}
                    name="email"
                  />
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <MaskedTextField
                        styles={commonInputStyles.textField}
                        required
                        label="Numéro de téléphone :"
                        mask="99 99 99 99 99"
                        value={watch(field.name)}
                        errorMessage={(errors[field.name] as any)?.message}
                        name={field.name}
                        onChange={(e, v): any => field.onChange(v)}
                      />
                    )}
                    defaultValue=""
                    control={control}
                    name="numero_telephone"
                  />
                </div>
              </div>
              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-col ms-sm12">
                  <Controller
                    render={({ field }) => (
                      <Dropdown
                        options={motifOptions}
                        styles={commonInputStyles.dropdownStyle}
                        selectedKey={field.value}
                        {...field}
                        onChange={(_e, option) => {
                          field.onChange(option?.key);
                        }}
                        required
                        label="Motifs de demande d'aide:"
                        errorMessage={(errors.motif as any)?.message}
                      />
                    )}
                    control={control}
                    defaultValue={1}
                    name="motif"
                  />
                </div>
                {watchAutre ===
                  motifOptions?.find((e: any) => e.text === "Autre").key && (
                  <div className="ms-Grid-col ms-sm12">
                    <Controller
                      render={({ field }) => (
                        <TextField
                          styles={commonInputStyles.textField}
                          required
                          label="Précisez le motif :"
                          type="text"
                          placeholder="Mon autre motif"
                          errorMessage={(errors[field.name] as any)?.message}
                          {...field}
                        />
                      )}
                      defaultValue=""
                      control={control}
                      name="autre_motif"
                    />
                  </div>
                )}
              </div>
              <Stack>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      required
                      label="Veuillez préciser votre besoin:"
                      placeholder="3000 caractères max"
                      multiline
                      rows={6}
                      maxLength={3000}
                      {...field}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="besoin"
                />
              </Stack>
              {/* <Label>Ajouter un ou des fichiers à votre demande</Label>
            <Controller
              name="files"
              control={control}
              render={({ field: { value, name } }) => (
                <input
                  type="file"
                  name={name}
                  multiple
                  onChange={(e) => setValue(name, e.target.files)}
                />
              )}
            /> */}
              <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
                <LoadButton
                  text={"Envoyer la demande d'aide"}
                  isLoading={isLoading}
                />
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ContactAdminPublic;
