import { Stack, TextField, DefaultButton, Dropdown } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import FormSection from "../../../Common/FormSection/FormSection";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import TextResult from "../../../Common/TextResult/TextResult";

import useUserList from "../useUserList";

import { userStateList } from "../../../fakeData/State.data";
import useConstants from "../../../hooks/useConstants";
import { useState } from "react";

function UserListFilters() {
  const { usersProfils, isLoadingUsers, actions, reactHookProps } =
    useUserList();
  const { constants } = useConstants();
  const [showFilters, setShowFilters] = useState(false);

  const roleListSort = [
    { id: undefined, text: "Tous les rôles", key: "all" },
    ...constants.MATRICES.PROFILS_UTILISATEURS.sort((a: any, b: any) =>
      a.text.localeCompare(b.text)
    ),
  ];

  const totalUsers = usersProfils?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => setShowFilters((s) => !s);

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Nom :"
                  type="text"
                  placeholder="Saisissez un nom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Prénom :"
                  type="text"
                  placeholder="Saisissez un prénom"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Email :"
                  id="email"
                  type="email"
                  placeholder="Saisissez un email"
                  {...field}
                />
              )}
            />
          </Stack.Item>
          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      selectedKey={value ? value.key : undefined}
                      label="Rôle :"
                      placeholder="Sélectionnez un rôle"
                      options={roleListSort}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={roleListSort[0]}
                  control={control}
                  name="role"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="Utilisateur actif :"
                      selectedKey={value ? value.key : undefined}
                      placeholder="Sélectionner un état"
                      options={userStateList}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={userStateList[0]}
                  control={control}
                  name="actif"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="Utilisateur multibranche :"
                      selectedKey={value ? value.key : undefined}
                      placeholder="Sélectionner un état"
                      options={userStateList}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={userStateList[0]}
                  control={control}
                  name="est_multibranches"
                />
              </Stack.Item>
            </>
          )}
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingUsers} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text="Réinitialiser ma recherche"
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalUsers} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default UserListFilters;
