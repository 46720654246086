import { statuts_candidature } from "../hooks/dynamicFields/useDynamicFields";
import tools from "../utils/tools";

const dynamicFieldAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      type: data.type,
      statut_candidature: data.statut_candidature.key,
      libelle: data.libelle,
      requis: data.required,
      export: data.exported,
    };

    if (data.uuid) {
      transformed.uuid = data.uuid;
      transformed._method = "PUT";
    }

    if (data.type == "SELECT") {
      transformed = {
        ...transformed,
        options: data.options.map((o: any) => ({ ...o, option: o.key })),
        multiple: data.multiple,
      };
    }
    if (data.type == "FILE") {
      console.log("data", data);
      transformed = {
        ...transformed,
        fichier_formats: data.fichier_formats.map((f: any) => ({
          fichier_format: f,
        })),
      };

      if (data.fichier_modele) {
        transformed.modele = data.fichier_modele;
      }
      if (data.fichier_modele_deleted) {
        transformed.modele_deleted = data.fichier_modele_deleted;
      }
    }

    return transformed;
  },
  transformForBO: (data: any) => {
    let transformed: any = {
      ...data,
      name: data.uuid,
      statut_candidature: tools.findIn(
        statuts_candidature,
        data.statut_candidature,
        "key"
      ),
      options: data.options?.map((o: any) => ({
        ...o,
        key: o.option,
        text: o.option.replace(/[_]/g, " "),
      })),
      required: data.requis,
      exported: data.export,
      fichier_formats: data.fichier_formats?.map((f: any) => f.fichier_format),
    };

    if (data.modele) {
      transformed.fichier_modele_url = data.modele;
    }

    return transformed;
  },
  transformListForBO: (data: any) => {
    let transformed: any = data.map((field: any) => {
      const transformField = {
        ...field,
        name: field.uuid, //voir avec API (uuid)
        statut_candidature: tools.findIn(
          statuts_candidature,
          field.statut_candidature,
          "key"
        ),
        options: field.options?.map((o: any) => ({
          ...o,
          key: o.option,
          text: o.option.replace(/[_]/g, " "),
        })),
        required: field.requis,
        exported: field.export,
        fichier_formats: field.fichier_formats?.map(
          (f: any) => f.fichier_format
        ),
      };

      if (field.modele) {
        transformField.fichier_modele_url = field.modele;
      }

      return transformField;
    });

    return transformed;
  },
};

export default dynamicFieldAdapters;
