import { MessageBar } from "@fluentui/react";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import { MessageBarStyles } from "../../styles";

const Can = ({ I = "", children }: any) => {
  const { actions } = useAccessSettings();

  const { granted, message }: any = actions.canI({ action: I });

  return granted ? (
    children
  ) : message ? (
    <div style={{ margin: "15px 0" }}>
      <MessageBar className="bg-info" styles={MessageBarStyles}>
        {message}
      </MessageBar>
    </div>
  ) : null;
};

export default Can;
