import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useAtom } from "jotai";
import useSuiviCohortesMutations from "./useSuiviCohortesMutations";
import suiviCohortesAdapters from "../../adapters/suiviCohortesAdapters";
import { getExportSuiviCohorte } from "../../services/suiviCohortesServices";
import { useState } from "react";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";

function useSuiviCohortes() {
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { mutations } = useSuiviCohortesMutations();
  const {actions:modalActions} = useCustomModal()

  const actions = {
    onSavePublic: (formulaire: any, token: any) => {
      const formateFormulaire = {
        ...formulaire,
        token,
      };
      mutations.PutSuiviCohortesPublic.mutate(
        suiviCohortesAdapters.transformForAPI(formateFormulaire)
      );
    },
    onUpdateSuiviCohorte: (formulaire: any) => {
      mutations.PutSuiviCohortesBo.mutate(
        suiviCohortesAdapters.transformForAPI(formulaire)
      );
    },
    onExportSuiviCohorte: async (data: any) => {
      setIsLoadingExport(true);
      try {
        const response:any = await getExportSuiviCohorte(data).catch((e:any)=>{
          toast.error("Votre demande ne contient aucune donnée")
      });
        const filename = response.headers["content-disposition"]
          .split("=")[1]
          .replace(/["]/g, "");
        if (filename) {
          fileDownload(response.data, filename);
        }
        setIsLoadingExport(false);
        modalActions.closeModal()
      } catch (e: any) {
        setIsLoadingExport(false);
      }
    },
  };

  return {
    isLoadingExport,
    userSelectedBranch,
    actions,
  };
}

export default useSuiviCohortes;
