export const sexeList: any = [
  {
    key: 1,
    id: 1,
    text: "Homme",
  },
  {
    key: 2,
    id: 2,
    text: "Femme",
  },
  {
    key: 3,
    id: 3,
    text: "Non spécifié",
  },
];
