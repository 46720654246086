import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  Dropdown,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import DynamicField from "../../../Common/DynamicField/DynamicField";
import FileInput, { fileTypes } from "../../../Common/FileInput/FileInput";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useDynamicFields, {
  dynamicFieldsTypes,
} from "../../../hooks/dynamicFields/useDynamicFields";
import validations from "../../../schemas/dynamicfieldSchema";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import AddOptionsForm from "./AddOptionsForm/AddOptionsForm";
import useAddFieldForm from "./useAddFieldForm";

const defaultErrorValue = {
  type: "",
  statut_candidature: "",
  libelle: "",
  required: "",
  exported: "",
  options: "",
  multiple: "",
  fichier_formats: "",
  fichier_modele: "",
};

const AddFieldForm = ({ field }: any) => {
  const { actions, isLoading, STATUTS_CANDIDATURE_ENABLED } =
    useDynamicFields();
  const { newField, setNewField, actions: fieldActions } = useAddFieldForm();
  const { actions: accessActions } = useAccessSettings();

  const [errorDynamicField, setErrorDynamicField] = useState(defaultErrorValue);

  const onCloseModal = () => {
    fieldActions.onResetNewField();
    actions.closeAddFieldForm();
  };

  const onSaveField = () => {
    setErrorDynamicField(defaultErrorValue);
    const validationDynamicField =
      validations.validateAddDynamicField(newField);
    if (validationDynamicField?.error) {
      validationDynamicField.error.details.map((item) => {
        setErrorDynamicField((prev) => ({
          ...prev,
          [item?.path[0]]: item.message,
        }));
      });
    } else {
      actions.saveField(newField);
    }
  };

  useEffect(() => {
    if (field) {
      setNewField(field);
    }
  }, [field]);

  return field && !newField.uuid ? null : (
    <Stack tokens={{ childrenGap: 15 }} style={{ margin: "10px 0" }}>
      <Stack.Item>
        <ChoiceGroup
          label="Choisissez le type de champ que vous souhaitez ajouter :"
          options={Object.keys(dynamicFieldsTypes).map((fieldKey: any) => {
            const field = dynamicFieldsTypes[fieldKey];

            return { key: field.id, text: field.name };
          })}
          required={true}
          name="type"
          onChange={(e: any, option: any) => {
            fieldActions.onChangeNewField({
              target: { name: "type", value: option.key },
            });
          }}
          selectedKey={newField.type || undefined}
          disabled={newField.uuid}
        />
        <ErrorMessage errorMessage={errorDynamicField.type} />
      </Stack.Item>
      <Stack.Item>
        <Dropdown
          label="Étape de la candidature où sera affiché de champ :"
          selectedKey={
            newField.statut_candidature
              ? (newField.statut_candidature as any).key
              : undefined
          }
          options={[
            { key: "", text: "Choisissez..." },
            ...STATUTS_CANDIDATURE_ENABLED.filter((s: any) => {
              if (
                // newField.type == dynamicFieldsTypes.FILE.id &&
                ["avis_second_evaluateur"].includes(s.key)
              ) {
                return false;
              }
              return true;
            }),
          ]}
          onChange={(e: any, options: any) => {
            fieldActions.onChangeNewField({
              target: {
                name: "statut_candidature",
                value: options,
              },
            });
          }}
          disabled={newField.uuid}
          styles={commonInputStyles.dropdownStyle}
          required
          errorMessage={errorDynamicField.statut_candidature}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          required
          styles={commonInputStyles.textField}
          label="Libellé du champ :"
          placeholder="Phrase qui explique ce qui doit être saisi dans ce champ"
          name="libelle"
          onChange={fieldActions.onChangeNewField}
          value={newField.libelle}
          errorMessage={errorDynamicField.libelle}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label="Ce champ est obligatoire ?"
          styles={commonInputStyles.checkbox}
          name="required"
          onChange={(e: any) => {
            fieldActions.onChangeNewField({
              target: {
                name: e.target.name,
                value: e.target.checked,
              },
            });
          }}
          checked={newField.required}
        />
        <ErrorMessage errorMessage={errorDynamicField.required} />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          label="Cette donnée est présente dans les exports ?"
          styles={commonInputStyles.checkbox}
          name="exported"
          onChange={(e: any) => {
            fieldActions.onChangeNewField({
              target: {
                name: e.target.name,
                value: e.target.checked,
              },
            });
          }}
          checked={newField.exported}
        />
        <ErrorMessage errorMessage={errorDynamicField.exported} />
      </Stack.Item>
      {newField.type == dynamicFieldsTypes.SELECT.id && (
        <>
          <Stack.Item>
            <Checkbox
              label="Permettre de sélectionner plusieurs éléments ?"
              styles={commonInputStyles.checkbox}
              name="multiple"
              onChange={(e: any) => {
                fieldActions.onChangeNewField({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              checked={newField.multiple}
            />
            <ErrorMessage errorMessage={errorDynamicField.multiple} />
          </Stack.Item>
          <AddOptionsForm
            options={newField.options}
            onUpdateOptions={fieldActions.onUpdateOptions}
          />
          <ErrorMessage errorMessage={errorDynamicField.options} />
        </>
      )}
      {newField.type == dynamicFieldsTypes.FILE.id && (
        <>
          <Stack.Item>
            <Dropdown
              label="Formats de fichier autorisés :"
              placeholder="Sélectionnez un ou plusieurs éléments"
              options={Object.keys(fileTypes)
                .filter((ft: any) => ft !== "ANY")
                .map((fileTypeKey: any) => ({
                  key: fileTypeKey,
                  text: fileTypeKey,
                }))}
              onChange={(e: any, options: any) => {
                fieldActions.onChangeNewField({
                  target: {
                    name: "fichier_formats",
                    value: options.selected
                      ? [...newField.fichier_formats, options.key]
                      : newField.fichier_formats.filter(
                          (key: any) => key !== options.key
                        ),
                  },
                });
              }}
              styles={commonInputStyles.dropdownStyle}
              required
              multiSelect
              selectedKeys={newField.fichier_formats}
              disabled={newField.uuid}
              errorMessage={errorDynamicField.fichier_formats}
            />
          </Stack.Item>
          <Stack.Item>
            <Label styles={commonInputStyles.label}>Modèle du fichier :</Label>
            <FileInput
              name="fichier_modele"
              file={newField.fichier_modele ? [newField.fichier_modele] : []}
              existingFileURL={newField.fichier_modele_url}
              onUpdateFile={fieldActions.onChangeNewField}
              acceptedFileTypes={["ANY"]}
              onDeleteFile={() =>
                fieldActions.onChangeNewField({
                  target: {
                    name: "fichier_modele",
                    value: [],
                  },
                })
              }
            />
          </Stack.Item>
        </>
      )}

      {newField.type && (
        <Stack.Item
          style={{
            background: "whitesmoke",
            padding: "0px 15px 15px",
            borderRadius: 5,
            marginTop: 40,
          }}
        >
          <h2>Aperçu du champ</h2>
          <DynamicField {...newField} />
        </Stack.Item>
      )}
      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={onCloseModal}
        >
          Annuler
        </DefaultButton>
        <LoadButton
          onClick={() => onSaveField()}
          text={newField.uuid ? "Modifier le champ" : "Ajouter le champ"}
          isLoading={isLoading}
        />
      </Stack.Item>
    </Stack>
  );
};

export default AddFieldForm;
