import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { stateList } from "../../fakeData/State.data";
import useGroupOf from "../../hooks/groupOf/useGroupOf";
import {
  getExportGroupOfs,
  getGroupOfsRequest,
} from "../../services/groupOfServices";
import tools from "../../utils/tools";
import { useState } from "react";
import { toast } from "react-toastify";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  raison_sociale: "",
  siren: "",
  actif: stateList[0],
  interbranche: stateList[0],
};

export const filtersAtom = atomWithReset<any>({});

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useGroupOfList() {
  const groupOfProps = useGroupOf();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();
  const [isLoadingExportGroupOf, setIsLoadingExportGroupOf] = useState(false);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: groupOfs, isLoading: isLoadingGroupOfs } = useQuery(
    ["groupOfs", optionsFilters, groupOfProps.userSelectedBranch],
    () => getGroupOfsRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteGroupOfModal: (groupOf: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={groupOf.raison_sociale}
            deleteFunction={() =>
              groupOfProps.actions.deleteGroupOf(groupOf.uuid)
            }
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportGroupOfs: async () => {
      setIsLoadingExportGroupOf(true);
      try {
        const firstResponse: any = await getExportGroupOfs(filters).catch(
          (e: any) => {
            console.log("ERR", e);
            toast.error(
              "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
            );
            setIsLoadingExportGroupOf(false);
          }
        );
        if (!firstResponse) return;
        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });
        setIsLoadingExportGroupOf(false);
      } catch (e: any) {
        setIsLoadingExportGroupOf(false);
      }
    },
  };

  return {
    groupOfs,
    groupOfProps,
    isLoadingGroupOfs,
    isLoadingExportGroupOf,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
  };
}

export default useGroupOfList;
