import { DefaultButton, PrimaryButton, Stack, Text } from "@fluentui/react";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import { commonButtonStyles } from "../../../../styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";

export const IdentifyEvaluateurLaterModal = ({
  onConfirmActions = () => {},
}: any) => {
  const { actions } = useCustomModal();

  const onConfirm = () => {
    onConfirmActions();
    actions.closeModal();
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Text style={{ margin: "20px 0px" }}>
        Vous pouvez éviter d'identifier l'évaluateur lors de cette étape. Vous
        pourrez l'identifier a postériori. Cette identification sera nécessaire
        pour la complétion de l'évaluation
      </Text>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>

        <PrimaryButton
          onClick={onConfirm}
          styles={commonButtonStyles.buttonSubmit}
        >
          Confirmer
        </PrimaryButton>
      </Stack.Item>
    </Stack>
  );
};
