import { Stack, Dropdown, DefaultButton, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCompetenceBlockList from "../useCompetenceBlockList";
import { commonInputStyles, commonButtonStyles } from "../../../styles/index";

import { stateList } from "../../../fakeData/State.data";
import { API_URL } from "../../../config/config";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";

function CompetenceBlockListFilters() {

  const { competenceBlocks, isLoadingCompetenceBlocks, actions, competenceBlockProps, reactHookProps } =
    useCompetenceBlockList();
  const { branches } = useConstants()
  const { userSelectedBranch } = competenceBlockProps
  const branchesList = tools.addKeyToObjectInArray([{ id: 0, nom: 'Toutes les branches', value: undefined }, ...branches], 'id')

  const totalCompetenceBlocks = competenceBlocks?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  return (
    <FormSection
      sectionTitle="Filtres de recherche"
    >
      <form
        onSubmit={handleSubmit(actions.changeFilters)}
      >
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="libelle"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Libellé du bloc de compétences :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Bloc actif :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionner un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="actif"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              control={control}
              name="branche_creatrice"
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Branche créatrice du bloc de compétences :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="Sélectionnez une branche"
                  options={branchesList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => setValue(name, options, { shouldValidate: true })}
                  onRenderOption={(option: any) => option.nom}
                  onRenderTitle={(option: any) => option[0].nom}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12"
            style={{ paddingRight: 20, marginTop: 20 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectManyFieldAsync
                  label="Certifications rattachées au bloc de compétences :"
                  placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                  resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?libelle=${terms}&branche_creatrice_id=${userSelectedBranch.id}`
                  }
                  method="GET"
                  renderItem={(item: any) => item.libelle}
                  renderValue={(item: any) => item.libelle}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={{}}
                  //errorMessage={(errors.ref_idcc as any)?.message}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="certifications_rattachees"
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack
            tokens={{ childrenGap: 15 }}
            horizontal
            wrap
          >
            <Stack.Item>
              <LoadButton isLoading={isLoadingCompetenceBlocks} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={()=>onReset()}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalCompetenceBlocks} />

        </Stack>

      </form>
    </FormSection>
  );
}

export default CompetenceBlockListFilters;
