import { Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import { commonInputStyles } from "../../../styles";
import { OfContext } from "../useOfDetail";
import SearchExistingStructure from "../../../Common/SearchExistingStructure/SearchExistingStructure";
import BranchesInfo from "../../../Common/BranchesInfo/BranchesInfo";

function GeneralInformationsSection() {
  const [isGroupUpdated, setIsGroupUpdated] = useState(false);

  const { reactHookProps, errors, isChecked, uuid, of, actions, isSaved }: any =
    useContext(OfContext);
  const { control, watch, setValue } = reactHookProps;

  const fieldsNeedingSavingFirstDisabled = uuid === "creer" && !isSaved;

  useEffect(() => {
    if (isGroupUpdated) {
      reactHookProps.setValue("sous_groupe", null);
    }
  }, [reactHookProps.watch("groupe_of")]);

  return (
    <FormSection sectionTitle="Informations principales de l'OF">
      {uuid === "creer" && (
        <SearchExistingStructure
          reactHookProps={reactHookProps}
          isChecked={isChecked}
          actions={actions}
          searchExistingUrl={"administration/ofs/existe"}
          keyToCheck={"siret"}
          acceptedChararacterNumber={14}
        />
      )}

      {(isChecked || uuid !== "creer") && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Raison sociale :"
                  type="text"
                  {...field}
                  placeholder="Ex : Ma société"
                  errorMessage={(errors[field.name] as any)?.message}
                  required
                />
              )}
              defaultValue=""
              control={control}
              name="raison_sociale"
            />
          </Stack.Item>

          {uuid !== "creer" && (
            <>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="SIRET (14 chiffres) :"
                      type="text"
                      value={value}
                      disabled={true}
                      placeholder="Ex : 12123434678412"
                      errorMessage={(errors[name] as any)?.message}
                      required
                      onChange={(e: any, newValue: any) => {
                        if (!newValue || newValue.length <= 14) {
                          onChange(e.target.value);
                        }
                      }}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="siret"
                />
              </Stack.Item>
              <BranchesInfo
                data={of}
                branchSentence={
                  "Cet organisme de formation existe sur les branches :"
                }
                noBranchSentence={
                  "Cet organisme de formation est présent uniquement sur cette branche"
                }
              />
            </>
          )}

          {/* WAIT QUALIOPI API 
      <Stack.Item>
        <Text>Qualiopi</Text>
      </Stack.Item> */}

          <FormAdress
            control={control}
            errors={errors}
            required={true}
            varToWatch={watch("code_postal") ?? false}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />

          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectFieldAsync
                  label="Groupe d’OFs rattaché à cet OF :"
                  placeholder="Recherchez un groupe d'OFs en tapant les premières lettres de son nom"
                  resourceURI={`${API_URL}api/administration/groupe-ofs/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?raison_sociale=${terms}&paginate=false`
                  }
                  method="GET"
                  renderItem={(item: any) =>
                    item.raison_sociale + " - " + item.siren
                  }
                  renderValue={(item: any) =>
                    item.raison_sociale + " - " + item.siren
                  }
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setIsGroupUpdated(true);
                    reactHookProps.setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={null}
                  errorMessage={(errors.ref_idccs as any)?.message}
                  handleResponse={(response: any) => [...response.data.data]}
                  disabled={fieldsNeedingSavingFirstDisabled}
                />
              )}
              control={control}
              name="groupe_of"
            />
          </Stack.Item>

          {reactHookProps.watch("groupe_of") && (
            <Stack.Item>
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectFieldAsync
                    label="Sous-groupe d’OFs rattaché à cet OF :"
                    placeholder="Recherchez un sous-groupe d'OFs en tapant les premières lettres de son nom"
                    resourceURI={`${API_URL}api/administration/sous-groupes/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?nom=${terms}&type=SOUS_GROUPE_OF&paginate=false&groupe=${
                        reactHookProps.watch("groupe_of").uuid
                      }`
                    }
                    method="GET"
                    renderItem={(item: any) => item.nom}
                    renderValue={(item: any) => item.nom}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      reactHookProps.setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={null}
                    errorMessage={(errors.ref_idccs as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                control={control}
                name="sous_groupe"
              />
            </Stack.Item>
          )}
        </>
      )}
    </FormSection>
  );
}

export default GeneralInformationsSection;
