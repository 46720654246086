import { Stack, Text } from "@fluentui/react";

function CompetenceBlockInformationsModal({ competences }: any) {
  if (!competences.length) {
    return <Text>Le bloc de compétence possède aucune compétence</Text>;
  }
  return (
    <Stack style={{ width: "100%" }}>
      <ul>
        {competences.map((c: any) => (
          <li>{c.libelle}</li>
        ))}
      </ul>
    </Stack>
  );
}

export default CompetenceBlockInformationsModal;
