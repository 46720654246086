import { Stack } from "@fluentui/react";
import { RiAddFill } from "react-icons/ri";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useFetchCandidature from "../useFetchCandidature";
import ExperiencePro from "./FormSection/ExperiencePro";
import FormationContinue from "./FormSection/FormationContinue";
import FormationInitiale from "./FormSection/FormationInitiale";
import useParcoursCandidat, {
  ParcoursCandidatContext,
} from "./useParcoursCandidat";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import Can from "../../../Common/Can/Can";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import { useAtom } from "jotai";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";

function ParcoursCandidat() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const { candidature, reactHookProps, isSaving, actions } =
    useParcoursCandidat();
  const {
    isThisPageStatusBeforeCandidatureStatus,
    isReferentielStillEditable,
  } = useCandidatureWorkflow();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  //Si la candidature est dans un statut ultérieur à choix_blocs et que son référentiel est toujours modifiable, il faut la permission MODIFIER_CHOIX_BLOCS_CERTIF pour les modifier (admin), sinon il faut juste la permission CHOIX_BLOCS_CERTIF
  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, [
        "identification_parties_prenantes",
      ])
      ? userAccessEnum.MODIFIER_PARCOURS_CANDIDAT
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.SAISIR_PARCOURS_CANDIDAT;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    if (isEditMode) {
      reactHookProps.handleSubmit(mecActions.onSaveMEC)(e);
    } else {
      reactHookProps.handleSubmit(actions.onSave)(e);
    }
  };

  return !candidature?.uuid ? null : (
    <ParcoursCandidatContext.Provider
      value={{
        actions,
        reactHookProps,
        candidature,
        inputsDisabled: inputsDisabled && !isEditMode,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection certification={candidature.certification} />
        </CandidatureInfoHeaderWrapper>
        {/* {inputsDisabled && (
          <Can I={userAccessEnum.MODIFIER_PARCOURS_CANDIDAT}>
            <BoutonDemanderMEC
              statut="saisie_parcours"
              statut_candidature={candidature.statut}
            />
          </Can>
        )} */}

        <form onSubmit={onSubmit}>
          <FormationInitiale
            applicableEditPermission={userAccessEnum.MODIFIER_PARCOURS_CANDIDAT}
          />
          <FormationContinue
            applicableEditPermission={userAccessEnum.MODIFIER_PARCOURS_CANDIDAT}
          />
          <ExperiencePro
            applicableEditPermission={userAccessEnum.MODIFIER_PARCOURS_CANDIDAT}
          />

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>
            <Can I={[applicableEditPermission]}>
              <LoadButton
                text="Valider le parcours candidat"
                isLoading={isSaving}
                icon={<RiAddFill size={38} />}
                style={{ fontWeight: "bold" }}
              />
            </Can>
            {/* <BoutonEnregistrerMEC reactHookProps={reactHookProps} /> */}
          </Stack>
        </form>
      </Stack>
    </ParcoursCandidatContext.Provider>
  );
}

export default ParcoursCandidat;
