import { useForm } from "react-hook-form";
import useSubGroupCompany from "../../hooks/subGroupCompany/useSubGroupCompany";
import useSubGroupCompanyQuery from "../../hooks/subGroupCompany/useSubGroupCompanyQuery";
import React from "react";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import AddUserToSubGroupModal from "./AddUserToSubGroupModal/AddUserToSubGroupModal";

export const SubGroupCompanyContext = React.createContext({});
export const SubGroupCompanyProvider = ({ value, children }: any) => {
  return (
    <SubGroupCompanyContext.Provider value={value}>
      {children}
    </SubGroupCompanyContext.Provider>
  );
};

function useSubGroupCompanyDetail() {
  const subGroupCompanyProps = useSubGroupCompany();
  const { subGroupCompany, isLoading, isRefetching } =
    useSubGroupCompanyQuery(subGroupCompanyProps);
  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
  });

  const actions = {
    saveSubGroupCompany: (data: any) => {
      if (!(data as any).uuid) {
        subGroupCompanyProps.actions.postSubGroupCompany(data);
      } else {
        subGroupCompanyProps.actions.putSubGroupCompany(data);
      }
    },
    openUserFormModal: () => {
      modalActions.openModal({
        header: `Ajouter un utilisateur`,
        body: (
          <AddUserToSubGroupModal
            onAddUser={(newUser: any) =>
              actions.onAddUser(newUser)
            }
          />
        ),
      });
    },
    onRemoveUser:(newUser:any)=>{
      const users = reactHookProps.watch("utilisateurs") 
      reactHookProps.setValue('utilisateurs', users.filter((u:any)=>u.email != newUser.email))
    },
    onAddUser: (newUser:any)=>{
      const users = reactHookProps.watch("utilisateurs") || [];
      reactHookProps.setValue('utilisateurs', [...users, {...newUser, isNew:true}])
    },
  };

  return {
    subGroupCompany,
    subGroupCompanyProps,
    reactHookProps,
    actions,
    isLoadingSubGroupCompany: isLoading || isRefetching,
  };
}

export default useSubGroupCompanyDetail;
