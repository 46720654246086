import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import tools from "../utils/tools";

import {
  postCompetenceBlockRequest,
  putCompetenceBlockRequest,
  deleteCompetenceBlockRequest,
  patchActivateCompetenceBlockRequest,
  patchDesactivateCompetenceBlockRequest,
} from "../services/competenceBlockServices";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import { useAtom } from "jotai";

function useCompetenceBlock() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [competenceBlockUuid, setCompetenceBlockUuid] = useState(null);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);

  const mutations = {
    PostCompetenceBlock: useMutation(postCompetenceBlockRequest, {
      onSuccess: (data) => {
        toast.success("Le bloc de compétence a été créé avec succès.");
        actions.setUpdateCompetenceBlockUuid(data.uuid);
        // Invalidate and refetch
        queryClient.invalidateQueries("competenceBlocks");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement du bloc de compétence : ${error.response?.data?.message}`
            );
      },
    }),
    PutCompetenceBlock: useMutation(putCompetenceBlockRequest, {
      onSuccess: () => {
        toast.success("Le bloc de compétence a été mis à jour avec succès.");
        queryClient.invalidateQueries("competenceBlocks");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour du bloc de compétence : ${error.response?.data?.message}`
            );
      },
    }),
    deleteCompetenceBlock: useMutation(deleteCompetenceBlockRequest, {
      onSuccess: () => queryClient.invalidateQueries("competenceBlocks"),
    }),
    toggleCompetenceBlockActivate: useMutation(
      patchActivateCompetenceBlockRequest,
      {
        onSuccess: () => {
          setIsToggleActivationUuid(null);
          toast.success("Le bloc de compétence a été activé avec succès.");
          queryClient.invalidateQueries("competenceBlocks");
        },
        onError: (error: any) => {
          toast.error(
            `Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`
          );
          setIsToggleActivationUuid(null);
        },
      }
    ),
    toggleCompetenceBlockDesactivate: useMutation(
      patchDesactivateCompetenceBlockRequest,
      {
        onSuccess: () => {
          setIsToggleActivationUuid(null);
          toast.success("Le bloc de compétence a été désactivé avec succès.");
          queryClient.invalidateQueries("competenceBlocks");
        },
        onError: (error: any) => {
          toast.error(
            `Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`
          );
          setIsToggleActivationUuid(null);
        },
      }
    ),
  };

  const actions = {
    setUpdateCompetenceBlockUuid: (data: any) => {
      setCompetenceBlockUuid(data);
    },
    postCompetenceBlock: async (competenceBlock: any) => {
      const data = competenceBlock;
      data.branch_id = userSelectedBranch.id;

      const formData = new FormData();
      tools.buildFormData(formData, data, null);
      mutations.PostCompetenceBlock.mutate(formData);
    },
    putCompetenceBlock: async (competenceBlock: any) => {
      const formData = new FormData();
      tools.buildFormData(formData, competenceBlock, null);
      mutations.PutCompetenceBlock.mutate(formData);
    },
    deleteCompetenceBlock: async (competenceBlockUuid: string) => {
      mutations.deleteCompetenceBlock.mutate(competenceBlockUuid);
    },
    toggleCompetenceBlockActivation: (competenceBlock: any) => {
      setIsToggleActivationUuid(competenceBlock.uuid);
      if (competenceBlock.actif) {
        mutations.toggleCompetenceBlockDesactivate.mutate(competenceBlock.uuid);
      } else {
        mutations.toggleCompetenceBlockActivate.mutate(competenceBlock.uuid);
      }
    },
  };

  const {
    isLoading: isLoadingPostCompetenceBlock,
    isError: isErrorPostCompetenceBlock,
    error: errorPostCompetenceBlock,
  } = mutations.PostCompetenceBlock;

  const {
    isLoading: isLoadingPatchCompetenceBlock,
    isError: isErrorPatchCompetenceBlock,
    error: errorPatchCompetenceBlock,
  } = mutations.PutCompetenceBlock;

  return {
    competenceBlockUuid,
    userSelectedBranch,
    isLoading: isLoadingPostCompetenceBlock || isLoadingPatchCompetenceBlock,
    isToggleActivationUuid,
    isErrorPostCompetenceBlock,
    isErrorPatchCompetenceBlock,
    errorPostCompetenceBlock,
    errorPatchCompetenceBlock,
    actions,
  };
}

export default useCompetenceBlock;
