import { PrimaryButton, Stack } from "@fluentui/react";
import { RiAddFill } from "react-icons/ri";
import FormSection from "../../Common/FormSection/FormSection";
import Page from "../../Common/Page/Page";
import useDynamicFields from "../../hooks/dynamicFields/useDynamicFields";
import { commonButtonStyles } from "../../styles";
import FieldList from "./FieldList/FieldList";

const DynamicFields = () => {
  const { actions } = useDynamicFields();

  return (
    <Stack>
      <Page
        title="Gérer les champs complémentaires"
        explain="Veuillez gérer les champs & documents complémentaires sur votre branche."
      >
        <div style={{ marginTop: 25 }}>
          <PrimaryButton
            styles={commonButtonStyles.buttonSubmit}
            onClick={() => actions.openAddFieldForm()}
          >
            <RiAddFill size={38} />
            <span style={{ fontWeight: 500, marginRight: 6 }}>Ajouter un nouveau champ</span>
          </PrimaryButton>
        </div>
        <FormSection sectionTitle="Liste des champs par étape de la candidature">
          <FieldList />
        </FormSection>
      </Page>
    </Stack>
  );
};

export default DynamicFields;
