import { useAtom } from 'jotai';
import { toast } from "react-toastify";
import groupCompanyAdapters from '../../adapters/groupCompanyAdapters';
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useGroupCompanyMutations from './useGroupCompanyMutations';

function useGroupCompany() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const {groupCompanyUuid,setGroupCompanyUuid, mutations, isToggleActivationUuid, setIsToggleActivationUuid} = useGroupCompanyMutations()

  const actions = {
    setUpdateGroupCompanyUuid:(data:any)=>{
      setGroupCompanyUuid(data)
    },
    postGroupCompany:async(groupCompany:any)=>{
      //DELETE quand tous les entité auront la meme logique pour les ADRESSES
      mutations.PostGroupCompany.mutate(groupCompanyAdapters.transformForAPI(groupCompany));
    },
    putGroupCompany: async(groupCompany:any)=>{
      //DELETE quand tous les entité auront la meme logique pour les ADRESSES
      mutations.PutGroupCompany.mutate(groupCompanyAdapters.transformForAPI(groupCompany))
    },
    deleteGroupCompany: async (groupCompanyUuid:any) =>{
      toast.info('En cours de développement')
      //mutations.DeleteGroupCompany.mutate(groupCompanyUuid)
    },
    toggleGroupCompanyActivation:(groupCompany:any)=>{
      setIsToggleActivationUuid(groupCompany.uuid)
      if(groupCompany.actif){
        mutations.ToggleGroupCompanyDesactivate.mutate(groupCompany.uuid)
      }else{
        mutations.ToggleGroupCompanyActivate.mutate(groupCompany.uuid)
      }
    }
  }

  const {
    isLoading: isLoadingPostGroupCompany,
    isError: isErrorPostGroupCompany,
    error: errorPostGroupCompany,
    isSuccess: isSuccessPostGroupCompany,
  } = mutations.PostGroupCompany

  const {
    isLoading: isLoadingPutGroupCompany,
    isError: isErrorPutGroupCompany,
    error: errorPutGroupCompany,
    isSuccess: isSuccessPutGroupCompany,
  } = mutations.PutGroupCompany

  return {
    userSelectedBranch,
    groupCompanyUuid,
    isLoading: isLoadingPostGroupCompany|| isLoadingPutGroupCompany,
    hasError: isErrorPostGroupCompany ||isErrorPutGroupCompany,
    isToggleActivationUuid,
    errorPostGroupCompany,
    isSuccessPostGroupCompany,
    errorPutGroupCompany,
    isSuccessPutGroupCompany,
    actions
  }
}

export default useGroupCompany