const stepContainerStyles = {
  container: {
    root: {
      maxWidth: "1024px",
      paddingTop: "30px",
      margin: "0 auto",
    },
  },
};

export default stepContainerStyles;
