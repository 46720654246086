import { useQuery } from "react-query"
import { getSubGroupOfRequest } from "../../services/subGroupOfServices"

function useSubGroupOfQuery(subGroupOfProps:any) {
    const { data: subGroupOf, isLoading, isRefetching} = useQuery(
        ['subGroupOfs', subGroupOfProps.subGroupOfUuid],
        () => getSubGroupOfRequest(subGroupOfProps.subGroupOfUuid),
        {
            enabled: !!subGroupOfProps.subGroupOfUuid,
            retry: 0,
            refetchOnWindowFocus:false,
        }
    )

    return {
        subGroupOf,
        isLoading,
        isRefetching
    }
}

export default useSubGroupOfQuery