import fileDownload from "js-file-download";
import { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../config/config";
import useDownloadFile from "../../hooks/useDownloadFile";
import {
  confirmImportRequest,
  validateImportRequest,
} from "../../services/importServices";
import tools from "../../utils/tools";

export const entityTypes = {
  ENTREPRISE: "entreprise",
  OF: "of",
  GROUPE_ENTREPRISE: "groupe_entreprise",
  GROUPE_OF: "groupe_of",
  CANDIDATURE: "candidature",
  HABILITATION: "habilitation",
  HABILITATION_UTILISATEUR: "habilitation_utilisateur",
};

export const modelsDir = "/import-models/";

export const entityConfig = [
  {
    type: entityTypes.ENTREPRISE,
    modelFilename: `entreprise.xlsx`,
    title: "Importer des entreprises",
  },
  {
    type: entityTypes.OF,
    modelFilename: `of.xlsx`,
    title: "Importer des OF",
  },
  {
    type: entityTypes.GROUPE_ENTREPRISE,
    modelFilename: `groupe-entreprise.xlsx`,
    title: "Importer des groupes d'entreprises",
  },
  {
    type: entityTypes.GROUPE_OF,
    modelFilename: `groupe-of.xlsx`,
    title: "Importer des groupes d'OF",
  },
];

const useImportEntities = (entityType: any) => {
  const [fileToImport, setFileToImport] = useState([]);
  const [validationResult, setValidationResult]: any = useState(null);
  const [confirmationResult, setConfirmationResult]: any = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadingTemplate, setIsDownloadingTemplate] = useState(false);
  const downloadFile = useDownloadFile();

  const actions = {
    onDownloadTemplate: async () => {
      setIsDownloadingTemplate(true);
      try {
        const response = await downloadFile(
          `${API_URL}api/administration/import-entites/modele/${entityType}`
        );
        fileDownload(response.data, `${entityType}.xlsx`);
      } catch (err: any) {
        toast.error(
          "Une erreur est survenue lors du téléchargement, veuillez réessayer."
        );
      }
      setIsDownloadingTemplate(false);
    },
    onUpdateFile: (e: any) => {
      setFileToImport(e.target.value);
      actions.onUploadFile(e.target.value);
    },
    onDeleteFile: () => {
      setFileToImport([]);
      setValidationResult(null);
    },

    onUploadFile: async (file: any) => {
      if (file.length == 0) return false;

      setIsLoading(true);
      try {
        const formData = new FormData();
        tools.buildFormData(
          formData,
          {
            type_entite: entityType,
            file: file[0],
          },
          null
        );
        const importResults = await validateImportRequest(formData);
        setValidationResult(importResults);
      } catch (err: any) {
        console.log(err);
        if (err?.response?.data) {
          toast.error(
            err.response.data?.message ||
              "Une erreur est survenue à l'envoi du fichier."
          );
        }
      }
      setIsLoading(false);
      //TODO : UPLOAD DU FICHIER
      // setValidationResult(validationResultExample[entityRef.type] || []);
    },

    onCancelImport: () => {
      actions.onDeleteFile();
    },

    onConfirmImport: async () => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        tools.buildFormData(
          formData,
          {
            type_entite: entityType,
            file: fileToImport[0],
          },
          null
        );
        const importResults = await confirmImportRequest(formData);
        setConfirmationResult(importResults);
        toast.info(
          `Entités importées : ${importResults.import_valides.length}. Entités non importées : ${importResults.import_invalides.length}.`
        );
      } catch (err: any) {
        console.log(err);
        if (err?.response?.data) {
          toast.error(
            err.response.data?.message ||
              "Une erreur est survenue à l'envoi du fichier."
          );
        }
      }
      setIsLoading(false);
    },
  };

  return {
    fileToImport,
    validationResult,
    isLoading,
    isDownloadingTemplate,
    confirmationResult,
    actions,
  };
};

export default useImportEntities;
