import useRouteAccessibility from "../../hooks/useRouteAccessibility";
import HeaderPrivate from "./HeaderPrivate";
import HeaderPublic from "./HeaderPublic";
import useAuth from "../../hooks/useAuth";
import useInitialize from "../../hooks/useInitialize";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { atom, useAtom } from "jotai";

export const showHeaderAtom = atom(true);

function Header() {
  const [showHeader] = useAtom(showHeaderAtom);
  const { isInitialize } = useInitialize();
  const { isPrivateRoute, isPublicRoute } = useRouteAccessibility();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { isAuth } = useAuth();

  if ((isPublicRoute || !isAuth) && showHeader) {
    return <HeaderPublic />;
  }

  if (isPrivateRoute && isInitialize && userSelectedBranch) {
    return <HeaderPrivate />;
  }

  return null;
}

export default Header;
