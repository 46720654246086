import {
  ChoiceGroup,
  DatePicker,
  Label,
  MaskedTextField,
  Stack,
  TextField,
} from "@fluentui/react";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import BirthInformationFormSection from "../../Common/BirthInformationFormSection/BirthInformationFormSection";
import CancelButton from "../../Common/CancelButton/CancelButton";
import FormSection from "../../Common/FormSection/FormSection";
import InputMessage from "../../Common/InputMessage/InputMessage";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import ResidenceInformationFormSection from "../../Common/ResidenceInformationFormSection/ResidenceInformationFormSection";
import candidatAdapters from "../../adapters/candidatAdapters";
import { strings } from "../../config/datePickerConfig";
import { sexeList } from "../../fakeData/Sexe.data";
import useConstants from "../../hooks/useConstants";
import { commonInputStyles } from "../../styles";
import tools from "../../utils/tools";
import useProfilCandidatDetail from "./useProfilCandidatDetail";

function ProfilCandidatDetail() {
  const { uuid_candidat } = useParams();
  const {
    reactHookProps,
    actions: actionsPage,
    candidatProps,
    candidat,
  } = useProfilCandidatDetail();
  const {
    isErrorPutCandidat,
    errorPutCandidat,
    isLoadingPutCandidat,
    actions,
  } = candidatProps;
  const { constants } = useConstants();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    reset,
  } = reactHookProps;

  useEffect(() => {
    actions.setUpdateCandidatUuid(uuid_candidat);
  }, [uuid_candidat]);

  useEffect(() => {
    if (candidat?.uuid) {
      reset(candidatAdapters.transformForBO(candidat, constants));
    }
  }, [candidat]);

  useEffect(() => {
    var apiErrors = (errorPutCandidat as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutCandidat]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.onSave)(e);
  };

  return (
    <Stack>
      <BackLink />

      <Page
        title={"Édition du profil candidat"}
        explain={"Veuillez saisir votre profil candidat"}
      >
        <form onSubmit={onSubmit}>
          <FormSection sectionTitle="Informations du candidat">
            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    required
                    label="Nom de naissance :"
                    type="text"
                    {...field}
                    placeholder="Ex : Doe"
                    errorMessage={(errors.nom as any)?.message}
                  />
                )}
                defaultValue=""
                control={control}
                name="nom"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    required
                    label="Nom d'usage :"
                    type="text"
                    {...field}
                    placeholder="Ex : Doe"
                    errorMessage={(errors.nom_usage as any)?.message}
                  />
                )}
                defaultValue=""
                control={control}
                name="nom_usage"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    required
                    label="Prénom :"
                    type="text"
                    {...field}
                    placeholder="Ex : John"
                    errorMessage={(errors.prenom as any)?.message}
                  />
                )}
                defaultValue=""
                control={control}
                name="prenom"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <>
                    <Label
                      htmlFor="date_de_naissance"
                      required
                      styles={commonInputStyles.label}
                    >
                      Date de naissance :
                    </Label>
                    <DatePicker
                      formatDate={(date?: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      strings={strings}
                      showGoToToday={false}
                      value={
                        watch("date_de_naissance")
                          ? new Date(watch("date_de_naissance"))
                          : undefined
                      }
                      styles={commonInputStyles.dataPicker}
                      onSelectDate={(date: any) =>
                        date
                          ? setValue(
                              "date_de_naissance",
                              DateTime.fromJSDate(date).toISO()
                            )
                          : null
                      }
                      placeholder="dd/mm/yyyy"
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  </>
                )}
                defaultValue={undefined}
                control={control}
                name="date_de_naissance"
              />
              {errors.date_de_naissance && (
                <InputMessage
                  message={(errors.date_de_naissance as any)?.message}
                />
              )}
            </Stack.Item>

            <Stack.Item style={{ width: "50%" }}>
              <Controller
                render={({ field: { value, name } }) => (
                  <ChoiceGroup
                    label="Sexe :"
                    styles={commonInputStyles.textField}
                    selectedKey={value ? value.key : 3}
                    options={sexeList}
                    onChange={(e: any, options: any) => {
                      setValue(name, options, { shouldValidate: true });
                    }}
                    name={name}
                    required
                  />
                )}
                control={control}
                name="civilite"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    required
                    label="Email :"
                    type="email"
                    {...field}
                    placeholder="Ex : john.doe@gmail.com"
                    errorMessage={(errors.email as any)?.message}
                  />
                )}
                defaultValue=""
                control={control}
                name="email"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <MaskedTextField
                    styles={commonInputStyles.textField}
                    mask="99 99 99 99 99"
                    required
                    label="Téléphone portable :"
                    {...field}
                    errorMessage={(errors.telephone_portable as any)?.message}
                  />
                )}
                defaultValue=""
                control={control}
                name="telephone_portable"
              />
            </Stack.Item>

            <Stack.Item>
              <Controller
                render={({ field }) => (
                  <MaskedTextField
                    styles={commonInputStyles.textField}
                    mask="99 99 99 99 99"
                    label="Téléphone professionnel :"
                    {...field}
                    errorMessage={
                      (errors.telephone_professionnel as any)?.message
                    }
                  />
                )}
                defaultValue=""
                control={control}
                name="telephone_professionnel"
              />
            </Stack.Item>
          </FormSection>

          <BirthInformationFormSection
            control={control}
            onResetValue={(e: any, checked: any) => {
              setValue("candidat.pays_naissance", "");
              setValue("candidat.code_postal_naissance", "");
              setValue("candidat.region_naissance", "");
              setValue("candidat.ville_naissance", "");
            }}
            codePostalvarToWatch={
              watch("candidat.code_postal_naissance") ?? false
            }
            neEtrangervarToWatch={!watch("candidat.ne_etranger") ?? false}
            errors={errors}
            onPrefillCityAndRegion={(data: any) => {
              setValue("candidat.region_naissance", data.nom_region);
              setValue("candidat.ville_naissance", data.nom_commune);
            }}
          />

          <ResidenceInformationFormSection
            required={true}
            control={control}
            varToWatch={watch("code_postal") ?? false}
            errors={errors?.adresse}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <CancelButton />

            <LoadButton
              isLoading={isLoadingPutCandidat}
              text={"Enregistrer les modifications"}
            />
          </Stack>

          {isErrorPutCandidat && (
            <PageMessage
              message={(errorPutCandidat as any).response.data.message}
              type="error"
            />
          )}
        </form>
      </Page>
    </Stack>
  );
}

export default ProfilCandidatDetail;
