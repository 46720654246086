import { Label } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import FileInput from "../../../Common/FileInput/FileInput";
import FormSection from "../../../Common/FormSection/FormSection";

export default function DocumentsJury() {
  const { watch, setValue, control } = useFormContext();
  return (
    <FormSection sectionTitle="Documents du jury">
      <Controller
        control={control}
        name="documents"
        render={({ field: { onChange, name, value } }) => (
          <>
            <Label>Documents :</Label>
            <FileInput
              name={name}
              file={watch(name) ? watch(name) : []}
              existingFilesURL={watch('documents_url')}
              onRemoveMultipleFile={(fileKey:any, fromArray?:any)=>{
                if(fromArray=='file'){
                  setValue(name,value.filter((f:any,fk:any)=>fk!=fileKey))
                }else{
                  setValue('documents_url',watch('documents_url')?.filter((f:any,fk:any)=>fk!=fileKey))
                }
              }}
              onUpdateFile={(e: any) => {
                setValue(name, e.target.value);
              }}
              acceptedFileTypes={["ANY"]}
              multipleFiles
            />
          </>
        )} />

    </FormSection>
  );
}