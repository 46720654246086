const breadCrumbStyle = {
  root: {
    margin: "0px 15px",
  },
  item: {
    fontSize: 14,
    padding: 0,
  },
  itemLink: {
    fontSize: 14,
  },
};

export default breadCrumbStyle;
