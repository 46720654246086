import {
  DatePicker,
  DefaultButton,
  Dropdown,
  Label,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useState } from "react";
import FileInput from "../../../../Common/FileInput/FileInput";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import useConstants from "../../../../hooks/useConstants";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import { DateTime } from "luxon";
import { API_URL } from "../../../../config/config";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { strings } from "../../../../config/datePickerConfig";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum } from "../../../../config/accessEnum";
import tools from "../../../../utils/tools";

const AddFormationForm = ({ onAddFormation, formation: initialFormation, isInitial, inputsDisabled }: any) => {
  const { actions } = useCustomModal();
  const { constants } = useConstants();
  const { actions: accessActions } = useAccessSettings();

  const [formation, setFormation] = useState(
    initialFormation
      ? {
        ...initialFormation,
        date_debut: DateTime.fromISO(initialFormation.date_debut).toUTC().toISO(),
        date_fin: DateTime.fromISO(initialFormation.date_fin).toUTC().toISO(),
      }
      : {
        intitule: "",
        niveau_formation: undefined,
        specialite: "",
        etablissement: "",
        date_debut: undefined,
        date_fin: undefined,
        certification_obtenue: true,
        pays: "",
        attestation_formation: null,
        attestation_formation_url: "",
        attestation_formation_deleted: false,
      }
  );
  const handleChangeFormation = (e: any) => {
    const { name, value } = e.target;
    if (name == "attestation_formation") {
      const newFormation = {
        ...formation,
        [name]: value[0],
        attestation_formation_deleted: value.length <= 0,
        attestation_formation_url: value.length <= 0 ? "" : formation.attestation_formation_url,
      };

      setFormation((f: any) => newFormation);
    } else {
      setFormation({ ...formation, [name]: value });
    }
  };

  const onSaveFormation = () => {
    onAddFormation(formation, isInitial);
  };

  return (
    <Stack
      style={{ ...formSectionStyles.formContainer, minWidth: 400, paddingBottom: 12 }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="intitule"
          label="Intitulé de la formation :"
          type="text"
          placeholder="Ex: Licence informatique"
          onChange={handleChangeFormation}
          required
          value={formation.intitule}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <Dropdown
          required
          label="Niveau de la formation :"
          selectedKey={
            formation.niveau_formation ? (formation.niveau_formation as any).key : undefined
          }
          placeholder="Sélectionnez le niveau du dernier diplôme"
          options={constants["CANDIDATURES"]["NIVEAUX_DIPLOME"]}
          onChange={(e: any, options: any) => {
            handleChangeFormation({ target: { name: "niveau_formation", value: options } });
          }}
          styles={commonInputStyles.dropdownStyle}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="specialite"
          label="Spécialité :"
          type="text"
          placeholder="Ex: Réseaux et télécommunications"
          onChange={handleChangeFormation}
          value={formation.specialite}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="etablissement"
          label="Établissement :"
          type="text"
          placeholder="Ex: Université de Paris"
          onChange={handleChangeFormation}
          value={formation.etablissement}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <Label htmlFor="date_debut" required styles={commonInputStyles.label}>
          Date de début :
        </Label>
        <DatePicker
          formatDate={(date?: any): any =>
            date ? `${tools.reformateDateFromJsDate(date)}` : null
          }
          strings={strings}
          showGoToToday={false}
          maxDate={new Date()}
          value={formation.date_debut ? new Date(formation.date_debut) : undefined}
          styles={commonInputStyles.dataPicker}
          onSelectDate={(date: any) => {
            if(date){
              handleChangeFormation({
                target: { name: "date_debut", value: DateTime.fromJSDate(date).set({hour:12}).toUTC().toISO() },
              });
            }
          }}
          disabled={inputsDisabled}
          placeholder="dd/mm/yyyy"
          allowTextInput
          parseDateFromString={(dateStr: any) => tools.parseDateFromStringDatePicker(dateStr)}
        />
      </Stack.Item>

      <Stack.Item>
        <Label htmlFor="date_fin" required styles={commonInputStyles.label}>
          Date de fin :
        </Label>
        <DatePicker
          formatDate={(date?: any): any =>
            date ? `${tools.reformateDateFromJsDate(date)}` : null
          }
          strings={strings}
          maxDate={new Date()}
          showGoToToday={false}
          value={formation.date_fin ? new Date(formation.date_fin) : undefined}
          styles={commonInputStyles.dataPicker}
          onSelectDate={(date: any) => {
            if(date){
              handleChangeFormation({
                target: { name: "date_fin", value: DateTime.fromJSDate(date).set({hour:12}).toUTC().toISO() },
              });
            }
          }}
          disabled={inputsDisabled}
          placeholder="dd/mm/yyyy"
          allowTextInput
          parseDateFromString={(dateStr: any) => tools.parseDateFromStringDatePicker(dateStr)}
        />
      </Stack.Item>

      <Stack.Item>
        <Label htmlFor="certification_obtenue" required styles={commonInputStyles.label}>
          La certification a t-elle été obtenue ? :
        </Label>
        <Toggle
          styles={commonInputStyles.toggleInput}
          onText="Oui"
          offText="Non"
          onChange={(e, checked): any =>
            handleChangeFormation({ target: { name: "certification_obtenue", value: checked } })
          }
          checked={formation.certification_obtenue}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <SelectFieldAsync
          label="Pays :"
          placeholder="Recherchez un pays en tapant les premières lettres"
          resourceURI={`${API_URL}api/references/ref-pays/chercher-pays`}
          addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?recherche=${terms}`}
          method="GET"
          renderItem={(item: any) => item.nom}
          renderValue={(item: any) => item}
          dataIndex="id"
          value={formation.pays}
          onChange={(e: any) =>
            handleChangeFormation({ target: { name: "pays", value: e.target.value.nom } })
          }
          defaultValue=""
          //errorMessage={(errors.code_postal as any)?.message}
          handleResponse={(response: any, terms: any) => {
            return response.data.data.length > 0 ? response.data.data : [{ nom: terms }];
          }}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <Label
          styles={commonInputStyles.label}
          required={
            accessActions.canI({
              action: isInitial
                ? branchAccessEnum.OBLIGER_UPLOAD_ATTESTATION_FORMATION_INITIALE
                : branchAccessEnum.OBLIGER_UPLOAD_ATTESTATION_FORMATION_CONTINUE,
            }).granted
          }
        >
          Attestation de la formation :
        </Label>
        <FileInput
          name="attestation_formation"
          file={formation.attestation_formation ? [formation.attestation_formation] : []}
          existingFileURL={formation.attestation_formation_url}
          onUpdateFile={handleChangeFormation}
          acceptedFileTypes={["PDF", "PNG", "JPG", "JPEG"]}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton styles={commonButtonStyles.defaultButton} onClick={actions.closeModal}>
          Annuler
        </DefaultButton>
        {!inputsDisabled &&
          <PrimaryButton
            onClick={onSaveFormation}
            styles={commonButtonStyles.buttonSubmit}
            iconProps={{ iconName: "Add" }}
          >
            Enregistrer la formation
          </PrimaryButton>
        }
      </Stack.Item>
    </Stack>
  );
};

export default AddFormationForm;
