import { Stack, Checkbox, IconButton, TextField } from "@fluentui/react";
import { useState } from "react";

const SelectedItem = ({ removeItem, value, editItem = () => {} }: any) => {
  const [isEditable, setIsEditable] = useState(false);
  const [newValue, setNewValue] = useState("");

  const updateOptionName = () => setIsEditable(true);

  const validateEdit = () => {
    if (newValue == "" || newValue == value) {
      cancelEdit();
    } else {
      editItem(value, newValue);
      cancelEdit();
    }
  };

  const cancelEdit = () => {
    setNewValue("");
    setIsEditable(false);
  };

  return (
    <Stack
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
      style={{ marginBottom: 10, marginRight: 10, borderBottom: "1px solid #D1D1D1" }}
      key={`resultItem${value?.uuid}`}
    >
      <Stack horizontal verticalAlign="center">
        <Checkbox checked={true} onChange={removeItem} />
        <TextField
          underlined
          disabled={!isEditable}
          placeholder={value}
          value={newValue}
          onChange={(e: any) => setNewValue(e.target.value)}
        />
      </Stack>
      {!isEditable && (
        <Stack.Item>
          <IconButton iconProps={{ iconName: "edit" }} onClick={() => updateOptionName()} />
        </Stack.Item>
      )}
      {isEditable && (
        <Stack.Item>
          <IconButton iconProps={{ iconName: "CheckMark" }} onClick={() => validateEdit()} />
          <IconButton iconProps={{ iconName: "Cancel" }} onClick={() => cancelEdit()} />
        </Stack.Item>
      )}
    </Stack>
  );
};

export default SelectedItem;
