const footerStyles = {
  containerStyle: {
    root: {
      backgroundColor: "white",
      height: "5vh",
    },
  },
  linkStyle: {
    root: {
      color: "black",
      fontSize: 16,
      ":hover": {
        color: "black",
      },
    },
  },
  itemStyle: {
    root: {
      display: "flex",
      flex: "auto",
      padding: "0px 10px",
      justifyContent: "center",
      alignItems: "center",
    },
  },
};

export default footerStyles;
