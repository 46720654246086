import { Stack, Toggle } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { API_URL } from "../../config/config";
import { commonInputStyles } from "../../styles/index";
import FormSection from "../FormSection/FormSection";
import SelectFieldAsync from "../SelectField/SelectFieldAsync/SelectFieldAsync";

function BirthInformationFormSection({
  errors = {},
  control,
  onResetValue = () => {},
  codePostalvarToWatch,
  neEtrangervarToWatch,
  disabled,
  enabledInputs = [],
  withContainer = true,
  required = true,
  onPrefillCityAndRegion = () => {},
}: any) {
  const onChangeCountry = (e: any, checked: any) => {
    onResetValue(e, checked);
  };

  const content = (
    <>
      <Stack.Item>
        <Controller
          render={({ field: { onChange, value, name } }) => (
            <Toggle
              styles={commonInputStyles.toggleInput}
              label="Candidat né à l'étranger ? :"
              inlineLabel
              onText="Oui"
              offText="Non"
              onChange={(e, checked) => {
                onChange(checked);
                onChangeCountry(e, checked);
              }}
              checked={value}
              disabled={disabled && !enabledInputs.includes(name)}
            />
          )}
          defaultValue={false}
          control={control}
          name="candidat.ne_etranger"
        />
      </Stack.Item>

      {!neEtrangervarToWatch && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref, onChange } }) => (
              <SelectFieldAsync
                required={required}
                label="Pays de naissance :"
                placeholder="Recherchez un pays en tapant les premières lettres"
                resourceURI={`${API_URL}api/references/ref-pays/chercher-pays`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => item.nom}
                renderValue={(item: any) => item}
                dataIndex="id"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => onChange(e.target.value.nom)}
                defaultValue=""
                errorMessage={(errors.candidat?.pays_naissance as any)?.message}
                handleResponse={(response: any, terms: any) => {
                  return response.data.data.length > 0
                    ? response.data.data
                    : [{ nom: terms }];
                }}
                disabled={disabled && !enabledInputs.includes(name)}
              />
            )}
            defaultValue=""
            control={control}
            name="candidat.pays_naissance"
          />
        </Stack.Item>
      )}

      {neEtrangervarToWatch && (
        <>
          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref, onChange } }) => (
                <SelectFieldAsync
                  required={required}
                  label="Code postal de naissance :"
                  placeholder="Recherchez un code postal en tapant les premiers chiffres"
                  resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?code_postal=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) =>
                    item.code_postal + " | " + item.nom_commune
                  }
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    onChange(e.target.value.code_postal);
                    if (e.target.value.nom_region) {
                      onPrefillCityAndRegion(e.target.value);
                    }
                  }}
                  defaultValue=""
                  errorMessage={
                    (errors.candidat?.code_postal_naissance as any)?.message
                  }
                  handleResponse={(response: any, terms: any) => {
                    return response.data.data.length > 0
                      ? response.data.data
                      : [{ code_postal: terms, nom_commune: "Inconnu" }];
                  }}
                  disabled={disabled && !enabledInputs.includes(name)}
                />
              )}
              defaultValue=""
              control={control}
              name="candidat.code_postal_naissance"
            />
          </Stack.Item>

          {codePostalvarToWatch && (
            <>
              <Stack.Item>
                <Controller
                  render={({
                    field: { name, value, onBlur, ref, onChange },
                  }) => (
                    <SelectFieldAsync
                      required={required}
                      label="Région de naissance :"
                      placeholder="Veuillez sélectionner ou inscrire votre region"
                      resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?nom_region=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item}
                      renderValue={(item: any) => item}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={onChange}
                      defaultValue=""
                      fetchOnLoad={true}
                      errorMessage={
                        (errors.candidat?.region_naissance as any)?.message
                      }
                      handleResponse={(response: any, terms: any) => {
                        const regionsSingle = Array.from(
                          new Set(
                            response.data.data.map((x: any) => x.nom_region)
                          )
                        );

                        return regionsSingle.length > 0
                          ? regionsSingle
                          : [terms];
                      }}
                      disabled={disabled && !enabledInputs.includes(name)}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="candidat.region_naissance"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({
                    field: { name, value, onBlur, ref, onChange },
                  }) => (
                    <SelectFieldAsync
                      required={required}
                      label="Ville de naissance :"
                      placeholder="Veuillez sélectionner ou inscrire votre ville"
                      resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?nom_commune=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item}
                      renderValue={(item: any) => item}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={onChange}
                      defaultValue=""
                      fetchOnLoad={true}
                      errorMessage={
                        (errors.candidat?.ville_naissance as any)?.message
                      }
                      handleResponse={(response: any, terms: any) => {
                        const citySingle = Array.from(
                          new Set(
                            response.data.data.map((x: any) => x.nom_commune)
                          )
                        );

                        return citySingle.length > 0 ? citySingle : [terms];
                      }}
                      disabled={disabled && !enabledInputs.includes(name)}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="candidat.ville_naissance"
                />
              </Stack.Item>
            </>
          )}
        </>
      )}
    </>
  );

  return withContainer ? (
    <FormSection sectionTitle="Naissance du candidat">{content}</FormSection>
  ) : (
    content
  );
}

export default BirthInformationFormSection;
