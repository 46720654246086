import { atom, useAtom } from "jotai";
import { useQuery } from "react-query";
import ModificationCandidaturesJuryModal from "../../Common/InscriptionJuryModal/ModificationCandidatureJuryModal";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useCandidature from "../../hooks/candidature/useCandidature";
import { getWaitingCandidatureRequest } from "../../services/candidatureService";
import tools from "../../utils/tools";
import { useForm } from "react-hook-form";
import DesincriptionConfirmDialog from "../CandidatureList/DesincriptionConfirmDialog/DesincriptionConfirmDialog";

export const initialFilters = {
  libelle_jury: "",
  date_debut_jury: null,
  date_fin_jury: null,
  entreprises: [],
  ofs: [],
  evaluateur: null,
};

const filtersAtom = atom<any>(initialFilters);

const optionsFiltersAtom = atom({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useValidationCandidatureList() {
  const candidatureProps = useCandidature();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const {
    data: validationCandidatureList,
    isLoading,
    refetch,
  } = useQuery(
    [
      "validateCandidature",
      optionsFilters,
      candidatureProps.userSelectedBranch,
    ],
    () => getWaitingCandidatureRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    openModifyCandidatureModal: async (
      item: any,
      isModificationEnMasse: boolean = false
    ) => {
      let candidatureInscriptionAValider = [];
      if (isModificationEnMasse) {
        candidatureInscriptionAValider = await getWaitingCandidatureRequest(
          filters,
          {
            ...optionsFilters,
            paginate: false,
            sort: "",
          }
        );
      }

      modalActions.openModal({
        header: "Modification du jury associé à la candidature",
        body: (
          <ModificationCandidaturesJuryModal
            candidatures={
              isModificationEnMasse
                ? candidatureInscriptionAValider.data
                : [item]
            }
            onSaved={(jury: any) => {
              refetch();
            }}
            isModificationEnMasse={isModificationEnMasse}
          />
        ),
        fullWidth: true,
      });
    },
    openDesincriptionConfirmModal: (candidature: any) => {
      modalActions.openModal({
        body: (
          <DesincriptionConfirmDialog
            candidat={`${candidature?.candidat?.nom} ${candidature?.candidat?.prenom}`}
            desinscriptionAction={() =>
              candidatureProps.actions.patchDesinscireCandidature(
                candidature.uuid
              )
            }
          />
        ),
      });
    },
  };

  return {
    validationCandidatureList,
    isLoading,
    optionsFilters,
    candidatureProps,
    actions,
    reactHookProps,
  };
}

export default useValidationCandidatureList;
