import {
  useQuery
} from "react-query";

import {
  getBranchRequest,
} from "../../services/branchServices";
import useBranch from "../../hooks/useBranch";
import { atom, useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useForm } from "react-hook-form";
import { IBranch } from "../../interfaces/branchInterface";
import { joiResolver } from "@hookform/resolvers/joi";
import { branchSchema } from "../../schemas/branchSchema";
import React from "react";

export const isGestionDomaineModifiedOnMyBranchAtom = atom(false)

export const BranchContext = React.createContext({});
export const BranchProvider = ({ value, children }: any) => {
  return (
    <BranchContext.Provider value={value}>
      {children}
    </BranchContext.Provider>
  );
};

function useBranchDetail() {
  const [, setIsGestionDomaineModifiedOnMyBranch] = useAtom(isGestionDomaineModifiedOnMyBranchAtom)
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom)
  const branchProps = useBranch()

  const { data: branch, isLoading, isRefetching } = useQuery(
    ["branches", branchProps.branchId],
    () => getBranchRequest(branchProps.branchId),
    { 
      enabled: !!branchProps.branchId,
      retry:0,
      refetchOnWindowFocus:false,
    }
  );

  const reactHookProps = useForm<IBranch>({
    mode: "onBlur",
    resolver: joiResolver(branchSchema),
  });

  const actions = {
    saveBranch: (data: any) => {
      if (!(data as any).id) {
        branchProps.actions.postBranch(data);
      } else {
        setIsGestionDomaineModifiedOnMyBranch((data.gestion_par_domaine != branch.gestion_par_domaine && userSelectedBranch.id == data.id))
        branchProps.actions.putBranch(data);
      }
    },
  };

  return {
    branch,
    branchProps,
    reactHookProps,
    actions,
    isLoadingBranch: isLoading || isRefetching
  };
}

export default useBranchDetail;
