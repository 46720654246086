import { DefaultButton, MessageBar, Stack } from "@fluentui/react";
import { useContext } from "react";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import useTheme from "../../../../Common/useTheme";
import { MessageBarStyles, commonButtonStyles } from "../../../../styles";
import ExperienceList from "../ExperienceList/ExperienceList";
import { ParcoursCandidatContext } from "../useParcoursCandidat";

function ExperiencePro({ applicableEditPermission, isEditMode = false }: any) {
  const { actions, reactHookProps, inputsDisabled }: any = useContext(
    ParcoursCandidatContext
  );
  const { watch } = reactHookProps;
  const { primaryColor } = useTheme();

  return (
    <FormSection sectionTitle="Expériences professionnelles & extra-professionnelles">
      <Can I={[applicableEditPermission]}>
        <Stack.Item>
          <DefaultButton
            onClick={() => actions.openAddExperience(null, true)}
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
          >
            Ajouter une expérience professionnelle
          </DefaultButton>
        </Stack.Item>
      </Can>
      {watch("experiences_professionnelles")?.length > 0 ? (
        <ExperienceList
          experiences={watch("experiences_professionnelles") || []}
          openExperience={actions.openAddExperience}
          isProfessionel={true}
        />
      ) : (
        <MessageBar styles={MessageBarStyles}>Aucune entrée</MessageBar>
      )}

      <Can I={[applicableEditPermission]}>
        <Stack.Item>
          <DefaultButton
            onClick={() => actions.openAddExperience(null, false)}
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
          >
            Ajouter une expérience extra-professionnelle
          </DefaultButton>
        </Stack.Item>
      </Can>
      {watch("experiences_extraprofessionnelles")?.length > 0 ? (
        <ExperienceList
          experiences={watch("experiences_extraprofessionnelles") || []}
          openExperience={actions.openAddExperience}
          isProfessionel={false}
        />
      ) : (
        <MessageBar styles={MessageBarStyles}>Aucune entrée</MessageBar>
      )}
    </FormSection>
  );
}

export default ExperiencePro;
