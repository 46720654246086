import { Label, Stack, Text } from "@fluentui/react";
import Badge from "../../../../Common/Badge/Badge";
import Can from "../../../../Common/Can/Can";
import { userAccessEnum } from "../../../../config/accessEnum";

function AvisCandidatPositionnement({ candidatValidationStatus, comment }: any) {
  if (candidatValidationStatus !== "valide") {
    return (
      <Can I={userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT}>
        <Stack style={{ marginTop: 20 }}>
          <Stack.Item>
            <Badge text="Positionnement invalidé par le candidat" color="rgb(224, 5, 0)" />
          </Stack.Item>
          {comment && (
            <Stack.Item>
              <Label>Commentaire laissé par le candidat :</Label>
              <div>
                <Text>{comment}</Text>
              </div>
            </Stack.Item>
          )}
        </Stack>
      </Can>
    );
  } else {
    return <></>;
  }
}

export default AvisCandidatPositionnement;
