import { useResetAtom } from "jotai/utils";
import useDemandeHabilitationMutations from "./useDemandeHabilitationMutations";
import { demandeHabilitationOfSelectedAtom } from "../../Pages/DemandeHabilitationOfList/useDemandeHabilitationOfList";
import { demandeHabilitationUtilisateurSelectedAtom } from "../../Pages/DemandeHabilitationUtilisateurList/useDemandeHabilitationUtilisateurList";
import tools from "../../utils/tools";
import { DateTime } from "luxon";

function useDemandeHabilitation() {
  const { mutations } = useDemandeHabilitationMutations();
  const resetDemandeHabilitationOfSelected = useResetAtom(
    demandeHabilitationOfSelectedAtom
  );
  const resetDemandeHabilitationUtilisateurSelected = useResetAtom(
    demandeHabilitationUtilisateurSelectedAtom
  );
  const actions = {
    onRefuseDemandeOf: (data: any) => {
      mutations.PatchRefuserDemandeHabilitationOf.mutate(data);
    },
    onValidDemandeOf: (data: any) => {
      const transformData = {
        ...data,
        date_debut: DateTime.fromJSDate(data.date_debut).toFormat("yyyy-MM-dd"),
        date_fin: DateTime.fromJSDate(data.date_fin).toFormat("yyyy-MM-dd"),
      };
      mutations.PostValiderDemandeHabilitationOf.mutate(transformData);
      resetDemandeHabilitationOfSelected();
    },
    onRefuseDemandeUtilisateur: (data: any) => {
      mutations.PatchRefuserDemandeHabilitationUtilisateur.mutate(data);
    },
    onValidDemandeUtilisateur: (data: any) => {
      const transformData = {
        ...data,
        date_debut: DateTime.fromJSDate(data.date_debut).toFormat("yyyy-MM-dd"),
        date_fin: DateTime.fromJSDate(data.date_fin).toFormat("yyyy-MM-dd"),
      };
      mutations.PostValiderDemandeHabilitationUtilisateur.mutate(transformData);
      resetDemandeHabilitationUtilisateurSelected();
    },
    onDemandeHabilitationOf: (data: any) => {
      mutations.PostDemandeHabilitationOf.mutate(data);
    },
    onDemandeHabilitationUtilisateur: (data: any) => {
      mutations.PostDemandeHabilitationUtilisateur.mutate(data);
    },
    onDemandeHabilitationOfFromPartiePrenante: (data: any) => {
      mutations.PostDemandeHabilitationOfFromPartiePrenante.mutate(data);
    },
  };
  return { actions };
}

export default useDemandeHabilitation;
