import useDemandeInscriptionStructureMutations from "./useDemandeInscriptionStructureMutations";

function useDemandeInscriptionStructure() {
  const {
    mutations,
    setIsToggleDemandeInscriptionUuid,
    isToggleDemandeInscriptionUuid,
  } = useDemandeInscriptionStructureMutations();

  const actions = {
    validerDemandeInscription: (stucture: any) => {
      setIsToggleDemandeInscriptionUuid(stucture.uuid);
      mutations.ValiderDemandeInscription.mutate(stucture.uuid);
    },
    refuserDemandeInscription: (structure: any, commentaire: string) => {
      mutations.RefuserDemandeInscription.mutate({
        structureUuid: structure.uuid,
        commentaire,
      });
    },
    validerDemandeInscriptionStructureEnMasse: (structures: any) => {
      mutations.ValiderDemandeInscriptionEnMasse.mutate(
        structures.map((u: any) => u.uuid)
      );
    },
  };

  const {
    isLoading: isLoadingAccepterDemandeInscription,
    isError: isErrorAccepterDemandeInscription,
    error: errorAccepterDemandeInscription,
    isSuccess: isSuccessAccepterDemandeInscription,
  } = mutations.ValiderDemandeInscription;

  const {
    isLoading: isLoadingRefuserDemandeInscription,
    isError: isErrorRefuserDemandeInscription,
    error: errorRefuserDemandeInscription,
    isSuccess: isSuccessRefuserDemandeInscription,
  } = mutations.RefuserDemandeInscription;

  return {
    isLoading:
      isLoadingAccepterDemandeInscription || isLoadingRefuserDemandeInscription,
    hasError:
      isErrorAccepterDemandeInscription || isErrorRefuserDemandeInscription,
    errorAccepterDemandeInscription,
    isSuccessAccepterDemandeInscription,
    errorRefuserDemandeInscription,
    isSuccessRefuserDemandeInscription,
    isToggleDemandeInscriptionUuid,
    actions,
  };
}

export default useDemandeInscriptionStructure;
