import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteGroupCompanyRequest,
  patchActivateGroupCompanyRequest,
  patchDesactivateGroupCompanyRequest,
  postGroupCompanyRequest,
  putGroupCompanyRequest,
} from "../../services/groupCompanyServices";

function useGroupCompanyMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [groupCompanyUuid, setGroupCompanyUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);

  const mutations = {
    PostGroupCompany: useMutation(postGroupCompanyRequest, {
      onSuccess: (data) => {
        toast.success("Le groupe d'entreprise a été créé avec succès.");
        setGroupCompanyUuid(data.uuid);
        queryClient.invalidateQueries("groupCompanies");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement du groupe d'entreprise : ${error?.response?.data?.message}`
            );
      },
    }),
    PutGroupCompany: useMutation(putGroupCompanyRequest, {
      onSuccess: () => {
        toast.success("Le groupe d'entreprise a été mis à jour avec succès.");
        queryClient.invalidateQueries("groupCompanies");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour du groupe d'entreprise : ${error?.response?.data?.message}`
            );
      },
    }),
    DeleteGroupCompany: useMutation(deleteGroupCompanyRequest, {
      onSuccess: () => queryClient.invalidateQueries("groupCompanies"),
    }),
    ToggleGroupCompanyActivate: useMutation(patchActivateGroupCompanyRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("Le groupe d'entreprise a été activé avec succès.");
        queryClient.invalidateQueries("groupCompanies");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
    ToggleGroupCompanyDesactivate: useMutation(
      patchDesactivateGroupCompanyRequest,
      {
        onSuccess: () => {
          setIsToggleActivationUuid(null);
          toast.success("Le groupe d'entreprise a été désactivé avec succès.");
          queryClient.invalidateQueries("groupCompanies");
        },
        onError: (error: any) => {
          toast.error(
            `Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`
          );
          setIsToggleActivationUuid(null);
        },
      }
    ),
  };

  return {
    groupCompanyUuid,
    setGroupCompanyUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
  };
}

export default useGroupCompanyMutations;
