import { Spinner, SpinnerSize } from "@fluentui/react";
import { atom, useAtom } from "jotai";
import { Outlet } from "react-router-dom";

export const isLoadingGlobalAtom = atom(false);

const GlobalLoader = () => {
  const [isLoadingGlobal] = useAtom(isLoadingGlobalAtom);

  return isLoadingGlobal ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner
        size={SpinnerSize.large}
        label="Chargement des paramètres de la branche..."
      />
    </div>
  ) : (
    <Outlet />
  );
};

export default GlobalLoader;
