import { useEffect, useState } from "react";
import Loader from "../../../Common/Loader/Loader";
import ResultatsCandidature from "./ResultatsCandidature";
import { useParams } from "react-router-dom";
import { getCandidatureDocumentsRequest } from "../../../services/candidatureService";
import { toast } from "react-toastify";

import useUrlParams from "../useUrlParams";

import { atom, useAtom } from "jotai";


var refetchTimeout: any = null;

export const shouldRefetchCandidatureAtom = atom(false);

const ResultatsCandidatureWrapper = () => {
  const { id_candidature } = useUrlParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [statutGeneration, setStatutGeneration] = useState("");
  const [shouldRefetchCandidature, setShouldRefetchCandidature] = useAtom(
    shouldRefetchCandidatureAtom
  );

  useEffect(() => {
    checkDocumentGenerationStatus();
  }, []);

  const checkDocumentGenerationStatus = async (hasBeenPending = false) => {
    setShouldRefetchCandidature((s) => false);
    try {
      const results = await getCandidatureDocumentsRequest(id_candidature);

      setStatutGeneration(results.data.statut);
      if (results.data.statut == "GENERATION_TERMINEE" && hasBeenPending) {
        setShouldRefetchCandidature((s) => true);
      }

      if (results.data.statut == "GENERATION_EN_COURS") {
        refetchTimeout = setTimeout(() => {
          checkDocumentGenerationStatus(true);
        }, 10000);
      } else {
        clearTimeout(refetchTimeout);
      }
    } catch (err: any) {
      toast.error(
        err?.response?.data?.message ||
          "Une erreur est survenue lors de la récupération des documents."
      );
    }
    if (!isLoaded) {
      setIsLoaded(true);
    }
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <ResultatsCandidature
      statutGeneration={statutGeneration}
      checkDocumentGenerationStatus={checkDocumentGenerationStatus}
    />
  );
};

export default ResultatsCandidatureWrapper;
