import { clear } from "console";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import useCandidat from "../../hooks/candidat/useCandidat";
import useCandidatQuery from "../../hooks/candidat/useCandidatQuery";
import { authAtom } from "../../hooks/useAuth";

function useProfilCandidatDetail() {
  const [auth] = useAtom(authAtom);
  const candidatProps = useCandidat();
  const { candidat } = useCandidatQuery(candidatProps);

  const reactHookProps = useForm(
    {
      mode: "onBlur",
    }
    //resolver:joiResolver ...
  );

  const actions = {
    onSave: (data: any) => {
      candidatProps.actions.putCandidat(data, auth.user.uuid);
    },
  };

  return {
    candidat,
    candidatProps,
    reactHookProps,
    actions,
  };
}

export default useProfilCandidatDetail;
