import { useQuery } from "react-query";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useDomaine from "../../hooks/domaine/useDomaine"
import { getDomainesRequest } from "../../services/domaineServices";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";



function useDomaineList() {
    const domaineProps = useDomaine();
    const { actions: modalActions } = useCustomModal();

    const { data: domaines, isLoading: isLoadingDomaines } = useQuery(
        ["domaines", domaineProps.userSelectedBranch],
        () => {
            return getDomainesRequest();
        },
        {
            retry: 0
        }
    );

    const actions = {
        openDeleteDomaineModal: (domaine: any) => {
            modalActions.openModal({
                body: <DeleteConfirmDialog
                    libelle={domaine.nom}
                    deleteFunction={() => domaineProps.actions.deleteDomaine(domaine.uuid)}
                />,
            })
        },
    }

    return {
        domaineProps,
        domaines,
        isLoadingDomaines,
        actions
    }
}

export default useDomaineList