import { atom, useAtom } from "jotai";
import { getLabelSettingsRequest } from "../../services/labelSettingServices";

const labelSettingsAtom = atom([]);
const labelsAtom = atom((get: any) => {
  const labelSettings = get(labelSettingsAtom);
  let labels: any = {};
  labelSettings.forEach((label: any) => {
    labels[label.type] = label.valeur;
  });
  return labels;
});

const useLabelSettings = () => {
  const [labelSettings, setLabelSettings] = useAtom(labelSettingsAtom);
  const [labels] = useAtom(labelsAtom);

  const actions = {
    getLabelSettings: async () => {
      const data = await getLabelSettingsRequest();

      setLabelSettings(data.data);
    },
  };

  return {
    labelSettings,
    setLabelSettings,
    actions,
    labels,
  };
};

export default useLabelSettings;
