import { useState } from "react";
import {
  useQueryClient,
  useMutation
} from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  postCompetenceRequest,
  putCompetenceRequest,
  deleteCompetenceRequest,
  patchActivateCompetenceRequest,
  patchDesactivateCompetenceRequest,
} from "../services/competenceServices"
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import { useAtom } from "jotai";

function useCompetence() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [competenceUuid, setCompetenceUuid] = useState(null);
  const [userSelectedBranch,] = useAtom(userSelectedBranchAtom)
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);

  const mutations = {
    PostCompetence: useMutation(postCompetenceRequest, {
      onSuccess: (data) => {
        toast.success("La compétence a été créée avec succès.");
        actions.setUpdateCompetenceUuid(data.uuid)
        // Invalidate and refetch
        queryClient.invalidateQueries("competences");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
            `Une erreur est survenue lors de l'enregistrement de la compétence : ${error?.message}`
          );
      },
    }),
    PutCompetence: useMutation(putCompetenceRequest, {
      onSuccess: () => {
        toast.success("La compétence a été mise à jour avec succès.");
        queryClient.invalidateQueries("competences");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
            `Une erreur est survenue lors de la mise à jour de la compétence : ${error?.message}`
          );
      },
    }),
    deleteCompetence: useMutation(deleteCompetenceRequest, {
      onSuccess: () => queryClient.invalidateQueries("competences"),
    }),
    toggleCompetenceActivate: useMutation(patchActivateCompetenceRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("La compétence a été activée avec succès.");
        queryClient.invalidateQueries("competences")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
    toggleCompetenceDesactivate: useMutation(patchDesactivateCompetenceRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null)
        toast.success("La compétence a été désactivée avec succès.");
        queryClient.invalidateQueries("competences")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
        setIsToggleActivationUuid(null)
      }
    }),
  };

  const actions = {
    setUpdateCompetenceUuid: (data: any) => {
      setCompetenceUuid(data);
    },
    postCompetence: async (competence: any) => {
      const data = competence;
      data.branch_id = userSelectedBranch.id;
      mutations.PostCompetence.mutate(data);
    },
    putCompetence: async (competence: any) => {
      mutations.PutCompetence.mutate(competence);
    },
    deleteCompetence: async (competenceUuid: string) => {
      mutations.deleteCompetence.mutate(competenceUuid);
    },
    toggleCompetenceActivation: (competence: any) => {
      setIsToggleActivationUuid(competence.uuid)
      if (competence.active) {
        mutations.toggleCompetenceDesactivate.mutate(competence.uuid);
      } else {
        mutations.toggleCompetenceActivate.mutate(competence.uuid);
      }
    },
  };

  const {
    isLoading: isLoadingPostCompetence,
    isError: isErrorPostCompetence,
    error: errorPostCompetence,
  } = mutations.PostCompetence;

  const {
    isLoading: isLoadingPutCompetence,
    isError: isErrorPutCompetence,
    error: errorPutCompetence,
  } = mutations.PutCompetence;

  return {
    userSelectedBranch,
    competenceUuid,
    isLoading: isLoadingPostCompetence || isLoadingPutCompetence,
    isToggleActivationUuid,
    isErrorPostCompetence,
    isErrorPutCompetence,
    errorPostCompetence,
    errorPutCompetence,
    actions
  }
}

export default useCompetence