import Joi from "joi";
import {joiPasswordExtendCore} from 'joi-password'

const JoiPassword = Joi.extend(joiPasswordExtendCore);


export const authSchema = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner votre email",
      "string.email": "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
  password: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner votre mot de passe" }),
});

export const forgetPasswordSchema = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner votre email",
      "string.email": "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
})

export const resetPasswordSchema = Joi.object({
  email:Joi.string()
  .required()
  .email({ tlds: { allow: false } })
  .messages({
    "string.empty": "Veuillez renseigner votre email",
    "string.email": "Veuillez respecter le format de l'email (exemple@domaine.fr)",
  }),
  token:Joi.string()
    .required(),
  password: JoiPassword.string()
    .min(8)
    .minOfSpecialCharacters(1)
    .minOfUppercase(1)
    .minOfNumeric(1)
    .required()
    .messages({
      "string.empty": "Veuillez renseigner votre mot de passe",
      "string.min": "Votre mot de passe doit contenir au minimum 8 caractères",
      "password.minOfUppercase": "Votre mot de passe doit contenir au minimum 1 majuscule",
      "password.minOfSpecialCharacters": "Votre mot de passe doit contenir au minimum 1 caractère spécial",
      "password.minOfNumeric":"Votre mot de passe doit contenir au minimum 1 chiffre"
    }),
  password_confirmation:Joi.string()
    .required()
    .valid(Joi.ref('password'))
    .messages({
      "string.empty": "Veuillez confirmer votre mot de passe",
      "any.only": "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas"
    }),
})