export const CopyCardStyles = {
  root: {
    padding: 8,
    border: "1px solid rgb(215 215 215)",
    borderRadius: 8,
    cursor: "pointer",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
};
