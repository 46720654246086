import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteSubGroupCompanyRequest, postSubGroupCompanyRequest, putSubGroupCompanyRequest } from "../../services/subGroupCompanyServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function useSubGroupCompanyMutations() {
    const queryClient = useQueryClient();
    let navigate = useNavigate();

    const [subGroupCompanyUuid, setSubGroupCompanyUuid] = useState(null);

    const mutations = {
        PostSubGroupCompany: useMutation(postSubGroupCompanyRequest, {
            onSuccess: (data) => {
              toast.success("Le sous groupe d'entreprise a été créée avec succès.");
              setSubGroupCompanyUuid(data.uuid)
              // Invalidate and refetch
              queryClient.invalidateQueries("subGroupCompanies");
            },
            onError: (error: any) => {
              !(error.response.status) || error.response.status >= 500
                ? navigate(`/erreur`)
                : toast.error(`Une erreur est survenue lors de l'enregistrement du sous groupe d'entreprise : ${error?.response?.data?.message}`);
            },
          }),
          PutSubGroupCompany: useMutation(putSubGroupCompanyRequest, {
            onSuccess: () => {
              toast.success("Le sous groupe d'entreprise a été mise à jour avec succès.");
              queryClient.invalidateQueries("subGroupCompanies");
            },
            onError: (error: any) => {
              !(error.response.status) || error.response.status >= 500
                ? navigate(`/erreur`)
                : toast.error(`Une erreur est survenue lors de la mise à jour du sous groupe d'entreprise : ${error?.response?.data?.message}`);
            },
          }),
        DeleteSubGroupCompany: useMutation(deleteSubGroupCompanyRequest, {
            onSuccess: () => queryClient.invalidateQueries('subGroupCompanies'),
          }),
    }

    return {
        subGroupCompanyUuid,
        setSubGroupCompanyUuid,
        mutations,
    }
}

export default useSubGroupCompanyMutations