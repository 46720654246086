import { MessageBar } from "@fluentui/react";
import { MessageBarStyles } from "../../styles";

function BranchesInfo({ data, branchSentence, noBranchSentence }: any) {
  if (!data) {
    return <></>;
  }

  const getBranchesInfoMessage = () => {
    if (data?.branches?.length > 1) {
      return branchSentence;
    } else {
      return noBranchSentence;
    }
  };

  return (
    <MessageBar styles={MessageBarStyles}>
      {getBranchesInfoMessage()}
      {data?.branches?.length > 1 && (
        <ul>
          {data.branches?.map((b: any) => (
            <li>{b.nom}</li>
          ))}
        </ul>
      )}
    </MessageBar>
  );
}

export default BranchesInfo;
