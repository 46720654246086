import { DefaultButton, Stack, Text } from "@fluentui/react";
import { useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import Can from "../../../Common/Can/Can";
import FileDisplay from "../../../Common/FileDisplay/FileDisplay";
import FormSection from "../../../Common/FormSection/FormSection";
import JuryStatusBadge from "../../../Common/JuryStatusBadge/JuryStatusBadge";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import { axiosInstance } from "../../../config/axiosConfig";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useAuth from "../../../hooks/useAuth";
import useGenerateCandidatureDocumentsJury from "../../../hooks/useGenerateCandidatureDocumentsJury";
import { jurysURI } from "../../../services/juryServices";
import { commonButtonStyles } from "../../../styles";
import tools from "../../../utils/tools";
import { candidaturesListAtom } from "../useCandidatureJuryList";
import CloseJuryModal from "./CloseJuryModal/CloseJuryModal";
import { FilesModal } from "./FilesModal";
import JuryFilesModal from "./JuryFilesModal/JuryFilesModal";
import RegenerateDocumentsModal from "./RegenerateDocumentsModal/RegenerateDocumentsModal";

function InformationsJury({ jury, actions }: any) {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { auth } = useAuth();
  const [candidaturesList]: any = useAtom(candidaturesListAtom);
  const [isLoading, setIsLoading] = useState(false);
  const { actions: modalActions } = useCustomModal();
  const { actions: accessActions } = useAccessSettings();
  const { actions: generateDocumentsActions, isLoadingFileKey } =
    useGenerateCandidatureDocumentsJury();

  const onDowloadDocument = () => {
    if (candidaturesList.length == 0) {
      toast.info("Veuillez d'abord effectuer une recherche.");
      return;
    }
    modalActions.openModal({
      body: <FilesModal jury_uuid={jury.uuid} />,
      fullWidth: true,
    });
  };

  const isAdmin =
    auth.user.est_administrateur ||
    userSelectedBranch.profils.includes("ADMINISTRATEUR_BRANCHE");

  const isPresidentForThisJury = jury?.president_jury_uuid == auth.user.uuid;

  const isMembreForThisJury = jury?.membres?.find(
    (m: any) => m.uuid == auth.user.uuid
  );

  const canUserViewResult =
    (isAdmin ||
      isPresidentForThisJury ||
      isMembreForThisJury ||
      jury?.est_cloture === true) &&
    accessActions.canI({
      action: userAccessEnum.TELECHARGER_DOCUMENTS_JURY,
    }).granted;

  const onCloseJury = () => {
    modalActions.openModal({
      body: <CloseJuryModal jury={jury} actions={actions} />,
      fullWidth: true,
    });
  };

  const onDowloadJuryDocument = async () => {
    setIsLoading(true);
    const response = await axiosInstance.get(`${jurysURI}/${jury.uuid}`);
    modalActions.openModal({
      body: <JuryFilesModal jury={response.data} />,
      fullWidth: true,
    });
    setIsLoading(false);
  };

  const onRegenerateDocuments = async () => {
    modalActions.openModal({
      body: <RegenerateDocumentsModal jury={jury} actions={actions} />,
      fullWidth: true,
    });
  };

  return !jury ? null : (
    <FormSection sectionTitle="Informations du jury">
      <Stack
        style={{ marginBottom: 24 }}
        horizontalAlign="start"
        horizontal
        wrap
        className="ms-Grid"
      >
        <Stack
          className="ms-Grid-col ms-sm12 ms-lg6"
          tokens={{ childrenGap: 10 }}
        >
          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>Libellé du jury : </Text>
            <Text>{jury.libelle}</Text>
          </Stack.Item>

          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>Date du jury : </Text>
            <Text>{tools.formatDate(jury.date_jury)}</Text>
          </Stack.Item>

          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>
              Date limite d'inscription des candidats :{" "}
            </Text>
            <Text>
              {tools.formatDate(jury.date_limite_inscription_candidat)}
            </Text>
          </Stack.Item>

          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>
              Date d'accès pour les membres du jury :{" "}
            </Text>
            <Text>{tools.formatDate(jury.date_acces_membres_jury)}</Text>
          </Stack.Item>

          <Stack.Item>
            <Text style={{ fontWeight: "bold" }}>Nombre de place : </Text>
            <Text>{jury.nombre_places}</Text>
          </Stack.Item>
          {jury.adresse?.code_postal && (
            <Stack.Item>
              <Text style={{ fontWeight: "bold" }}>Lieu : </Text>
              <Text>
                {jury.adresse?.adresse}, {jury.adresse?.code_postal}{" "}
                {jury.adresse?.ville} {jury.adresse?.region}
              </Text>
            </Stack.Item>
          )}

          {/* <Stack.Item>
                            <Text style={{fontWeight:'bold'}}>Domaine : </Text>
                            <Text>{jury.libelle}</Text>
                        </Stack.Item> */}

          <Stack.Item>
            <JuryStatusBadge statut={jury.statut} />
          </Stack.Item>
        </Stack>

        <Stack
          className="ms-Grid-col ms-sm12 ms-lg6"
          tokens={{ childrenGap: 10 }}
          style={{ marginTop: 10 }}
        >
          {jury.president_jury?.name && (
            <Stack.Item>
              <Text style={{ fontWeight: "bold" }}>Président du jury : </Text>
              <Text>{jury.president_jury?.name}</Text>
            </Stack.Item>
          )}

          {jury.vice_president_jury?.name && (
            <Stack.Item>
              <Text style={{ fontWeight: "bold" }}>
                Vice-président du jury :{" "}
              </Text>
              <Text>{jury.vice_president_jury?.name}</Text>
            </Stack.Item>
          )}

          {jury.membres.length > 0 && (
            <Stack.Item>
              <Text style={{ fontWeight: "bold" }}>Membre(s) du jury : </Text>
              <ul style={{ marginTop: 0 }}>
                {jury.membres?.map((membre: any) => (
                  <li key={membre.uuid}>
                    {membre.prenom} {membre.nom}
                  </li>
                ))}
              </ul>
            </Stack.Item>
          )}

          {(jury.prenom_vice_president || jury.nom_vice_president) && (
            <Stack.Item style={{ marginBottom: 10 }}>
              <Text style={{ fontWeight: "bold" }}>Vice-président : </Text>
              <Text>
                {jury.prenom_vice_president} {jury.nom_vice_president} -{" "}
                {jury.syndicat_vice_president?.syndicat} -{" "}
                {jury.statut_vice_president}
              </Text>
            </Stack.Item>
          )}

          {!jury.est_cloture && auth.user.est_administrateur && (
            <Stack.Item>
              <LoadButton
                type={"button"}
                text="Re-générer les documents des candidatures"
                onClick={onRegenerateDocuments}
                isLoading={isLoading}
                primary={false}
                style={{
                  fontWeight: "500",
                  padding: "0 14px",
                  lineHeight: "100%",
                }}
              />
            </Stack.Item>
          )}

          {isAdmin &&
            accessActions.canI({
              action: branchAccessEnum.GENERER_FICHE_INDIVIDUELLE_CANDIDATURE,
            }).granted && (
              <Stack.Item>
                <LoadButton
                  type={"button"}
                  text="Télécharger les fiches individuelles de candidature"
                  onClick={(e: any) =>
                    generateDocumentsActions.onDownloadDocument(
                      jury.uuid,
                      {
                        key: "FICHE_INDIVIDUELLE",
                        id: "FICHE_INDIVIDUELLE",
                      },
                      null
                    )
                  }
                  isLoading={isLoadingFileKey}
                  primary={false}
                  style={{
                    fontWeight: "500",
                    padding: "0 14px",
                    lineHeight: "100%",
                  }}
                />
              </Stack.Item>
            )}

          {canUserViewResult && (
            <Stack.Item>
              <DefaultButton
                styles={commonButtonStyles.defaultButton}
                text="Télécharger les parchemins et fiches de décision"
                onClick={onDowloadDocument}
              />
            </Stack.Item>
          )}

          {jury?.est_cloture ? (
            <Stack.Item>
              <LoadButton
                type={"button"}
                text="Télécharger les documents du jury"
                onClick={onDowloadJuryDocument}
                isLoading={isLoading}
                primary={false}
                style={{
                  fontWeight: "500",
                  padding: "0 14px",
                  lineHeight: "100%",
                }}
              />
            </Stack.Item>
          ) : (
            <Can I={userAccessEnum.CLOTURER_JURY}>
              <Stack.Item>
                <DefaultButton
                  styles={commonButtonStyles.defaultButton}
                  text="Clôturer ce jury"
                  onClick={onCloseJury}
                />
              </Stack.Item>
            </Can>
          )}
        </Stack>
      </Stack>

      <Stack horizontal horizontalAlign="start" wrap>
        {jury.documents
          ? jury.documents.map((d: any) => (
              <Stack.Item style={{ marginTop: 10, marginLeft: 10 }}>
                <FileDisplay existingFileURL={d.file_url} readOnly={true} />
              </Stack.Item>
            ))
          : null}
      </Stack>
    </FormSection>
  );
}

export default InformationsJury;
