import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const modelesLettreURI = "administration/modeles-lettre";

export const getModelesLettreRequest = async (options: any) => {
  const queryOptionsUrl = options ? tools.generateOptionUrl(options) : "";

  const response = await eCertifApi.get(
    `${modelesLettreURI}/chercher?${queryOptionsUrl}`
  );
  return response.data;
};

export const getModeleLettreDetailRequest = async (modeleLettreId: any) => {
  const response = await eCertifApi.get(
    `${modelesLettreURI}/${modeleLettreId}`
  );
  return response.data;
};

export const getDictionnaireModeleLettreRequest = async (modelType: any) => {
  const response = await eCertifApi.get(
    `${modelesLettreURI}/dictionnaire/${modelType}`
  );
  return response.data;
};

export const putModeleLettreRequest = async (modeleLettre: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${modelesLettreURI}/${modeleLettre.get("uuid")}`,
    modeleLettre,
    xsrfHeader
  );
  return response.data;
};

export const putResetModeleLettreRequest = async (modeleLettreUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${modelesLettreURI}/${modeleLettreUuid}/par-defaut`,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateModeleLettreRequest = async (
  modeleLettreUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${modelesLettreURI}/${modeleLettreUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateModeleLettreRequest = async (
  modeleLettreUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${modelesLettreURI}/${modeleLettreUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};
