import {
  Stack,
  TextField,
  DefaultButton,
  PrimaryButton,
  Text,
  Link,
  MessageBar,
  Label,
} from "@fluentui/react";
import { useState } from "react";
import { toast } from "react-toastify";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import ErrorMessage from "../../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import useTheme from "../../../../Common/useTheme";
import { API_URL } from "../../../../config/config";
import { verifyOfSIRET } from "../../../../services/ofServices";
import {
  commonInputStyles,
  commonButtonStyles,
  MessageBarStyles,
} from "../../../../styles";
import validations from "../../../../utils/validations";
import FileInput from "../../../../Common/FileInput/FileInput";
import Can from "../../../../Common/Can/Can";
import { branchAccessEnum } from "../../../../config/accessEnum";
import useAccessSettings from "../../../../hooks/branchSettings/useAccessSettings";

export function AddOfForm({ onAddOf, onCreateOfWithHabilitation }: any) {
  const { actions: accessActions } = useAccessSettings();
  const { actions } = useCustomModal();
  const { primaryColor } = useTheme();
  const [searchResult, setSearchResult]: any = useState([]);
  const [errors, setErrors]: any = useState({});
  const [existenceVerified, setExistenceVerified] = useState(false);
  const [
    isDemandeHabilitationOnOfExistant,
    setIsDemandeHabilitationOnOfExistant,
  ] = useState(false);
  const canCreateNewOf =
    searchResult.length == 0 && existenceVerified && !errors.siret;
  const [newOf, setNewOf] = useState({
    raison_sociale: "",
    siret: "",
    adresse: "",
    complement_adresse: "",
    code_postal: "",
    region: "",
    ville: "",
    documents: null,
  });

  const handleChangeNewOf = (name: any, value: any) => {
    if (name == "documents") {
      const newOfDemandeHabilitation = {
        ...newOf,
        [name]: [{ file: value, type: "convention" }],
      };
      setNewOf((f: any) => newOfDemandeHabilitation);
    } else {
      if (name == "siret") {
        setExistenceVerified(false);
      }
      setErrors({ ...errors, [name]: "" });
      setNewOf({ ...newOf, [name]: value });
    }
  };

  const validateOf = (of: any) => {
    let validationErrors: any = {};
    const validationOf = validations.validateOf(of);
    if (validationOf?.error) {
      validationOf.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message;
      });
    }
    setErrors(validationErrors);
    let isValid = Object.keys(validationErrors).length === 0;
    if (!isValid) {
      toast.error("Le formulaire comporte des erreurs.");
    }
    return isValid;
  };

  const onSaveOf = (data?: any) => {
    if (data) {
      var ofExistant = {
        ...data,
        adresse: data.adresse?.adresse,
        complement_adresse: data.adresse?.complement_adresse,
        code_postal: data.adresse?.code_postal,
        region: data.adresse?.region,
        ville: data.adresse?.ville,
      };
    }
    if (validateOf(ofExistant ?? newOf)) {
      onAddOf(data || newOf);
      actions.closeModal();
    }
  };

  const onSaveDemandeHabilitationOf = (data: any) => {
    if (validateOf(data)) {
      onCreateOfWithHabilitation(data);
      actions.closeModal();
    }
  };

  const onSelectOf = (data: any) => {
    if (
      accessActions.canI({
        action: branchAccessEnum.HABILITATION_SIMPLE_OF,
      }).granted
    ) {
      setIsDemandeHabilitationOnOfExistant(true);
      setNewOf({
        ...data,
        adresse: data.adresse?.adresse,
        complement_adresse: data.adresse?.complement_adresse,
        code_postal: data.adresse?.code_postal,
        region: data.adresse?.region,
        ville: data.adresse?.ville,
      });
      return;
    }
    onSaveOf(data);
  };

  const onVerifySiret = async () => {
    setErrors({});
    try {
      const data: any = await verifyOfSIRET(newOf.siret);

      setSearchResult(data?.uuid ? [data] : []);
      setExistenceVerified(true);
    } catch (err: any) {
      if (err?.response?.data?.message) {
        setErrors({ siret: err.response.data.message });
      }
    }
  };

  const getBlocsInfoMessage = () => {
    if (searchResult.length > 0) {
      return "Un OF possédant ce SIRET existe déjà en base de données : ";
    } else {
      return "Aucun OF avec ce SIRET n'a été trouvé en base de données. Vous pouvez en créer un en renseignant les champs ci-dessous.";
    }
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Text style={{ color: "#979797" }}>
        Sélectionnez ou ajoutez un organisme de formation afin de pouvoir y
        accéder dans les parties prenantes de la candidature.
      </Text>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          type="text"
          label="SIRET (14 chiffres) :"
          placeholder="Ex: 12312312312312"
          required
          name="siret"
          onChange={(e: any, newValue: any) => {
            if (!newValue || newValue.length <= 14) {
              handleChangeNewOf("siret", e.target.value);
            }
          }}
          value={newOf.siret}
        />
        <ErrorMessage errorMessage={errors.siret} />
      </Stack.Item>

      <Stack.Item>
        <DefaultButton
          onClick={onVerifySiret}
          styles={commonButtonStyles.defaultButton}
          style={{
            color: primaryColor,
            borderColor: primaryColor,
            maxWidth: 270,
          }}
        >
          Vérifier l'existence du SIRET
        </DefaultButton>
      </Stack.Item>

      <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
        {existenceVerified && searchResult.length === 0 && (
          <MessageBar styles={MessageBarStyles}>
            {getBlocsInfoMessage()}
          </MessageBar>
        )}
        {searchResult.length > 0 && (
          <>
            {searchResult.map((item: any, index: any) => (
              <Stack
                horizontal
                verticalAlign="center"
                style={{ marginLeft: 30, marginBottom: 10 }}
                key={`profil${index}`}
              >
                <p
                  style={{
                    color: "grey",
                    fontStyle: "italic",
                    margin: "5px 0px 0px",
                    fontSize: 16,
                  }}
                >
                  {item.raison_sociale} - {item.siret}
                </p>
                <Link
                  onClick={() => onSelectOf(item)}
                  styles={commonButtonStyles.buttonLink}
                  style={{ marginLeft: 20, marginTop: 5 }}
                >
                  Cliquez ici pour utiliser cet OF existant
                </Link>
              </Stack>
            ))}
          </>
        )}
      </Stack.Item>
      {(canCreateNewOf || isDemandeHabilitationOnOfExistant) && (
        <>
          <Stack.Item>
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Raison sociale :"
              type="text"
              placeholder="Ex: Ma société"
              name="raison_sociale"
              onChange={(e: any) =>
                handleChangeNewOf("raison_sociale", e.target.value)
              }
              value={newOf.raison_sociale}
              errorMessage={errors.raison_sociale}
              disabled={isDemandeHabilitationOnOfExistant}
            />
          </Stack.Item>

          <Stack.Item>
            <TextField
              required={true}
              styles={commonInputStyles.textField}
              label="Adresse :"
              type="text"
              placeholder="Rue, adresse de boite postale, nom de société"
              name="adresse"
              onChange={(e: any) =>
                handleChangeNewOf("adresse", e.target.value)
              }
              value={newOf.adresse}
              errorMessage={errors.adresse}
              disabled={isDemandeHabilitationOnOfExistant}
            />
          </Stack.Item>

          <Stack.Item>
            <TextField
              styles={commonInputStyles.textField}
              label="Complément d'adresse :"
              type="text"
              placeholder="Appartement, suite, l'unité, batîment, étage, etc"
              name="complement_adresse"
              onChange={(e: any) =>
                handleChangeNewOf("complement_adresse", e.target.value)
              }
              value={newOf.complement_adresse}
              errorMessage={errors.complement_adresse}
              disabled={isDemandeHabilitationOnOfExistant}
            />
          </Stack.Item>

          <Stack.Item>
            <SelectFieldAsync
              required={true}
              label="Code postal :"
              placeholder="Recherchez un code postal en tapant les premiers chiffres"
              resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?code_postal=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.code_postal + " | " + item.nom_commune
              }
              renderValue={(item: any) => item}
              dataIndex="uuid"
              onChange={(e: any) => {
                handleChangeNewOf("code_postal", e.target.value.code_postal);
                setNewOf((prev: any) => ({
                  ...prev,
                  region: e.target.value.nom_region,
                  ville: e.target.value.nom_commune,
                }));
              }}
              defaultValue=""
              handleResponse={(response: any, terms: any) => {
                return response.data.data.length > 0
                  ? response.data.data
                  : [{ code_postal: terms, nom_commune: "Inconnu" }];
              }}
              name="code_postal"
              value={newOf.code_postal}
              errorMessage={errors.code_postal}
              disabled={isDemandeHabilitationOnOfExistant}
            />
          </Stack.Item>

          {newOf.code_postal && (
            <>
              <Stack.Item>
                <SelectFieldAsync
                  required={true}
                  label="Région :"
                  placeholder="Veuillez sélectionner ou inscrire votre région"
                  resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?nom_region=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => item}
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  onChange={(e: any) =>
                    handleChangeNewOf("region", e.target.value)
                  }
                  defaultValue=""
                  fetchOnLoad={true}
                  handleResponse={(response: any, terms: any) => {
                    const regionsSingle = Array.from(
                      new Set(response.data.data.map((x: any) => x.nom_region))
                    );

                    return regionsSingle.length > 0 ? regionsSingle : [terms];
                  }}
                  name="region"
                  value={newOf.region}
                  errorMessage={errors.region}
                  disabled={isDemandeHabilitationOnOfExistant}
                />
              </Stack.Item>

              <Stack.Item>
                <SelectFieldAsync
                  required={true}
                  label="Ville :"
                  placeholder="Veuillez sélectionner ou inscrire votre ville"
                  resourceURI={`${API_URL}api/references/ref-geographiques/chercher-villes`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?nom_commune=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => item}
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  onChange={(e: any) =>
                    handleChangeNewOf("ville", e.target.value)
                  }
                  defaultValue=""
                  fetchOnLoad={true}
                  handleResponse={(response: any, terms: any) => {
                    const citySingle = Array.from(
                      new Set(response.data.data.map((x: any) => x.nom_commune))
                    );

                    return citySingle.length > 0 ? citySingle : [terms];
                  }}
                  name="ville"
                  value={newOf.ville}
                  errorMessage={errors.ville}
                  disabled={isDemandeHabilitationOnOfExistant}
                />
              </Stack.Item>
            </>
          )}

          <Can I={branchAccessEnum.HABILITATION_SIMPLE_OF}>
            <Stack.Item>
              <Label styles={commonInputStyles.label}>
                Convention d'habilitation :
              </Label>
              <FileInput
                name="documents"
                nameDisplay="convention_habilitation.pdf"
                file={newOf.documents ? (newOf.documents[0] as any)?.file : []}
                existingFileURL={
                  newOf.documents ? (newOf.documents[0] as any)?.file_url : null
                }
                onUpdateFile={(e: any) =>
                  handleChangeNewOf("documents", e.target.value)
                }
                acceptedFileTypes={["PDF"]}
              />
            </Stack.Item>
          </Can>
        </>
      )}
      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>
        {(canCreateNewOf || isDemandeHabilitationOnOfExistant) && (
          <>
            {accessActions.canI({
              action: branchAccessEnum.HABILITATION_SIMPLE_OF,
            }).granted ? (
              <PrimaryButton
                onClick={() => onSaveDemandeHabilitationOf(newOf)}
                styles={commonButtonStyles.buttonSubmit}
                iconProps={{ iconName: "Add" }}
              >
                Demander l'habilitation de l'OF
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={() => onSaveOf()}
                styles={commonButtonStyles.buttonSubmit}
                iconProps={{ iconName: "Add" }}
              >
                Ajouter l'OF
              </PrimaryButton>
            )}
          </>
        )}
      </Stack.Item>
    </Stack>
  );
}
