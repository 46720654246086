export const strings = {
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre'
    ],
  
    shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  
    shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    goToToday: "Aujourd'hui",
    prevMonthAriaLabel: 'Mois précédent',
    nextMonthAriaLabel: 'Mois suivant',
    prevYearAriaLabel: 'Année précédente',
    nextYearAriaLabel: 'Année suivante'
  }