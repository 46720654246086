import FileDisplay from "../FileDisplay/FileDisplay";
import FileDropZone from "./FileDropZone/FileDropZone";

const WORD = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
const EXCEL = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const PDF = ["application/pdf"];
const PNG = ["image/png"];
const JPG = ["image/jpg"];
const JPEG = ["image/jpeg"];

export const fileTypes: any = {
  WORD: WORD,
  EXCEL: EXCEL,
  PDF: PDF,
  PNG: PNG,
  JPG: JPG,
  JPEG: JPEG,
  ANY: [...WORD, ...EXCEL, ...PDF, ...PNG, ...JPG, ...JPEG],
};

const FileInput = ({
  file = [],
  name,
  onUpdateFile,
  onDeleteFile = () => { },
  onRemoveMultipleFile = () => { },
  acceptedFileTypes = [],
  existingFileURL = "",
  existingFilesURL = [],
  multipleFiles = false,
  disabled = false,
  readOnly = false,
}: any) => {
  const acceptTypes: any = [];
  acceptedFileTypes.forEach((ft: any) => {
    if (!fileTypes[ft]) {
      alert(`${ft} n'est pas un type de document valide`);
      return false;
    }
    acceptTypes.push(...fileTypes[ft]);
  });

  const onChangeFile = (newfile: any) => {
    if (multipleFiles) {
      onUpdateFile({
        target: {
          name,
          value: [...file, ...newfile],
        },
      });
    } else {
      onUpdateFile({
        target: {
          name,
          value: newfile,
        },
      });
    }
  };

  const onRemoveFile = (fileKey: any, fromArray?: string) => {
    if (typeof fileKey == "number") {
      // alert(fileKey);
      onRemoveMultipleFile(fileKey, fromArray);
    } else {
      onUpdateFile({
        target: {
          name,
          value: [],
        },
      });
    }
    onDeleteFile();
  };

  if (multipleFiles && (file.length > 0 || existingFilesURL.length > 0)) {
    return (
      <>
        {file?.map((theFile: any, theFileKey: any) => {
          return (
            <FileDisplay
              file={theFile}
              onRemoveFile={() => onRemoveFile(theFileKey, `file`)}
              existingFileURL={""}
              disabled={disabled}
              readOnly={readOnly}
            />
          );
        })}
        {existingFilesURL?.map((theFile: any, theFileKey: any) => {
          return (
            <FileDisplay
              file={null}
              onRemoveFile={() => onRemoveFile(theFileKey, `existingFilesURL`)}
              existingFileURL={theFile}
              disabled={disabled}
              readOnly={readOnly}
            />
          );
        })}
        {!disabled && (
          <FileDropZone
            onFileDrop={onChangeFile}
            authorizedTypes={acceptTypes}
            acceptedFileTypes={acceptedFileTypes}
            multipleFiles={multipleFiles}
          />
        )}
      </>
    );
  }

  return file.length > 0 || existingFileURL ? (
    <FileDisplay
      file={file[0]}
      onRemoveFile={onRemoveFile}
      existingFileURL={existingFileURL}
      disabled={disabled}
      readOnly={readOnly}
    />
  ) : disabled ? null : (
    <FileDropZone
      onFileDrop={onChangeFile}
      authorizedTypes={acceptTypes}
      acceptedFileTypes={acceptedFileTypes}
      multipleFiles={multipleFiles}
    />
  );
};

export default FileInput;
