const blockCompetenceAdapters = {
    transformForAPI: (data: any) => {

        let transformed: any = {
            ...data,
        }

        return transformed
    },
    transformForBO: (data: any) => {

        let transformed: any = {
            ...data,
        }

        return transformed
    }
}

export default blockCompetenceAdapters;