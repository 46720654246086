import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { MessageBar } from "@fluentui/react";

export const GroupeOfProfileForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
  userProfil,
}: SubFormsProps) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  let disabledUUIDs: any = [];
  if (userProfil?.referents?.length > 0) {
    const referentOfCurrentModel = userProfil.referents.filter(
      (r: any) =>
        r.pivot.model_type === "groupe_of" &&
        r.sur_les_branches.find((b: any) => b.id === userSelectedBranch.id)
    );

    disabledUUIDs = referentOfCurrentModel.map((r: any) => r.uuid);
  }

  return (
    <ProfileAddionalInformations
      name="GROUPE_OF"
      sectionTitle="Informations du profil Groupe d'OF"
      watchRoles={watchRoles}
    >
      <>
        {" "}
        {disabledUUIDs.length > 0 && (
          <MessageBar
            messageBarType={1}
            messageBarIconProps={{ iconName: "Warning" }}
          >
            Vous ne pouvez pas changer le groupe d'OF de ce profil car cet
            utilisateur est référent de ce groupe d'OF sur cette branche.
          </MessageBar>
        )}
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Sélectionnez un groupe d'OF lié à cet utilisateur :"
              placeholder="Recherchez un groupe d'OF par SIRET ou Raison sociale (ex : 12312312312312 ou Groupe 1)"
              resourceURI={`${API_URL}api/administration/groupe-ofs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siren}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siren}
                      </span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={null}
              errorMessage={
                ((errors.groupeOFProfile as any)?.groupOf as any)?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              required
              disabled={disabledUUIDs.length > 0}
            />
          )}
          control={control}
          name="groupeOFProfile.groupOf"
        />
      </>
    </ProfileAddionalInformations>
  );
};
