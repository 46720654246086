import { Stack, Link } from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import { userAccessEnum } from "../../config/accessEnum";
import useAuth from "../../hooks/useAuth";
import useLabelSettings from "../../Pages/LabelSettingsDetail/useLabelSettings";
import Can from "../Can/Can";
import footerStyles from "./Footer.styles";
import { useAtom } from "jotai";
import { isOfflineAtom } from "../../hooks/useNav";

function Footer({
  linkToCGU = "/CGU",
  linkToLegalMention = "/mentions_legales",
  linkToNeedHelp = "/demande-aide",
}) {
  const [isOffline] = useAtom(isOfflineAtom);
  const { isAuth } = useAuth();
  const { labels } = useLabelSettings();

  if (isOffline)
    return (
      <div style={{ position: "relative", zIndex: "9", height: 80 }}></div>
    );
  return (
    <div style={{ position: "relative", zIndex: "9" }}>
      <Stack
        styles={footerStyles.containerStyle}
        style={{
          background: "#fbfbfb",
        }}
        horizontal
        wrap
      >
        <Stack.Item styles={footerStyles.itemStyle}>
          <Link
            to={isAuth ? "admin/cgu" : linkToCGU}
            as={RouterLink}
            styles={footerStyles.linkStyle}
          >
            Conditions générales d'utilisation
          </Link>
        </Stack.Item>

        <Stack.Item styles={footerStyles.itemStyle}>
          <Link
            to={isAuth ? "admin/mentions_legales" : linkToLegalMention}
            as={RouterLink}
            styles={footerStyles.linkStyle}
          >
            Mentions légales
          </Link>
        </Stack.Item>

        <Can I={isAuth ? userAccessEnum.DEMANDER_AIDE_BRANCHE : "*"}>
          <Stack.Item styles={footerStyles.itemStyle}>
            <Link
              to={isAuth ? "/admin/demande_aide" : linkToNeedHelp}
              as={RouterLink}
              styles={footerStyles.linkStyle}
            >
              {isAuth
                ? labels.FICHE_INDIVIDUELLE_BESOIN_D_AIDE
                : "Besoin d'aide ?"}
            </Link>
          </Stack.Item>
        </Can>
      </Stack>
    </div>
  );
}

export default Footer;
