import { Stack } from "@fluentui/react";

const CandidatureInfoHeaderWrapper = ({ children, className = "" }: any) => {
  return (
    <Stack
      style={{
        padding: 20,
        borderRadius: 10,
        backgroundColor: "#f5f5f5",
        boxShadow: " rgb(193, 193, 193) 0px 3px 6px -4px",
        border: "1px solid rgb(228, 228, 228)",
        marginBottom: 15,
      }}
      className={className}
    >
      {children}
    </Stack>
  );
};

export default CandidatureInfoHeaderWrapper;
