import { Controller, useFormContext } from "react-hook-form";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { MessageBar } from "@fluentui/react";

export const OfRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
  userProfil,
}: SubFormsProps) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  let disabledUUIDs: any = [];

  if (userProfil?.referents?.length > 0) {
    const referentOfCurrentModel = userProfil.referents.filter(
      (r: any) =>
        r.pivot.model_type === "organisme_de_formation" &&
        r.sur_les_branches.find((b: any) => b.id === userSelectedBranch.id)
    );

    disabledUUIDs = referentOfCurrentModel.map((r: any) => r.uuid);
  }

  return (
    <ProfileAddionalInformations
      name="OF"
      sectionTitle="Informations du profil OF"
      watchRoles={watchRoles}
    >
      <>
        {disabledUUIDs.length > 0 && (
          <MessageBar
            messageBarType={1}
            messageBarIconProps={{ iconName: "Warning" }}
          >
            Vous ne pouvez pas changer certains OF de ce profil car cet
            utilisateur est référent de ces OF sur cette branche.
          </MessageBar>
        )}
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Sélectionnez un ou plusieurs OF liés à cet utilisateur :"
              placeholder="Recherchez des OF par SIRET ou Raison sociale (ex : 123123123123123 ou OF1)"
              resourceURI={`${API_URL}api/administration/ofs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={[]}
              errorMessage={((errors.ofProfile as any)?.ofs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
              required
              unremovableItemsFn={(item: any) =>
                disabledUUIDs.includes(item.uuid)
              }
            />
          )}
          control={control}
          name="ofProfile.ofs"
        />
      </>
    </ProfileAddionalInformations>
  );
};
