import Page from "../../Common/Page/Page";

const Unauthorized = () => {
  return (
    <Page title="Accès non autorisé" explain="Votre rôle ne vous permet pas d'accéder à cette page">
      <p>Vous n'avez pas accès à cette page</p>
    </Page>
  );
};

export default Unauthorized;
