import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./config/axiosConfig";
import CustomModal from "./Common/Overlays/CustomModal/CustomModal";
import SideNav from "./Common/SideNav/SideNav";
import Footer from "./Common/Footer/Footer";
import { Stack, ThemeProvider } from "@fluentui/react";
import { routesConfig } from "./config/routesConfig";
import { ToastContainer } from "react-toastify";
import CustomPanel from "./Common/Overlays/CustomPanel/CustomPanel";
import { useEffect } from "react";

import useInitialize from "./hooks/useInitialize";
import useTheme from "./Common/useTheme";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "./atoms/branchAtom";

import SynchronizationWidget from "./Common/SynchronizationWidget/SynchronizationWidget";
import { isOfflineAtom } from "./hooks/useNav";
import { CHANGE_IS_OFFLINE } from "./config/offlineConfig";
import OfflineBanner from "./Common/OfflineBanner/OfflineBanner";
import CandidatureNeedActionWidget from "./Common/CandidatureNeedActionWidget/CandidatureNeedActionWidget";

import WorkflowModal from "./Common/Overlays/CustomModal/WorkflowModal";

import useConsoleLog from "./hooks/useConsoleLog";

function App() {
  useConsoleLog();
  const [userSelectedBranch]: any = useAtom(userSelectedBranchAtom);
  const { auth, initializeApp } = useInitialize();
  const { primaryColor, customTheme, generateTheme } = useTheme();
  const [isOffline, setIsOffline] = useAtom(isOfflineAtom);

  useEffect(() => {
    CHANGE_IS_OFFLINE(isOffline);
    if (isOffline) {
      window.addEventListener("beforeunload", onPageRefresh);
    } else {
      window.removeEventListener("beforeunload", onPageRefresh);
    }
  }, [isOffline]);

  const onPageRefresh = (event: any) => {
    event.preventDefault();
    event.returnValue =
      "Si vous actualisez la page, assurez-vous d'avoir une connexion internet active. Sinon vous perdrez accès à l'application.";
  };

  useEffect(() => {
    generateTheme(primaryColor);
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated) {
      initializeApp();
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    if (userSelectedBranch?.couleur_primaire) {
      generateTheme(userSelectedBranch?.couleur_primaire);
    } else {
      generateTheme();
    }
  }, [userSelectedBranch]);

  if (!customTheme) return null;

  return (
    <ThemeProvider theme={customTheme as any}>
      <div className="App">
        <BrowserRouter>
          <OfflineBanner />
          <Stack horizontal>
            <SideNav routes={routesConfig} />
            <Stack className="contentContainer">
              <AppRoutes />
              <Footer />
            </Stack>
          </Stack>
          <ToastContainer />
          <WorkflowModal />
          <CustomModal />
          <CustomPanel />
          <SynchronizationWidget />
          <CandidatureNeedActionWidget />
        </BrowserRouter>
        {/* <Stack horizontal>
          <Stack style={{backgroundColor:'yellow', width:400, height:'100vh'}}>

          </Stack>
          <Stack style={{backgroundColor:'blue', width:'100%',height:'100vh'}}>

          </Stack>
        </Stack> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
