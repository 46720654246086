const BUTTON_BIG_STYLE = {
  fontSize: "17px",
  borderRadius: 3,
  minHeight: 42,
  padding: "0 10px",
  fontWeight: "400",
};
export const BUTTON_SMALL_STYLE = {
  //outline: "1px solid",
  padding: "0 15px",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 2,

  fontSize: "16px",
  fontWeight: "400",
  minHeight: 25,
  backgroundColor: "transparent",
};

const commonButtonStyles = {
  buttonSubmit: {
    root: {
      ...BUTTON_BIG_STYLE,
    },
  },
  defaultButton: {
    root: {
      ...BUTTON_BIG_STYLE,
    },
  },
  buttonLink: {
    root: {
      ...BUTTON_SMALL_STYLE,
      height: "auto",
      padding: "0 3px",
      justifyContent: "start",
      fontSize: 14,
      color: "rgb(0, 120, 212)",
      ":hover": {
        color: "rgb(16, 110, 190)",
      },
      ":focus": {
        color: "rgb(16, 110, 190)",
      },
    },
  },
  deleteButtonLink: {
    root: {
      ...BUTTON_SMALL_STYLE,
      fontSize: 14,
      color: "rgb(224, 5, 0)",

      ":hover": {
        color: "rrgb(209, 3, 0)",
      },
      ":focus": {
        color: "rgb(209, 3, 0)",
      },
    },
  },

  activeButtonLink: {
    root: {
      ...BUTTON_SMALL_STYLE,
      color: "rgb(102, 187, 106)",

      ":hover": {
        color: "rgb(56, 142, 60)",
      },
      ":focus": {
        color: "rgb(56, 142, 60)",
      },
    },
  },
  disableButtonLink: {
    root: {
      ...BUTTON_SMALL_STYLE,

      color: "rgb(244, 67, 54)",

      ":hover": {
        color: "rgb(198, 55, 45)",
      },
      ":focus": {
        color: "rgb(198, 55, 45)",
      },
    },
  },
  deleteButton: {
    root: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "rgb(224, 5, 0)",
      borderColor: "rgb(224, 5, 0)",
      color: "white",
    },
    rootHovered: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "rgb(209, 3, 0)",
      borderColor: "rgb(209, 3, 0)",
      color: "white",
    },
    rootFocused: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "rgb(209, 3, 0)",
      borderColor: "rgb(209, 3, 0)",
      color: "white",
    },
    rootPressed: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "rgb(201, 3, 0)",
      borderColor: "rgb(201, 3, 0)",
      color: "white",
    },
  },
  validateButton: {
    root: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "#0AA204",
      borderColor: "#0aa204",
      color: "white",
    },
    rootHovered: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "#0da204",
      borderColor: "#0da204",
      color: "white",
    },
    rootFocused: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "#0da204",
      borderColor: "#0da204",
      color: "white",
    },
    rootPressed: {
      ...BUTTON_BIG_STYLE,
      backgroundColor: "#10a204",
      borderColor: "#10a204",
      color: "white",
    },
  },
};

export default commonButtonStyles;
