import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { BsFillChatDotsFill } from "react-icons/bs";
import SectionTitle from "../../../../Common/SectionTitle/SectionTitle";
import useTheme from "../../../../Common/useTheme";
import useConstants from "../../../../hooks/useConstants";
import { BUTTON_SMALL_STYLE } from "../../../../styles/CommonButton.styles";
import tools from "../../../../utils/tools";
import useMiseEnConfirmite from "./useMiseEnConfirmite";
import useInfoProfilUser from "../../../../hooks/useInfoProfilUser";

const ListeMECS = ({ mecs }: any) => {
  const { constants }: any = useConstants();
  const items: any = mecs || [];
  const { dangerColor } = useTheme();
  const { mecActions, deletedAskedMEC } = useMiseEnConfirmite();
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  const columns = [
    {
      key: "1",
      name: "Étape",
      fieldName: "candidature_statut",
      minWidth: 50,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <strong>
          {
            (
              tools.findIn(
                constants.CANDIDATURES.STATUTS,
                item.candidature_statut,
                "id"
              ) as any
            ).text
          }
        </strong>
      ),
    },
    {
      key: "2",
      name: "Demandée par Admin ?",
      fieldName: "demande_par",
      onRender: (item: any) =>
        item ? (
          <div>
            <div>
              {item.commentaire_demandeur ? (
                <Text style={{ color: "green" }}>Oui</Text>
              ) : (
                <Text>Non</Text>
              )}
            </div>
            {item.commentaire_demandeur && (
              <div
                style={{
                  color: "grey",
                  whiteSpace: "break-spaces",
                }}
              >
                <BsFillChatDotsFill style={{ marginRight: 3 }} />
                {item.commentaire_demandeur}
              </div>
            )}
            {isAnyKindOfAdmin &&
              item.commentaire_demandeur &&
              !item.corrige_par && (
                <DefaultButton
                  onClick={() => mecActions.onCancelAskMEC(item.uuid)}
                  style={{
                    ...BUTTON_SMALL_STYLE,
                    fontSize: 14,
                    padding: "0px 5px",
                    height: 24,
                  }}
                >
                  Annuler la demande
                </DefaultButton>
              )}
          </div>
        ) : null,
      minWidth: 50,
      isResizable: true,
    },
    {
      key: "3",
      name: "Demandée le",
      fieldName: "demande_le",
      onRender: (item: any) =>
        item.demande_le ? tools.formatDate(item.demande_le) : null,
      minWidth: 100,
      isResizable: true,
    },
    {
      key: "4",
      name: "Modifié par",
      fieldName: "corrige_par",
      onRender: (item: any) =>
        item.corrige_par ? (
          <div>
            <div>{tools.getFullname(item.corrige_par)}</div>
            {item.commentaire_correcteur && (
              <div
                style={{
                  color: "grey",
                  whiteSpace: "break-spaces",
                }}
              >
                <BsFillChatDotsFill style={{ marginRight: 3 }} />
                {item.commentaire_correcteur}
              </div>
            )}
          </div>
        ) : (
          <Text style={{ color: dangerColor }}>En attente de modification</Text>
        ),
      minWidth: 180,
      isResizable: true,
    },
    {
      key: "5",
      name: "Modifiée le",
      fieldName: "corrige_le",
      onRender: (item: any) =>
        item.corrige_le ? tools.formatDate(item.corrige_le) : null,
      minWidth: 100,
      isResizable: true,
    },
  ];

  return (
    <Stack>
      <SectionTitle title="Mises en conformité" style={{ marginBottom: 0 }} />
      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={items.filter((f: any) => !deletedAskedMEC.includes(f.uuid))}
        columns={columns}
        selectionMode={SelectionMode.none}
      />
    </Stack>
  );
};

export default ListeMECS;
