import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import CreateLink from "../../Common/CreateLink/CreateLink";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import { commonButtonStyles } from "../../styles/index";
import tools from "../../utils/tools";

import OfListFilters from "./OfListFilters/OfListFilters";
import useOfList from "./useOfList";

import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import { entityTypes } from "../../Common/ImportEntities/useImportEntities";
import LoadLink from "../../Common/LoadLink/LoadLink";
import OpenImportButton from "../../Common/OpenImportButton/OpenImportButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { userAccessEnum } from "../../config/accessEnum";
import useTheme from "../../Common/useTheme";
import Can from "../../Common/Can/Can";

function OfList() {
  const {
    ofs,
    ofProps,
    optionsFilters,
    actions: actionsPage,
    isLoadingExportOf,
  } = useOfList();
  const { actions, isToggleActivationUuid } = ofProps;
  const { primaryColor } = useTheme();
  const items = ofs?.data || [];

  const columns = [
    {
      key: "1",
      name: "Raison sociale",
      fieldName: "raison_sociale",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.raison_sociale}</Text>,

      onColumnClick: () => {
        actionsPage.sortColumn("raison_sociale");
      },
      ...tools.sorted(optionsFilters.sort, "raison_sociale"),
    },
    {
      key: "2",
      name: "SIRET",
      fieldName: "siret",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.siret}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("siret");
      },
      ...tools.sorted(optionsFilters.sort, "siret"),
    },
    {
      key: "3",
      name: "Groupe lié",
      fieldName: "groupe_of",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.groupe_of?.raison_sociale}</Text>,
    },
    {
      key: "4",
      name: "Actif",
      fieldName: "actif",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <BooleanValue value={item["actif"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn("actif");
      },
      ...tools.sorted(optionsFilters.sort, "actif"),
    },
    {
      key: "5",
      name: "Multibranche",
      fieldName: "interbranches",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.interbranches} />,
    },
    {
      key: "6",
      name: "Qualiopi",
      fieldName: "qualiopi",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      //showSortIconWhenUnsorted: true,
      //onRender: (item: any) => <BooleanValue value={item["qualiopi"]} />,
      // onColumnClick: () => {
      //   actionsPage.sortColumn('qualiopi')
      // },
      // ...tools.sorted(optionsFilters.sort, 'qualiopi')
    },
    {
      key: "7",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Link
              to={`/admin/of/${item.uuid}`}
              as={RouterLink}
              style={{ marginLeft: 5, marginTop: 5 }}
              styles={commonButtonStyles.buttonLink}
            >
              Editer
            </Link>
            <Link
              onClick={() => actionsPage.openDeleteOfModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>

            <LoadLink
              styles={
                item.actif
                  ? commonButtonStyles.disableButtonLink
                  : commonButtonStyles.activeButtonLink
              }
              onClick={() => actions.toggleOfActivation(item)}
              isLoading={isToggleActivationUuid == item.uuid}
              text={item.actif ? "Désactiver" : "Activer"}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des Organismes de formation"
        explain="Veuillez gérer les organismes de formations rattachés à votre branche"
      >
        <Stack
          horizontal
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          <CreateLink text="Créer un OF" to="/admin/of/creer" />
          <Can I={userAccessEnum.EXPORTER_UTILISATEURS_ET_ENTITES}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les données OF"
                isLoading={isLoadingExportOf}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportOfs}
                primary={false}
              />
            </div>
          </Can>
          <OpenImportButton entityType={entityTypes.OF} />
        </Stack>

        <OfListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={ofs?.meta.current_page || 1}
          totalPages={Math.ceil(ofs?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default OfList;
