import { useQuery } from "react-query";
import { getCertificationsRequest } from "../../services/certificationServices";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { useAtom } from "jotai";
import useCertification from "../../hooks/certifications/useCertification";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { ICertificationFilters } from "../../interfaces/certificationInterface";
import { useForm } from "react-hook-form";
import { stateList } from "../../fakeData/State.data";
import { atomWithReset } from "jotai/utils";
import CreateCertificationDomaine from "./CreateCertificationDomaine/CreateCertificationDomaine";

export const initialFilters = {
  libelle: "",
  active: stateList[0],
  type: { key: 0, text: "Tous les types" },
  type_libelle: "",
  blocs_competences: [],
  domaines: [],
  certifications_limites: stateList[0],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useCertificationList() {
  const certificationProps = useCertification();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm<ICertificationFilters>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: certifications, isLoading: isLoadingCertifications } = useQuery(
    ["certifications", optionsFilters, certificationProps.userSelectedBranch],
    () => {
      return getCertificationsRequest(
        filters,
        optionsFilters,
        certificationProps.userSelectedBranch.id
      );
    },
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteCertificationModal: (certification: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={certification.libelle}
            deleteFunction={() =>
              certificationProps.actions.deleteCertification(certification.uuid)
            }
          />
        ),
      });
    },
    openCreateCertificationModal: () => {
      modalActions.openModal({
        header: `Création d'une certification sur un domaine`,
        body: <CreateCertificationDomaine />,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
  };

  return {
    certifications,
    certificationProps,
    isLoadingCertifications,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
  };
}

export default useCertificationList;
