import { DatePicker, DefaultButton, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import TextResult from "../../../Common/TextResult/TextResult";
import { strings } from "../../../config/datePickerConfig";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import useValidationCandidatureList from "../useValidationCandidatureList";
import { API_URL } from "../../../config/config";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { useState } from "react";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";

function ValidationCandidatureListFilters() {
  const { validationCandidatureList, actions, isLoading, reactHookProps } =
    useValidationCandidatureList();
  const [showFilters, setShowFilters] = useState(false);
  const { handleSubmit, control, setValue } = reactHookProps;

  const totalWaitingCandidature = validationCandidatureList?.meta.total || 0;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Libellé du jury :"
                  type="text"
                  placeholder="Ex: Jury"
                  {...field}
                />
              )}
              defaultValue={""}
              control={control}
              name="libelle_jury"
            />
          </Stack.Item>
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  ariaLabel="dd/mm/yyyy"
                  value={value ? new Date(value) : undefined}
                  onSelectDate={(date: any) =>
                    date
                      ? setValue(
                          name,
                          `${date.getFullYear()}-${
                            date.getMonth() + 1
                          }-${date.getDate()}`
                        )
                      : null
                  }
                  styles={commonInputStyles.textField}
                  label="Date du jury entre le"
                  strings={strings}
                  formatDate={(date: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              defaultValue={undefined}
              control={control}
              name="date_debut_jury"
            />
          </Stack.Item>
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  ariaLabel="dd/mm/yyyy"
                  styles={commonInputStyles.textField}
                  value={value ? new Date(value) : undefined}
                  onSelectDate={(date: any) =>
                    date
                      ? setValue(
                          name,
                          `${date.getFullYear()}-${
                            date.getMonth() + 1
                          }-${date.getDate()}`
                        )
                      : null
                  }
                  label="Et le"
                  strings={strings}
                  formatDate={(date: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              defaultValue={undefined}
              control={control}
              name="date_fin_jury"
            />
          </Stack.Item>

          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Entreprises :"
                      placeholder="Recherchez une entreprise (ex : entreprise)"
                      resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?nom=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.nom}
                      renderValue={(item: any) => item.nom}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="entreprises"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Organismes de formation :"
                      placeholder="Recherchez un OF (ex : OF)"
                      resourceURI={`${API_URL}api/administration/ofs/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?raison_sociale=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.raison_sociale}
                      renderValue={(item: any) => item.raison_sociale}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="ofs"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectFieldAsync
                      label="Evaluateur :"
                      placeholder="Recherchez un évaluateur par son nom"
                      resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?roles[]=EVALUATEUR&nom=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.nom + " " + item.prenom}
                      renderValue={(item: any) => item.nom + " " + item.prenom}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="evaluateur"
                />
              </Stack.Item>
            </>
          )}
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoading} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                styles={commonButtonStyles.defaultButton}
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalWaitingCandidature} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default ValidationCandidatureListFilters;
