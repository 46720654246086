import { DatePicker, TextField } from "@fluentui/react";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { strings } from "../../../config/datePickerConfig";
import { commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";

export default function InformationsPrincipalesFormSection() {
  const {
    control,
    formState: { errors },
    setValue,
    register,
    watch,
  } = useFormContext();

  useEffect(() => {
    register("date_jury");
    register("date_limite_inscription_candidat");
  }, []);

  return (
    <FormSection sectionTitle="Informations principales du jury">
      <Controller
        render={({ field }) => (
          <TextField
            styles={commonInputStyles.textField}
            label="Libellé du jury :"
            type="text"
            {...field}
            placeholder="Ex : Mon jury"
            errorMessage={(errors.libelle as any)?.message}
            required
          />
        )}
        control={control}
        defaultValue=""
        name="libelle"
      />

      <Controller
        render={({ field: { value, name } }) => (
          <DatePicker
            placeholder="dd/mm/yyyy"
            ariaLabel="dd/mm/yyyy"
            value={value}
            onSelectDate={(date: any) => (date ? setValue(name, date) : null)}
            styles={commonInputStyles.textField}
            label="Date du jury"
            isRequired
            strings={strings}
            formatDate={(date: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            allowTextInput
            parseDateFromString={(dateStr: any) =>
              tools.parseDateFromStringDatePicker(dateStr)
            }
          />
        )}
        control={control}
        name="date_jury"
      />
      <ErrorMessage errorMessage={(errors.date_jury as any)?.message} />

      <Controller
        render={({ field: { value, name } }) => (
          <DatePicker
            placeholder="dd/mm/yyyy"
            ariaLabel="dd/mm/yyyy"
            value={value}
            onSelectDate={(date: any) => (date ? setValue(name, date) : null)}
            styles={commonInputStyles.textField}
            isRequired
            label="Date d'accès pour les membres du jury :"
            strings={strings}
            formatDate={(date: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            allowTextInput
            parseDateFromString={(dateStr: any) =>
              tools.parseDateFromStringDatePicker(dateStr)
            }
          />
        )}
        control={control}
        name="date_acces_membres_jury"
      />
      <ErrorMessage
        errorMessage={(errors.date_acces_membres_jury as any)?.message}
      />

      <Controller
        render={({ field: { value, name } }) => (
          <DatePicker
            placeholder="dd/mm/yyyy"
            ariaLabel="dd/mm/yyyy"
            value={value}
            onSelectDate={(date: any) => (date ? setValue(name, date) : null)}
            styles={commonInputStyles.textField}
            isRequired
            label="Date limite d'inscription des candidats :"
            strings={strings}
            formatDate={(date: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            allowTextInput
            parseDateFromString={(dateStr: any) =>
              tools.parseDateFromStringDatePicker(dateStr)
            }
          />
        )}
        control={control}
        name="date_limite_inscription_candidat"
      />
      <ErrorMessage
        errorMessage={(errors.date_limite_inscription_candidat as any)?.message}
      />

      <Controller
        render={({ field }) => (
          <TextField
            styles={commonInputStyles.textField}
            label="Nombre de places :"
            type="number"
            {...field}
            placeholder="Ex : 12"
            errorMessage={(errors.nombre_places as any)?.message}
            required
          />
        )}
        control={control}
        defaultValue=""
        name="nombre_places"
      />

      <Controller
        render={({ field }) => (
          <TextField
            styles={commonInputStyles.textField}
            label="Lieu :"
            type="text"
            {...field}
            placeholder="Ex : Bureau A"
            errorMessage={(errors.lieu as any)?.message}
          />
        )}
        control={control}
        defaultValue=""
        name="lieu"
      />

      <FormAdress
        control={control}
        errors={errors}
        required={false}
        varToWatch={watch("code_postal") ?? false}
        moreDetail={false}
        onPrefillCityAndRegion={(data: any) => {
          setValue("ville", data.nom_commune);
          setValue("region", data.nom_region);
        }}
      />

      {/* <Controller
        render={({ field: { name, value, onBlur, ref } }) => (
          <Dropdown
            placeholder="Choisissez un domaine"
            label="Domaine du jury :"
            options={domainsList}
            onChange={(e: any, options: any) => {
              setValue(name, options["key"], { shouldValidate: true });
            }}
            styles={commonInputStyles.dropdownStyle}
          />
        )}
        control={control}
        name="domaine"
      /> */}

      <ErrorMessage errorMessage={(errors.statut as any)?.message} />
    </FormSection>
  );
}
