import { TooltipHost } from "@fluentui/react";
import { BsArrowReturnRight } from "react-icons/bs";

const ImportTable = ({ rows, headers }: any) => {
  const columns = Object.keys(rows[0].data);
  const newColumns = columns.map((item: any) => headers[item]);

  const errorStyle = {
    borderBottom: "1px dotted red",
    color: "red",
    padding: "2px 5px 2px 0px",
  };

  const getErrorEntry = (entity: any, col: any) => {
    if (entity.errors) {
      const keyToFind = Object.keys(entity.errors).find((key: any) => {
        return col.includes(key);
      });
      if (keyToFind) {
        return entity.errors[keyToFind];
      } else {
        return false;
      }
    }
  };

  return (
    <table style={{ minWidth: "80vw" }}>
      <thead>
        <tr>
          {newColumns.map((key: any) => (
            <th
              align="left"
              key={`th${key}`}
              style={{
                whiteSpace: "nowrap",
                padding: "2px 5px",
                borderBottom: "1px solid #e4e4e4",
                borderLeft: "1px solid #e4e4e4",
              }}
            >
              {key}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((ent: any, key: any) => {
          return (
            <>
              <tr key={`invalent${key}`}>
                {columns.map((col: any) => {
                  const colError = getErrorEntry(ent, col);

                  return (
                    <td
                      style={{
                        borderBottom: "1px solid #e4e4e4",
                        borderLeft: "1px solid #e4e4e4",
                      }}
                    >
                      {ent.errors && colError ? (
                        <>
                          <TooltipHost content={colError} id={`invent${key}`}>
                            {typeof ent.data[col] != "object" ? (
                              <span style={errorStyle}>{ent.data[col] || "???"}</span>
                            ) : Array.isArray(ent?.data[col]) ? (
                              ent?.data[col]?.map((item: any) => (
                                <>
                                  <span style={errorStyle}>{item || "???"}</span>
                                  <br />
                                </>
                              ))
                            ) : (
                              <span style={errorStyle}>{"???"}</span>
                            )}
                          </TooltipHost>
                        </>
                      ) : typeof ent.data[col] != "object" ? (
                        <span>{ent.data[col].toString()}</span>
                      ) : ent?.data[col] ? (
                        Array.isArray(ent?.data[col]) ? (
                          ent?.data[col]?.map((item: any) => (
                            <>
                              <span>{item}</span>
                              <br />
                            </>
                          ))
                        ) : null
                      ) : null}
                    </td>
                  );
                })}
              </tr>
              {ent.message && (
                <tr>
                  <td colSpan={columns.length} style={{ color: "red" }}>
                    <BsArrowReturnRight />
                    {ent.message}
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default ImportTable;
