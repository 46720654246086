import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  MessageBar,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
  Toggle,
} from "@fluentui/react";
import { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import Badge from "../../Common/Badge/Badge";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import Page from "../../Common/Page/Page";
import SectionTitle from "../../Common/SectionTitle/SectionTitle";
import { StatutCandidature } from "../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../hooks/useAuth";
import useConstants from "../../hooks/useConstants";
import {
  MessageBarStyles,
  commonButtonStyles,
  commonInputStyles,
} from "../../styles";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import AvisMembreDisplay from "./AvisMembreDisplay";
import CandidatJuryListFilters from "./CandidatJuryListFilters/CandidatJuryListFilters";
import InformationsJury from "./InformationsJury/InformationsJury";
import useCandidatJuryList from "./useCandidatJuryList";
import useCandidatureJuryList, {
  initialFilters,
} from "./useCandidatureJuryList";

import { useAtom } from "jotai";
import { isCandidatureWidgetVisibleAtom } from "../../Common/CandidatureNeedActionWidget/CandidatureNeedActionWidget";
import { branchAccessEnum, userAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useCreateurCandidature from "../../hooks/certifications/useCreateurCandidature";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import { StatutJuryEnum } from "../../interfaces/juryStatut";

function CandidatJuryList() {
  const [, setIsCandidatureWidgetVisible] = useAtom(
    isCandidatureWidgetVisibleAtom
  );
  const { isCreateurCandidature } = useCreateurCandidature();
  const { isAdmin, isAnyKindOfAdmin } = useInfoProfilUser();
  const { auth }: any = useAuth();
  const { labels } = useLabelSettings();
  const { uuid_jury } = useParams();
  const { jury, juryProps, isLoadingJury } = useCandidatJuryList();
  const { actions: accessActions } = useAccessSettings();
  const { actions } = juryProps;

  const {
    candidaturesList,
    candidaturesTotal,
    isLoadingCandidatures,
    isLoadingFirstCandidatures,
    filters,
    candidaturesNeedActionList,
    actions: actionsPage,
    previousJuryCallUuid,
    isInitialFirstRender,
    isInitialRender,
    resetIsInitialRender,
    nbCandidatureLoaded,
    isExportingCandidatures,
    isGroupeParDecision,
    sort,
  } = useCandidatureJuryList();
  const { constants } = useConstants();

  useEffect(() => {
    actions.setUpdateJuryUuid(uuid_jury);
  }, [uuid_jury]);

  useEffect(() => {
    return () => {
      resetIsInitialRender();
    };
  }, []);

  const items: any = candidaturesList;

  const columns = [
    {
      key: "1",
      name: "Nom de naissance",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>{item.candidat?.nom}</Text>
          <AvisMembreDisplay candidature={item} />
        </Stack>
      ),
      onColumnClick: () => {
        actionsPage.sortColumnBo("candidat.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "candidat.nom"),
    },
    {
      key: "2",
      name: "Nom d'usage",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom_usage}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("candidat.nom_usage");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "candidat.nom_usage"),
    },
    {
      key: "3",
      name: "Prenom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => {
        return <Text>{item.candidat?.prenom}</Text>;
      },
      onColumnClick: () => {
        actionsPage.sortColumnBo("candidat.prenom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "Entreprise",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.entreprise_liee?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("entreprise_liee.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "entreprise_liee.nom"),
    },
    {
      key: "5",
      name: "OF",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of_lie?.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("of_lie.raison_sociale");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "of_lie.raison_sociale"),
    },
    {
      key: "6",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("certification.libelle");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "certification.libelle"),
    },
    {
      key: "7",
      name: "Statut",
      minWidth: 160,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const waitingMER = item.mise_en_conformites.filter(
          (f: any) => f.statut === "EN_ATTENTE_DE_MODIFICATION"
        );
        return (
          <>
            <Stack>
              <Text>
                {
                  (
                    tools.findIn(
                      constants.CANDIDATURES.STATUTS,
                      item.statut,
                      "id"
                    ) as any
                  ).text
                }
              </Text>
              <Text>
                {
                  (
                    tools.findIn(
                      constants.CANDIDATURES.RESULTATS_STATUTS,
                      item.resultat,
                      "id"
                    ) as any
                  ).text
                }
              </Text>
            </Stack>
            <Stack>
              {waitingMER.length > 0 && (
                <Text
                  style={{
                    color: "grey",
                    fontSize: 12,
                  }}
                >
                  Étapes à corriger :
                </Text>
              )}
              {waitingMER.map((mer: any) => {
                return (
                  <div>
                    <Badge
                      text={`${
                        (
                          tools.findIn(
                            constants.CANDIDATURES.STATUTS,
                            mer.candidature_statut,
                            "id"
                          ) as any
                        )?.text
                      }`}
                      style={{ padding: "0px 5px", marginBottom: 3 }}
                      textStyle={{ fontSize: 12 }}
                      color="red"
                    />
                  </div>
                );
              })}
            </Stack>
          </>
        );
      },
      onColumnClick: () => {
        if (!isGroupeParDecision) {
          actionsPage.sortColumnBo("statut");
        }
      },
      showSortIconWhenUnsorted: isGroupeParDecision ? false : true,
      ...tools.sorted(sort, "statut"),
    },
    {
      key: "8",
      name: "Récap. Évaluation",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {
            <>
              {item.blocs_competences_passes.reduce((a: any, b: any) => {
                if (b.decision_evaluation == "VALIDE") {
                  return a + 1;
                }
                return a;
              }, 0)}
              /{item.blocs_competences_passes.length} bloc(s) validé(s)
            </>
          }
        </Stack>
      ),
    },
    {
      key: "9",
      name: "Évaluateur",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>
            {item.evaluateur?.nom} {item.evaluateur?.prenom}
          </Text>
        </Stack>
      ),
      onColumnClick: () => {
        actionsPage.sortColumnBo("evaluateur.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "evaluateur.nom"),
    },
    {
      key: "10",
      name: "Gérer",
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        const isCandidatCanBeUnsubscribe = tools.candidatureCanBeUnsubscribe(
          item,
          isAdmin,
          accessActions.canI({
            action: userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION,
          }).granted,
          accessActions.canI({
            action:
              branchAccessEnum.OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY,
          }).granted
        );

        //Il est possible de prendre la decision d'un jury :
        //si on est un admin ou le president du jury
        //ET que le jury est au statut EN_SEANCE
        const canPrendreDecision =
          (isAnyKindOfAdmin ||
            item.jury?.president_jury?.uuid == auth.user.uuid) &&
          item.jury?.statut == StatutJuryEnum.EN_SEANCE;

        return (
          <Stack>
            <Link
              to={`/admin/candidature/${item.uuid}/${item.statut}`}
              as={RouterLink}
              styles={commonButtonStyles.buttonLink}
            >
              {item.statut == "resultats_jury"
                ? "Consulter les résultats du jury"
                : labels.CANDIDATURES_BTN_VISUALISER}
            </Link>

            {item.statut == StatutCandidature.DECISION_JURY &&
              candidaturesNeedActionList.length > 0 && (
                <>
                  {(
                    tools.findIn(
                      item.jury?.membres,
                      auth.user.uuid,
                      "uuid"
                    ) as any
                  ).id &&
                    item.jury?.statut == StatutJuryEnum.EN_SEANCE && (
                      <Link
                        to={`/admin/candidature/${item.uuid}/${item.statut}`}
                        as={RouterLink}
                        styles={commonButtonStyles.buttonLink}
                      >
                        Donner son avis
                      </Link>
                    )}
                  {canPrendreDecision && (
                    <Link
                      to={`/admin/candidature/${item.uuid}/${item.statut}`}
                      as={RouterLink}
                      styles={commonButtonStyles.buttonLink}
                    >
                      Prendre une décision
                    </Link>
                  )}
                </>
              )}

            {(isAnyKindOfAdmin ||
              isCreateurCandidature({
                ofUuid: item.cree_par?.uuid,
                companyUuid: item.cree_par?.uuid,
              })) &&
              item.statut != "resultats_jury" && (
                <Link
                  onClick={() => {
                    actionsPage.openGiveUpCandidatureModal(item);
                  }}
                  styles={commonButtonStyles.deleteButtonLink}
                >
                  {labels.CANDIDATURES_BTN_SUPPRIMER}
                </Link>
              )}

            {isCandidatCanBeUnsubscribe && (
              <Link
                onClick={() => {
                  actionsPage.openDesincriptionConfirmModal(item);
                }}
                styles={commonButtonStyles.deleteButtonLink}
              >
                Désinscrire du jury
              </Link>
            )}
          </Stack>
        );
      },
    },
  ];

  const filterListNotModified = Object.keys(initialFilters).filter(
    (key: any) => {
      if (key == "resultat") {
        return initialFilters[key]["key"] == filters[key]["key"];
      }
      return initialFilters[key] == filters[key];
    }
  );

  const isJuryUuidDifferentFromLastRequestAtRender =
    uuid_jury != previousJuryCallUuid && isInitialRender;
  const isFilterIsDifferentFromInitialAtRender =
    filterListNotModified.length != Object.keys(initialFilters).length &&
    isInitialRender;
  /*
  INFO: needToMakeNewSearch
    -si c'est la premiere fois qu'on arrive sur la page (isInitialFirstRender)
    -si les filtres sont differents de la precedente recherche (isFilterIsDifferentFromInitialAtRender)
    -si le jury a change de la precedente recherche (isJuryUuidDifferentFromLastRequestAtRender)
  */
  const needToMakeNewSearch =
    isInitialFirstRender ||
    isFilterIsDifferentFromInitialAtRender ||
    isJuryUuidDifferentFromLastRequestAtRender;

  /*
  INFO: canExportCandidatures
    -si Utilisateur possede la permission EXPORTER_CANDIDATURE_JURY
    -s'il est necessaire de faire une nouvelle recherche (needToMakeNewSearch)
    -si on est en cours de chargement des candidatures (isLoadingCandidatures)
  */
  const canExportCandidatures =
    accessActions.canI({
      action: userAccessEnum.EXPORTER_CANDIDATURE_JURY,
    }).granted &&
    !needToMakeNewSearch &&
    !isLoadingCandidatures;

  useEffect(() => {
    setIsCandidatureWidgetVisible(!needToMakeNewSearch);
  }, [candidaturesNeedActionList, needToMakeNewSearch]);

  return (
    <Stack>
      <BackLink />

      <Page
        title={labels.VISUALISATION_JURY_TITRE}
        explain={labels.VISUALISATION_JURY_SOUS_TITRE}
      >
        <DetailLoader
          isLoadingDetail={
            isLoadingFirstCandidatures || isLoadingJury || isLoadingCandidatures
          }
          customText={`${
            isLoadingJury
              ? "Jury"
              : isLoadingCandidatures || isLoadingFirstCandidatures
              ? "Candidatures"
              : ""
          } en cours de chargement ${
            candidaturesTotal > 15
              ? `${nbCandidatureLoaded} / ${candidaturesTotal} éléments`
              : ""
          }`}
        >
          <SectionTitle title={labels.VISUALISATION_JURY_CANDIDAT_TITRE} />

          <CandidatJuryListFilters />

          {!isLoadingFirstCandidatures && (
            <>
              {needToMakeNewSearch ? (
                <MessageBar className="bg-info" styles={MessageBarStyles}>
                  Cliquez sur le bouton <b>rechercher</b> pour afficher les
                  candidatures inscrites à ce jury
                </MessageBar>
              ) : (
                <>
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign="space-between"
                    verticalAlign="center"
                    wrap
                  >
                    <Stack horizontal tokens={{ childrenGap: 10 }} wrap>
                      {!needToMakeNewSearch &&
                        !isLoadingCandidatures &&
                        !isLoadingJury &&
                        candidaturesNeedActionList.length > 0 && (
                          <PrimaryButton
                            text="Traiter toutes les candidatures en attente d'action de ma part"
                            onClick={actionsPage.onConsultCandidatures}
                            styles={commonButtonStyles.buttonSubmit}
                          />
                        )}
                      {canExportCandidatures && (
                        <DefaultButton
                          disabled={isExportingCandidatures}
                          onClick={actionsPage.exportCandidatures}
                          styles={commonButtonStyles.defaultButton}
                          text={`Exporter les ${candidaturesList.length} candidatures du jury`}
                        />
                      )}
                      {isAnyKindOfAdmin &&
                        !needToMakeNewSearch &&
                        !isLoadingCandidatures && (
                          <DefaultButton
                            onClick={actionsPage.changeJuryCandidatures}
                            styles={commonButtonStyles.defaultButton}
                          >
                            Changer de jury en masse
                          </DefaultButton>
                        )}
                    </Stack>
                    <Stack.Item>
                      <Toggle
                        styles={commonInputStyles.toggleInput}
                        label={`Grouper par décision :`}
                        inlineLabel
                        onText="Oui"
                        offText="Non"
                        onChange={(e, checked) =>
                          actionsPage.onGrouperParDecision(checked)
                        }
                        checked={isGroupeParDecision ?? false}
                      />
                    </Stack.Item>
                  </Stack>
                  <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    items={items}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                  />
                </>
              )}
            </>
          )}
          {!isLoadingJury && <InformationsJury jury={jury} actions={actions} />}
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default CandidatJuryList;
