import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import CandidatureAbandonneeListFilters from "./CandidatureAbandonneeListFilters/CandidatureAbandonneeListFilters";
import useCandidatureAbandonneeList from "./useCandidatureAbandonneeList";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import useConstants from "../../hooks/useConstants";

function CandidatureAbandonneeList() {
  const { constants } = useConstants();
  const { labels } = useLabelSettings();
  const {
    actions: actionsPage,
    optionsFilters,
    candidaturesAbandonnees,
  } = useCandidatureAbandonneeList();

  const items: any = candidaturesAbandonnees?.data || [];

  const columns = [
    {
      key: "1",
      name: "Nom de naissance",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom"),
    },
    {
      key: "2",
      name: "Nom d'usage",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom_usage}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom_usage");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom_usage"),
    },
    {
      key: "3",
      name: "Prénom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat.prenom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.prenom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "Entreprise",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.entreprise_liee?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("entreprise.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "entreprise.nom"),
    },
    {
      key: "5",
      name: "OF",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of_lie?.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("organisme_de_formation.raison_sociale");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(
        optionsFilters.sort,
        "organisme_de_formation.raison_sociale"
      ),
    },
    {
      key: "6",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("certification.libelle");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "certification.libelle"),
    },
    {
      key: "8",
      name: "Statut",
      minWidth: 160,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Text>
            {
              (
                tools.findIn(
                  constants.CANDIDATURES.STATUTS,
                  item.statut,
                  "id"
                ) as any
              )?.text
            }
          </Text>
        );
      },
      onColumnClick: () => {
        actionsPage.sortColumn("statut");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "statut"),
    },
    {
      key: "9",
      name: "Date de l'abandon",
      fieldName: "abandonnee_le",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.abandonnee_le)}</Stack>
      ),
    },
  ];

  return (
    <Stack>
      <Page
        title={"Candidatures abandonnées"}
        explain={"Vous pouvez voir les candidatures abandonnées"}
      >
        <CandidatureAbandonneeListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={candidaturesAbandonnees?.meta.current_page || 1}
          totalPages={Math.ceil(candidaturesAbandonnees?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CandidatureAbandonneeList;
