import { MessageBar, Stack, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/axiosConfig";
import { MessageBarStyles, commonInputStyles } from "../../styles";
import LoadButton from "../LoadButton/LoadButton";

type SearchExistingStructureSiretProps = {
  reactHookProps: UseFormReturn;
  isChecked: boolean;
  actions: any;
  searchExistingUrl: string;
  keyToCheck: "siret";
  acceptedChararacterNumber: 14;
};

type SearchExistingStructureSirenProps = {
  reactHookProps: UseFormReturn;
  isChecked: boolean;
  actions: any;
  searchExistingUrl: string;
  keyToCheck: "siren";
  acceptedChararacterNumber: 9;
};

function SearchExistingStructure({
  reactHookProps,
  isChecked,
  actions,
  searchExistingUrl,
  keyToCheck,
  acceptedChararacterNumber,
}: SearchExistingStructureSiretProps | SearchExistingStructureSirenProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<any>([]);

  const keyToCheckToString = keyToCheck.toUpperCase();

  const actionsPage = {
    onKeyDown: (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        reactHookProps.handleSubmit(actionsPage.onVerify)(e);
      }
    },
    onVerify: async () => {
      setIsLoading(true);
      try {
        const structureExist = await axiosInstance.get(
          `${searchExistingUrl}/${reactHookProps.watch(keyToCheck)}`
        );
        actions.setUpdateIsCheck(true, structureExist.data);
        setSearchResult(structureExist.data ? [structureExist.data] : []);
      } catch (e: any) {
        toast.error(
          e.response?.data?.message ||
            `Une erreur est survenue lors de la vérification du ${keyToCheckToString}`
        );
      }
      setIsLoading(false);
    },
  };

  const {
    formState: { errors },
    control,
  } = reactHookProps;

  useEffect(() => {
    actions.setUpdateIsCheck(false);
    actions.resetAutocompleteInput();
    setSearchResult([]);
  }, [reactHookProps.watch(keyToCheck)]);

  const getBlocsInfoMessage = () => {
    if (!isChecked) {
      return `La vérification de l'existence du ${keyToCheckToString} est obligatoire pour poursuivre la création.`;
    }
    if (searchResult?.length > 0) {
      return "Une structure similaire existe déjà en base de données sur la(les) branche(s) :";
    } else {
      return `Aucune structure avec ce ${keyToCheckToString} n'a été trouvée en base de données.`;
    }
  };

  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label={`Saisissez le ${keyToCheckToString}`}
              type="text"
              placeholder={`N° ${keyToCheckToString} à ${acceptedChararacterNumber} chiffres sans espace`}
              errorMessage={(errors[keyToCheck] as any)?.message}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= acceptedChararacterNumber) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={control}
          name={keyToCheck}
        />
      </Stack.Item>

      <LoadButton
        isLoading={isLoading}
        text="Vérifier l'existence"
        onClick={actionsPage.onVerify}
        type="button"
        style={{ maxWidth: 270 }}
      />

      <MessageBar styles={MessageBarStyles}>
        {getBlocsInfoMessage()}
        {searchResult?.length > 0 && (
          <ul>
            {searchResult[0]["branches"].map((b: any) => (
              <li>{b.nom}</li>
            ))}
          </ul>
        )}
      </MessageBar>
    </>
  );
}

export default SearchExistingStructure;
