import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  Checkbox,
} from "@fluentui/react";
import Page from "../../Common/Page/Page";
import useConstants from "../../hooks/useConstants";
import tools from "../../utils/tools";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import useOfflineCandidatureList from "./useOfflineCandidatureList";
import { useEffect } from "react";
import LoadButton from "../../Common/LoadButton/LoadButton";
import OfflineCandidatureListFilters from "./OfflineCandidatureListFilters/OfflineCandidatureListFilters";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import useOfflineCandidaturePile from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import Loader from "../../Common/Loader/Loader";
import { useAtom } from "jotai";
import { isWidgetVisibleAtom } from "../../Common/SynchronizationWidget/SynchronizationWidget";

function CandidatureList() {
  const { labels } = useLabelSettings();
  const {
    userSelectedBranch,
    actions: actionsPage,
    sort,
  } = useOfflineCandidatureList();
  const {
    actions: actionsPile,
    candidaturesToDownloadList,
    candidaturesToDownloadTotal,
    isLoadingCandidaturesToDownload,
    isLoadingFirstCandidaturesToDownload,
    isDownloadingCandidature,
    auth,
  } = useOfflineCandidaturePile();
  const { constants } = useConstants();
  const [, setIsWidgetVisible] = useAtom(isWidgetVisibleAtom);

  const items = candidaturesToDownloadList || [];

  const columns = [
    {
      key: "1",
      name: "",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: any) => {
        if (isDownloadingCandidature.includes(item.uuid)) {
          return <Loader size="small" />;
        }
        return (
          <Checkbox
            key={item.uuid}
            defaultChecked={
              localStorage.getItem(`${item.uuid}-${auth.user.uuid}`)
                ? true
                : false
            }
            onChange={(e: any, checked: any) => {
              actionsPile.onSelectCandidature(item, checked);
            }}
          />
        );
      },
    },
    {
      key: "2",
      name: "Nom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("candidat.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "candidat.nom"),
    },
    {
      key: "3",
      name: "Prénom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat.prenom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("candidat.prenom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "Domaine",
      fieldName: "domaine",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.domaine?.nom}</Text>,
    },
    {
      key: "5",
      name: "Entreprise",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.entreprise_liee?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("entreprise_liee.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "entreprise_liee.nom"),
    },
    {
      key: "6",
      name: "OF",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of_lie?.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumnBo("of_lie.raison_sociale");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "of_lie.raison_sociale"),
    },
    {
      key: "7",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
    },
    {
      key: "8",
      name: "Statut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {
            (
              tools.findIn(
                constants.CANDIDATURES.STATUTS,
                item.statut,
                "id"
              ) as any
            )?.text
          }
        </Text>
      ),
      onColumnClick: () => {
        actionsPage.sortColumnBo("statut");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(sort, "statut"),
    },
  ].filter((col: any) =>
    userSelectedBranch?.gestion_par_domaine ? col : col.fieldName != "domaine"
  );

  useEffect(() => {
    actionsPile.getAllCandidaturesToDownload();
    //setIsWidgetVisible(false);
  }, []);

  return (
    <Stack>
      <Page
        title="Mes candidatures à télécharger"
        explain="Vous pouvez télécharger les candidatures pour effectuer l'évaluation hors ligne"
      >
        <DetailLoader
          isLoadingDetail={
            isLoadingFirstCandidaturesToDownload ||
            isLoadingCandidaturesToDownload
          }
          customText={`Candidatures en cours de chargement ${
            candidaturesToDownloadTotal > 15
              ? `${candidaturesToDownloadTotal} éléments`
              : ""
          }`}
        >
          <OfflineCandidatureListFilters />

          <DetailsList
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.justified}
            items={items}
            columns={columns}
          />

          <Stack
            horizontal
            wrap
            horizontalAlign="end"
            verticalAlign="center"
            style={{ marginTop: 25 }}
          >
            <LoadButton
              text={"Commencer à traiter les candidatures en mode hors ligne"}
              isLoading={false}
              isDisabled={!(isDownloadingCandidature.length == 0)}
              onClick={actionsPage.onNavigateToOfflineCandidatureSync}
            />
          </Stack>
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default CandidatureList;
