import { Stack, Text } from "@fluentui/react"

function DefinePassword({ title, definition }: any) {
    return (
        <Stack
            horizontalAlign="center"
            style={{ width: 300 }}
        >
            <Text variant={"xLarge"}>{title}</Text>
            <Text variant={"mediumPlus"} style={{ margin: "20px 0", textAlign: "justify" }}>
                {definition}
            </Text>
            <Text style={{ textAlign: "justify" }}>
                Votre mot de passe doit contenir au minimum:
            </Text>
            <ul
                style={{ listStyleType: 'square', paddingInlineStart: 0, fontSize: 14, fontWeight: '400', color: 'rgb(50, 49, 48)' }}
            >
                <li>1 lettre en majuscule</li>
                <li>1 chiffre</li>
                <li>1 caractère spécial</li>
                <li>8 caractères</li>
            </ul>
        </Stack>
    )
}

export default DefinePassword