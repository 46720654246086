import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import AvisCandidatPositionnement from "../Common/AvisCandidatPositionnement/AvisCandidatPositionnement";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import useFetchCandidature from "../useFetchCandidature";
import {
  ValidationPositionnementProvider,
  useValidationPositionnement,
} from "./useValidationPositionnement";

const ValidationPositionnement = () => {
  const { isThisPageStatusBeforeCandidatureStatus, isReferentielStillEditable } =
    useCandidatureWorkflow();
  const { candidature, reactHookProps, actions } = useValidationPositionnement();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps);

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(candidature.statut)
    ? isReferentielStillEditable(candidature.statut, ["validation_positionnement"])
      ? userAccessEnum.MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT
      : userAccessEnum.NO_ACCESS //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT;
    
  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  return (
    <ValidationPositionnementProvider
      value={{
        reactHookProps,
        actions,
        candidature,
      }}
    >
      <Stack>
        <CandidatSection candidat={candidature.candidat} />
        {candidature.statut_validation_positionnement_par_candidat && (
          <AvisCandidatPositionnement
            candidatValidationStatus={candidature.statut_validation_positionnement_par_candidat}
            comment={candidature.commentaire_candidat_positionnement}
          />
        )}
        <CertificationSection certification={candidature.certification} />

        <form onSubmit={reactHookProps.handleSubmit(actions.onSave)}>
          <OptionsBlocsSection
            candidature={candidature}
            statutCandidature={StatutCandidature.VALIDATION_POSITIONNEMENT}
            reactHookProps={reactHookProps}
          />

          <FormSection sectionTitle="Commentaire du candidat">
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Vous pouvez laisser un commentaire concernant votre positionnement : (3000 caractères max)"
                  placeholder="Ex : commentaire du candidat"
                  multiline
                  rows={6}
                  maxLength={3000}
                  {...field}
                  disabled={inputsDisabled}
                />
              )}
              control={reactHookProps.control}
              name="commentaire_candidat_positionnement"
            />
          </FormSection>
          <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
            <CancelButton />
            <Can I={userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT}>
              <>
                <DefaultButton
                  styles={commonButtonStyles.deleteButton}
                  onClick={() => actions.onInvalidatePositionnement(reactHookProps.getValues())}
                >
                  Invalider le positionnement saisi
                </DefaultButton>
                <LoadButton text={"Confirmer la lecture de mon positionnement"} isLoading={false} />
              </>
            </Can>
          </Stack>
        </form>
      </Stack>
    </ValidationPositionnementProvider>
  );
};

export default ValidationPositionnement;
