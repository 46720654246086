import { useState } from "react";
import useCandidat from "../../hooks/candidat/useCandidat";
import useCandidatQuery from "../../hooks/candidat/useCandidatQuery";

function useFicheCandidat() {
  const candidatProps = useCandidat();
  const { candidat, isLoading } = useCandidatQuery(candidatProps);
  const [isCandidat, setIsCandidat] = useState(false);

  const actions = {
    setUpdateIsCandidat: (value: boolean) => {
      setIsCandidat(value);
    },
  };

  return {
    candidat,
    candidatProps,
    isLoading,
    actions,
    isCandidat,
  };
}

export default useFicheCandidat;
