import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { getModelesLettreRequest } from "../../services/modelesLettreServices";
import useModeleLettre from "../../hooks/modeleLettre/useModeleLettre";

const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useModelesLettreList() {
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const modelesLettreProps = useModeleLettre();

  const { data: modelesLettre } = useQuery(
    ["modelesLettre", optionsFilters, userSelectedBranch],
    () => getModelesLettreRequest(optionsFilters),
    { retry: 0 }
  );
  const actions = {
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
  };

  return { actions, modelesLettre, modelesLettreProps };
}

export default useModelesLettreList;
