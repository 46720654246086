import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode
} from "@fluentui/react";
import { Link as RouterLink } from 'react-router-dom'
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useBranchList from "./useBranchList";
import BranchListFilters from "./BranchListFilters/BranchListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import tools from "../../utils/tools";

import { commonButtonStyles } from '../../styles/index'
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";

function BranchList() {
  const { branches, branchProps, optionsFilters, actions: actionsPage } = useBranchList();

  const {
    actions,
    isToggleActivationId
  } = branchProps

  const items = branches?.data || [];

  const columns = [
    {
      key: "1",
      name: "Nom ",
      fieldName: "nom",
      minWidth: 150,
      maxWidth: 400,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn('nom')
      },
      onRender: (item: any) => (
        <Text>{item.nom}</Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'nom')
    },
    {
      key: "2",
      name: "Email administrateur",
      minWidth: 200,
      maxWidth: 300,
      isCollapsible: true,
      isResizable: true,
      onRender: (item: any) => (
        <Text>{item.admin.email}</Text>
      ),
      onColumnClick: () => {
        actionsPage.sortColumn('admin.email')
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'admin.email')
    },
    {
      key: "3",
      name: "Administrateur",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => (
        <Text>{item.admin.name}</Text>
      ),
      onColumnClick: () => {
        actionsPage.sortColumn('admin.prenom')
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'admin.prenom')
    },
    {
      key: "4",
      name: "Actif",
      fieldName: "active",
      minWidth: 40,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item["active"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn('active')
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'active')
    },
    {
      key: "5",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack
            horizontal
            wrap
          >
            <Link
              to={`/admin/branches/${item.id}`}
              as={RouterLink}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Editer
            </Link>

            <LoadLink
              styles={item.active ? commonButtonStyles.disableButtonLink : commonButtonStyles.activeButtonLink}
              onClick={() => actions.toggleBranchActivation(item)}
              isLoading={isToggleActivationId==item.id}
              text={item.active ? "Désactiver" : "Activer"}
            />

          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Branches de l’application"
        explain="Gérez vos branches"
      >
        <Stack style={{ marginTop: 25 }}>
          <CreateLink
            text="Créer une branche"
            to="/admin/branches/creer"
          />
        </Stack>


        <BranchListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={branches?.meta.current_page || 1} totalPages={Math.ceil(branches?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default BranchList;
