import { atom, useAtom } from 'jotai'

const isOpenPanelAtom = atom(false)
const contentPanelAtom = atom({
    header:undefined,
    body:undefined,
    footer:undefined,
})

function useCustomPanel() {

    const [isOpenPanel,setIsOpenPanel] = useAtom(isOpenPanelAtom)
    const [contentPanel,setContentPanel] = useAtom(contentPanelAtom)

    const actions ={
        openPanel : (panelContent:any) =>{
            setIsOpenPanel(true);
            setContentPanel({
                header: undefined,
                body: undefined,
                footer: undefined, 
                ...panelContent
            })
        },
        closeModal: () =>{
            setIsOpenPanel(false);
        }
    }

    return {
        isOpenPanel,
        contentPanel,
        actions,
    }
}

export default useCustomPanel