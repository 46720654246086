import { Checkbox, Label, MessageBar } from "@fluentui/react";
import { DateTime } from "luxon";
import { BsDashLg } from "react-icons/bs";
import useTheme from "../../../../Common/useTheme";
import useConstants from "../../../../hooks/useConstants";
import { commonInputStyles, MessageBarStyles } from "../../../../styles";

const BlocSelection = ({
  blocs_competences,
  nb_blocs_au_choix_a_valider,
  selectedBlocs,
  onChange,
  disabled,
}: any) => {
  const { constants } = useConstants();
  const { primaryColor } = useTheme();

  const onToggleBloc = (bloc: any) => {
    let newSelectedBlocs = [];
    if (selectedBlocs.find((b: any) => b.uuid === bloc.uuid)) {
      newSelectedBlocs = selectedBlocs.filter((b: any) => b.uuid !== bloc.uuid);
    } else {
      newSelectedBlocs = [...selectedBlocs, bloc];
    }
    onChange(newSelectedBlocs);
  };

  const getMessageBarTextByStatus = (statut: any) => {
    switch (statut) {
      case 1:
        return `Veuillez sélectionner les blocs imposés (pour l’obtention de la certification) que le candidat va passer. 
Si le candidat ne passe pas tous les blocs obligatoires, alors il ne pourra être validé que partiellement 
(sauf s’il a déjà acquis les blocs non passés auparavant).`;

      case 2:
        return (
          <>
            Veuillez sélectionner exactement{" "}
            <strong>{nb_blocs_au_choix_a_valider}</strong> blocs au choix
          </>
        );
      case 3:
        return `Vous pouvez sélectionner un ou plusieurs blocs optionnels parmi la liste suivante.
Si aucun bloc n’est choisi, le candidat pourra tout de même être validé totalement.`;

      default:
        return "";
    }
  };

  return constants.CERTIFICATIONS.BLOC_COMPETENCE_OPTION_STATUTS.map(
    (type_bloc: any) => {
      const blocs_competences_filtered_by_statut = blocs_competences.filter(
        (e: any) => e.statut == type_bloc.id
      );
      if (blocs_competences_filtered_by_statut.length == 0) {
        return null;
      }
      return (
        <div style={{ marginBottom: 36 }} key={`blocsofstatus${type_bloc.id}`}>
          <div style={{ marginBottom: 16 }}>
            <MessageBar className="bg-info" styles={MessageBarStyles}>
              {getMessageBarTextByStatus(type_bloc.id)}
            </MessageBar>
          </div>
          <div style={{ marginBottom: 16 }}>
            Blocs de compétences au statut «{" "}
            <strong>{type_bloc.text} » :</strong>
          </div>
          {blocs_competences_filtered_by_statut.map((e: any, i: any) => {
            let nbSelected = selectedBlocs.filter(
              (te: any) => te.statut == type_bloc.id
            );
            let isSelected = selectedBlocs.find((b: any) => b.uuid === e.uuid);
            let isDisabled =
              !isSelected &&
              type_bloc.id == 2 &&
              nbSelected.length == nb_blocs_au_choix_a_valider;

            return (
              <div
                key={`bloc${e.uuid}`}
                style={{
                  marginBottom: 10,
                  borderBottom: "1px solid #d8d8d8",
                  paddingTop: 5,
                  paddingBottom: 10,
                }}
              >
                {e.deja_acquis_dans_une_precedente_certification ? (
                  <Label
                    styles={commonInputStyles.checkbox}
                    style={{
                      lineHeight: "19px",
                      padding: 0,
                      color: "grey",
                    }}
                  >
                    <BsDashLg style={{ marginRight: 13 }} />
                    <span className="ms-Checkbox-text">
                      {e.libelle} |{" "}
                      <i style={{ fontWeight: "400", fontSize: 13 }}>
                        Déjà acquis le{" "}
                        {DateTime.fromISO(e.date_acquisition).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </i>
                    </span>
                  </Label>
                ) : (
                  <Checkbox
                    styles={{
                      checkbox: {
                        border: "1px solid #D1D1D1",
                      },
                      root: {
                        selectors: {
                          ".ms-Checkbox-text": {
                            fontSize: "16px",
                            fontWeight: "500",
                            color: isSelected ? primaryColor : "black",
                          },
                        },
                      },
                    }}
                    label={`${(e.reference || "") && e.reference + " - "}${
                      e.libelle
                    }`}
                    checked={isSelected ? true : false}
                    onChange={() => onToggleBloc(e)}
                    disabled={disabled || isDisabled}
                  />
                )}
                <p
                  className="text-info"
                  style={{ marginTop: 0, marginBottom: 0, paddingLeft: 28 }}
                >
                  {e.description}
                </p>
              </div>
            );
          })}
        </div>
      );
    }
  );
};

export default BlocSelection;
