import { Stack, Text } from "@fluentui/react";
import { useContext } from "react";
import BackLink from "../../../Common/BackLink/BackLink";
import { SignUpContext } from "../useSignUp";
import stepContainerStyles from "./StepContainer.styles";

function StepContainer({ children }: any) {
  const { stepInfo, actions }: any = useContext(SignUpContext);

  return (
    <>
      <Stack styles={stepContainerStyles.container}>
        {actions.isFirstStep() && (
          <BackLink style={{ margin: "0px 0px 20px 0px" }} />
        )}
        <Text variant="xLarge">{stepInfo.title}</Text>
        <Text variant="mediumPlus" style={{ color: "#6F6F6F" }}>
          {stepInfo.subtitle}
        </Text>

        <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 15 }}>
          {children}
        </Stack>
      </Stack>
    </>
  );
}

export default StepContainer;

{
  /* <Stack
                        wrap
                        horizontal
                        horizontalAlign="space-between"
                        style={{ marginTop: 20 }}
                    >
                        <DefaultButton
                            onClick={onPrevious}
                            styles={commonButtonStyles.defaultButton}
                            style={{ color: primaryColor, borderColor: primaryColor }}
                        >
                            {labelPreviousButton}
                        </DefaultButton>

                        <LoadButton
                            text={labelNextButton}
                        />
                    </Stack> */
}
