import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
//import { ReactQueryDevtools } from "react-query/devtools";

import * as Sentry from "@sentry/react";

import { initializeIcons } from "@fluentui/font-icons-mdl2";
import ErrorBoundary from "./ErrorBoundary";
initializeIcons();

if(process.env.REACT_APP_MODE == "production" || process.env.REACT_APP_MODE == "staging"){
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_MODE,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/www\.certifia\.net/],
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // -> 0.25 in production
    tracesSampleRate: 0.25,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}


const queryClient = new QueryClient();

if (process.env.REACT_APP_MODE == "production") {
  console.log = function () {};
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
