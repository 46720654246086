import { Text, PrimaryButton } from "@fluentui/react";
import { commonButtonStyles } from "../../../styles/index";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";

function DesincriptionConfirmDialog({
  candidat,
  desinscriptionAction = () => {},
}: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickDesinscrireConfirmButton = () => {
    desinscriptionAction();
    closeModal();
  };

  return (
    <>
      <Text
        variant="medium"
        style={{ margin: "10px 0px", textAlign: "center" }}
      >
        Voulez-vous vraiment désinscire la candidature de son jury ? <br />{" "}
        <strong>{candidat}</strong>
      </Text>
      <PrimaryButton
        text="Désinscrire"
        onClick={onClickDesinscrireConfirmButton}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </>
  );
}

export default DesincriptionConfirmDialog;
