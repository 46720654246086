import {
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import Can from "../../Common/Can/Can";
import CreateLink from "../../Common/CreateLink/CreateLink";
import LoadButton from "../../Common/LoadButton/LoadButton";
import LoadLink from "../../Common/LoadLink/LoadLink";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import useTheme from "../../Common/useTheme";
import { userAccessEnum } from "../../config/accessEnum";
import useConstants from "../../hooks/useConstants";
import { commonButtonStyles } from "../../styles/index";
import tools from "../../utils/tools";
import UserListFilters from "./UserListFilters/UserListFilters";
import useUserList from "./useUserList";
import useAuth from "../../hooks/useAuth";

function UserList() {
  const {
    usersProfils,
    userProps,
    actions: actionsPage,
    optionsFilters,
    isLoadingExportUsersExceptCandidat,
  } = useUserList();
  const { constants } = useConstants();
  const { actions, isToggleActivationUuid, isSendingPasswordUuid } = userProps;
  const { primaryColor } = useTheme();
  const { auth } = useAuth();

  const items = usersProfils?.data || [];

  const columns = [
    {
      key: "1",
      name: "Rôle(s)",
      fieldName: "role",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        //    actions.sortColumn('role')
      },
      onRender: (item: any) => (
        <Stack>
          {item.est_administrateur && <Text>Administrateur système</Text>}
          {item.profils?.map((profil: any, i: number) => {
            const profilObject: any = tools.findIn(
              constants.MATRICES.PROFILS_UTILISATEURS,
              profil.profil,
              "id"
            );
            return <Text key={i}>{profilObject?.text}</Text>;
          })}
        </Stack>
      ),
      //showSortIconWhenUnsorted: true,
      //...tools.sorted(userFilters.sort, 'role')
    },
    {
      key: "2",
      name: "Nom",
      fieldName: "nom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("nom");
      },
      onRender: (item: any) => <Text>{item.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "nom"),
    },
    {
      key: "3",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("prenom");
      },
      onRender: (item: any) => <Text>{item.prenom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "prenom"),
    },
    {
      key: "4",
      name: "Email",
      fieldName: "email",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("email");
      },
      onRender: (item: any) => <Text>{item.email}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "email"),
    },
    {
      key: "5",
      name: "Date de création",
      fieldName: "created_at",
      minWidth: 120,
      maxWidth: 125,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("created_at");
      },
      onRender: (item: any) => (
        <Text>{tools.reformateDateFromJsDate(item.created_at)}</Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "created_at"),
    },
    {
      key: "6",
      name: "Multibranche",
      fieldName: "est_multibranches",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.est_multibranches} />,
    },
    {
      key: "7",
      name: "Actif",
      fieldName: "actif",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.actif} />,
    },
    {
      key: "8",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Can I={userAccessEnum.GERER_UTILISATEUR}>
              <Link
                to={`/admin/utilisateurs/${item.uuid}`}
                as={RouterLink}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Editer
              </Link>
            </Can>

            <LoadLink
              styles={commonButtonStyles.buttonLink}
              onClick={() => actions.sendPassword(item)}
              text={"Envoi du MDP"}
              isLoading={isSendingPasswordUuid == item.uuid}
            />

            {/* <LoadLink
              styles={commonButtonStyles.buttonLink}
              onClick={() => actions.showlog(item)}
              text={"Logs du compte"}
            /> */}

            <Can I={userAccessEnum.GERER_UTILISATEUR}>
              <LoadLink
                styles={
                  item.actif
                    ? commonButtonStyles.disableButtonLink
                    : commonButtonStyles.activeButtonLink
                }
                onClick={() => actions.toggleUserActivation(item)}
                isLoading={isToggleActivationUuid == item.uuid}
                text={item.actif ? "Désactiver" : "Réactiver"}
              />
            </Can>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page title="Liste des utilisateurs" explain="Gérer les utilisateurs">
        <Stack
          horizontal
          wrap
          verticalAlign="center"
          tokens={{ childrenGap: 10 }}
        >
          <Can I={userAccessEnum.CREER_UTILISATEUR}>
            <CreateLink
              text="Créer un utilisateur"
              to="/admin/utilisateurs/creer"
            />
          </Can>
          <div style={{ height: 46 }}>
            <LoadButton
              text="Exporter les données utilisateurs (sauf candidats)"
              isLoading={isLoadingExportUsersExceptCandidat}
              style={{
                fontWeight: 500,
                color: primaryColor,
                borderColor: primaryColor,
              }}
              onClick={actionsPage.onExportUsersExceptCandidat}
              primary={false}
            />
          </div>
        </Stack>

        <UserListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={usersProfils?.meta.current_page}
          totalPages={Math.ceil(usersProfils?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default UserList;
