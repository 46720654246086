import { Stack, Text, TextField } from "@fluentui/react";
import { useState } from "react";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useHabilitation from "../../../hooks/habilitation/useHabilitation";
import { commonButtonStyles, commonInputStyles } from "../../../styles";

function RemoveHabilitationModal({ habilitation }: any) {
  const [commentaire, setCommentaire] = useState("");
  const {
    actions: { closeModal },
  } = useCustomModal();

  const { actions: habilitationActions, isLoadingRemoveHabilitations } =
    useHabilitation();

  const onClickRemoveButton = () => {
    habilitationActions.removeHabilitations({
      habilitations: [habilitation[0].uuid],
      commentaire,
    });
    closeModal();
  };
  return (
    <Stack>
      <Text style={{ color: "#979797" }}>
        Veuillez renseigner un commentaire pour l'habilitation sélectionnée
        justifiant la suppression.
      </Text>

      <Text variant="medium" style={{ margin: "10px 0px" }}>
        Voulez-vous vraiment supprimer l'habilitation de l'utilisateur{" "}
        {habilitation[0]?.utilisateur?.nom}{" "}
        {habilitation[0]?.utilisateur?.prenom} pour la certification{" "}
        {habilitation[0].certification.libelle}.
      </Text>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          label="Commentaire :"
          placeholder="Ex : commentaire"
          multiline
          required
          rows={6}
          maxLength={3000}
          value={commentaire}
          onChange={(e) => setCommentaire((e.target as any).value)}
        />
      </Stack.Item>

      <LoadButton
        isLoading={isLoadingRemoveHabilitations}
        text="Retirer"
        onClick={onClickRemoveButton}
        style={{ margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </Stack>
  );
}

export default RemoveHabilitationModal;
