import { Checkbox, Label, Stack, Text, TextField } from "@fluentui/react";
import { useEffect } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import FormSection from "../../Common/FormSection/FormSection";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import { commonInputStyles } from "../../styles/index";
import useUserDetail from "./useUserDetail";

import React from "react";
import BranchesInfo from "../../Common/BranchesInfo/BranchesInfo";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import userAdapters from "../../adapters/userAdapters";
import { userAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useConstants from "../../hooks/useConstants";
import SearchExistingUser from "./SearchExistingUser";
import {
  AdminDomaineRoleForm,
  ConsultationOfRoleForm,
  EvaluateurRoleForm,
  GroupeEntrepriseProfileForm,
  GroupeOfProfileForm,
  MembreJuryRoleForm,
  OfRoleForm,
  PositionneurRoleForm,
  SousGroupeEntrepriseRoleForm,
  SousGroupeOfRoleForm,
} from "./components";
import { EntrepriseProfileForm } from "./components/EntrepriseProfileForm";

function UserDetail() {
  const { actions: accessActions } = useAccessSettings();
  const { uuid } = useParams();
  const { constants } = useConstants();
  const {
    userProfil,
    userProps,
    reactHookProps,
    actions: actionsPage,
    isLoadingUser,
    isChecked,
  } = useUserDetail();
  const { isLoading, hasError, errorPostUser, errorPutUser, actions } =
    userProps;

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
    clearErrors,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateUserUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (userProfil?.uuid) {
      reset(userAdapters.transformForBO(userProfil, constants));
    }
  }, [userProfil]);

  useEffect(() => {
    var apiErrors = (errorPostUser as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostUser]);

  useEffect(() => {
    var apiErrors = (errorPutUser as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutUser]);

  /**
   * We use these useEffects to control the activated roles.
   *
   * For example in this first useEffect 11 is the key of  "Sous-groupe d'entreprises" as represented by the list inside User.data.tsx
   *
   * Sous-groupe d'entreprise cannot be selected together with "entreprise", "groupe d'entreprise" or "groupe d'of" ou "sous groupe d'of" as
   * explained in the UI, so when 11 is activated we deactivate the other checkboxes and we can add error message using setError("11","...")
   *
   */

  useEffect(() => {
    if (watch("ENTREPRISE")) {
      //setValue("ENTREPRISE", false); //entreprise
      setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      //setValue("OF", false); //OF
      //setValue("GROUPE_OF", false); //groupe of
      //setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("ENTREPRISE")]); //si profil entreprise sélectionné

  useEffect(() => {
    if (watch("ADMINISTRATEUR_DOMAINE")) {
      setValue("ADMINISTRATEUR_BRANCHE", false);
    }
  }, [watch("ADMINISTRATEUR_DOMAINE")]); //si profil admin de domaine sélectionné

  useEffect(() => {
    if (watch("ADMINISTRATEUR_BRANCHE")) {
      setValue("ADMINISTRATEUR_DOMAINE", false);
    }
  }, [watch("ADMINISTRATEUR_BRANCHE")]); //si profil admin de branche sélectionné

  useEffect(() => {
    if (watch("GROUPE_ENTREPRISE")) {
      setValue("ENTREPRISE", false); //entreprise
      //setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      //setValue("OF", false); //OF
      setValue("GROUPE_OF", false); //groupe of
      setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("GROUPE_ENTREPRISE")]); //si profil groupe entreprise sélectionné

  useEffect(() => {
    if (watch("SOUS_GROUPE_ENTREPRISE")) {
      setValue("ENTREPRISE", false); //entreprise
      setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      //setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      //setValue("OF", false); //OF
      setValue("GROUPE_OF", false); //groupe of
      setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("SOUS_GROUPE_ENTREPRISE")]); //si profil sous-groupe entreprise sélectionné

  useEffect(() => {
    if (watch("OF")) {
      //setValue("ENTREPRISE", false); //entreprise
      //setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      //setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      //setValue("OF", false); //OF
      setValue("GROUPE_OF", false); //groupe of
      setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("OF")]); //si profil OF sélectionné

  useEffect(() => {
    if (watch("GROUPE_OF")) {
      //setValue("ENTREPRISE", false); //entreprise
      setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      setValue("OF", false); //OF
      //setValue("GROUPE_OF", false); //groupe of
      setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("GROUPE_OF")]); //si profil groupe OF sélectionné

  useEffect(() => {
    if (watch("SOUS_GROUPE_OF")) {
      //setValue("ENTREPRISE", false); //entreprise
      setValue("GROUPE_ENTREPRISE", false); //groupe entreprise
      setValue("SOUS_GROUPE_ENTREPRISE", false); //sous-groupe entreprise
      setValue("OF", false); //OF
      setValue("GROUPE_OF", false); //groupe of
      //setValue("SOUS_GROUPE_OF", false); //sous-groupe of
    }
  }, [watch("SOUS_GROUPE_OF")]); //si profil sous-groupe OF sélectionné

  const rolesOptions = constants.UTILISATEURS.PROFILS.filter((role: any) =>
    accessActions.canI({ action: userAccessEnum.CREER_UTILISATEUR_SOUS_GROUPE })
      .granted
      ? role
      : role.id != "SOUS_GROUPE_OF" && role.id != "SOUS_GROUPE_ENTREPRISE"
  ).map((role: any) => ({
    label: role.text,
    name: role.id + "",
    key: role.id,
  }));

  const watchRole: any = [
    ...rolesOptions.map((e: any) => ({ ...e, watch: watch(e.name) })),
  ];

  const onSubmit = (e: any) => {
    if (watch("uuid")) {
      setValue("_method", "PUT");
    }
    clearErrors();
    handleSubmit(actionsPage.saveUser)(e);
  };

  return (
    <Stack>
      <BackLink />

      <Page
        title="Détail de l'utilisateur"
        explain={
          !userProfil?.uuid
            ? "Veuillez créer un nouvel utilisateur"
            : "Veuillez modifier les informations de l'utilisateur"
        }
      >
        <DetailLoader isLoadingDetail={isLoadingUser}>
          <form onSubmit={onSubmit}>
            <input
              type="hidden"
              {...register("_method", {
                value: "POST",
              })}
            />

            <FormSection sectionTitle="Informations principales d'un utilisateur">
              {uuid === "creer" && (
                <SearchExistingUser
                  isChecked={isChecked}
                  reactHookProps={reactHookProps}
                  actions={actionsPage}
                />
              )}

              {(isChecked || uuid !== "creer") && (
                <>
                  <Stack.Item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          styles={commonInputStyles.textField}
                          required
                          label="Nom de l'utilisateur :"
                          type="text"
                          {...field}
                          placeholder="Ex: Dupont"
                          errorMessage={(errors[field.name] as any)?.message}
                        />
                      )}
                      defaultValue=""
                      control={control}
                      name="nom"
                    />
                  </Stack.Item>

                  <Stack.Item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          styles={commonInputStyles.textField}
                          required
                          label="Prénom de l'utilisateur :"
                          type="text"
                          {...field}
                          placeholder="Ex: Pierre"
                          errorMessage={(errors[field.name] as any)?.message}
                        />
                      )}
                      defaultValue=""
                      control={control}
                      name="prenom"
                    />
                  </Stack.Item>

                  <Stack.Item>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          styles={commonInputStyles.textField}
                          label="Numéro de le téléphone :"
                          type="text"
                          {...field}
                          placeholder="Ex: 0600000000"
                          errorMessage={(errors[field.name] as any)?.message}
                        />
                      )}
                      defaultValue=""
                      control={control}
                      name="telephone_portable"
                    />
                  </Stack.Item>

                  {uuid !== "creer" && (
                    <>
                      <Stack.Item>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              styles={commonInputStyles.textField}
                              required
                              label="Email de l'utilisateur :"
                              type="email"
                              {...field}
                              placeholder="Ex: pierre.dupont@exemple.com"
                              errorMessage={
                                (errors[field.name] as any)?.message
                              }
                              disabled={true}
                            />
                          )}
                          defaultValue=""
                          control={control}
                          name="email"
                        />
                      </Stack.Item>

                      <BranchesInfo
                        data={userProfil}
                        branchSentence={
                          "Cet utilisateur existe sur les branches :"
                        }
                        noBranchSentence={
                          "Cet utilisateur est présent uniquement sur cette branche"
                        }
                      />
                    </>
                  )}

                  {!watch("uuid") && (
                    <Stack.Item style={{ margin: "30px 0px 20px" }}>
                      <Controller
                        defaultValue={false}
                        name="envoyer_mot_de_passe"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Checkbox
                            styles={commonInputStyles.checkbox}
                            label="Envoyer le mot de passe à la validation de la création du compte ?"
                            checked={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Stack.Item>
                  )}

                  <Stack.Item>
                    <Label>Profils de l'utilisateur :</Label>
                    <Stack tokens={{ childrenGap: 15 }}>
                      {rolesOptions.map((e: any, i: any) => {
                        const disabled = e.key == "CANDIDAT";
                        return (
                          <React.Fragment key={i}>
                            <Controller
                              key={i}
                              defaultValue={false}
                              name={e.name}
                              control={control}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <Checkbox
                                  styles={commonInputStyles.checkbox}
                                  label={e.label}
                                  checked={value}
                                  onChange={onChange}
                                  name={name}
                                  disabled={disabled}
                                />
                              )}
                            />
                            <Text
                              variant="small"
                              styles={{ root: { color: "red" } }}
                            >
                              {errors[e.name]?.message}
                            </Text>
                          </React.Fragment>
                        );
                      })}
                    </Stack>
                  </Stack.Item>
                </>
              )}
            </FormSection>

            {(isChecked || uuid !== "creer") && (
              <>
                <FormProvider {...reactHookProps}>
                  <EntrepriseProfileForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <OfRoleForm watchRoles={watchRole} userProfil={userProfil} />
                  <ConsultationOfRoleForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <PositionneurRoleForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <GroupeEntrepriseProfileForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <AdminDomaineRoleForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <GroupeOfProfileForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <EvaluateurRoleForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <MembreJuryRoleForm
                    watchRoles={watchRole}
                    userProfil={userProfil}
                  />
                  <Can I={userAccessEnum.CREER_UTILISATEUR_SOUS_GROUPE}>
                    <SousGroupeEntrepriseRoleForm
                      watchRoles={watchRole}
                      userProfil={userProfil}
                    />
                    <SousGroupeOfRoleForm
                      watchRoles={watchRole}
                      userProfil={userProfil}
                    />
                  </Can>
                </FormProvider>

                <Stack
                  horizontal
                  horizontalAlign="space-between"
                  style={{ marginTop: 20 }}
                >
                  <CancelButton />

                  <Can
                    I={[
                      userAccessEnum.CREER_UTILISATEUR,
                      userAccessEnum.GERER_UTILISATEUR,
                    ]}
                  >
                    <LoadButton
                      isLoading={isLoading}
                      text={!userProfil?.uuid ? "Créer" : "Modifier"}
                    />
                  </Can>
                </Stack>
              </>
            )}
          </form>

          {hasError && (
            <PageMessage
              message={
                ((errorPostUser as any) || (errorPutUser as any)).response.data
                  .message
              }
              type="error"
            />
          )}
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default UserDetail;
