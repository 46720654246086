import { MessageBar, Pivot, PivotItem, Stack } from "@fluentui/react";
import { useContext } from "react";
import FormSection from "../../../Common/FormSection/FormSection";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import PivotItemContainer from "../../../Common/PivotItemContainer/PivotItemContainer";
import BlocksContainer from "../BlocksContainer/BlocksContainer";
import MessageBarStyles  from "../../../styles/MessageBar.styles";

const ReferentialSection = () => {
  const { conditionnalDisplayVariables, reactHookProps, allBlocks }: any =
    useContext(CertificationContext);
  const { watch } = reactHookProps;
  const { isUsingExistingReferential, hasOptions } = conditionnalDisplayVariables;

  const getBlocsInfoMessage = () => {
    if (isUsingExistingReferential) {
      return "Le référentiel de cette certification est issu de celui d’une certification existante. Il ne peut donc pas être modifié.";
    }
    if (hasOptions) {
      return "Ajoutez des blocs de compétences pour chaque option de cette certification.";
    } else {
      return "Ajoutez des blocs de compétences pour cette certification";
    }
  };

  return (
    <FormSection sectionTitle="Personnalisation des référentiels de la certification">
      <Stack.Item>
        <MessageBar styles={MessageBarStyles}>{getBlocsInfoMessage()}</MessageBar>
      </Stack.Item>
      {hasOptions && watch("options") && (
        <Stack.Item>
          <Pivot aria-label="Basic Pivot Example">
            {watch("options").map((option: any) => {
              let selectedBlocksOfOption = allBlocks.filter((b: any) => b.option == option.option);
              return (
                <PivotItem
                  key={`pivotoption${option.option}`}
                  headerText={`Option ${option.option}`}
                >
                  <PivotItemContainer>
                    <BlocksContainer
                      isUsingExistingReferential={isUsingExistingReferential}
                      selectedBlocks={selectedBlocksOfOption}
                      option={option}
                    />
                  </PivotItemContainer>
                </PivotItem>
              );
            })}
          </Pivot>
        </Stack.Item>
      )}
      {!hasOptions && (
        <BlocksContainer
          isUsingExistingReferential={isUsingExistingReferential}
          selectedBlocks={allBlocks}
        />
      )}
    </FormSection>
  );
};

export default ReferentialSection;
