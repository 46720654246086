import { Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { branchAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { CompanyContext } from "../useCompanyDetail";

function EntitiesLinkedSection() {
  const { reactHookProps, errors }: any = useContext(CompanyContext);
  const { actions: accesActions } = useAccessSettings();
  const { setValue, control } = reactHookProps;

  return (
    <FormSection sectionTitle="Entités liées à l’entreprise">
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Organismes de formation liés à l’entreprise :"
              placeholder="Recherchez un OF en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/administration/ofs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?raison_sociale=${terms}&paginate=false`
              }
              method="GET"
              renderItem={(item: any) =>
                item.raison_sociale + " - " + item.siret
              }
              renderValue={(item: any) =>
                item.raison_sociale + " - " + item.siret
              }
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              required={
                accesActions.canI({
                  action: branchAccessEnum.OBLIGER_LIAISON_OF_ET_ENTREPRISE,
                }).granted
              }
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="ofs"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Évaluateurs :"
              placeholder="Recherchez un évaluateur en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom=${terms}&roles[]=EVALUATEUR`
              }
              method="GET"
              renderItem={(item: any) => item.nom + " " + item.prenom}
              renderValue={(item: any) => item.nom + " " + item.prenom}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="evaluateurs"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Positionneurs :"
              placeholder="Recherchez un positionneur en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom=${terms}&roles[]=POSITIONNEUR`
              }
              method="GET"
              renderItem={(item: any) => item.nom + " " + item.prenom}
              renderValue={(item: any) => item.nom + " " + item.prenom}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="positionneurs"
        />
      </Stack.Item>
    </FormSection>
  );
}

export default EntitiesLinkedSection;
