import { Dropdown, Link, Stack } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { useQuery } from "react-query";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import { getConstantRequest } from "../../../services/publicServices";
import { commonInputStyles } from "../../../styles";
import CleProfilInput from "../CleProfilInput/CleProfilInput";
import EntrepriseProfileInformation from "../EntrepriseProfileInformation/EntrepriseProfileInformation";
import GroupeEntrepriseProfileInformation from "../GroupeEntrepriseProfileInformation/GroupeEntrepriseProfileInformation";
import OfProfileInformation from "../OfProfileInformation/OfProfileInformation";
import PreviousStepButton from "../PreviousStepButton/PreviousStepButton";
import SelectedAccount from "../SelectedAccount/SelectedAccount";
import { SignUpContext } from "../useSignUp";
import PublicSearchExistingStructure from "../../../Common/PublicSearchExistingStructure/PublicSearchExistingStructure";

export enum ModelType {
  "organisme_de_formation" = 13,
  "of" = 13,
  "entreprise" = 10,
  "groupe_entreprise" = 22,
}

export enum CleModelType {
  "organisme_de_formation" = "siret",
  "OF" = "siret",
  "entreprise" = "siret",
  "ENTREPRISE" = "siret",
  "groupe_entreprise" = "siren",
  "GROUPE_ENTREPRISE" = "siren",
}

export enum CleStructureLength {
  "siret" = 14,
  "siren" = 9,
}

const profileInformationSchema = Joi.object({
  profil: Joi.string()
    .required()
    .valid("ENTREPRISE", "OF", "GROUPE_ENTREPRISE")
    .messages({
      "string.empty": "Le profil est obligatoire",
      "any.only":
        "Le profil doit être 'ENTREPRISE', 'OF' ou 'GROUPE_ENTREPRISE'",
    }),
}).unknown(true);

function ProfileInformation() {
  const [isChecked, setIsChecked] = useState(false);

  const {
    actions,
    accountInformations,
    canDemandeInscriptionStructure,
    setIsExistantStructure,
    isExistantStructure,
    isRefIdccRequired,
  }: any = useContext(SignUpContext);
  const reactHookProps: any = useForm({
    mode: "onBlur",
    resolver: joiResolver(profileInformationSchema),
  });

  const { data: constants, isLoading } = useQuery(["motifs"], () =>
    getConstantRequest()
  );

  const selectedProfile = reactHookProps.watch("profil");

  const profileList: any =
    constants?.DEMANDE_INSCRIPTION?.PROFILS_AUTORISES ?? [];

  const actionsProfileInformation = {
    setUpdateIsCheck: (isVerified: boolean, isExistant: boolean) => {
      setIsChecked(isVerified);
      setIsExistantStructure(isExistant);
    },
  };

  useEffect(() => {
    reactHookProps.setValue("cle_profil", "");
  }, [selectedProfile]);

  const getProfileSpecificInput = () => {
    var props: any = { reactHookProps };
    switch (selectedProfile) {
      case "ENTREPRISE":
      case "OF":
        props = {
          ...props,
          label: "Numéro SIRET :",
          placeholder: "14 chiffres",
          maxLengthCle: 14,
        };
        break;
      case "GROUPE_ENTREPRISE":
        props = {
          ...props,
          label: "Numéro SIREN :",
          placeholder: "9 chiffres",
          maxLengthCle: 9,
        };
        break;
    }
    return <CleProfilInput {...props} />;
  };

  return (
    <>
      <SelectedAccount>
        <PreviousStepButton text="Modifier les informations du compte" />
      </SelectedAccount>
      <form
        onSubmit={reactHookProps.handleSubmit(
          actions.onSubmitProfileInformations
        )}
      >
        <Stack tokens={{ childrenGap: 15 }}>
          {isLoading ? (
            <Loader />
          ) : (
            <Stack.Item>
              <Controller
                render={({ field: { name, value } }) => (
                  <Dropdown
                    label="Profil :"
                    placeholder="Sélectionnez un profil"
                    options={profileList}
                    //calloutProps={{ doNotLayer: true }}
                    onChange={(e: any, options: any) => {
                      reactHookProps.setValue(name, options.id, {
                        shouldValidate: true,
                      });
                    }}
                    required
                    styles={commonInputStyles.dropdownStyle}
                  />
                )}
                control={reactHookProps.control}
                name="profil"
              />
            </Stack.Item>
          )}
          {selectedProfile && !canDemandeInscriptionStructure && (
            <Stack.Item>{getProfileSpecificInput()}</Stack.Item>
          )}

          {selectedProfile && canDemandeInscriptionStructure && (
            <PublicSearchExistingStructure
              reactHookProps={reactHookProps as UseFormReturn}
              isChecked={isChecked}
              isExistant={isExistantStructure}
              actions={actionsProfileInformation}
              keyToCheck={
                CleModelType[
                  // prettier-ignore
                  reactHookProps.watch("profil")  as
                    "ENTREPRISE"
                    | "OF"
                    | "GROUPE_ENTREPRISE"
                ]
              }
              acceptedChararacterNumber={
                CleStructureLength[
                  CleModelType[
                    // prettier-ignore
                    reactHookProps.watch("profil") as
                      "ENTREPRISE"
                      | "OF"
                      | "GROUPE_ENTREPRISE"
                  ]
                ]
              }
              structureType={
                ModelType[
                  // prettier-ignore
                  (reactHookProps.watch("profil") as string).toLowerCase() as
                    "of"
                    | "entreprise"
                    | "groupe_entreprise"
                ]
              }
            />
          )}

          {canDemandeInscriptionStructure &&
            isChecked &&
            !isExistantStructure && (
              <>
                {reactHookProps.watch("profil") == "OF" && (
                  <OfProfileInformation reactHookProps={reactHookProps} />
                )}
                {reactHookProps.watch("profil") == "ENTREPRISE" && (
                  <EntrepriseProfileInformation
                    reactHookProps={reactHookProps}
                    isRefIdccRequired={isRefIdccRequired}
                  />
                )}
                {reactHookProps.watch("profil") == "GROUPE_ENTREPRISE" && (
                  <GroupeEntrepriseProfileInformation
                    reactHookProps={reactHookProps}
                  />
                )}
              </>
            )}
          <Stack.Item>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="center"
            >
              <Stack.Item>
                <Link
                  href="/"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Revenir sur la page de connexion
                </Link>
              </Stack.Item>
              <Stack.Item>
                <Link
                  href={`/demande-aide?branche=${accountInformations.branch_id}`}
                >
                  Demande d'aide
                </Link>
              </Stack.Item>
              <Stack.Item>
                <LoadButton
                  text="Envoyer ma demande d'inscription"
                  type="submit"
                  isDisabled={!isChecked && canDemandeInscriptionStructure}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </form>
    </>
  );
}

export default ProfileInformation;
