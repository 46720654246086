import { useContext } from "react";
import FormSection from "../../../../Common/FormSection/FormSection";
import { CandidatureContext } from "../useCreationCandidature";
import { Dropdown, Stack } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../../styles";
import { domainesAccessAtom } from "../../../../hooks/domaine/useDomaine";
import { useAtom } from "jotai";

function DomaineInformationSection({ disabled }: any) {
  const { reactHookProps }: any = useContext(CandidatureContext);
  const { control, setValue } = reactHookProps;

  const [domainesAccess] = useAtom<any>(domainesAccessAtom);

  return (
    <FormSection sectionTitle="Domaine de la candidature">
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <Dropdown
              disabled={disabled}
              placeholder="Sélectionnez un domaine"
              label="Domaine:"
              selectedKey={value ? (value as any).key : null}
              options={domainesAccess || []}
              styles={commonInputStyles.dropdownStyle}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
            />
          )}
          control={control}
          name="domaine"
        />
      </Stack.Item>
    </FormSection>
  );
}

export default DomaineInformationSection;
