import { IconButton, Stack } from "@fluentui/react";
import customModalStyles from "./CustomModal.styles";
import useWorkflowModal from "./useWorkflowModal";

function WorkflowModal() {
  const { isOpen, content, actions } = useWorkflowModal();

  return !isOpen ? null : (
    <div
      //isOpen={isOpen}
      //onDismiss={actions.closeModal}
      //isBlocking={false}
      //containerClassName={customModalStyles.contentStyles.container}
      onClick={() => {
        if (content.isDissmiss) {
          (content as any).onPreviousDissmiss();
          actions.closeModal();
        }
      }}
      style={{
        position: "fixed",
        zIndex: "999999",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgb(0 0 0 / 40%)",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "absolute",
          width: "95%",
          height: "95%",
          overflow: "auto",
          left: "50%",
          top: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          background: "white",
        }}
      >
        {content.isDissmiss && (
          <Stack horizontalAlign="end" style={{ top: 10, right: 10 }}>
            <IconButton
              iconProps={customModalStyles.cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => {
                (content as any).onPreviousCloseModal();
                actions.closeModal();
              }}
            />
          </Stack>
        )}

        <div className={customModalStyles.contentStyles.header}>
          {content.header}
        </div>

        {content.body}

        <div>{content.footer}</div>
      </div>
    </div>
  );
}

export default WorkflowModal;
