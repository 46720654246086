const ErrorMessage = ({ errorMessage }: any) => {
  return errorMessage ? (
    <p
      className="ms-TextField-errorMessage"
      style={{ color: "rgb(164, 38, 44)", fontSize: 12, margin: 0, paddingTop: 5 }}
    >
      {errorMessage}
    </p>
  ) : null;
};

export default ErrorMessage;
