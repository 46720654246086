const formSectionStyle = {
    buttonLink: {
        root: {
          fontSize: "17px",
          fontWeight: "500",
          backgroundColor: "transparent",
        },
      },
}

export default formSectionStyle;