import { joiResolver } from "@hookform/resolvers/joi";
import { companySchema } from "../../schemas/companySchema";
import { useForm } from "react-hook-form";
import useCompany from "../../hooks/company/useCompany";
import useCompanyQuery from "../../hooks/company/useCompanyQuery";
import React, { useState } from "react";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useNavigate } from "react-router-dom";
import companyAdapters from "../../adapters/companyAdapters";

export const CompanyContext = React.createContext({});
export const CompanyProvider = ({ value, children }: any) => {
  return (
    <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>
  );
};

const resetImportEntreprise: any = {
  nom: "",
  ref_idcc: null,
  adresse_uuid: null,
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  region: "",
  ville: "",
  groupe_entreprise: null,
};

function useCompanyDetail() {
  const [isChecked, setIsChecked] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const navigate = useNavigate();
  const companyProps = useCompany();
  const { company, isLoading, isRefetching, refetch } =
    useCompanyQuery(companyProps);

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: joiResolver(companySchema),
  });

  const actions = {
    saveCompany: async (data: any) => {
      if (!(data as any).uuid) {
        companyProps.actions.postCompany(data);
      } else {
        companyProps.actions.putCompany(data);
      }
    },
    setUpdateIsCheck: (isVerified: boolean, value?: any) => {
      if (isVerified && value) {
        const mesBranchesId = value.branches?.map((b: any) => b.id);
        if (
          mesBranchesId.length > 0 &&
          mesBranchesId.includes(userSelectedBranch.id)
        ) {
          navigate(
            `/admin/entreprises/${value.uuid}?branchId=${userSelectedBranch.id}`
          );
        } else {
          const entrepriseAdapterValue = companyAdapters.transformForBO(value);
          Object.keys(resetImportEntreprise).forEach((field: any) => {
            reactHookProps.setValue(`${field}`, entrepriseAdapterValue[field]);
          });
          reactHookProps.setValue("uuid", entrepriseAdapterValue.uuid);
        }
      }
      setIsChecked(isVerified);
    },
    resetAutocompleteInput: () => {
      Object.keys(resetImportEntreprise).forEach((field: any) => {
        reactHookProps.setValue(`${field}`, resetImportEntreprise[field]);
      });
    },
  };

  return {
    company,
    companyProps,
    reactHookProps,
    actions,
    isLoadingCompany: isLoading || isRefetching,
    isChecked,
    refetch,
  };
}

export default useCompanyDetail;
