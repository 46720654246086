import {
  DatePicker,
  DefaultButton,
  Dropdown,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { Controller, useForm } from "react-hook-form";
import LoadButton from "../../LoadButton/LoadButton";
import { strings } from "../../../config/datePickerConfig";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import FormSection from "../../FormSection/FormSection";
import tools from "../../../utils/tools";
import useConstants from "../../../hooks/useConstants";

function JuryListFilters({
  updateFilters,
  isSelect,
}: {
  updateFilters: any;
  isSelect?: any;
}) {
  const { constants } = useConstants();

  const juryStateListSort = [
    { id: undefined, text: "Tous", key: "all" },
    ...constants.JURY.STATUTS,
  ];

  const { handleSubmit, control, reset, setValue } = useForm({
    mode: "onBlur",
  });

  const onReset = () => {
    reset();
    handleSubmit(updateFilters)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <Stack>
        <form onSubmit={handleSubmit(updateFilters)}>
          <Stack
            style={{ marginBottom: 24 }}
            horizontalAlign="start"
            horizontal
            wrap
            className="ms-Grid"
          >
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field }) => (
                  <TextField
                    styles={commonInputStyles.textField}
                    label="Libellé du jury :"
                    type="text"
                    placeholder="Ex: Jury"
                    {...field}
                  />
                )}
                defaultValue={""}
                control={control}
                name="libelle"
              />
            </Stack.Item>

            {isSelect && (
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Label htmlFor="date_jury" styles={commonInputStyles.label}>
                  Date du jury:
                </Label>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      formatDate={(date?: any): any =>
                        date ? `${tools.reformateDateFromJsDate(date)}` : null
                      }
                      strings={strings}
                      showGoToToday={false}
                      value={value}
                      styles={commonInputStyles.dataPicker}
                      onSelectDate={(date: any) => {
                        if (date) {
                          onChange(
                            `${date.getFullYear()}-${
                              date.getMonth() + 1
                            }-${date.getDate()}`
                          );
                        }
                      }}
                      placeholder="dd/mm/yyyy"
                      allowTextInput
                      parseDateFromString={(dateStr: any) =>
                        tools.parseDateFromStringDatePicker(dateStr)
                      }
                    />
                  )}
                  control={control}
                  name="date_jury"
                />
              </Stack.Item>
            )}

            {!isSelect && (
              <>
                <Stack.Item
                  align="start"
                  className="ms-Grid-col ms-sm12 ms-lg6"
                  style={{ paddingRight: 20, marginTop: 10 }}
                >
                  <Controller
                    render={({ field: { name, value, onBlur, ref } }) => (
                      <Dropdown
                        label="Statut du jury :"
                        selectedKey={value ? value.key : undefined}
                        placeholder="..."
                        options={juryStateListSort}
                        calloutProps={{ doNotLayer: true }}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    )}
                    defaultValue={juryStateListSort[0]}
                    control={control}
                    name="statut"
                  />
                </Stack.Item>

                <Stack.Item
                  className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                  style={{ paddingRight: 20, marginTop: 10 }}
                >
                  <Label
                    htmlFor="date_jury_entre_le"
                    styles={commonInputStyles.label}
                  >
                    Date du jury entre le:
                  </Label>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        formatDate={(date?: any): any =>
                          date ? `${tools.reformateDateFromJsDate(date)}` : null
                        }
                        strings={strings}
                        showGoToToday={false}
                        maxDate={new Date()}
                        value={value}
                        styles={commonInputStyles.dataPicker}
                        onSelectDate={(date: any) => {
                          if (date) {
                            onChange(
                              `${date.getFullYear()}-${
                                date.getMonth() + 1
                              }-${date.getDate()}`
                            );
                          }
                        }}
                        placeholder="dd/mm/yyyy"
                        allowTextInput
                        parseDateFromString={(dateStr: any) =>
                          tools.parseDateFromStringDatePicker(dateStr)
                        }
                      />
                    )}
                    control={control}
                    name="date_jury_entre_le"
                  />
                </Stack.Item>

                <Stack.Item
                  className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                  style={{ paddingRight: 20, marginTop: 10 }}
                >
                  <Label
                    htmlFor="date_jury_et_le"
                    styles={commonInputStyles.label}
                  >
                    Et le:
                  </Label>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        formatDate={(date?: any): any =>
                          date ? `${tools.reformateDateFromJsDate(date)}` : null
                        }
                        strings={strings}
                        showGoToToday={false}
                        maxDate={new Date()}
                        value={value}
                        styles={commonInputStyles.dataPicker}
                        onSelectDate={(date: any) => {
                          if (date) {
                            onChange(
                              `${date.getFullYear()}-${
                                date.getMonth() + 1
                              }-${date.getDate()}`
                            );
                          }
                        }}
                        placeholder="dd/mm/yyyy"
                        allowTextInput
                        parseDateFromString={(dateStr: any) =>
                          tools.parseDateFromStringDatePicker(dateStr)
                        }
                      />
                    )}
                    control={control}
                    name="date_jury_et_le"
                  />
                </Stack.Item>
              </>
            )}
          </Stack>

          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="end"
            wrap
          >
            <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
              <Stack.Item>
                <LoadButton isLoading={false} text="Rechercher" />
              </Stack.Item>
              <Stack.Item>
                <DefaultButton
                  styles={commonButtonStyles.defaultButton}
                  text="Réinitialiser ma recherche"
                  onClick={onReset}
                />
              </Stack.Item>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </FormSection>
  );
}

export default JuryListFilters;
