import { CommandButton, Dropdown, Label, TextField } from "@fluentui/react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { dynamicFieldsTypes } from "../../hooks/dynamicFields/useDynamicFields";
import { commonInputStyles } from "../../styles";
import FileInput from "../FileInput/FileInput";
import { toast } from "react-toastify";

const DynamicField = ({
  type = "",
  libelle = "",
  name = "",
  multiple = false,
  options = [],
  fichier_modele = null,
  fichier_modele_url = null,
  fichier_formats = [],
  required = false,
  onChange = () => {},
  onDeleteFile = () => {},
  value,
  fichier_url = "",
  disabled = false,
}: any) => {
  let realValue = value;
  if (!realValue) {
    switch (type) {
      case dynamicFieldsTypes.SELECT.id:
        realValue = multiple ? [] : null;
        break;
      case dynamicFieldsTypes.FILE.id:
        realValue = null;
        break;
      case dynamicFieldsTypes.TEXT.id:
        realValue = "";
        break;
      default:
        realValue = null;
        break;
    }
  }

  switch (type) {
    case dynamicFieldsTypes.TEXT.id:
      return (
        <TextField
          required={required}
          styles={commonInputStyles.textField}
          label={`${libelle} :`}
          placeholder="900 caractères maximum"
          onChange={onChange}
          value={realValue || ""}
          multiline
          name={name}
          disabled={disabled}
        />
      );
    case dynamicFieldsTypes.SELECT.id:
      return (
        <Dropdown
          label={`${libelle} :`}
          placeholder={
            multiple
              ? "Sélectionnez un ou plusieurs éléments"
              : "Sélectionnez un élément"
          }
          options={options}
          onChange={(e: any, options: any) => {
            // onChange({
            //   target: {
            //     name,
            //     value: options.selected
            //       ? [...realValue, options.key]
            //       : realValue.filter((v: any) => v !== options.key),
            //   },
            // });
            onChange({
              target: {
                name,
                value: multiple
                  ? realValue.find((v: any) => v == options.key)
                    ? realValue.filter((v: any) => v != options.key)
                    : [...realValue, options.key]
                  : options.key,
              },
            });
          }}
          styles={commonInputStyles.dropdownStyle}
          required={required}
          multiSelect={multiple}
          selectedKey={multiple ? null : value}
          selectedKeys={multiple ? value : null}
          disabled={disabled}
        />
      );

    case dynamicFieldsTypes.FILE.id:
      return (
        <div>
          <Label required={required} styles={commonInputStyles.label}>
            {libelle} :
          </Label>
          {(fichier_modele || fichier_modele_url) && (
            <CommandButton href={fichier_modele_url} download target="_blank">
              <AiOutlineCloudDownload style={{ marginRight: 5 }} />
              Télécharger le modèle
            </CommandButton>
          )}
          <FileInput
            name={name}
            file={realValue ? [realValue] : []}
            existingFileURL={fichier_url}
            onUpdateFile={(e: any) => {
              if (e.target.value[0] && e.target.value[0].size >= 20000000) {
                toast.info(
                  `Les documents à déposer sur la plateforme sont trop volumineux. Merci de les déposer en ${fichier_formats
                    .map((item: any) => " " + item)
                    .join(", ")} et de les compresser auparavant.`
                );
              } else {
                onChange({
                  target: {
                    name,
                    value: e.target.value[0],
                  },
                });
              }
            }}
            onDeleteFile={(e: any) =>
              onDeleteFile({
                target: {
                  name,
                },
              })
            }
            acceptedFileTypes={fichier_formats}
            disabled={disabled}
          />
        </div>
      );
    default:
      return null;
  }
};

export default DynamicField;
