import {
    Stack,
    Image,
    ImageFit,
    DefaultButton,
    Icon,
    Text,
} from '@fluentui/react'
import { useEffect, useState } from 'react'

function ImageDisplay({
    styleImgContainer,
    file,
    isFile,
    alt,
    isEditable = false,
    isDeleted = false,
    onClick = () => { }
}: any) {

    const [objectUrl, setObjectUrl] = useState<any>(null);

    useEffect(() => {
        if (file && !isDeleted) {
            isFile ? setObjectUrl(URL.createObjectURL(file)) : setObjectUrl(file);
        } else {
            setObjectUrl(null);
        }
    }, [file, isDeleted]);

    const hasImage = objectUrl ? true : false;

    if (hasImage) {
        return (
            <Stack>
                <Stack
                    horizontal
                    verticalAlign="center"
                >
                    <Stack
                        styles={styleImgContainer}
                    >
                        <Image
                            src={objectUrl}
                            maximizeFrame
                            imageFit={ImageFit.contain}
                            alt={alt}
                        />
                    </Stack>
                    {isEditable &&
                        <DefaultButton onClick={onClick}>
                            <Icon iconName="cancel" />
                        </DefaultButton>
                    }

                </Stack>
            </Stack>
        )

    } else if (isDeleted) {
        return (
            <Stack>
                <Text
                    style={{ color: 'red' }}
                >
                    L'image a bien été supprimée, veuillez enregistrer en bas de la page pour prendre en compte votre modification
                </Text>
            </Stack>
        )

    } else {
        return null
    }

}

export default ImageDisplay