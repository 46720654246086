import {
  ChoiceGroup,
  Dropdown,
  MessageBar,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import DynamicListEditable from "../../../Common/DynamicListEditable/DynamicListEditable";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import useConstants from "../../../hooks/useConstants";
import { MessageBarStyles, commonInputStyles } from "../../../styles";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import NotationForm from "../NotationForm/NotationForm";

const GeneralInformationsSection = () => {
  const {
    reactHookProps,
    errors,
    conditionnalDisplayVariables,
    actions,
    nbBlockDisabled,
  }: any = useContext(CertificationContext);

  const { constants } = useConstants();

  const { setValue, control, watch } = reactHookProps;
  const {
    isUsingExistingReferential,
    isChoosingCertification,
    needExtraLibelleType,
    canUseExistingReferential,
    hasNumeroEnregistrement,
    nbChiffresEnregistrement,
    idTypeCertificationDelivered,
    isUsingTypeBlocInterBranch,
    hasOptions,
  } = conditionnalDisplayVariables;

  return (
    <FormSection sectionTitle="Personnalisation générale de la certification">
      {watch("domaine") && (
        <MessageBar className="bg-info" styles={MessageBarStyles}>
          Cette certification est rattachée au domaine :{" "}
          <b>{watch("domaine").nom}</b>
        </MessageBar>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { name, value } }) => (
            <Dropdown
              label="Type de certification :"
              selectedKey={value ? (value as any).key : undefined}
              placeholder="Sélectionnez un type"
              options={constants["CERTIFICATIONS"]["TYPES"]}
              calloutProps={{ doNotLayer: true }}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
                setValue("certification_delivree", undefined, {
                  shouldValidate: true,
                });
              }}
              styles={commonInputStyles.dropdownStyle}
            />
          )}
          defaultValue={undefined}
          control={control}
          name="type"
        />
      </Stack.Item>
      {needExtraLibelleType && (
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Libellé du type :"
                type="text"
                {...field}
                placeholder="Ex : Ma certification"
                errorMessage={(errors[field.name] as any)?.message}
                required
              />
            )}
            control={control}
            defaultValue=""
            name="type_libelle"
          />
        </Stack.Item>
      )}
      {isChoosingCertification && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Sélectionnez la certification délivrée :"
                placeholder="Recherchez une certification (ex: certification 1)"
                resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?libelle=${terms}&types[0][type]=${idTypeCertificationDelivered}`
                }
                method="GET"
                renderItem={(item: any) =>
                  item.libelle + " - " + item.branche_creatrice.nom
                }
                renderValue={(item: any) =>
                  item.libelle + " - " + item.branche_creatrice.nom
                }
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                required={true}
                onChange={(e: any) => {
                  if (!("uuid" in e.target.value)) {
                    setValue(name, undefined, { shouldValidate: true });
                  } else {
                    setValue(name, e.target.value, { shouldValidate: true });
                  }
                }}
                defaultValue={{}}
                //errorMessage={(errors.ref_idcc as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="certification_delivree"
          />
        </Stack.Item>
      )}
      {canUseExistingReferential && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Toggle
                styles={commonInputStyles.toggleInput}
                label="Reprendre le référentiel d'une certification existante :"
                inlineLabel
                onText="Oui"
                offText="Non"
                onChange={(e, checked) => {
                  onChange(checked);
                  actions.onChangeIsUsingExistingReferential(checked);
                }}
                checked={value}
              />
            )}
            defaultValue={false}
            control={control}
            name="reprise_referentiel_existant"
          />
        </Stack.Item>
      )}

      {isUsingExistingReferential && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Sélectionnez la certification dont vous souhaitez copier le référentiel :"
                placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?libelle=${terms}`
                }
                method="GET"
                renderItem={(item: any) =>
                  item.libelle + " - " + item.branche_creatrice.nom
                }
                renderValue={(item: any) =>
                  item.libelle + " - " + item.branche_creatrice.nom
                }
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                required={true}
                onChange={(e: any) => {
                  actions.onCopyCertificationBlocks(e.target.value);
                  if (!("uuid" in e.target.value)) {
                    setValue(name, undefined, { shouldValidate: true });
                  } else {
                    setValue(name, e.target.value, { shouldValidate: true });
                  }
                }}
                defaultValue={{}}
                //errorMessage={(errors.ref_idcc as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="reprise_referentiel_uuid"
          />
        </Stack.Item>
      )}
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Libellé :"
              type="text"
              {...field}
              placeholder="Ex : Ma certification"
              errorMessage={(errors[field.name] as any)?.message}
              required
            />
          )}
          control={control}
          name="libelle"
          defaultValue={""}
        />
      </Stack.Item>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Description :"
              type="text"
              {...field}
              placeholder="Ex : Description de cette certification"
              errorMessage={(errors[field.name] as any)?.message}
            />
          )}
          control={control}
          name="description"
          defaultValue={""}
        />
      </Stack.Item>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Mention :"
              type="text"
              {...field}
              placeholder="Ex : A définir"
              errorMessage={(errors[field.name] as any)?.message}
            />
          )}
          control={control}
          name="mention"
          defaultValue={""}
        />
      </Stack.Item>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Numéro d'identité CPNE :"
              type="text"
              {...field}
              placeholder="Ex : 80 caractères max"
              errorMessage={(errors[field.name] as any)?.message}
            />
          )}
          control={control}
          name="numero_identite_cpne"
          defaultValue={""}
        />
      </Stack.Item>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Enregistrement CPNE :"
              type="text"
              {...field}
              placeholder="Ex : 80 caractères max"
              errorMessage={(errors[field.name] as any)?.message}
            />
          )}
          control={control}
          name="enregistrement_cpne"
          defaultValue={""}
        />
      </Stack.Item>
      <Stack horizontal style={{ justifyContent: "start" }}>
        <Stack.Item style={{ width: "50%" }}>
          <Controller
            render={({ field: { value, name } }) => (
              <ChoiceGroup
                label="Modalités d'évaluation :"
                styles={commonInputStyles.textField}
                selectedKey={value ? value.key : 1}
                options={
                  constants["CERTIFICATIONS"]["MODALITE_EVALUATION_TYPES"]
                }
                onChange={(e: any, options: any) => {
                  setValue(name, options, { shouldValidate: true });
                }}
                name={name}
              />
            )}
            defaultValue={1}
            control={control}
            name="modalites_evaluation"
          />
        </Stack.Item>
        <Stack.Item>
          <NotationForm
            modalites_evaluation={watch("modalites_evaluation")}
            modalites_evaluation_notation={watch(
              "modalites_evaluation_notation"
            )}
          />
        </Stack.Item>
      </Stack>
      <Stack.Item>
        <Controller
          render={({ field: { value, name } }) => (
            <ChoiceGroup
              styles={commonInputStyles.textField}
              label="Enregistrement de la certification :"
              selectedKey={value ? value.key : 3}
              options={constants["CERTIFICATIONS"]["ENREGISTREMENT"]}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              required
            />
          )}
          defaultValue={3}
          control={control}
          name="enregistrement"
        />
      </Stack.Item>
      {hasNumeroEnregistrement && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Numéro d'enregistrement :"
                  type="text"
                  {...field}
                  placeholder={`Ex : ${nbChiffresEnregistrement} chiffres max`}
                  errorMessage={(errors[field.name] as any)?.message}
                />
              )}
              control={control}
              name="numero_enregistrement"
              defaultValue={""}
            />
          </Stack.Item>
          <Stack.Item>
            <Controller
              render={({ field: { name, value, onBlur, ref, onChange } }) => (
                <SelectFieldAsync
                  label="Saisissez le nom du porteur dans le champ ci-dessous. Si celui-ci existe, choisissez le dans la liste. Le cas échéant, un nouveau porteur sera créé au nom que vous avez saisi"
                  placeholder="Recherchez un porteur de certification en tapant les premieres lettres"
                  resourceURI={`${API_URL}api/administration/certifications/certifications/porteurs/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?nom=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => (
                    <Stack>
                      <Stack.Item>
                        {item.numero_fiche_client
                          ? item.nom +
                            " | " +
                            item.numero_fiche_client +
                            " | " +
                            item.numero_contrat
                          : item.nom}
                        {!item.uuid && (
                          <i style={{ fontSize: 12 }}>
                            {" "}
                            (créer un nouveau porteur avec ce nom)
                          </i>
                        )}
                      </Stack.Item>
                    </Stack>
                  )}
                  renderValue={(item: any) => item}
                  dataIndex="uuid"
                  name={name}
                  value={value != undefined ? value : null}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    onChange(e.target.value.nom || "");
                    if (e.target.value.uuid) {
                      setValue("porteur_uuid", e.target.value.uuid);
                      setValue(
                        "numero_fiche_client",
                        e.target.value.numero_fiche_client
                      );
                      setValue("numero_contrat", e.target.value.numero_contrat);
                    } else {
                      setValue("porteur_uuid", null);
                      setValue("numero_fiche_client", null);
                      setValue("numero_contrat", null);
                    }
                  }}
                  defaultValue=""
                  errorMessage={(errors.nom_porteur as any)?.message}
                  handleResponse={(response: any, terms: any) => [
                    ...response.data.data,
                    { nom: terms },
                  ]}
                />
              )}
              defaultValue=""
              control={control}
              name="nom_porteur"
            />
          </Stack.Item>
          {watch("nom_porteur") && (
            <>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      disabled={watch("porteur_uuid")}
                      styles={commonInputStyles.textField}
                      label="N° de fiche client certificateur/émetteur :"
                      type="text"
                      {...field}
                      placeholder="Ex : 123456789101"
                      errorMessage={(errors[field.name] as any)?.message}
                    />
                  )}
                  control={control}
                  name="numero_fiche_client"
                  defaultValue={""}
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      disabled={watch("porteur_uuid")}
                      styles={commonInputStyles.textField}
                      label="N° de contrat spécifique certificateur :"
                      type="text"
                      {...field}
                      placeholder="Ex : 456789101"
                      errorMessage={(errors[field.name] as any)?.message}
                    />
                  )}
                  control={control}
                  name="numero_contrat"
                  defaultValue={""}
                />
              </Stack.Item>
            </>
          )}
        </>
      )}
      {!isUsingExistingReferential && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Toggle
                styles={commonInputStyles.toggleInput}
                label="Certification avec options :"
                inlineLabel
                onText="Oui"
                offText="Non"
                onChange={(e, checked) => {
                  onChange(checked);
                  actions.onChangeOptions(checked);
                }}
                checked={value}
              />
            )}
            defaultValue={false}
            control={control}
            name="avec_options"
          />
        </Stack.Item>
      )}
      {hasOptions && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <DynamicListEditable
                label="Ajoutez une ou plusieurs options :"
                placeholder="Ex : Mon option (3 options max)"
                addButtonLabel="Ajoutez cette option"
                name={name}
                value={value || []}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, { shouldValidate: true });
                }}
                defaultValue={{ option: "" }}
                attr="option"
                errorMessage={(errors[name] as any)?.message}
                validateItem={(item: any) => item}
                onAddItem={actions.onAddOption}
                onRemoveItem={actions.onRemoveOption}
                onEditItem={actions.onEditOption}
              />
            )}
            control={control}
            name="options"
          />
        </Stack.Item>
      )}
      {!isUsingExistingReferential && !isUsingTypeBlocInterBranch && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Toggle
                styles={commonInputStyles.toggleInput}
                label="Cette certification contient des blocs inter branche :"
                inlineLabel
                onText="Oui"
                offText="Non"
                onChange={(e, checked) => {
                  onChange(checked);
                  actions.onChangeIsUsingBlockInterBranche(checked);
                }}
                checked={value}
              />
            )}
            defaultValue={false}
            control={control}
            name="avec_blocs_inter_branches"
          />
        </Stack.Item>
      )}
      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Toggle
              styles={commonInputStyles.toggleInput}
              label="Certification active :"
              inlineLabel
              onText="Oui"
              offText="Non"
              onChange={(e, checked) => {
                onChange(checked);
              }}
              checked={value}
              disabled={watch("est_acces_et_processus_limites")}
            />
          )}
          defaultValue={false}
          control={control}
          name="active"
        />
      </Stack.Item>
      {watch("active") && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Toggle
                styles={commonInputStyles.toggleInput}
                label="Certification à accès et processus limités :"
                inlineLabel
                onText="Oui"
                offText="Non"
                onChange={(e, checked) => {
                  onChange(checked);
                }}
                checked={value}
              />
            )}
            defaultValue={false}
            control={control}
            name="est_acces_et_processus_limites"
          />
        </Stack.Item>
      )}
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Nombre de blocs au choix à valider obligatoirement :"
              type="text"
              {...field}
              disabled={nbBlockDisabled}
              placeholder="Ex : 4 (max 10)"
              errorMessage={(errors[field.name] as any)?.message}
              required
            />
          )}
          control={control}
          defaultValue=""
          name="nb_blocs_au_choix_a_valider"
        />
      </Stack.Item>
    </FormSection>
  );
};

export default GeneralInformationsSection;
