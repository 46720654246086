import { DefaultButton, Link, Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import Can from "../../../../Common/Can/Can";
import FormSection from "../../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import useTheme from "../../../../Common/useTheme";
import { branchAccessEnum, userAccessEnum } from "../../../../config/accessEnum";
import { API_URL } from "../../../../config/config";
import { commonButtonStyles } from "../../../../styles";
import { PartiesPrenantesContext } from "../usePartiesPrenantes";
import formSectionStyle from "./FormSection.styles";

function SelectPositionneurFormSection({ disabled }: any) {
  const { primaryColor } = useTheme();
  const { conditionnalDisplayVariables, actions, reactHookProps, candidature }: any =
    useContext(PartiesPrenantesContext);
  const {
    control,
    setValue,
    formState: { errors },
  } = reactHookProps;
  const { showPositionneur } = conditionnalDisplayVariables;

  return (
    <FormSection sectionTitle="Positionneur du candidat">
      {showPositionneur && (
        <>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Positionneur rattaché au candidat :"
                placeholder="Recherchez un positionneur par son nom, prénom ou email"
                resourceURI={`${API_URL}api/candidature/candidatures/${candidature.uuid}/chercher-positionneur`}
                addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?recherche=${terms}`}
                method="GET"
                renderItem={(item: any) => item.nom + " " + item.prenom}
                renderValue={(item: any) => item.nom + " " + item.prenom}
                dataIndex="uuid"
                name={name}
                value={value}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, { shouldValidate: true });
                }}
                defaultValue={null}
                //errorMessage={}
                handleResponse={(response: any) => [...response.data.data]}
                disabled={disabled}
                required={true}
              />
            )}
            control={control}
            name="positionneur"
          />

          {!disabled && (
            <Can I={userAccessEnum.SAISIR_POSITIONNEMENT}>
              <Stack horizontal>
                <Link
                  styles={formSectionStyle.buttonLink}
                  style={{ color: primaryColor }}
                  onClick={() => actions.addMeAs("positionneur")}
                >
                  Sélectionner mon propre compte
                </Link>
              </Stack>
            </Can>
          )}

          {!disabled && (
            <Can I={branchAccessEnum.AUTORISER_CREATEUR_CANDIDATURE_INVITER_POSITIONNEUR}>
              <Stack horizontal>
                <DefaultButton
                  styles={commonButtonStyles.defaultButton}
                  style={{ color: primaryColor, borderColor: primaryColor }}
                  text="Inviter un nouveau positionneur"
                  onClick={() => actions.openUserFormModal("positionneur")}
                />
              </Stack>
            </Can>
          )}
        </>
      )}
    </FormSection>
  );
}

export default SelectPositionneurFormSection;
