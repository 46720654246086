import { atom, useAtom } from 'jotai';
import { getPageBranchRequest } from '../../services/publicServices';

const isStaticPageAtom = atom(false);
const pageAtom = atom({})

function useGeneralPagePrivate() {
    const [isStaticPage,setIsStaticPage]=useAtom(isStaticPageAtom)
    const [page,setPage] = useAtom(pageAtom);

    const initializeStaticPage = async ({type}: any) => {
        const response = await getPageBranchRequest(type);
        setPage(response);
        setIsStaticPage(true);
    }

    return {
        page,
        isStaticPage,
        initializeStaticPage,
    }
}

export default useGeneralPagePrivate