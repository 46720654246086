import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useOf from "../../hooks/of/useOf";
import useOfQuery from "../../hooks/of/useOfQuery";
import { joiResolver } from "@hookform/resolvers/joi";
import { ofSchema } from "../../schemas/ofSchema";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useNavigate } from "react-router-dom";
import ofAdapters from "../../adapters/ofAdapters";
import { atomWithReset } from "jotai/utils";
import tools from "../../utils/tools";
import useHabilitationOfQuery from "../../hooks/habilitation/useHabilitationOfQuery";
import RemovaHabilitationModal from "../HabilitationOfList/RemoveHabilitationModal/RemovaHabilitationModal";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { toast } from "react-toastify";

export const OfContext = React.createContext({});
export const OfProvider = ({ value, children }: any) => {
  return <OfContext.Provider value={value}>{children}</OfContext.Provider>;
};

const resetImportOf: any = {
  raison_sociale: "",
  adresse_uuid: null,
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  region: "",
  ville: "",
  groupe_of: null,
};

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useOfDetail() {
  const [isChecked, setIsChecked] = useState(false);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const navigate = useNavigate();
  const ofProps = useOf();
  const { of, isLoading, isRefetching, refetch } = useOfQuery(ofProps);
  const { actions: modalActions } = useCustomModal();
  const {
    habilitations,
    isLoading: isLoadingHabilitations,
    isRefetching: isRefetchingHabilitations,
  } = useHabilitationOfQuery({ ofProps, optionsFilters });

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: joiResolver(ofSchema),
  });

  const actions = {
    saveOf: (data: any) => {
      if (!(data as any).uuid && !of?.id) {
        ofProps.actions.postOf(data);
      } else {
        ofProps.actions.putOf(data);
      }
    },
    setUpdateIsCheck: (isVerified: boolean, value?: any) => {
      if (isVerified && value) {
        const mesBranchesId = value.branches?.map((b: any) => b.id);
        if (
          mesBranchesId.length > 0 &&
          mesBranchesId.includes(userSelectedBranch.id)
        ) {
          navigate(`/admin/of/${value.uuid}?branchId=${userSelectedBranch.id}`);
        } else {
          const ofAdapterValue = ofAdapters.transformForBO(value);
          Object.keys(resetImportOf).forEach((field: any) => {
            reactHookProps.setValue(`${field}`, ofAdapterValue[field]);
          });
          reactHookProps.setValue("uuid", ofAdapterValue.uuid);
        }
      }
      setIsChecked(isVerified);
    },
    resetAutocompleteInput: () => {
      Object.keys(resetImportOf).forEach((field: any) => {
        reactHookProps.setValue(`${field}`, resetImportOf[field]);
      });
    },
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({ ...optionsFilters, page });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onRemoveHabilitation: (habilitation: any) => {
      modalActions.openModal({
        header: "Retirer une habilitation",
        body: (
          <RemovaHabilitationModal
            habilitations={habilitation}
            isEnMasse={false}
          />
        ),
      });
    },
  };

  return {
    of,
    ofProps,
    reactHookProps,
    actions,
    isChecked,
    isLoadingOf: isLoading || isRefetching,
    refetch,
    optionsFilters,
    habilitations,
    isLoadingHabilitations,
    isRefetchingHabilitations,
  };
}

export default useOfDetail;
