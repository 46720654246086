import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { stateList } from "../../fakeData/State.data";
import useGroupCompany from "../../hooks/groupCompany/useGroupCompany";
import {
  getExportGroupCompanies,
  getGroupCompaniesRequest,
} from "../../services/groupCompanyServices";
import tools from "../../utils/tools";
import { useState } from "react";
import { toast } from "react-toastify";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  raison_sociale: "",
  siren: "",
  actif: stateList[0],
  interbranche: stateList[0],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useGroupCompanyList() {
  const groupCompanyProps = useGroupCompany();
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();
  const [isLoadingExportGroupCompany, setIsLoadingExportGroupCompany] =
    useState(false);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: groupCompanies, isLoading: isLoadingGroupCompanies } = useQuery(
    ["groupCompanies", optionsFilters, groupCompanyProps.userSelectedBranch],
    () => getGroupCompaniesRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteGroupCompanyModal: (groupCompany: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={groupCompany.raison_sociale}
            deleteFunction={() =>
              groupCompanyProps.actions.deleteGroupCompany(groupCompany.uuid)
            }
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportGroupCompanies: async () => {
      setIsLoadingExportGroupCompany(true);
      try {
        const firstResponse: any = await getExportGroupCompanies(filters).catch(
          (e: any) => {
            console.log("ERR", e);
            toast.error(
              "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
            );
            setIsLoadingExportGroupCompany(false);
          }
        );
        if (!firstResponse) return;
        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });
        setIsLoadingExportGroupCompany(false);
      } catch (e: any) {
        setIsLoadingExportGroupCompany(false);
      }
    },
  };

  return {
    groupCompanies,
    groupCompanyProps,
    isLoadingGroupCompanies,
    isLoadingExportGroupCompany,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
  };
}

export default useGroupCompanyList;
