import { axiosInstance } from "../config/axiosConfig";
import {
  IS_OFFLINE,
  OFFLINE_ROUTE,
  getOfflineStorage,
} from "../config/offlineConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const getBranchesRequest = async () => {
  const url: string = "public/lister-branches-actives";
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(`public/lister-branches-actives`);
  localStorage.setItem("branchesActives", JSON.stringify(response.data));
  return response.data;
};

export const getPageGeneralRequest = async (type: any) => {
  const response = await eCertifApi.get(`public/pages-generales/${type}`);
  return response.data;
};

export const getPageBranchRequest = async (type: any) => {
  const response = await eCertifApi.get(
    `administration/pages-generales-branche/public/${type}`
  );
  return response.data;
};

export const getConstantRequest = async () => {
  const url: string = "public/constantes";
  if (IS_OFFLINE) return getOfflineStorage(url as keyof typeof OFFLINE_ROUTE);
  const response = await eCertifApi.get(`public/constantes`);
  localStorage.setItem("constantes", JSON.stringify(response.data));
  return response.data;
};

export const checkPublicUtilisateurExistsRequest = async (
  branchId: any,
  userEmail: any
) => {
  const response = await eCertifApi.get(
    `public/utilisateurs/verifier-existe?email=${encodeURIComponent(
      userEmail
    )}&branch_id=${branchId}`
  );
  return response.data;
};

export const postSignUpRequest = async (userInfo: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `public/demande-inscription/creer`,
    userInfo,
    xsrfHeader
  );

  return response;
};

export const accepterCguRequest = async (cguId: string) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `administration/pages-generales-branche/${cguId}/accepter`,
    xsrfHeader
  );

  return response;
};

export const refuserCguRequest = async (cguId: string) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `administration/pages-generales-branche/${cguId}/refuser`,
    xsrfHeader
  );

  return response;
};
