import { Link, Stack, TextField } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useAtom } from "jotai";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { commonInputStyles } from "../../../styles";
import PreviousStepButton from "../PreviousStepButton/PreviousStepButton";
import SelectedBranch from "../SelectedBranch/SelectedBranch";
import { SignUpContext, accountInformationsAtom } from "../useSignUp";

const userInformationSchema = Joi.object({
  nom: Joi.string().required().messages({
    "string.empty": "Le nom est obligatoire",
  }),
  prenom: Joi.string().required().messages({
    "string.empty": "Le prénom est obligatoire",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "L'email est obligatoire",
      "string.email": "L'email doit être valide",
    }),
  telephone: Joi.string()
    .length(10)
    .pattern(/^[0-9]+$/, "numbers")
    .allow("")
    .messages({
      "string.empty": "Le numéro de téléphone est obligatoire",
      "string.length":
        "Le numéro de téléphone doit avoir exactement 10 chiffres",
      "string.pattern.numbers":
        "Le numéro de téléphone ne doit contenir que des chiffres",
    }),
});

function PersonalInformation() {
  const { actions, isLoading }: any = useContext(SignUpContext);
  const [accountInformations, setAccountInformations]: any = useAtom(
    accountInformationsAtom
  );
  const reactHookProps: any = useForm({
    mode: "onBlur",
    defaultValues: {
      nom: accountInformations?.nom || "",
      prenom: accountInformations?.prenom || "",
      email: accountInformations?.email || "",
      telephone: accountInformations?.telephone || "",
    },
    resolver: joiResolver(userInformationSchema),
  });
  const {
    formState: { errors },
  } = reactHookProps;

  return (
    <form
      onSubmit={reactHookProps.handleSubmit(
        actions.onSubmitPersonalInformations
      )}
    >
      <Stack
        styles={formSectionStyles.formContainer}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        tokens={{ childrenGap: 15 }}
      >
        <Stack.Item>
          <SelectedBranch>
            <PreviousStepButton text="Modifier la branche choisie" />
          </SelectedBranch>
        </Stack.Item>
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Nom :"
                type="text"
                {...field}
                placeholder="Ex : Lafayette"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            defaultValue=""
            control={reactHookProps.control}
            name="nom"
          />
        </Stack.Item>
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Prénom :"
                type="text"
                {...field}
                placeholder="Ex : Lucie"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            defaultValue=""
            control={reactHookProps.control}
            name="prenom"
          />
        </Stack.Item>
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                required
                label="Email :"
                type="email"
                {...field}
                placeholder="Ex : lucie@domaine.com"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            defaultValue=""
            control={reactHookProps.control}
            name="email"
          />
        </Stack.Item>
        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Téléphone mobile (optionel) :"
                type="tel"
                {...field}
                placeholder="Ex : 0606060606"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            defaultValue=""
            control={reactHookProps.control}
            name="telephone"
          />
        </Stack.Item>

        <Stack.Item>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Stack.Item>
              <Link href="/" style={{ display: "flex", alignItems: "center" }}>
                Revenir sur la page de connexion
              </Link>
            </Stack.Item>
            <LoadButton
              isLoading={isLoading}
              text="Continuer ma demande d'inscription"
              type="submit"
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </form>
  );
}

export default PersonalInformation;
