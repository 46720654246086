import { DefaultButton, Stack } from "@fluentui/react";
import useTheme from "../../../useTheme";
import InfoMessage from "../InfoMessage/InfoMessage";

const ResultItem = ({
  isSelected,
  text,
  onClick,
  disabled = false,
  prefilled = false,
  infoMessageDisabled = "",
}: any) => {
  const { primaryColor } = useTheme();

  return (
    <div>
      <DefaultButton
        onClick={onClick}
        style={{
          height: "auto",
          padding: 10,
          textAlign: "left",
          border: 0,
          borderBottom: `1px solid #d8d8d8`,
          width: "100%",
          color: isSelected ? primaryColor : "black",
          background: disabled ? "#f4f4f4" : prefilled ? "#E7F0FE" : "",
        }}
        className="multiSelectButton"
        iconProps={{
          iconName: isSelected ? "CheckboxComposite" : "Checkbox",
          color: isSelected ? primaryColor : "",
        }}
      >
        <Stack>
          {text}
          {disabled && (
            <Stack>
              <InfoMessage infoMessage={infoMessageDisabled} />
            </Stack>
          )}
        </Stack>
      </DefaultButton>
    </div>
  );
};

export default ResultItem;
