import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useAtom } from 'jotai';
import useSubGroupOfMutations from "./useSubGroupOfMutations";
import { toast } from "react-toastify";

function useSubGroupOf() {
    const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
    const { subGroupOfUuid, setSubGroupOfUuid, mutations } = useSubGroupOfMutations();

    const actions = {
        setUpdateSubGroupOfUuid: (data: any) => {
            setSubGroupOfUuid(data)
        },
        deleteSubGroupOf: async (subGroupOfUuid:any) =>{
            toast.info('En cours de développement')
            //mutations.DeleteSubGroupOf.mutate(subGroupOfUuid)
          },
    }

    return {
        userSelectedBranch,
        subGroupOfUuid,
        actions
    }
}

export default useSubGroupOf