import { Label, Stack, TextField } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../styles";
import FileInput from "../../../Common/FileInput/FileInput";
import DynamicList from "../../../Common/DynamicList/DynamicList";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import { useContext } from "react";
import { BranchContext } from "../useBranchDetail";
import { validateContactEmailList } from "../../../schemas/branchSchema";

function SupplementaryInformationsSection() {
  const { reactHookProps }: any = useContext(BranchContext);

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = reactHookProps;

  return (
    <FormSection sectionTitle="Informations complémentaires de la branche">
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Prénom du Président de la CPNE :"
              type="text"
              {...field}
              placeholder="Ex : John"
              errorMessage={errors.prenom_president_cpne?.message}
            />
          )}
          defaultValue=""
          control={control}
          name="prenom_president_cpne"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Nom du Président de la CPNE :"
              type="text"
              {...field}
              placeholder="Ex : Doe"
              errorMessage={errors.nom_president_cpne?.message}
            />
          )}
          defaultValue=""
          control={control}
          name="nom_president_cpne"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          control={control}
          name="signature_fiche_decision"
          render={({ field: { onChange, name, value } }) => (
            <>
              <Label>Signature du Président de la CPNE :</Label>
              <FileInput
                name={name}
                file={value ? [value] : []}
                onUpdateFile={(e: any) => {
                  onChange(e.target.value[0]);
                  setValue(`${name}_deleted`, false);
                }}
                onDeleteFile={() => {
                  setValue(`${name}_deleted`, true);
                }}
                existingFileURL={
                  watch(`${name}_deleted`)
                    ? ""
                    : value
                    ? ""
                    : watch(`${name}_url`)
                }
                acceptedFileTypes={["PNG", "JPG", "JPEG"]}
              />
            </>
          )}
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <DynamicList
              label="Ajoutez un ou plusieurs emails de contact :"
              placeholder="Ex : test@example.fr"
              addButtonLabel="Ajoutez cet email"
              name={name}
              value={value || []}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={{ email: "" }}
              attr="email"
              validateItem={validateContactEmailList}
            />
          )}
          control={control}
          name="emails_contact"
        />
        <ErrorMessage errorMessage={(errors.emails_contact as any)?.message} />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Email de l'expéditeur :"
              type="email"
              {...field}
              placeholder="Ex : test@example.fr"
              errorMessage={errors.defaut_email_expediteur?.message}
            />
          )}
          defaultValue=""
          control={control}
          name="defaut_email_expediteur"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Email de réponse :"
              type="email"
              {...field}
              placeholder="Ex : test@example.fr"
              errorMessage={errors.email_reponse?.message}
            />
          )}
          defaultValue=""
          control={control}
          name="email_reponse"
        />
      </Stack.Item>

      <FormAdress
        control={control}
        errors={errors}
        varToWatch={watch("code_postal") ?? false}
        onPrefillCityAndRegion={(data: any) => {
          setValue("ville", data.nom_commune);
          setValue("region", data.nom_region);
        }}
      />
    </FormSection>
  );
}

export default SupplementaryInformationsSection;
