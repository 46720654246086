import {Stack, Text} from '@fluentui/react'

function TextResult({total}:{total:number}) {
    return (
        <Stack.Item>
            <Text variant="mediumPlus" style={{ color: "#6F6F6F" }}>
                Nombre de résultats: {total}
            </Text>
        </Stack.Item>
    )
}

export default TextResult