import Joi from "joi";

export const accountSchema = Joi.object({
    nom: Joi.string()
        .required()
        .messages({
            "string.empty": "Veuillez renseigner un nom d'utilisateur",
            "any.required": "Veuillez renseigner un nom d'utilisateur"
        }),
    prenom: Joi.string()
        .required()
        .messages({
            "string.empty": "Veuillez renseigner un prénom",
            "any.required": "Veuillez renseigner un prénom"
        }),
    email: Joi.string()
        .required()
        .email({ tlds: { allow: false } })
        .messages({
            "string.empty": "Veuillez renseigner un email",
            "string.email": "Veuillez respecter le format de l'email (exemple@domaine.fr)",
            "any.required": "Veuillez renseigner un email"
        }),
    telephone_portable: Joi.string()
        .allow("", null)
        .length(10)
        .regex(/[0-9]{10}$/)
        .messages({
            'string.length': 'Veuillez renseigner un numéro de téléphone à 10 chiffres',
            "string.pattern.base": "Veuillez respecter le format 0611223344",
        })
}).unknown(true);