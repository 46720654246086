import { FontIcon, Link } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import {
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
  TREATMENT_STATUS_NAMES,
} from "../../../hooks/offlineCandidature/useOfflineCandidaturePile";
import { commonButtonStyles } from "../../../styles";
import { useAtom } from "jotai";
import { isConsultCandidaturesListAtom } from "../../../Pages/CandidatJuryList/useCandidatureJuryList";
import { urlIdCandidatureAtom } from "../../../hooks/candidature/useCandidatureWorkflow";
import { useEffect } from "react";

function CandidatureNeedActionList({ items }: any) {
  const [, setIsConsultCandidaturesList] = useAtom(
    isConsultCandidaturesListAtom
  );
  const [urlIdCandidature]: any = useAtom(urlIdCandidatureAtom);
  const navigate = useNavigate();

  const onClickToLink = (item: any) => {
    setIsConsultCandidaturesList(true);
    navigate(`/admin/candidature/${item.uuid}/${item.statut}/true`);
  };

  useEffect(() => {
    if (urlIdCandidature && items.length > 0) {
      //scrollIntoView the li ielement
      const element = document.querySelector(`#scitem${urlIdCandidature}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    }
  }, [urlIdCandidature]);

  return (
    <ol
      style={{
        paddingLeft: "20px",
        paddingRight: "5px",

        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: 0,
        marginBottom: 0,
        overflowY: "scroll",
        height: "calc(100vh - 130px)",
      }}
    >
      {items.map((item: any) => {
        const isActive = urlIdCandidature === item.uuid;
        return (
          <li
            key={`scitem${item.uuid}`}
            id={`scitem${item.uuid}`}
            style={{
              padding: "0 4px",
              paddingLeft: 0,
              borderRadius: 3,
              background: isActive ? "#f2f2f2" : "transparent",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "3px 0",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Link
                  styles={commonButtonStyles.buttonLink}
                  style={{
                    display: "block",
                    color: item.error ? "red" : item.isTreated ? "green" : "",
                    fontWeight: isActive ? "bold" : "normal",
                  }}
                  onClick={() => onClickToLink(item)}
                >
                  {item.candidat.nom} - {item.certification.libelle}
                </Link>
              </div>
              <div
                style={{
                  width: 22,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: 5,
                }}
              >
                <FontIcon
                  iconName={
                    item.isTreated
                      ? TREATMENT_STATUS_ICONS["TREATED"]
                      : TREATMENT_STATUS_ICONS["NOT_TREATED"]
                  }
                  title={
                    item.isTreated
                      ? TREATMENT_STATUS_NAMES["TREATED"]
                      : TREATMENT_STATUS_NAMES["NOT_TREATED"]
                  }
                  style={
                    item.isTreated
                      ? {
                          color: TREATMENT_STATUS_COLORS["TREATED"],
                        }
                      : {
                          color: TREATMENT_STATUS_COLORS["NOT_TREATED"],
                        }
                  }
                />
              </div>
            </div>
          </li>
        );
      })}
    </ol>
  );
}

export default CandidatureNeedActionList;
