import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

const candidatURI = "candidature/candidats";

export const getCandidatRequest = async (candidatUuid: any, userUuid: any) => {
  if (candidatUuid == userUuid) {
    const response = await eCertifApi.get(`${candidatURI}/mon-profil-candidat`);
    return response.data;
  } else {
    const response = await eCertifApi.get(`${candidatURI}/${candidatUuid}`);
    return response.data;
  }
};

export const putCandidatRequest = async ({ candidatData, userUuid }: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  if (candidatData.utilisateur_uuid == userUuid) {
    const response = await eCertifApi.post(
      `${candidatURI}/modifier-mon-profil-candidat`,
      { ...candidatData, _method: "PUT" },
      xsrfHeader
    );
    return response.data;
  } else {
    const response = await eCertifApi.post(
      `${candidatURI}/${candidatData.uuid}`,
      { ...candidatData, _method: "PUT" },
      xsrfHeader
    );
    return response.data;
  }
};
