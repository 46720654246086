import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ padding: 40 }}>
          <h1>Une problème technique est survenu</h1>
          {process.env.REACT_APP_MODE != "production" && (
            <div>
              {" "}
              <p>Merci de remonter le message d'erreur ci-dessous à un administrateur.</p>
              <div style={{ color: "red", background: "black", padding: 10 }}>
                <pre>{this.state.error.message}</pre>
                <pre style={{ whiteSpace: "pre-wrap" }}>{this.state.error.stack}</pre>
              </div>
            </div>
          )}
          <p>
            Vous pouvez essayer de{" "}
            <button onClick={() => window.location.reload()}>Recharger la page</button>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
