import tools from "../utils/tools"

const suiviCohortesAdapters = {
    transformForAPI: (data: any) => {

        let transformed: any = {
            ...data,
            situation_apres_certification:data.situation_apres_certification?.key,
            type_contrat:data.type_contrat?.key
        }

        return transformed
    },
    transformForBO: (data: any, constants:any) => {

        let transformed: any = {
            ...data,
            situation_apres_certification:data.situation_apres_certification ? tools.findIn(constants.CANDIDATURES.SITUATION_APRES_CERTIFICATION,data.situation_apres_certification,'id'): undefined,
            type_contrat:data.type_contrat ? tools.findIn(constants.CANDIDATURES.TYPES_CONTRAT,data.type_contrat,'id') : undefined,
            statut_cadre: data.statut_cadre ? true : false
        }

        return transformed

    },
}

export default suiviCohortesAdapters;