import { Link, Spinner, SpinnerSize } from "@fluentui/react";

function LoadLink({
  text,
  isLoading = false,
  onClick = () => {},
  styles,
  href,
}: any) {
  const getRender = () => {
    if (isLoading) {
      return <Spinner size={SpinnerSize.small} />;
    }
    return <>{text}</>;
  };

  return !href ? (
    <Link
      styles={styles}
      style={{ marginLeft: 5, marginTop: 5 }}
      onClick={onClick}
    >
      {getRender()}
    </Link>
  ) : (
    <Link
      href={href}
      download
      styles={styles}
      style={{ marginLeft: 5, marginTop: 5 }}
      rel="noreferrer"
      target="_blank"
    >
     {getRender()}
    </Link>
  );
}

export default LoadLink;
