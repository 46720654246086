import Joi from "joi";

const addDocumentSchema = Joi.object({
  nom: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un libellé" }),
  file_url: Joi.string().allow(""),
  file: Joi.when("file_url", {
    not: Joi.string().min(1),
    then: Joi.object().required().messages({
      "object.base": "Veuillez ajouter un modèle",
      "any.required": "Veuillez ajouter un modèle",
    }),
    otherwise: Joi.forbidden(),
  }),
  profils_autorises_en_lecture: Joi.array()
    .items(
      Joi.object()
        .keys({
          id: Joi.string().required(),
        })
        .unknown(true)
    )
    .min(1)
    .messages({
      "any.required": "Veuillez ajouter au moins un profil en lecture",
      "array.min": "Veuillez ajouter au moins un profil en lecture",
    }),
  types_candidature_autorises_en_ajout: Joi.array()
    .items(
      Joi.object()
        .keys({
          id: Joi.number().required(),
        })
        .unknown(true)
    )
    .min(1)
    .messages({
      "any.required":
        "Veuillez ajouter au moins un type de candidature autorisé en ajout",
      "array.min":
        "Veuillez ajouter au moins un type de candidature autorisé en ajout",
    }),
  obligatoire: Joi.boolean().required().default(false),
}).unknown(true);

const validations = {
  validateAddDocumentForm: (form: any) => {
    return addDocumentSchema.validate(form, { abortEarly: false });
  },
};

export default validations;
