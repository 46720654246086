import { atom, useAtom } from "jotai";
import { axiosInstance } from "../config/axiosConfig";
import { userSelectedBranchAtom } from "../atoms/branchAtom";
import useAccessSettings from "./branchSettings/useAccessSettings";
import useRolesManagement from "../Pages/RolesManagement/useRolesManagement";
import useLabelSettings from "../Pages/LabelSettingsDetail/useLabelSettings";
import { isLoadingGlobalAtom } from "../Common/GlobalLoader/GlobalLoader";
import useDynamicFields from "./dynamicFields/useDynamicFields";
import useResetFiltersAtom from "./useResetFiltersAtom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { atomWithStorage } from "jotai/utils";
import { CHANGE_IS_OFFLINE } from "../config/offlineConfig";
import { authAtom } from "./useAuth";
import {
  checkUserConnectedRequest,
  getUserInfoOnSelectedBranch,
} from "../services/authServices";

const isNavOpenAtom = atom(false);
export const isOfflineAtom = atomWithStorage("isOffline", false);

function useNav() {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = useAtom(isNavOpenAtom);
  const [, setIsLoadingGlobal] = useAtom(isLoadingGlobalAtom);
  const [auth, setAuth] = useAtom(authAtom);

  const [userSelectedBranch, setUserSelectedBranch]: any = useAtom(
    userSelectedBranchAtom
  );
  const [isOffline, setIsOffline] = useAtom(isOfflineAtom);
  const { actions: branchSettingsActions } = useAccessSettings();
  const { actions: branchRolesSettingsActions } = useRolesManagement();
  const { actions: branchLabelSettingsActions } = useLabelSettings();
  const { actions: branchDynamicFieldsActions } = useDynamicFields();
  const { actions: resetAtomActions } = useResetFiltersAtom();

  const actions = {
    onClickOnOfflineButton: () => {
      if (!navigator.onLine && isOffline)
        return toast.error(
          "Une connexion internet est nécessaire pour passer en mode en ligne."
        );
      if (!isOffline) navigate("/admin/offline/candidature-sync");
      else navigate("/admin/offline/candidature");
      setIsOffline(!isOffline);
      CHANGE_IS_OFFLINE(isOffline);
    },
    toggleNav: () => {
      setIsNavOpen(!isNavOpen);
      document.body.classList.toggle("lock-scroll", !isNavOpen);
    },
    changeBranch: async (item: any) => {
      axiosInstance.defaults.headers.common["X-TENANT-ID"] = item.id;
      setIsLoadingGlobal(true);
      await branchSettingsActions.getCurrentBranchSettings();
      await branchRolesSettingsActions.getCurrentBranchRolesSettings();
      await branchDynamicFieldsActions.getFields();
      await branchLabelSettingsActions.getLabelSettings();
      resetAtomActions.resetFiltersAtom();
      resetAtomActions.resetCacheQuery();
      localStorage.setItem("selectedBranchId", item.id);
      setUserSelectedBranch(item);
      const userInfoOnSelectedBranch = await getUserInfoOnSelectedBranch();
      const userInfoGeneral = await checkUserConnectedRequest();
      setAuth({
        ...auth,
        isReferent: userInfoOnSelectedBranch.mes_structures.length > 0,
        user: {
          ...auth.user,
          cgu_acceptees: userInfoGeneral.cgu_acceptees,
          profils_sur_branche: userInfoOnSelectedBranch.profils,
        },
      });
      setIsLoadingGlobal(false);
    },
    emptySelectedBranch: () => {
      setUserSelectedBranch(null);
      //window.location.reload();
    },
  };

  return {
    userSelectedBranch,
    isNavOpen,
    actions,
    isOffline,
  };
}

export default useNav;
