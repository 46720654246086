import { useResetAtom } from "jotai/utils";

import { filtersAtom as rolesManagementFiltersAtom } from "../Pages/RolesManagement/useRolesManagement";
import {
  filtersAtom as branchFiltersAtom,
  optionsFiltersAtom as branchOptionsAtom,
} from "../Pages/BranchList/useBranchList";
import {
  filtersAtom as userFiltersAtom,
  optionsFiltersAtom as userOptionsAtom,
} from "../Pages/UserList/useUserList";
import {
  filtersAtom as companyFiltersAtom,
  optionsFiltersAtom as companyOptionsAtom,
} from "../Pages/CompanyList/useCompanyList";
import {
  filtersAtom as groupCompanyFiltersAtom,
  optionsFiltersAtom as groupCompanyOptionsAtom,
} from "../Pages/GroupCompanyList/useGroupCompanyList";
import {
  filtersAtom as subGroupCompFiltersAtom,
  optionsFiltersAtom as subGroupCompOptionsAtom,
} from "../Pages/SubGroupCompanyList/useSubGroupCompanyList";
import {
  filtersAtom as ofFiltersAtom,
  optionsFiltersAtom as ofOptionsAtom,
} from "../Pages/OfList/useOfList";
import {
  filtersAtom as groupOfFiltersAtom,
  optionsFiltersAtom as groupOfOptionsAtom,
} from "../Pages/GroupOfList/useGroupOfList";
import {
  filtersAtom as subGroupOfFiltersAtom,
  optionsFiltersAtom as subGroupOfOptionsAtom,
} from "../Pages/SubGroupOfList/useSubGroupOfList";
import {
  overwrittenFiltersAtom as competenceFiltersAtom,
  optionsFiltersAtom as competenceOptionsAtom,
} from "../Pages/CompetenceList/useCompetenceList";
import {
  overwrittenFiltersAtom as competenceBlocFiltersAtom,
  optionsFiltersAtom as competenceBlocOptionsAtom,
} from "../Pages/CompetenceBlockList/useCompetenceBlockList";
import {
  filtersAtom as certificationFiltersAtom,
  optionsFiltersAtom as certificationOptionsAtom,
} from "../Pages/CertificationList/useCertificationList";
import {
  filtersAtom as juryFiltersAtom,
  optionsFiltersAtom as juryOptionsAtom,
} from "../Pages/JuryList/useJuryListPage";
import {
  filtersAtom as canidatureFiltersAtom,
  optionsFiltersAtom as canidatureOptionsAtom,
} from "../Pages/CandidatureList/useCandidatureList";
import {
  filtersAtom as staticPageFiltersAtom,
  optionsFiltersAtom as staticPageOptionsAtom,
} from "../Pages/StaticPageList/useStaticPageList";
import {
  filtersAtom as candidatureJuryFiltersAtom,
  optionsFiltersAtom as candidatureJuryOptionsAtom,
} from "../Pages/CandidatJuryList/useCandidatureJuryList";
import { filtersAtom as suiviCohortesFiltersAtom } from "../Pages/SuiviCohortesList/useSuiviCohortesList";
import { isIinitialFetchOnThisBrancheAtom as competenceIsInitialFetchAtom } from "../Pages/CompetenceList/useCompetenceList";
import { isIinitialFetchOnThisBrancheAtom as competenceBlocIsInitialFetchAtom } from "../Pages/CompetenceBlockList/useCompetenceBlockList";
import { useQueryClient } from "react-query";

function useResetFiltersAtom() {
  const queryClient = useQueryClient();

  const resetBranchFilters = useResetAtom(branchFiltersAtom);
  const resetRolesManagementFilters = useResetAtom(rolesManagementFiltersAtom);
  const resetUserFilters = useResetAtom(userFiltersAtom);
  const resetCompanyFilters = useResetAtom(companyFiltersAtom);
  const resetGroupCompanyFilters = useResetAtom(groupCompanyFiltersAtom);
  const resetSubGroupCompFilters = useResetAtom(subGroupCompFiltersAtom);
  const resetOfFilters = useResetAtom(ofFiltersAtom);
  const resetGroupOfFilters = useResetAtom(groupOfFiltersAtom);
  const resetSubGroupOfFilters = useResetAtom(subGroupOfFiltersAtom);
  const resetCompetenceFilters = useResetAtom(competenceFiltersAtom);
  const resetCompetenceBlocFilters = useResetAtom(competenceBlocFiltersAtom);
  const resetCertificationFilters = useResetAtom(certificationFiltersAtom);
  const resetJuryFilters = useResetAtom(juryFiltersAtom);
  const resetCanidatureFilters = useResetAtom(canidatureFiltersAtom);
  const resetStaticPageFilters = useResetAtom(staticPageFiltersAtom);
  const resetCandidatureJuryFilters = useResetAtom(candidatureJuryFiltersAtom);
  const resetSuiviCohortesFiltersAtom = useResetAtom(suiviCohortesFiltersAtom);

  const resetBranchOptions = useResetAtom(branchOptionsAtom);
  const resetUserOptions = useResetAtom(userOptionsAtom);
  const resetCompanyOptions = useResetAtom(companyOptionsAtom);
  const resetGroupCompanyOptions = useResetAtom(groupCompanyOptionsAtom);
  const resetSubGroupCompOptions = useResetAtom(subGroupCompOptionsAtom);
  const resetOfOptions = useResetAtom(ofOptionsAtom);
  const resetGroupOfOptions = useResetAtom(groupOfOptionsAtom);
  const resetSubGroupOfOptions = useResetAtom(subGroupOfOptionsAtom);
  const resetCompetenceOptions = useResetAtom(competenceOptionsAtom);
  const resetCompetenceBlocOptions = useResetAtom(competenceBlocOptionsAtom);
  const resetCertificationOptions = useResetAtom(certificationOptionsAtom);
  const resetJuryOptions = useResetAtom(juryOptionsAtom);
  const resetCanidatureOptions = useResetAtom(canidatureOptionsAtom);
  const resetStaticPageOptions = useResetAtom(staticPageOptionsAtom);
  const resetCandidatureJuryOptions = useResetAtom(candidatureJuryOptionsAtom);

  const resetCompetenceIsInitialFetch = useResetAtom(competenceIsInitialFetchAtom);
  const resetCompetenceBlocIsInitialFetch = useResetAtom(competenceBlocIsInitialFetchAtom);

  const actions = {
    resetFiltersAtom: () => {
      resetRolesManagementFilters();
      resetBranchFilters();
      resetUserFilters();
      resetCompanyFilters();
      resetGroupCompanyFilters();
      resetSubGroupCompFilters();
      resetOfFilters();
      resetGroupOfFilters();
      resetSubGroupOfFilters();
      resetCompetenceFilters();
      resetCompetenceBlocFilters();
      resetCertificationFilters();
      resetJuryFilters();
      resetCanidatureFilters();
      resetStaticPageFilters();
      resetCandidatureJuryFilters();
      resetSuiviCohortesFiltersAtom();

      resetCompetenceIsInitialFetch();
      resetCompetenceBlocIsInitialFetch();

      resetBranchOptions();
      resetUserOptions();
      resetCompanyOptions();
      resetGroupCompanyOptions();
      resetSubGroupCompOptions();
      resetOfOptions();
      resetGroupOfOptions();
      resetSubGroupOfOptions();
      resetCompetenceOptions();
      resetCompetenceBlocOptions();
      resetCertificationOptions();
      resetJuryOptions();
      resetCanidatureOptions();
      resetStaticPageOptions();
      resetCandidatureJuryOptions();
    },
    resetCacheQuery: () => {
      queryClient.clear();
    },
  };

  return {
    actions,
  };
}

export default useResetFiltersAtom;
