import useJury from "../../hooks/jury/useJury";
import useJuryQuery, { juryInfoAtom } from "../../hooks/jury/useJuryQuery";
import { useAtom } from "jotai";
import tools from "../../utils/tools";

function useJuryDetail() {
  const juryProps = useJury();
  const { jury, isLoadingJury, isRefetchingJury } = useJuryQuery(juryProps);
  const [juryInfo, setJuryInfo]: any = useAtom(juryInfoAtom);

  const conditionsInputDisabled = {
    president_jury_uuid: juryInfo.date_jury
      ? !tools.isThisStartOfDateAfterOrEqualStartOfToday(juryInfo.date_jury)
      : false,
    vice_president_jury_uuid: juryInfo.date_jury
      ? !tools.isThisStartOfDateAfterOrEqualStartOfToday(juryInfo.date_jury)
      : false,
    commentaire: juryInfo.est_cloture,
  };

  const actions = {
    saveJury: (data: any) => {
      if (!(data as any).uuid) {
        juryProps.actions.postJury(data);
      } else {
        juryProps.actions.putJury(data);
      }
    },
  };

  return {
    jury,
    actions,
    juryProps,
    isLoadingJury: isLoadingJury || isRefetchingJury,
    conditionsInputDisabled,
    setJuryInfo,
  };
}

export default useJuryDetail;
