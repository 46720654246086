import { Stack, DefaultButton } from "@fluentui/react";
import { useContext } from "react";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useTheme from "../../../Common/useTheme";
import { commonButtonStyles } from "../../../styles";
import stepContainerStyles from "../StepContainer/StepContainer.styles";
import { SignUpContext } from "../useSignUp";
import { FaArrowLeft } from "react-icons/fa";

function PreviousStepButton({
  text = "Revenir à l'étape précédente",
}: {
  text?: string;
}) {
  const { stepInfo, actions }: any = useContext(SignUpContext);
  const { primaryColor } = useTheme();

  return (
    <div>
      <DefaultButton
        onClick={actions.goToPrevious}
        styles={commonButtonStyles.buttonLink}
        style={{ color: primaryColor, borderColor: primaryColor }}
      >
        <FaArrowLeft style={{ marginRight: 5 }} size={12} />
        {text}
      </DefaultButton>
    </div>
  );
}

export default PreviousStepButton;
