import { DirectionalHint, Stack, Text, TooltipHost } from "@fluentui/react";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import useFetchCandidature from "../useFetchCandidature";
import usePriseConnaissance from "./usePriseConnaissance";
import ValiderAvisFormateurForm from "./ValiderAvisFormateurForm/ValiderAvisFormateurForm";
import ValiderEvaluationForm from "./ValiderEvaluationForm/ValiderEvaluationForm";
import ValiderSecondeEvaluationForm from "./ValiderSecondeEvaluationForm/ValiderSecondeEvaluationForm";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import { userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import InfoMessage from "../../../Common/SelectField/common/InfoMessage/InfoMessage";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";

function PriseConnaissance() {
  const { candidature, reactHookProps, isCandidat, actions, isSaving } =
    usePriseConnaissance();

  const { isAdmin } = useInfoProfilUser();
  const { actions: accessActions } = useAccessSettings();
  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isThisPageStatusBeforeCandidatureStatus } = useCandidatureWorkflow();

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? userAccessEnum.NO_ACCESS_EVEN_ADMIN
    : userAccessEnum.VALIDER_EVALUATION;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  return !candidature.uuid ? null : (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>

      <Stack style={{ margin: "10px 0px" }}>
        <Text
          className="ms-TextField-errorMessage"
          style={{
            color: "#595959",
            fontSize: 14,
            margin: 0,
            paddingTop: 5,
          }}
        >
          Merci de prendre connaissance des différentes étapes de votre
          candidature : informations personnelles, évaluation, avis du
          formateur.
        </Text>
        <Text
          className="ms-TextField-errorMessage"
          style={{
            color: "red",
            fontSize: 14,
            margin: 0,
            paddingTop: 5,
          }}
        >
          ATTENTION, sans cette action de votre part, votre candidature ne
          pourra pas passer en jury. Pour rappel, seul le jury décide ou non de
          l’obtention de la certification.
        </Text>
      </Stack>

      <ValiderEvaluationForm
        candidature={candidature}
        reactHookProps={reactHookProps}
      />
      <ValiderSecondeEvaluationForm
        reactHookProps={reactHookProps}
        accessActions={accessActions}
      />
      <ValiderAvisFormateurForm
        reactHookProps={reactHookProps}
        accessActions={accessActions}
      />

      {!inputsDisabled && (
        <>
          <Stack horizontal horizontalAlign="end" style={{ marginTop: 20 }}>
            {isCandidat && (
              <TooltipHost
                content="J’ai pris connaissance de mon dossier d'évaluation"
                calloutProps={{ gapSpace: 0 }}
                styles={{ root: { display: "inline-block" } }}
                directionalHint={DirectionalHint.topAutoEdge}
              >
                <LoadButton
                  text={"J’ai pris connaissance"}
                  isLoading={isSaving}
                  type="button"
                  onClick={actions.onSubmit}
                />
              </TooltipHost>
            )}
            {isAdmin && (
              <LoadButton
                text={"Passer cette étape"}
                isLoading={isSaving}
                type="button"
                onClick={actions.onSubmit}
              />
            )}
          </Stack>
          {isCandidat && (
            <Stack>
              <InfoMessage
                infoMessage={
                  "ATTENTION : Sans cette action de votre part, votre candidature ne pourra pas être examinée en jury. Pour rappel, seul le jury décide ou non de l’obtention de la certification."
                }
              />
            </Stack>
          )}
        </>
      )}
    </Stack>
  );
}

export default PriseConnaissance;
