import { useAtom } from "jotai";
import { isOpenCandidatureWidgetAtom } from "../../Common/CandidatureNeedActionWidget/CandidatureNeedActionWidget";

const WorkflowWrapper = ({ children }: any) => {
  const [isOpenWidget, setIsOpenWidget] = useAtom(isOpenCandidatureWidgetAtom);

  return (
    <div
      onClick={() => {
        if (isOpenWidget) setIsOpenWidget(false);
      }}
    >
      {children}
    </div>
  );
};

export default WorkflowWrapper;
