import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import Page from "../../Common/Page/Page";
import useOfflineCandidaturePile, {
  SYNCHRO_STATUS,
  SYNCHRO_STATUS_COLORS,
  SYNCHRO_STATUS_ICONS,
  SYNCHRO_STATUS_NAMES,
  TREATMENT_STATUS,
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
} from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { FiRefreshCcw } from "react-icons/fi";
import BackLink from "../../Common/BackLink/BackLink";
import useOfflineCandidatureSyncList from "./useOfflineCandidatureSyncList";
import OfflineCandidatureItem from "./OfflineCandidatureItem";
import SyncItem from "../../Common/SynchronizationWidget/SyncItem/SyncItem";

function OfflineCandidatureSyncList() {
  const { actions: actionsPile, candidaturesFilteredFromPile } =
    useOfflineCandidaturePile();
  const { actions: actionsPage } = useOfflineCandidatureSyncList();

  useEffect(() => {
    actionsPile.getAllCandidatureFromLocalStorage();
    //setIsWidgetVisible(true);
  }, []);

  return (
    <Stack>
      <BackLink
        text="Repasser en mode en ligne"
        onClick={actionsPage.onNavigateToInlineCandidatureToDownload}
      />
      <br />

      <Page
        title="Mes candidatures à synchroniser"
        explain="Vous pouvez éditer et synchroniser les candidatures"
      >
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
          <SyncItem
            text="Total"
            qty={candidaturesFilteredFromPile.length}
            showText
          />
          <SyncItem
            text="Traitées"
            qty={`${
              candidaturesFilteredFromPile.filter(
                (c: any) => c.treatment_status == TREATMENT_STATUS.TREATED
              ).length
            }`}
            icon={TREATMENT_STATUS_ICONS[TREATMENT_STATUS.TREATED]}
            color={TREATMENT_STATUS_COLORS[TREATMENT_STATUS.TREATED]}
            showText
          />
          <SyncItem
            text="Non traitées"
            qty={`${
              candidaturesFilteredFromPile.filter(
                (c: any) => c.treatment_status == TREATMENT_STATUS.NOT_TREATED
              ).length
            }`}
            icon={TREATMENT_STATUS_ICONS[TREATMENT_STATUS.NOT_TREATED]}
            color={TREATMENT_STATUS_COLORS[TREATMENT_STATUS.NOT_TREATED]}
            showText
          />
        </Stack>
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
          {Object.keys(SYNCHRO_STATUS).map((key: any) => {
            return (
              <SyncItem
                key={`statit${key}`}
                text={SYNCHRO_STATUS_NAMES[key]}
                color={SYNCHRO_STATUS_COLORS[key]}
                qty={
                  candidaturesFilteredFromPile.filter(
                    (c: any) => c.synchro_status == SYNCHRO_STATUS[key]
                  ).length
                }
                icon={SYNCHRO_STATUS_ICONS[key]}
                showText
              />
            );
          })}
        </Stack>
        {/* <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={candidaturesFilteredFromPile}
          columns={columns}
          selectionMode={SelectionMode.none}
        /> */}
        <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
          {candidaturesFilteredFromPile.map((candidature: any) => {
            return <OfflineCandidatureItem item={candidature} />;
          })}
        </Stack>
        <Stack horizontalAlign="start">
          <LoadButton
            text="Synchroniser les candidatures traitées"
            isLoading={false}
            icon={<FiRefreshCcw size={24} style={{ marginRight: 10 }} />}
            type="button"
            onClick={actionsPile.onSyncAllCandidaturesFromPile}
          />
        </Stack>
      </Page>
    </Stack>
  );
}

export default OfflineCandidatureSyncList;
