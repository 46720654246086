import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import { commonInputStyles } from "../../../styles";

function EntrepriseProfileInformation({
  reactHookProps,
  isRefIdccRequired,
}: any) {
  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Raison sociale :"
              type="text"
              {...field}
              placeholder="Ex : Ma société"
              required
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="raison_sociale"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Sélectionnez un IDCC :"
              placeholder="Recherchez un IDCC en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              renderValue={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              dataIndex="id"
              name={name}
              value={value}
              fieldRef={ref}
              required={isRefIdccRequired}
              onChange={(e: any) => {
                reactHookProps.setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={null}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={reactHookProps.control}
          name="ref_idcc"
        />
      </Stack.Item>

      <FormAdress
        control={reactHookProps.control}
        required={true}
        varToWatch={reactHookProps.watch("code_postal") ?? false}
        onPrefillCityAndRegion={(data: any) => {
          reactHookProps.setValue("ville", data.nom_commune);
          reactHookProps.setValue("region", data.nom_region);
        }}
      />

      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              label={`Saisissez le SIREN de rattachement`}
              type="text"
              placeholder={`N° SIREN à 9 chiffres sans espace`}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= 9) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="siren_rattachement"
        />
      </Stack.Item>
    </>
  );
}

export default EntrepriseProfileInformation;
