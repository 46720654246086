import { Stack, Text } from "@fluentui/react";

function PageMessage({ message, type }: any) {
  const getColorFromType = () => {
    switch (type) {
      case "success":
        return "green";
      case "error":
        return "red";
    }
  };

  return (
    <Stack horizontalAlign="center" style={{ width: "100%", marginTop: "20px" }}>
      <Text variant={"medium"} style={{ color: getColorFromType() }}>
        {message}
      </Text>
    </Stack>
  );
}

export default PageMessage;
