import {
  DetailsList,
  DetailsListLayoutMode,
  Dropdown,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import LoadLink from "../../../../Common/LoadLink/LoadLink";
import Page from "../../../../Common/Page/Page";
import useConstants from "../../../../hooks/useConstants";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import useDocumentsWithMyPermission from "../../../../hooks/useDocumentsWithMyPermission";
import useGenerateCandidatureDocumentsJury from "../../../../hooks/useGenerateCandidatureDocumentsJury";
import { useState } from "react";

export function FilesModal({ jury_uuid }: any) {
  const { constants } = useConstants();

  const groupageDefault =
    constants["CANDIDATURES"]["GROUPAGE_TELECHARGEMENT_DOCUMENTS"][0];

  const [groupage, setGroupage] = useState(groupageDefault.id);
  const { actions, isLoadingFileKey, downloadedFileKeys } =
    useGenerateCandidatureDocumentsJury();
  const { actions: documentActions } = useDocumentsWithMyPermission({
    documents: constants["CANDIDATURES"]["DOCUMENTS_TYPES"],
  });
  const listColumns = [
    {
      key: "nom",
      name: "Nom du document",
      fieldName: "nom",
      minWidth: 300,
      onRender: (item: any) => <Text>{item.nom}</Text>,
    },
    {
      key: "column2",
      name: "Télécharger",
      fieldName: "telecharger",
      minWidth: 200,
      onRender: (item: any) => {
        console.log("item", item);
        if (downloadedFileKeys.includes(item.key)) {
          return (
            <div
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              Envoi du lien de téléchargement par email en cours
            </div>
          );
        }
        return (
          <LoadLink
            styles={commonButtonStyles.buttonLink}
            onClick={() =>
              actions.onDownloadDocument(jury_uuid, item, groupage)
            }
            text={"Télécharger"}
            isLoading={isLoadingFileKey == item.key}
          />
        );
      },
    },
  ];

  const items = documentActions.getDocumentsWithMyPermission({
    idDocument: "id",
  });

  return (
    <Page
      title={"Documents des candidats (parchemins et fiches de décision) "}
      explain={
        "Veuillez télécharger les documents des candidats une fois les décisions prises"
      }
    >
      <Stack tokens={{ childrenGap: 25 }}>
        <Stack>
          <Dropdown
            required
            label="Groupage des documents"
            selectedKey={groupage ?? undefined}
            options={
              constants["CANDIDATURES"]["GROUPAGE_TELECHARGEMENT_DOCUMENTS"]
            }
            onChange={(e: any, options: any) => {
              setGroupage(options.id);
            }}
            styles={commonInputStyles.dropdownStyle}
            disabled={isLoadingFileKey}
          />
        </Stack>
        <Stack>
          <DetailsList
            layoutMode={DetailsListLayoutMode.justified}
            items={items}
            columns={listColumns}
            selectionMode={SelectionMode.none}
          />
        </Stack>
      </Stack>
    </Page>
  );
}
