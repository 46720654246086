import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { putCandidatRequest } from '../../services/candidatServices';

function useCandidatMutations() {
    const queryClient = useQueryClient();
    let navigate = useNavigate();

    const [candidatUuid, setCandidatUuid] = useState(null); 

    const mutations = {
        PutCandidat: useMutation(putCandidatRequest, {
            onSuccess: () => {
                toast.success("Le profil a été mis à jour avec succès.");
                queryClient.invalidateQueries("ofs");
            },
            onError: (error: any) => {
                !error.response.status || error.response.status >= 500
                    ? navigate(`/erreur`)
                    : toast.error(
                        `Une erreur est survenue lors de la mise à jour du profil : ${error?.response?.data?.message}`
                    );
            },
        })
    }

    

    return {
        candidatUuid,
        setCandidatUuid,
        mutations,
    }
}

export default useCandidatMutations