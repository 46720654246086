import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const inscriptionUtilisateurURI = "utilisateur/inscriptions";
export const inscriptionStructureURI = "utilisateur/demandes-inscriptions";

export const getInscriptionsRequest = async (filters: any, options: any) => {
  const filterType = {
    role: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "roles[]",
    },
    nom: { type: "string" },
    prenom: { type: "string" },
    email: { type: "string" },
    raison_sociale: { type: "string" },
    statut: { type: "select", keyValue: "value", valueNotFilter: undefined },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${inscriptionUtilisateurURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const validerInscriptionRequest = async (userUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/valider/${userUuid}`,
    xsrfHeader
  );
  return response.data;
};

export const validerInscriptionEnMasseRequest = async (usersUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/valider-en-masse`,
    { demandes_inscriptions: usersUuid },
    xsrfHeader
  );
  return response.data;
};

export const refuserInscriptionRequest = async ({
  userUuid,
  commentaire,
}: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/refuser/${userUuid}`,
    { commentaire },
    xsrfHeader
  );
  return response.data;
};

export const getDemandeInscriptionsStructureRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    raison_sociale: { type: "string" },
    siret: { type: "string" },
    siren: { type: "string" },
    type_structure: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${inscriptionStructureURI}/chercher/structure?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const validerDemandeInscriptionStructureRequest = async (
  structureUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/valider/structure/${structureUuid}`,
    xsrfHeader
  );
  return response.data;
};

export const refuserDemandeInscriptionStructureRequest = async ({
  structureUuid,
  commentaire,
}: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/refuser/structure/${structureUuid}`,
    { commentaire },
    xsrfHeader
  );
  return response.data;
};

export const validerDemandeInscriptionStructureEnMasseRequest = async (
  structuresUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `utilisateur/demandes-inscriptions/valider-en-masse/structure`,
    { demandes_inscriptions_structure: structuresUuid },
    xsrfHeader
  );
  return response.data;
};
