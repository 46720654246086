import { atom, useAtom } from "jotai";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import rolesManagementAdapters from "../../adapters/rolesManagementAdapters";
import {
  getBranchRolesSettings,
  putBranchRolesSetting,
} from "../../services/branchServices";
import { atomWithReset } from "jotai/utils";

export const RolesManagementContext = React.createContext({});
export const RolesManagementProvider = ({ value, children }: any) => {
  return (
    <RolesManagementContext.Provider value={value}>
      {children}
    </RolesManagementContext.Provider>
  );
};

export const branchRolesSettingsAtom = atom(null);
const isLoadingBranchRolesSettingsAtom = atom(false);
export const filtersAtom = atomWithReset<any>({});

function useRolesManagement() {
  const [branchRolesSettings, setBranchRolesSettings] = useAtom(
    branchRolesSettingsAtom
  );

  const [isLoadingBranchRolesSettings, setIsLoadingBranchRolesSettings] =
    useAtom(isLoadingBranchRolesSettingsAtom);
  const [filters, setFilters] = useAtom(filtersAtom);

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const actions = {
    getCurrentBranchRolesSettings: async () => {
      const settings = await getBranchRolesSettings();

      setBranchRolesSettings(settings);
    },
    saveCurrentBranchRolesSettings: async (newSettings: any) => {
      setIsLoadingBranchRolesSettings(true);
      try {
        const settings = await putBranchRolesSetting(
          rolesManagementAdapters.transformForAPI(
            newSettings,
            branchRolesSettings
          )
        );
        setBranchRolesSettings(settings);
        reactHookProps.setValue("accesModified", []);
        toast.success(
          "Les paramètres concernant les rôles de la branche ont bien été mis à jour."
        );
      } catch (err: any) {
        if (err.response?.data?.message) {
          toast.error(err.response?.data?.message);
        }
      }
      setIsLoadingBranchRolesSettings(false);
    },
    onChangeCheckbox: (profil: any, acces: any, checked: any) => {
      const accesUpdated: any = reactHookProps.watch("accesModified") || [];
      reactHookProps.setValue("accesModified", [
        ...accesUpdated.filter(
          (i: any) => i.acces != acces || i.profil != profil
        ),
        { profil, acces, value: checked },
      ]);
      reactHookProps.setValue(`${profil}${acces}`, checked);
    },
    changeFilter: (newFilters: any) => {
      setFilters(newFilters);
    },
  };

  return {
    branchRolesSettings,
    isLoadingBranchRolesSettings,
    actions,
    filters,
    reactHookProps,
  };
}

export default useRolesManagement;
