import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useCertificationList from "./useCertificationList";
import CertificationListFilters from "./CertificationListFilters/CertificationListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import { commonButtonStyles } from "../../styles/index";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";
import LoadButton from "../../Common/LoadButton/LoadButton";
import { RiAddFill } from "react-icons/ri";

function CertificationList() {
  const {
    certifications,
    certificationProps,
    actions: actionsPage,
  } = useCertificationList();
  const { actions, isToggleActivationUuid, userSelectedBranch } =
    certificationProps;

  const items = certifications?.data || [];

  const columns = [
    {
      key: "1",
      name: "Type",
      fieldName: "type",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => <Text>{item.type_libelle}</Text>,
    },

    {
      key: "2",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.libelle}</Text>,
    },

    {
      key: "3",
      name: "Domaine",
      fieldName: "domaine",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) => <Text>{item.domaine?.nom}</Text>,
    },

    {
      key: "4",
      name: "Enregistrement",
      fieldName: "type_enregistrement",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          <Text>{item.enregistrement_libelle}</Text>
          <Text>{item["numero_enregistrement"]}</Text>
        </Stack>
      ),
    },
    {
      key: "5",
      name: "Active",
      fieldName: "active",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) =>
        item?.est_acces_et_processus_limites ? (
          <Text style={{ color: "rgb(244, 67, 54)" }}>Limitée</Text>
        ) : (
          <BooleanValue value={item.active} />
        ),
    },
    {
      key: "6",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Link
              to={`/admin/certifications/${item.uuid}`}
              as={RouterLink}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Editer
            </Link>

            <Link
              onClick={() => actionsPage.openDeleteCertificationModal(item)}
              styles={commonButtonStyles.buttonLink}
              style={{ marginLeft: 5, marginTop: 5 }}
            >
              Supprimer
            </Link>

            {!item?.est_acces_et_processus_limites && (
              <LoadLink
                styles={
                  item.active
                    ? commonButtonStyles.disableButtonLink
                    : commonButtonStyles.activeButtonLink
                }
                onClick={() => actions.toggleCertificationActivation(item)}
                isLoading={isToggleActivationUuid == item.uuid}
                text={item.active ? "Désactiver" : "Activer"}
              />
            )}
          </Stack>
        );
      },
    },
  ].filter((col: any) =>
    userSelectedBranch?.gestion_par_domaine ? col : col.fieldName != "domaine"
  );

  return (
    <Stack>
      <Page
        title="Gestion des certifications"
        explain="Veuillez gérer les certifications"
      >
        <Stack
          style={{ marginTop: 25 }}
          horizontal
          wrap
          tokens={{ childrenGap: 20 }}
        >
          <CreateLink
            text="Créer une certification"
            to="/admin/certifications/creer"
          />

          {userSelectedBranch?.gestion_par_domaine && (
            <LoadButton
              icon={<RiAddFill size={38} />}
              isLoading={false}
              text="Créer une certification sur un domaine"
              type="button"
              style={{ fontWeight: 500, marginRight: 6 }}
              onClick={actionsPage.openCreateCertificationModal}
            />
          )}
        </Stack>

        <CertificationListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={certifications?.meta.current_page || 1}
          totalPages={Math.ceil(certifications?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CertificationList;
