import Joi from "joi";

const addDynamicField = Joi.object({
    type: Joi.string().required()
        .messages({ "string.empty": "Veuillez sélectionnier un type de champ" }),
    statut_candidature: Joi.object().required()
        .keys({
            key: Joi.string().required()
                .messages({ "string.empty": "Veuillez sélectionnier un statut de la candidature" }),
            text: Joi.string().required(),
        })
        .unknown(true),
    libelle: Joi.string()
        .required()
        .messages({
            "any.required": "Veuillez renseigner un libellé",
            "string.empty": "Veuillez renseigner un libellé"
        }),
    required: Joi.boolean().required().default(false),
    exported: Joi.boolean().required().default(false),
    options: Joi.when("type", {
        is: Joi.valid("SELECT").required(),
        then: Joi.array()
            .items(
                Joi.object().required()
                    .keys({
                        key: Joi.string().required()
                            .messages({ "string.empty": "Veuillez saisir la valeur de l'option non complétée" }),
                        text: Joi.string().required()
                            .messages({ "string.empty": "Veuillez saisir la valeur de l'option non complétée" }),
                    }).unknown(true),
            )
            .unique((a, b) => a.key === b.key)
            .min(1)
            .messages({
                "any.required": "Veuillez ajouter au moins une option",
                "array.min": "Veuillez ajouter au moins une option",
                "array.unique": "Veuillez saisir des valeurs d'option unique"
            }),
        otherwise: Joi.array(),
    }),
    multiple: Joi.when("type", {
        is: Joi.valid("SELECT").required(),
        then: Joi.boolean().default(false),
        otherwise: Joi.boolean().allow(null).default(false),
    }),
    fichier_formats: Joi.when("type", {
        is: Joi.valid("FILE").required(),
        then: Joi.array()
            .items(
                Joi.string()
            )
            .min(1)
            .messages({
                "any.required": "Veuillez ajouter au moins un format de fichier",
                "array.min": "Veuillez ajouter au moins un format de fichier",
            }),
        otherwise: Joi.array(),
    }),
}).unknown(true);

const validations = {
    validateAddDynamicField: (form: any) => {
        return addDynamicField.validate(form, { abortEarly: false });
    },
};

export default validations;