const paginationStyles = {
  iconButton: {
    icon: {
      fontSize: 16,
    },
    root: {
      height: 40,
      width: 40,
      selectors: {
        ".ms-Button-icon": {
          fontWeight: "600",
        },
      },
    },
  },
};

export default paginationStyles;
