import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useOfflineCandidaturePile from "../../../hooks/offlineCandidature/useOfflineCandidaturePile";
import useAuth from "../../../hooks/useAuth";
import {
  deleteInvitationSecondEvaluateur,
  postInviteSecondEvaluateur,
} from "../../../services/candidatureService";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import AddSecondEvaluateurModal from "../Evaluation/AddSecondEvaluateurModal/AddSecondEvaluateurModal";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import useCreateurCandidature from "../../../hooks/certifications/useCreateurCandidature";
import tools from "../../../utils/tools";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import useConstants from "../../../hooks/useConstants";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";

function useAvisFormateurEtEvaluation({ id_candidature }: any) {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingSecondEvaluateur, setIsSavingSecondEvaluateur] =
    useState(false);
  const [isSuccessEnregistrement, setIsSuccessEnregistrement] = useState(false);
  const [
    isSuccessToInviteSecondEvaluateur,
    setIsSuccessToInviteSecondEvaluateur,
  ] = useState(false);
  const [isRemovedSecondEvaluateur, setIsRemovedSecondEvaluateur] =
    useState(false);
  const [isLoadingRemoveSecondEvaluateur, setIsLoadingRemoveSecondEvaluateur] =
    useState(false);

  const { candidature, actions: candidatureActions } = useCandidature();
  const { isMyOF } = useCreateurCandidature();
  const { isEditMode, mecActions } = useMiseEnConfirmite();
  const {
    STATUT_PAGE,
    isThisPageStatusBeforeCandidatureStatus,
    isReferentielStillEditable,
  } = useCandidatureWorkflow();
  const { actions: pileActions, isOffline } = useOfflineCandidaturePile();

  const { auth }: any = useAuth();
  const { actions: accessActions, roleSettings } = useAccessSettings();
  const { actions: modalActions } = useCustomModal();
  const { labels } = useLabelSettings();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const { constants } = useConstants();
  const { dynamicFields } = useDynamicFields();

  const reactHookProps = useForm({
    mode: "onBlur",
  });

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        false,
        dynamicFields
      )
    );
  };

  const conditionnalDisplayVariable = {
    isEvaluateurThisCandidature: candidature.evaluateur?.uuid == auth.user.uuid,
    isSecondEvaluateurThisCandidature:
      candidature.second_evaluateur?.uuid == auth.user.uuid,
    doitAvoirAvisFormateur:
      roleSettings.filter(
        (a: any) =>
          a.est_parametrable &&
          a.est_accessible &&
          a.acces == "CREER_AVIS_FORMATEUR"
      ).length != 0 &&
      (accessActions.canI({
        action: branchAccessEnum.ACTIVER_AVIS_FORMATEUR,
      }).granted ||
        accessActions.canI({
          action: branchAccessEnum.AVIS_FORMATEUR_FACULTATIF,
        }).granted) &&
      candidature.of_lie &&
      !candidature.certification.est_acces_et_processus_limites,
  };

  const actions = {
    openAddSecondEvaluateurModal: () => {
      modalActions.openModal({
        header: labels.INVITE_EVALUATEUR_TITRE,
        body: (
          <AddSecondEvaluateurModal
            onSave={actions.onInviteSecondEvaluateur}
            isSaving={isSavingSecondEvaluateur}
          />
        ),
      });
    },
    onInviteSecondEvaluateur: async (secondEvaluateur: any) => {
      setIsSavingSecondEvaluateur(true);
      const response = await postInviteSecondEvaluateur(
        candidature.uuid,
        secondEvaluateur
      );
      if (!response.error) {
        toast.success("Second évaluateur invité avec succès.");
        setIsSuccessToInviteSecondEvaluateur(true);
      } else {
        toast.error(response.error);
      }
      setIsSuccessToInviteSecondEvaluateur(false);
      setIsSavingSecondEvaluateur(false);
    },
    onSaveEvaluation: async (data: any, isEnregistrerEvaluation: boolean) => {
      setIsSaving(true);
      //Si on modifie evaluationObject, il faut aussi le modifier dans useEvaluationObject.ts
      const evaluationObject = {
        ...data,
        isEnregistrerEvaluation,
        evaluation: data.blocs_competences_passes.map((b: any) => ({
          uuid: b.candidature_bloc_competence_uuid,
          decision: b.decision_evaluation,
          commentaire_evaluation: b.commentaire_evaluation,
          competences_associees: b.competences.map((c: any) => ({
            uuid: c.uuid,
            decision: c.decision_evaluation,
            criteres_evaluation: accessActions.canI({
              action: branchAccessEnum.EVALUATION_PAR_CRITERE,
            }).granted
              ? c.criteres
                ? c.criteres.map((l: any) => ({
                    libelle: l.libelle,
                    decision: l.decision_evaluation?.toString(),
                  }))
                : null //waiting api
              : null,
          })),
        })),
      };

      if (isOffline) {
        pileActions.onSaveEvaluation(data);
        toast.info(
          "Vos informations on été temporairement sauvegardées et sont en attente de synchronisation"
        );
      } else {
        await candidatureActions.putCandidatureFormData(
          evaluationObject,
          STATUT_PAGE,
          reactHookProps
        );
        pileActions.onRemoveCandidatureFromPile(data.uuid, false);
        fetchModifiedCandidature();
      }
      setIsSaving(false);
    },
    onSaveSecondEvaluation: async (data: any) => {
      setIsSaving(true);
      const secondEvaluationObject = {
        ...data,
        statut_a_modifier: "avis_second_evaluateur",
      };
      if (isOffline) {
        pileActions.onSaveEvaluation(secondEvaluationObject);
        toast.info(
          "Vos informations on été temporairement sauvegardées et sont en attente de synchronisation"
        );
      } else {
        await candidatureActions.putCandidatureFormData(
          secondEvaluationObject,
          STATUT_PAGE,
          reactHookProps
        );
        fetchModifiedCandidature();
      }
      setIsSaving(false);
    },
    onValidateEvaluvation: () => {
      const validateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_evaluation: "VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_evaluation: "VALIDE",
            criteres:
              accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted &&
              candidature.certification.modalites_evaluation.type == 1
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      ...l,
                      decision_evaluation: "VALIDE",
                    }))
                  : null
                : c.criteres,
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", validateAll);
    },
    onInvalidateEvaluvation: () => {
      const invalidateAll = reactHookProps
        .watch("blocs_competences_passes")
        .map((b: any) => ({
          ...b,
          decision_evaluation: "NON_VALIDE",
          competences: b.competences?.map((c: any) => ({
            ...c,
            decision_evaluation: "NON_VALIDE",
            criteres:
              accessActions.canI({
                action: branchAccessEnum.EVALUATION_PAR_CRITERE,
              }).granted &&
              candidature.certification.modalites_evaluation.type == 1
                ? c.criteres
                  ? c.criteres.map((l: any) => ({
                      ...l,
                      decision_evaluation: "NON_VALIDE",
                    }))
                  : null
                : c.criteres,
          })),
        }));
      reactHookProps.setValue("blocs_competences_passes", invalidateAll);
    },
    onSaveAvisFormateur: async (data: any, estEnregistre: any) => {
      setIsSaving(true);
      setIsSuccessEnregistrement(false);
      const avisFormateurObject = {
        ...data,
        statut_a_modifier: "avis_formateur",
        avis_formateur: {
          id: data.avis_formateur_id ?? null,
          nom: data.nom_formateur,
          prenom: data.prenom_formateur,
          email: data.email_formateur,
          date_debut_formation: data.date_debut_formation,
          date_fin_formation: data.date_fin_formation,
          avis: data.avis_formateur,
        },
        isEnregistrerAvisFormateur: estEnregistre,
      };
      await candidatureActions.putCandidatureFormData(
        avisFormateurObject,
        STATUT_PAGE,
        reactHookProps
      );

      if (estEnregistre) {
        setIsSuccessEnregistrement(true);
      }

      fetchModifiedCandidature();
      setIsSaving(false);
    },
    onCancelInvitationSecondEvaluateur: async () => {
      setIsLoadingRemoveSecondEvaluateur(true);
      setIsRemovedSecondEvaluateur(false);
      const response = await deleteInvitationSecondEvaluateur(candidature.uuid);
      if (!response.error) {
        toast.success("Second évaluateur supprimé avec succès.");
      } else {
        toast.error(response.error);
      }
      setIsRemovedSecondEvaluateur(true);
      setIsLoadingRemoveSecondEvaluateur(false);
    },
  };

  const getPermission = {
    evaluation: () => {
      if (!candidature.evaluateur) {
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      if (!candidature.evaluation_brouillon) {
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      if (
        accessActions.canI({
          action: [branchAccessEnum.OBLIGER_AVIS_SECOND_EVALUATEUR],
        }).granted &&
        !candidature.second_evaluateur?.uuid
      ) {
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      if (
        isThisPageStatusBeforeCandidatureStatus(
          tools.getCandidatureStatut(candidature)
        )
      ) {
        if (
          isReferentielStillEditable(candidature.statut, [
            "validation_evaluation",
          ])
        ) {
          return userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION;
        }
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      return userAccessEnum.SAISIR_EVALUATION;
    },
    seconde_evaluation: () => {
      if (candidature.avis_second_evaluateur?.avis) {
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      if (
        isThisPageStatusBeforeCandidatureStatus(
          tools.getCandidatureStatut(candidature)
        )
      ) {
        if (
          isReferentielStillEditable(candidature.statut, [
            "validation_evaluation",
          ])
        ) {
          return userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR;
        }
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      return userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR;
    },
    avis_formateur: () => {
      if (!candidature.avis_formateur_brouillon) {
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      if (isThisPageStatusBeforeCandidatureStatus(candidature.statut)) {
        if (
          isReferentielStillEditable(candidature.statut, ["positionnement"])
        ) {
          return userAccessEnum.MODIFIER_AVIS_FORMATEUR;
        }
        return userAccessEnum.NO_ACCESS_EVEN_ADMIN;
      }

      return userAccessEnum.CREER_AVIS_FORMATEUR;
    },
  };

  let applicableEditPermissionEvaluation = getPermission.evaluation();
  let applicableEditSecondeEvaluationPermissions =
    getPermission.seconde_evaluation();
  let applicableEditPermissionAvisFormateur = getPermission.avis_formateur();

  const inputsDisabledEvaluation =
    (!accessActions.canI({ action: [applicableEditPermissionEvaluation] })
      .granted ||
      (!conditionnalDisplayVariable.isEvaluateurThisCandidature &&
        !isAnyKindOfAdmin)) &&
    (!isEditMode || isEditMode !== StatutCandidature.EVALUATION);

  const inputsDisabledSecondeEvaluation =
    (!accessActions.canI({
      action: [applicableEditSecondeEvaluationPermissions],
    }).granted ||
      !conditionnalDisplayVariable.isSecondEvaluateurThisCandidature) &&
    (!isEditMode || isEditMode !== StatutCandidature.AVIS_SECOND_EVALUATEUR);

  const inputsDisabledAvisFormateur =
    !accessActions.canI({
      action: [applicableEditPermissionAvisFormateur],
    }).granted ||
    (!isMyOF(candidature.of_lie?.uuid) && !isAnyKindOfAdmin);

  return {
    candidature,
    reactHookProps,
    isOffline,
    isEditMode,
    isOpenedInModal,
    isSaving,
    isSuccessEnregistrement,
    isSuccessToInviteSecondEvaluateur,
    isRemovedSecondEvaluateur,
    isLoadingRemoveSecondEvaluateur,
    conditionnalDisplayVariable,
    actions,
    candidatureActions,
    accessActions,
    mecActions,
    applicableEditPermissionEvaluation,
    applicableEditPermissionAvisFormateur,
    applicableEditSecondeEvaluationPermissions,
    inputsDisabledEvaluation,
    inputsDisabledAvisFormateur,
    inputsDisabledSecondeEvaluation,
    fetchModifiedCandidature,
  };
}

export default useAvisFormateurEtEvaluation;
