import { useState } from "react";
import {
  useQueryClient,
  useMutation
} from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useInitialize from "./useInitialize";
import tools from "../utils/tools";

import {
  postBranchRequest,
  putBranchRequest,
  patchActivateBranchRequest,
  patchDesactivateBranchRequest
} from "../services/branchServices"

import branchAdapters from '../adapters/branchAdapters'
import { useAtom } from "jotai";
import { isGestionDomaineModifiedOnMyBranchAtom } from "../Pages/BranchDetail/useBranchDetail";
import { userSelectedBranchAtom } from "../atoms/branchAtom";

function useBranch() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isGestionDomaineModifiedOnMyBranch, setIsGestionDomaineModifiedOnMyBranch] = useAtom(isGestionDomaineModifiedOnMyBranchAtom)
  const [, setUserSelectedBranch]: any = useAtom(userSelectedBranchAtom);

  const { initializeBranches } = useInitialize();

  const [branchId, setBranchId] = useState(null);
  const [isToggleActivationId, setIsToggleActivationId] = useState(null)

  const mutations = {
    PostBranch: useMutation(postBranchRequest, {
      onSuccess: (data) => {
        initializeBranches()
        toast.success("La branche a été créée avec succès");
        actions.setUpdateBranchId(data.id)
        queryClient.invalidateQueries("branches");
      },
      onError: (error: any) => {
        !(error.response.status) || error.response.status >= 500 ? navigate(`/erreur`) : toast.error(`Une erreur est survenue lors de la création de la branche : ${error?.message}`);
      }
    }),
    PutBranch: useMutation(putBranchRequest, {
      onSuccess: (data) => {
        if(isGestionDomaineModifiedOnMyBranch){
          setUserSelectedBranch((prev:any)=>({...prev, gestion_par_domaine:data.gestion_par_domaine}));
          setIsGestionDomaineModifiedOnMyBranch(false)
        }
        initializeBranches()
        toast.success("La branche a été mise à jour avec succès");
        queryClient.invalidateQueries("branches");
      },
      onError: (error: any) => {
        !(error.response.status) || error.response.status >= 500 ? navigate(`/erreur`) : toast.error(`Une erreur est survenue lors de la mise à jour de la branche : ${error?.message}`)
      }
    }),
    ToggleBranchActivate: useMutation(patchActivateBranchRequest, {
      onSuccess: () => {
        setIsToggleActivationId(null)
        toast.success("La branche a été activée avec succès");
        queryClient.invalidateQueries("branches")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`);
        setIsToggleActivationId(null)
      }
    }),
    ToggleBranchDesactivate: useMutation(patchDesactivateBranchRequest, {
      onSuccess: () => {
        setIsToggleActivationId(null)
        toast.success("La branche a été désactivée avec succès");
        queryClient.invalidateQueries("branches")
      },
      onError: (error: any) => {
        toast.error(`Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`);
        setIsToggleActivationId(null)
      }
    }),
  };

  const actions = {
    setUpdateBranchId: (data: any) => {
      setBranchId(data);
    },
    postBranch: async (branch: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        branchAdapters.transformForAPI(branch),
        null
      );
      mutations.PostBranch.mutate(formData);
    },
    putBranch: async (branch: any) => {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        branchAdapters.transformForAPI(branch),
        null
      );
      mutations.PutBranch.mutate(formData)
    },
    toggleBranchActivation: (branch: any) => {
      setIsToggleActivationId(branch.id)
      if (branch.active) {
        mutations.ToggleBranchDesactivate.mutate(branch.id)
      } else {
        mutations.ToggleBranchActivate.mutate(branch.id)
      }
    },
  };

  const {
    isLoading: isLoadingPostBranch,
    isError: isErrorPostBranch,
    error: errorPostBranch,
  } = mutations.PostBranch;

  const {
    isLoading: isLoadingPutBranch,
    isError: isErrorPutBranch,
    error: errorPutBranch,
  } = mutations.PutBranch;

  const {
    isLoading: isLoadingToggleBranchActivate,
  } = mutations.ToggleBranchActivate;

  const {
    isLoading: isLoadingToggleBranchDesactivate,
  } = mutations.ToggleBranchDesactivate;

  return {
    branchId,
    isLoading: isLoadingPostBranch || isLoadingPutBranch,
    isLoadingActivation: isLoadingToggleBranchActivate || isLoadingToggleBranchDesactivate,
    isToggleActivationId,
    isErrorPostBranch,
    errorPostBranch,
    isErrorPutBranch,
    errorPutBranch,
    actions
  }
}

export default useBranch