import { Label, TextField, Text, Icon } from "@fluentui/react";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../config/axiosConfig";
import Loader from "../../Loader/Loader";
import SearchResults from "../common/SearchResults/SearchResults";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";

import { commonInputStyles } from "../../../styles/index";
import InfoMessage from "../common/InfoMessage/InfoMessage";

const eCertifApi = axiosInstance;
let searchTimeout: any = null;

function SelectFieldAsync({
  label, //label global de l'input
  placeholder, //placeholder du champ de recherche
  errorMessage, //message d'erreur de l'input
  resourceURI, //url de la route à appeler pour search les datas à partir de la variable "terms"
  method, //méthode pour fetch les datas de recherche (GET,POST...)
  renderItem = () => {}, //fonction à utiliser pour déclarer comment doivent s'afficher les résultats retournés par l'api ex : renderItem : item => item.name
  renderValue = null, //fonction à utiliser pour déclarer comment doit s'afficher la valeur (value) qui a été selectionnée ex : renderValue : item => item.name+' '+item.category
  getPostData = () => {}, //si des post datas sont à passer à la requête, défini l'objet à passer à l'aide des termes de recherche ex : terms => ({name:terms})
  dataIndex = "uuid", //identifiant unique qui sera toujours présent dans les résultats API et dans l'objet sélectionné dans value
  onChange = () => {}, //appelé lorsque la valeur sélectionnée change
  onBlur = () => {},
  value, //objet ex : {name:""}, valeur de l'input
  name, //nom de l'input
  defaultValue, //sera un objet vide {} la plupart du temps
  defaultTerms = "",
  fetchOnLoad = false, //récupérer les datas api dès l'affichage du composant
  fieldRef,
  required, //si requis
  handleResponse = () => {},
  fakeData = false, // fake data
  handleFakeData = () => {},
  addQueryURL = (resourceURI: any) => {
    return resourceURI;
  },
  disabled = false,
  prefilled = false,
  maxLength = 0,
  unremovableItemsFn = (item: any) => {},
}: any) {
  const [isSearching, setIsSearching] = useState(false);
  const [isShadowLoading, setIsShadowLoading] = useState(false);
  const [terms, setTerms] = useState(defaultTerms);
  const [searchResults, setSearchResults] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");

  const handleChangeTerms = (e: any) => {
    setTerms(e.target.value);
  };

  useEffect(() => {
    if (fetchOnLoad) {
      searchDatas();
    }
  }, []);

  useEffect(() => {
    setSearchResults([]);
    if (terms.length > 0) {
      autoSearchData();
    }
  }, [addQueryURL]);

  useEffect(() => {
    if (defaultTerms) {
      setTerms(defaultTerms);
    }
  }, [defaultTerms]);

  useEffect(() => {
    setIsShadowLoading(false);
    if (terms.length > 0) {
      autoSearchData();
    }
  }, [terms]);

  const autoSearchData = () => {
    if (fakeData) {
      searchDatas();
    } else {
      clearTimeout(searchTimeout);
      if (!isSearching) {
        setIsShadowLoading(true);
        searchTimeout = setTimeout(() => {
          searchDatas();
        }, 2000);
      }
    }
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      searchDatas();
    }
  };

  const searchDatas = async () => {
    setIsShadowLoading(false);
    if (fakeData) {
      setSearchResults(handleFakeData(terms));
    } else {
      if (terms?.length > 1) {
        clearTimeout(searchTimeout);
        setIsSearching(true);
        let postDatas = null;
        if (method === "POST") {
          postDatas = getPostData(terms);
        }
        const response = await eCertifApi({
          method,
          url: addQueryURL(resourceURI, terms),
          data: postDatas,
        });
        setIsSearching(false);
        setSearchResults(handleResponse(response, terms));

        if (handleResponse(response, terms).length == 0) {
          setInfoMessage("Aucun résultat trouvé");
        } else {
          setInfoMessage("");
        }
      }
    }
  };

  const selectItem = (item: any) => {
    setTerms("");
    clearTimeout(searchTimeout);
    onChange({ target: { name, value: item } });
  };

  const removeItem = () => {
    onChange({ target: { name, value: defaultValue } });
  };

  const hasValue = () => {
    if (!value) {
      //fix return null from api
      return false;
    }
    if (typeof value === "string") {
      return value && value.length > 0;
    }
    if (typeof value === "object") {
      return Object.keys(value).length > 0;
    }
  };

  const getFilteredSearchResults = () => {
    return searchResults.filter((item: any) => {
      return hasValue() ? item[dataIndex] !== value[dataIndex] : true;
    });
  };

  const hasValidSearchResults = () => {
    return searchResults.length > 0 && getFilteredSearchResults().length > 0;
  };

  return (
    <div>
      <Label required={required} styles={commonInputStyles.label}>
        {label}{" "}
        <span className="ms-fontColor-gray100 ms-fontWeight-regular">
          (sélection unique)
        </span>
      </Label>
      <Text variant="small">
        (Veuillez entrer au minimum 2 caractères pour effectuer la recherche)
      </Text>
      {/* {hasValue() && (
        <SelectedItem
          key={`valueItem${value.uuid}`}
          value={value}
          removeItem={removeItem}
          renderValue={renderValue}
          renderItem={renderItem}
        />
      )} */}
      <div
        style={{
          position: "relative",
          background: disabled ? "#f4f4f4" : prefilled ? "#E7F0FE" : "",
        }}
      >
        <Icon
          className="d-center"
          iconName="Search"
          style={{
            position: "absolute",
            height: "100%",
            color: "grey",
            left: 5,
          }}
        />
        <TextField
          styles={commonInputStyles.searchField}
          placeholder={placeholder}
          onChange={handleChangeTerms}
          value={terms}
          type="text"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          componentRef={fieldRef}
          disabled={disabled}
          maxLength={maxLength || 10000}
        />
        {isSearching ||
          (isShadowLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: 5,
                transform: "translateY(-50%)",
              }}
            >
              <Loader size="small" />
            </div>
          ))}
      </div>

      {(hasValidSearchResults() || hasValue()) && (
        <SearchResults
          filteredSearchResults={getFilteredSearchResults()}
          selectedItems={value ? [value] : null}
          renderItem={renderItem}
          renderValue={renderValue}
          selectItem={selectItem}
          removeItem={removeItem}
          dataIndex={dataIndex}
          disabled={disabled}
          prefilled={prefilled}
          unremovableItemsFn={(item: any) => unremovableItemsFn(item)}
        />
      )}

      <InfoMessage infoMessage={infoMessage} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export default SelectFieldAsync;

/*

Exemple d'appel :

<Controller
  render={({ field: { name, value, onBlur, ref } }) => (
    <SelectFieldAsync
      label="Sélectionnez un IDCC"
      placeholder="Recherchez un IDCC en tapant les premières lettres de son nom"
      resourceURI={branchURI}
      method="POST"
      renderItem={(item: any) => item.name}
      renderValue={(item: any) => item.name}
      getPostData={(terms: string) => ({ name: terms })}
      dataIndex="id"
      name={name}
      value={value}
      onBlur={onBlur}
      fieldRef={ref}
      onChange={(e: any) => {
        setValue(name, e.target.value, { shouldValidate: true });
      }}
      defaultValue={{}}
      errorMessage={(errors.idcc as any)?.message}
    />
  )}
  control={control}
  name="idcc"
/>

*/
