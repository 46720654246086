import { DefaultButton } from "@fluentui/react";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import ImportEntities from "../ImportEntities/ImportEntities";
import { entityConfig } from "../ImportEntities/useImportEntities";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";
import useTheme from "../useTheme";

const OpenImportButton = ({ entityType }: any) => {
  const entityRef = tools.findIn(entityConfig, entityType, "type") as any;
  const { actions } = useCustomModal();
  const { primaryColor } = useTheme();
  const onOpenImportModal = () => {
    actions.openModal({
      body: <ImportEntities entityType={entityType} isAsync />,
      fullWidth: true,
    });
  };

  return (
    <DefaultButton
      style={{
        fontWeight: 500,
        color: primaryColor,
        borderColor: primaryColor,
      }}
      styles={commonButtonStyles.defaultButton}
      onClick={onOpenImportModal}
    >
      {entityRef.title}
    </DefaultButton>
  );
};

export default OpenImportButton;
