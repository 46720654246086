import { BsCheck2All } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import useConstants from "../../../../hooks/useConstants";
import CounterItem from "./CounterItem/CounterItem";

const BlocSelectionRecap = ({
  blocs_competences,
  blocs_competences_passes,
  nb_blocs_au_choix_a_valider,
  showValidation = false,
  blocs_competences_updated,
  finalResult = false,
}: any) => {
  const { constants } = useConstants();

  const statusesCount: any = {};
  constants.CERTIFICATIONS.BLOC_COMPETENCE_OPTION_STATUTS.forEach(
    (statut: any) => {
      const allBlocs = blocs_competences.filter(
        (bloc: any) => bloc.statut === statut.id
      );
      const acquiredBlocs = blocs_competences.filter(
        (b: any) =>
          b.statut === statut.id &&
          b.deja_acquis_dans_une_precedente_certification
      );
      const selectedBlocs = blocs_competences_passes
        ? blocs_competences_passes.filter((b: any) => b.statut === statut.id)
        : [];
      const total = allBlocs.length;
      const dejaAcquis = acquiredBlocs.length;
      const selected = selectedBlocs.length + dejaAcquis;
      let complete = false;
      let valid = 0;
      let invalid = 0;

      selectedBlocs.forEach((bloc: any) => {
        if (bloc.decision_president == "VALIDE") {
          valid = valid + 1;
        }
        if (bloc.decision_president == "NON_VALIDE") {
          invalid = invalid + 1;
        }
      });

      switch (statut.id) {
        case 1:
          complete = selected >= total;
          break;
        case 2:
          complete = selected >= nb_blocs_au_choix_a_valider;
          break;
        case 3:
          complete = true;
      }

      statusesCount[statut.id] = {
        selected: selected > total ? total : selected,
        total: statut.id == 2 ? nb_blocs_au_choix_a_valider : total,
        complete,
        dejaAcquis,
        valid,
        invalid,
      };

      let areAllBlocsValidated = false;
      let doesAllBlocsHaveDecision = false;
      let nbBlocsWithDecision = 0;
      let nbBlocsValidated = 0;
      if (blocs_competences_updated) {
        const updatedBlocsOfStatut = blocs_competences_updated.filter(
          (b: any) => b.statut == statut.id
        );
        nbBlocsWithDecision = updatedBlocsOfStatut.reduce((a: any, b: any) => {
          if (b.decision_president) {
            return a + 1;
          }
          return a;
        }, 0);

        if (nbBlocsWithDecision == updatedBlocsOfStatut.length) {
          doesAllBlocsHaveDecision = true;
          nbBlocsValidated = updatedBlocsOfStatut.reduce((a: any, b: any) => {
            if (b.decision_president == "VALIDE") {
              return a + 1;
            }
            return a;
          }, 0);
          areAllBlocsValidated =
            nbBlocsValidated + dejaAcquis == statusesCount[statut.id].selected;
        }
      }

      statusesCount[statut.id]["areAllBlocsValidated"] = areAllBlocsValidated;
      statusesCount[statut.id]["doesAllBlocsHaveDecision"] =
        doesAllBlocsHaveDecision;
      statusesCount[statut.id]["nbBlocsWithDecision"] = nbBlocsWithDecision;
      statusesCount[statut.id]["nbBlocsValidated"] = nbBlocsValidated;
    }
  );

  let canFullyCertify =
    statusesCount["1"].complete &&
    statusesCount["2"].complete &&
    statusesCount["3"].complete;

  const allDecisionsAreMade =
    statusesCount["1"].doesAllBlocsHaveDecision &&
    statusesCount["2"].doesAllBlocsHaveDecision &&
    statusesCount["3"].doesAllBlocsHaveDecision;

  const checkFullValidation = () => {
    let completeValidation = false;

    if (statusesCount["1"].areAllBlocsValidated) {
      completeValidation = true;
    }
    if (statusesCount["2"].selected > 0) {
      completeValidation =
        completeValidation && statusesCount["2"].areAllBlocsValidated;
    }
    return completeValidation;
  };

  const checkNoneValidation = () => {
    let noneValidation = false;

    if (statusesCount["1"].nbBlocsValidated == 0) {
      noneValidation = true;
    }
    if (statusesCount["2"].selected > 0) {
      noneValidation =
        noneValidation && statusesCount["2"].nbBlocsValidated == 0;
    }
    return noneValidation;
  };

  let certificationObtention = checkNoneValidation()
    ? "none"
    : checkFullValidation()
    ? "full"
    : "partial";

  //si un résultat a déjà été défini, on surcharge la valeur de certificationObtention
  let hidePronostic = false;

  if (finalResult) {
    switch (finalResult) {
      case "VALIDE":
        certificationObtention = "full";
        canFullyCertify = true;
        hidePronostic = true;

        break;
      case "NON_VALIDE":
        certificationObtention = "none";
        hidePronostic = true;
        break;
      case "VALIDE_PARTIELLEMENT":
        certificationObtention = "partial";
        hidePronostic = true;
        break;
      case "EN_ATTENTE_RESULTAT":
        break;
      default:
        break;
    }
  }
  let colorStatus: any = {};
  switch (certificationObtention) {
    case "none":
      colorStatus = {
        color: "black",
        icon: (
          <BsCheck2All style={{ color: "white", marginRight: 10 }} size={30} />
        ),
        text: "Certification non obtenue",
      };
      break;
    case "full":
      colorStatus = {
        color: "green",
        icon: (
          <BsCheck2All style={{ color: "white", marginRight: 10 }} size={30} />
        ),
        text: canFullyCertify
          ? "Certification obtenue"
          : "Certification obtenue partiellement",
      };
      break;
    case "partial":
      colorStatus = {
        color: "#ff8f00",
        icon: (
          <BsCheck2All style={{ color: "white", marginRight: 10 }} size={30} />
        ),
        text: "Certification obtenue partiellement",
      };
      break;
  }

  return (
    <div>
      {constants.CERTIFICATIONS.BLOC_COMPETENCE_OPTION_STATUTS.map(
        (type_block: any) => {
          const {
            selected,
            total,
            complete,
            dejaAcquis,
            valid,
            invalid,
            areAllBlocsValidated,
            doesAllBlocsHaveDecision,
            nbBlocsWithDecision,
            nbBlocsValidated,
          } = statusesCount[type_block.id];
          if (total === 0) {
            return null;
          }

          return (
            <CounterItem
              selected={selected}
              total={total}
              complete={complete}
              dejaAcquis={dejaAcquis}
              valid={valid}
              invalid={invalid}
              text={type_block.text}
              showValidation={showValidation && !blocs_competences_updated}
              showLiveValidation={blocs_competences_updated?.length > 0}
              areAllBlocsValidated={areAllBlocsValidated}
              doesAllBlocsHaveDecision={doesAllBlocsHaveDecision}
              nbBlocsWithDecision={nbBlocsWithDecision}
              nbBlocsValidated={nbBlocsValidated}
            />
          );
        }
      )}
      {!hidePronostic && (
        <div
          style={{
            marginTop: 20,
            padding: "5px 10px",
            borderRadius: 30,
            fontSize: 18,
            fontWeight: "bold",
            background: canFullyCertify ? "green" : "#ff8f00",
            color: "white",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {canFullyCertify ? (
            <BsCheck2All
              style={{ color: "white", marginRight: 10 }}
              size={30}
            />
          ) : (
            <IoMdClose style={{ color: "white", marginRight: 10 }} size={30} />
          )}
          {canFullyCertify
            ? "Le candidat pourra être validé complètement"
            : "Le candidat ne pourra être validé que partiellement"}
        </div>
      )}

      {allDecisionsAreMade && (
        <div
          style={{
            marginTop: 20,
            marginLeft: 5,
            padding: "5px 10px",
            borderRadius: 30,
            fontSize: 18,
            fontWeight: "bold",
            background: colorStatus.color,
            color: "white",
            display: "inline-flex",
            alignItems: "center",
          }}
        >
          {colorStatus.icon}
          {colorStatus.text}
        </div>
      )}
    </div>
  );
};

export default BlocSelectionRecap;
