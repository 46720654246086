import { Stack, Text } from '@fluentui/react'
import SectionTitle from '../../../../Common/SectionTitle/SectionTitle'

function EntrepriseRecap({ entreprise }: any) {

    //pays obligatoirement FRANCE ? 
    const adresse = entreprise ? `${entreprise.adresse?.adresse.toLowerCase()} ${entreprise.adresse?.code_postal} ${entreprise.adresse?.ville.charAt(0).toUpperCase() + entreprise.adresse?.ville.slice(1).toLowerCase()} - France` : ""

    return entreprise ? (
        <Stack>
            <SectionTitle title="ENTREPRISE LIÉE À LA CANDIDATURE" />
            <Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text style={{ fontWeight: "bold" }}>
                        {entreprise.nom}
                    </Text>
                    <Text>-</Text>
                    <Text className="ms-fontColor-gray110">
                        {entreprise.siret}
                    </Text>
                </Stack>

                <Text>{adresse}</Text>
            </Stack>
        </Stack>
    ) : null
}

export default EntrepriseRecap