import { useAtom } from "jotai";
import { useQuery } from "react-query";

import tools from "../../utils/tools";

import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import DeleteConfirmDialog from "../../Common/Overlays/DeleteConfirmDialog/DeleteConfirmDialog";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { stateList } from "../../fakeData/State.data";
import useOf from "../../hooks/of/useOf";
import { getExportOfs, getOfsRequest } from "../../services/ofServices";
import { useState } from "react";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";
import { toast } from "react-toastify";

export const initialFilters = {
  raison_sociale: "",
  siret: "",
  //qualiopi: stateList[0],
  actif: stateList[0],
  interbranche: stateList[0],
  certifications: [],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useOfList() {
  const ofProps = useOf();

  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [isLoadingExportOf, setIsLoadingExportOf] = useState(false);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: ofs, isLoading: isLoadingOfs } = useQuery(
    ["ofs", optionsFilters, ofProps.userSelectedBranch],
    () => getOfsRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openDeleteOfModal: (of: any) => {
      modalActions.openModal({
        body: (
          <DeleteConfirmDialog
            libelle={of.raison_sociale}
            deleteFunction={() => ofProps.actions.deleteOf(of.uuid)}
          />
        ),
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportOfs: async () => {
      setIsLoadingExportOf(true);
      try {
        const firstResponse: any = await getExportOfs(filters).catch(
          (e: any) => {
            console.log("ERR", e);
            toast.error(
              "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
            );
            setIsLoadingExportOf(false);
          }
        );

        if (!firstResponse) return;

        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });

        setIsLoadingExportOf(false);
      } catch (e: any) {
        setIsLoadingExportOf(false);
      }
    },
  };

  return {
    ofs,
    ofProps,
    isLoadingOfs,
    isLoadingExportOf,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
    userSelectedBranch,
  };
}

export default useOfList;
