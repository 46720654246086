import { useContext } from "react";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";
import ABCNotationForm from "../ABCNotationForm/ABCNotationForm";
import ScoreNotationForm from "../ScoreNotationForm/ScoreNotationForm";

const NotationForm = ({ modalites_evaluation, modalites_evaluation_notation }: any) => {

  const {constants} = useConstants()

  const selectedNotation = modalites_evaluation as any;
  if (!selectedNotation) return null;
  const { isLetter, isScore } = selectedNotation;
  if (isLetter) {
    const notationValue = tools.getModaliteNotationObject(
      (modalites_evaluation as any)?.id,
      modalites_evaluation_notation,
      constants['CERTIFICATIONS']['MODALITE_EVALUATION_TYPES']
    );

    return (
      <ABCNotationForm
        label="Personnalisez la modalité d'évaluation :"
        name="modalites_evaluation_notation"
        initialValue={notationValue}
      />
    );
  }
  if (isScore) {
    return <ScoreNotationForm />;
  }

  return null;
};

export default NotationForm;
