import { atom, useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCandidature from "../../../hooks/candidature/useCandidature";
import { postRegenerateDocuments } from "../../../services/candidatureService";
import useWorkflowNavigate from "../useWorkflowNavigate";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import tools from "../../../utils/tools";
import { isFailureToUpdateDocumentAtom } from "./Sections/UpdateGenerateDocuments/useUpdateGenerateDocuments";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";

const isUpdatedDocumentsAtom = atom(false);
export const isFirstLoadAtom = atom(true);
export const isLoadingCandidatureAtom = atom(false);

function useResultatsCandidature() {
  const {
    candidature,
    candidatureSelectableBlocs,
    actions: candidatureActions,
    generalConditionnalDisplayVariables,
  } = useCandidature();
  const [isFailureToUpdateDocument] = useAtom(isFailureToUpdateDocumentAtom);
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const [isUpdatedDocuments, setIsUpdatedDocuments] = useAtom(
    isUpdatedDocumentsAtom
  );
  const [, setIsLoadingCandidature] = useAtom(isLoadingCandidatureAtom);
  const reactHookProps = useForm({ mode: "onBlur" });
  const { navigate } = useWorkflowNavigate();
  const { isPresidentForThisCandidature } = generalConditionnalDisplayVariables;

  const actions = {
    postRegenerateDocuments: async (candidatureUuid: any, callback: any) => {
      setIsUpdatedDocuments(true);
      try {
        await postRegenerateDocuments(candidatureUuid);
        toast.info("Génération des documents démarrée");
        if (callback) {
          callback();
        }
      } catch (err: any) {
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
      }
      setIsUpdatedDocuments(false);
    },
    cancelDecisionPresident: async () => {
      if (
        window.confirm(
          "Cette action annulera la décision du président, supprimera les documents générés et repassera la candidature dans le statut 'En attente de décision du président'.\n\nÊtes-vous sûr de vouloir continuer ?"
        )
      ) {
        setIsLoadingCandidature(true);
        try {
          const response: any =
            await candidatureActions.deleteDesicionPresident(candidature.uuid);
          toast.success("Décision du président annulée avec succès.");
          if (!isOpenedInModal) {
            navigate(`/admin/candidature/${candidature.uuid}/decision_jury`);
          } else {
            navigate({
              id_candidature: candidature.uuid,
              statut_candidature: "decision_jury",
            });
          }
        } catch (err: any) {
          const errorData = err.response?.data;
          if (errorData?.message) {
            toast.error(errorData.message);
          }
        }
        setIsLoadingCandidature(false);
      }
    },
  };

  // const isDecisionCancelable =
  //   !candidature.jury.est_cloture &&
  //   DateTime.fromISO(candidature.jury.date_jury).endOf("day").toMillis() >
  //     DateTime.now().toMillis() &&
  //   (isPresidentForThisCandidature || isAdmin);
  const isDecisionCancelable =
    !candidature?.jury?.est_cloture &&
    ((isPresidentForThisCandidature &&
      tools.isThisEndOfDateAfterStartOfToday(candidature.jury?.date_jury)) ||
      isAnyKindOfAdmin);

  return {
    candidature,
    candidatureSelectableBlocs,
    reactHookProps,
    actions,
    isUpdatedDocuments,
    isDecisionCancelable,
    isFailureToUpdateDocument,
  };
}

export default useResultatsCandidature;
