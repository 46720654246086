import { ReactElement } from "react";
import FormSection from "../../../Common/FormSection/FormSection";

type ProfileAddionalInformationsProps = {
  name: string;
  children: ReactElement;
  sectionTitle: string;
  watchRoles: Array<any>;
};

const ProfileAddionalInformations = ({
  name,
  children,
  sectionTitle,
  watchRoles,
}: ProfileAddionalInformationsProps) => {
  if (watchRoles.find((e: any) => e.name == name).watch)
    return <FormSection sectionTitle={sectionTitle}>{children}</FormSection>;
  return <></>;
};

export default ProfileAddionalInformations;
