import { joiResolver } from "@hookform/resolvers/joi";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useGroupOf from "../../hooks/groupOf/useGroupOf";
import useGroupOfQuery from "../../hooks/groupOf/useGroupOfQuery";
import { groupOfSchema } from "../../schemas/groupOfSchema";
import { useNavigate } from "react-router-dom";
import groupOfAdapters from "../../adapters/groupOfAdapters";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";

export const GroupOfContext = React.createContext({});
export const GroupOfProvider = ({ value, children }: any) => {
  return (
    <GroupOfContext.Provider value={value}>{children}</GroupOfContext.Provider>
  );
};

const resetImportGroupOf: any = {
  raison_sociale: "",
  adresse_uuid: null,
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  region: "",
  ville: "",
  ofs_hors_branche: "",
};

function useGroupOfDetail() {
  const [isChecked, setIsChecked] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const navigate = useNavigate();
  const groupOfProps = useGroupOf();
  const { groupOf, isLoading, isRefetching, refetch } =
    useGroupOfQuery(groupOfProps);

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: joiResolver(groupOfSchema),
  });

  const actions = {
    onSave: (data: any) => {
      if (!(data as any).uuid && !groupOf?.id) {
        groupOfProps.actions.postGroupOf(data);
      } else {
        groupOfProps.actions.putGroupOf(data);
      }
    },
    setUpdateIsCheck: (isVerified: boolean, value?: any) => {
      if (isVerified && value) {
        const mesBranchesId = value.branches?.map((b: any) => b.id);
        if (
          mesBranchesId.length > 0 &&
          mesBranchesId.includes(userSelectedBranch.id)
        ) {
          navigate(
            `/admin/groupe-of/${value.uuid}?branchId=${userSelectedBranch.id}`
          );
        } else {
          const groupOfAdapterValue = groupOfAdapters.transformForBO(value);
          Object.keys(resetImportGroupOf).forEach((field: any) => {
            reactHookProps.setValue(`${field}`, groupOfAdapterValue[field]);
          });
          reactHookProps.setValue("uuid", groupOfAdapterValue.uuid);
        }
      }
      setIsChecked(isVerified);
    },
    resetAutocompleteInput: () => {
      Object.keys(resetImportGroupOf).forEach((field: any) => {
        reactHookProps.setValue(`${field}`, resetImportGroupOf[field]);
      });
    },
  };

  return {
    groupOf,
    groupOfProps,
    reactHookProps,
    actions,
    isChecked,
    isLoadingGroupOf: isLoading || isRefetching,
    refetch,
  };
}

export default useGroupOfDetail;
