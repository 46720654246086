import { Label, Stack, TextField, Toggle } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import DynamicList from "../../Common/DynamicList/DynamicList";
import FileInput from "../../Common/FileInput/FileInput";
import FormSection from "../../Common/FormSection/FormSection";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import SelectManyFieldAsync from "../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import blockCompetenceAdapters from "../../adapters/blocCompetenceAdapters";
import { API_URL } from "../../config/config";
import { ICompetenceBlock } from "../../interfaces/competenceBlockInterface";
import { competenceBlockSchema } from "../../schemas/competenceBlockSchema";
import { commonInputStyles } from "../../styles/index";
import useCompetenceBlockDetail from "./useCompetenceBlockDetail";

function CompetenceBlockDetail() {
  const { uuid } = useParams();
  const {
    competenceBlock,
    competenceBlockProps,
    actions: actionsPage,
    isLoadingCompetenceBlock,
  } = useCompetenceBlockDetail();

  const {
    userSelectedBranch,
    isLoading,
    isErrorPostCompetenceBlock,
    isErrorPatchCompetenceBlock,
    errorPostCompetenceBlock,
    errorPatchCompetenceBlock,
    actions,
  } = competenceBlockProps;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
  } = useForm<ICompetenceBlock>({
    mode: "onBlur",
    defaultValues: competenceBlock,
    resolver: joiResolver(competenceBlockSchema),
  });

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateCompetenceBlockUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    reset(blockCompetenceAdapters.transformForBO(competenceBlock));
  }, [competenceBlock]);

  useEffect(() => {
    if (!watch("inter_branches")) {
      setValue("branches_rattachees", []);
    }
  }, [watch("inter_branches")]);

  useEffect(() => {
    var apiErrors = (errorPostCompetenceBlock as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostCompetenceBlock]);

  useEffect(() => {
    var apiErrors = (errorPatchCompetenceBlock as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPatchCompetenceBlock]);

  return (
    <Stack>
      <BackLink />

      <Page
        title={
          !competenceBlock?.uuid
            ? "Ajouter un bloc de compétences"
            : "Editer un bloc de compétences"
        }
        explain={
          !competenceBlock?.uuid
            ? "Veuillez personnaliser votre bloc de compétences"
            : "Veuillez personnaliser votre bloc de compétences"
        }
      >
        <DetailLoader isLoadingDetail={isLoadingCompetenceBlock}>
          <form onSubmit={handleSubmit(actionsPage.saveCompetenceBlock)}>
            <input
              type="hidden"
              {...register("_method", {
                value: !competenceBlock?.uuid ? "POST" : "PUT",
              })}
            />

            <FormSection sectionTitle="Informations principales du bloc de compétences">
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Libellé :"
                      type="text"
                      {...field}
                      placeholder="Ex : Mon bloc de compétences"
                      errorMessage={(errors.libelle as any)?.message}
                      required
                    />
                  )}
                  control={control}
                  defaultValue=""
                  name="libelle"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Description :"
                      type="text"
                      {...field}
                      placeholder="Ex : Ma description"
                      errorMessage={(errors.description as any)?.message}
                    />
                  )}
                  control={control}
                  defaultValue=""
                  name="description"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Activités professionnelles :"
                      type="text"
                      {...field}
                      placeholder="Ex : mon activité professionnelle"
                      errorMessage={
                        (errors.activites_professionnelles as any)?.message
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  name="activites_professionnelles"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Référence du bloc :"
                      type="text"
                      {...field}
                      placeholder="Ex : Ma référence"
                      errorMessage={(errors.reference as any)?.message}
                    />
                  )}
                  control={control}
                  defaultValue=""
                  name="reference"
                />
              </Stack.Item>

              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      styles={commonInputStyles.toggleInput}
                      label="Bloc actif :"
                      onText="Oui"
                      offText="Non"
                      onChange={(e, checked) => onChange(checked)}
                      checked={value}
                    />
                  )}
                  defaultValue={false}
                  control={control}
                  name="actif"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      styles={commonInputStyles.toggleInput}
                      label="Ce bloc est ouvert aux autres branches (parcours par bloc) :"
                      onText="Oui"
                      offText="Non"
                      onChange={(e, checked) => onChange(checked)}
                      checked={value}
                    />
                  )}
                  defaultValue={false}
                  control={control}
                  name="inter_branches"
                />
              </Stack.Item>
              {watch("inter_branches") && (
                <Stack.Item>
                  <Controller
                    render={({ field: { name, value, onBlur, ref } }) => (
                      <SelectManyFieldAsync
                        label="Visibilité du bloc aux branches :"
                        placeholder="Recherchez des branches par nom"
                        resourceURI={`${API_URL}api/administration/branches`}
                        addQueryURL={(resourceURI: any, terms: any) =>
                          `${resourceURI}/chercher?nom=${terms}&paginate=false`
                        }
                        method="GET"
                        renderItem={(item: any) => item.nom}
                        renderValue={(item: any) => item.nom}
                        dataIndex="id"
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        fieldRef={ref}
                        onChange={(e: any) => {
                          setValue(name, e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={[]}
                        errorMessage={
                          (errors.branches_rattachees as any)?.message
                        }
                        handleResponse={(response: any) => [
                          ...response.data.data,
                        ]}
                      />
                    )}
                    control={control}
                    name="branches_rattachees"
                  />
                </Stack.Item>
              )}
            </FormSection>

            <FormSection sectionTitle="Compétences liées à ce bloc">
              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <DynamicList
                      label="Ajoutez une ou plusieurs compétences associées :"
                      placeholder="Ex : Ma compétence associée"
                      addButtonLabel="Ajoutez cette compétence associée"
                      name={name}
                      value={value || []}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{ libelle: "" }}
                      attr="libelle"
                      errorMessage={
                        (errors.competences_associees as any)?.message
                      }
                      validateItem={(i: any) => i}
                    />
                  )}
                  control={control}
                  name="competences_associees"
                />
              </Stack.Item>
              <Stack.Item style={{ width: "100%" }}>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Sélectionnez une ou plusieurs compétences rattachées au bloc :"
                      placeholder="Recherchez une compétence en tapant les premières lettres de son nom (Ex : Ma comp)"
                      resourceURI={`${API_URL}api/administration/certifications/competences`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}/chercher?libelle=${terms}&branche_creatrice=${userSelectedBranch.id}&paginate=false`
                      }
                      method="GET"
                      renderItem={(item: any) => item.libelle}
                      renderValue={(item: any) => item.libelle}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      required={true}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{}}
                      errorMessage={(errors.competences as any)?.message}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="competences"
                />
              </Stack.Item>
            </FormSection>

            <FormSection sectionTitle="Documents liés à ce bloc">
              <Stack.Item>
                <Controller
                  control={control}
                  name="document_evaluation"
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <Label htmlFor={name}>
                        Document du processus d'évaluation :
                      </Label>
                      <FileInput
                        name={name}
                        file={value ? [value] : []}
                        onUpdateFile={(e: any) => {
                          onChange(e.target.value[0]);
                          setValue(`${name}_deleted`, false);
                        }}
                        onDeleteFile={(e: any) => {
                          setValue(`${name}_deleted`, true);
                        }}
                        existingFileURL={
                          watch(`${name}_deleted`)
                            ? ""
                            : value
                            ? ""
                            : watch(`${name}_url`)
                        }
                        acceptedFileTypes={["PDF"]}
                      />
                    </>
                  )}
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  control={control}
                  name="attestation"
                  render={({ field: { onChange, name, value } }) => (
                    <>
                      <Label htmlFor={name}>Attestation :</Label>
                      <FileInput
                        name={name}
                        file={value ? [value] : []}
                        onUpdateFile={(e: any) => {
                          onChange(e.target.value[0]);
                          setValue(`${name}_deleted`, false);
                        }}
                        onDeleteFile={(e: any) => {
                          setValue(`${name}_deleted`, true);
                        }}
                        existingFileURL={
                          watch(`${name}_deleted`)
                            ? ""
                            : value
                            ? ""
                            : watch(`${name}_url`)
                        }
                        acceptedFileTypes={["PDF"]}
                      />
                    </>
                  )}
                />
              </Stack.Item>
            </FormSection>

            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              <CancelButton />

              <LoadButton
                text={
                  !competenceBlock?.uuid
                    ? "Créer le bloc de compétences"
                    : "Modifier le bloc de compétences"
                }
                isLoading={isLoading}
              />
            </Stack>
          </form>

          {isErrorPostCompetenceBlock && (
            <PageMessage
              message={(errorPostCompetenceBlock as any).response.data.message}
              type="error"
            />
          )}

          {isErrorPatchCompetenceBlock && (
            <PageMessage
              message={(errorPatchCompetenceBlock as any).response.data.message}
              type="error"
            />
          )}
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default CompetenceBlockDetail;
