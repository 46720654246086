import { FontWeights, Stack, Text } from "@fluentui/react"
import { TiTick, TiTimes } from "react-icons/ti";

function DecisionEvaluation({ modalites_evaluation, decision }: any) {

    const typeModaliteEvaluation: 1 | 2 | 3 | 4 | 5 = modalites_evaluation?.type;

    if (decision == "NON_RENSEIGNE") {
        return (
            <Stack>
                <Stack.Item>
                    <Text variant="mediumPlus" style={{ fontWeight: FontWeights.bold }}>
                        -
                    </Text>
                </Stack.Item>
            </Stack>
        )
    }

    if (typeModaliteEvaluation == 1) {
        return (
            <Stack horizontal tokens={{ childrenGap: 7 }}>
                <Stack.Item>
                    {decision == 'VALIDE'
                        ? <TiTick color={"#0aa204"} size={25} />
                        : <TiTimes color={"#df793a"} size={25} />
                    }
                </Stack.Item>
                <Stack.Item>
                    <Text variant="mediumPlus" style={{ fontWeight: FontWeights.bold }}>
                        {decision == 'VALIDE'
                            ? "Validé"
                            : "Non validé"
                        }
                    </Text>
                </Stack.Item>
            </Stack>
        )
    } else if (typeModaliteEvaluation < 5) {
        return (
            <Stack>
                <Text variant="mediumPlus" style={{ fontWeight: FontWeights.bold }}>
                    {decision}
                </Text>
            </Stack>
        )
    } else {
        return (
            <Stack>
                <Text variant="mediumPlus" style={{ fontWeight: FontWeights.bold }}>
                    {decision} / {modalites_evaluation?.note_sur_score}
                </Text>
            </Stack>
        )
    }
}

export default DecisionEvaluation