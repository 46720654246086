import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

export const ChoixBlocs = React.createContext({});
export const ChoixBlocsProvider = ({ value, children }: any) => {
  return <ChoixBlocs.Provider value={value}>{children}</ChoixBlocs.Provider>;
};
export const useChoixBlocs = () => {
  const [isSaving, setIsSaving] = useState(false);
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const { candidature, actions: candidatureActions } = useCandidature();
  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  useEffect(() => {
    if (candidature.uuid) {
      actions.resetBlocsFromOptionChanged(null);
    }
  }, [candidature?.uuid]);

  const actions = {
    onSave: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidature(
        data,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
    resetBlocsFromOptionChanged: (newOption: any) => {
      reactHookProps.setValue(
        "blocs_competences_passes",
        candidature.certification?.blocs_competences?.filter(
          (bloc: any) =>
            bloc.option_uuid == (newOption || null) &&
            bloc.statut == 1 &&
            !candidature.candidat.blocs_competences_valides?.find(
              (blocValide: any) => blocValide.uuid == bloc.uuid
            )
        )
      );
    },
  };

  const blocsFromSelectedOption =
    candidature.certification?.blocs_competences
      ?.filter(
        (bloc: any) =>
          bloc.option_uuid ==
          (reactHookProps.watch("option_selectionnee") || null)
      )
      .map((bloc: any) => {
        const blocCompetenceValide =
          candidature.candidat.blocs_competences_valides?.find(
            (blocValide: any) => blocValide.uuid == bloc.uuid
          );

        return blocCompetenceValide
          ? candidature.blocs_competences_passes.find(
              (blocValide: any) => blocValide.uuid == bloc.uuid
            )
            ? bloc
            : {
                ...bloc,
                deja_acquis_dans_une_precedente_certification:
                  blocCompetenceValide.modifie_le < candidature.cree_le,
                date_acquisition: blocCompetenceValide.modifie_le,
              }
          : bloc;
      }) || [];

  return {
    candidature,
    reactHookProps,
    blocsFromSelectedOption,
    actions,
    isSaving,
  };
};
