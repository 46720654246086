import { Icon, Label, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../../config/axiosConfig";
import { commonInputStyles } from "../../../styles/index";
import Loader from "../../Loader/Loader";
import ErrorMessage from "../common/ErrorMessage/ErrorMessage";
import InfoMessage from "../common/InfoMessage/InfoMessage";
import SearchResults from "../common/SearchResults/SearchResults";

const eCertifApi = axiosInstance;
let searchTimeout: any = null;

const MIN_TERMS_TO_TRIGGER_SEARCH = 1; //nb de caractères min pour lancer la recherche auto

function SelectManyFieldAsync({
  label, //label global de l'input
  placeholder, //placeholder du champ de recherche
  errorMessage, //message d'erreur de l'input
  resourceURI, //url de la route à appeler pour search les datas à partir de la variable "terms"
  addQueryURL = (ressourceURI: any) => ressourceURI,
  method, //méthode pour fetch les datas de recherche (GET,POST...)
  renderItem = () => {}, //fonction à utiliser pour déclarer comment doivent s'afficher les résultats retournés par l'api ex : renderItem : item => item.name
  renderValue = null, //fonction à utiliser pour déclarer comment doit s'afficher la valeur (value) qui a été selectionnée ex : renderValue : item => item.name+' '+item.category
  getPostData = () => {}, //si des post datas sont à passer à la requête, défini l'objet à passer à l'aide des termes de recherche ex : terms => ({name:terms})
  dataIndex = "uuid", //identifiant unique qui sera toujours présent dans les résultats API et dans l'objet sélectionné dans value
  onChange = () => {}, //appelé lorsque la valeur sélectionnée change
  onBlur = () => {},
  value, //array ex : [{name:""}], valeur de l'input
  name, //nom de l'input
  fetchOnLoad = false, //récupérer les datas api dès l'affichage du composant
  fieldRef,
  required, //si requis
  handleResponse = () => {},
  fakeData = false, // fake data
  handleFakeData = () => {},
  disabled = false,
  disabledSelectedItem = () => false,
  infoMessageDisabledItem = () => "",
  unremovableItemsFn = () => false,
}: any) {
  const [isSearching, setIsSearching] = useState(false);
  const [isShadowLoading, setIsShadowLoading] = useState(false);
  const [terms, setTerms] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [infoMessage, setInfoMessage] = useState("");

  const handleChangeTerms = (e: any) => {
    setTerms(e.target.value);
  };

  useEffect(() => {
    if (fetchOnLoad) {
      searchDatas();
    }
  }, []);

  useEffect(() => {
    if (!value) {
      setTerms("");
    }
  }, [value]);

  useEffect(() => {
    setIsShadowLoading(false);
    if (terms.length > MIN_TERMS_TO_TRIGGER_SEARCH) {
      autoSearchData();
    } else {
      setSearchResults([]);
      clearTimeout(searchTimeout);
    }
  }, [terms]);

  const autoSearchData = () => {
    if (fakeData) {
      searchDatas();
    } else {
      clearTimeout(searchTimeout);
      if (!isSearching) {
        setIsShadowLoading(true);
        searchTimeout = setTimeout(() => searchDatas(), 2000);
      }
    }
  };

  const onKeyDown = (e: any) => {
    var key = e.charCode || e.keyCode || 0;
    if (key === 13) {
      e.preventDefault();
      e.stopPropagation();
      searchDatas();
    }
  };

  const searchDatas = async () => {
    setIsShadowLoading(false);
    if (fakeData) {
      setSearchResults(handleFakeData(terms));
    } else {
      setIsSearching(true);
      let postDatas = null;
      if (method === "POST") {
        postDatas = getPostData(terms);
      }
      const response = await eCertifApi({
        method,
        url: addQueryURL(resourceURI, terms),
        data: postDatas,
      });

      setIsSearching(false);
      setSearchResults(handleResponse(response));

      if (handleResponse(response).length == 0) {
        setInfoMessage("Aucun résultat trouvé");
      } else {
        setInfoMessage("");
      }
    }
  };

  const selectItem = (item: any) => {
    const newValue = hasValue() ? [...value, item] : [item];
    onChange({ target: { name, value: newValue } });
  };

  const removeItem = (item: any) => {
    onChange({
      target: {
        name,
        value: [...value.filter((v: any) => v[dataIndex] !== item[dataIndex])],
      },
    });
  };

  const hasValue = () => {
    if (typeof value === "string") {
      return value && value.length > 0;
    }
    if (typeof value === "object") {
      return Object.keys(value).length > 0;
    }
  };

  const getFilteredSearchResults = () => {
    return searchResults.filter((item: any) => {
      return hasValue()
        ? !value.find((v: any) => v[dataIndex] == item[dataIndex])
        : true;
    });
  };

  const hasValidSearchResults = () => {
    return searchResults.length > 0 && getFilteredSearchResults().length > 0;
  };

  return (
    <div>
      <Label required={required} styles={commonInputStyles.label}>
        {label}{" "}
        <span className="ms-fontColor-gray100 ms-fontWeight-regular">
          (sélection multiple)
        </span>
      </Label>

      <div
        style={{ position: "relative", background: disabled ? "#f4f4f4" : "" }}
      >
        <Icon
          className="d-center"
          iconName="Search"
          style={{
            position: "absolute",
            height: "100%",
            color: "grey",
            left: 5,
          }}
        />
        <TextField
          styles={commonInputStyles.searchField}
          placeholder={placeholder}
          onChange={handleChangeTerms}
          value={terms}
          type="text"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          componentRef={fieldRef}
          disabled={disabled}
        />
        {isSearching ||
          (isShadowLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: 5,
                transform: "translateY(-50%)",
              }}
            >
              <Loader size="small" />
            </div>
          ))}
      </div>

      {(hasValidSearchResults() || hasValue()) && (
        <SearchResults
          filteredSearchResults={getFilteredSearchResults()}
          selectedItems={value}
          renderItem={renderItem}
          selectItem={selectItem}
          removeItem={removeItem}
          disabled={disabled}
          disabledSelectedItem={disabledSelectedItem}
          infoMessageDisabledItem={infoMessageDisabledItem}
          unremovableItemsFn={unremovableItemsFn}
        />
      )}

      <InfoMessage infoMessage={infoMessage} />
      <ErrorMessage errorMessage={errorMessage} />
    </div>
  );
}

export default SelectManyFieldAsync;

/*

Exemple d'appel :

<Controller
  render={({ field: { name, value, onBlur, ref } }) => (
    <SelectManyFieldAsync
      label="Sélectionnez le(s) organisme(s) de formation lié(s)"
      placeholder="Recherchez un organisme de formation en tapant les premières lettres de son nom"
      resourceURI={branchURI}
      method="GET"
      renderItem={(item: any) => item.name}
      renderValue={(item: any) => item.name}
      dataIndex="id"
      name={name}
      value={value}
      onBlur={onBlur}
      fieldRef={ref}
      onChange={(e: any) => {
        setValue(name, e.target.value, { shouldValidate: true });
      }}
      defaultValue={[]}
      errorMessage={(errors.training_organizations as any)?.message}
    />
  )}
  control={control}
  name="training_organizations"
/>

*/
