import Joi from "joi";

export const jurySchema = Joi.object({
  libelle: Joi.string().required().max(150).messages({
    "any.required": "Veuillez renseigner un libellé",
    "string.empty": "Veuillez renseigner un libellé",
  }),
  date_jury: Joi.date().required().messages({}),
  date_limite_inscription_candidat: Joi.date().required().messages({}),
  date_acces_membres_jury: Joi.date().required().messages({}),
  nombre_places: Joi.string().required().messages({
    "any.required": "Veuillez renseigner un nombre de places",
    "string.empty": "Veuillez renseigner un nombre de places",
  }),
  lieu: Joi.string().allow("", null).messages({}),
  code_postal: Joi.string().allow("", null).messages({}),
  region: Joi.string().allow("", null).messages({}),
  ville: Joi.string().allow("", null).messages({}),
  statut: Joi.object()
    .keys({
      id: Joi.number().required(),
    })
    .unknown(true)
    .messages({
      "any.required": "Veuillez renseigner un statut pour le jury",
      "object.base": "Veuillez renseigner un statut pour le jury",
    }),
  president_jury_uuid: Joi.object()
    .keys({
      uuid: Joi.string().required(),
    })
    .unknown(true)
    .allow("", null)
    .messages({}),
  vice_president_jury_uuid: Joi.object()
    .keys({
      uuid: Joi.string().required(),
    })
    .unknown(true)
    .allow("", null)
    .messages({}),
  membres: Joi.array().required().min(1).messages({
    "any.required": "Veuillez renseigner au minimum 1 membre",
    "array.base": "Veuillez renseigner au minimum 1 membre",
    "array.min": "Veuillez renseigner au minimum 1 membre",
  }),
}).unknown(true);
