import commonPasswordStyles from "./CommonPassword.styles"
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { resetPasswordSchema } from '../../../schemas/authSchema';
import useQuery from "../../../hooks/useQuery";
import { useEffect } from 'react'
import { Stack, TextField, Text } from '@fluentui/react'
import LoadButton from "../../../Common/LoadButton/LoadButton";

function FormPassword({ onSubmit, errorAPI, isErrorAPI, isLoadingAPI }: any) {

  let query = useQuery();

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    register,
  } = useForm({
    mode: "onBlur",
    resolver: joiResolver(resetPasswordSchema),
  })

  useEffect(() => {
    var apiErrors = (errorAPI as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorAPI]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        type="hidden" {...register('email', { value: query.get('email') })}
      />

      <input
        type="hidden" {...register('token', { value: query.get('token') })}
      />
      <Stack tokens={commonPasswordStyles.stackTokens} horizontalAlign="center" style={{ marginBottom: '20px' }}>

        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Saisissez votre mot de passe"
                errorMessage={(errors.password as any)?.message}
                styles={commonPasswordStyles.inputStyle}
                type="password"
                canRevealPassword
                revealPasswordAriaLabel="Show password"
              />
            )}
            defaultValue=""
            control={control}
            name="password"
          />
        </Stack.Item>

        <Stack.Item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Confirmez votre mot de passe"
                errorMessage={(errors.password_confirmation as any)?.message}
                styles={commonPasswordStyles.inputStyle}
                type="password"
                canRevealPassword
                revealPasswordAriaLabel="Show password"
              />
            )}
            defaultValue=""
            control={control}
            name="password_confirmation"
          />
        </Stack.Item>

        {isErrorAPI &&
          <Stack.Item
            style={{width:300}}
          >
            <Text style={{ color: 'red' }} >{errorAPI.response.data.message}</Text>
          </Stack.Item>
        }

        <LoadButton
          isLoading={isLoadingAPI}
          text="Valider le nouveau mot de passe"
        />
      </Stack>
    </form>
  )
}

export default FormPassword