import React, { useContext, useEffect, useState } from "react";
import FormSection from "../../../Common/FormSection/FormSection";
import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../styles";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { SubGroupCompanyContext } from "../useSubGroupCompanyDetail";
import useAuth from "../../../hooks/useAuth";

function GeneralInformationsSection() {
  const { reactHookProps, userSelectedBranch }: any = useContext(
    SubGroupCompanyContext
  );
  const [isGroupeEntreprisePrefill, setIsGroupeEntreprisePrefill] = useState(false)
  const { auth }: any = useAuth();

  const userCanSelectGroupeEntreprise = [
    "ADMINISTRATEUR_BRANCHE",
    "ADMINISTRATEUR_DOMAINE",
  ];

  useEffect(() => {
    const profilGroupEntreprise = auth.user.profils.find(
      (p: any) => p.profil == "GROUPE_ENTREPRISE"
    );
    if (profilGroupEntreprise) {
      reactHookProps.setValue("groupeEntreprise", {
        uuid: profilGroupEntreprise.entites[0]["id"],
        raison_sociale:
          profilGroupEntreprise.entites[0]["attributes"]["raison_sociale"],
        siren: profilGroupEntreprise.entites[0]["attributes"]["siren"],
        
      });
      setIsGroupeEntreprisePrefill(true)
    }
  }, []);

  return (
    <FormSection sectionTitle="Informations principales">
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              required
              label="Nom du sous-groupe :"
              type="text"
              {...field}
              placeholder="Ex: Sous-groupe 1"
              errorMessage={
                (reactHookProps.formState.errors[field.name] as any)?.message
              }
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="nom"
        />
      </Stack.Item>

      {/* disabled en fonction du profil et associer si groupe entreprise et non admin de branche ou admin de domaine*/}
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Groupe d'entreprises lié au sous-groupe :"
              placeholder="Recherchez un groupe d'entreprises par SIREN ou par nom (ex: 123123123 ou Groupe 1)"
              resourceURI={`${API_URL}api/administration/groupe-entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    {item.raison_sociale}{" "}
                    <span className="ms-fontColor-gray110">- {item.siren}</span>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    {item.raison_sociale}{" "}
                    <span className="ms-fontColor-gray110">- {item.siren}</span>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                reactHookProps.setValue(name, e.target.value, {
                  shouldValidate: true,
                });
                reactHookProps.setValue("entreprises", [], {
                  shouldValidate: true,
                });
                setIsGroupeEntreprisePrefill(false)
              }}
              defaultValue={null}
              errorMessage={
                (reactHookProps.formState.errors[name] as any)?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              disabled={
                !(
                  auth.user.est_administrateur ||
                  userSelectedBranch.profils?.some((myProfil: any) =>
                    userCanSelectGroupeEntreprise.includes(myProfil)
                  )
                )
              }
              required
            />
          )}
          control={reactHookProps.control}
          name="groupeEntreprise"
        />
      </Stack.Item>

      {(reactHookProps.watch("groupeEntreprise") || isGroupeEntreprisePrefill) && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectManyFieldAsync
                label="Entreprises liées au sous-groupe :"
                placeholder="Recherchez des entreprises par SIRET ou par nom (ex: 12312312311111 ou Entreprise 1)"
                resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}&groupe=${
                    reactHookProps.watch("groupeEntreprise").uuid
                  }`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  reactHookProps.setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={[]}
                errorMessage={
                  (reactHookProps.formState.errors[name] as any)?.message
                }
                handleResponse={(response: any) => [...response.data.data]}
                required
              />
            )}
            control={reactHookProps.control}
            name="entreprises"
          />
        </Stack.Item>
      )}
    </FormSection>
  );
}

export default GeneralInformationsSection;
