import { Dropdown, Label, Stack, Toggle } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../styles";
import CustomImageInput from "../../../Common/CustomImageInput/CustomImageInput";
import ErrorMessage from "../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import FormSection from "../../../Common/FormSection/FormSection";
import useConstants from "../../../hooks/useConstants";
import { useContext } from "react";
import { BranchContext } from "../useBranchDetail";
import branchDetailStyles from "../BranchDetail.styles";

function GraphicInformationsSection() {
    const { constants } = useConstants();

    const { reactHookProps, branch }: any = useContext(
        BranchContext
      );

      const {formState:{errors}, control, setValue, watch } = reactHookProps

      console.log('errors',errors);


  return (
    <FormSection sectionTitle="Informations graphiques de la branche">
      <Stack.Item>
        <Controller
          render={({ field: { onChange, name, value } }) => (
            <CustomImageInput
              name={name}
              label="Logo :"
              required={true}
              onChange={(e: any) => {
                onChange((e.target as any).files[0]);
              }}
              errorMessage={(errors.logo as any)?.message}
              styleImgContainer={branchDetailStyles.logoPreviewContainer}
              isFile={value ? true : false}
              file={value || branch?.logo_url}
              alt="Aperçu du Logo de la branche"
            />
          )}
          control={control}
          name="logo"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          control={control}
          name="couleur_primaire"
          defaultValue={undefined}
          render={({ field: { name, value, onChange } }) => (
            <>
              <Dropdown
                placeholder="Selectionnez votre couleur primaire"
                label="Couleur primaire"
                required={true}
                selectedKey={value ? value.key : undefined}
                options={constants["COLORS"]}
                styles={commonInputStyles.dropdownStyle}
                onRenderOption={(option: any) => (
                  <div
                    style={{
                      backgroundColor: option.color,
                      width: "50px",
                      height: "20px",
                      padding: 0,
                    }}
                  ></div>
                )}
                onRenderTitle={(option: any) => (
                  <div
                    style={{
                      backgroundColor: option[0].color,
                      width: "50px",
                      height: "20px",
                      margin: "11px 0px",
                    }}
                  ></div>
                )}
                onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                }}
              />
              <ErrorMessage
                errorMessage={(errors.couleur_primaire as any)?.message}
              />
            </>
          )}
        />
      </Stack.Item>

      {branch?.id && (
        <Stack.Item>
          <Controller
            render={({ field: { onChange, name, value } }) => (
              <CustomImageInput
                name={name}
                label="Bandeau :"
                onChange={(e: any) => {
                  onChange((e.target as any).files[0]);
                  setValue(`${name}_deleted`, false);
                }}
                errorMessage={(errors.headband as any)?.message}
                styleImgContainer={branchDetailStyles.logoPreviewContainer}
                isFile={value ? true : false}
                file={value || branch?.headband_url}
                alt="Aperçu de la bannière de la branche"
                isEditable={true}
                isDeleted={watch(`${name}_deleted`) ? true : false}
                onClick={() => {
                    setValue(`${name}_deleted`, true);
                    setValue(`${name}`, {});
                  (document.getElementById(`${name}`) as any).value = "";
                }}
              />
            )}
            control={control}
            name="headband"
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <>
              <Label
                htmlFor="afficher_logo"
                required
                styles={commonInputStyles.label}
              >
                Afficher le logo sur les documents :
              </Label>
              <Toggle
                styles={commonInputStyles.toggleInput}
                onText="Oui"
                offText="Non"
                onChange={(e, checked) => onChange(checked)}
                checked={value}
                id="afficher_logo"
              />
            </>
          )}
          defaultValue={false}
          control={control}
          name="afficher_logo_sur_documents"
        />
      </Stack.Item>
    </FormSection>
  );
}

export default GraphicInformationsSection;
