const myStructureAdapters = {
  transformForAPI: ({ structureType, data }: any) => {
    let transformed = {};

    switch (structureType) {
      case "entreprise":
        transformed = myStructureAdapters.transformForEntrepriseAPI(data);
        break;
      case "organisme_de_formation":
        transformed = myStructureAdapters.transformForOfAPI(data);
        break;
      case "groupe_entreprise":
        transformed = myStructureAdapters.transformForGroupEntrepriseAPI(data);
        break;
      case "groupe_of":
        transformed = myStructureAdapters.transformForGroupOfAPI(data);
        break;
    }

    return transformed;
  },
  transformForEntrepriseAPI: (data: any) => ({
    nom: data.nom,
    ref_idcc: data.ref_idcc?.id ?? null,
    adresse: {
      uuid: data.adresse_uuid,
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    uuid: data.uuid,
  }),
  transformForOfAPI: (data: any) => ({
    raison_sociale: data.nom,
    adresse: {
      uuid: data.adresse_uuid,
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    uuid: data.uuid,
  }),
  transformForGroupEntrepriseAPI: (data: any) => ({
    raison_sociale: data.nom,
    ref_idccs: data.ref_idccs?.map((item: any) => item.id),
    adresse: {
      uuid: data.adresse_uuid,
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    uuid: data.uuid,
  }),
  transformForGroupOfAPI: (data: any) => ({
    raison_sociale: data.nom,
    adresse: {
      uuid: data.adresse_uuid,
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    uuid: data.uuid,
  }),
  transformForBO: (data: any) => {
    let transformed: any = {
      ...data,
      nom: data.nom ?? data.raison_sociale,
      adresse_uuid: data.adresse.uuid,
      adresse: data.adresse.adresse,
      complement_adresse: data.adresse.complement_adresse,
      code_postal: data.adresse.code_postal,
      region: data.adresse.region,
      ville: data.adresse.ville,
    };

    return transformed;
  },
};

export default myStructureAdapters;
