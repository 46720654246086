import { useQuery } from "react-query";
import { getGroupOfRequest } from "../../services/groupOfServices";

function useGroupOfQuery(groupOfsProps: any) {
  const {
    data: groupOf,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ["groupOfs", groupOfsProps.groupOfUuid],
    () => getGroupOfRequest(groupOfsProps.groupOfUuid),
    {
      enabled: !!groupOfsProps.groupOfUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  return {
    groupOf,
    isLoading,
    isRefetching,
    refetch,
  };
}

export default useGroupOfQuery;
