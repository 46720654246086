import { DefaultButton, Stack } from '@fluentui/react';
import { commonButtonStyles } from '../../styles';
import useTheme from '../useTheme';

const ButtonNumberInfo = ({ text, count, onClick }: any) => {
    const { primaryColor } = useTheme();
    
    return (
      <DefaultButton
        onClick={onClick ? onClick : () => console.log()}
        styles={commonButtonStyles.defaultButton}
        style={{ color: primaryColor, borderColor: primaryColor }}
        className="buttonHeight"
      >
        <Stack wrap verticalAlign="center" horizontalAlign="center">
          {text}
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: "100%",
              backgroundColor: primaryColor,
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 5,
            }}
          >
            <span>{count}</span>
          </div>
        </Stack>
      </DefaultButton>
    );
  };
  
  export default ButtonNumberInfo;