import { ChoiceGroup, Stack, TextField } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import { scoreGranularite } from "../../../fakeData/Certifications.data";
import { commonInputStyles } from "../../../styles";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";

const ScoreNotationForm = () => {
  const { reactHookProps, errors }: any = useContext(CertificationContext);

  const { setValue, control } = reactHookProps;

  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Score noté sur :"
              type="text"
              {...field}
              placeholder={`Ex : 20`}
              errorMessage={(errors[field.name] as any)?.message}
              required
            />
          )}
          control={control}
          name="note_sur_score"
        />
      </Stack.Item>
      <Stack.Item>
        <Controller
          render={({ field: { value, name } }) => (
            <ChoiceGroup
              styles={commonInputStyles.textField}
              label="Granularité :"
              selectedKey={value ? (value as any).key : undefined}
              options={scoreGranularite}
              onChange={(e: any, options: any) => {
                setValue(name, options, { shouldValidate: true });
              }}
              required
            />
          )}
          control={control}
          name="granularite_score"
        />
      </Stack.Item>
    </>
  );
};

export default ScoreNotationForm;
