import { BsCheck2All } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import Badge from "../../../../../Common/Badge/Badge";
import useTheme from "../../../../../Common/useTheme";

const CounterItem = ({
    selected,
    total,
    complete,
    dejaAcquis,
    text,
    valid,
    invalid,
    showValidation,
    showLiveValidation,
    areAllBlocsValidated,
    doesAllBlocsHaveDecision,
    nbBlocsWithDecision,
    nbBlocsValidated,
}: any) => {
    const { warningColor, successColor } = useTheme();
    const color = complete ? successColor : warningColor;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                fontSize: 18,
            }}
        >
            <div style={{ paddingRight: 10, lineHeight: "0px" }}>
                {complete ? (
                    <BsCheck2All style={{ color }} size={20} />
                ) : (
                    <IoMdClose style={{ color }} size={20} />
                )}
            </div>
            <strong>
                Blocs au statut « {text} » :
                <span style={{ color, paddingLeft: 8 }}>
                    {selected}/{total} sélectionnés
                </span>
                {dejaAcquis > 0 && (
                    <span
                        className="text-info"
                        style={{ paddingLeft: 8, fontWeight: "400", fontStyle: "italic" }}
                    >
                        dont {dejaAcquis} déjà acquis
                    </span>
                )}
                {showValidation && (
                    <span style={{ padding: "0px 10px" }}>
                        {valid > 0 && (
                            <Badge
                                color={successColor}
                                text={`${valid} validé(s)`}
                                style={{ marginRight: 5, padding: "2px 8px" }}
                                textStyle={{ fontSize: 12 }}
                            />
                        )}
                        {invalid > 0 && (
                            <Badge
                                color={warningColor}
                                text={`${invalid} non validé(s)`}
                                style={{ marginRight: 5, padding: "2px 8px" }}
                                textStyle={{ fontSize: 12 }}
                            />
                        )}
                    </span>
                )}
                {showLiveValidation && doesAllBlocsHaveDecision && (selected - dejaAcquis) > 0 && (
                    <span style={{ padding: "0px 10px" }}>
                        {nbBlocsValidated}/{selected - dejaAcquis} blocs validés
                    </span>
                )}
            </strong>
        </div>
    );
};

export default CounterItem;