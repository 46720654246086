import { isOfflineAtom } from "../../hooks/useNav";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { CHANGE_IS_OFFLINE } from "../../config/offlineConfig";
import { axiosInstance } from "../../config/axiosConfig";

function useOfflineCandidatureSyncList() {
  const [isOffline, setIsOffline] = useAtom(isOfflineAtom);
  const navigate = useNavigate();

  const actions = {
    onNavigateToInlineCandidatureToDownload: () => {
      if (
        isOffline &&
        !window.confirm(
          "Pour télécharger d'autres candidatures, assurez-vous d'avoir à nouveau une connexion internet. Voulez-vous passer en mode en ligne ?"
        )
      ) {
        return false;
      }
      setIsOffline(false);
      CHANGE_IS_OFFLINE(false);

      setTimeout(() => {
        navigate("/admin/offline/candidature");
      }, 1000);
    },
  };

  return {
    actions,
  };
}

export default useOfflineCandidatureSyncList;
