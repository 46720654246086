import { IStackStyles, IComboBoxStyles, IIconStyles } from "@fluentui/react";

interface IHeaderStyles {
  comboBoxStyles: Partial<IComboBoxStyles>;
  iconButtonStyles: IIconStyles;
  outerStyle: IStackStyles;
  outerPublicStyle: IStackStyles;
  dropdownNavHeader: any;
}

const headerStyles: IHeaderStyles = {
  comboBoxStyles: {
    root: {
      maxWidth: 300,
      marginLeft: "1rem",
      marginRight: "1rem",
    },
  },
  iconButtonStyles: {
    root: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
  },
  outerStyle: {
    root: {
      position: "relative",
      padding: "0.5rem 0.5rem",
      minHeight: "5vh",
      boxSizing: "border-box",
    },
  },
  outerPublicStyle: {
    root: {
      height: "5vh",
      fontSize: "20px",
      backgroundColor: "#F2F2F2",
    },
  },
  dropdownNavHeader: {
    root: {
      selectors: {
        ".ms-Callout": {
          right: "0px !important",
          left: "0px !important",
          top: "0px !important",
        },
      },
    },
    label: {
      fontSize: "14px",
      fontWeight: "500",
    },
    title: {
      borderRadius: 5,
      border: "0px",
      backgroundColor: "transparent",
      //color: "white",
      fontSize: "16px",
      fontWeight: "500",
    },
    caretDown: {
      //color: "white",
    },
  },
};

export default headerStyles;
