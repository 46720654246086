import { IconButton, Stack } from "@fluentui/react";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import {
  candidaturesNeedActionListAtom,
  candidaturesTreatedListAtom,
} from "../../Pages/CandidatJuryList/useCandidatureJuryList";
import {
  TREATMENT_STATUS,
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
} from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import useAuth from "../../hooks/useAuth";
import SyncItem from "../SynchronizationWidget/SyncItem/SyncItem";
import CandidatureNeedActionList from "./CandidatureNeedActionList/CandidatureNeedActionList";

export const isCandidatureWidgetVisibleAtom = atom(false);
export const isOpenCandidatureWidgetAtom = atom(false);

function CandidatureNeedActionWidget() {
  const [isCandidatureWidgetVisible] = useAtom(isCandidatureWidgetVisibleAtom);
  const { auth } = useAuth();
  const [isOpenWidget, setIsOpenWidget] = useAtom(isOpenCandidatureWidgetAtom);
  const [candidaturesNeedActionList] = useAtom(candidaturesNeedActionListAtom);
  const [candidaturesTreatedList] = useAtom(candidaturesTreatedListAtom);

  const location = useLocation();
  const navigate = useNavigate();

  const matchJuryCandidatPathname = matchPath(
    { path: "/admin/jurys/:uuid_jury/candidats" },
    location.pathname
  );
  const matchCandidaturePathname = matchPath(
    {
      path: "/admin/candidature/:id_candidature/:statut_candidature/:isListCandidatures",
    },
    location.pathname
  );

  useEffect(() => {
    if (matchJuryCandidatPathname) {
      setIsOpenWidget(false);
    }
  }, [location]);

  if (
    !auth.isAuthenticated ||
    !isCandidatureWidgetVisible ||
    candidaturesNeedActionList.length == 0 ||
    (!matchJuryCandidatPathname && !matchCandidaturePathname)
  )
    return null;

  const onOpen = (e: any) => {
    setIsOpenWidget(true);
  };
  const onClose = (e: any) => {
    e.stopPropagation();
    setIsOpenWidget(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        padding: "5px 10px",
        background: "#fafafa",
        zIndex: "99",
        fontSize: 12,
        borderRadius: 10,
        border: "1px solid #cfcfcf",
        cursor: isOpenWidget ? "initial" : "pointer",
        width: isOpenWidget ? "min(calc(100vw - 80px), 400px)" : "auto",
        height: isOpenWidget ? "calc(100vh - 80px)" : "auto",
        boxShadow: "grey 0px 0px 12px -8px",
      }}
      onClick={onOpen}
    >
      <div style={{ marginBottom: 3, fontWeight: "500" }}>
        <Stack
          horizontal
          verticalAlign="center"
          onClick={() =>
            navigate(
              `/admin/jurys/${candidaturesNeedActionList[0].jury.uuid}/candidats`
            )
          }
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <BsArrowLeft style={{ marginRight: 3 }} />
          Candidature du jury {candidaturesNeedActionList[0].jury.libelle}
        </Stack>
      </div>
      <div
        style={{
          margin: 0,
          display: "flex",
          flexDirection: isOpenWidget ? "column" : "row",
        }}
      >
        <SyncItem
          text="Traitées"
          qty={`${candidaturesTreatedList.length}/${
            [...candidaturesTreatedList, ...candidaturesNeedActionList].length
          }`}
          color={TREATMENT_STATUS_COLORS[TREATMENT_STATUS.TREATED]}
          icon={TREATMENT_STATUS_ICONS[TREATMENT_STATUS.TREATED]}
          showText
        />

        {isOpenWidget && (
          <IconButton
            style={{
              position: "absolute",
              top: 5,
              right: 5,
            }}
            iconProps={{ iconName: "Cancel" }}
            onClick={onClose}
          />
        )}
        {isOpenWidget && (
          <CandidatureNeedActionList
            items={[...candidaturesTreatedList, ...candidaturesNeedActionList]}
          />
        )}
      </div>
    </div>
  );
}

export default CandidatureNeedActionWidget;
