import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import useCompetenceList from "./useCompetenceList";
import CompetenceListFilters from "./CompetenceListFilters/CompetenceListFilters";
import CreateLink from "../../Common/CreateLink/CreateLink";
import tools from "../../utils/tools";

import { commonButtonStyles } from "../../styles/index";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import LoadLink from "../../Common/LoadLink/LoadLink";

function CompetenceList() {
  const { competences, competenceProps,optionsFilters , actions:actionsPage } = useCompetenceList();

  const {
    userSelectedBranch,
    actions, 
    isToggleActivationUuid,
  } =competenceProps

  const items = competences?.data || [];

  const columns = [
    {
      key: "1",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("libelle");
      },
      onRender: (item: any) => <Text>{item.libelle}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "libelle"),
    },
    {
      key: "2",
      name: "Compétence clé",
      fieldName: "competence_cle",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("competence_cle");
      },
      onRender: (item: any) => <BooleanValue value={item["competence_cle"]} />,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "competence_cle"),
    },
    {
      key: "3",
      name: "Blocs rattachés",
      fieldName: "blocs_rattaches",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
      isCollapsible: true,
      onRender: (item: any) =>
        item["blocs_rattaches"].map((bloc: any, i: number) => <Stack key={i}><Text>{bloc.libelle}</Text></Stack>),
    },
    {
      key: "4",
      name: "Active",
      fieldName: "active",
      minWidth: 50,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item["active"]} />,
      onColumnClick: () => {
        actionsPage.sortColumn('active')
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'active')
    },
    {
      key: "5",
      name: "Branche créatrice",
      fieldName: "branche_creatrice",
      minWidth: 200,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Text>{item.branche_creatrice.nom}</Text>,
    },
    {
      key: "6",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        if (userSelectedBranch.id == item.branche_creatrice.id) {
          return (
            <Stack horizontal wrap>
              <Link
                to={`/admin/competences/${item.uuid}`}
                as={RouterLink}
                style={{ marginLeft: 5, marginTop: 5 }}
                styles={commonButtonStyles.buttonLink}
              >
                Editer
              </Link>
              <Link
                onClick={() => actionsPage.openDeleteCompetenceModal(item)}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Supprimer
              </Link>
              
              <LoadLink
              styles={item.active ? commonButtonStyles.disableButtonLink : commonButtonStyles.activeButtonLink}
              onClick={() => actions.toggleCompetenceActivation(item)}
              isLoading={isToggleActivationUuid==item.uuid}
              text={item.active ? "Désactiver" : "Activer"}
            />
            </Stack>
          );
        }else{
          return null
        }
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des compétences de vos référentiels"
        explain="Veuillez gérer les compétences de vos référentiels"
      >
        <Stack style={{ marginTop: 25 }}>
          <CreateLink text="Créer une compétence" to="/admin/competences/creer" />
        </Stack>

        <CompetenceListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={competences?.meta.current_page || 1}
          totalPages={Math.ceil(competences?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CompetenceList;
