import { useState } from "react";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";
import AddEvaluateurModal from "./AddEvaluateurModal/AddEvaluateurModal";
import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";
import { postInviteEvaluateur } from "../../services/candidatureService";

export const isSuccessToInviteEvaluateurAtom = atom(false);

function useInviterEvaluateurButton({ refreshCandidature }: any) {
  const [isSavingEvaluateur, setIsSavingEvaluateur] = useState(false);
  const [, setIsSuccessToInviteEvaluateur] = useAtom(
    isSuccessToInviteEvaluateurAtom
  );
  const { actions: modalActions } = useCustomModal();

  const actions = {
    openAddEvaluateurModal: (candidature: any) => {
      modalActions.openModal({
        header: "Inviter un évaluateur",
        body: (
          <AddEvaluateurModal
            candidature={candidature}
            onSave={actions.onInviteEvaluateur}
            isSaving={isSavingEvaluateur}
          />
        ),
      });
    },
    onInviteEvaluateur: async (
      evaluateurData: any,
      candidatureUuid: string
    ) => {
      setIsSavingEvaluateur(true);
      const response = await postInviteEvaluateur(
        candidatureUuid,
        evaluateurData
      );
      if (!response.error) {
        toast.success("Évaluateur invité avec succès.");
        setIsSuccessToInviteEvaluateur(true);
        refreshCandidature();
      } else {
        toast.error(response.error?.message);
      }
      setIsSuccessToInviteEvaluateur(false);
      setIsSavingEvaluateur(false);
    },
  };
  return { actions };
}

export default useInviterEvaluateurButton;
