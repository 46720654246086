import { scoreGranularite } from "../fakeData/Certifications.data";
import tools from "../utils/tools";
import { v4 as uuidv4 } from "uuid";

const certificationAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      ...data,
      blocs_competences: data.blocs_competences.map((b: any) => ({
        uuid: b.uuid,
        option: b.option,
        statut: b.statut,
        est_nouveau_bloc: b.est_nouveau_bloc,
      })),
      //nb_blocs_au_choix_a_valider:parseInt(data.nb_blocs_au_choix_a_valider),
      type: data.type?.id,
      certification_delivree_uuid: data.certification_delivree?.uuid,
      enregistrement: data.enregistrement?.id,
      modalites_evaluation: {
        type: data.modalites_evaluation?.id,
      },
      document_parchemin_attestation: data.document_parchemin_attestation.map(
        (item: any) =>
          item?.file ? { ...item, file: item.file[0] } : { ...item }
      ),
    };

    if (data.modalites_evaluation?.isLetter) {
      transformed.modalites_evaluation.notations =
        data["modalites_evaluation_notation"];
    }
    if (data.modalites_evaluation?.isScore) {
      transformed.modalites_evaluation.note_sur_score = data.note_sur_score;
      transformed.modalites_evaluation.granularite_score = parseFloat(
        data.granularite_score.text
      );
    }
    if (data.reprise_referentiel_existant) {
      transformed.reprise_referentiel_uuid = data.reprise_referentiel_uuid.uuid;
    }

    if (data.nom_porteur) {
      transformed.porteur = {
        uuid: data.porteur_uuid ?? null,
        nom: data.nom_porteur,
        numero_fiche_client: data.numero_fiche_client,
        numero_contrat: data.numero_contrat,
      };
    }

    return transformed;
  },
  transformOnDomaineForAPI: (data: any) => {
    let transformed = {
      certification_uuid: data.certification_template.key,
      domaine_uuid: data.domaine.key,
    };

    return transformed;
  },
  transformForBO: (data: any, certificationConstants: any) => {
    if (!data) return data;
    let transformed: any = {
      ...data,
      type: tools.findIn(certificationConstants["TYPES"], data.type, "id"),
      enregistrement: tools.findIn(
        certificationConstants["ENREGISTREMENT"],
        data.enregistrement,
        "id"
      ),
      modalites_evaluation: tools.findIn(
        certificationConstants["MODALITE_EVALUATION_TYPES"],
        data.modalites_evaluation.type,
        "id"
      ),
      certification_delivree: data.certification_delivree,
      modalites_evaluation_notation: data.modalites_evaluation.notations,
      note_sur_score: data.modalites_evaluation.note_sur_score,
      granularite_score: tools.findIn(
        scoreGranularite,
        data.modalites_evaluation.granularite_score,
        "text"
      ),
      reprise_referentiel_existant: data.reprise_referentiel_uuid
        ? true
        : false,
      porteur: null,
      porteur_uuid: data.porteur?.uuid,
      nom_porteur: data.porteur?.nom,
      numero_fiche_client: data.porteur?.numero_fiche_client,
      numero_contrat: data.porteur?.numero_contrat,
    };

    const allOptions = data.options;

    if (transformed.reprise_referentiel_existant) {
      transformed.reprise_referentiel_uuid = {
        uuid: data.reprise_referentiel.uuid,
        libelle: data.reprise_referentiel.libelle,
      };
    }

    if (data.document_parchemin_attestation) {
      transformed.document_parchemin_attestation =
        data.document_parchemin_attestation.map((item: any) => {
          return {
            ...item,
            option: allOptions
              ? (tools.findIn(allOptions, item.option_uuid, "uuid") as any)
                  .option
              : null,
          };
        });
    }

    if (data.documents) {
      transformed.documents = data.documents.map((item: any) => ({
        ...item,
        profils_autorises_en_lecture: item.profils_autorises_en_lecture.map(
          (profil: any) =>
            tools.findIn(
              certificationConstants["PROFIL_TYPES"],
              profil.key,
              "key"
            )
        ),
        types_candidature_autorises_en_ajout:
          item.types_candidature_autorises_en_ajout.map((type: any) =>
            tools.findIn(
              certificationConstants["CANDIDATURE_TYPES"],
              type.key,
              "key"
            )
          ),
        option: allOptions
          ? (tools.findIn(allOptions, item.option_uuid, "uuid") as any).option
          : null,
        boUuid: uuidv4(),
      }));
    }

    return transformed;
  },
};

export default certificationAdapters;
