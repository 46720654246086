import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import TextResult from "../../../Common/TextResult/TextResult";
import { API_URL } from "../../../config/config";
import useConstants from "../../../hooks/useConstants";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import useSubGroupOfList from "../useSubGroupOfList";

function SubGroupOfListFilters() {
  const { subGroupOfs, actions, isLoadingSubGroupOfs, reactHookProps, subGroupOfProps } = useSubGroupOfList();
  const { userSelectedBranch } = subGroupOfProps
  const totalSubGroupOfs = subGroupOfs?.meta.total || 0;
  const { branches } = useConstants();
  const branchesList = tools.addKeyToObjectInArray(
    [{ id: 0, nom: "Toutes les branches", value: undefined }, ...branches],
    "id"
  );

  const { handleSubmit, control, setValue } = reactHookProps

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontal
          horizontalAlign="start"
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Nom du sous-groupe :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="utilisateur"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Nom utilisateur :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              control={control}
              name="branche_liee"
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Branche liée au sous-groupe:"
                  selectedKey={value ? value.key : undefined}
                  placeholder="Sélectionnez une branche"
                  options={branchesList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) =>
                    setValue(name, options, { shouldValidate: true })
                  }
                  onRenderOption={(option: any) => option.nom}
                  onRenderTitle={(option: any) => option[0].nom}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item className="ms-Grid-col ms-sm12" style={{ paddingRight: 20, marginTop: 20 }}>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectManyFieldAsync
                  label="Certifications liées"
                  placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                  resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?libelle=${terms}&branche_creatrice_id=${userSelectedBranch.id}`}
                  method="GET"
                  renderItem={(item: any) => item.libelle}
                  renderValue={(item: any) => item.libelle}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={{}}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="certifications_liees"
            />
          </Stack.Item>
        </Stack>

        <Stack horizontal horizontalAlign="space-between" verticalAlign="end" wrap>
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingSubGroupOfs} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalSubGroupOfs} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default SubGroupOfListFilters;
