import { useState } from "react";
import useMyStructureMutations from "./useMyStructureMutations";
import myStructureAdapters from "../../adapters/myStructureAdapters";

function useMyStructure() {
  const [structureUuid, setStructureUuid] = useState(null);

  const { mutations } = useMyStructureMutations();

  const actions = {
    setUpdateStructureUuid: (data: any) => {
      setStructureUuid(data);
    },
    putMyStructure: async ({ structureType, structure }: any) => {
      mutations.PutMyStructure.mutate({
        structureType,
        structure: myStructureAdapters.transformForAPI({
          structureType,
          data: structure,
        }),
      });
    },
  };

  const {
    isLoading: isLoadingPatchMyStructure,
    isError: isErrorPatchMyStructure,
    error: errorPatchMyStructure,
  } = mutations.PutMyStructure;

  return {
    actions,
    structureUuid,
    isLoadingPatchMyStructure,
    isErrorPatchMyStructure,
    errorPatchMyStructure,
  };
}

export default useMyStructure;
