import { useAtom } from "jotai";
import juryAdapters from "../../adapters/juryAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import useJuryMutations from "./useJuryMutations";

function useJury() {
  const { juryUuid, setJuryUuid, mutations } = useJuryMutations();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const actions = {
    setUpdateJuryUuid: (data: any) => {
      setJuryUuid(data);
    },
    postJury: async (jury: any) => {
      const formData = new FormData();
      tools.buildFormData(formData, juryAdapters.transformForAPI(jury), null);
      mutations.PostJury.mutate(formData);
    },
    putJury: async (jury: any) => {
      const formData = new FormData();
      tools.buildFormData(formData, juryAdapters.transformForAPI(jury), null);
      mutations.PutJury.mutate(formData);
    },
    putJuryAddCandidats: async (jury: any, candidats: any) => {
      const data: any = {
        juryUuid: jury.uuid,
        candidats,
      };
      await mutations.PutJuryAddCandidats.mutateAsync(data);
    },
    putCloseJury: async () => {
      await mutations.PutCloseJury.mutateAsync(juryUuid);
    },
    postRegenerateDocuments: async () => {
      await mutations.PostRegenerateDocuments.mutateAsync(juryUuid);
    },
    deleteJury: async (juryUuid: string) => {
      await mutations.DeleteJury.mutateAsync(juryUuid);
    },
    toggleJuryActivation: (jury: any) => {},
  };
  const {
    isLoading: isLoadingPostJury,
    isError: isErrorPostJury,
    error: errorPostJury,
  } = mutations.PostJury;

  const {
    isLoading: isLoadingPutJury,
    isError: isErrorPutJury,
    error: errorPutJury,
  } = mutations.PutJury;

  const {
    isSuccess: isSuccessJuryAddCandidats,
    isLoading: isLoadingPutJuryAddCandidats,
  } = mutations.PutJuryAddCandidats;

  return {
    juryUuid,
    actions,
    isLoading: isLoadingPostJury || isLoadingPutJury,
    hasError: isErrorPostJury || isErrorPutJury,
    isLoadingPutJuryAddCandidats,
    errorPostJury,
    errorPutJury,
    userSelectedBranch,
    isSuccessJuryAddCandidats,
  };
}

export default useJury;
