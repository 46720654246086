import Joi from "joi";

export const competenceSchema = Joi.object({
  libelle: Joi.string().required().max(1000).messages({
    "any.required": "Veuillez renseigner un libellé",
    "string.empty": "Veuillez renseigner un libellé",
    "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
  }),
  description: Joi.string().allow(null, ""),
  active: Joi.boolean().required().default(false),
  competence_cle: Joi.boolean().required().default(false),
  criteres: Joi.array()
    .items(
      Joi.object().keys({
        libelle: Joi.string().required(),
        actif: Joi.boolean().default(true),
      })
    )
    .allow(null),
}).unknown(true);
