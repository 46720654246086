import { ChoiceGroup, Stack, TextField } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import useConstants from "../../../hooks/useConstants";
import { commonInputStyles } from "../../../styles";

const MemberDecisionBlock = ({ onChangeDecision, decision, commentaire, disabled }: any) => {
  const {constants} = useConstants()
  return (
    <FormSection sectionTitle="Avis sur la candidature">
      <Stack.Item>
        <ChoiceGroup
          label="Avis sur la candidature :"
          selectedKey={decision.avis}
          options={constants['CANDIDATURES']['AVIS_JURY_TYPES']}
          required={true}
          name="avis"
          onChange={(e: any, option: any) =>
            onChangeDecision({
              target: { name: e.target.name, value: option.key },
            })
          }
          disabled={disabled}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          label="Si nécessaire, commentez votre avis :"
          name="commentaire"
          value={decision.commentaire}
          onChange={onChangeDecision}
          styles={commonInputStyles.textField}
          rows={3}
          multiline
          disabled={disabled}
        />
      </Stack.Item>
    </FormSection>
  );
};

export default MemberDecisionBlock;
