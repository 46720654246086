import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const contactURI = "contact/demande_aide";
export const contactAdminInterbrancheURI = 'interbranches/demande_aide'
export interface PostContactAdminBodyType {
  motif: number;
  corps: string;
  motif_libelle?: string;
  files: FileList | null;
}

export interface PostContactAdminInterbranchesBodyType {
  motif: number;
  corps: string;
  motif_libelle?: string;
  nom: string;
  prenom: string;
  numero: string;
  email: string;
  branche: string,
}

export const postContactRequest = async (form: PostContactAdminBodyType) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${contactURI}`,
    form,
    xsrfHeader // this for logged in users
  );
  return response.data;
};

export const postContactRequestInterbranches = async (
  form: PostContactAdminInterbranchesBodyType
) => {
  const response = await eCertifApi.post(`${contactAdminInterbrancheURI}`, form);
  return response.data;
};
