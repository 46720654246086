import { Link as RouterLink, useLocation } from "react-router-dom";
import tools from "../../../utils/tools";
import {
  SYNCHRO_STATUS_COLORS,
  SYNCHRO_STATUS_ICONS,
  SYNCHRO_STATUS_NAMES,
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
  TREATMENT_STATUS_NAMES,
} from "../../../hooks/offlineCandidature/useOfflineCandidaturePile";
import { FontIcon, Link } from "@fluentui/react";
import { commonButtonStyles } from "../../../styles";

const SyncList = ({ items }: any) => {
  const location = useLocation();
  return (
    <ol
      style={{
        paddingLeft: "20px",
        paddingRight: "5px",
        height: "calc(100% - 130px)",
        overflowY: "auto",
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      {items.map((item: any) => {
        const isActive = tools.isLocationSimilarUrl(
          item.url_client,
          location.pathname
        );
        return (
          <li
            key={`scitem${item.candidature_uuid}`}
            style={{
              background: isActive ? "white" : "transparent",
              padding: "0 4px",
              borderRadius: 3,
              border: isActive ? "1px solid #d9d9d9" : "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "3px 0",
              }}
            >
              <div
                style={{
                  textAlign: "left",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Link
                  to={item.url_client}
                  as={RouterLink}
                  styles={commonButtonStyles.buttonLink}
                  style={{
                    display: "block",
                    color: item.error ? "red" : "",
                  }}
                  title={item.name}
                >
                  {tools.capitalize(item.name)}
                </Link>
              </div>
              <div
                style={{
                  width: 50,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: 5,
                }}
              >
                <FontIcon
                  iconName={TREATMENT_STATUS_ICONS[item.treatment_status]}
                  title={TREATMENT_STATUS_NAMES[item.treatment_status]}
                  style={{
                    color: TREATMENT_STATUS_COLORS[item.treatment_status],
                  }}
                />
                <FontIcon
                  iconName={SYNCHRO_STATUS_ICONS[item.synchro_status]}
                  title={SYNCHRO_STATUS_NAMES[item.synchro_status]}
                  style={{ color: SYNCHRO_STATUS_COLORS[item.synchro_status] }}
                />
              </div>
            </div>
          </li>
        );
      })}
    </ol>
  );
};

export default SyncList;
