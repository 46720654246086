import {
  ChoiceGroup,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import JuryListFilters from "./JuryListFilters/JuryListFilters";
import useJuryList from "./useJuryList";
import { commonButtonStyles } from "../../styles";
import LoadLink from "../LoadLink/LoadLink";
import tools from "../../utils/tools";

function JuryList({
  jurys,
  updateFilters,
  onSelectJury,
  jurySelected,
  inputDisabled,
}: any) {
  const { actions } = useJuryList();
  const items: any = jurys || [];

  const columns = [
    {
      key: "1",
      name: "Date",
      fieldName: "date_jury",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{tools.reformateDateFromJsDate(item.date_jury)}</Stack>
      ),
    },
    {
      key: "2",
      name: "Libellé",
      fieldName: "libelle",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Stack>{item?.libelle}</Stack>,
    },
    {
      key: "3",
      name: "Date limite d'inscription",
      fieldName: "date_limite_inscription_candidat",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>
          {tools.reformateDateFromJsDate(item.date_limite_inscription_candidat)}
        </Stack>
      ),
    },
    {
      key: "4",
      name: "Places restantes",
      fieldName: "nombre_de_places_restantes",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Stack>{item?.nombre_de_places_restantes}</Stack>
      ),
    },
    {
      key: "5",
      name: onSelectJury ? "Sélectionner" : "Actions",
      fieldName: onSelectJury ? "selectionner" : "actions",
      minWidth: 100,
      isResizable: true,
      onRender: (item: any) => (
        <>
          {onSelectJury ? (
            <Stack>
              <ChoiceGroup
                disabled={inputDisabled}
                options={[{ key: `${item.uuid}`, text: "Sélectionner" }]}
                selectedKey={
                  jurySelected?.uuid == item.uuid ? jurySelected.uuid : null
                }
                onChange={() => onSelectJury(item)}
              />
            </Stack>
          ) : (
            <Stack horizontal wrap>
              <Link
                //to={`/admin/utilisateurs/${item.uuid}`}
                //as={RouterLink}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Editer
              </Link>

              <LoadLink
                styles={commonButtonStyles.buttonLink}
                onClick={() => actions.onVisualize(item)}
                text="Visualiser"
              />

              {/* <LoadLink
                            styles={commonButtonStyles.buttonLink}
                            onClick={() => actions.onInscrireCandidat(item)}
                            text="Inscrire des candidats"
                        /> */}
            </Stack>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <JuryListFilters isSelect={onSelectJury} updateFilters={updateFilters} />

      <DetailsList
        styles={{ root: { marginTop: 20 } }}
        layoutMode={DetailsListLayoutMode.justified}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
      />
    </>
  );
}

export default JuryList;
