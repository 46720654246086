import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { stateList } from "../../fakeData/State.data";
import useUser from "../../hooks/user/useUser";
import {
  getExportUsersExceptCandidats,
  getUsersRequest,
} from "../../services/userServices";
import tools from "../../utils/tools";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  role: { id: undefined, text: "Tous les rôles", key: "all" },
  nom: "",
  prenom: "",
  email: "",
  actif: stateList[0],
  est_multibranches: stateList[0],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: 1,
  searchAt: "",
});
function useUserList() {
  const userProps = useUser();
  const [
    isLoadingExportUsersExceptCandidat,
    setIsLoadingExportUsersExceptCandidat,
  ] = useState(false);
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: usersProfils, isLoading: isLoadingUsers } = useQuery(
    ["usersProfil", optionsFilters, userProps.userSelectedBranch],
    () => getUsersRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onExportUsersExceptCandidat: async () => {
      setIsLoadingExportUsersExceptCandidat(true);
      try {
        const firstResponse: any = await getExportUsersExceptCandidats(
          filters
        ).catch((e: any) => {
          console.log("ERR", e);
          toast.error(
            "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
          );
        });
        if (!firstResponse) return;

        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });

        setIsLoadingExportUsersExceptCandidat(false);
      } catch (e: any) {
        setIsLoadingExportUsersExceptCandidat(false);
      }
    },
  };

  return {
    reactHookProps,
    usersProfils,
    userProps,
    isLoadingUsers,
    optionsFilters,
    actions,
    isLoadingExportUsersExceptCandidat,
  };
}

export default useUserList;
