import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import CandidatureWorkflow, {
  isOpenedInModalAtom,
} from "./CandidatureWorkflow";
import useWorkflowModal from "../../Common/Overlays/CustomModal/useWorkflowModal";

const useWorkflowNavigate = () => {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const navigate = useNavigate();
  const { actions } = useWorkflowModal();

  const navigateInModal: any = (params: any) => {
    setTimeout(() => {
      actions.reOpenModal({
        body: <CandidatureWorkflow {...params} />,
      });
    }, 400);
  };

  return {
    navigate: isOpenedInModal ? navigateInModal : navigate,
  };
};

export default useWorkflowNavigate;
