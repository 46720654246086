import { Stack, TextField, Toggle } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import FormSection from "../../Common/FormSection/FormSection";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import { ICompetence } from "../../interfaces/competenceInterface";
import { competenceSchema } from "../../schemas/competenceSchema";
import { commonInputStyles } from "../../styles/index";
import useCompetenceDetail from "./useCompetenceDetail";
import CritereDynamicList from "./CritereDynamicList/CritereDynamicList";

function CompetenceDetail() {
  const { uuid } = useParams();
  const {
    competence,
    competenceProps,
    actions: actionsPage,
    isLoadingCompetence,
  } = useCompetenceDetail();

  const {
    isLoading,
    isErrorPostCompetence,
    isErrorPutCompetence,
    errorPostCompetence,
    errorPutCompetence,
    actions,
  } = competenceProps;

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
  } = useForm<ICompetence>({
    mode: "onBlur",
    defaultValues: competence,
    resolver: joiResolver(competenceSchema),
  });

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateCompetenceUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    reset(competence);
  }, [competence]);

  useEffect(() => {
    var apiErrors = (errorPostCompetence as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostCompetence]);

  useEffect(() => {
    var apiErrors = (errorPutCompetence as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutCompetence]);

  return (
    <Stack>
      <BackLink />

      <Page
        title={
          !competence?.uuid ? "Ajouter une compétence" : "Editer une compétence"
        }
        explain={
          !competence?.uuid
            ? "Veuillez saisir le référentiel de la compétence"
            : "Veuillez modifier le référentiel de la compétence"
        }
      >
        <DetailLoader isLoadingDetail={isLoadingCompetence}>
          <form onSubmit={handleSubmit(actionsPage.saveCompetence)}>
            <FormSection sectionTitle="Informations principales de la compétence">
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Libellé :"
                      type="text"
                      {...field}
                      placeholder="Ex : Ma compétence"
                      errorMessage={(errors.libelle as any)?.message}
                      required
                    />
                  )}
                  control={control}
                  name="libelle"
                  defaultValue=""
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="Description :"
                      type="text"
                      {...field}
                      placeholder="Ex : Description de la compétence"
                      errorMessage={(errors.description as any)?.message}
                    />
                  )}
                  control={control}
                  defaultValue=""
                  name="description"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      styles={commonInputStyles.toggleInput}
                      label="Compétence clé :"
                      onText="Oui"
                      offText="Non"
                      onChange={(e, checked) => onChange(checked)}
                      checked={value}
                    />
                  )}
                  defaultValue={false}
                  control={control}
                  name="competence_cle"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Toggle
                      styles={commonInputStyles.toggleInput}
                      label="Compétence active :"
                      onText="Oui"
                      offText="Non"
                      onChange={(e, checked) => onChange(checked)}
                      checked={value}
                    />
                  )}
                  defaultValue={false}
                  control={control}
                  name="active"
                />
              </Stack.Item>
              <Stack.Item>
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <CritereDynamicList
                      label="Ajoutez un ou plusieurs critères :"
                      placeholder="Ex : Mon critère"
                      addButtonLabel="Ajoutez ce critère"
                      name={name}
                      value={value || []}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{ libelle: "" }}
                      attr="libelle"
                      errorMessage={(errors.criteres as any)?.message}
                      validateItem={(i: any) => i}
                    />
                  )}
                  control={control}
                  name="criteres"
                />
              </Stack.Item>
            </FormSection>

            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              <CancelButton />

              <LoadButton
                text={
                  !competence?.uuid
                    ? "Créer la compétence"
                    : "Modifier la compétence"
                }
                isLoading={isLoading}
              />
            </Stack>
          </form>

          {isErrorPostCompetence && (
            <PageMessage
              message={(errorPostCompetence as any).response.data.message}
              type="error"
            />
          )}

          {isErrorPutCompetence && (
            <PageMessage
              message={(errorPutCompetence as any).response.data.message}
              type="error"
            />
          )}
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default CompetenceDetail;
