import { useEffect } from "react";
import { useParams } from "react-router-dom";
import candidatureAdapters from "../../adapters/candidatureAdapters";
import useCandidature from "../../hooks/candidature/useCandidature";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../hooks/candidature/useCandidatureWorkflow";
import useDynamicFields from "../../hooks/dynamicFields/useDynamicFields";
import useConstants from "../../hooks/useConstants";
import useUrlParams from "./useUrlParams";

const useFetchCandidature = (reactHookProps: any, onAfterReset?: Function) => {
  const { constants } = useConstants();
  const { id_candidature } = useUrlParams();
  const candidatureProps: any = useCandidature();
  const { updateDisabledStatuses, isFirstStatutBeforeOrEqualSecondStatus } =
    useCandidatureWorkflow();
  const { dynamicFields } = useDynamicFields();

  useEffect(() => {
    if (id_candidature !== "creer") {
      if (candidatureProps.candidature.uuid !== id_candidature) {
        candidatureProps.actions.resetCandidature();
      }
      candidatureProps.actions.getCandidatureDetail(id_candidature);
    } else {
      candidatureProps.actions.resetCandidature();
    }
  }, [id_candidature]);

  useEffect(() => {
    if (id_candidature == "creer") {
      reactHookProps.reset(candidatureProps.candidature);
    } else if (candidatureProps.candidature.uuid) {
      reactHookProps.reset(
        candidatureAdapters.transformForBO(
          candidatureProps.candidature,
          constants,
          isFirstStatutBeforeOrEqualSecondStatus(
            candidatureProps?.candidature.statut,
            "identification_parties_prenantes"
          ),
          dynamicFields
        )
      );

      updateDisabledStatuses(
        StatutCandidature.POSITIONNEMENT,
        !candidatureProps.candidature.positionnement_par_candidat
      );
      if (onAfterReset) {
        onAfterReset(candidatureProps.candidature);
      }
    }
  }, [candidatureProps.candidature.uuid, candidatureProps.candidature.statut]);

  const refrechCandidature = () => {
    candidatureProps.actions.resetCandidature();
    candidatureProps.actions.getCandidatureDetail(id_candidature);
  };

  return { refrechCandidature };
};

export default useFetchCandidature;
