import { Stack, Text, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useHabilitation from "../../../hooks/habilitation/useHabilitation";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";

function RemovaHabilitationModal({
  habilitations = [],
  isEnMasse = false,
}: any) {
  const [selectedHabilitations, setSelectedHabilitations]: any =
    useState(habilitations);
  const [commentaire, setCommentaire] = useState("");
  const {
    actions: { closeModal },
  } = useCustomModal();
  const { actions: habilitationActions, isLoadingRemoveHabilitations } =
    useHabilitation();
  const onClickRemoveButton = () => {
    habilitationActions.removeHabilitations({
      habilitations: selectedHabilitations.map(
        (habilitation: any) => habilitation.uuid
      ),
      commentaire,
    });
    closeModal();
  };

  useEffect(() => {
    setSelectedHabilitations(habilitations);
  }, [habilitations]);

  return (
    <Stack>
      <Text style={{ color: "#979797" }}>
        Veuillez renseigner un commentaire pour la(les) habilitation(s)
        sélectionnée(s) justifiant la suppression.
      </Text>
      {isEnMasse ? (
        <>
          <SelectManyFieldAsync
            label="Habilitations sélectionnées :"
            placeholder="Cherchez via le nom de la certification ou le nom de l'OF"
            resourceURI={`${API_URL}api/administration/habilitations/chercher`}
            addQueryURL={(resourceURI: any, terms: any) =>
              `${resourceURI}?recherche=${terms}&statut=ACTIVE`
            }
            method="GET"
            renderItem={(item: any) =>
              `Certification: ${item.certification?.libelle} - Organisme de formation: ${item.of?.raison_sociale}`
            }
            renderValue={(item: any) =>
              `Certification: ${item.certification?.libelle} - Organisme de formation: ${item.of?.raison_sociale}`
            }
            dataIndex="uuid"
            onChange={(e: any) => {
              setSelectedHabilitations(e.target.value);
            }}
            defaultValue={{}}
            value={selectedHabilitations}
            handleResponse={(response: any) => [
              ...response.data.data.filter((c: any) => c.statut == "ACTIVE"),
            ]}
          />
        </>
      ) : (
        <Text variant="medium" style={{ margin: "10px 0px" }}>
          Voulez-vous vraiment supprimer l'habilitation de l'organisme de
          formation {selectedHabilitations[0].of.raison_sociale} pour la
          certification {selectedHabilitations[0].certification.libelle} ?
        </Text>
      )}

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          label="Commentaire :"
          placeholder="Ex : commentaire"
          multiline
          required
          rows={6}
          maxLength={3000}
          value={commentaire}
          onChange={(e) => setCommentaire((e.target as any).value)}
        />
      </Stack.Item>

      <LoadButton
        isLoading={isLoadingRemoveHabilitations}
        text="Retirer"
        onClick={onClickRemoveButton}
        style={{ margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </Stack>
  );
}

export default RemovaHabilitationModal;
