import { PrimaryButton, Text } from '@fluentui/react';
import useCustomModal from '../../../../Common/Overlays/CustomModal/useCustomModal';
import { commonButtonStyles } from '../../../../styles';
import { useDecisionJury } from '../useDecisionJury';

function ValidationDecisionMembreJuryModal({ avis, reactHookProps }: any) {
    const { actions } = useDecisionJury()
    const { actions: actionsModal } = useCustomModal()

    const onValidateAvisMembre = () => {
        actions.onSaveMembre(avis);
        actionsModal.closeModal()
    };

    return (
        <>
            <Text variant="medium" style={{ margin: "10px 0px", textAlign: "center" }}>
                Après validation votre avis ne sera plus modifiable. Confirmez-vous la validation de votre avis ?
            </Text>
            <PrimaryButton
                text="Valider"
                onClick={() => onValidateAvisMembre()}
                style={{ width: "200px", margin: "10px 0px" }}
                styles={commonButtonStyles.buttonSubmit}
            />
        </>
    )
}

export default ValidationDecisionMembreJuryModal