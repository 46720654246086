import { useQuery } from "react-query";
import useDemandeHabilitation from "../../../hooks/demandeHabilitation/useDemandeHabilitation";
import useAuth from "../../../hooks/useAuth";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { getCertificationsCanDemandeHabilitationRequest } from "../../../services/certificationServices";
import tools from "../../../utils/tools";

function useDemandeHabilitationModal() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const { auth } = useAuth();
  const { actions: actionsDemandesHabilitation } = useDemandeHabilitation();

  const entiteOfLieProfilOf = (
    auth.user.profils_sur_branche.find(
      (item: any) => item.profil == "OF"
    ) as any
  )?.entites.filter(
    (entite: any) => entite.attributes.type == "organisme_de_formation"
  );

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () => {
      return getCertificationsCanDemandeHabilitationRequest(
        { sort: "", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];

  const actions = {
    onDemandeHabilitation: ({ entityOrProfil, data }: any) => {
      const demandeHabilitationData = { ...data };

      if (entityOrProfil == "OF" && entiteOfLieProfilOf.length == 1) {
        const of = entiteOfLieProfilOf[0];
        demandeHabilitationData.organisme_de_formation = {
          id: of.attributes.uuid,
          key: of.attributes.uuid,
          text: `${of.attributes.raison_sociale} - ${of.attributes.siret}`,
        };
      }

      const transformData = {
        utilisateur: auth.user.uuid,
        profil: entityOrProfil,
        organisme_de_formation:
          demandeHabilitationData?.organisme_de_formation?.id,
        certification: demandeHabilitationData?.certification?.id,
        documents: demandeHabilitationData?.documents?.map((d: any) => ({
          ...d,
          file: d.file[0],
        })),
      };

      const formData = new FormData();
      tools.buildFormData(formData, transformData, null);

      if (entityOrProfil == "OF") {
        actionsDemandesHabilitation.onDemandeHabilitationOf(formData);
        return;
      }

      actionsDemandesHabilitation.onDemandeHabilitationUtilisateur(formData);
    },
  };
  return {
    actions,
    auth,
    entiteOfLieProfilOf,
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  };
}

export default useDemandeHabilitationModal;
