import {
  Stack,
  Text,
  DetailsList,
  SelectionMode,
  Link,
  DetailsListLayoutMode
} from "@fluentui/react";
import { Link as RouterLink } from 'react-router-dom'
import Pagination from "../../Common/Pagination/Pagination";
import Page from "../../Common/Page/Page";
import tools from "../../utils/tools";

import { commonButtonStyles } from '../../styles/index'
import useStaticPageList, { StaticPageContext } from "./useStaticPageList";
import StaticPageListFilters from "./StaticPageListFilters/StaticPageListFilters";


function StaticPageList({ type }: any) {

  const { staticPages, optionsFilters, actions } = useStaticPageList(type);

  const items = staticPages?.data || [];

  const columns = [
    {
      key: "1",
      name: "Titre de la page",
      fieldName: "titre",
      minWidth: 150,
      maxWidth: 1200,
      isResizable: true,
      onColumnClick: () => {
        actions.sortColumn('titre')
      },
      onRender: (item: any) => (
        <Text>{item.titre}</Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, 'titre')
    },
    {
      key: "2",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) =>
        <Stack
          horizontal
        >
          <Link
            to={`/admin/pages/${type}/${item.id}`}
            as={RouterLink}
            styles={commonButtonStyles.buttonLink}
          >
            Editer
          </Link>
        </Stack>
    },
  ]

  return (
    <StaticPageContext.Provider
      value={{
        type
      }}
    >
      <Stack>
        <Page
          title="Pages de l'appplication"
          explain="Gérer les pages"
        >

          <StaticPageListFilters />

          <DetailsList
            layoutMode={DetailsListLayoutMode.justified}
            items={items}
            columns={columns}
            selectionMode={SelectionMode.none}
          />

          <Pagination
            changePage={actions.changePage}
            page={staticPages?.meta.current_page || 1} totalPages={Math.ceil(staticPages?.meta.total / 15) || 1}
          />
        </Page>
      </Stack>
    </StaticPageContext.Provider>
  )
}

export default StaticPageList