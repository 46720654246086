import { useAtom } from "jotai";
import { 
    branchesAtom, 
    constantsAtom,
} from "../atoms/constantsAtom"

function useConstants() {
    const [branches,] = useAtom<any>(branchesAtom);
    const [constants,] = useAtom<any>(constantsAtom);

    return {
        branches,
        constants
    }
}

export default useConstants