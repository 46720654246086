import { putBoSuiviCohortesRequest, putPublicSuiviCohorteRequest } from "../../services/suiviCohortesServices";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";

function useSuiviCohortesMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  const {actions:modalActions} = useCustomModal()

  const mutations = {
    PutSuiviCohortesPublic: useMutation(putPublicSuiviCohorteRequest, {
      onSuccess: () => {
        toast.success("Votre suivi de cohorte a bien été enregistré.");
        queryClient.invalidateQueries("suiviCohortes");
        navigate('/')
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement du suivi de cohorte : ${error?.response?.data?.message}`
            );
      },
    }),
    PutSuiviCohortesBo: useMutation(putBoSuiviCohortesRequest, {
        onSuccess: () => {
          toast.success("Le suivi de cohorte a bien été modifié.");
          modalActions.closeModal()
          queryClient.invalidateQueries("suiviCohortes");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la mise à jour du suivi de cohorte : ${error?.response?.data?.message}`
              );
        },
      }),
  };

  return {
    mutations,
  };
}

export default useSuiviCohortesMutations;
