import { atom, useAtom } from "jotai";

const urlParamsAtom = atom({
  id_candidature: "",
  statut_candidature: "",
  isEmbeded: false,
});

const useUrlParams = () => {
  const [urlParams, setUrlParams]: any = useAtom(urlParamsAtom);

  return {
    id_candidature: urlParams.id_candidature,
    statut_candidature: urlParams.statut_candidature,
    isEmbeded: urlParams.isEmbeded,
    setUrlParams,
  };
};

export default useUrlParams;
