import useJury from "../../hooks/jury/useJury";
import useJuryQuery from "../../hooks/jury/useJuryQuery";

function useCandidatJuryList() {
  const juryProps = useJury();
  const { jury, isLoadingJury, isRefetchingJury } = useJuryQuery(juryProps);

  return {
    jury,
    juryProps,
    isLoadingJury: isLoadingJury || isRefetchingJury,
  };
}

export default useCandidatJuryList;
