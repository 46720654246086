import { useAtom } from "jotai";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { homePageByProfile } from "../../config/routesConfig";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useAuth from "../../hooks/useAuth";
import useNav from "../../hooks/useNav";
import tools from "../../utils/tools";
import FullscreenLoader from "../FullscreenLoader/FullscreenLoader";

const RequireAccess = ({ allowedAccess }: any) => {
  const { auth }: any = useAuth();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: accessActions, accessSettings } = useAccessSettings();
  const { actions: navActions } = useNav();
  const location = useLocation();

  //si les accès du user n'ont pas encore été récupérés ici, c'est parceque la page a été rechargée et la branche n'est plus sélectionnée
  useEffect(() => {
    if (accessSettings.length == 0 && userSelectedBranch) {
      navActions.changeBranch(userSelectedBranch);
    }
  }, [accessSettings, userSelectedBranch]);

  const homePage = auth.user.est_administrateur
    ? homePageByProfile["ADMINISTRATEUR_BRANCHE"]
    : homePageByProfile[
        (tools.findIn(auth.user.mes_branches, userSelectedBranch.id, "id") as any)
          ?.profils[0] as any
      ];

  return accessSettings.length > 0 || location.pathname == "/select-branch" ? (
    accessActions.isRouteAccessAllowed(allowedAccess) ? (
      <Outlet />
    ) : (
      <Navigate to={homePage} state={{ from: location }} replace />
    )
  ) : (
    <FullscreenLoader />
  );
};

export default RequireAccess;
