import { useQuery } from "react-query"
import { getUserRequest } from '../../services/userServices'

function useUserQuery(userProfilProps: any) {
    const { data: userProfil, isLoading, isRefetching } = useQuery(
        ['usersProfil', userProfilProps.userUuid],
        () => getUserRequest(userProfilProps.userUuid),
        {
            enabled: !!userProfilProps.userUuid,
            retry: 0,
            refetchOnWindowFocus:false,
        }
    )

    return {
        userProfil,
        isLoading,
        isRefetching
    }
}

export default useUserQuery