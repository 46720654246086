import { Controller, useFormContext } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import { TextField } from "@fluentui/react";
import { commonInputStyles } from "../../../styles";
import useJuryDetail from "../useJuryDetail";

function CommentaireJury() {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { conditionsInputDisabled } = useJuryDetail();

  return (
    <FormSection sectionTitle="Commentaire du jury">
      <Controller
        render={({ field }) => (
          <TextField
            styles={commonInputStyles.textField}
            label="Commentaire du jury (5000 caractères max) :"
            placeholder="Ex : commentaire du jury"
            multiline
            rows={6}
            maxLength={5000}
            {...field}
            errorMessage={(errors.commentaire as any)?.message}
            disabled={conditionsInputDisabled.commentaire}
          />
        )}
        control={control}
        name="commentaire"
      />
    </FormSection>
  );
}

export default CommentaireJury;
