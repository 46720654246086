import useConstants from "../../../../hooks/useConstants";
import tools from "../../../../utils/tools";

const CertificationInfos = ({
  certification,
  selectedOption = null,
  extraInformation = true,
}: any) => {
  const { constants } = useConstants();

  const nbBlocsImposes =
    certification?.blocs_competences?.reduce((a: any, b: any) => {
      if (b.option == selectedOption) {
        if (b.statut === 1) {
          return a + 1;
        }
      }
      return a;
    }, 0) || 0;

  return !certification ? null : (
    <>
      <div>
        <strong style={extraInformation ? {} : { fontSize: 16 }}>{certification.libelle}</strong>
        <span>
          {" "}
          - {(tools.findIn(constants.CERTIFICATIONS.TYPES, certification.type, "id") as any).text}
        </span>
        {extraInformation && (
          <>
            <div className="text-info">{certification.description}</div>
            <div>Type d'enregistrement : {certification.enregistrement_libelle}</div>
            <div>
              Numéro d'enregistrement :{" "}
              {certification.numero_enregistrement || <i className="text-info">aucun</i>}
            </div>
            <div>
              Nombre de blocs nécessaires à l'obtention :{" "}
              {nbBlocsImposes + parseInt(certification.nb_blocs_au_choix_a_valider)}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CertificationInfos;
