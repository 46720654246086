function PreviewPage({
    data,
}: any) {
    return (
        <div>
            <h1>{data.titre}</h1>
            <div
            dangerouslySetInnerHTML={{
                __html: `${data.contenu}`,
            }}
        />
        </div>
    )
}

export default PreviewPage