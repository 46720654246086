import { Label, Stack } from "@fluentui/react";
import { commonInputStyles } from "../../styles";
import Badge from "../Badge/Badge";
import useTheme from "../useTheme";
import ImportTable from "./ImportTable";

const ValidationResults = ({ results, validLabel, invalidLabel }: any) => {
  const { successColor, dangerColor } = useTheme();
  const headersEntities = results.headers;
  const validEntities = results.import_valides;
  const invalidEntities = results.import_invalides;

  return (
    <>
      {validEntities.length > 0 && (
        <div
          style={{
            paddingLeft: 20,
            borderLeft: "2px solid green",
            maxWidth: "80vw",
            overflow: "scroll",
          }}
        >
          <Label className="text-green" styles={commonInputStyles.label} style={{ fontSize: 21 }}>
            {validLabel} <Badge text={validEntities.length} color={successColor} />
          </Label>
          <ImportTable rows={validEntities} headers={headersEntities} />
        </div>
      )}
      {invalidEntities.length > 0 && (
        <div
          style={{
            paddingLeft: 20,
            borderLeft: "2px solid green",
            maxWidth: "80vw",
            overflow: "scroll",
          }}
        >
          <Label className="text-red" styles={commonInputStyles.label} style={{ fontSize: 21 }}>
            {invalidLabel} <Badge text={invalidEntities.length} color={dangerColor} />
          </Label>
          <ImportTable rows={invalidEntities} headers={headersEntities} />
        </div>
      )}
    </>
  );
};

export default ValidationResults;
