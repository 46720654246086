const signUpAdapters = {
  transformForAPI: ({
    data,
    isExistantStructure,
  }: {
    data: any;
    isExistantStructure: boolean;
  }) => {
    let transformed: any = {
      branch_id: data.branch_id,
      email: data.email,
      nom: data.nom,
      prenom: data.prenom,
      profil: data.profil,
      cle_profil: data.profil == "GROUPE_ENTREPRISE" ? data.siren : data.siret,
    };

    if (isExistantStructure) {
      return transformed;
    }

    switch (data.profil) {
      case "ENTREPRISE":
        transformed.structure = signUpAdapters.transformForEntrepriseAPI(data);
        break;
      case "OF":
        transformed.structure = signUpAdapters.transformForOfAPI(data);
        break;
      case "GROUPE_ENTREPRISE":
        transformed.structure =
          signUpAdapters.transformForGroupeEntrepriseAPI(data);
        break;
    }

    return transformed;
  },
  transformForEntrepriseAPI: (data: any) => ({
    raison_sociale: data.raison_sociale,
    siret: data.siret,
    ref_idcc: data.ref_idcc?.id,
    adresse: {
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    groupe: data.siren_rattachement,
  }),
  transformForOfAPI: (data: any) => ({
    raison_sociale: data.raison_sociale,
    siret: data.siret,
    adresse: {
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
    groupe: data.siren_rattachement,
  }),
  transformForGroupeEntrepriseAPI: (data: any) => ({
    raison_sociale: data.raison_sociale,
    siren: data.siren,
    adresse: {
      adresse: data.adresse,
      complement_adresse: data.complement_adresse,
      code_postal: data.code_postal,
      region: data.region,
      ville: data.ville,
    },
  }),
};

export default signUpAdapters;
