import { Label } from "@fluentui/react";
import { commonInputStyles } from "../../styles";
import ImageDisplay from "../ImageDisplay/ImageDisplay";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import ErrorMessage from "../SelectField/common/ErrorMessage/ErrorMessage";

const fileTypes: any = ["image/png", "image/jpeg", "image/jpg"];

function CustomImageInput({
  name,
  label,
  required = false,
  onChange = () => {},
  errorMessage,

  styleImgContainer,
  isFile,
  file,
  alt,

  isEditable = false,
  isDeleted = false,
  onClick = () => {},
  authorizedTypes = fileTypes,

}: any) {
  const onDrop = useCallback((files: any) => {
    // Do something with the files
    if (files.length > 0) {
      let hasErrors = false;
      Array.from(files).forEach((file: any) => {
        if (!authorizedTypes.includes(file.type)) {
          toast.error(`${file.name} n'a pas le bon format `);
          hasErrors = true;
        }
      });
      if (!hasErrors) {
        onChange({target: {name, files: [files[0]]}});
      }
    } else {
      toast.error("Aucun fichier valide n'a été choisi");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <>
      <Label htmlFor={name} required={required}>
        {label}
      </Label>
      <div
        style={{
          border: `1px dashed grey`,
          borderRadius: 5,
          background:
            commonInputStyles.textField.root.selectors[
              ".ms-TextField-fieldGroup"
            ].backgroundColor,
          textAlign: "center",
          cursor: "pointer",
          padding: 20,
          height: 64,
          fontSize: 20,
          opacity: 0.5,
        }}
        className="d-center"
        {...getRootProps()}
      >
        <input {...getInputProps()} name={name}/>
        {isDragActive ? (
          <p>Déposez le fichier ici ...</p>
        ) : (
          <div>
            Glissez-déposez des fichiers
            {["PNG", "JPEG", "JPG"]
              .map((item: any) => " " + item.toLowerCase())
              .join(", ")}{" "}
            <br />
            ou cliquez pour parcourir vos dossiers <br />
          </div>
        )}
      </div>
      {errorMessage && <ErrorMessage
                errorMessage={errorMessage}
              />}

      <ImageDisplay
        styleImgContainer={styleImgContainer}
        isDeleted={isDeleted}
        isFile={isFile}
        file={file}
        alt={alt}
        isEditable={isEditable}
        onClick={onClick}
      />
    </>
  );
}

export default CustomImageInput;
