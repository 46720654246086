import { DefaultButton, Dropdown, Stack } from "@fluentui/react";
import { useAtom } from "jotai";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import TextResult from "../../../Common/TextResult/TextResult";
import useConstants from "../../../hooks/useConstants";
import { getCertificationsCanDemandeHabilitationRequest } from "../../../services/certificationServices";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import useMesDemandesHabilitationsList from "../useMesDemandesHabilitationsList";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum } from "../../../config/accessEnum";
import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function MesDemandesHabilitationsListFilters() {
  const location = useLocation();

  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const { actions: accessActions } = useAccessSettings();
  const { constants } = useConstants();
  const {
    actions,
    reactHookProps,
    demandeHabilitationsOf,
    demandeHabilitationsUtilisateur,
    isLoadingDemandeHabilitationsOf,
    isLoadingDemandeHabilitationsUtilisateur,
  } = useMesDemandesHabilitationsList();
  const { handleSubmit, setValue, control } = reactHookProps;

  const {
    data: certificationsFromApi,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () => {
      return getCertificationsCanDemandeHabilitationRequest(
        { sort: "libelle", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...(certificationsFromApi?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || []),
  ];

  const demandeHabilitationStatutList = [
    { id: undefined, text: "Tous", key: "all" },
    ...tools.addKeyToObjectInArray(
      constants.DEMANDE_HABILITATION.STATUTS,
      "id"
    ),
  ];

  const totalDemandesHabilitationUtilisateur =
    (accessActions.canI({
      action: branchAccessEnum.HABILITATION_SIMPLE_OF,
    }).granted
      ? demandeHabilitationsOf?.meta?.total
      : demandeHabilitationsUtilisateur?.meta?.total) || 0;

  useEffect(() => {
    const parsed: any = queryString.parse(location.search);
    if (parsed["statut"]) {
      setValue("statut", {
        key: parsed["statut"],
        id: parsed["statut"],
        text: constants.HABILITATION.STATUTS.find(
          (statut: any) => statut.key == parsed["statut"]
        )?.text,
      });
      handleSubmit(actions.changeFilters)();
    }
  }, []);

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Statut de la demande :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="..."
                  options={demandeHabilitationStatutList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={demandeHabilitationStatutList[0]}
              control={control}
              name="statut"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => {
                return (
                  <Dropdown
                    selectedKey={value ? value.key : undefined}
                    label="Certification :"
                    placeholder="Sélectionnez une certification"
                    options={certificationList}
                    onChange={(e: any, options: any) => {
                      setValue(name, options, { shouldValidate: true });
                    }}
                    styles={commonInputStyles.dropdownStyle}
                  />
                );
              }}
              defaultValue={certificationList[0]}
              control={control}
              name="certification"
            />
          </Stack.Item>
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={
                  isLoadingDemandeHabilitationsOf ||
                  isLoadingDemandeHabilitationsUtilisateur
                }
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalDemandesHabilitationUtilisateur} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default MesDemandesHabilitationsListFilters;
