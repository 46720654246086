import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import useMiseEnConfirmite from "./useMiseEnConfirmite";
import LoadButton from "../../../../Common/LoadButton/LoadButton";

const dialogStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: "Commentaire",
  closeButtonAriaLabel: "Fermer",
  subText: "Précisez la raison et la nature de cette mise en conformité.",
};


const DialogConfirmerMEC = ({ reactHookProps,onAfterValidate = () => {}, }: any) => {
  const { mecActions, isConfirmDialogOpen, mecDetail, isMECSaving } =
    useMiseEnConfirmite();


  return (
    <Dialog
      hidden={!isConfirmDialogOpen}
      onDismiss={mecActions.onCloseConfirmDialog}
      modalProps={{
        styles: dialogStyles,
      }}
      dialogContentProps={dialogContentProps}
    >
      <TextField
        label=""
        multiline
        rows={3}
        value={mecDetail.comment}
        name="comment"
        placeholder="Pourquoi avoir effectué ces corrections ?"
        onChange={mecActions.onUpdateMECDetail}
      />
      <DialogFooter>
        <LoadButton
          type="button"
          onClick={() =>
            mecActions.onValidateDialog(reactHookProps, onAfterValidate)
          }
          text="Confirmer"
          isLoading={isMECSaving}
        />
        <DefaultButton
          type="button"
          onClick={mecActions.onCloseConfirmDialog}
          text="Annuler"
        />
      </DialogFooter>
    </Dialog>
  );
};

export default DialogConfirmerMEC;
