import { useAtom } from "jotai";
import { toast } from "react-toastify";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import companyAdapters from "../../adapters/companyAdapters";
import useCompanyMutations from "./useCompanyMutations";

function useCompany() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const {
    companyUuid,
    setCompanyUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    isSaved,
  } = useCompanyMutations();

  const actions = {
    setUpdateCompanyUuid: (data: any) => {
      setCompanyUuid(data);
    },
    postCompany: async (company: any) => {
      mutations.PostCompany.mutate(companyAdapters.transformForAPI(company));
    },
    putCompany: async (company: any) => {
      mutations.PutCompany.mutate(companyAdapters.transformForAPI(company));
    },
    deleteCompany: async (companyUuid: string) => {
      toast.info("En cours de développement");
      //mutations.DeleteCompany.mutate(companyUuid);
    },
    toggleCompanyActivation: (company: any) => {
      setIsToggleActivationUuid(company.uuid);
      if (company.active) {
        mutations.ToggleCompanyDesactivate.mutate(company.uuid);
      } else {
        mutations.ToggleCompanyActivate.mutate(company.uuid);
      }
    },
  };

  const {
    isLoading: isLoadingPostCompany,
    isError: isErrorPostCompany,
    error: errorPostCompany,
  } = mutations.PostCompany;

  const {
    isLoading: isLoadingPatchCompany,
    isError: isErrorPatchCompany,
    error: errorPatchCompany,
  } = mutations.PutCompany;

  return {
    userSelectedBranch,
    companyUuid,
    isLoading: isLoadingPostCompany || isLoadingPatchCompany,
    isToggleActivationUuid,
    hasError: isErrorPostCompany || isErrorPatchCompany,
    errorPostCompany,
    errorPatchCompany,
    actions,
    isSaved,
  };
}

export default useCompany;
