import { atom, useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { branchAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import {
  getHabilitationOfRequest,
  getHabilitationUtilisateurRequest,
} from "../../services/habilitationServices";
import tools from "../../utils/tools";
import DemandeHabilitationModal from "../Dashboard/DemandeHabilitationModal/DemandeHabilitationModal";

const optionsFiltersAtom = atom({
  sort: "-date_fin",
  page: 1,
  paginate: true,
  searchAt: "",
});

export const initialFilters = {
  statut: { id: undefined, text: "Tous", key: "all" },
  certification: { id: undefined, text: "Tous", key: "all" },
};

export const filtersAtom = atomWithReset<any>(initialFilters);

function useMesHabilitationsList() {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const { actions: modalActions } = useCustomModal();
  const { actions: accessActions } = useAccessSettings();
  const navigate = useNavigate();

  const reactHookProps = useForm<any>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: habilitationsOF, isLoading: isLoadingHabilitationsOF } =
    useQuery(
      ["habilitationsOF", optionsFilters, userSelectedBranch],
      () => getHabilitationOfRequest(filters, optionsFilters),
      {
        retry: 0,
        enabled: accessActions.canI({
          action: branchAccessEnum.HABILITATION_SIMPLE_OF,
        }).granted,
      }
    );
  const {
    data: habilitationsUtilisateur,
    isLoading: isLoadingHabilitationsUtilisateur,
  } = useQuery(
    ["habilitationsUtilisateurs", userSelectedBranch, optionsFilters],
    () => getHabilitationUtilisateurRequest(filters, optionsFilters),
    {
      retry: 0,
      enabled:
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
        }).granted ||
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
        }).granted,
    }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.onChangeHabilitationOptionFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    onOpenDemandeHabilitationModal: (
      entityOrProfil: "OF" | "EVALUATEUR" | "POSITIONNEUR"
    ) => {
      modalActions.openModal({
        header: "Demander une habilitation",
        body: (
          <DemandeHabilitationModal
            entityOrProfil={entityOrProfil}
            onAfterSave={() => {
              navigate(`/admin/mes-demandes-habilitation`);
            }}
          />
        ),
        fullWidth: true,
      });
    },
    changePage: (page: number) => {
      actions.onChangeHabilitationOptionFilters({
        ...optionsFilters,
        page,
      });
    },
    onChangeHabilitationOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeHabilitationOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
  };

  return {
    actions,
    optionsFilters,
    habilitationsOF,
    isLoadingHabilitationsOF,
    habilitationsUtilisateur,
    isLoadingHabilitationsUtilisateur,
    reactHookProps,
  };
}

export default useMesHabilitationsList;
