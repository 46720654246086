import { Icon } from "@fluentui/react";
import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useTheme from "../../../Common/useTheme";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import CompetenceBlockItem from "./CompetenceBlockItem";

const CompetenceBlockListDnd = ({ selectedBlocks, forOption }: any) => {
  const { primaryColor } = useTheme();
  const { allBlocks, actions, blocksDisabled }: any =
    useContext(CertificationContext);
  const { onChangeBlocks } = actions;

  const handleOnDragEnd = (result: any) => {
    if (blocksDisabled) return null;
    if (!result.destination) {
      return false;
    }
    const items = Array.from(selectedBlocks);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    onChangeBlocks([
      ...allBlocks.filter((b: any) =>
        forOption ? b.option !== forOption : false
      ),
      ...items,
    ]);
  };

  return (
    <div style={{ display: "block", width: "100%", minHeight: 400 }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="blocks" type="BLOCKS">
          {(provided) => (
            <ul
              style={{
                marginBottom: 10,
                padding: 0,
                listStyle: "none",
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedBlocks.map((block: any, blockIndex: any) => {
                return (
                  <Draggable
                    key={`block${block.uuid}`}
                    draggableId={`block${block.uuid}`}
                    index={blockIndex}
                  >
                    {(provided) => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          style={{
                            margin: "10px 0px",
                            marginBottom: "10px",
                            position: "relative",
                            marginLeft: 30,
                            marginRight: 30,
                            outline: `1px solid ${primaryColor}`,
                            background: "white",
                          }}
                        >
                          {!blocksDisabled && (
                            <GripHandle
                              left
                              dragHandleProps={provided.dragHandleProps}
                              disabled={blocksDisabled}
                            />
                          )}
                          <CompetenceBlockItem
                            block={block}
                            forOption={forOption}
                          />
                          {!blocksDisabled && (
                            <GripHandle
                              dragHandleProps={provided.dragHandleProps}
                              disabled={blocksDisabled}
                            />
                          )}
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

const GripHandle = ({ dragHandleProps, disabled, left }: any) => {
  const { primaryColor } = useTheme();
  return (
    <div
      style={{
        background: primaryColor,
        borderTopLeftRadius: left ? "5px" : 0,
        borderBottomLeftRadius: left ? "5px" : 0,
        borderTopRightRadius: !left ? "5px" : 0,
        borderBottomRightRadius: !left ? "5px" : 0,
        width: "30px",
        height: "100%",
        position: "absolute",
        left: left ? "-30px" : "auto",
        right: !left ? "-30px" : "auto",
        transition: "opacity 400ms",
        top: "0px",
        textAlign: "center",
        outline: `1px solid ${primaryColor}`,
      }}
      disabled={disabled}
      {...dragHandleProps}
    >
      <Icon
        style={{ color: "white", marginTop: 10 }}
        iconName="WaffleOffice365"
      />
    </div>
  );
};

export default CompetenceBlockListDnd;
