import {
    useQueryClient,
    useMutation,
    useQuery
} from "react-query";
import {
    getStaticPage,
    getBranchStaticPage,
    putStaticPage,
    putBranchStaticPage,
} from '../../services/staticPagesServices'
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import useCustomPanel from "../../Common/Overlays/CustomPanel/useCustomPanel";
import PreviewPage from "./PreviewPage/PreviewPage";

function useStaticPageDetail(type:any) {
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const [pageId, setPageId] = useState(null);

    const { actions: panelActions } = useCustomPanel();

    const { data: page, isLoading, isRefetching } = useQuery(
        ["staticPages", pageId],
        () => type=='generales' ? getStaticPage(pageId) : getBranchStaticPage(pageId),
        {
            enabled: !!pageId,
            retry: 0,
            refetchOnWindowFocus:false,
        }
    );

    const mutations = {
        PutPageGeneral: useMutation(putStaticPage, {
            onSuccess: () => {
                toast.success("La page a été mise à jour avec succès");
                queryClient.invalidateQueries("staticPages");
            },
            onError: (error: any) => {
                !(error.response.status) || error.response.status >= 500 ? navigate(`/erreur`) : toast.error(`Une erreur est survenue lors de la mise à jour de la page : ${error?.message}`)
            }
        }),
        PutPageBranche: useMutation(putBranchStaticPage, {
            onSuccess: () => {
                toast.success("La page a été mise à jour avec succès");
                queryClient.invalidateQueries("staticPages");
            },
            onError: (error: any) => {
                !(error.response.status) || error.response.status >= 500 ? navigate(`/erreur`) : toast.error(`Une erreur est survenue lors de la mise à jour de la page : ${error?.message}`)
            }
        }),
    }

    const actions = {
        putPage: async (page: any) => {
            if(type=='generales'){
                mutations.PutPageGeneral.mutate(page)
            }else{
                mutations.PutPageBranche.mutate(page)
            }
   
        },
        setUpdatePageId: (data: any) => {
            setPageId(data);
        },
        openPreviewPanel: (data: any) => {
            panelActions.openPanel({
                header: "Aperçu de la page",
                body: <PreviewPage data={data} />
            })
        }
    }

    const {
        isLoading: isLoadingPutPageGeneral,
        isError: isErrorPutPageGeneral,
        error: errorPutPageGeneral,
    } = mutations.PutPageGeneral;

    const {
        isLoading: isLoadingPutPageBranche,
        isError: isErrorPutPageBranche,
        error: errorPutPageBranche,
    } = mutations.PutPageBranche;

    return {
        page,
        isLoadingPage: isLoading || isRefetching,
        isLoadingPutPage:isLoadingPutPageGeneral || isLoadingPutPageBranche,
        isErrorPutPage: isErrorPutPageGeneral || isErrorPutPageBranche,
        errorPutPage: errorPutPageGeneral || errorPutPageBranche,
        actions
    }
}

export default useStaticPageDetail