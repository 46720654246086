import { DatePicker, Label, Stack, TextField } from "@fluentui/react";
import FormSection from "../../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../../styles";
import { DateTime } from "luxon";
import { strings } from "../../../../config/datePickerConfig";
import tools from "../../../../utils/tools";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import { branchAccessEnum } from "../../../../config/accessEnum";

function ValiderAvisFormateurForm({ reactHookProps, accessActions }: any) {
  const doitValiderAvisFormateur =
    (accessActions.canI({
      action: branchAccessEnum.ACTIVER_AVIS_FORMATEUR,
    }).granted ||
      accessActions.canI({
        action: branchAccessEnum.AVIS_FORMATEUR_FACULTATIF,
      }).granted) &&
    accessActions.canI({
      action: branchAccessEnum.ACTIVER_VALIDATION_AVIS_FORMATEUR,
    }).granted &&
    reactHookProps.watch("avis_formateur");

  return doitValiderAvisFormateur ? (
    <Stack>
      <FormSection sectionTitle="Avis du formateur">
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label={"Nom :"}
                  onRenderLabel={({ label }: any) => (
                    <Stack horizontal verticalAlign="center">
                      {label} &nbsp;
                      <i
                        style={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        (requis pour la validation de l'avis)
                      </i>
                    </Stack>
                  )}
                  {...field}
                  placeholder="Ex : Doe"
                  disabled={true}
                />
              )}
              name="nom_formateur"
              control={reactHookProps.control}
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Prénom :"
                  onRenderLabel={({ label }: any) => (
                    <Stack horizontal verticalAlign="center">
                      {label} &nbsp;
                      <i
                        style={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        (requis pour la validation de l'avis)
                      </i>
                    </Stack>
                  )}
                  {...field}
                  placeholder="Ex : John"
                  disabled={true}
                />
              )}
              name="prenom_formateur"
              control={reactHookProps.control}
            />
          </Stack.Item>
          <Stack.Item
            className="ms-Grid-col ms-sm12"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Email :"
                  onRenderLabel={({ label }: any) => (
                    <Stack horizontal verticalAlign="center">
                      {label} &nbsp;
                      <i
                        style={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        (requis pour la validation de l'avis)
                      </i>
                    </Stack>
                  )}
                  {...field}
                  placeholder="Ex : john.doe@email.com"
                  disabled={true}
                />
              )}
              name="email_formateur"
              control={reactHookProps.control}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Label
              htmlFor="date_debut_formation"
              styles={commonInputStyles.label}
            >
              Date de début de formation du candidat :
            </Label>
            <Controller
              render={({ field: { onChange, name, value } }) => (
                <DatePicker
                  formatDate={(date?: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  strings={strings}
                  showGoToToday={false}
                  maxDate={new Date()}
                  value={
                    reactHookProps.watch("date_debut_formation")
                      ? new Date(reactHookProps.watch("date_debut_formation"))
                      : undefined
                  }
                  styles={commonInputStyles.dataPicker}
                  onSelectDate={(date: any) => {
                    if (date) {
                      onChange(
                        DateTime.fromJSDate(date)
                          .set({ hour: 12 })
                          .toUTC()
                          .toISO()
                      );
                    }
                  }}
                  disabled={true}
                  placeholder="dd/mm/yyyy"
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              name="date_debut_formation"
              control={reactHookProps.control}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Label
              htmlFor="date_fin_formation"
              styles={commonInputStyles.label}
            >
              Date de fin de formation du candidat:
            </Label>
            <Controller
              render={({ field: { onChange, name, value } }) => (
                <DatePicker
                  formatDate={(date?: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  strings={strings}
                  showGoToToday={false}
                  maxDate={new Date()}
                  value={
                    reactHookProps.watch("date_fin_formation")
                      ? new Date(reactHookProps.watch("date_fin_formation"))
                      : undefined
                  }
                  styles={commonInputStyles.dataPicker}
                  onSelectDate={(date: any) => {
                    if (date) {
                      onChange(
                        DateTime.fromJSDate(date)
                          .set({ hour: 12 })
                          .toUTC()
                          .toISO()
                      );
                    }
                  }}
                  disabled={true}
                  placeholder="dd/mm/yyyy"
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              name="date_fin_formation"
              control={reactHookProps.control}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Avis du formateur (6000 caractères max) :"
                  placeholder="Ex : avis du formateur"
                  onRenderLabel={({ label }: any) => (
                    <Stack horizontal verticalAlign="center">
                      {label} &nbsp;
                      <i
                        style={{
                          fontWeight: 300,
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        (requis pour la validation de l'avis)
                      </i>
                    </Stack>
                  )}
                  multiline
                  rows={6}
                  maxLength={6000}
                  {...field}
                  disabled={true}
                  errorMessage={
                    (
                      (reactHookProps.formState.errors.avis_formateur as any)
                        ?.avis as any
                    )?.message
                  }
                />
              )}
              control={reactHookProps.control}
              name="avis_formateur"
            />
          </Stack.Item>
        </Stack>
      </FormSection>

      <DynamicFieldForm
        statut={StatutCandidature.AVIS_FORMATEUR}
        reactHookProps={reactHookProps}
        disabled={true}
      />
    </Stack>
  ) : null;
}

export default ValiderAvisFormateurForm;
