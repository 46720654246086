import { useForm } from "react-hook-form";
import { ICertification } from "../../interfaces/certificationInterface";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import AddDocumentForm from "./AddDocumentForm/AddDocumentForm";
import tools from "../../utils/tools";
import useCertification from "../../hooks/certifications/useCertification";
import useCertificationQuery from "../../hooks/certifications/useCertificationQuery";
import useConstants from "../../hooks/useConstants";
//import { certificationSchema } from "../../schemas/certificationSchema";

function useCertificationDetail() {
  const certificationProps = useCertification();
  const { actions: modalActions } = useCustomModal();
  const { constants } = useConstants();

  const { certification, isLoading, isRefetching }: any = useCertificationQuery(certificationProps);

  const reactHookProps = useForm<ICertification>({
    mode: "onBlur",
    defaultValues: {
      blocs_competences: certification?.blocs_competences || [],
      documents: [],
      document_parchemin_attestation: [],
      options: [],
      modalites_evaluation: constants["CERTIFICATIONS"]["MODALITE_EVALUATION_TYPES"][0],
      enregistrement: constants["CERTIFICATIONS"]["ENREGISTREMENT"][2],
    },
    //resolver: joiResolver(certificationSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const typesThatCanUseReferential = [2, 5]; //id des types de certif qui peuvent utiliser le référentiel d'une autre certif
  const typeThaTChooseCertificationDelivered = [3, 6]; // id des types de certif qui doivent choisir une certification delivré supplementaire
  const typeAllowedCertificationDelivered = [2, 5]; // id des types de certif parmis lesquels on doit choisir une certification
  const typeUsingBlocInterBranchOnly = [2, 5]; // id des types de certif parmis lesquels on doit choisir une certification
  const typesThatNeedLibelle = [12]; //id du type (Autre) qui necessite un champ label supplementaire
  const enregistrementTypesThatRequireANumber = [1, 2]; //id des types d'enregistrement qui nécessitent de renseigner un numéro d'enregistrement

  /* Variables qui conditionnent l'affichage des inputs/élements sur la page */
  const conditionnalDisplayVariables = {
    blocs_de_competence: reactHookProps.watch("blocs_competences"),
    canUseExistingReferential: typesThatCanUseReferential.includes(
      (reactHookProps.watch("type") as any)?.id
    ), //le type de la certif permet-il d'utiliser le référentiel d'une autre branche ?
    isChoosingCertification: typeThaTChooseCertificationDelivered.includes(
      (reactHookProps.watch("type") as any)?.id
    ),
    //si on choisi une certification delivre en plus de la certification en cours de creation
    idTypeCertificationDelivered:
      typeAllowedCertificationDelivered[
      typeThaTChooseCertificationDelivered.indexOf((reactHookProps.watch("type") as any)?.id)
      ],
    // l'id du type de certification delivre qu'on a le droit de selectionne en fonction du type de la certification en cours de creation
    needExtraLibelleType: typesThatNeedLibelle.includes((reactHookProps.watch("type") as any)?.id),
    isUsingExistingReferential: reactHookProps.watch("reprise_referentiel_existant") === true,
    //si on choisi d'utiliser un référentiel existant
    isUsingTypeBlocInterBranch: typeUsingBlocInterBranchOnly.includes(
      (reactHookProps.watch("type") as any)?.id
    ),
    // si on choisi d'utiliser le type 2 ou 5 on peut chercher uniquement des blocs interbranche
    queryUrlSearchBlockInterBranch: typeUsingBlocInterBranchOnly.includes(
      (reactHookProps.watch("type") as any)?.id
    )
      ? "&seulement_inter_branches=true"
      : reactHookProps.watch("avec_blocs_inter_branches")
        ? ""
        : `&seulement_ma_branche=true`,
    // si type de certification interbranche alors l'utilisateur peut uniquement ajouter des blocs inter branche
    // sinon
    //si utilisateur choisi d'utiliser des blocs inter branche, l'utilisateur peut ajouter des blocs propre à sa branche ET des blocs inter branche
    //sinon, l'utilisateur peut ajouter UNIQUEMENT des blocs propre à sa branche (compris blocs inter branche de sa branche)
    hasNumeroEnregistrement: enregistrementTypesThatRequireANumber.includes(
      (reactHookProps.watch("enregistrement") as any)?.id
    ), //affcher l'input du n° d'enregistrement si le type d'enregistrement est inclu dans enregistrementTypesThatRequireANumber
    nbChiffresEnregistrement: (reactHookProps.watch("enregistrement") as any)?.id == 1 ? 4 : 5,
    hasOptions: reactHookProps.watch("avec_options") === true, //affiche le DynamicList pour ajouter des options et permet de renseigner le référentiel pour chaque option
  };

  const actions = {
    saveCertification: (data: any) => {
      if (!(data as any).uuid && !certification?.id) {
        certificationProps.actions.postCertification(data);
      } else {
        certificationProps.actions.putCertification(data);
      }
    },
    onAddOption: (newOption: any) => {
      const currentOptions = reactHookProps.watch("options") || [];
      reactHookProps.setValue("options", [...currentOptions, newOption]);
    },
    onRemoveOption: (optionToRemove: any) => {
      var optionHasBlocks = tools.checkBlockHasOption(
        reactHookProps.watch("blocs_competences"),
        optionToRemove.option
      );
      var optionHasDocuments = tools.checkDocumentHasOption(
        reactHookProps.watch("documents"),
        optionToRemove.option
      );
      var optionHasDocumentsParchemins = tools.checkDocumentHasOption(
        reactHookProps.watch("document_parchemin_attestation"),
        optionToRemove.option
      );
      const currentOptions = reactHookProps.watch("options") || [];
      const newOptions = currentOptions.filter((opt: any) => opt.option !== optionToRemove.option);

      const newBlocks = tools.removeBlockHasOption(
        reactHookProps.watch("blocs_competences"),
        optionToRemove.option
      );
      const newDocuments = tools.removeDocumentHasOption(
        reactHookProps.watch("documents"),
        optionToRemove.option
      );
      const newDocumentsParchemins = tools.removeDocumentParcheminHasOption(
        reactHookProps.watch("document_parchemin_attestation"),
        optionToRemove.option
      );

      if (optionHasBlocks || optionHasDocuments || optionHasDocumentsParchemins) {
        if (
          window.confirm(
            "Cette option possède un référentiel de compétence et des documents qui vont être supprimés suite à cette action. Confirmez vous la suppression de cette option ?"
          )
        ) {
          reactHookProps.setValue("options", newOptions);
          reactHookProps.setValue("blocs_competences", newBlocks);
          reactHookProps.setValue("documents", newDocuments);
          reactHookProps.setValue("document_parchemin_attestation", newDocumentsParchemins);
          return false;
        } else {
          return false;
        }
      }
      reactHookProps.setValue("options", newOptions);
    },
    onEditOption: (previousOption: any, newOption: any) => {
      const currentOptions = reactHookProps.watch("options") || [];
      const newOptions = currentOptions.map((opt: any) => {
        if (opt.option == previousOption) {
          opt.option = newOption;
        }
        return opt;
      });

      const newBlocks = tools.updateBlockOptionName(
        reactHookProps.watch("blocs_competences"),
        previousOption,
        newOption
      );

      const newDocuments = tools.updateBlockOptionName(
        reactHookProps.watch("documents"),
        previousOption,
        newOption
      );

      const newDocumentsParchemins = tools.updateBlockOptionName(
        reactHookProps.watch("document_parchemin_attestation"),
        previousOption,
        newOption
      );

      reactHookProps.setValue("options", newOptions);
      reactHookProps.setValue("blocs_competences", newBlocks);
      reactHookProps.setValue("documents", newDocuments);
      reactHookProps.setValue("document_parchemin_attestation", newDocumentsParchemins);
    },
    //discuter du message confirm("") car les deux cas sont possible
    onChangeOptions: (checked: boolean) => {
      if (
        reactHookProps.watch("options")[0] ||
        reactHookProps.watch("blocs_competences")[0] ||
        reactHookProps.watch("document_parchemin_attestation")[0] ||
        reactHookProps.watch("documents")[0]
      ) {
        if (
          window.confirm(
            "Cette certification possède déjà des options ou blocs de competence ou documents. Confirmez vous vouloir modifier les options ?"
          )
        ) {
          reactHookProps.setValue("options", []);
          reactHookProps.setValue("blocs_competences", []);
          reactHookProps.setValue("documents", []);
          reactHookProps.setValue("document_parchemin_attestation", []);
        } else {
          reactHookProps.setValue("avec_options", !checked);
        }
      }
    },
    onChangeBlocks: (blocks: any) => {
      //appelé lors d'un DnD sur les blocs de compétences
      reactHookProps.setValue("blocs_competences", blocks);
    },
    onChangeBlock: (blockItem: any, forOption: any) => {
      //Modifier un des blocs dans la liste
      actions.onChangeBlocks([
        ...conditionnalDisplayVariables.blocs_de_competence.map((b: any) => {
          var areSame = b.uuid == blockItem.uuid;
          if (forOption && areSame) {
            return forOption === b.option ? blockItem : b;
          }
          return areSame ? blockItem : b;
        }),
      ]);
    },
    onAddBlock: (block: any, forOption: any) => {
      //devine
      const newBlock = { ...block, option: forOption || "", est_nouveau_bloc: true };
      actions.onChangeBlocks([...conditionnalDisplayVariables.blocs_de_competence, newBlock]);
    },
    onRemoveBlock: (block: any, forOption: any) => {
      //devine
      actions.onChangeBlocks([
        ...conditionnalDisplayVariables.blocs_de_competence.filter((b: any) => {
          var areSame = b.uuid == block.uuid;
          if (forOption && areSame) {
            return forOption !== b.option;
          }
          return !areSame;
        }),
      ]);
    },
    onCopyCertificationBlocks: (certification: any) => {
      //Lorsqu'on reprend le référentiel d'une autre certif, on copie la liste des blocs de cette certif dans l'actuelle lorsqu'elle est sélectionnée
      // [UPDATE 27/10] et également le nombre de bloc au choix a valider obligatoirement
      if (certification) {
        reactHookProps.setValue("blocs_competences", certification.blocs_competences);
        reactHookProps.setValue(
          "nb_blocs_au_choix_a_valider",
          certification.nb_blocs_au_choix_a_valider
        );
      }
    },
    openAddDocumentModal: (document: any, option: string | null) => {
      modalActions.openModal({
        header: "Détail du document",
        body: (
          <AddDocumentForm
            onAddDocument={(doc: any) => actions.onAddDocument({ ...doc, option })}
            onUpdateDocument={actions.onUpdateDocument}
            document={document}
          />
        ),
      });
    },
    onAddDocument: (document: any) => {
      const documents = reactHookProps.watch("documents") || [];
      reactHookProps.setValue("documents", [...documents, document]);
    },
    onUpdateDocument: (document: any) => {
      const documents = reactHookProps.watch("documents") || [];
      reactHookProps.setValue("documents", [
        ...documents.map((d: any) => (d.boUuid == document.boUuid ? document : d)),
      ]);
    },
    onRemoveDocument: (document: any) => {
      reactHookProps.setValue(
        "documents",
        reactHookProps.watch("documents").filter((d) => d.boUuid !== document.boUuid)
      );
    },
    onUpdateParcheminFile: (newFile: any, option: string | null) => {
      var parcheminRef = tools.findIn(
        reactHookProps.watch("document_parchemin_attestation"),
        option,
        "option"
      ) as any;

      var file = newFile.target.value;
      if (!option) {
        reactHookProps.setValue("document_parchemin_attestation", [{ file, option }]);
        return true;
      }
      if (parcheminRef.file || parcheminRef.file_url) {
        if (file.length === 0) {
          reactHookProps.setValue(
            "document_parchemin_attestation",
            reactHookProps
              .watch("document_parchemin_attestation")
              .filter((doc: any) => doc.option !== option)
          );
        } else {
          reactHookProps.setValue(
            "document_parchemin_attestation",
            reactHookProps
              .watch("document_parchemin_attestation")
              .map((doc: any) => (doc.option == option ? { ...doc, file } : doc))
          );
        }
      } else {
        reactHookProps.setValue("document_parchemin_attestation", [
          ...reactHookProps.watch("document_parchemin_attestation"),
          { option: option, file },
        ]);
      }
    },
    //discuter du message confirm("") car les deux cas sont possible
    //checked ne devrait pas conditionner le changement option blocs_de_competence documents etc...
    onChangeIsUsingExistingReferential: (checked: boolean) => {
      if (
        reactHookProps.watch("reprise_referentiel_uuid") ||
        reactHookProps.watch("options")[0] ||
        reactHookProps.watch("blocs_competences")[0] ||
        reactHookProps.watch("document_parchemin_attestation")[0] ||
        reactHookProps.watch("documents")[0]
      ) {
        if (
          window.confirm(
            "Cette certification possède déjà des options ou blocs de competence ou documents ou un référentiel. Confirmez vous vouloir utiliser/retirer un réferentiel existant ?"
          )
        ) {
          reactHookProps.setValue("reprise_referentiel_uuid", undefined);
          reactHookProps.setValue("avec_options", false);
          reactHookProps.setValue("options", []);
          reactHookProps.setValue("blocs_competences", []);
          reactHookProps.setValue("documents", []);
          reactHookProps.setValue("document_parchemin_attestation", []);
          reactHookProps.setValue("nb_blocs_au_choix_a_valider", "");
          return false;
        } else {
          reactHookProps.setValue("reprise_referentiel_existant", !checked);
          return false;
        }
      }
    },
    onChangeIsUsingBlockInterBranche: (checked: boolean) => {
      if (!checked && reactHookProps.watch("blocs_competences")[0]) {
        if (
          window.confirm(
            "Cette certification possède déjà des blocs de competence. Confirmez vous vouloir utiliser uniquement des blocs de compétence de votre branche ?"
          )
        ) {
          reactHookProps.setValue("blocs_competences", []);
          return false;
        } else {
          reactHookProps.setValue("avec_blocs_inter_branches", !checked);
          return false;
        }
      }
      return false;
    },
  };

  return {
    certification,
    isLoadingCertification: isLoading || isRefetching,
    certificationProps,
    reactHookProps,
    conditionnalDisplayVariables,
    actions,
  };
}

export default useCertificationDetail;
