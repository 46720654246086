import { MessageBar, Stack } from "@fluentui/react";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import Loader from "../../../../Common/Loader/Loader";
import ErrorMessage from "../../../../Common/SelectField/common/ErrorMessage/ErrorMessage";
import { useEffect } from "react";
import { MessageBarStyles } from "../../../../styles";
import useTheme from "../../../../Common/useTheme";
import { useAtom } from "jotai";
import { shouldRefetchCandidatureAtom } from "../ResultatsCandidatureWrapper";

const GenerateDocumentsLink = ({
  isUpdatedDocuments,
  generateDocuments,
}: any) => {
  return (
    <LoadButton
      isLoading={isUpdatedDocuments}
      text="Re-générer tous les documents"
      onClick={generateDocuments}
    />
  );
};

const StatutDocuments = ({
  candidature,
  isUpdatedDocuments,
  actions,
  canSeeDocumentActionButtons,
  statutGeneration,
  checkDocumentGenerationStatus,
  refrechCandidature,
}: any) => {
  const { primaryColor } = useTheme();
  const [shouldRefetchCandidature, setShouldRefetchCandidature] = useAtom(
    shouldRefetchCandidatureAtom
  );

  useEffect(() => {
    if (shouldRefetchCandidature) {
      refrechCandidature();
      setShouldRefetchCandidature(false);
    }
  }, [shouldRefetchCandidature]);

  const generateDocuments = () => {
    actions.postRegenerateDocuments(
      candidature?.uuid,
      checkDocumentGenerationStatus
    );
  };

  let content: any = null;

  switch (statutGeneration) {
    case "GENERATION_EN_COURS":
      content = (
        <>
          <Loader />
          Les documents sont en cours de génération. Ils apparaîtront dans
          quelques instants.
        </>
      );
      break;
    case "GENERATION_ECHOUEE":
      content = (
        <>
          {" "}
          <ErrorMessage errorMessage="Une erreur s'est produite lors de la génération des documents, vous pouvez tenter de re-générer les documents. Si le problème persiste, contactez un administrateur." />
          {canSeeDocumentActionButtons && (
            <GenerateDocumentsLink
              isUpdatedDocuments={isUpdatedDocuments}
              generateDocuments={generateDocuments}
            />
          )}
        </>
      );
      break;
    case "GENERATION_TERMINEE":
      content = canSeeDocumentActionButtons ? (
        <>
          <MessageBar styles={MessageBarStyles}>
            Cliquez sur{" "}
            <span style={{ color: primaryColor }}>
              Re-générer tous les documents
            </span>{" "}
            si vous souhaitez mettre à jour les informations présentes sur les
            documents en lien avec la candidature.
          </MessageBar>
          <GenerateDocumentsLink
            isUpdatedDocuments={isUpdatedDocuments}
            generateDocuments={generateDocuments}
          />
        </>
      ) : null;
      break;
    case "GENERATION_A_FAIRE":
      content = canSeeDocumentActionButtons ? (
        <>
          <MessageBar styles={MessageBarStyles}>
            Cliquez sur{" "}
            <span style={{ color: primaryColor }}>
              Re-générer tous les documents
            </span>{" "}
            si vous souhaitez mettre à jour les informations présentes sur les
            documents en lien avec la candidature.
          </MessageBar>
          <GenerateDocumentsLink
            isUpdatedDocuments={isUpdatedDocuments}
            generateDocuments={generateDocuments}
          />
        </>
      ) : null;
      break;
    default:
      break;
  }
  return (
    <Stack horizontalAlign="center">
      <Stack.Item>{content}</Stack.Item>
    </Stack>
  );
};

export default StatutDocuments;
