import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";

const eCertifApi = axiosInstance;

const companyURI = "administration/entreprises";

export const getCompaniesRequest = async (filters: any, options: any) => {
  const filterType = {
    nom: { type: "string" },
    siret: { type: "string" },
    active: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${companyURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getCompanyRequest = async (companyUuid: any) => {
  const response = await eCertifApi.get(`${companyURI}/${companyUuid}`);
  return response.data;
};

export const postCompanyRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${companyURI}`, data, xsrfHeader);
  return response.data;
};

export const putCompanyRequest = async (company: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${companyURI}/${company.uuid}`,
    company,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateCompanyRequest = async (companyUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${companyURI}/${companyUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateCompanyRequest = async (companyUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${companyURI}/${companyUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteCompanyRequest = async (companyUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${companyURI}/${companyUuid}`,
    xsrfHeader
  );
  return response;
};

export const verifyCompanySIRET = async (siret: string) => {
  const response = await eCertifApi.get(`${companyURI}/existe/${siret}`);
  return response.data;
};

export const getExportCompanies = async (filters: any) => {
  const filterType = {
    nom: { type: "string" },
    siret: { type: "string" },
    active: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/entreprises?paginate=false${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
