const formationAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      ...data,
      niveau_formation: data.niveau_formation?.id,
    };
    return transformed;
  },
  transformForBO: (data: any, constants: any) => {
    let transformed: any = {
      ...data,
    };

    return transformed;
  },
};

export default formationAdapters;
