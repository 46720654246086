import { atom, useAtom } from "jotai";

const isOpenAtom = atom(false);
const contentAtom = atom({
  header: null,
  body: null,
  footer: null,
  isDissmiss: true,
  fullWidth: false,
});

function useCustomModal() {
  const [isOpen, setIsOpen] = useAtom(isOpenAtom);
  const [content, setContent] = useAtom(contentAtom);

  const openModal = (modalContent: any) => {
    document.body.classList.add("modal-open");
    setIsOpen(true);
    setContent({
      header: null,
      body: null,
      footer: null,
      isDissmiss: true,
      onPreviousDissmiss:()=>{},
      onPreviousCloseModal:()=>{},
      ...modalContent,
    });
  };

  const closeModal = () => {
    document.body.classList.remove("modal-open");
    setIsOpen(false);
  };

  return {
    isOpen,
    content,
    actions: {
      openModal,
      closeModal,
    },
  };
}

export default useCustomModal;
