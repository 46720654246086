import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useAtom } from "jotai";
import useSubGroupCompanyMutations from "./useSubGroupCompanyMutations";
import { toast } from "react-toastify";
import subGroupCompanyAdapters from "../../adapters/subGroupCompanyAdapters";

function useSubGroupCompany() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { subGroupCompanyUuid, setSubGroupCompanyUuid, mutations } =
    useSubGroupCompanyMutations();

  const actions = {
    setUpdateSubGroupCompanyUuid: (data: any) => {
      setSubGroupCompanyUuid(data);
    },
    postSubGroupCompany: async (subGroupCompany: any) => {
      mutations.PostSubGroupCompany.mutate(subGroupCompanyAdapters.transformForAPI(subGroupCompany));
    },
    putSubGroupCompany: async (subGroupCompany: any) => {
      mutations.PutSubGroupCompany.mutate(subGroupCompanyAdapters.transformForAPI(subGroupCompany));
    },
    deleteSubGroupCompany: async (subGroupCompanyUuid: any) => {
      toast.info("En cours de développement");
      //mutations.DeleteSubGroupCompany.mutate(subGroupCompanyUuid)
    },
  };

  const {
    isLoading: isLoadingPostSubGroupCompany,
    isError: isErrorPostSubGroupCompany,
    error: errorPostSubGroupCompany,
  } = mutations.PostSubGroupCompany;

  const {
    isLoading: isLoadingPatchSubGroupCompany,
    isError: isErrorPatchSubGroupCompany,
    error: errorPatchSubGroupCompany,
  } = mutations.PutSubGroupCompany;

  return {
    userSelectedBranch,
    subGroupCompanyUuid,
    actions,
    isLoading: isLoadingPostSubGroupCompany || isLoadingPatchSubGroupCompany,
    hasError:isErrorPostSubGroupCompany||isErrorPatchSubGroupCompany,
    errorPostSubGroupCompany,
    errorPatchSubGroupCompany,
  };
}

export default useSubGroupCompany;
