import {
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  TextField,
} from "@fluentui/react";
import { commonInputStyles } from "../../../styles";

interface IDecisionInputsProps {
  choiceGroupeOptions: IChoiceGroupOption[];
  radioLabel: string;
  radioInputName: string;
  textfieldInputLabel?: string;
  textfieldInputName?: string;
  textfieldInputPlaceholder?: string;
  defaultValues?: any;
  onChange: any;
  disabled: boolean;
  competenceCleNonValide?: boolean;
}

function DecisionInputs({
  choiceGroupeOptions,
  radioLabel,
  radioInputName,
  textfieldInputLabel = "",
  textfieldInputName = "",
  textfieldInputPlaceholder = "",
  defaultValues,
  onChange,
  disabled,
  competenceCleNonValide = false,
}: IDecisionInputsProps) {
  /*   const domainStateRadioOptions: IChoiceGroupOption[] = [
      { key: "syndicat_existant", text: "Syndicat existant" },
      { key: "nouveau_syndicat", text: "Nouveau syndicat" },
    ]; */
  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack>
        <ChoiceGroup
          styles={{ flexContainer: { display: "flex", flexWrap: "wrap" } }}
          options={choiceGroupeOptions}
          label={radioLabel}
          required={true}
          name={radioInputName}
          selectedKey={defaultValues?.decision}
          checked={false}
          onChange={(e: any, option: any) =>
            onChange({
              target: {
                name: e.target.name,
                value: option.key,
              },
            })
          }
          disabled={disabled || competenceCleNonValide}
        />
      </Stack>
      {textfieldInputLabel && (
        <Stack>
          <TextField
            styles={commonInputStyles.textField}
            label={textfieldInputLabel}
            placeholder={textfieldInputPlaceholder}
            multiline
            rows={6}
            maxLength={3000}
            name={textfieldInputName}
            value={defaultValues?.commentaire || ""}
            onChange={onChange}
            disabled={disabled}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default DecisionInputs;
