import { atom, useAtom } from "jotai";
import { useState } from "react";
import { toast } from "react-toastify";
import dynamicFieldAdapters from "../../adapters/dynamicFieldAdapters";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import AddFieldForm from "../../Pages/DynamicFields/AddFieldForm/AddFieldForm";
import useAddFieldForm from "../../Pages/DynamicFields/AddFieldForm/useAddFieldForm";
import {
  getDynamicFiedsRequest,
  postDynamicFiedsRequest,
  patchActivateDynamicFieldRequest,
  patchDesactivateDynamicFieldRequest,
  putDynamicFiedsRequest,
} from "../../services/dynamicFieldsServices";
import tools from "../../utils/tools";
import { selectedBranchIdAtom } from "../useBranchEntities";
import { branchAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../branchSettings/useAccessSettings";

export const statuts_candidature = [
  { key: "saisie_informations", text: "Saisie des informations" },
  { key: "positionnement", text: "Positionnement" },
  { key: "avis_formateur", text: "Avis formateur" },
  { key: "evaluation", text: "Évaluation" },
  { key: "avis_second_evaluateur", text: "Seconde évaluation" },
  { key: "decision_jury", text: "Décision du président du jury" },

  //enable later
  // { key: "validation_evaluation", text: "Validation de l'évaluation" },
];

export const dynamicFieldsTypes: any = {
  TEXT: {
    id: "TEXT",
    name: "Champ texte",
    description: "300 caractères max",
  },
  SELECT: {
    id: "SELECT",
    name: "Sélection dans une liste",
    description: "unique ou multiple",
  },

  FILE: {
    id: "FILE",
    name: "Fichier",
    description: "Envoi de fichier avec modèle téléchargeable",
  },
};

const dynamicFieldsAtom = atom([]);

const useDynamicFields = () => {
  const [dynamicFields, setDynamicFields]: any = useAtom(dynamicFieldsAtom);
  const [selectedBranchId] = useAtom(selectedBranchIdAtom);
  const { actions: modalActions } = useCustomModal();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingToggleActifUUID, setIsLoadingToggleActifUUID] =
    useState(null);
  const { actions: addFieldFormActions } = useAddFieldForm();
  const { actions: accessActions } = useAccessSettings();

  const statutsCandidatureDisabled: string[] = [];
  if (
    !accessActions.canI({
      action: branchAccessEnum.ACTIVER_AVIS_FORMATEUR,
    }).granted
  ) {
    statutsCandidatureDisabled.push("avis_formateur");
  }
  const STATUTS_CANDIDATURE_ENABLED = statuts_candidature.filter(
    (s: any) => !statutsCandidatureDisabled.includes(s.key)
  );

  const actions = {
    getFields: async () => {
      const branchDynamicFields = await getDynamicFiedsRequest();

      setDynamicFields(
        dynamicFieldAdapters.transformListForBO(branchDynamicFields)
      );
    },
    saveField: async (newField: any) => {
      setIsLoading(true);
      if (newField.uuid) {
        try {
          const formData = new FormData();
          tools.buildFormData(
            formData,
            dynamicFieldAdapters.transformForAPI(newField),
            null
          );
          const response = await putDynamicFiedsRequest(formData);
          setDynamicFields((dyn: any) =>
            dyn.map((d: any) =>
              d.uuid === newField.uuid
                ? dynamicFieldAdapters.transformForBO(response)
                : d
            )
          );
          addFieldFormActions.onResetNewField();
          setIsLoading(false);
          toast.success("Le champ complémentaire a bien été modifié");
        } catch (e: any) {
          setIsLoading(false);
          toast.error(e.message);
        }
      } else {
        try {
          const formData = new FormData();
          tools.buildFormData(
            formData,
            dynamicFieldAdapters.transformForAPI(newField),
            null
          );
          const response = await postDynamicFiedsRequest(formData);
          setDynamicFields((dyn: any) => [
            ...dyn,
            dynamicFieldAdapters.transformForBO(response),
          ]);
          addFieldFormActions.onResetNewField();
          setIsLoading(false);
          toast.success("Le champ complémentaire a bien été crée");
        } catch (e: any) {
          setIsLoading(false);
          toast.error(e.message);
        }
      }
      actions.closeAddFieldForm();
    },
    activeField: async (field: any) => {
      setIsLoadingToggleActifUUID(field.uuid);
      try {
        await patchActivateDynamicFieldRequest(field.uuid);
        setDynamicFields((dyn: any) =>
          dyn.map((d: any) =>
            d.uuid === field.uuid ? { ...field, actif: true } : d
          )
        );
        setIsLoadingToggleActifUUID(null);
        toast.success("Le champ complémentaire a bien été activé");
      } catch (e: any) {
        setIsLoadingToggleActifUUID(null);
        toast.error(e.message);
      }
    },
    desactiveField: async (field: any) => {
      setIsLoadingToggleActifUUID(field.uuid);
      try {
        await patchDesactivateDynamicFieldRequest(field.uuid);
        setDynamicFields((dyn: any) =>
          dyn.map((d: any) =>
            d.uuid === field.uuid ? { ...field, actif: false } : d
          )
        );
        setIsLoadingToggleActifUUID(null);
        toast.success("Le champ complémentaire a bien été désactivé");
      } catch (e: any) {
        setIsLoadingToggleActifUUID(null);
        toast.error(e.message);
      }
    },
    openAddFieldForm: (field?: any) => {
      modalActions.openModal({
        header: field ? "Modifier un champ" : "Ajouter un nouveau champ",
        body: <AddFieldForm field={field} />,
        onPreviousDissmiss: addFieldFormActions.onResetNewField,
        onPreviousCloseModal: addFieldFormActions.onResetNewField,
      });
    },
    closeAddFieldForm: () => {
      modalActions.closeModal();
    },
  };

  return {
    STATUTS_CANDIDATURE_ENABLED,
    isLoading,
    isLoadingToggleActifUUID,
    dynamicFields,
    actions,
  };
};

export default useDynamicFields;
