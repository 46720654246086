import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

const domaineURI = "administration/domaines";

export const getDomainesRequest = async () => {

    const response = await eCertifApi.get(`${domaineURI}/lister`);

    return response.data;
};

export const getDomaineRequest = async (domaineUuid: any) => {
    const response = await eCertifApi.get(`${domaineURI}/${domaineUuid}`);
    return response.data.data;
};

export const postDomaineRequest = async (data: any) => {
    const xsrfHeader = await getCSRFCookieRequest();

    const response = await eCertifApi.post(`${domaineURI}`, data, xsrfHeader);
    return response.data;
};

export const putDomaineRequest = async (domaine: any) => {
    const xsrfHeader = await getCSRFCookieRequest();

    const response = await eCertifApi.put(`${domaineURI}/${domaine.uuid}`, domaine, xsrfHeader);
    return response.data;
};

export const patchActivateDomaineRequest = async (domaineUuid: any) => {
    const xsrfHeader = await getCSRFCookieRequest();

    const response = await eCertifApi.patch(`${domaineURI}/${domaineUuid}/activer`, xsrfHeader);
    return response.data;
};

export const patchDesactivateDomaineRequest = async (domaineUuid: any) => {
    const xsrfHeader = await getCSRFCookieRequest();

    const response = await eCertifApi.patch(`${domaineURI}/${domaineUuid}/desactiver`, xsrfHeader);
    return response.data;
};

export const deleteDomaineRequest = async (domaineUuid: string) => {
    const xsrfHeader = await getCSRFCookieRequest();

    const response = await eCertifApi.delete(`${domaineURI}/${domaineUuid}`, xsrfHeader);
    return response;
};