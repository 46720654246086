import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;
const demandeHabilitationURI = "administration/demandes-habilitation";

export const getDemandeHabilitationsOfRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    certification: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
    of: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${demandeHabilitationURI}/simple/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getDemandeHabilitationsUtilisateurRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    certification: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
    positionneur: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
    evaluateur: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${demandeHabilitationURI}/stricte/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const postDemandeHabilitationOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${demandeHabilitationURI}/simple`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const postDemandeHabilitationUtilisateurRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${demandeHabilitationURI}/stricte`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const postDemandeHabilitationOfFromPartiePrenanteRequest = async (
  data: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${demandeHabilitationURI}/simple/avec-of`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const postValiderDemandeHabilitationOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${demandeHabilitationURI}/simple/valider`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const patchRefuserDemandeHabilitationOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${demandeHabilitationURI}/simple/refuser`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const postValiderDemandeHabilitationUtilisateurRequest = async (
  data: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.post(
    `${demandeHabilitationURI}/stricte/valider`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const patchRefuserDemandeHabilitationUtilisateurRequest = async (
  data: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${demandeHabilitationURI}/stricte/refuser`,
    data,
    xsrfHeader
  );
  return response.data;
};
