import { axiosInstance } from "../../config/axiosConfig";
import fileDownload from "js-file-download";
import { useState } from "react";
import { toast } from "react-toastify";
import LoadButton from "../LoadButton/LoadButton";
import { AiOutlineCloudDownload } from "react-icons/ai";

const eCertifApi = axiosInstance;

const DownloadFileButton = ({ fileUrl, fileName }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!fileUrl || !fileName) return null;

  const onDownloadFile = async () => {
    setIsLoading(true);
    try {
      const response = await eCertifApi.get(fileUrl, {
        responseType: "blob",
      });

      fileDownload(response.data, fileName);
    } catch (err) {
      toast.error(
        "Une erreur est survenue lors du téléchargement, veuillez réessayer."
      );
    }
    setIsLoading(false);
  };

  return (
    <LoadButton
      isLoading={isLoading}
      text={fileName}
      onClick={onDownloadFile}
      icon={<AiOutlineCloudDownload style={{ marginRight: 10 }} />}
    />
  );
};

export default DownloadFileButton;
