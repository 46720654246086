import { DefaultButton, Stack, Text } from "@fluentui/react";
import Page from "../../../Common/Page/Page";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import BlocsList from "./BlocsList";

const FicheSynthetique = ({ isShown, certification, setIsShownTo }: any) => {
    const { labels } = useLabelSettings();
    if (!isShown) return <></>;
    return (
      <Stack
        styles={{
          root: {
            minWidth: "100%",
            minHeight: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "#fff",
            padding: "5% 20%",
            overflowY: "scroll",
          },
        }}
      >
        <Page
          title="Fiche synthétique de la certification"
          explain="Veuillez trouver un résumé des informations de la certification"
        >
          <Stack tokens={{ childrenGap: 30 }}>
            <Stack>
              <Text variant="xLargePlus">{certification.title}</Text>
              <Text style={{ color: "#6F6F6F" }}>{certification.description}</Text>
            </Stack>
  
            <Stack tokens={{ childrenGap: 15 }}>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="large">Type de certification : </Text>
                <Text variant="medium">{certification.type}</Text>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="large">Enregistrement : </Text>
                <Text variant="medium">{certification.enregistrement}</Text>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="large">Numéro d'enregistrement : </Text>
                <Text variant="medium">{certification.numero_enregistrement}</Text>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="large">
                  {labels.CERTIFICATION_CANDIDAT_CERTIFICATION_NBRBLOCSOBTENTION} :{" "}
                </Text>
                <Text variant="medium">{certification.blocs_necessaire}</Text>
              </Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="large">Nombre de blocs sur lesquels je vais être certifié : </Text>
                <Text variant="medium">{certification.blocs_certifie}</Text>
              </Stack>
            </Stack>
            <BlocsList type={"Blocs imposés"} list={certification.blocs_imposes} />
            <BlocsList type={"Blocs au choix"} list={certification.blocs_choix} />
            <Stack horizontalAlign="end">
              <Stack>
                <DefaultButton text={"Fermer"} onClick={() => setIsShownTo(false)} />
              </Stack>
            </Stack>
          </Stack>
        </Page>
      </Stack>
    );
  };

  export default FicheSynthetique;