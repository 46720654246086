import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { branchAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { commonInputStyles } from "../../../styles";

function MyStructureEntrepriseForm({ reactHookProps }: any) {
  const { actions: accessActions } = useAccessSettings();

  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = reactHookProps;
  return (
    <FormSection sectionTitle="Informations générales">
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Saisissez la raison sociale de la structure"
              type="text"
              {...field}
              placeholder="Raison sociale"
              errorMessage={(errors.nom as any)?.message}
              required
            />
          )}
          defaultValue=""
          control={control}
          name="nom"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { onChange, value } }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Saisissez le numéro SIRET"
              type="text"
              placeholder="N° SIRET à 14 chiffres sans espace"
              errorMessage={(errors.siret as any)?.message}
              required
              disabled={true}
              value={value}
              onChange={(e: any, newValue: any) => {
                if (!newValue || newValue.length <= 14) {
                  onChange(e.target.value);
                }
              }}
            />
          )}
          defaultValue=""
          control={control}
          name="siret"
        />
      </Stack.Item>

      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Sélectionnez un IDCC :"
              placeholder="Recherchez un IDCC en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              renderValue={(item: any) =>
                item.idcc + " | " + item.titre_convention
              }
              dataIndex="id"
              name={name}
              value={value}
              fieldRef={ref}
              required={
                !accessActions.canI({
                  action:
                    branchAccessEnum.RENDRE_IDCC_FACULTATIF_SUR_LES_ENTREPRISES,
                }).granted
              }
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={null}
              errorMessage={(errors.ref_idcc as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="ref_idcc"
        />
      </Stack.Item>

      <FormAdress
        control={control}
        errors={errors}
        varToWatch={watch("code_postal") ?? false}
        required={true}
        onPrefillCityAndRegion={(data: any) => {
          setValue("ville", data.nom_commune);
          setValue("region", data.nom_region);
        }}
      />
    </FormSection>
  );
}

export default MyStructureEntrepriseForm;
