import { Stack, DefaultButton, TextField, Dropdown } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useCompetenceList from "../useCompetenceList";
import { commonInputStyles, commonButtonStyles } from "../../../styles/index";

import { stateList } from "../../../fakeData/State.data";
import { API_URL } from "../../../config/config";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";

function CompetenceListFilters() {
  const { competences, isLoadingCompetences, actions, reactHookProps } =
    useCompetenceList();
  const { branches } = useConstants();
  const { handleSubmit, control, setValue } = reactHookProps;

  const branchesList = tools.addKeyToObjectInArray(
    [{ id: 0, nom: "Toutes les branches", value: undefined }, ...branches],
    "id"
  );

  const totalCompetences = competences?.meta.total || 0;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack style={{ marginBottom: 24 }} horizontalAlign="start" horizontal wrap>
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="libelle"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Libellé de la compétence :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Compétence active :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionner un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="active"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Compétence clé :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionner un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="competence_cle"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              control={control}
              name="branche_creatrice"
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Branche créatrice de la compétence :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="Sélectionnez une branche"
                  options={branchesList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) =>
                    setValue(name, options, { shouldValidate: true })
                  }
                  onRenderOption={(option: any) => option.nom}
                  onRenderTitle={(option: any) => option[0].nom}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item className="ms-Grid-col ms-sm12" style={{ paddingRight: 20, marginTop: 20 }}>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectManyFieldAsync
                  label="Blocs rattachés à la compétence :"
                  placeholder="Recherchez un bloc de compétences en tapant les premières lettres de son nom"
                  resourceURI={`${API_URL}api/administration/certifications/bloc-competences`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}/chercher?libelle=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) => item.libelle}
                  renderValue={(item: any) => item.libelle}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={{}}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="blocs_rattaches"
            />
          </Stack.Item>
        </Stack>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="end" wrap>
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingCompetences} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalCompetences} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default CompetenceListFilters;
