import {
  DatePicker,
  DefaultButton,
  Label,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useState } from "react";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import { DateTime } from "luxon";
import DynamicList from "../../../../Common/DynamicList/DynamicList";
import { strings } from "../../../../config/datePickerConfig";
import { API_URL } from "../../../../config/config";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import tools from "../../../../utils/tools";

const AddExperienceForm = ({
  onAddExperience,
  experience: initialExperience,
  isProfessionel,
  inputsDisabled
}: any) => {
  const { actions } = useCustomModal();

  const [experience, setExperience] = useState(
    initialExperience
      ? {
        ...initialExperience,
        date_debut: DateTime.fromISO(initialExperience.date_debut).toUTC().toISO(),
        date_fin: DateTime.fromISO(initialExperience.date_fin).toUTC().toISO(),
      }
      : {
        intitule: "",
        raison_sociale: "",
        missions: [],
        responsabilites: [],
        poste_actuel: false,
        date_debut: undefined,
        date_fin: undefined,
        pays: "",
      }
  );

  const handleChangeExperience = (e: any) => {
    const { name, value } = e.target;
    let newExperience = { ...experience, [name]: value };
    if (name == "poste_actuel" && value) {
      newExperience.date_fin = undefined;
    }
    setExperience(newExperience);
  };

  const onSaveExperience = () => {
    onAddExperience(experience, isProfessionel);
  };

  return (
    <Stack
      style={{ ...formSectionStyles.formContainer, minWidth: 400, paddingBottom: 12 }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="intitule"
          label="Intitulé du poste occupé :"
          type="text"
          placeholder="Ex: Electricien en chef"
          onChange={handleChangeExperience}
          required
          value={experience.intitule}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          name="raison_sociale"
          label="Raison sociale :"
          type="text"
          placeholder="Ex: Mon entreprise"
          onChange={handleChangeExperience}
          value={experience.raison_sociale}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <DynamicList
          label="Missions principales :"
          placeholder="Ex : Mission"
          addButtonLabel="Ajoutez cette mission"
          name="missions"
          value={experience.missions || []}
          onChange={(e: any) => {
            handleChangeExperience({ target: { name: "missions", value: e.target.value } });
          }}
          defaultValue={{ libelle: "" }}
          attr="libelle"
          validateItem={(i: any) => i}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <DynamicList
          label="Responsabilités :"
          placeholder="Ex : Responsabilité"
          addButtonLabel="Ajoutez cette responsabilité"
          name="responsabilites"
          value={experience.responsabilites || []}
          onChange={(e: any) => {
            handleChangeExperience({ target: { name: "responsabilites", value: e.target.value } });
          }}
          defaultValue={{ libelle: "" }}
          attr="libelle"
          validateItem={(i: any) => i}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item>
        <Label htmlFor="date_debut" required styles={commonInputStyles.label}>
          Date de début :
        </Label>
        <DatePicker
          formatDate={(date?: any): any =>
            date ? `${tools.reformateDateFromJsDate(date)}` : null
          }
          strings={strings}
          showGoToToday={false}
          maxDate={new Date()}
          value={experience.date_debut ? new Date(experience.date_debut) : undefined}
          styles={commonInputStyles.dataPicker}
          onSelectDate={(date: any) => {
            if(date){
              handleChangeExperience({
                target: { name: "date_debut", value: DateTime.fromJSDate(date).set({hour:12}).toUTC().toISO() },
              });
            }
          }}
          disabled={inputsDisabled}
          placeholder="dd/mm/yyyy"
          allowTextInput
          parseDateFromString={(dateStr: any) => tools.parseDateFromStringDatePicker(dateStr)}
        />
      </Stack.Item>
      <Stack.Item>
        <Label htmlFor="poste_actuel" required styles={commonInputStyles.label}>
          S'agit-il du poste occupé actuellement ?
        </Label>
        <Toggle
          styles={commonInputStyles.toggleInput}
          onText="Oui"
          offText="Non"
          onChange={(e, checked): any =>
            handleChangeExperience({ target: { name: "poste_actuel", value: checked } })
          }
          checked={experience.poste_actuel}
          disabled={inputsDisabled}
        />
      </Stack.Item>
      {!experience.poste_actuel && (
        <Stack.Item>
          <Label htmlFor="date_fin" required styles={commonInputStyles.label}>
            Date de fin :
          </Label>
          <DatePicker
            formatDate={(date?: any): any =>
              date ? `${tools.reformateDateFromJsDate(date)}` : null
            }
            strings={strings}
            showGoToToday={false}
            maxDate={new Date()}
            value={experience.date_fin ? new Date(experience.date_fin) : undefined}
            styles={commonInputStyles.dataPicker}
            onSelectDate={(date: any) => {
              if(date){
                handleChangeExperience({
                  target: { name: "date_fin", value: DateTime.fromJSDate(date).set({hour:12}).toUTC().toISO() },
                });
              }
            }}
            disabled={inputsDisabled}
            placeholder="dd/mm/yyyy"
            allowTextInput
            parseDateFromString={(dateStr: any) => tools.parseDateFromStringDatePicker(dateStr)}
          />
        </Stack.Item>
      )}

      <Stack.Item>
        <SelectFieldAsync
          label="Pays :"
          placeholder="Recherchez un pays en tapant les premières lettres"
          resourceURI={`${API_URL}api/references/ref-pays/chercher-pays`}
          addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?recherche=${terms}`}
          method="GET"
          renderItem={(item: any) => item.nom}
          renderValue={(item: any) => item}
          dataIndex="id"
          value={experience.pays}
          onChange={(e: any) =>
            handleChangeExperience({ target: { name: "pays", value: e.target.value.nom } })
          }
          defaultValue=""
          //errorMessage={(errors.code_postal as any)?.message}
          handleResponse={(response: any, terms: any) => {
            return response.data.data.length > 0 ? response.data.data : [{ nom: terms }];
          }}
          disabled={inputsDisabled}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton styles={commonButtonStyles.defaultButton} onClick={actions.closeModal}>
          Annuler
        </DefaultButton>
        {!inputsDisabled &&
          <PrimaryButton
            onClick={onSaveExperience}
            styles={commonButtonStyles.buttonSubmit}
            iconProps={{ iconName: "Add" }}
          >
            Ajouter l'expérience
          </PrimaryButton>
        }
      </Stack.Item>
    </Stack>
  );
};

export default AddExperienceForm;
