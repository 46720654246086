import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  MessageBar,
  PrimaryButton,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { useState } from "react";
import { API_URL } from "../../config/config";
import { commonButtonStyles, MessageBarStyles } from "../../styles";
import LoadButton from "../LoadButton/LoadButton";
import useCustomModal from "../Overlays/CustomModal/useCustomModal";
import SelectManyFieldAsync from "../SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import useTheme from "../useTheme";
import { useQuery } from "react-query";
import tools from "../../utils/tools";
import {
  getCandidaturesRequest,
  getWaitingCandidatureRequest,
  patchModifyCandidatureJuryRequest,
} from "../../services/candidatureService";
import { atom, useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import SelectFieldAsync from "../SelectField/SelectFieldAsync/SelectFieldAsync";
import useJury from "../../hooks/jury/useJury";
import { toast } from "react-toastify";

const filtersAtom = atom<any>({
  statut: { id: "inscription_jury", key: "inscription_jury" },
});

const optionsFiltersAtom = atom({
  sort: "",
  paginate: false,
  searchAt: "",
});

function InscriptionCandidaturesJuryModal({ jury, onSaved }: any) {
  const { actions } = useCustomModal();
  const [filters] = useAtom(filtersAtom);
  const [optionsFilters] = useAtom(optionsFiltersAtom);
  const [selectedCandidatures, setSelectedCandidatures]: any = useState([]);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: juryActions } = useJury();

  const { data: candidatures } = useQuery(
    ["candidatures", optionsFilters, userSelectedBranch],
    () => getCandidaturesRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const onCheckCandidature = (e: any, candidature: any) => {
    if (selectedCandidatures.includes(candidature.uuid)) {
      setSelectedCandidatures((s: any) =>
        s.filter((o: any) => o != candidature.uuid)
      );
    } else {
      setSelectedCandidatures((s: any) => [...s, candidature.uuid]);
    }
  };

  const onSaveJury = async () => {
    try {
      const candidatures = { candidatures: selectedCandidatures };
      const res = await juryActions.putJuryAddCandidats(jury, candidatures);
      onSaved(selectedCandidatures);
      actions.closeModal();
    } catch (err: any) {
      toast.error("Une erreur est survenue");
    }
  };

  const items =
    candidatures?.data?.filter(
      (c: any) => c.statut == "inscription_jury" && !c.jury
    ) || [];

  const columns = [
    {
      key: "1",
      name: "Candidat",
      fieldName: "candidat",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,

      onRender: (item: any) => <Text>{tools.getFullname(item.candidat)}</Text>,
    },
    {
      key: "2",
      name: "Certification",
      fieldName: "certification",
      minWidth: 250,
      maxWidth: 350,
      isResizable: true,

      onRender: (item: any) => (
        <Text>
          {item.certification.libelle} - {item.certification.type_libelle}
        </Text>
      ),
    },

    {
      key: "4",
      name: "Sélectionner",
      fieldName: "actions",
      minWidth: 100,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => {
        return (
          <Stack horizontal wrap>
            <Checkbox
              name={`chx${item.uuid}`}
              checked={selectedCandidatures.includes(item.uuid)}
              onChange={(e: any) => {
                onCheckCandidature(e, item);
              }}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Text style={{ color: "#979797" }}>
        Veuillez inscrire des candidatures au jury de certification
      </Text>

      <SelectFieldAsync
        label="Jury sélectionné :"
        placeholder=""
        resourceURI={`${API_URL}api/candidature/jurys/chercher-disponible-inscription-candidature`}
        addQueryURL={(resourceURI: any, terms: any) =>
          `${resourceURI}?libelle=${terms}`
        }
        method="GET"
        renderItem={(item: any) =>
          `${item.libelle} - ${tools.formatDate(item.date_jury)} - ${
            item.nombre_de_places_restantes
          } places restantes`
        }
        renderValue={(item: any) =>
          `${item.libelle} - ${tools.formatDate(item.date_jury)} - ${
            item.nombre_de_places_restantes
          } places restantes`
        }
        dataIndex="uuid"
        // onChange={(e: any) => {
        //     setValue(name, e.target.value, { shouldValidate: true });
        // }}
        defaultValue={{}}
        value={jury}
        handleResponse={(response: any) => [...response.data.data]}
        disabled={jury ? true : false}
      />

      <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
        <Stack.Item>
          <LoadButton isLoading={false} text="Rechercher" />
        </Stack.Item>
        <Stack.Item>
          <DefaultButton
            styles={commonButtonStyles.defaultButton}
            text="Réinitialiser ma recherche"
            //onClick={}
          />
        </Stack.Item>
      </Stack>

      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={items}
        columns={columns}
        selectionMode={SelectionMode.none}
      />

      {/* {selectedJury && (
        <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
          <MessageBar styles={MessageBarStyles}>
            Inscription de <span style={{ color: primaryColor }}>{candidatures.length}</span>{" "}
            candidature(s) au jury «{" "}
            <span style={{ color: primaryColor }}>{selectedJury.libelle}</span> » du «{" "}
            <span style={{ color: primaryColor }}>{tools.formatDate(selectedJury.date_jury)}</span>{" "}
            »
          </MessageBar>
        </Stack.Item>
      )} */}

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>
        {selectedCandidatures.length > 0 && (
          <PrimaryButton
            onClick={onSaveJury}
            styles={commonButtonStyles.buttonSubmit}
            iconProps={{ iconName: "Add" }}
          >
            Valider l’inscription des candidatures au jury sélectionné
          </PrimaryButton>
        )}
      </Stack.Item>
    </Stack>
  );
}

export default InscriptionCandidaturesJuryModal;
