import { Stack, Text } from "@fluentui/react";

const BlocsList = ({ type, list }: any) => {
  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Text variant="large">{type}</Text>
      <ul style={{ listStyle: "none", paddingLeft: 0 }}>
        {list.map((e: any, i: any) => (
          <li
            key={i}
            style={{
              marginBottom: 10,
              padding: "10px 0",
              boxSizing: "border-box",
              borderBottom: `1px solid #6F6F6F`,
            }}
          >
            <Stack>
              <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 15 }}>
                <Text variant="medium">{e.title}</Text>

                {e.selectionne && (
                  <Text
                    styles={{
                      root: {
                        width: "120px",
                        backgroundColor: "#0aa204",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "6px",
                        padding: "1px 0",
                      },
                    }}
                  >
                    sélectionné
                  </Text>
                )}
              </Stack>
              <Text styles={{ root: { color: "#6F6F6F" } }}>{e.description} </Text>
            </Stack>
          </li>
        ))}
      </ul>
    </Stack>
  );
};

export default BlocsList;