import { Stack, DefaultButton, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";

import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import useStaticPageList, { StaticPageContext } from "../useStaticPageList";
import { useContext } from "react";

function StaticPageListFilters() {
  const { type }: any = useContext(StaticPageContext);
  const { staticPages, isLoadingStaticPages, actions, reactHookProps } =
    useStaticPageList(type);

  const totalPages = staticPages?.meta.total || 0;

  const { handleSubmit, control } = reactHookProps

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilter)();
  };

  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilter)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="titre"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Titre de la page :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack horizontal wrap tokens={{ childrenGap: 15 }}>
            <Stack.Item>
              <LoadButton isLoading={isLoadingStaticPages} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                styles={commonButtonStyles.defaultButton}
                onClick={onReset}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalPages} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default StaticPageListFilters;
