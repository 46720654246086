import tools from "../utils/tools";

const branchAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      ...data,
      ref_idccs: data.ref_idccs.map((item: any) => item.id),
      couleur_primaire: data.couleur_primaire.color,
      admin: {
        ...data.admin,
        uuid: null,
      },
    };

    if (data.adresse || data.code_postal || data.region || data.ville) {
      transformed.adresse = {
        uuid: data.adresse_uuid,
        adresse: data.adresse,
        complement_adresse: data.complement_adresse,
        code_postal: data.code_postal,
        region: data.region,
        ville: data.ville,
      };
    }

    return transformed;
  },
  transformForBO: (data: any, colorsList: any) => {
    let transformed: any = {
      ...data,
      couleur_primaire: tools.findIn(
        colorsList,
        data?.couleur_primaire,
        "color"
      ),
      adresse_uuid: data.adresse?.uuid,
      adresse: data.adresse?.adresse,
      complement_adresse: data.adresse?.complement_adresse,
      code_postal: data.adresse?.code_postal,
      region: data.adresse?.region,
      ville: data.adresse?.ville,
    };

    return transformed;
  },
};

export default branchAdapters;
