import { FontIcon } from "@fluentui/react";

const SyncItem = ({ qty, text, color, icon, showText, style = {} }: any) => {
  return (
    <div
      style={{
        margin: "1px 4px",
        color,
        display: "flex",
        alignItems: "center",
        //justifyContent: "flex-end",
        gap: 2,
        background: showText ? "" : "#fff",
        borderRadius: "6px",
        padding: "0px 4px",
        border: showText ? "" : "1px solid rgb(217 217 217)",
        ...style,
      }}
      title={text}
    >
      {icon && <FontIcon iconName={icon} style={{ marginRight: 5 }} />}
      {showText && <div>{text} </div>}
      {qty && <strong style={{ marginLeft: 3 }}>{qty}</strong>}
    </div>
  );
};

export default SyncItem;
