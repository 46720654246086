import React, { useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "../../config/axiosConfig";
import useUserMutations from "../../hooks/user/useUserMutations";

export const StructureContext = React.createContext({});

export enum StructureType {
  "ENTREPRISE" = "entreprises",
  "OF" = "ofs",
  "GROUPE_ENTREPRISE" = "groupe-entreprises",
  "GROUPE_OF" = "groupe-ofs",
}

const urlCommun = "administration";

interface IStructureProdvider {
  structureType: StructureType;
  structureUuid: string;
  referent: [];
  users: [];
  structureRefetch: any;
  children: any;
}

export const StructureProvider = ({
  structureType,
  structureUuid,
  users = [],
  referent,
  structureRefetch = () => {},
  children,
}: IStructureProdvider) => {
  const { mutations, isToggleActivationUuid, setIsToggleActivationUuid } =
    useUserMutations();
  const [isToggleReferentUuid, setIsToggleReferentUuid] = useState(null);

  const referentUuidList = referent?.map((user: any) => user.uuid);
  const usersList = users.map((user: any) =>
    referentUuidList?.includes(user.uuid) ? { ...user, isReferent: true } : user
  );

  const canHaveMoreReferent =
    structureType === StructureType.ENTREPRISE ||
    structureType === StructureType.OF
      ? referentUuidList?.length < 1
      : true;

  const actions = {
    thenAction: () => {
      structureRefetch();
    },
    makeUrl: (): string => {
      return `${urlCommun}/${structureType}/${structureUuid}/`;
    },
    toggleUserActivation: async (user: any) => {
      setIsToggleActivationUuid(user.uuid);
      const options = {
        onSuccess: () => {
          actions.thenAction();
        },
      };
      if (user.actif_sur_branche_courante) {
        mutations.ToggleUserDesactivate.mutate(user.uuid, options);
      } else {
        mutations.ToggleUserActivate.mutate(user.uuid, options);
      }
    },
    toggleUserReferent: async (user: any) => {
      setIsToggleReferentUuid(user.uuid);
      const completeUrl = user.isReferent
        ? `${actions.makeUrl()}supprimer-referent`
        : `${actions.makeUrl()}ajouter-referent`;
      await axiosInstance
        .patch(completeUrl, {
          utilisateur: user.uuid,
        })
        .then(() => {
          actions.thenAction();
        })
        .catch((err: any) => {
          const errorData = err.response?.data;
          if (errorData?.message) {
            toast.error(err.response.data.message);
          }
        });
      setIsToggleReferentUuid(null);
    },
  };

  return (
    <StructureContext.Provider
      value={{
        users: usersList,
        actions,
        isToggleActivationUuid,
        isToggleReferentUuid,
        canHaveMoreReferent,
        structureType,
      }}
    >
      {children}
    </StructureContext.Provider>
  );
};
