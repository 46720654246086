import {
  Stack,
  DefaultButton,
  TextField,
  Dropdown,
  DatePicker,
} from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useJuryListPage from "../useJuryListPage";
import { commonInputStyles, commonButtonStyles } from "../../../styles/index";

import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import { strings } from "../../../config/datePickerConfig";
import useConstants from "../../../hooks/useConstants";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { useState } from "react";
import tools from "../../../utils/tools";

function JuryListFilters() {
  const { jurys, actions, isLoadingJurys, reactHookProps } = useJuryListPage();
  const { labels } = useLabelSettings();
  const { constants } = useConstants();
  const [showFilters, setShowFilters] = useState(false);

  const totalJurys = jurys?.meta?.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  const juryStateListSort = [
    { id: undefined, text: "Tous", key: "all" },
    ...constants.JURY.STATUTS,
  ];

  const datePickerFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return (
    <FormSection sectionTitle={labels.JURY_FILTRE}>
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  ariaLabel="dd/mm/yyyy"
                  value={value ? new Date(value) : undefined}
                  onSelectDate={(date: any) =>
                    date
                      ? setValue(
                          name,
                          `${date.getFullYear()}-${
                            date.getMonth() + 1
                          }-${date.getDate()}`
                        )
                      : null
                  }
                  styles={commonInputStyles.textField}
                  label="Date du jury entre le"
                  strings={strings}
                  formatDate={(date: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              defaultValue={undefined}
              control={control}
              name="date_debut"
            />
          </Stack.Item>
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <DatePicker
                  placeholder="dd/mm/yyyy"
                  ariaLabel="dd/mm/yyyy"
                  styles={commonInputStyles.textField}
                  value={value ? new Date(value) : undefined}
                  onSelectDate={(date: any) =>
                    date
                      ? setValue(
                          name,
                          `${date.getFullYear()}-${
                            date.getMonth() + 1
                          }-${date.getDate()}`
                        )
                      : null
                  }
                  label="Et le"
                  strings={strings}
                  formatDate={(date: any): any =>
                    date ? `${tools.reformateDateFromJsDate(date)}` : null
                  }
                  allowTextInput
                  parseDateFromString={(dateStr: any) =>
                    tools.parseDateFromStringDatePicker(dateStr)
                  }
                />
              )}
              defaultValue={undefined}
              control={control}
              name="date_fin"
            />
          </Stack.Item>
          {showFilters && (
            <>
              <Stack.Item
                align="start"
                className="ms-Grid-col ms-sm12 ms-lg6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  name="libelle"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Libellé du jury :"
                      styles={commonInputStyles.textField}
                      type="text"
                      {...field}
                    />
                  )}
                />
              </Stack.Item>

              <Stack.Item
                align="start"
                className="ms-Grid-col ms-sm12 ms-lg6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="Statut du jury :"
                      selectedKey={value ? value.key : undefined}
                      placeholder="..."
                      options={juryStateListSort}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={juryStateListSort[0]}
                  control={control}
                  name="statut"
                />
              </Stack.Item>
            </>
          )}
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingJurys} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalJurys} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default JuryListFilters;
