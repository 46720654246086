import { useAtom } from "jotai";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useAuth from "../../../hooks/useAuth";
import { branchEntitiesAtom } from "../../../hooks/useBranchEntities";
import tools from "../../../utils/tools";
import { branchRolesSettingsAtom } from "../../RolesManagement/useRolesManagement";
import { AddEntrepriseForm } from "./AddEntrepriseFormModal/AddEntrepriseFormModal";
import { AddOfForm } from "./AddOfFormModal/AddOfFormModal";
import AddUserFormModal from "./AddUserFormModal/AddUserFormModal";
import useDemandeHabilitation from "../../../hooks/demandeHabilitation/useDemandeHabilitation";
import { IdentifyEvaluateurLaterModal } from "./IdentifyEvaluateurLaterModal/IdentifyEvaluateurLaterModal";

export const PartiesPrenantesContext = React.createContext({});
export const PartiesPrenantesProvider = ({ value, children }: any) => {
  return (
    <PartiesPrenantesContext.Provider value={value}>
      {children}
    </PartiesPrenantesContext.Provider>
  );
};

function usePartiesPrenantes() {
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const [branchRolesSettings]: any = useAtom(branchRolesSettingsAtom);
  const { candidature, actions: candidatureActions } = useCandidature();
  const { actions: modalActions } = useCustomModal();
  const { auth }: any = useAuth();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const { actions: accessActions } = useAccessSettings();
  const [isSaving, setIsSaving] = useState(false);
  const [branchEntities] = useAtom<any>(branchEntitiesAtom);
  const { actions: actionsDemandeHabilitation } = useDemandeHabilitation();

  const reactHookProps = useForm<any>({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api.

  const conditionnalDisplayVariables = {
    //profil de l'utilisateur qui modifie cette page
    userProfilEntreprise: [
      "ENTREPRISE",
      "GROUPE_ENTREPRISE",
      "SOUS_GROUPE_ENTREPRISE",
    ].some((r: any) => userSelectedBranch.profils.includes(r)),
    userProfilOf: ["OF", "GROUPE_OF", "SOUS_GROUPE_OF"].some((r: any) =>
      userSelectedBranch.profils.includes(r)
    ),
    //possibiliter d'ajouter un evaluateur/positionneur (soi-même ou une autre personne)
    positionneurTier:
      branchRolesSettings.find(
        (r: any) =>
          r.acces == "SAISIR_POSITIONNEMENT" &&
          r.est_accessible &&
          r.est_parametrable
      ) && candidature.positionnement_par_candidat,
    evaluateurTier: true,
    //possibiliter d'ajouter un evaluateur/positionneur autre que soi-même
    showPositionneur: true,
    showEvaluateur:
      !accessActions.canI({
        action:
          branchAccessEnum.CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION,
      }).granted || userSelectedBranch.gestion_par_domaine,
    //si possibilite ajouter/creer une entite non visible/existante sur la branche
    renseigneOfInexistante: true,
    renseigneEntrepriseInexistante: true,
    //si possibilite ajouter un nouveau utilisateur OF
    renseigneUtilisateurOf: false,
    cantUpdateThisCandidature: tools.checkRightToUpdateCandidature(
      candidature,
      auth.user,
      userSelectedBranch.profils,
      branchEntities
    ),
  };

  const actions = {
    openOfFormModal: () => {
      modalActions.openModal({
        header: "Ajouter un Organisme de Formation",
        body: (
          <AddOfForm
            onAddOf={(of: any) => reactHookProps.setValue("of_lie", of)}
            onCreateOfWithHabilitation={(of: any) =>
              actions.onCreateOfWithHabilitation(of)
            }
          />
        ),
      });
    },
    openEntrepriseFormModal: () => {
      modalActions.openModal({
        header: "Ajouter une Entreprise",
        body: (
          <AddEntrepriseForm
            onAddEntreprise={(entreprise: any) =>
              reactHookProps.setValue("entreprise_liee", entreprise)
            }
          />
        ),
      });
    },
    openUserFormModal: (role: any) => {
      modalActions.openModal({
        header: `Ajouter un ${role}`,
        body: (
          <AddUserFormModal
            role={role}
            onAddUser={(user: any) =>
              reactHookProps.setValue(`${role.toLowerCase()}`, user)
            }
          />
        ),
      });
    },
    openIdentifyEvaluateurLaterModal: () => {
      modalActions.openModal({
        header: "Identifier l'évaluateur jusqu'à l'étape d'évaluation",
        body: (
          <IdentifyEvaluateurLaterModal
            onConfirmActions={actions.onValidateIdentifyEvaluateurLater}
          />
        ),
      });
    },
    onValidateIdentifyEvaluateurLater: () => {
      reactHookProps.setValue("evaluateur_inconnu", true);
    },
    addMeAs: (role: any) => {
      const meObject = {
        uuid: auth.user?.uuid,
        nom: auth?.user.nom,
        prenom: auth?.user.prenom,
      };
      reactHookProps.setValue(`${role}`, meObject);
    },
    onSave: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidature(
        data,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
    onCreateOfWithHabilitation: async (data: any) => {
      const demandeHabilitationData = {
        of: {
          ...data,
          documents: null,
          adresse: {
            adresse: data.adresse,
            complement_adresse: data.complement_adresse,
            code_postal: data.code_postal,
            region: data.region,
            ville: data.ville,
          },
          entreprises: candidature?.entreprise_liee
            ? [candidature.entreprise_liee.uuid]
            : null,
          groupe_of: data.groupe_of?.uuid ?? "",
        },
        certification: candidature.certification.uuid,
        documents: data?.documents?.map((d: any) => ({
          ...d,
          file: d.file[0],
        })),
      };

      console.log("data", demandeHabilitationData);

      const formData = new FormData();
      tools.buildFormData(formData, demandeHabilitationData, null);

      actionsDemandeHabilitation.onDemandeHabilitationOfFromPartiePrenante(
        formData
      );
    },
  };

  return {
    branchEntities,
    userSelectedBranch,
    isSaving,
    candidature,
    conditionnalDisplayVariables,
    actions,
    reactHookProps,
  };
}

export default usePartiesPrenantes;
