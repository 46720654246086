import tools from "../utils/tools";

const juryAdapters = {
  transformForAPI: (data: any) => {
    let transformed: any = {
      _method: data._method,
      libelle: data.libelle,
      date_jury: `${data.date_jury?.getFullYear()}-${
        data.date_jury?.getMonth() + 1
      }-${data.date_jury?.getDate()}`,
      date_limite_inscription_candidat: `${data.date_limite_inscription_candidat?.getFullYear()}-${
        data.date_limite_inscription_candidat?.getMonth() + 1
      }-${data.date_limite_inscription_candidat?.getDate()}`,
      date_acces_membres_jury: `${data.date_acces_membres_jury?.getFullYear()}-${
        data.date_acces_membres_jury?.getMonth() + 1
      }-${data.date_acces_membres_jury?.getDate()}`,
      nombre_places: parseInt(data.nombre_places),
      president_jury_uuid: data.president_jury_uuid?.uuid,
      membres: data.membres?.map((e: any) => e.uuid),
      vice_president_jury_uuid: data.vice_president_jury_uuid?.uuid,
      documents:
        data.documents?.map((e: any) => {
          return { file_name: e };
        }) ?? [],
      commentaire: data.commentaire ?? "",
    };

    if (data.lieu || data.code_postal) {
      transformed.adresse = {};
      if (data.adresse_uuid) {
        transformed.adresse.uuid = data.adresse_uuid;
      }
      transformed.adresse.adresse = data.lieu;
      transformed.adresse.code_postal = data.code_postal;
      transformed.adresse.region = data.region;
      transformed.adresse.ville = data.ville;
    }

    if (data.uuid) {
      transformed.uuid = data.uuid;
    }

    if (data.documents_info) {
      const documentsNotRemoved = data.documents_url;
      const documentsRemoved = data.documents_info.filter(
        (d: any) => !documentsNotRemoved.includes(d.file_url)
      );
      if (documentsRemoved) {
        const documentsDeleted = documentsRemoved.map((d: any) => ({
          uuid: d.uuid,
          deleted: true,
        }));
        transformed.documents = [...transformed.documents, ...documentsDeleted];
      }
    }

    return transformed;
  },

  transformForBO: (data: any, juryConstants: any) => {
    let transformed: any = {
      ...data,
      date_jury: new Date(data.date_jury),
      date_limite_inscription_candidat: new Date(
        data.date_limite_inscription_candidat
      ),
      date_acces_membres_jury: new Date(data.date_acces_membres_jury),
      nombre_places: data.nombre_places.toString(),
      adresse_uuid: data.adresse?.uuid,
      lieu: data.adresse?.adresse,
      code_postal: data.adresse?.code_postal,
      region: data.adresse?.region,
      ville: data.adresse?.ville,
      statut: tools.findIn(juryConstants["JURY"]["STATUTS"], data.statut, "id"),
      documents: null,
      documents_url: data.documents?.map((d: any) => d.file_url),
      documents_info: data.documents,
      president_jury_uuid: data.president_jury,
      vice_president_jury_uuid: data.vice_president_jury,
    };

    return transformed;
  },
};

export default juryAdapters;
