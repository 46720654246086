import { Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import { commonInputStyles } from "../../../styles";

function GroupeEntrepriseProfileInformation({ reactHookProps }: any) {
  return (
    <>
      <Stack.Item>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Raison sociale :"
              type="text"
              {...field}
              placeholder="Ex : Ma société"
              required
            />
          )}
          defaultValue=""
          control={reactHookProps.control}
          name="raison_sociale"
        />
      </Stack.Item>

      <FormAdress
        control={reactHookProps.control}
        required={true}
        varToWatch={reactHookProps.watch("code_postal") ?? false}
        onPrefillCityAndRegion={(data: any) => {
          reactHookProps.setValue("ville", data.nom_commune);
          reactHookProps.setValue("region", data.nom_region);
        }}
      />
    </>
  );
}

export default GroupeEntrepriseProfileInformation;
