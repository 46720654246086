import { DetailsList, DetailsListLayoutMode, Link, SelectionMode, Stack, Text } from "@fluentui/react";
import useDomaineList from "./useDomaineList"
import { Link as RouterLink } from "react-router-dom";
import { commonButtonStyles } from "../../styles";
import Page from "../../Common/Page/Page";
import CreateLink from "../../Common/CreateLink/CreateLink";
import LoadLink from "../../Common/LoadLink/LoadLink";


function DomaineList() {
    const { domaineProps, domaines } = useDomaineList();
    const { actions: actionsDomaines, isToggleActivationUuid } = domaineProps;
    const items = domaines?.data || []

    const columns = [
        {
            key: "1",
            name: "Nom du domaine",
            fieldName: "nom",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item: any) => <Text>{item.nom}</Text>,
        },
        {
            key: "2",
            name: "Nombre de certifications rattachées",
            fieldName: "nombre_certification",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item: any) => <Text>{item.certifications?.length}</Text>,
        },
        {
            key: "3",
            name: "OF(s) rattaché(s)",
            fieldName: "ofs_rattaches",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item: any) => (
                <Stack>
                    {item.ofs?.map((of: any) => (
                        <Text>{of.raison_sociale}</Text>
                    ))
                    }
                </Stack>),
        },
        {
            key: "4",
            name: "Actions",
            fieldName: "actions",
            minWidth: 200,
            isResizable: true,
            onRender: (item: any) => {
                return (
                    <Stack horizontal wrap>
                        <Link
                            to={`/admin/domaines/${item.uuid}`}
                            as={RouterLink}
                            styles={commonButtonStyles.buttonLink}
                            style={{ marginLeft: 5, marginTop: 5 }}
                        >
                            Editer
                        </Link>

                        <LoadLink
                            styles={item.actif ? commonButtonStyles.disableButtonLink : commonButtonStyles.activeButtonLink}
                            onClick={() => actionsDomaines.toggleDomaineActivation(item)}
                            isLoading={isToggleActivationUuid == item.uuid}
                            text={item.actif ? "Désactiver" : "Activer"}
                        />
                    </Stack>
                );
            },
        },
    ]

    return (
        <Stack>
            <Page title="Gestion des domaines" explain="Veuillez gérer les domaines">
                <Stack style={{ marginTop: 25 }}>
                    <CreateLink text="Créer un domaine" to="/admin/domaines/creer" />
                </Stack>

                <DetailsList
                    layoutMode={DetailsListLayoutMode.justified}
                    items={items}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />

            </Page>
        </Stack>
    )
}

export default DomaineList