import { useAtom } from "jotai";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { getCertificationsRequest } from "../../services/certificationServices";

function useCertificationList() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () => {
      return getCertificationsRequest(
        { active: { value: true }, certifications_limites: { value: false } },
        { sort: "libelle", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];

  return {
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  };
}

export default useCertificationList;
