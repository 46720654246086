import { Stack } from "@fluentui/react";
import Can from "../../../../Common/Can/Can";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import { branchAccessEnum } from "../../../../config/accessEnum";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import OptionsBlocsSection from "../../Common/OptionsBlocsSection";

function ValiderEvaluationForm({ candidature, reactHookProps }: any) {
  return (
    <Can I={branchAccessEnum.ACTIVER_PRISE_CONNAISSANCE_EVALUATION}>
      <Stack>
        <OptionsBlocsSection
          candidature={candidature}
          statutCandidature={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={true}
        />

        <DynamicFieldForm
          statut={StatutCandidature.EVALUATION}
          reactHookProps={reactHookProps}
          disabled={true}
        />
      </Stack>
    </Can>
  );
}

export default ValiderEvaluationForm;
