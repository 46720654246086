import { Stack } from "@fluentui/react";
import useTheme from "../useTheme";
import tools from "../../utils/tools";

function SuiviCohorteTextUpdatedAt({ paramBrancheSuiviCohorteActif }: any) {
  const { successColor } = useTheme();

  if (!paramBrancheSuiviCohorteActif) {
    return null;
  }

  return (
    <Stack>
      <p style={{ margin: 0, color: "#6F6F6F" }}>
        Le suivi de cohortes automatique est{" "}
        <strong style={{ color: successColor }}>activé</strong> sur la branche
        depuis le :{" "}
        <strong>
          {tools.formatDate(paramBrancheSuiviCohorteActif.modifie_le)}
        </strong>
      </p>
    </Stack>
  );
}

export default SuiviCohorteTextUpdatedAt;
