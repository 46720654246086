import { joiResolver } from "@hookform/resolvers/joi";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ofAdapters from "../../adapters/ofAdapters";
import useGroupCompany from "../../hooks/groupCompany/useGroupCompany";
import useGroupCompanyQuery from "../../hooks/groupCompany/useGroupCompanyQuery";
import { groupCompanySchema } from "../../schemas/groupCompanySchema";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import groupCompanyAdapters from "../../adapters/groupCompanyAdapters";

export const GroupCompanyContext = React.createContext({});
export const GroupCompanyProvider = ({ value, children }: any) => {
  return (
    <GroupCompanyContext.Provider value={value}>
      {children}
    </GroupCompanyContext.Provider>
  );
};

const resetImportGroupCompany: any = {
  raison_sociale: "",
  ref_idcc: [],
  adresse_uuid: null,
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  region: "",
  ville: "",
  entreprises_hors_branche: [],
};

function useGroupCompanyDetail() {
  const [isChecked, setIsChecked] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const navigate = useNavigate();
  const groupCompanyProps = useGroupCompany();
  const { groupCompany, isLoading, isRefetching, refetch } =
    useGroupCompanyQuery(groupCompanyProps);

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    resolver: joiResolver(groupCompanySchema),
  });

  const actions = {
    saveGroupCompany: (data: any) => {
      if (!(data as any).uuid && !groupCompany?.id) {
        groupCompanyProps.actions.postGroupCompany(data);
      } else {
        groupCompanyProps.actions.putGroupCompany(data);
      }
    },
    setUpdateIsCheck: (isVerified: boolean, value?: any) => {
      if (isVerified && value) {
        const mesBranchesId = value.branches?.map((b: any) => b.id);
        if (
          mesBranchesId.length > 0 &&
          mesBranchesId.includes(userSelectedBranch.id)
        ) {
          navigate(
            `/admin/groupes-entreprises/${value.uuid}?branchId=${userSelectedBranch.id}`
          );
        } else {
          const groupCompanyAdapterValue =
            groupCompanyAdapters.transformForBO(value);
          Object.keys(resetImportGroupCompany).forEach((field: any) => {
            reactHookProps.setValue(
              `${field}`,
              groupCompanyAdapterValue[field]
            );
          });
          reactHookProps.setValue("uuid", groupCompanyAdapterValue.uuid);
        }
      }
      setIsChecked(isVerified);
    },
    resetAutocompleteInput: () => {
      Object.keys(resetImportGroupCompany).forEach((field: any) => {
        reactHookProps.setValue(`${field}`, resetImportGroupCompany[field]);
      });
    },
  };

  return {
    groupCompany,
    groupCompanyProps,
    reactHookProps,
    actions,
    isChecked,
    isLoadingGroupCompany: isLoading || isRefetching,
    refetch,
  };
}

export default useGroupCompanyDetail;
