import { Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import SelectFieldAsync from "../../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../../config/config";
import { CandidatureContext } from "../useCreationCandidature";

const EntitiesLinkedToCandidatSection = ({ disabled }: any) => {
  const { reactHookProps, conditionnalDisplayVariables }: any =
    useContext(CandidatureContext);
  const { isGroupCompanyUser, isGroupOfUser } = conditionnalDisplayVariables;
  const {
    control,
    setValue,
    formState: { errors },
  } = reactHookProps;

  return (
    <>
      {isGroupCompanyUser && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Entreprise liée au candidat (sélection unique) :"
                placeholder="Recherchez une entreprise à l'aide de son SIRET ou nom (ex: entreprise 1)"
                resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.nom}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.nom}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                required={true}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                errorMessage={(errors[name] as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                disabled={disabled}
              />
            )}
            control={control}
            name="entreprise_liee"
          />
        </Stack.Item>
      )}

      {isGroupOfUser && (
        <Stack.Item>
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="OF lié au candidat (sélection unique) :"
                placeholder="Recherchez un OF à l'aide de son SIRET ou nom (ex: entreprise 1)"
                resourceURI={`${API_URL}api/administration/ofs/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110">
                          - {item.siret}
                        </span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                required={true}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                errorMessage={(errors[name] as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                disabled={disabled}
              />
            )}
            control={control}
            name="of_lie"
          />
        </Stack.Item>
      )}
    </>
  );
};

export default EntitiesLinkedToCandidatSection;
