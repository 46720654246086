import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import experienceAdapters from "../../../adapters/experienceAdapters";
import formationAdapters from "../../../adapters/formationAdapters";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import {
  deleteExperienceRequest,
  deleteFormationRequest,
  postCandidatureExperienceRequest,
  postCandidatureFormationRequest,
  putCandidatureExperienceRequest,
  putCandidatureFormationRequest,
} from "../../../services/candidatureService";
import tools from "../../../utils/tools";
import AddExperienceForm from "./AddExperienceForm/AddExperienceForm";
import AddFormationForm from "./AddFormationForm/AddFormationForm";

export const ParcoursCandidatContext = React.createContext({});
export const ParcoursCandidatProvider = ({ value, children }: any) => {
  <ParcoursCandidatContext.Provider value={value}>{children}</ParcoursCandidatContext.Provider>;
};

function useParcoursCandidat() {
  const { actions: modalActions } = useCustomModal();
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const [isSaving, setIsSaving] = useState(false);

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver:joiResolver schema
  });

  const actions = {
    openAddFormation: (formation: any, isInitial: any, inputsDisabled:any) => {
      modalActions.openModal({
        header: `Ajout d'une formation ${isInitial ? "initiale" : "continue"}`,
        body: (
          <AddFormationForm
            onAddFormation={actions.onAddFormation}
            formation={formation}
            isInitial={isInitial}
            inputsDisabled={inputsDisabled}
          />
        ),
      });
    },
    onAddFormation: async (formation: any, isInitial: any) => {
      const hookVar = isInitial ? "formations_initiales" : "formations_continues";
      try {
        const formData = new FormData();
        let apiData = {
          ...formation,
          type: isInitial ? "FORMATION_INITIALE" : "FORMATION_CONTINUE",
          _method: formation.uuid ? "PUT" : "POST",
        };

        tools.buildFormData(formData, formationAdapters.transformForAPI(apiData), null);
        const newFormation = formation.uuid
          ? await putCandidatureFormationRequest(candidature.uuid, formation.uuid, formData)
          : await postCandidatureFormationRequest(candidature.uuid, formData);
        toast.success("Formation enregistrée avec succès.");
        modalActions.closeModal();
        const formationInitiale = reactHookProps.watch(hookVar) || [];
        if (formation.uuid) {
          reactHookProps.setValue(hookVar, [
            ...formationInitiale.map((f: any) => (f.uuid == formation.uuid ? formation : f)),
          ]);
        } else {
          reactHookProps.setValue(hookVar, [
            ...formationInitiale,
            { ...formation, uuid: newFormation.uuid },
          ]);
        }
      } catch (err: any) {
        console.log("err", err);
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        // if (errorData?.errors) {
        //   tools.mapOnApiErrors(errorData.errors, reactHookProps);
        // }
      }
    },

    onRemoveFormation: async (formation: any, isInitial: any) => {
      if (
        window.confirm(
          `Confirmez-vous la suppression de cette formation ${
            isInitial ? "initiale" : "continue"
          } ?`
        )
      ) {
        const hookVar = isInitial ? "formations_initiales" : "formations_continues";
        try {
          const deletedFormation = await deleteFormationRequest(candidature.uuid, formation.uuid);
          reactHookProps.setValue(
            hookVar,
            reactHookProps.watch(hookVar).filter((f: any) => f.uuid !== formation.uuid)
          );
        } catch (err: any) {
          const errorData = err.response?.data;
          if (errorData?.message) {
            toast.error(err.response.data.message);
          }
        }
      }
    },
    openAddExperience: (experience: any, isProfessionel: any, inputsDisabled:any) => {
      modalActions.openModal({
        header: `Ajout d'une expérience ${
          isProfessionel ? "professionnelle" : "extra-professionnelle"
        }`,
        body: (
          <AddExperienceForm
            onAddExperience={actions.onAddExperience}
            experience={experience}
            isProfessionel={isProfessionel}
            inputsDisabled={inputsDisabled}
          />
        ),
      });
    },
    onAddExperience: async (experience: any, isProfessionel: any) => {
      const hookVar = isProfessionel
        ? "experiences_professionnelles"
        : "experiences_extraprofessionnelles";
      try {
        const formData = new FormData();
        let apiData = {
          ...experience,
          type: isProfessionel ? "EXPERIENCE_PRO" : "EXPERIENCE_EXTRA_PRO",
          _method: experience.uuid ? "PUT" : "POST",
        };

        tools.buildFormData(formData, experienceAdapters.transformForAPI(apiData), null);
        const newExperience = experience.uuid
          ? await putCandidatureExperienceRequest(candidature.uuid, experience.uuid, formData)
          : await postCandidatureExperienceRequest(candidature.uuid, formData);
        toast.success("Experience enregistrée avec succès.");
        modalActions.closeModal();
        const experienceInitiale = reactHookProps.watch(hookVar) || [];
        if (experience.uuid) {
          reactHookProps.setValue(hookVar, [
            ...experienceInitiale.map((f: any) => (f.uuid == experience.uuid ? experience : f)),
          ]);
        } else {
          reactHookProps.setValue(hookVar, [
            ...experienceInitiale,
            { ...experience, uuid: newExperience.uuid },
          ]);
        }
      } catch (err: any) {
        console.log("err", err);
        const errorData = err.response?.data;
        if (errorData?.message) {
          toast.error(err.response.data.message);
        }
        // if (errorData?.errors) {
        //   tools.mapOnApiErrors(errorData.errors, reactHookProps);
        // }
      }
    },

    onRemoveExperience: async (experience: any, isProfessionel: any) => {
      if (
        window.confirm(
          `Confirmez-vous la suppression de cette experience ${
            isProfessionel ? "professionnelle" : "extra-professionnelle"
          } ?`
        )
      ) {
        const hookVar = isProfessionel
          ? "experiences_professionnelles"
          : "experiences_extraprofessionnelles";
        try {
          const deletedExperience = await deleteExperienceRequest(
            candidature.uuid,
            experience.uuid
          );
          reactHookProps.setValue(
            hookVar,
            reactHookProps.watch(hookVar).filter((f: any) => f.uuid !== experience.uuid)
          );
        } catch (err: any) {
          const errorData = err.response?.data;
          if (errorData?.message) {
            toast.error(err.response.data.message);
          }
        }
      }
    },
    onSave: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidature(data, STATUT_PAGE, reactHookProps);
      setIsSaving(false);
    },
  };

  return {
    candidature,
    reactHookProps,
    isSaving,
    actions,
  };
}

export default useParcoursCandidat;
