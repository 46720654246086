import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
const eCertifApi = axiosInstance;

const ofURI = "administration/ofs";

export const getOfsRequest = async (filters: any, options: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siret: { type: "string" },
    qualiopi: { type: "select", keyValue: "value", valueNotFilter: undefined },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    certifications: { type: "array", keyValue: "uuid" },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${ofURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );

  return response.data;
};

export const getOfRequest = async (ofUuid: any) => {
  const response = await eCertifApi.get(`${ofURI}/${ofUuid}`);
  return response.data;
};

export const postOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${ofURI}`, data, xsrfHeader);
  return response.data;
};

export const putOfRequest = async (of: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${ofURI}/${of.uuid}`, of, xsrfHeader);
  return response.data;
};

export const patchActivateOfRequest = async (OfUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${ofURI}/${OfUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateOfRequest = async (OfUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${ofURI}/${OfUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteOfRequest = async (ofUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(`${ofURI}/${ofUuid}`, xsrfHeader);
  return response;
};

export const verifyOfSIRET = async (siret: string) => {
  const response = await eCertifApi.get(`${ofURI}/existe/${siret}`);
  return response.data;
};

export const getExportOfs = async (filters: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siret: { type: "string" },
    qualiopi: { type: "select", keyValue: "value", valueNotFilter: undefined },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    certifications: { type: "array", keyValue: "uuid" },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/ofs?paginate=false${queryFiltersUrl}`
    );

    return true;
  } catch (err) {
    throw err;
  }
};
