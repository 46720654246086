import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  postHabilitationOfRequest,
  postHabilitationUtilisateurRequest,
  removeHabilitationsRequest,
} from "../../services/habilitationServices";

function useHabilitationMutation() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [isSaved, setIsSaved] = useState(false);

  const mutations = {
    PostHabilitationOf: useMutation(postHabilitationOfRequest, {
      onSuccess: (data) => {
        toast.success("L'Habilitation a été créée avec succès.");
        queryClient.invalidateQueries("habilitations");
        setIsSaved(true);
        navigate(-1);
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la création de l'habilitation : ${error?.response?.data?.message}`
            );
      },
    }),
    PostHabilitationUtilisateur: useMutation(
      postHabilitationUtilisateurRequest,
      {
        onSuccess: (data) => {
          toast.success("L'Habilitation a été créée avec succès.");
          queryClient.invalidateQueries("habilitations");
          setIsSaved(true);
          navigate(-1);
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la création de l'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    RemoveHabilitations: useMutation(removeHabilitationsRequest, {
      onSuccess: (data) => {
        toast.success(
          "La ou les Habilitation(s) ont été retirée(s) avec succès."
        );
        queryClient.invalidateQueries("habilitations");
        queryClient.invalidateQueries("habilitationsEvaluateur");
        queryClient.invalidateQueries("habilitationsPositionneur");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la suppression : ${error?.response?.data?.message}`
            );
      },
    }),
  };

  return {
    mutations,
    isSaved,
  };
}

export default useHabilitationMutation;
