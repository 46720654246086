import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { RiAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import groupCompanyAdapters from "../../adapters/groupCompanyAdapters";
import EntitiesLinkedSection from "./FormSections/EntitiesLinkedSection";
import GeneralInformationSection from "./FormSections/GeneralInformationSection";
import useGroupCompanyDetail, {
  GroupCompanyContext,
} from "./useGroupCompanyDetail";
import {
  StructureProvider,
  StructureType,
} from "../../providers/StructureProvider/StructureProvider";
import UtilisateursLinkToStructure from "../../Common/UtilisateursLinkToStructure/UtilisateursLinkToStructure";

function GroupCompanyDetail() {
  const { uuid } = useParams();
  const {
    groupCompany,
    groupCompanyProps,
    reactHookProps,
    actions: actionsPage,
    isLoadingGroupCompany,
    isChecked,
    refetch,
  } = useGroupCompanyDetail();
  const {
    isLoading,
    hasError,
    errorPostGroupCompany,
    errorPutGroupCompany,
    actions,
  } = groupCompanyProps;

  const {
    handleSubmit,
    setError,
    reset,
    clearErrors,
    formState: { errors },
    register,
    setValue,
    watch,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid != "creer") {
      actions.setUpdateGroupCompanyUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (groupCompany?.uuid) {
      reset(groupCompanyAdapters.transformForBO(groupCompany));
    }
  }, [groupCompany]);

  useEffect(() => {
    var apiErrors = (errorPostGroupCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostGroupCompany]);

  useEffect(() => {
    var apiErrors = (errorPutGroupCompany as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutGroupCompany]);

  const onSubmit = (e: any) => {
    if (watch("uuid")) {
      setValue("_method", "PUT");
    }
    clearErrors();
    handleSubmit(actionsPage.saveGroupCompany)(e);
  };

  return (
    <GroupCompanyContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        errors,
        isChecked,
        uuid,
        groupCompany,
      }}
    >
      <Stack>
        <BackLink />
        <Page
          title={
            !groupCompany?.uuid
              ? "Ajouter un groupe d'entreprises"
              : "Editer un groupe d'entreprises"
          }
          explain={
            !groupCompany?.uuid
              ? "Veuillez créer un groupe d'entreprises rattaché à votre branche"
              : "Veuillez modifier le groupe d'entreprises rattaché à votre branche"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingGroupCompany}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: "POST",
                })}
              />

              <GeneralInformationSection />
              {(isChecked || uuid !== "creer") && (
                <>
                  <EntitiesLinkedSection />

                  <StructureProvider
                    structureType={StructureType.GROUPE_ENTREPRISE}
                    structureUuid={uuid as any}
                    users={groupCompany?.utilisateurs_lies}
                    referent={groupCompany?.referents}
                    structureRefetch={refetch}
                  >
                    <UtilisateursLinkToStructure sectionTitle="Utilisateurs liés au groupe d'entreprise" />
                  </StructureProvider>

                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <CancelButton />

                    <LoadButton
                      text={
                        !groupCompany?.uuid
                          ? "Créer le groupe d'entreprises"
                          : "Modifier le groupe d'entreprises"
                      }
                      isLoading={isLoading}
                      icon={<RiAddFill size={38} />}
                      style={{ fontWeight: "bold" }}
                    />
                  </Stack>
                </>
              )}
            </form>

            {hasError && (
              <PageMessage
                message={
                  (
                    (errorPostGroupCompany as any) ||
                    (errorPutGroupCompany as any)
                  ).response.data.message
                }
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </GroupCompanyContext.Provider>
  );
}

export default GroupCompanyDetail;
