import { Stack } from "@fluentui/react";
import { useEffect } from "react";
import { RiAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import groupOfAdapters from "../../adapters/groupOfAdapters";
import EntitiesLinkedSection from "./FormSections/EntitiesLinkedSection";
import GeneralInformationSection from "./FormSections/GeneralInformationSection";
import useGroupOfDetail, { GroupOfContext } from "./useGroupOfDetail";
import {
  StructureProvider,
  StructureType,
} from "../../providers/StructureProvider/StructureProvider";
import UtilisateursLinkToStructure from "../../Common/UtilisateursLinkToStructure/UtilisateursLinkToStructure";

function GroupOfDetail() {
  const { uuid } = useParams();
  const {
    groupOf,
    groupOfProps,
    reactHookProps,
    actions: actionsPage,
    isLoadingGroupOf,
    isChecked,
    refetch,
  } = useGroupOfDetail();
  const { actions, hasError, errorPostGroupOf, errorPutGroupOf, isLoading } =
    groupOfProps;
  const {
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    clearErrors,
    watch,
    setValue,
    register,
  } = reactHookProps;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (uuid != "creer") {
      actions.setUpdateGroupOfUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (groupOf?.uuid) {
      reset(groupOfAdapters.transformForBO(groupOf));
    }
  }, [groupOf]);

  useEffect(() => {
    var apiErrors = (errorPostGroupOf as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostGroupOf]);

  useEffect(() => {
    var apiErrors = (errorPutGroupOf as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutGroupOf]);

  const onSubmit = (e: any) => {
    if (watch("uuid")) {
      setValue("_method", "PUT");
    }
    clearErrors();
    handleSubmit(actionsPage.onSave)(e);
  };

  return (
    <GroupOfContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        errors,
        isChecked,
        uuid,
        groupOf,
      }}
    >
      <Stack>
        <BackLink />
        <Page
          title={
            !groupOf?.uuid ? "Ajouter un Groupe d'OF" : "Editer un Groupe d'OF"
          }
          explain={
            !groupOf?.uuid
              ? "Veuillez créer un groupe d'organisme de formation rattaché à votre branche"
              : "Veuillez modifier un groupe d'organisme de formation rattaché à votre branche"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingGroupOf}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: "POST",
                })}
              />
              <GeneralInformationSection />
              {(isChecked || uuid !== "creer") && (
                <>
                  <EntitiesLinkedSection />

                  <StructureProvider
                    structureType={StructureType.GROUPE_OF}
                    structureUuid={uuid as any}
                    users={groupOf?.utilisateurs_lies}
                    referent={groupOf?.referents}
                    structureRefetch={refetch}
                  >
                    <UtilisateursLinkToStructure sectionTitle="Utilisateurs liés au groupe d'OF" />
                  </StructureProvider>

                  <Stack
                    horizontal
                    horizontalAlign="space-between"
                    style={{ marginTop: 20 }}
                  >
                    <CancelButton />

                    <LoadButton
                      text={
                        !groupOf?.uuid
                          ? "Créer le groupe d'OF"
                          : "Modifier le groupe d'OF"
                      }
                      isLoading={isLoading}
                      icon={<RiAddFill size={38} />}
                      style={{ fontWeight: "bold" }}
                    />
                  </Stack>
                </>
              )}
            </form>

            {hasError && (
              <PageMessage
                message={
                  ((errorPostGroupOf as any) || (errorPutGroupOf as any))
                    .response.data.message
                }
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </GroupOfContext.Provider>
  );
}

export default GroupOfDetail;
