import { Label, Stack } from "@fluentui/react";
import ResultItem from "./ResultItem";

const SearchResults = ({
  filteredSearchResults,
  selectedItems,
  renderItem,
  renderValue,
  selectItem,
  removeItem,
  dataIndex,
  disabled,
  disabledSelectedItem = () => false,
  infoMessageDisabledItem = () => "",
  prefilled = false,
  unremovableItemsFn = () => false,
}: any) => {
  return (
    <div
      style={{
        background: disabled ? "f4f4f4" : "white",
        paddingTop: 0,
        borderTop: 0,
      }}
    >
      <Stack>
        {selectedItems?.map((item: any, key: any) => {
          const text = renderValue ? renderValue(item) : renderItem(item);
          return (
            <Stack.Item key={`resultItemSel${renderValue ? item : key}`}>
              <ResultItem
                text={text}
                onClick={
                  disabled || unremovableItemsFn(item)
                    ? () => {}
                    : () => removeItem(item)
                }
                isSelected
                disabled={disabled || unremovableItemsFn(item)}
                prefilled={prefilled}
              />
            </Stack.Item>
          );
        })}
        {filteredSearchResults?.length > 0 && (
          <Label style={{ fontWeight: "400", color: "grey" }}>
            Choisissez parmi les résultats :
          </Label>
        )}
        <div style={{ maxHeight: 250, overflowY: "scroll" }}>
          {filteredSearchResults?.map((item: any, key: any) => (
            <Stack.Item key={`resultItem${key}`}>
              <ResultItem
                text={renderItem(item)}
                onClick={
                  disabled || disabledSelectedItem(item)
                    ? () => {}
                    : () => selectItem(item)
                }
                prefilled={prefilled}
                disabled={disabledSelectedItem(item)}
                infoMessageDisabled={infoMessageDisabledItem(item)}
              />
            </Stack.Item>
          ))}
        </div>
      </Stack>
    </div>
  );
};

export default SearchResults;
