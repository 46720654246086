import { useAtom } from "jotai";
import { toast } from "react-toastify";
import ofAdapters from "../../adapters/ofAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import useOfMutations from "./useOfMutations";

function useOf() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const {
    ofUuid,
    setOfUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    isSaved,
  } = useOfMutations();

  const actions = {
    setUpdateOfUuid: (data: any) => {
      setOfUuid(data);
    },
    postOf: async (of: any) => {
      //DELETE ADAPTERS quand tous les entité auront la meme logique pour les ADRESSES
      mutations.PostOf.mutate(ofAdapters.transformForAPI(of));
    },
    putOf: async (of: any) => {
      //DELETE ADAPTERS quand tous les entité auront la meme logique pour les ADRESSES
      mutations.PutOf.mutate(ofAdapters.transformForAPI(of));
    },
    deleteOf: async (ofUuid: any) => {
      toast.info(`En cours de développement`);
      //mutations.DeleteOf.mutate(ofUuid)
    },
    toggleOfActivation: (of: any) => {
      setIsToggleActivationUuid(of.uuid);
      if (of.actif) {
        mutations.ToggleOfDesactivate.mutate(of.uuid);
      } else {
        mutations.ToggleOfActivate.mutate(of.uuid);
      }
    },
  };

  const {
    isLoading: isLoadingPostOf,
    isError: isErrorPostOf,
    error: errorPostOf,
    isSuccess: isSuccessPostOf,
  } = mutations.PostOf;

  const {
    isLoading: isLoadingPutOf,
    isError: isErrorPutOf,
    error: errorPutOf,
    isSuccess: isSuccessPutOf,
  } = mutations.PutOf;

  return {
    userSelectedBranch,
    ofUuid,
    isLoading: isLoadingPostOf || isLoadingPutOf,
    hasError: isErrorPostOf || isErrorPutOf,
    isToggleActivationUuid,
    errorPostOf,
    isSuccessPostOf,
    errorPutOf,
    isSuccessPutOf,
    actions,
    isSaved,
  };
}

export default useOf;
