import { getBranchesRequest } from "../../services/publicServices";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";

export const allBranchesAtom = atom({ data: [] });
const isLoadingBranchesAtom = atom(false);

function useHome() {
  const [isLoadingBranches, setIsLoadingBranches] = useAtom(
    isLoadingBranchesAtom
  );

  const [allBranches, setAllBranches]: any = useAtom(allBranchesAtom);
  const onLoadBranches = async () => {
    setIsLoadingBranches(true);
    const data = await getBranchesRequest();
    setAllBranches(data);
    setIsLoadingBranches(false);
  };

  useEffect(() => {
    if (allBranches.data?.length == 0 && !isLoadingBranches) onLoadBranches();
  }, []);

  // const { data: branches, isLoading:isLoadingBranches, isRefetching:isRefetchingBranches } = useQuery(
  //     'branches',
  //     getBranchesRequest,
  //     {
  //         retry:0,
  //     })

  return {
    branches: allBranches,
    isLoading: isLoadingBranches,
  };
}

export default useHome;
