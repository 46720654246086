import { IconButton } from "@fluentui/react";
import tools from "../../utils/tools";
import useTheme from "../useTheme";

import paginationStyles from "./Pagination.styles";

interface paginationInterface {
  totalPages: number;
  page: number;
  changePage: any;
}

function Pagination({ totalPages, page, changePage }: paginationInterface) {
  const { primaryColor } = useTheme();
  var pages = [];
  var offsetActualPage = 1;

  var pageMin = page - offsetActualPage < 1 ? 1 : page - offsetActualPage;
  var pageMax = page + offsetActualPage > totalPages ? totalPages : page + offsetActualPage;

  for (let i = pageMin; i <= pageMax; i++) {
    pages.push(
      <li key={"page" + i} style={{ margin: "0px 5px" }}>
        <span
          className={page == i ? "isActivePage" : "isNotActivePage"}
          style={
            {
              "--branch-color": primaryColor,
              "--branch-color-lighter": tools.lightenDarkenColor(primaryColor, 80),
              color: page == i ? "white" : primaryColor,
            } as React.CSSProperties
          }
          onClick={() => changePage(i)}
        >
          {i}
        </span>
      </li>
    );
  }

  if (totalPages < 2) {
    return null;
  }
  return (
    <ul
      style={{
        display: "flex",
        justifyContent: "center",
        listStyleType: "none",
        paddingInlineStart: 0,
        alignItems: "center",
        marginTop: 20,
        marginBottom: 0,
      }}
    >
      <li style={{ margin: "0px 5px" }}>
        <IconButton
          iconProps={{ iconName: "DoubleChevronLeft" }}
          title="first"
          ariaLabel="first"
          disabled={page === 1}
          onClick={() => changePage(1)}
          styles={paginationStyles.iconButton}
        />
      </li>
      <li style={{ margin: "0px 5px" }}>
        <IconButton
          iconProps={{ iconName: "ChevronLeft" }}
          title="previous"
          ariaLabel="previous"
          disabled={page === 1}
          onClick={() => changePage(page - 1)}
          styles={paginationStyles.iconButton}
        />
      </li>
      {pages}
      <li style={{ margin: "0px 5px" }}>
        <IconButton
          iconProps={{ iconName: "ChevronRight" }}
          title="next"
          ariaLabel="next"
          disabled={page === totalPages}
          onClick={() => changePage(page + 1)}
          styles={paginationStyles.iconButton}
        />
      </li>
      <li style={{ margin: "0px 5px" }}>
        <IconButton
          iconProps={{ iconName: "DoubleChevronRight" }}
          title="last"
          ariaLabel="last"
          disabled={page === totalPages}
          onClick={() => changePage(totalPages)}
          styles={paginationStyles.iconButton}
        />
      </li>
    </ul>
  );
}

export default Pagination;
