import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  DetailsRow,
  Link,
  Selection,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import Can from "../../Common/Can/Can";
import CreateLink from "../../Common/CreateLink/CreateLink";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useWorkflowModal from "../../Common/Overlays/CustomModal/useWorkflowModal";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import useTheme from "../../Common/useTheme";
import WaitingMECBadge from "../../Common/WaitingMECBadge/WaitingMECBadge";
import {
  branchAccessEnum,
  permissionsEditCandidatureStatus,
  userAccessEnum,
} from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow from "../../hooks/candidature/useCandidatureWorkflow";
import useCreateurCandidature from "../../hooks/certifications/useCreateurCandidature";
import useAuth from "../../hooks/useAuth";
import useConstants from "../../hooks/useConstants";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import { StatutJuryEnum } from "../../interfaces/juryStatut";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import CandidatureWorkflow from "../CandidatureWorkflow/CandidatureWorkflow";
import useLabelSettings from "../LabelSettingsDetail/useLabelSettings";
import CandidatureListFilters from "./CandidatureListFilters/CandidatureListFilters";
import useCandidatureList from "./useCandidatureList";

function CandidatureList() {
  const { auth } = useAuth();
  const { labels } = useLabelSettings();
  const { actions: accessActions } = useAccessSettings();
  const {
    candidatures,
    candidatureProps,
    actions: actionsPage,
    optionsFilters,
    isLoadingExportCandidatures,
    highlightedUuid,
    setHighLightedUuid,
  } = useCandidatureList();
  const { isMyOF } = useCreateurCandidature();
  const { userSelectedBranch } = candidatureProps;
  const { primaryColor, dangerColor } = useTheme();
  const { constants } = useConstants();
  const { isFirstStatutBeforeOrEqualSecondStatus, STATUTS_CANDIDATURE } =
    useCandidatureWorkflow();
  const { isAdmin, isAnyKindOfAdmin } = useInfoProfilUser();
  const { isCreateurCandidature } = useCreateurCandidature();
  const { actions: workflowModalActions } = useWorkflowModal();

  const onOpenVueSynthetique = (params: any) => {
    workflowModalActions.openModal({
      header: <h2 style={{ marginTop: 0 }}>Vue synthétique</h2>,
      body: <CandidatureWorkflow {...params} />,
    });
    setHighLightedUuid((u: any) => [...u, params.id_candidature]);
  };

  const items =
    candidatures?.data.map((c: any) => ({
      ...c,
      isHighlighted: highlightedUuid.includes(c.uuid),
    })) || [];

  const columns = [
    {
      key: "1",
      name: "Nom de naissance",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom"),
    },
    {
      key: "2",
      name: "Nom d'usage",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat?.nom_usage}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom_usage");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom_usage"),
    },
    {
      key: "3",
      name: "Prénom",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Text>{item.candidat.prenom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.prenom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "Domaine",
      fieldName: "domaine",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item.domaine?.nom} </Text>,
    },
    {
      key: "5",
      name: "Entreprise",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.entreprise_liee?.nom}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("entreprise.nom");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "entreprise.nom"),
    },
    {
      key: "6",
      name: "OF",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.of_lie?.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("organisme_de_formation.raison_sociale");
      },
      showSortIconWhenUnsorted: true,
      ...tools.sorted(
        optionsFilters.sort,
        "organisme_de_formation.raison_sociale"
      ),
    },
    {
      key: "7",
      name: labels.CANDIDATURES_COLONNE_CERTIFICATION,
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.certification?.libelle}</Text>,
    },
    {
      key: "8",
      name: "Statut",
      minWidth: 160,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const isStatutAfterPartiePrenante =
          tools.isCandidatureStatutAfterStatut(
            item,
            "identification_parties_prenantes",
            constants
          );
        return (
          <>
            {item.statut == "resultats_jury" ? (
              <Text
                style={
                  item.resultat == "VALIDE"
                    ? { color: "green" }
                    : item.resultat == "VALIDE_PARTIELLEMENT"
                    ? { color: "#ff8f00" }
                    : {}
                }
              >
                {
                  (
                    tools.findIn(
                      constants["CANDIDATURES"]["RESULTATS_STATUTS"],
                      item.resultat,
                      "id"
                    ) as any
                  )?.text
                }
              </Text>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text>
                  {
                    (
                      tools.findIn(
                        constants.CANDIDATURES.STATUTS,
                        item.statut,
                        "id"
                      ) as any
                    )?.text
                  }
                </Text>
                {item.statut == "inscription_jury" && item.jury && (
                  <small
                    style={{
                      color: dangerColor,
                    }}
                  >
                    demandée, en attente de validation
                  </small>
                )}
                {isStatutAfterPartiePrenante && !item.evaluateur && (
                  <small
                    style={{
                      color: dangerColor,
                    }}
                  >
                    en attente d'évaluateur
                  </small>
                )}
              </div>
            )}
            <WaitingMECBadge candidature={item} />
          </>
        );
      },
    },
    {
      key: "9",
      name: "Jury",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      // a modifie si plusieurs jury et non le type de jury
      onRender: (item: any) => <Text>{item.jury?.libelle}</Text>,
    },
    {
      key: "10",
      name: "Actions",
      minWidth: 200,
      isResizable: true,
      // a modifie si plusieurs jury et non le type de jury
      onRender: (item: any) => {
        const myActions = STATUTS_CANDIDATURE.map(
          (statut: any, index: number) => {
            if (
              index > 0 &&
              isFirstStatutBeforeOrEqualSecondStatus(statut.id, item.statut)
            ) {
              //Il est possible de modifier la candidature :
              //si la candidature n'est pas a la decision du jury
              //si la candidature est à la décision du jury et que le jury soit en seance
              const canEditCandidatureStatus =
                statut.id !== "decision_jury" ||
                (statut.id == "decision_jury" &&
                  item.jury?.statut == StatutJuryEnum.EN_SEANCE);
              return (
                <>
                  {canEditCandidatureStatus && (
                    <Can I={permissionsEditCandidatureStatus[statut.id]}>
                      <Link
                        to={`/admin/candidature/${item.uuid}/${statut.id}`}
                        as={RouterLink}
                        styles={commonButtonStyles.buttonLink}
                      >
                        {statut.actionName}
                      </Link>
                    </Can>
                  )}
                </>
              );
            }
            return null;
          }
        ).filter((t: any) => t);

        const isCandidatCanBeUnsubscribe = tools.candidatureCanBeUnsubscribe(
          item,
          isAdmin,
          accessActions.canI({
            action: userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION,
          }).granted,
          accessActions.canI({
            action:
              branchAccessEnum.OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY,
          }).granted
        );

        return (
          <Stack>
            {item.statut != "resultats_jury" && (
              <Link
                to={`/admin/candidature/${item.uuid}/${item.statut}`}
                as={RouterLink}
                styles={commonButtonStyles.buttonLink}
              >
                {labels.CANDIDATURES_BTN_VISUALISER}
              </Link>
            )}

            {item.statut == "avis_formateur" && (
              <>
                {(isAnyKindOfAdmin || isMyOF(item.of_lie?.uuid)) && (
                  <Can I={permissionsEditCandidatureStatus["avis_formateur"]}>
                    <Link
                      to={`/admin/candidature/${item.uuid}/avis_formateur`}
                      as={RouterLink}
                      styles={commonButtonStyles.buttonLink}
                    >
                      Donner son avis formateur
                    </Link>
                  </Can>
                )}
                {(isAnyKindOfAdmin ||
                  auth?.user?.uuid == item?.evaluateur?.uuid) &&
                  item.evaluation_brouillon && (
                    <Can I={permissionsEditCandidatureStatus["evaluation"]}>
                      <Link
                        to={`/admin/candidature/${item.uuid}/evaluation`}
                        as={RouterLink}
                        styles={commonButtonStyles.buttonLink}
                      >
                        Evaluer
                      </Link>
                    </Can>
                  )}
              </>
            )}

            {(item.statut == "evaluation" || item.statut == "avis_formateur") &&
              auth?.user?.uuid == item?.second_evaluateur?.uuid &&
              !item.avis_second_evaluateur?.avis && (
                <Can
                  I={permissionsEditCandidatureStatus["avis_second_evaluateur"]}
                >
                  <Link
                    to={`/admin/candidature/${item.uuid}/evaluation`}
                    as={RouterLink}
                    styles={commonButtonStyles.buttonLink}
                  >
                    Donner son avis second évaluateur
                  </Link>
                </Can>
              )}

            {myActions.length > 0 && myActions[myActions.length - 1]}
            {/* A DECOMMENTER POUR LA VUE SYNTHETIQUE */}
            {isAnyKindOfAdmin && (
              <Link
                onClick={() =>
                  onOpenVueSynthetique({
                    id_candidature: item.uuid,
                    statut_candidature: item.statut,
                  })
                }
                styles={commonButtonStyles.buttonLink}
              >
                Vue synthétique
              </Link>
            )}
            {(isAnyKindOfAdmin ||
              isCreateurCandidature({
                ofUuid: item.cree_par?.uuid,
                companyUuid: item.cree_par?.uuid,
              })) &&
              item.statut != "resultats_jury" && (
                <Link
                  onClick={() => {
                    actionsPage.openGiveUpCandidatureModal(item);
                  }}
                  styles={commonButtonStyles.deleteButtonLink}
                >
                  {labels.CANDIDATURES_BTN_SUPPRIMER}
                </Link>
              )}

            {isCandidatCanBeUnsubscribe && (
              <Link
                onClick={() => {
                  actionsPage.openDesincriptionConfirmModal(item);
                }}
                styles={commonButtonStyles.deleteButtonLink}
              >
                Désinscrire du jury
              </Link>
            )}
          </Stack>
        );
      },
    },
  ].filter((col: any) =>
    userSelectedBranch?.gestion_par_domaine ? col : col.fieldName != "domaine"
  );

  const userCanCreateCandidatureOnDomaine = [
    "ADMINISTRATEUR_BRANCHE",
    "ADMINISTRATEUR_DOMAINE",
    "OF",
  ];
  const selection = new Selection();

  const onRenderRow = (props: any) => {
    // Check if the row should be highlighted
    const isHighlighted = props.item.isHighlighted; // You can customize this logic
    // Define a CSS class to apply for the highlighted row
    const rowClassName = isHighlighted
      ? "ms-bgColor-gray30 ms-bgColor-gray30--hover"
      : "";

    return (
      <DetailsRow
        {...props}
        styles={{
          root: { selectors: { ":hover": { backgroundColor: "transparent" } } },
        }}
        className={rowClassName}
      />
    );
  };

  return (
    <Stack>
      <Page
        title={labels.CANDIDATURES_TITRE}
        explain={labels.CANDIDATURES_SOUS_TITRE}
      >
        <Stack
          horizontal
          wrap
          verticalAlign="center"
          style={{ marginTop: 25 }}
          tokens={{ childrenGap: 10 }}
        >
          {userSelectedBranch.gestion_par_domaine ? (
            <>
              {(auth.user.est_administrateur ||
                userSelectedBranch.profils?.some((myProfil: any) =>
                  userCanCreateCandidatureOnDomaine.includes(myProfil)
                )) && (
                <Can I={userAccessEnum.SAISIR_CANDIDATURE}>
                  <CreateLink
                    text="Créer une candidature"
                    to="/admin/candidature/creer/creation_candidature"
                  />
                </Can>
              )}
            </>
          ) : (
            <Can I={userAccessEnum.SAISIR_CANDIDATURE}>
              <CreateLink
                text="Créer une candidature"
                to="/admin/candidature/creer/creation_candidature"
              />
            </Can>
          )}
          <Can I={userAccessEnum.IMPORT_CANDIDATURE}>
            <div style={{ height: 46 }}>
              <DefaultButton
                text="Importer des candidatures en masse"
                styles={commonButtonStyles.defaultButton}
                style={{ color: primaryColor, borderColor: primaryColor }}
                onClick={actionsPage.onImportCandidats}
              />
            </div>
          </Can>
          <Can I={userAccessEnum.EXPORTER_INFORMATION_CANDIDATURES_EXCEL}>
            <div style={{ height: 46 }}>
              <LoadButton
                text="Exporter les candidatures"
                isLoading={isLoadingExportCandidatures}
                style={{
                  fontWeight: 500,
                  color: primaryColor,
                  borderColor: primaryColor,
                }}
                onClick={actionsPage.onExportCandidats}
                primary={false}
              />
            </div>
          </Can>
        </Stack>

        <CandidatureListFilters />

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selection={selection}
          selectionMode={SelectionMode.none}
          onRenderRow={onRenderRow}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={candidatures?.meta.current_page || 1}
          totalPages={Math.ceil(candidatures?.meta.total / 15) || 1}
        />
      </Page>
    </Stack>
  );
}

export default CandidatureList;
