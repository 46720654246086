import { DefaultButton, Dropdown, Stack } from "@fluentui/react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import TextResult from "../../../Common/TextResult/TextResult";
import { API_URL } from "../../../config/config";
import useCertificationList from "../../../hooks/certifications/useCertificationList";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useHabilitationOfList from "../useHabilitationOfList";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";

function HabilitationOfListFilters() {
  const { actions, reactHookProps, habilitations, isLoadingHabilitations } =
    useHabilitationOfList();
  const { constants } = useConstants();
  const {
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  } = useCertificationList();
  const { handleSubmit, setValue, control } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const totalHabilitations = habilitations?.meta?.total || 0;
  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...certificationsFromApi,
  ];

  const habilitationStatutList = [
    { id: undefined, text: "Tous", key: "all" },
    ...tools.addKeyToObjectInArray(constants.HABILITATION.STATUTS, "id"),
  ];

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Statut de l'habilitation :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="..."
                  options={habilitationStatutList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={habilitationStatutList[0]}
              control={control}
              name="statut"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => {
                return (
                  <Dropdown
                    selectedKey={value ? value.key : undefined}
                    label="Certification :"
                    placeholder="Sélectionnez une certification"
                    options={certificationList}
                    onChange={(e: any, options: any) => {
                      setValue(name, options, { shouldValidate: true });
                    }}
                    styles={commonInputStyles.dropdownStyle}
                  />
                );
              }}
              defaultValue={certificationList[0]}
              control={control}
              name="certification"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectFieldAsync
                  label="Organisme de formation :"
                  placeholder="Recherchez un OF (ex : OF)"
                  resourceURI={`${API_URL}api/administration/ofs/chercher`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?recherche=${terms}&actif=true&paginate=false`
                  }
                  method="GET"
                  renderItem={(item: any) => {
                    return (
                      <div>
                        <div>
                          {item.raison_sociale}{" "}
                          <span className="ms-fontColor-gray110">
                            - {item.siret}
                          </span>
                        </div>
                      </div>
                    );
                  }}
                  renderValue={(item: any) => {
                    return (
                      <div>
                        <div>
                          {item.raison_sociale}{" "}
                          <span className="ms-fontColor-gray110">
                            - {item.siret}
                          </span>
                        </div>
                      </div>
                    );
                  }}
                  dataIndex="uuid"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="of"
            />
          </Stack.Item>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingHabilitations}
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalHabilitations} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default HabilitationOfListFilters;
