import { Stack } from "@fluentui/react";
import { useAtom } from "jotai";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import useFetchCandidature from "../useFetchCandidature";
import EntrepriseRecap from "./EntrepriseRecap/EntrepriseRecap";
import SelectEntrepriseFormSection from "./FormSection/SelectEntrepriseFormSection";
import SelectEvaluateurFormSection from "./FormSection/SelectEvaluateurFormSection";
import SelectOfFormSection from "./FormSection/SelectOfFormSection";
import SelectPositionneurFormSection from "./FormSection/SelectPositionneurFormSection";
import OfRecap from "./OfRecap/OfRecap";
import usePartiesPrenantes, {
  PartiesPrenantesContext,
} from "./usePartiesPrenantes";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";

function PartiesPrenantes() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const {
    isReferentielStillEditable,
    isThisPageStatusBeforeCandidatureStatus,
    isFirstStatutBeforeOrEqualSecondStatus,
  } = useCandidatureWorkflow();
  const { labels } = useLabelSettings();
  const {
    candidature,
    conditionnalDisplayVariables,
    actions,
    reactHookProps,
    isSaving,
    userSelectedBranch,
  } = usePartiesPrenantes();
  const { actions: accessActions } = useAccessSettings();

  const fetchCandidature = useFetchCandidature(reactHookProps, () => {
    if (
      accessActions.canI({
        action: [
          branchAccessEnum.CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION,
        ],
      }).granted &&
      !userSelectedBranch.gestion_par_domaine
    ) {
      actions.addMeAs("evaluateur");
    } else if (userSelectedBranch.gestion_par_domaine) {
      reactHookProps.setValue(
        "evaluateur",
        reactHookProps.watch("domaine").evaluateur
      );
    }
  });
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    if (isEditMode) {
      reactHookProps.handleSubmit(mecActions.onSaveMEC)(e);
    } else {
      reactHookProps.handleSubmit(actions.onSave)(e);
    }
  };

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, [
        "identification_parties_prenantes",
      ])
      ? userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS;

  const inputsDisabled =
    !accessActions.canI({ action: [applicableEditPermission] }).granted ||
    conditionnalDisplayVariables.cantUpdateThisCandidature;

  return !candidature?.uuid ? null : (
    <PartiesPrenantesContext.Provider
      value={{
        conditionnalDisplayVariables,
        actions,
        reactHookProps,
        candidature,
        userSelectedBranch,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection certification={candidature.certification} />
          {!isAnyKindOfAdmin && (
            <>
              {conditionnalDisplayVariables.userProfilEntreprise && (
                <EntrepriseRecap
                  entreprise={(candidature as any)?.entreprise_liee}
                />
              )}
              {conditionnalDisplayVariables.userProfilOf && (
                <OfRecap of={candidature?.of_lie} />
              )}
            </>
          )}
        </CandidatureInfoHeaderWrapper>

        {inputsDisabled && (
          <Can I={userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS}>
            <BoutonDemanderMEC
              statut="identification_parties_prenantes"
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
              infoMessage={
                <>
                  Seul le positionneur et l'évaluateur peuvent être corrigés.
                  <br />
                  Pour corriger le positionneur, la candidature doit être dans
                  un statut antérieur ou égal au positionnement.
                  <br />
                  Pour corriger l'évaluateur, la candidature doit être dans un
                  statut antérieur ou égal à l'évaluation.
                  <br />
                  Le cas échéant, aucune modification ne sera possible sur cette
                  étape.
                </>
              }
              createurUuid={candidature.createur?.uuid}
            />
          </Can>
        )}
        <form onSubmit={onSubmit}>
          {(isAnyKindOfAdmin ||
            conditionnalDisplayVariables.userProfilEntreprise) && (
            <SelectOfFormSection disabled={inputsDisabled} />
          )}
          {(isAnyKindOfAdmin || conditionnalDisplayVariables.userProfilOf) && (
            <SelectEntrepriseFormSection disabled={inputsDisabled} />
          )}
          {conditionnalDisplayVariables.positionneurTier && (
            <SelectPositionneurFormSection
              disabled={
                inputsDisabled &&
                !(
                  isEditMode &&
                  isFirstStatutBeforeOrEqualSecondStatus(
                    candidature.statut,
                    "positionnement"
                  )
                )
              }
            />
          )}
          {(userSelectedBranch.gestion_par_domaine ||
            conditionnalDisplayVariables.evaluateurTier) && ( //check cette regle
            <SelectEvaluateurFormSection
              disabled={
                inputsDisabled &&
                !(
                  isEditMode &&
                  isFirstStatutBeforeOrEqualSecondStatus(
                    candidature.statut,
                    "evaluation"
                  )
                )
              }
            />
          )}

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>
            {!inputsDisabled && (
              <Can I={userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS}>
                <LoadButton
                  isLoading={isSaving}
                  text={labels.PARTIE_PRENANTE_BOUTONENREGISTRERLESPP}
                />
              </Can>
            )}
            <BoutonEnregistrerMEC
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          </Stack>
        </form>
      </Stack>
    </PartiesPrenantesContext.Provider>
  );
}

export default PartiesPrenantes;
