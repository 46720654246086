import { PrimaryButton, Text } from "@fluentui/react";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles } from "../../../styles";

function ResetModeleLettreModal({ modeleLettre, onValidate }: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickDeleteButton = () => {
    onValidate();
    closeModal();
  };

  return (
    <>
      <Text
        variant="medium"
        style={{ margin: "10px 0px", textAlign: "center" }}
      >
        Voulez-vous vraiment restaurer le modèle de lettre par défaut ?
        <br />
        La restauration supprimera de manière définitive vos personnalisations
      </Text>
      <PrimaryButton
        text="Restaurer"
        onClick={onClickDeleteButton}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </>
  );
}

export default ResetModeleLettreModal;
