import { useForm } from "react-hook-form";
import useMyStructure from "../../hooks/myStructure/useMyStructure";
import useMyStructureQuery from "../../hooks/myStructure/useMyStructureQuery";

function useMyStructureDetail({ type }: any) {
  const myStructureProps = useMyStructure();
  const {
    structure,
    isLoadingStructure,
    isRefetchingStructure,
    refetchStructure,
  } = useMyStructureQuery({
    structureUuid: myStructureProps.structureUuid,
    structureType: type,
  });

  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const actions = {
    saveStructure: (data: any) => {
      myStructureProps.actions.putMyStructure({
        structureType: type,
        structure: data,
      });
    },
  };

  return {
    structure,
    isLoadingStructure,
    isRefetchingStructure,
    refetchStructure,
    reactHookProps,
    myStructureProps,
    actions,
  };
}

export default useMyStructureDetail;
