import { Stack, TextField } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormAdress from "../../../Common/FormAdress/FormAdress";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import { commonInputStyles } from "../../../styles/index";
import { GroupCompanyContext } from "../useGroupCompanyDetail";
import SearchExistingStructure from "../../../Common/SearchExistingStructure/SearchExistingStructure";
import BranchesInfo from "../../../Common/BranchesInfo/BranchesInfo";

function GeneralInformationSection() {
  const {
    reactHookProps,
    errors,
    isChecked,
    uuid,
    groupCompany,
    actions,
  }: any = useContext(GroupCompanyContext);
  const { control, watch, setValue } = reactHookProps;

  return (
    <FormSection sectionTitle="Informations principales du groupe d'entreprise">
      {uuid === "creer" && (
        <SearchExistingStructure
          reactHookProps={reactHookProps}
          isChecked={isChecked}
          actions={actions}
          searchExistingUrl={"administration/groupe-entreprises/existe"}
          keyToCheck={"siren"}
          acceptedChararacterNumber={9}
        />
      )}

      {(isChecked || uuid !== "creer") && (
        <>
          <Stack.Item>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  label="Raison sociale :"
                  type="text"
                  {...field}
                  placeholder="Ex : Mon groupe d'entreprise"
                  errorMessage={(errors[field.name] as any)?.message}
                  required
                />
              )}
              defaultValue=""
              control={control}
              name="raison_sociale"
            />
          </Stack.Item>

          {uuid !== "creer" && (
            <>
              <Stack.Item>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <TextField
                      styles={commonInputStyles.textField}
                      label="SIREN (9 chiffres) :"
                      type="text"
                      placeholder="Ex : 123412123"
                      errorMessage={(errors[name] as any)?.message}
                      required
                      disabled={true}
                      value={value}
                      onChange={(e: any, newValue: any) => {
                        if (!newValue || newValue.length <= 9) {
                          onChange(e.target.value);
                        }
                      }}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="siren"
                />
              </Stack.Item>
              <BranchesInfo
                data={groupCompany}
                branchSentence={
                  "Ce groupe d'entreprise existe sur les branches :"
                }
                noBranchSentence={
                  "Ce groupe d'entreprise est présent uniquement sur cette branche"
                }
              />
            </>
          )}

          <Stack.Item style={{ width: "100%" }}>
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <SelectManyFieldAsync
                  label="Sélectionnez un ou plusieurs IDCC(s) :"
                  placeholder="Recherchez des IDCC(s) en tapant les premières lettres de leur nom"
                  resourceURI={`${API_URL}api/references/ref-idcc/chercher-idcc`}
                  addQueryURL={(resourceURI: any, terms: any) =>
                    `${resourceURI}?recherche=${terms}`
                  }
                  method="GET"
                  renderItem={(item: any) =>
                    item.idcc + " | " + item.titre_convention
                  }
                  renderValue={(item: any) =>
                    item.idcc + " | " + item.titre_convention
                  }
                  dataIndex="id"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  fieldRef={ref}
                  onChange={(e: any) => {
                    setValue(name, e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  defaultValue={{}}
                  errorMessage={(errors.ref_idccs as any)?.message}
                  handleResponse={(response: any) => [...response.data.data]}
                />
              )}
              control={control}
              name="ref_idccs"
            />
          </Stack.Item>

          <FormAdress
            control={control}
            errors={errors}
            varToWatch={watch("code_postal") ?? false}
            required={true}
            onPrefillCityAndRegion={(data: any) => {
              setValue("ville", data.nom_commune);
              setValue("region", data.nom_region);
            }}
          />
        </>
      )}
    </FormSection>
  );
}

export default GeneralInformationSection;
