import { useAtom } from "jotai";
import {
  SignUpContext,
  accountInformationsAtom,
  chosenBranchAtom,
} from "../useSignUp";
import { useContext } from "react";

const SelectedBranch = ({ children }: any) => {
  const [accountInformations]: any = useAtom(accountInformationsAtom);

  if (!accountInformations?.branche) return null;
  return (
    <>
      <p>
        Branche choisie : <strong>{accountInformations.branche.nom}</strong>
      </p>
      {children}
    </>
  );
};

export default SelectedBranch;
