import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import FormSection from "../../../../Common/FormSection/FormSection";
import LoadLink from "../../../../Common/LoadLink/LoadLink";
import { commonButtonStyles } from "../../../../styles";
import useCandidatureWorkflow from "../../../../hooks/candidature/useCandidatureWorkflow";
import tools from "../../../../utils/tools";
import { useState } from "react";
import ButtonNumberInfo from "../../../../Common/ButtonNumberInfo/ButtonNumberInfo";

export const ETAPE_FILES_CANDIDATURE = [
  {
    id: "ATTESTATION_DERNIER_DIPLOME",
    name: "Attestation du dernier diplôme",
    etapeId: "saisie_informations",
  },
];

function DocumentAddedDuringCandidature({ candidature, dynamicFields }: any) {
  const [showDocuments, setShowDocuments] = useState(false);
  const { STATUTS_CANDIDATURE }: any = useCandidatureWorkflow();

  const documentsFromApi = [
    //correspond aux documents lors de la transmission du dossier lie a la certification
    //{
    //  d => ensemble des pairs key:value en lien avec le fichier upload
    //  documentInfo => utilisé afin de nommer le fichier dans la colonne Nom du document du tableau
    //  etapeInfo => utilisé afin de connaitre l'etape où se trouve le fichier upload dans la candidature
    //  isChampComplementaire => est ce qu'il s'agit d'un champs complementaire
    //}
    ...candidature.certification_documents.map((d: any) => ({
      ...d,
      documentInfo: tools.findIn(
        candidature.certification.documents,
        d.collection,
        "uuid"
      ),
      etapeInfo: "transmission_dossier",
    })),
    //correspond aux documents standards de toutes les etapes de la candidature en lien avec le candidat
    ...candidature.candidat_documents.map((d: any) => {
      const documentInfo: any = tools.findIn(
        ETAPE_FILES_CANDIDATURE,
        d.collection,
        "id"
      );
      return {
        ...d,
        documentInfo,
        etapeInfo: documentInfo?.etapeId,
      };
    }),
    //correspond a la fiche individuelle qui est generer lors du passage de l'inscription a la decision si param branche actif
    ...candidature.documents
      .filter((d: any) => d.collection == "FICHE_INDIVIDUELLE")
      .map((d: any) => {
        return {
          documentInfo: {
            nom: "Fiche individuelle de candidature",
          },
          etapeInfo: "decision_jury",
          file_url: d.file_url,
        };
      }),
    //correspond aux champs complementaires de la branche (documents et autres)
    ...candidature.champs_complementaires.map((d: any) => {
      const documentInfo: any = tools.findIn(
        dynamicFields,
        d.champ_complementaire_uuid,
        "uuid"
      );
      return {
        ...d,
        documentInfo,
        etapeInfo: documentInfo?.statut_candidature?.key,
        isChampComplementaire: true,
      };
    }),
  ];

  const etapeLibelle = STATUTS_CANDIDATURE.map((s: any) => s.text);
  const items = tools
    .transformDocumentsForBo({
      documents: documentsFromApi,
      etapesCandidature: STATUTS_CANDIDATURE,
    })
    .sort((a: any, b: any) => {
      const etapeA = etapeLibelle.indexOf(a.etape);
      const etapeB = etapeLibelle.indexOf(b.etape);
      return etapeA - etapeB;
    });

  const columns: any = [
    {
      key: "1",
      name: "Étape de la candidature",
      fieldName: "etape",
      minWidth: 150,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {item.etape}
          {item.champ_complementaire && (
            <>
              <br />
              <span className="text-info">Document complémentaire</span>
            </>
          )}
        </Text>
      ),
    },
    {
      key: "2",
      name: "Nom du document",
      fieldName: "nom",
      minWidth: 150,
      maxWidth: 600,
      isResizable: true,
      onRender: (item: any) => <Text>{item.nom}</Text>,
    },
    {
      key: "3",
      name: "Télécharger",
      fieldName: "file_url",
      minWidth: 200,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <LoadLink
          href={item.file_url}
          styles={commonButtonStyles.buttonLink}
          text={"Télécharger"}
        />
      ),
    },
  ];

  const onShowDocuments = () => {
    setShowDocuments((s) => !s);
  };

  return items.length == 0 ? null : (
    <FormSection sectionTitle="Tous les documents de la candidature">
      <Stack horizontal>
        <ButtonNumberInfo
          text={
            showDocuments
              ? "Masquer tous les documents"
              : "Afficher tous les documents"
          }
          count={items.length}
          onClick={onShowDocuments}
        />
      </Stack>
      {showDocuments && (
        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
      )}
    </FormSection>
  );
}

export default DocumentAddedDuringCandidature;
