import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { toast } from "react-toastify";
import tools from "../../../utils/tools";
import { getHabilitationsUtilisateurDetailRequest } from "../../../services/habilitationServices";
import { useQuery } from "react-query";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import RemoveHabilitationModal from "../../HabilitationUtilisateurList/RemoveHabilitationModal/RemoveHabilitationModal";

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function usePositionneurRoleForm(userUuid: any) {
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const { actions: modalActions } = useCustomModal();

  const {
    data: habilitations,
    isLoading: isLoadingPositionneurHabilitation,
    isRefetching: isRefetchingPositionneurHabilitation,
    refetch: refetchPositionneurHabilitation,
  } = useQuery(
    ["habilitationsPositionneur", userUuid, optionsFilters],
    () =>
      getHabilitationsUtilisateurDetailRequest(
        { positionneur: userUuid },
        optionsFilters
      ),
    {
      enabled: !!userUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const actions = {
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({ ...optionsFilters, page });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onRemoveHabilitation: (habilitation: any[]) => {
      modalActions.openModal({
        header: "Retirer une habilitation",
        body: <RemoveHabilitationModal habilitation={habilitation} />,
      });
    },
  };

  return {
    habilitations,
    actions,
    optionsFilters,
    isLoadingPositionneurHabilitation,
    isRefetchingPositionneurHabilitation,
    refetchPositionneurHabilitation,
  };
}

export default usePositionneurRoleForm;
