import useMiseEnConfirmite from "./useMiseEnConfirmite";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import DialogConfirmerMEC from "./DialogConfirmerMEC";

const BoutonEnregistrerMEC = ({
  reactHookProps,
  onClick = () => {},
  onAfterValidate = () => {},
  typeBtn = "submit",
}: any) => {
  const { isEditMode } = useMiseEnConfirmite();

  return (
    <>
      <DialogConfirmerMEC
        reactHookProps={reactHookProps}
        onAfterValidate={onAfterValidate}
      />
      {isEditMode && (
        <LoadButton
          onClick={onClick}
          text="Enregistrer les corrections"
          isLoading={false}
          type={typeBtn}
        />
      )}
    </>
  );
};

export default BoutonEnregistrerMEC;
