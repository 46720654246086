import { DefaultSpacing, Stack, Text } from "@fluentui/react";
import { IComponentPlusProps } from "../../interfaces/pageInterface";
import pageStyles from "./Page.styles";

function Page({ children, explain, title, headerButton }: IComponentPlusProps) {
  return (
    <Stack styles={pageStyles.outerStyle} tokens={{ childrenGap: DefaultSpacing.l2 }}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        styles={{ root: { marginBottom: DefaultSpacing.l2 } }}
      >
        <Stack.Item>
          {/* Title */}
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <div style={{ fontSize: 20, fontWeight: "600" }}>{title}</div>
          </Stack>

          {/* Instructions */}
          <Text variant="mediumPlus" style={{ color: "#6F6F6F", maxWidth:800, display:'block' }}>
            {explain}
          </Text>
        </Stack.Item>
        <Stack.Item>{headerButton}</Stack.Item>
      </Stack>

      {children}
    </Stack>
  );
}

export default Page;
