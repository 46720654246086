import { Text, PrimaryButton } from "@fluentui/react";
import { commonButtonStyles } from "../../../styles/index";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";


function GiveUpConfirmDialog({ candidat, giveUpFunction = () => {} }: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickGiveUpButton = () => {
    giveUpFunction();
    closeModal();
  };

  return (
    <>
      <Text
        variant="medium"
        style={{ margin: "10px 0px", textAlign: "center" }}
      >
        Voulez-vous vraiment abandonner la candidature ? <br />{" "}
        <strong>{candidat}</strong>
      </Text>
      <PrimaryButton
        text="Abandonner"
        onClick={onClickGiveUpButton}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </>
  );
}

export default GiveUpConfirmDialog;
