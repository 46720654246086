import { useForm } from "react-hook-form";
import useUser from "../../hooks/user/useUser";
import useUserQuery from "../../hooks/user/useUserQuery";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useAtom } from "jotai";

const resetImportProfil: any = {
  nom: "",
  prenom: "",
  telephone_portable: "",
};

function useUserDetail() {
  const [isChecked, setIsChecked] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const userProps = useUser();
  const navigate = useNavigate();
  const { userProfil, isLoading, isRefetching } = useUserQuery(userProps);

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver:joiResolver()
  });

  const actions = {
    saveUser: (data: any) => {
      if (!(data as any).uuid) {
        userProps.actions.postUser(data);
      } else {
        userProps.actions.putUser(data);
      }
    },
    setUpdateIsCheck: (isVerified: boolean, value?: any) => {
      if (isVerified && value) {
        const mesBranchesId = value.branches?.map((b: any) => b.id);
        if (
          mesBranchesId.length > 0 &&
          mesBranchesId.includes(userSelectedBranch.id)
        ) {
          navigate(
            `/admin/utilisateurs/${value.uuid}?branchId=${userSelectedBranch.id}`
          );
        } else {
          Object.keys(resetImportProfil).forEach((field: any) => {
            reactHookProps.setValue(`${field}`, value[field]);
          });
          reactHookProps.setValue("uuid", value.uuid);
        }
      }
      setIsChecked(isVerified);
    },
    resetAutocompleteInput: () => {
      Object.keys(resetImportProfil).forEach((field: any) => {
        reactHookProps.setValue(`${field}`, resetImportProfil[field]);
      });
    },
  };

  return {
    isChecked,
    userProfil,
    userProps,
    reactHookProps,
    actions,
    isLoadingUser: isLoading || isRefetching,
  };
}

export default useUserDetail;
