import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { patchEnvoyerDocumentRequest } from "../../../../../services/candidatureService";
import { useParams } from "react-router-dom";
import tools from "../../../../../utils/tools";
import { atom, useAtom } from "jotai";
import useConstants from "../../../../../hooks/useConstants";
import useAuth from "../../../../../hooks/useAuth";

export const isFailureToUpdateDocumentAtom = atom(false);

function useUpdateGenerateDocuments() {
  const { auth } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [, setIsFailureToUpdateDocument] = useAtom(
    isFailureToUpdateDocumentAtom
  );
  const reactHookProps = useForm({ mode: "onBlur" });
  const { id_candidature } = useParams();
  const { constants } = useConstants();

  const isSuperAdmin = auth.user.est_administrateur;

  var documentsCanBeUpload = [
    { key: "select", id: undefined, text: "Sélectionnez un type" },
    ...constants["CANDIDATURES"]["DOCUMENTS_TYPES"],
  ];

  const actions = {
    onSubmit: async (data: any) => {
      setIsFailureToUpdateDocument(true);
      setIsLoading(true);
      try {
        const formData = new FormData();
        tools.buildFormData(
          formData,
          { ...data, document: data.document[0], type: data.type?.id },
          null
        );
        await patchEnvoyerDocumentRequest(formData, id_candidature);
        toast.success("Le document a bien été rattaché à la candidature");
        setIsFailureToUpdateDocument(false);
        reactHookProps.reset({
          _method: "PATCH",
          type: documentsCanBeUpload[0],
          document: undefined,
        });
      } catch (err: any) {
        toast.error(err.response?.data?.message);
      }
      setIsLoading(false);
    },
  };
  return {
    documentsCanBeUpload,
    isLoading,
    reactHookProps,
    actions,
    isSuperAdmin,
  };
}

export default useUpdateGenerateDocuments;
