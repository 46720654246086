const pageStyles = {
  outerStyle: {
    root: {
      display: "block",
      boxSizing: "border-box",
      padding: "00px 20px 30px 30px",
      minHeight: "90vh", // NEED TO BE CORRECT
      background: "rgb(248 248 248 / 51%)",
    },
  },
};

export default pageStyles;
