import { useEffect, useState } from "react";
import { Stack, IconButton, Image, ImageFit, Text } from "@fluentui/react";

import accordionStyles from "./Accordion.styles";

function Accordion({
  title,
  defaultIsOpen = false,
  content,
  srcImg,
  altImg,
  styles = accordionStyles,
  noImg = false,
  horizontalAlign = "space-between",
  forceClose = false,
}: any) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  useEffect(() => {
    if (forceClose) {
      setIsOpen(false);
    }
  }, [forceClose]);

  const showContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Stack styles={styles.container}>
      <div style={styles.wrapper}>
        <Stack.Item styles={styles.header}>
          <Stack
            horizontal
            horizontalAlign={horizontalAlign}
            onClick={showContent}
            style={{ cursor: "pointer" }}
          >
            <Stack horizontal>
              {!noImg && (
                <Stack.Item
                  align="center"
                  style={{ maxWidth: 40, height: 40, marginRight: 10 }}
                >
                  <Image
                    style={{ width: 40, height: 40 }}
                    src={srcImg}
                    maximizeFrame
                    imageFit={ImageFit.contain}
                    alt={altImg}
                  />
                </Stack.Item>
              )}
              <Stack.Item align="center">
                <Text
                  style={{ ...styles.text, fontSize: 16, fontWeight: "bold" }}
                >
                  {title}
                </Text>
              </Stack.Item>
            </Stack>

            <Stack.Item align="center">
              <IconButton
                styles={styles.iconButton}
                iconProps={{
                  iconName: isOpen ? "ChevronDown" : "ChevronRight",
                }}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        {isOpen && <Stack.Item styles={styles.content}>{content}</Stack.Item>}
      </div>
    </Stack>
  );
}

export default Accordion;
