export const habilitationAdapters = {
  transformForAPIOf: (data: any) => {
    let transformed: any = {
      ...data,
      date_debut: `${data.date_debut?.getFullYear()}-${
        data.date_debut?.getMonth() + 1
      }-${data.date_debut?.getDate()}`,
      date_fin: `${data.date_fin?.getFullYear()}-${
        data.date_fin?.getMonth() + 1
      }-${data.date_fin?.getDate()}`,
      certification: data.certification?.id,
      organisme_de_formation: data.organisme_de_formation?.uuid,
      documents: data.documents?.map((item: any) =>
        item?.file ? { ...item, file: item.file[0] } : { ...item }
      ),
    };
    return transformed;
  },
  transformForBOOf: (data: any) => {
    let transformed: any = {
      ...data,
    };

    return transformed;
  },
  transformForAPIUtilisateur: (data: any) => {
    let transformed: any = {
      date_debut: `${data.date_debut?.getFullYear()}-${
        data.date_debut?.getMonth() + 1
      }-${data.date_debut?.getDate()}`,
      date_fin: `${data.date_fin?.getFullYear()}-${
        data.date_fin?.getMonth() + 1
      }-${data.date_fin?.getDate()}`,
      certification: data.certification?.id,
      utilisateur: data.utilisateur?.uuid,
      profil: data.profil?.id,
      documents: data.documents?.map((item: any) =>
        item?.file ? { ...item, file: item.file[0] } : { ...item }
      ),
    };
    return transformed;
  },
  transformForBOUtilisateur: (data: any) => {
    let transformed: any = {
      ...data,
    };

    return transformed;
  },
};

export default habilitationAdapters;
