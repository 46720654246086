import { ChoiceGroup, IChoiceGroupOption, Stack, TextField } from "@fluentui/react";
import { FormEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { userAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { commonInputStyles } from "../../../styles";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";

export const SousGroupeEntrepriseRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const { actions:accessActions } = useAccessSettings();
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

    const sousGroupeStateRadioOptions: IChoiceGroupOption[] = [{ key: "sous_groupe_existant", text: "Sous-groupe d'entreprises existant" }]
    if(accessActions.canI({action:userAccessEnum.CREER_SOUS_GROUPE}).granted){
      sousGroupeStateRadioOptions.push({ key: "nouveau_sous_groupe", text: "Nouveau sous-groupe d'entreprises" })
    }

  return (
    <ProfileAddionalInformations
      name="SOUS_GROUPE_ENTREPRISE"
      sectionTitle="Informations du profil Sous-groupe d'entreprises"
      watchRoles={watchRoles}
    >
      <Stack>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Groupe d'entreprises du sous-groupe :"
              placeholder="Recherchez un groupe d'entreprises par SIREN ou par nom (ex: 123123123 ou Groupe 1)"
              resourceURI={`${API_URL}api/administration/groupe-entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">- {item.siren}</span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">- {item.siren}</span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
                setValue('sousGroupeEntrepriseProfile.entreprise', [], { shouldValidate: true })
              }}
              defaultValue={null}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
              required
            />
          )}
          control={control}
          name="sousGroupeEntrepriseProfile.groupeEntreprise"
        />
        <Controller
          defaultValue={null}
          name="sousGroupeEntrepriseProfile.type"
          control={control}
          render={({ field: { name, value } }) => (
            <ChoiceGroup
              options={sousGroupeStateRadioOptions}
              label="Souhaitez-vous rattacher cet utilisateur à un sous-groupe d'entreprises existant ou à un nouveau sous-groupe d'entreprises ? :"
              selectedKey={value ?? null}
              required={true}
              name={name}
              onChange={(
                ev?: FormEvent<HTMLInputElement | HTMLElement>,
                option?: IChoiceGroupOption
              ) => {
                if (option?.key == 'sous_groupe_existant') {
                  setValue('sousGroupeEntrepriseProfile.nomSousGroupeEntreprise', '');
                  setValue('sousGroupeEntrepriseProfile.entreprise', []);
                } else {
                  setValue('sousGroupeEntrepriseProfile.sousGroupeEntreprise', null)
                }
                setValue("sousGroupeEntrepriseProfile.type", option?.key)
              }}
            />
          )}
        />

        {(watch("sousGroupeEntrepriseProfile.type") == "sous_groupe_existant" && watch('sousGroupeEntrepriseProfile.groupeEntreprise')) && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Sous-groupe d'entreprises existant à rattacher :"
                placeholder="Recherchez un sous-groupe d'entreprises par nom (ex: sous-groupe d'entreprise)"
                resourceURI={`${API_URL}api/administration/sous-groupes/chercher`}
                addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?type=SOUS_GROUPE_ENTREPRISE&nom=${terms}&paginate=false&groupe=${watch('sousGroupeEntrepriseProfile.groupeEntreprise').uuid}`}
                method="GET"
                renderItem={(item: any) => item.nom}
                renderValue={(item: any) => item.nom}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                //errorMessage={(errors.code_postal as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="sousGroupeEntrepriseProfile.sousGroupeEntreprise"
          />
        )}

        {watch("sousGroupeEntrepriseProfile.type") == "nouveau_sous_groupe" && (
          <Stack>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  required
                  label="Nom du nouveau sous-groupe d'entreprises auquel rattacher l'utilisateur : "
                  type="text"
                  {...field}
                  placeholder="Ex: sous-groupe 1"
                  errorMessage={
                    ((errors.sousGroupeEntrepriseProfile as any)?.nomSousGroupeEntreprise as any)?.message
                  }
                />
              )}
              defaultValue=""
              control={control}
              name="sousGroupeEntrepriseProfile.nomSousGroupeEntreprise"
            />

            {watch('sousGroupeEntrepriseProfile.groupeEntreprise') &&
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="Entreprises liées au nouveau sous-groupe d'entreprises :"
                    placeholder="Recherchez des entreprises par SIRET ou par nom (ex: 12312312311111 ou Entreprise 1)"
                    resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?recherche=${terms}&groupe=${watch('sousGroupeEntrepriseProfile.groupeEntreprise').uuid}`}
                    method="GET"
                    renderItem={(item: any) => {
                      return (
                        <div>
                          <div>
                            {item.nom} <span className="ms-fontColor-gray110">- {item.siret}</span>
                          </div>
                        </div>
                      );
                    }}
                    renderValue={(item: any) => {
                      return (
                        <div>
                          <div>
                            {item.nom} <span className="ms-fontColor-gray110">- {item.siret}</span>
                          </div>
                        </div>
                      );
                    }}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={[]}
                    errorMessage={((errors.sousGroupeEntrepriseProfile as any)?.entreprise as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                    required
                  />
                )}
                control={control}
                name="sousGroupeEntrepriseProfile.entreprise"
              />
            }
          </Stack>
        )}
      </Stack>
    </ProfileAddionalInformations>
  );
};
