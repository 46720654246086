import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import {
  postOfRequest,
  putOfRequest,
  deleteOfRequest,
  patchActivateOfRequest,
  patchDesactivateOfRequest,
} from "../../services/ofServices";

function useOfMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [ofUuid, setOfUuid] = useState(null);
  const [isToggleActivationUuid, setIsToggleActivationUuid] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const mutations = {
    PostOf: useMutation(postOfRequest, {
      onSuccess: (data) => {
        toast.success("L'Organisme de formation a été créé avec succès.");
        setOfUuid(data.uuid);
        queryClient.invalidateQueries("ofs");
        setIsSaved(true);
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de l'enregistrement de l'Organisme de formation : ${error?.response?.data?.message}`
            );
      },
    }),
    PutOf: useMutation(putOfRequest, {
      onSuccess: () => {
        toast.success("L'Organisme de formation a été mis à jour avec succès.");
        queryClient.invalidateQueries("ofs");
        setIsSaved(true);
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour de l'Organisme de formation : ${error?.response?.data?.message}`
            );
      },
    }),
    DeleteOf: useMutation(deleteOfRequest, {
      onSuccess: () => queryClient.invalidateQueries("ofs"),
    }),
    ToggleOfActivate: useMutation(patchActivateOfRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("L'Organisme de formation a été activé avec succès.");
        queryClient.invalidateQueries("ofs");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de l'activation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
    ToggleOfDesactivate: useMutation(patchDesactivateOfRequest, {
      onSuccess: () => {
        setIsToggleActivationUuid(null);
        toast.success("L'Organisme de formation a été désactivé avec succès.");
        queryClient.invalidateQueries("ofs");
      },
      onError: (error: any) => {
        toast.error(
          `Une erreur est survenue lors de désactivation : ${error?.response?.data?.message}`
        );
        setIsToggleActivationUuid(null);
      },
    }),
  };

  return {
    ofUuid,
    setOfUuid,
    mutations,
    isToggleActivationUuid,
    setIsToggleActivationUuid,
    isSaved,
  };
}

export default useOfMutations;
