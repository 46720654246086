import { watch } from "fs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useCandidature from "../../../hooks/candidature/useCandidature";

export const ValidationPositionnement = React.createContext({});
export const ValidationPositionnementProvider = ({ value, children }: any) => {
  return (
    <ValidationPositionnement.Provider value={value}>
      {children}
    </ValidationPositionnement.Provider>
  );
};
export const useValidationPositionnement = () => {
  const { candidature } = useCandidature();
  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const actions = {
    onSave: (data: any) => {
      toast.info("En cours de développements...");
      const dataToSend = {
        ...data,
        statut_validation_positionnement_par_candidat: "valide",
        commentaire_candidat_positionnement: "",
      };
    },
    onInvalidatePositionnement: (data: any) => {
      toast.info("En cours de développements...");
      const dataToSend = {
        ...data,
        statut_validation_positionnement_par_candidat: "invalide",
      };
    },
  };

  // useEffect(() => {
  //   reactHookProps.setValue(
  //     "blocs_competences_passes",
  //     candidature.certification.blocs_competences.filter(
  //       (bloc: any) =>
  //         bloc.option == (reactHookProps.watch("option_selectionnee")?.key || null) &&
  //         bloc.statut == 1
  //     )
  //   );
  // }, [reactHookProps.watch("option_selectionnee")]);

  return { candidature, reactHookProps, actions };
};
