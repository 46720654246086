import { MessageBar, Stack } from "@fluentui/react";
import { useAtom } from "jotai";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import JuryList from "../../../Common/JuryList/JuryList";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useTheme from "../../../Common/useTheme";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";
import useConstants from "../../../hooks/useConstants";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import { MessageBarStyles, commonButtonStyles } from "../../../styles";
import { MessageBarErrorStyles } from "../../../styles/MessageBar.styles";
import tools from "../../../utils/tools";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import useFetchCandidature from "../useFetchCandidature";
import useUrlParams from "../useUrlParams";
import useInscriptionJury from "./useInscriptionJury";
import { toast } from "react-toastify";

let startingInterval: any = null;

function InscriptionJury() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);
  const { isAdmin } = useInfoProfilUser();
  const { primaryColor } = useTheme();
  const {
    juryList,
    candidature,
    isAdminModifiedJury,
    actions,
    reactHookProps,
    candidatureActions,
    isSuccessModifyCandidatureJury,
    isLoadingModifyCandidatureJury,
    isSuccessJuryAddCandidats,
    isSuccessRejectCandidature,
    isSuccessAcceptCandidature,
    isLoadingPutJuryAddCandidats,
    isLoadingAcceptCandidature,
    isLoadingRejectCandidature,
  }: any = useInscriptionJury();
  const { actions: accessActions } = useAccessSettings();
  const { constants } = useConstants();
  const { id_candidature } = useUrlParams();
  const { isFirstStatutBeforeOrEqualSecondStatus } = useCandidatureWorkflow();
  const { dynamicFields } = useDynamicFields();
  const location = useLocation();
  const queryParamParse: any = queryString.parse(location.search);
  const [timer, setTimer] = useState(10);

  const navigate = useNavigate();
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { activeMecsAsked } = useMiseEnConfirmite();

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        isFirstStatutBeforeOrEqualSecondStatus(
          candidature.statut,
          "identification_parties_prenantes"
        ),
        dynamicFields
      )
    );
    actions.setUpdateIsAdminModifiedJury(false);
  };

  useEffect(() => {
    if (
      id_candidature &&
      (isSuccessModifyCandidatureJury ||
        isSuccessJuryAddCandidats ||
        isSuccessRejectCandidature ||
        isSuccessAcceptCandidature)
    ) {
      fetchModifiedCandidature();
    }
  }, [
    isSuccessModifyCandidatureJury,
    isSuccessJuryAddCandidats,
    isSuccessRejectCandidature,
    isSuccessAcceptCandidature,
  ]);

  useEffect(() => {
    if (queryParamParse.torefetch) {
      if (candidature.statut == "decision_jury") {
        clearInterval(startingInterval);
        navigate(
          `/admin/candidature/${candidature.uuid}/inscription_jury?branchId=${queryParamParse.branchId}`
        );
      }
      if (timer <= 0 && candidature.statut == "inscription_jury") {
        window.location.reload();
      }
      if (timer == 10) {
        launchTimer();
      }
    }
  }, [timer]);

  useEffect(() => {
    return () => {
      clearInterval(startingInterval);
    };
  }, []);

  const launchTimer = () => {
    clearInterval(startingInterval);
    startingInterval = setInterval(() => {
      setTimer((t: any) => t - 1);
    }, 1000);
  };

  const applicablePermission = userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION;
  const inputsDisabled = !accessActions.canI({ action: [applicablePermission] })
    .granted;

  const onAfterSaved = () => {
    if (isAnyKindOfAdmin) {
      toast.info(
        "Administrateur : La validation automatique de l’inscription au jury est en cours et peut prendre quelques instants. La page se mettra automatiquement à jour une fois l’opération effectuée.",
        { autoClose: 10000 }
      );
      navigate(
        `/admin/candidature/${candidature.uuid}/inscription_jury?branchId=${queryParamParse.branchId}&torefetch=true`
      );
      launchTimer();
    }
  };

  const onSaveInscription = (data: any) => {
    actions.onSave(data, onAfterSaved);
  };

  return (
    <Stack style={{ marginTop: 0 }}>
      <CandidatureInfoHeaderWrapper>
        <CandidatSection candidat={candidature.candidat} />
        <CertificationSection certification={candidature.certification} />
      </CandidatureInfoHeaderWrapper>
      {activeMecsAsked.length > 0 ? (
        <div style={{ marginTop: 20 }}>
          <MessageBar messageBarType={2} styles={MessageBarErrorStyles}>
            <div>
              La candidature ne peut être inscrite à un jury car une ou
              plusieurs demandes de mise en conformité sont toujours en attente
              de modification.
            </div>
          </MessageBar>
        </div>
      ) : (
        <>
          {candidature.jury?.uuid && !isAdminModifiedJury ? (
            <>
              <Stack.Item style={{ backgroundColor: "#F2F2F2", marginTop: 20 }}>
                <MessageBar styles={MessageBarStyles}>
                  Le candidat est inscrit au jury «{" "}
                  <span style={{ color: primaryColor }}>
                    {candidature.jury.libelle}
                  </span>{" "}
                  » du «{" "}
                  <span style={{ color: primaryColor }}>
                    {tools.reformateDateFromJsDate(candidature.jury.date_jury)}
                  </span>{" "}
                  »
                </MessageBar>
              </Stack.Item>
              {candidature.statut == "inscription_jury" && candidature.jury && (
                <MessageBar styles={MessageBarStyles}>
                  L'inscription au jury est en attente de validation
                </MessageBar>
              )}
              {isAdmin && candidature.statut != "resultats_jury" && (
                //si c'est un admin && si la candidature n'est pas deja a l'etape resultats_jury
                <Stack.Item style={{ marginTop: 20 }}>
                  <LoadButton
                    isLoading={false}
                    text="Modifier le jury du candidat"
                    onClick={() => actions.setUpdateIsAdminModifiedJury(true)}
                  />
                </Stack.Item>
              )}
            </>
          ) : (
            <>
              <JuryList
                onSelectJury={(jury: any) =>
                  reactHookProps.setValue("jury", jury)
                }
                jurys={juryList}
                jurySelected={reactHookProps.watch("jury")}
                updateFilters={(filters: any) => {
                  actions.changeFilters(filters);
                }}
                inputDisabled={inputsDisabled}
              />

              {reactHookProps.watch("jury") && (
                <Stack.Item
                  style={{ backgroundColor: "#F2F2F2", marginTop: 20 }}
                >
                  <MessageBar styles={MessageBarStyles}>
                    Le candidat sera inscrit au jury «{" "}
                    <span style={{ color: primaryColor }}>
                      {reactHookProps.watch("jury").libelle}
                    </span>{" "}
                    » du «{" "}
                    <span style={{ color: primaryColor }}>
                      {tools.reformateDateFromJsDate(
                        reactHookProps.watch("jury").date_jury
                      )}
                    </span>{" "}
                    »
                  </MessageBar>
                </Stack.Item>
              )}
            </>
          )}

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>

            {!candidature.jury && (
              <Can I={[userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION]}>
                <LoadButton
                  isLoading={isLoadingPutJuryAddCandidats}
                  text={"Inscrire le candidat au jury"}
                  onClick={(e: any) =>
                    reactHookProps.handleSubmit(onSaveInscription)(e)
                  }
                />
              </Can>
            )}
            {isAdminModifiedJury && (
              <LoadButton
                isLoading={isLoadingModifyCandidatureJury}
                text={"Modifier le jury du candidat"}
                onClick={(e: any) =>
                  reactHookProps.handleSubmit(
                    candidatureActions.patchModifyCandidatureJury
                  )(e)
                }
              />
            )}

            {candidature.jury?.uuid &&
              isAdmin &&
              candidature.statut == "inscription_jury" &&
              !isAdminModifiedJury && (
                //s'il y a un jury d'assigne &&
                //si l'utilisateur est un admin &&
                //si le statut de la candidature est à l'inscription du jury &&
                //si l'admin n'est pas en train de modifier le jury &&
                //si la date d'inscription d'un candidat n'est pas echue
                <Stack horizontal>
                  <LoadButton
                    text="Valider l'inscription"
                    onClick={() =>
                      candidatureActions.patchAcceptCandidature(
                        candidature.uuid
                      )
                    }
                    styles={commonButtonStyles.validateButton}
                    style={{ marginRight: 20 }}
                    isLoading={isLoadingAcceptCandidature}
                  />
                  <LoadButton
                    text="Refuser l'inscription"
                    onClick={() =>
                      candidatureActions.patchRejectCandidature(
                        candidature.uuid
                      )
                    }
                    styles={commonButtonStyles.deleteButton}
                    isLoading={isLoadingRejectCandidature}
                  />
                </Stack>
              )}
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default InscriptionJury;
