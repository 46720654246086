import { atom, useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import tools from "../../utils/tools";
import DemandeHabilitationModal from "../Dashboard/DemandeHabilitationModal/DemandeHabilitationModal";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import {
  getDemandeHabilitationsOfRequest,
  getDemandeHabilitationsUtilisateurRequest,
} from "../../services/demandeHabilitationService";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import { branchAccessEnum } from "../../config/accessEnum";

const optionsFiltersAtom = atom({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

export const initialFilters = {
  statut: { id: undefined, text: "Tous", key: "all" },
  certification: { id: undefined, text: "Tous", key: "all" },
};

export const filtersAtom = atomWithReset<any>(initialFilters);

function useMesDemandesHabilitationsList() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const { actions: modalActions } = useCustomModal();
  const { actions: accessActions } = useAccessSettings();

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const {
    data: demandeHabilitationsOf,
    isLoading: isLoadingDemandeHabilitationsOf,
  } = useQuery(
    ["demandeHabilitationsOf", optionsFilters, userSelectedBranch],
    () => getDemandeHabilitationsOfRequest(filters, optionsFilters),
    {
      retry: 0,
      enabled: accessActions.canI({
        action: branchAccessEnum.HABILITATION_SIMPLE_OF,
      }).granted,
    }
  );

  const {
    data: demandeHabilitationsUtilisateur,
    isLoading: isLoadingDemandeHabilitationsUtilisateur,
  } = useQuery(
    ["demandeHabilitationsUtilisateur", optionsFilters, userSelectedBranch],
    () => getDemandeHabilitationsUtilisateurRequest(filters, optionsFilters),
    {
      retry: 0,
      enabled:
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
        }).granted ||
        accessActions.canI({
          action: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
        }).granted,
    }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.onChangeOptionFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({
        ...optionsFilters,
        page,
      });
    },
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
    onOpenDemandeHabilitationModal: (
      entityOrProfil: "OF" | "EVALUATEUR" | "POSITIONNEUR"
    ) => {
      modalActions.openModal({
        header: "Demander une habilitation",
        body: <DemandeHabilitationModal entityOrProfil={entityOrProfil} />,
        fullWidth: true,
      });
    },
  };
  return {
    actions,
    reactHookProps,
    optionsFilters,
    demandeHabilitationsOf,
    isLoadingDemandeHabilitationsOf,
    demandeHabilitationsUtilisateur,
    isLoadingDemandeHabilitationsUtilisateur,
  };
}

export default useMesDemandesHabilitationsList;
