import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IModifyAccountInformations } from "../../interfaces/IAccountInformations";
import { joiResolver } from "@hookform/resolvers/joi";
import { accountSchema } from "../../schemas/accountSchema";
import useAuth from "../../hooks/useAuth";
import { updateAccountInformations } from "../../services/accountService";

function useAccountInformations() {
  const { auth, getUserQuery } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const reactHookProps = useForm<IModifyAccountInformations>({
    mode: "onBlur",
    resolver: joiResolver(accountSchema),
  });

  const mutations = {
    updateUserInfos: useMutation(updateAccountInformations, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("user");
        getUserQuery();
        toast.success("Informations changées avec succès");
        navigate("/admin/accueil");
      },
      onError: (error: any) => {
        toast.error(error.response.data.message);
        if (!error.response.status || error.response.status >= 500) {
          navigate(`/erreur`);
        } else {
          console.log(error.message);
        }
      },
    }),
  };

  const actions = {
    submitForm: (data: IModifyAccountInformations) => {
      const body: any = {
        uuid: auth.user.uuid,
        email: data.email,
        nom: data.nom,
        prenom: data.prenom,
        telephone_portable: data.telephone_portable,
        actuel_mot_de_passe: data.actuel_mot_de_passe,
        nouveau_mot_de_passe: data.nouveau_mot_de_passe,
      };
      try {
        mutations.updateUserInfos.mutate(body);
      } catch (err: any) {
        toast.error(`${err.message}`);
      }
    },
  };

  return {
    actions,
    reactHookProps,
    isLoading: mutations.updateUserInfos.isLoading,
    user: auth.user,
  };
}

export default useAccountInformations;
