import candidatureAdapters from "../../adapters/candidatureAdapters";
import { branchAccessEnum } from "../../config/accessEnum";
import { putCandidatureWithFormDataRequest } from "../../services/candidatureService";
import tools from "../../utils/tools";
import useAccessSettings from "../branchSettings/useAccessSettings";
import useDynamicFields from "../dynamicFields/useDynamicFields";
import useAuth from "../useAuth";

const useEvaluationObject = () => {
  const { dynamicFields } = useDynamicFields();
  const { actions: accessActions } = useAccessSettings();
  const { auth } = useAuth();

  const saveEvaluationObject = async (data: any) => {
    const evaluationObject =
      data.statut_a_modifier === "avis_second_evaluateur"
        ? {
            ...data,
          }
        : {
            ...data,
            evaluation: data.blocs_competences_passes.map((b: any) => ({
              uuid: b.candidature_bloc_competence_uuid,
              decision: b.decision_evaluation,
              commentaire_evaluation: b.commentaire_evaluation,
              competences_associees: b.competences.map((c: any) => ({
                uuid: c.uuid,
                decision: c.decision_evaluation,
                criteres_evaluation: accessActions.canI({
                  action: branchAccessEnum.EVALUATION_PAR_CRITERE,
                }).granted
                  ? c.criteres
                    ? c.criteres.map((l: any) => ({
                        libelle: l.libelle,
                        decision: l.decision_evaluation?.toString(),
                      }))
                    : null //waiting api
                  : null,
              })),
            })),
          };

    try {
      const formData = new FormData();
      tools.buildFormData(
        formData,
        candidatureAdapters.transformForAPI(
          {
            ...evaluationObject,
            statut_a_modifier: evaluationObject.statut_a_modifier
              ? evaluationObject.statut_a_modifier
              : "evaluation",
            _method: "PUT",
          },
          dynamicFields
        ),
        null
      );

      const newCandidature = await putCandidatureWithFormDataRequest(
        formData,
        evaluationObject.uuid
      );
      return { success: true, data: newCandidature };
    } catch (err: any) {
      const errorData = err.response?.data;
      return { success: false, errors: errorData };
    }
  };

  return { saveEvaluationObject };
};

export default useEvaluationObject;
