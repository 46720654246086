import Joi from "joi";

const emailSchema = Joi.object({
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner un email",
      "string.email":
        "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
});

const verifyProfilSchema = Joi.object({
  nom: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un nom" }),
  prenom: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un prénom" }),
  email: Joi.string()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.empty": "Veuillez renseigner un email",
      "string.email":
        "Veuillez respecter le format de l'email (exemple@domaine.fr)",
    }),
});

const verifyOfSchema = Joi.object({
  raison_sociale: Joi.string().required().max(150).messages({
    "any.required": "Veuillez renseigner une raison sociale",
    "string.empty": "Veuillez renseigner une raison sociale",
    "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
  }),
  siret: Joi.string().required().length(14).regex(/^\d+$/).messages({
    "any.required": "Veuillez renseigner un numéro SIRET",
    "string.empty": "Veuillez renseigner un numéro SIRET",
    "string.length": "Veuillez renseigner un numéro à 14 chiffres",
    "string.pattern.base": "Le siret ne doit comporter que des chiffres",
  }),
  adresse: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une adresse" }),
  complement_adresse: Joi.string().allow("", null),
  code_postal: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un code postal" }),
  region: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une région" }),
  ville: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une ville" }),
}).unknown(true);

const verifyEntrepriseSchema = Joi.object({
  nom: Joi.string().required().max(150).messages({
    "any.required": "Veuillez renseigner une raison sociale",
    "string.empty": "Veuillez renseigner une raison sociale",
    "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
  }),
  siret: Joi.string().required().length(14).regex(/^\d+$/).messages({
    "any.required": "Veuillez renseigner un numéro SIRET",
    "string.empty": "Veuillez renseigner un numéro SIRET",
    "string.length": "Veuillez renseigner un numéro à 14 chiffres",
    "string.pattern.base": "Le siret ne doit comporter que des chiffres",
  }),
  ref_idcc: Joi.object().required().messages({
    "any.required": "Veuillez choisir un IDCC",
    "object.base": "Veuillez choisir un IDCC",
  }),
  adresse: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une adresse" }),
  complement_adresse: Joi.string().allow("", null),
  code_postal: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner un code postal" }),
  region: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une région" }),
  ville: Joi.string()
    .required()
    .messages({ "string.empty": "Veuillez renseigner une ville" }),
}).unknown(true);

const verifyExportSuiviCohorte = Joi.object({
  certification: Joi.string().required().messages({
    "any.required": "Veuillez sélectionner une certification",
    "string.empty": "Veuillez sélectionner une certification",
  }),
  date_debut: Joi.date().required().messages({
    "any.required": "Veuillez sélectionner une date",
  }),
  date_fin: Joi.date().required().messages({
    "any.required": "Veuillez sélectionner une date",
  }),
  periode_de_suivi: Joi.string().required().messages({
    "any.required": "Veuillez sélectionner une periode de suivi",
    "string.empty": "Veuillez sélectionner une periode de suivi",
  }),
});

const verifyExportCaisseDesDepots = Joi.object({
  porteur: Joi.string().required().messages({
    "any.required": "Veuillez sélectionner un porteur",
  }),
  date_debut: Joi.date().required().messages({
    "any.required": "Veuillez sélectionner une date",
  }),
  date_fin: Joi.date().required().messages({
    "any.required": "Veuillez sélectionner une date",
  }),
});

const validations = {
  validateEmail: (email) => {
    return emailSchema.validate({ email });
  },
  validateProfil: (profil) => {
    return verifyProfilSchema.validate(profil, { abortEarly: false });
  },
  validateOf: (of) => {
    return verifyOfSchema.validate(of, { abortEarly: false });
  },
  validateEntreprise: (entreprise) => {
    return verifyEntrepriseSchema.validate(entreprise, { abortEarly: false });
  },
  validateExportForm: (info) => {
    return verifyExportSuiviCohorte.validate(info, { abortEarly: false });
  },
  validateDatasExportForm: (dates) => {
    return verifyExportCaisseDesDepots.validate(dates, { abortEarly: false });
  },
  checkForDoublons: (array, value, key) => {
    return array.find((item) => item[key] == value);
  },
};

export default validations;
