import { useQuery } from "react-query";
import blockCompetenceAdapters from "../../adapters/blocCompetenceAdapters";
import useCompetenceBlock from "../../hooks/useCompetenceBlock";
import { getCompetenceBlockRequest } from "../../services/competenceBlockServices";

function useCompetenceBlockDetail() {
  const competenceBlockProps = useCompetenceBlock();

  const {
    data: competenceBlock,
    isLoading,
    isRefetching,
  } = useQuery(
    ["competenceBlocks", competenceBlockProps.competenceBlockUuid],
    () => getCompetenceBlockRequest(competenceBlockProps.competenceBlockUuid),
    {
      onSuccess(data) {
        return blockCompetenceAdapters.transformForBO(data);
      },
      enabled: !!competenceBlockProps.competenceBlockUuid,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const actions = {
    saveCompetenceBlock: (data: any) => {
      if (!(data as any).uuid) {
        competenceBlockProps.actions.postCompetenceBlock(data);
      } else {
        competenceBlockProps.actions.putCompetenceBlock(data);
      }
    },
  };

  return {
    competenceBlock,
    competenceBlockProps,
    actions,
    isLoadingCompetenceBlock: isLoading || isRefetching,
  };
}

export default useCompetenceBlockDetail;
