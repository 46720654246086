import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import LoadLink from "../../Common/LoadLink/LoadLink";
import useWorkflowModal from "../../Common/Overlays/CustomModal/useWorkflowModal";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import WaitingMECBadge from "../../Common/WaitingMECBadge/WaitingMECBadge";
import { branchAccessEnum, userAccessEnum } from "../../config/accessEnum";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import { commonButtonStyles } from "../../styles";
import tools from "../../utils/tools";
import CandidatureWorkflow from "../CandidatureWorkflow/CandidatureWorkflow";
import ValidationCandidatureListFilters from "./ValidationCandidatureListFilters/ValidationCandidatureListFilters";
import useValidationCandidatureList from "./useValidationCandidatureList";
import Can from "../../Common/Can/Can";
import useTheme from "../../Common/useTheme";

function ValidationCandidatureList() {
  const {
    candidatureProps,
    validationCandidatureList,
    optionsFilters,
    actions: actionsPage,
  } = useValidationCandidatureList();
  const { actions } = candidatureProps;
  const { actions: workflowModalActions } = useWorkflowModal();
  const { actions: accessActions } = useAccessSettings();
  const { isAdmin } = useInfoProfilUser();
  const { primaryColor } = useTheme();

  const onOpenVueSynthetique = (params: any) => {
    workflowModalActions.openModal({
      header: <h2 style={{ marginTop: 0 }}>Vue synthétique</h2>,
      body: <CandidatureWorkflow {...params} />,
    });
  };

  const items = validationCandidatureList?.data || [];

  const columns = [
    {
      key: "1",
      name: "Nom de naissance",
      fieldName: "nom_naissance",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom");
      },
      onRender: (item: any) => <Text>{item?.candidat?.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom"),
    },
    {
      key: "2",
      name: "Nom de d'usage",
      fieldName: "nom_usage",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.nom_usage");
      },
      onRender: (item: any) => <Text>{item?.candidat?.nom_usage}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.nom_usage"),
    },
    {
      key: "3",
      name: "Prénom",
      fieldName: "prenom",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("candidat.prenom");
      },
      onRender: (item: any) => <Text>{item?.candidat?.prenom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "candidat.prenom"),
    },
    {
      key: "4",
      name: "OF",
      fieldName: "of",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("organismeDeFormation.raison_sociale");
      },
      onRender: (item: any) => <Text>{item?.of_lie?.raison_sociale}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(
        optionsFilters.sort,
        "organismeDeFormation.raison_sociale"
      ),
    },
    {
      key: "5",
      name: "Entreprise",
      fieldName: "entreprise",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("entreprise.nom");
      },
      onRender: (item: any) => <Text>{item?.entreprise_liee?.nom}</Text>,
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "entreprise.nom"),
    },
    {
      key: "6",
      name: "Evaluateur",
      fieldName: "evaluateur",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("evaluateur.nom");
      },
      onRender: (item: any) => (
        <Text>
          {item?.evaluateur?.nom} {item?.evaluateur?.prenom}
        </Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "evaluateur.nom"),
    },
    {
      key: "7",
      name: "Certification",
      fieldName: "certification",
      minWidth: 100,
      maxWidth: 300,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("certification.libelle");
      },
      onRender: (item: any) => (
        <Text>
          {item?.certification?.libelle} - {item?.certification?.type_libelle}
        </Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "certification.libelle"),
    },
    {
      key: "8",
      name: "Jury associé",
      fieldName: "jury",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("jury.libelle");
      },
      onRender: (item: any) => (
        <Stack>
          <Text>
            {item?.jury?.libelle} du {tools.formatDate(item?.jury?.date_jury)}
          </Text>
          <Text>
            {item?.jury?.nombre_de_places_restantes} place(s) restante(s)
          </Text>
        </Stack>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "jury.libelle"),
    },
    {
      key: "9",
      name: "Date de demande d'inscription",
      fieldName: "demande_inscription_jury_le",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      onColumnClick: () => {
        actionsPage.sortColumn("demande_inscription_jury_le");
      },
      onRender: (item: any) => (
        <Text>{tools.formatDate(item?.demande_inscription_jury_le)}</Text>
      ),

      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "demande_inscription_jury_le"),
    },
    {
      key: "10",
      name: "Actions",
      fieldName: "actions",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        const isCandidatCanBeUnsubscribe = tools.candidatureCanBeUnsubscribe(
          item,
          isAdmin,
          accessActions.canI({
            action: userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION,
          }).granted,
          accessActions.canI({
            action:
              branchAccessEnum.OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY,
          }).granted
        );

        return (
          <>
            <Stack horizontal wrap>
              <LoadLink
                styles={commonButtonStyles.activeButtonLink}
                onClick={() => actions.patchAcceptCandidature(item.uuid)}
                //isLoading={}
                text={"Accepter"}
              />

              <LoadLink
                styles={commonButtonStyles.disableButtonLink}
                onClick={() => actions.patchRejectCandidature(item.uuid)}
                //isLoading={}
                text={"Refuser"}
              />

              <Link
                onClick={() => actionsPage.openModifyCandidatureModal(item)}
                styles={commonButtonStyles.buttonLink}
                style={{ marginLeft: 5, marginTop: 5 }}
              >
                Changer le jury associé
              </Link>
              {/* A DECOMMENTER POUR LA VUE SYNTHETIQUE */}
              <Link
                onClick={() =>
                  onOpenVueSynthetique({
                    id_candidature: item.uuid,
                    statut_candidature: item.statut,
                  })
                }
                styles={commonButtonStyles.buttonLink}
              >
                Vue synthétique
              </Link>
              {isCandidatCanBeUnsubscribe && (
                <Link
                  onClick={() => {
                    actionsPage.openDesincriptionConfirmModal(item);
                  }}
                  styles={commonButtonStyles.deleteButtonLink}
                >
                  Désinscrire du jury
                </Link>
              )}
            </Stack>
            <WaitingMECBadge candidature={item} />
          </>
        );
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des demandes d'inscription aux jurys de certification"
        explain="Veuillez valider ou invalider les demandes d'inscription aux jurys de certification"
      >
        <ValidationCandidatureListFilters />

        {(items.length > 1 ||
          validationCandidatureList?.meta.current_page != 1) && (
          <Can I={userAccessEnum.CHANGER_DEMANDE_INSCRIPTION_EN_MASSE}>
            <div style={{ height: 46 }}>
              <DefaultButton
                text="Changement de demande d'inscription en masse"
                styles={commonButtonStyles.defaultButton}
                style={{ color: primaryColor, borderColor: primaryColor }}
                onClick={() =>
                  actionsPage.openModifyCandidatureModal(null, true)
                }
              />
            </div>
          </Can>
        )}

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />

        <Pagination
          changePage={actionsPage.changePage}
          page={validationCandidatureList?.meta.current_page}
          totalPages={
            Math.ceil(validationCandidatureList?.meta.total / 15) || 1
          }
        />
      </Page>
    </Stack>
  );
}

export default ValidationCandidatureList;
