import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteSubGroupOfRequest } from '../../services/subGroupOfServices'

function useSubGroupOfMutations() {
    const queryClient = useQueryClient();

    const [subGroupOfUuid, setSubGroupOfUuid] = useState(null);

    const mutations = {
        DeleteSubGroupOf: useMutation(deleteSubGroupOfRequest, {
            onSuccess: () => queryClient.invalidateQueries('subGroupOfs'),
          }),
    }

    return {
        subGroupOfUuid,
        setSubGroupOfUuid,
        mutations
    }
}

export default useSubGroupOfMutations