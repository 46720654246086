import FormSection from "../../Common/FormSection/FormSection";
import Page from "../../Common/Page/Page";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../styles";
import { Stack, TextField, Text } from "@fluentui/react";
import CancelButton from "../../Common/CancelButton/CancelButton";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useAccountInformations from "./useAccountInformations";

function AccountInformation() {
  const {
    reactHookProps: {
      handleSubmit,
      formState: { errors },
      control,
      watch,
      setError,
      clearErrors,
    },
    actions,
    isLoading,
    user,
  } = useAccountInformations();
  const onInvalid = (errors: any) => console.error(errors);
  return (
    <Page
      title="Informations de mon compte"
      explain={"Vous pouvez mettre à jour les informations de votre compte"}
    >
      <form onSubmit={handleSubmit(actions.submitForm, onInvalid)}>
        <FormSection sectionTitle="Informations générales">
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Nom :"
                type="text"
                {...field}
                placeholder="Ex: Dupont"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            control={control}
            name="nom"
            defaultValue={user.nom}
          />
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Prénom :"
                type="text"
                {...field}
                placeholder="Ex: Pierre"
                errorMessage={(errors[field.name] as any)?.message}
              />
            )}
            control={control}
            name="prenom"
            defaultValue={user.prenom}
          />
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Numéro de téléphone :"
                type="text"
                {...field}
                maxLength={10}
                minLength={10}
                errorMessage={(errors["telephone_portable"] as any)?.message}
              />
            )}
            control={control}
            name="telephone_portable"
            defaultValue={user.telephone_portable ? user.telephone_portable : ""}
          />
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Email :"
                type="text"
                {...field}
                placeholder="Ex: Pierre.Dupont@exemple.com"
                errorMessage={(errors["email"] as any)?.message}
              />
            )}
            control={control}
            name="email"
            defaultValue={user.email}
          />
        </FormSection>
        <FormSection sectionTitle="Changer mon mot de passe">
          <Text variant="small">
            Vous pouvez laisser le champ vide si vous ne souhaitez pas y apporter de modification
          </Text>
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Mot de passe actuel :"
                type="password"
                {...field}
                errorMessage={(errors["actuel_mot_de_passe"] as any)?.message}
              />
            )}
            control={control}
            name="actuel_mot_de_passe"
          />
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Nouveau mot de passe :"
                type="password"
                {...field}
                errorMessage={(errors["nouveau_mot_de_passe"] as any)?.message}
              />
            )}
            control={control}
            name="nouveau_mot_de_passe"
          />
          <Controller
            render={({ field }) => (
              <TextField
                styles={commonInputStyles.textField}
                label="Confirmez votre mot de passe :"
                type="password"
                {...field}
                onBlur={() => {
                  if (field.value != watch("nouveau_mot_de_passe")) {
                    setError("confirm_mot_de_passe", {
                      message: "Le mot de passe ne correspond pas",
                    });
                  } else {
                    clearErrors("confirm_mot_de_passe");
                  }
                }}
                errorMessage={(errors["confirm_mot_de_passe"] as any)?.message}
              />
            )}
            control={control}
            name="confirm_mot_de_passe"
          />
        </FormSection>
        <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
          <CancelButton />

          <LoadButton isLoading={isLoading} text={"Valider les modifications"} />
        </Stack>
      </form>
    </Page>
  );
}

export default AccountInformation;
