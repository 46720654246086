import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";

const eCertifApi = axiosInstance;

const certificationURI = "administration/certifications/certifications";

export const getCertificationsRequest = async (
  filters: any,
  options: any,
  branchId: any
) => {
  const filterType = {
    libelle: { type: "string" },
    active: { type: "select", keyValue: "value", valueNotFilter: undefined },
    type: {
      type: "select",
      keyValue: "key",
      keyUrl: "types[0][type]",
      valueNotFilter: 0,
    },
    type_libelle: { type: "string", keyUrl: "types[0][type_libelle]" },
    blocs_competences: { type: "array", keyValue: "uuid" },
    domaines: { type: "array", keyValue: "id", keyUrl: "domaines" },
    certifications_limites: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${certificationURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}&branche_creatrice_id=${branchId}`
  );
  return response.data;
};

export const getCertificationsCanDemandeHabilitationRequest = async (
  options: any,
  branchId: any
) => {
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${certificationURI}/chercher/public?${queryOptionsUrl}&branche_creatrice_id=${branchId}&active=true&certifications_limites=false`
  );
  return response.data;
};

export const getCertificationRequest = async (certificationUuid: any) => {
  const response = await eCertifApi.get(
    `${certificationURI}/${certificationUuid}`
  );
  return response.data;
};

export const postCertificationRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${certificationURI}`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const postCertificationOnDomaineRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${certificationURI}/domaine`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putCertificationRequest = async (certification: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${certificationURI}/${certification.get("uuid")}`,
    certification,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateCertificationRequest = async (
  certificationUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${certificationURI}/${certificationUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateCertificationRequest = async (
  certificationUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${certificationURI}/${certificationUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteCertificationRequest = async (certificationUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${certificationURI}/${certificationUuid}`,
    xsrfHeader
  );
  return response;
};

export const getPorteursRequest = async (filters: any, options: any) => {
  const filterType = {
    nom: { type: "string" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${certificationURI}/porteurs/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};
