import { Stack } from "@fluentui/react";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import useBranchDetail, { BranchContext } from "./useBranchDetail";
import Page from "../../Common/Page/Page";
import { useEffect } from "react";
import PageMessage from "../../Common/PageMessage/PageMessage";
import LoadButton from "../../Common/LoadButton/LoadButton";
import CancelButton from "../../Common/CancelButton/CancelButton";
import branchAdapters from "../../adapters/branchAdapters";
import useConstants from "../../hooks/useConstants";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import GeneralInformationsSection from "./FormSections/GeneralInformationsSection";
import GraphicInformationsSection from "./FormSections/GraphicInformationsSection";
import SupplementaryInformationsSection from "./FormSections/SupplementaryInformationsSection";

function BranchDetail() {
  const { constants } = useConstants();

  const { id } = useParams();

  const {
    branch,
    branchProps,
    actions: actionsPage,
    reactHookProps,
    isLoadingBranch,
  } = useBranchDetail();

  const {
    actions,
    isLoading,
    isErrorPutBranch,
    isErrorPostBranch,
    errorPutBranch,
    errorPostBranch,
  } = branchProps;

  const { reset, clearErrors, handleSubmit, setError, register, formState:{errors} } =
    reactHookProps;

  console.log('errors',errors);
  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (id !== "creer") {
      actions.setUpdateBranchId(id);
    }
  }, [id]);

  useEffect(() => {
    if (branch?.id) {
      reset(branchAdapters.transformForBO(branch, constants["COLORS"]));
    }
  }, [branch]);

  useEffect(() => {
    var apiErrors = (errorPostBranch as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostBranch]);

  useEffect(() => {
    var apiErrors = (errorPutBranch as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutBranch]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.saveBranch)(e);
  };

  return (
    <BranchContext.Provider
      value={{
        actions: actionsPage,
        reactHookProps,
        branch,
      }}
    >
      <Stack>
        <BackLink />

        <Page
          title="Détail de la branche"
          explain={
            !branch?.id
              ? "Veuillez créer une nouvelle branche qui sera intégrée à l'application"
              : "Veuillez modifier les informations de la branche"
          }
        >
          <DetailLoader isLoadingDetail={isLoadingBranch}>
            <form onSubmit={onSubmit}>
              <input
                type="hidden"
                {...register("_method", {
                  value: !branch?.id ? "POST" : "PUT",
                })}
              />

              <GeneralInformationsSection />

              <GraphicInformationsSection />

              {branch?.id && <SupplementaryInformationsSection />}

              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginTop: 20 }}
              >
                <CancelButton />

                <LoadButton
                  text={!branch?.id ? "Créer" : "Modifier"}
                  isLoading={isLoading}
                />
              </Stack>
            </form>

            {isErrorPostBranch && (
              <PageMessage
                message={(errorPostBranch as any).response.data.message}
                type="error"
              />
            )}

            {isErrorPutBranch && (
              <PageMessage
                message={(errorPutBranch as any).response.data.message}
                type="error"
              />
            )}
          </DetailLoader>
        </Page>
      </Stack>
    </BranchContext.Provider>
  );
}

export default BranchDetail;
