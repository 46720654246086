import { useQuery } from "react-query"
import { getDomaineRequest } from "../../services/domaineServices"

function useDomaineQuery(domaineProps: any) {
    const { data: domaine, isLoading, isRefetching } = useQuery(
        ["domaines", domaineProps.domaineUuid],
        () => getDomaineRequest(domaineProps.domaineUuid),
        {
            enabled: !!domaineProps.domaineUuid,
            retry: 0,
            refetchOnWindowFocus: false,
        }
    )

    return {
        domaine,
        isLoading,
        isRefetching
    }
}

export default useDomaineQuery