import Joi from "joi";

export const companySchema = Joi.object({
  nom: Joi.string().required().max(150).messages({
    "any.required": "Veuillez renseigner une raison sociale",
    "string.empty": "Veuillez renseigner une raison sociale",
    "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
  }),
  siret: Joi.string().required().length(14).regex(/^\d+$/).messages({
    "any.required": "Veuillez renseigner un numéro SIRET",
    "string.empty": "Veuillez renseigner un numéro SIRET",
    "string.length": "Veuillez renseigner un numéro à 14 chiffres",
    "string.pattern.base": "Le siret ne doit comporter que des chiffres",
  }),
  // training_organizations: Joi.array()
  //   .items(
  //     Joi.object().keys({
  //       uuid: Joi.string().required(),
  //     }).unknown(true)
  //   )
  //   .min(1)
  //   .messages({
  //     "any.required": "Veuillez choisir au moins un organisme de formation",
  //     "array.min": "Veuillez choisir au moins un organisme de formation",
  //   }),
  adresse: Joi.string().required().messages({
    "any.required": "Veuillez indiquer votre adresse",
    "string.empty": "Veuillez renseigner votre adresse",
  }),
  complement_adresse: Joi.string().allow("", null),
  code_postal: Joi.string().required().messages({
    "any.required": "Veuillez indiquer votre code postal",
    "string.empty": "Veuillez renseigner votre code postal",
  }),
  region: Joi.string().required().messages({
    "any.required": "Veuillez indiquer votre région",
    "string.empty": "Veuillez renseigner votre région",
  }),
  ville: Joi.string().required().messages({
    "any.required": "Veuillez indiquer votre ville",
    "string.empty": "Veuillez renseigner votre ville",
  }),
  // appraisers: Joi.array()
  //   .items(
  //     Joi.object().keys({
  //       uuid: Joi.string().required(),
  //     }).unknown(true)
  //   )
  //   .messages({
  //     "any.required": "Veuillez choisir au moins un organisme de formation",
  //     "array.min": "Veuillez choisir au moins un organisme de formation",
  //   }),
  // positioners: Joi.array()
  //   .items(
  //     Joi.object().keys({
  //       uuid: Joi.string().required(),
  //     }).unknown(true)
  //   )
  //   .messages({
  //     "any.required": "Veuillez choisir au moins un organisme de formation",
  //     "array.min": "Veuillez choisir au moins un organisme de formation",
  //   }),
  // group: Joi.string().allow("", null),
  // sub_groups: Joi.array()
  //   .items(
  //     Joi.object().keys({
  //       uuid: Joi.string().required(),
  //     }).unknown(true)
  //   )
  //   .messages({
  //     "any.required": "Veuillez choisir au moins un organisme de formation",
  //     "array.min": "Veuillez choisir au moins un organisme de formation",
  //   }),
}).unknown(true);
