import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import { useAtom } from "jotai";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

function usePriseConnaissance() {
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const { candidature, actions: candidatureActions } = useCandidature();
  const { actions: accessActions } = useAccessSettings();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const { auth } = useAuth();

  const isCandidat = candidature.candidat.utilisateur_uuid === auth.user.uuid;

  const reactHookProps = useForm({
    mode: "onBlur",
  });

  const actions = {
    onSubmit: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidature(
        candidature,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
  };

  return {
    candidature,
    candidatureActions,
    accessActions,
    reactHookProps,
    isOpenedInModal,
    isCandidat,
    actions,
    isSaving,
  };
}

export default usePriseConnaissance;
