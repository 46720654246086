import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";
const eCertifApi = axiosInstance;

const groupCompanyfURI = "administration/groupe-entreprises";

export const getGroupCompaniesRequest = async (filters: any, options: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siren: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };
  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${groupCompanyfURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getGroupCompanyRequest = async (groupCompanyUuid: any) => {
  const response = await eCertifApi.get(
    `${groupCompanyfURI}/${groupCompanyUuid}`
  );
  return response.data;
};

export const postGroupCompanyRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${groupCompanyfURI}`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putGroupCompanyRequest = async (groupCompany: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${groupCompanyfURI}/${groupCompany.uuid}`,
    groupCompany,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateGroupCompanyRequest = async (
  groupCompanyUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${groupCompanyfURI}/${groupCompanyUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateGroupCompanyRequest = async (
  groupCompanyUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${groupCompanyfURI}/${groupCompanyUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteGroupCompanyRequest = async (groupCompanyUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${groupCompanyfURI}/${groupCompanyUuid}`,
    xsrfHeader
  );
  return response;
};

export const verifyGroupCompanySIREN = async (siren: string) => {
  const response = await eCertifApi.get(`${groupCompanyfURI}/existe/${siren}`);
  return response.data;
};

export const getExportGroupCompanies = async (filters: any) => {
  const filterType = {
    raison_sociale: { type: "string" },
    siren: { type: "string" },
    actif: { type: "select", keyValue: "value", valueNotFilter: undefined },
    interbranche: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `exports/groupe-entreprises?paginate=false${queryFiltersUrl}`
    );
    return true;
  } catch (err) {
    throw err;
  }
};
