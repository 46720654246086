import { Stack } from "@fluentui/react";
import { useAtom } from "jotai";
import { useState } from "react";
import { GoAlert } from "react-icons/go";
import { Link as RouterLink } from "react-router-dom";
import { isConsultCandidaturesListAtom } from "../../Pages/CandidatJuryList/useCandidatureJuryList";
import CandidatureWorkflow from "../../Pages/CandidatureWorkflow/CandidatureWorkflow";
import { isEditModeAtom } from "../../Pages/CandidatureWorkflow/Common/MiseEnConformite/useMiseEnConfirmite";
import useUrlParams from "../../Pages/CandidatureWorkflow/useUrlParams";
import useWorkflowNavigate from "../../Pages/CandidatureWorkflow/useWorkflowNavigate";
import { isLoadingCandidatureAtom } from "../../hooks/candidature/useCandidature";
import useOfflineCandidaturePile from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import tools from "../../utils/tools";
import Loader from "../Loader/Loader";
import useWorkflowModal from "../Overlays/CustomModal/useWorkflowModal";
import useTheme from "../useTheme";

function SliderCustom({
  steps,
  subSteps,
  currentSubStep,
  activeSubStep,
  disabled,
  subStepsWithAlert = [],
}: any) {
  const { primaryColor } = useTheme();
  const { navigate } = useWorkflowNavigate();
  const [currentStepName, setCurrentStepName] = useState(
    currentSubStep.stepName
  );
  const { isOffline } = useOfflineCandidaturePile();
  const [isEditMode] = useAtom(isEditModeAtom);
  const { isEmbeded, id_candidature } = useUrlParams();
  const onChangeCategory = (stepName: any) => {
    if (disabled) return;
    setCurrentStepName(stepName);
  };
  const { actions } = useWorkflowModal();
  const [isLoadingCandidature] = useAtom(isLoadingCandidatureAtom);

  const activeSubStepRef: any = tools.findIn(subSteps, activeSubStep, "id");
  const filteredSubSteps = subSteps.filter(
    (subStep: any) => subStep.stepName == currentStepName
  );

  const colors = {
    normal: primaryColor,
    light: tools.lightenDarkenColor(primaryColor, 50),
    lighter: tools.lightenDarkenColor(primaryColor, 80),
    dark: tools.lightenDarkenColor(primaryColor, 0),
    darker: tools.lightenDarkenColor(primaryColor, 15),
  };

  const openStepInModal = (step: any) => {
    actions.reOpenModal({
      body: (
        <CandidatureWorkflow
          statut_candidature={step}
          id_candidature={id_candidature}
        />
      ),
    });
  };

  return isOffline ? null : (
    <div
      className="sliderWrapper"
      style={
        {
          "--branch-color": colors.normal,
          "--branch-color-light": colors.light,
          "--branch-color-lighter": colors.lighter,
          "--branch-color-dark": colors.dark,
          "--branch-color-darker": colors.darker,
        } as React.CSSProperties
      }
    >
      <Stack className="sliderContainer">
        <Stack
          horizontal
          verticalAlign="stretch"
          horizontalAlign="stretch"
          style={{ marginBottom: 6 }}
        >
          {steps.map((step: any, index: any) => {
            const hasAlert = subStepsWithAlert.find((s: any) =>
              step.statuses.find((ss: any) => ss.id === s.statut)
            );

            return (
              <SliderFirstStepButton
                text={step.name}
                onClick={() => {
                  onChangeCategory(step.name);
                  if (step.statuses.length == 1) {
                    navigate(
                      isEmbeded
                        ? {
                            statut_candidature: step.statuses[0].id,
                            id_candidature: id_candidature,
                          }
                        : step.statuses[0].url
                    );
                  }
                }}
                isDisabled={
                  step.statuses[0].order > activeSubStepRef.order ||
                  (isEditMode && currentStepName !== step.name)
                }
                isSelected={currentStepName === step.name}
                isActive={step.name === currentSubStep.stepName}
                isLast={index === steps.length - 1}
                key={`step${step.name}`}
                withAlert={hasAlert}
                isLoading={isLoadingCandidature}
              />
            );
          })}
        </Stack>

        <Stack horizontal verticalAlign="stretch" horizontalAlign="stretch">
          {filteredSubSteps.map((subStep: any, index: number) => {
            const hasAlert = subStepsWithAlert.find(
              (s: any) => subStep.id === s.statut
            );
            return (
              <SliderLinkButton
                text={subStep.text}
                to={subStep.url}
                isActive={subStep.id === currentSubStep.id}
                isDisabled={
                  subStep.order > activeSubStepRef.order ||
                  (isEditMode && subStep.id !== currentSubStep.id)
                }
                isLast={index === filteredSubSteps.length - 1}
                key={`substep${subStep.id}${index}`}
                onClick={isEmbeded ? () => openStepInModal(subStep.id) : null}
                withAlert={hasAlert}
                isLoading={isLoadingCandidature}
              />
            );
          })}
        </Stack>
      </Stack>
    </div>
  );
}

const SliderFirstStepButton = ({
  onClick,
  text,
  isDisabled,
  isSelected,
  isActive,
  isLast,
  withAlert = false,
  isLoading,
}: any) => {
  let buttonClassNames = [];

  if (isDisabled) {
    buttonClassNames.push("item-disabled");
  }
  if (isSelected) {
    buttonClassNames.push("item-selected");
  }
  if (isActive) {
    buttonClassNames.push("item-active");
  }

  const isActiveAndLoading = isLoading && isActive;
  const loadingStyle: any = isActiveAndLoading
    ? { position: "relative", opacity: 0.5, transition: "opacity 0.3s" }
    : {};

  return (
    <div
      className={`sliderItem  ${buttonClassNames.join(" ")}`}
      onClick={isDisabled ? () => {} : onClick}
      style={{
        ...loadingStyle,
      }}
    >
      {isActiveAndLoading && <LoaderSlider />}
      <span>{text}</span>
      {withAlert && (
        <GoAlert
          title={withAlert.title}
          style={{
            marginLeft: "5px",
            color: "red",
            position: "absolute",
            top: "-6px",
            right: 10,
            zIndex: 20,
            fontSize: "18px",
          }}
        />
      )}
    </div>
  );
};

const SliderLinkButton = ({
  text,
  to,
  isActive,
  isDisabled,
  isLast,
  onClick,
  withAlert = false,
  isLoading,
}: any) => {
  const [isConsultCandidaturesList] = useAtom(isConsultCandidaturesListAtom);
  let buttonClassNames = [];

  if (isDisabled) {
    buttonClassNames.push("item-disabled");
  }

  if (isActive) {
    buttonClassNames.push("item-active");
  }
  const isActiveAndLoading = isLoading && isActive;
  const loadingStyle: any = isActiveAndLoading
    ? { position: "relative", opacity: 0.5, transition: "opacity 0.3s" }
    : {};

  if (onClick) {
    return (
      <button
        className={`sliderItem sliderItemSmall ${buttonClassNames.join(" ")}`}
        style={{
          textDecoration: "none",
          cursor: isDisabled ? "not-allowed" : "pointer",
          marginBottom: 2,
          border: 0,
          ...loadingStyle,
        }}
        type="button"
        onClick={onClick}
        disabled={isDisabled}
      >
        {isActiveAndLoading && <LoaderSlider />}
        <span>{text}</span>
        {withAlert && (
          <GoAlert
            title={withAlert.title}
            style={{
              marginLeft: "5px",
              color: "red",
              position: "absolute",
              top: "-6px",
              right: 10,
              zIndex: 20,
              fontSize: "18px",
            }}
          />
        )}
      </button>
    );
  }

  return (
    <RouterLink
      className={`sliderItem sliderItemSmall ${buttonClassNames.join(" ")}`}
      to={isDisabled ? "#" : isConsultCandidaturesList ? `${to}/true` : to}
      style={{
        textDecoration: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
        marginBottom: 2,
        ...loadingStyle,
      }}
    >
      {isActiveAndLoading && <LoaderSlider />}
      <span>{text}</span>
      {withAlert && (
        <GoAlert
          title={withAlert.title}
          style={{
            marginLeft: "5px",
            color: "red",
            position: "absolute",
            top: "-6px",
            right: 10,
            zIndex: 20,
            fontSize: "18px",
          }}
        />
      )}
    </RouterLink>
  );
};

const LoaderSlider = () => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
      }}
    >
      <Loader style={{ color: "white" }} />
    </div>
  );
};

export default SliderCustom;
