import {
  useQuery
} from "react-query";
import {
  getBranchesRequest
} from "../../services/branchServices";
import {
  useAtom
}
  from "jotai";
  import { atomWithReset } from "jotai/utils"
import tools from "../../utils/tools";
import useBranch from "../../hooks/useBranch";
import { useForm } from "react-hook-form";

export const initialFilters = {
  nom: "",
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useBranchList() {
  const branchProps = useBranch()
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const reactHookProps = useForm({
    mode: 'onBlur',
    defaultValues:filters,
  })
  
  const { data: branches, isLoading: isLoadingBranches } = useQuery(
    ["branches", optionsFilters],
    () => {
      return getBranchesRequest(filters, optionsFilters);
    },
    { retry: 0 }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page
      });
    },
    changeFilter: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({ ...optionsFilters, page: 1, searchAt: new Date().toISOString() })
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(',')
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      })
    }
  };

  return {
    branches,
    branchProps,
    isLoadingBranches,
    optionsFilters,
    actions,
    reactHookProps
  };
}

export default useBranchList;
