import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

export const jurysURI = "candidature/jurys";

export const getJurysRequest = async (filters: any, options: any) => {
  const filterType = {
    libelle: { type: "string" },
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    date_debut: { type: "string", valueNotFilter: null },
    date_fin: { type: "string", valueNotFilter: null },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${jurysURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getJuryRequest = async (juryUuid: any) => {
  const response = await eCertifApi.get(`${jurysURI}/${juryUuid}`);
  return response.data;
};

export const postJuryRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${jurysURI}`, data, xsrfHeader);
  return response.data;
};

export const putJuryRequest = async (jury: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${jurysURI}/${jury.get("uuid")}`,
    jury,
    xsrfHeader
  );

  return response.data;
};

export const putCloseJury = async (juryUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${jurysURI}/${juryUuid}/cloturer`,
    xsrfHeader
  );

  return response.data;
};

export const postRegenerateDocuments = async (juryUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${jurysURI}/${juryUuid}/regenerer-candidatures-documents`,
    xsrfHeader
  );

  return response.data;
};

export const getJuryAvailableForCandidature = async (filters: any) => {
  const filterType = {
    libelle: { type: "string" },
    date_jury: { type: "string", valueNotFilter: undefined },
  };
  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  const response = await eCertifApi.get(
    `${jurysURI}/chercher-disponible-inscription-candidature?${queryFiltersUrl}`
  );
  return response.data.data;
};

export const putJuryAddCandidats: any = async ({
  juryUuid,
  candidats,
}: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${jurysURI}/${juryUuid}/inscrire-candidatures`,
    candidats,
    xsrfHeader
  );
  return response.data;
};

export const getDocumentsJuryCandidats: any = async (data: any) => {
  const response = await axiosInstance.post(
    `candidature/jurys/${data.jury_associe}/candidatures-documents`,
    data,
    //NECESSAIRE OU ERREUR LORS DE LA DECOMPRESSION DU ZIP
    {
      responseType: "blob",
    }
  );
  return response;
};

export const deleteJuryRequest = async (juryUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${jurysURI}/${juryUuid}`,
    xsrfHeader
  );

  return response.data;
};
