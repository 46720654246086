import {
  CommandButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import React, { useContext } from "react";
import Can from "../../../../Common/Can/Can";
import { userAccessEnum } from "../../../../config/accessEnum";
import tools from "../../../../utils/tools";
import { ParcoursCandidatContext } from "../useParcoursCandidat";

const FormationList = ({ formations = [], openFormation, isInitial, inputsDisabled }: any) => {
  const { actions }: any = useContext(ParcoursCandidatContext);

  const columns: IColumn[] = [
    {
      key: `1${isInitial ? "fi" : "fc"}`,
      name: "Intitulé",
      fieldName: "intitule",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: `2${isInitial ? "fi" : "fc"}`,
      name: "Niveau",
      fieldName: "niveau",
      minWidth: 100,
      maxWidth: 250,
      isResizable: true,
      onRender: (item: any) => <Stack>{item.niveau_formation?.text}</Stack>,
    },
    {
      key: `3${isInitial ? "fi" : "fc"}`,
      name: "Établissement",
      fieldName: "etablissement",
      minWidth: 100,
      maxWidth: 350,
      isResizable: true,
    },
    {
      key: `4${isInitial ? "fi" : "fc"}`,
      name: "Date de début",
      fieldName: "date_debut",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Stack>{tools.formatDate(item.date_debut)}</Stack>,
    },
    {
      key: `5${isInitial ? "fi" : "fc"}`,
      name: "Date fin",
      fieldName: "date_fin",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      onRender: (item: any) => <Stack>{tools.formatDate(item.date_fin)}</Stack>,
    },
    {
      key: `6${isInitial ? "fi" : "fc"}`,
      name: "Actions",
      fieldName: "actions",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => (
        <Stack horizontal wrap>
          <Can I={[userAccessEnum.MODIFIER_PARCOURS_CANDIDAT]}>
            <>
              <CommandButton onClick={() => openFormation(item, isInitial, inputsDisabled)}>Editer</CommandButton>
              {!inputsDisabled &&
                <CommandButton onClick={() => actions.onRemoveFormation(item, isInitial)}>
                  Supprimer
                </CommandButton>
              }
            </>
          </Can>
        </Stack>
      ),
    },
  ];

  return (
    <DetailsList
      layoutMode={DetailsListLayoutMode.justified}
      items={formations}
      columns={columns}
      selectionMode={SelectionMode.none}
      styles={{
        root: {
          selectors: {
            ".ms-DetailsHeader": {
              backgroundColor: "rgba(255,255,255,0)",
            },
            ".ms-DetailsRow": {
              backgroundColor: "rgba(255,255,255,0)",
            },
          },
        },
      }}
    />
  );
};

export default FormationList;
