import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";
import tools from "../utils/tools";

const eCertifApi = axiosInstance;
const habilitationURI = "administration/habilitations";

export const getHabilitationOfRequest = async (filters: any, options: any) => {
  const filterType = {
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    certification: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
    of: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${habilitationURI}/simple/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getHabilitationsOfDetailRequest = async (
  ofUuid: any,
  options: any
) => {
  const filterType = {
    of: { type: "string" },
  };

  const queryFiltersUrl = tools.generateFilterUrl({ of: ofUuid }, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${habilitationURI}/simple/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const postHabilitationOfRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${habilitationURI}/simple`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const getHabilitationUtilisateurRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    statut: { type: "select", keyValue: "id", valueNotFilter: undefined },
    certification: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
    },
    positionneur: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
    evaluateur: {
      type: "select",
      keyValue: "uuid",
      valueNotFilter: undefined,
    },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${habilitationURI}/stricte/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getHabilitationsUtilisateurDetailRequest = async (
  userFilter: any,
  options: any
) => {
  const filterType = {
    positionneur: { type: "string" },
    evaluateur: { type: "string" },
  };
  const queryFiltersUrl = tools.generateFilterUrl(userFilter, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${habilitationURI}/stricte/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const postHabilitationUtilisateurRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${habilitationURI}/stricte`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const removeHabilitationsRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${habilitationURI}/retirer`,
    data,
    xsrfHeader
  );
  return response.data;
};
