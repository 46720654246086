import {
    Stack,
    TextField,
    DefaultButton,
} from '@fluentui/react'
import {
    Controller,
} from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useBranchList from '../useBranchList';

import { commonButtonStyles, commonInputStyles } from '../../../styles/index'
import FormSection from '../../../Common/FormSection/FormSection';
import TextResult from '../../../Common/TextResult/TextResult';
function BranchListFilters() {
    const { branches, isLoadingBranches, actions, reactHookProps } = useBranchList();

    const totalBranches = branches?.meta.total || 0;

    const { handleSubmit, control } = reactHookProps

    const onReset = () => {
        actions.resetFilters();
        handleSubmit(actions.changeFilter)();
    }

    return (
        <FormSection
            sectionTitle="Filtres de recherche"
        >
            <form
                onSubmit={handleSubmit(actions.changeFilter)}
            >

                <Stack
                    style={{ marginBottom: 24 }}
                    horizontalAlign="start"
                    horizontal
                    wrap
                    className="ms-Grid"
                >
                    <Stack.Item
                        className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                        style={{ paddingRight: 20, marginTop: 10 }}
                    >
                        <Controller
                            name="nom"
                            defaultValue=""
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Nom de la branche :"
                                    styles={commonInputStyles.textField}
                                    type="text"
                                    {...field}
                                />
                            )}
                        />
                    </Stack.Item>


                </Stack>

                <Stack
                    horizontal
                    horizontalAlign='space-between'
                    verticalAlign='end'
                    wrap
                >
                    <Stack
                        tokens={{ childrenGap: 15 }}
                        horizontal
                        wrap
                    >
                        <Stack.Item>
                            <LoadButton
                                isLoading={isLoadingBranches} text="Rechercher"
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton text="Réinitialiser ma recherche"
                                styles={commonButtonStyles.defaultButton}
                                onClick={onReset}
                            />
                        </Stack.Item>
                    </Stack>

                    <TextResult total={totalBranches} />

                </Stack>


            </form>
        </FormSection>
    )
}

export default BranchListFilters
