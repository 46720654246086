import { atom, useAtom } from "jotai";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import branchSettingsAdapters from "../../adapters/branchSettingsAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import {
  branchAccessEnum,
  branchAccessMessageEnum,
  userAccessEnum,
} from "../../config/accessEnum";
import { branchRolesSettingsAtom } from "../../Pages/RolesManagement/useRolesManagement";
import {
  getBranchSettings,
  putBranchSettings,
} from "../../services/branchServices";
import tools from "../../utils/tools";
import { authAtom } from "../useAuth";
import { selectedBranchIdAtom } from "../useBranchEntities";

export const branchSettingsAtom = atom(null);
// const accessSettingsAtom = atom([
//   userAccessEnum.CREER_UTILISATEUR,
//   userAccessEnum.GERER_UTILISATEUR,
//   userAccessEnum.VISUALISER_TABLEAU_BORD_CANDIDATURES,
//   userAccessEnum.SAISIR_CANDIDATURE,
//   userAccessEnum.VISUALISER_PARCOURS_CANDIDAT,
//   userAccessEnum.PARAMETRER_ENTREPRISES_OF_GROUPES,
//   userAccessEnum.SAISIR_1ER_INFO_CANDIDATURE,
//   userAccessEnum.IMPORT_CANDIDATURE,
//   userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF,
//   userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
//   userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE,
//   userAccessEnum.VISUALISER_POSITIONNEMENT,
//   userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT,
//   userAccessEnum.VISUALISER_ACCUEIL_APRES_CONNEXION
// ]);
//permissions possédées par l'utilisateur
const accessSettingsAtom = atom((get) => {
  const auth: any = get(authAtom);
  const roleSettings: any = get(branchRolesSettingsAtom);
  const branchSettings: any = get(branchSettingsAtom);
  const selectedBranch: any = get(userSelectedBranchAtom);

  let accessArray: any = [];
  //pour l'instant on donne tous les accès à l'utilisateur, il faudra par la suite n'ajouter que celles qui sont liées à son "profil" et la valeur "est_accessible" de la permission
  if (roleSettings && branchSettings && selectedBranch) {
    accessArray = [
      ...accessArray,
      ...roleSettings
        .filter((r: any) =>
          auth.user.est_administrateur
            ? true
            : r.est_accessible && selectedBranch.profils.includes(r.profil)
        )
        .map((r: any) => r.acces),
      ...branchSettings.map((r: any) => r.parametre),
    ];
    if (auth.user.est_administrateur) {
      accessArray.push("NO_ACCESS");
    }
  }
  return accessArray;
}); //permissions possédées par l'utilisateur TODO : cabler avec l'api
const isLoadingBranchSettingsAtom = atom(false);

const useAccessSettings = () => {
  const [isLoadingBranchSettings, setIsLoadingBranchSettings] = useAtom(
    isLoadingBranchSettingsAtom
  );
  const [branchSettings, setBranchSettings] = useAtom(branchSettingsAtom);
  const [roleSettings]: any = useAtom(branchRolesSettingsAtom);
  const [accessSettings, setAccessSettings]: any =
    useAtom<any>(accessSettingsAtom);
  const [selectedBranchId] = useAtom(selectedBranchIdAtom);
  const reactHookProps = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
  });

  const getParamFromId = (paramId: number) => {
    return tools.findIn(branchSettings, paramId, "parametre") as any;
  };

  //on construit un objet qui inclut toutes les permissions possibles (branche et accès), sous forme de fonctions qui retourneront granted:true/false selon le cas
  let authorizations: any = {};
  Object.keys(branchAccessEnum).forEach((branchAccessKey: any) => {
    authorizations[branchAccessEnum[branchAccessKey]] = () => {
      return {
        granted: getParamFromId(branchAccessEnum[branchAccessKey]).valeur,
        message: branchAccessMessageEnum[branchAccessKey],
      };
    };
  });

  Object.keys(userAccessEnum).forEach((access: any) => {
    let entry = userAccessEnum[access];
    authorizations[entry] = () => ({ granted: accessSettings.includes(entry) });
  });

  const actions = {
    addAcces: (newAcces: any) => {
      setAccessSettings([...accessSettings, newAcces]);
    },
    removeAcces: (removeAcces: any) => {
      setAccessSettings([
        ...accessSettings.filter((acces: any) => acces != removeAcces),
      ]);
    },
    //------------- added GodAccesList
    getCurrentBranchSettings: async () => {
      const settings = await getBranchSettings();

      setBranchSettings(settings);
    },
    saveCurrentBranchSettings: async (newBranchSettings: any) => {
      setIsLoadingBranchSettings(true);
      try {
        const settings = await putBranchSettings(
          branchSettingsAdapters.transformForAPI(
            newBranchSettings,
            branchSettings
          )
        );
        setBranchSettings(settings);
        toast.success("Les paramètres de la branche ont bien été mis à jour.");
        window.location.reload();
      } catch (err: any) {
        if (err.response?.data?.message) {
          toast.error(err.response?.data?.message);
        }
      }
      setIsLoadingBranchSettings(false);
    },
    canI: ({ action, params }: any) => {
      if (action === "*") {
        return { granted: true };
      }
      if (typeof action === "string") {
        if (authorizations[action]) return authorizations[action]();
      }
      if (typeof action === "object") {
        for (var key in action) {
          if (authorizations[action[key]]) {
            let res = authorizations[action[key]]();
            if (res.granted) {
              return { granted: true };
            }
          }
        }
        return { granted: false };
      }
      return { granted: false };
    },
    isRouteAccessAllowed: (allowedAccess: any) => {
      let accessGranted = false;
      if (allowedAccess.includes("*")) return true;
      allowedAccess.forEach((access: any) => {
        const checkAuthorizationResult = actions.canI({ action: access });
        if (checkAuthorizationResult?.granted) {
          accessGranted = true;
        }
      });
      return accessGranted;
    },
  };

  return {
    accessSettings,
    roleSettings,
    branchSettings,
    reactHookProps,
    isLoadingBranchSettings,
    actions,
  };
};

export default useAccessSettings;
