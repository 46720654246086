export const candidatInformationsStyles = {
  imgContainerExtraInformation: {
    root: {
      borderRadius: "50%",
      height: "110px",
      width: "110px",
    },
  },
  imgContainer: {
    root: {
      borderRadius: "50%",
      height: "20px",
      width: "20px",
    },
  },
};
