import { DefaultButton, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import formSectionStyles from "../../../../Common/FormSection/FormSection.styles";
import LoadButton from "../../../../Common/LoadButton/LoadButton";
import { commonButtonStyles, commonInputStyles } from "../../../../styles";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";
import validations from "../../../../utils/validations";
import { toast } from "react-toastify";

const AddSecondEvaluateurModal = ({ onSave = () => {}, isSaving = false }:any) => {
  const { labels } = useLabelSettings();
  const { actions } = useCustomModal();

  const [errors, setErrors] = useState<any>({});
  const [user, setUser] = useState({
    nom: "",
    prenom: "",
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateSecondEvaluateur = () =>{
    let validationErrors: any = {};
    const validationSecondEvaluateur = validations.validateProfil(user);
    if(validationSecondEvaluateur.error){
      validationSecondEvaluateur.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message
      })
    }
    setErrors(validationErrors)
    let isValid = Object.keys(validationErrors).length === 0;
    if (!isValid) {
      toast.error("Le formulaire comporte des erreurs.");
    };
    return isValid
  }

  const onInviteSecondEvaluateur = ()=>{
    if(validateSecondEvaluateur()){
      onSave(user)
      actions.closeModal()
    }
  }

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Nom :"
          type="text"
          placeholder="Ex : Doe"
          name="nom"
          onChange={handleChange}
          value={user.nom}
          errorMessage={errors.nom}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Prénom :"
          type="text"
          placeholder="Ex : John"
          name="prenom"
          onChange={handleChange}
          value={user.prenom}
          errorMessage={errors.prenom}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Email :"
          type="text"
          placeholder="Ex : john.doe@email.Fr"
          name="email"
          onChange={handleChange}
          value={user.email}
          errorMessage={errors.email}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>

        <LoadButton
          onClick={onInviteSecondEvaluateur}
          type="button"
          text={labels.INVITE_EVALUATEUR_BTN}
          isLoading={false}
        />
      </Stack.Item>
    </Stack>
  );
};

export default AddSecondEvaluateurModal;
