import { ChoiceGroup, IChoiceGroupOption, Stack, TextField } from "@fluentui/react";
import { FormEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { userAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { commonInputStyles } from "../../../styles";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";

export const SousGroupeOfRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const { actions:accessActions } = useAccessSettings();
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

  const sousGroupeStateRadioOptions: IChoiceGroupOption[] = [{ key: "sous_groupe_existant", text: "Sous-groupe d'OF existant" }];

  if(accessActions.canI({action:userAccessEnum.CREER_SOUS_GROUPE}).granted ){
    sousGroupeStateRadioOptions.push({ key: "nouveau_sous_groupe", text: "Nouveau sous-groupe d'OF" })
  }

  return (
    <ProfileAddionalInformations
      name="SOUS_GROUPE_OF"
      sectionTitle="Informations du profil Sous-groupe d'OF"
      watchRoles={watchRoles}
    >
      <Stack>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Groupe d'OF du sous-groupe :"
              placeholder="Recherchez un groupe d'OF par SIRET ou Raison sociale (ex : 12312312312312 ou Groupe 1)"
              resourceURI={`${API_URL}api/administration/groupe-ofs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">- {item.siren}</span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">- {item.siren}</span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
                setValue('sousGroupeOfProfile.ofs', [], { shouldValidate: true })
              }}
              defaultValue={null}
              errorMessage={((errors.sousGroupeOfProfile as any)?.groupOf as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
              required
            />
          )}
          control={control}
          name="sousGroupeOfProfile.groupOf"
        />

        <Controller
          name="sousGroupeOfProfile.type"
          control={control}
          defaultValue={null}
          render={({ field: { name, value } }) => (
            <ChoiceGroup
              options={sousGroupeStateRadioOptions}
              label="Souhaitez-vous rattacher cet utilisateur à un sous-groupe d'OF existant ou à un nouveau sous-groupe d'OF ? :"
              selectedKey={value ?? null}
              required={true}
              name={name}
              onChange={(
                ev?: FormEvent<HTMLInputElement | HTMLElement>,
                option?: IChoiceGroupOption
              ) => {
                if (option?.key == 'sous_groupe_existant') {
                  setValue('sousGroupeOfProfile.nomSousGroupeOf', '');
                  setValue('sousGroupeOfProfile.ofs', []);
                } else {
                  setValue('sousGroupeOfProfile.sousGroupeOf', null)
                }
                setValue("sousGroupeOfProfile.type", option?.key)
              }}
            />
          )}
        />
        {(watch("sousGroupeOfProfile.type") == "sous_groupe_existant" && watch('sousGroupeOfProfile.groupOf')) && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Sous-groupe d'OF existant à rattacher :"
                placeholder="Recherchez un sous-groupe d'OF par nom (ex: sous-groupe d'OF)"
                resourceURI={`${API_URL}api/administration/sous-groupes/chercher`}
                addQueryURL={(resourceURI: any, terms: any) => `${resourceURI}?type=SOUS_GROUPE_OF&nom=${terms}&paginate=false&groupe=${watch('sousGroupeOfProfile.groupOf').uuid}`}
                method="GET"
                renderItem={(item: any) => item.nom}
                renderValue={(item: any) => item.nom}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                //errorMessage={(errors.code_postal as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="sousGroupeOfProfile.sousGroupeOf"
          />
        )}

        {watch("sousGroupeOfProfile.type") == "nouveau_sous_groupe" && (
          <Stack>
            <Controller
              render={({ field }) => (
                <TextField
                  styles={commonInputStyles.textField}
                  required
                  label="Nom du nouveau sous-groupe d'OF auquel rattacher l'utilisateur : "
                  type="text"
                  {...field}
                  placeholder="Ex: sous-groupe 1"
                  errorMessage={((errors.sousGroupeOfProfile as any)?.nomSousGroupeOf as any)?.message}
                />
              )}
              defaultValue=""
              control={control}
              name="sousGroupeOfProfile.nomSousGroupeOf"
            />

            {watch('sousGroupeOfProfile.groupOf') &&
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectManyFieldAsync
                    label="OF liés au nouveau sous-groupe d'entreprises :"
                    placeholder="Recherchez des OF par SIRET ou raison sociale (ex : 123123123123123 ou OF1)"
                    resourceURI={`${API_URL}api/administration/ofs/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?recherche=${terms}&groupe=${watch('sousGroupeOfProfile.groupOf').uuid}`
                    }
                    method="GET"
                    renderItem={(item: any) => {
                      return (
                        <div>
                          <div>
                            {item.raison_sociale}{" "}
                            <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                          </div>
                        </div>
                      );
                    }}
                    renderValue={(item: any) => {
                      return (
                        <div>
                          <div>
                            {item.raison_sociale}{" "}
                            <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                          </div>
                        </div>
                      );
                    }}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    defaultValue={[]}
                    errorMessage={((errors.sousGroupeOfProfile as any)?.groupOf as any)?.message}
                    handleResponse={(response: any) => [...response.data.data]}
                    required
                  />
                )}
                control={control}
                name="sousGroupeOfProfile.ofs"
              />
            }
          </Stack>
        )}
      </Stack>
    </ProfileAddionalInformations>
  );
};
