const PivotItemContainer = ({ children }: any) => {
  return (
    <div
      style={{
        background: "rgb(248 248 248)",
        padding: "40px",
        marginLeft: "-30px",
        marginRight: "-30px",
        borderTop: "1px solid #dfdfdf",
      }}
    >
      {children}
    </div>
  );
};

export default PivotItemContainer;
