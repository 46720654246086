import { DefaultButton } from "@fluentui/react";
import { useContext } from "react";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import DocumentList from "../DocumentList/DocumentList";

const DocumentsContainer = ({ option }: any) => {
  const { actions, reactHookProps }: any = useContext(CertificationContext);
  const { watch } = reactHookProps;
  const allDocuments = watch("documents") || [] ;
  const { openAddDocumentModal } = actions;

  return (
    <>
      <DefaultButton
        onClick={() => openAddDocumentModal(null, option?.option || null)}
        iconProps={{ iconName: "Add" }}
      >
        Ajouter un document
      </DefaultButton>
      <DocumentList
        documents={
          option ? allDocuments.filter((doc: any) => doc.option === option.option) : allDocuments
        }
        openDocument={openAddDocumentModal}
      />
    </>
  );
};

export default DocumentsContainer;
