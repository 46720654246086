import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { stateList } from "../../fakeData/State.data";
import { getBranchesRequest } from "../../services/branchServices";
import { getAllUsersRequest } from "../../services/userServices";
import tools from "../../utils/tools";

export const initialFilters = {
  nom: "",
  prenom: "",
  email: "",
  actif: stateList[0],
  branches: [],
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: 1,
  searchAt: "",
});
function usePlatformUserList() {
  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [allBranches, setAllBranches] = useState([]);
  const [isLoadingBranches, setIsLoadingBranches] = useState(true);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: usersProfils, isLoading: isLoadingUsers } = useQuery(
    ["platformUsersProfil", optionsFilters],
    () => getAllUsersRequest(filters, optionsFilters),
    { retry: 0 }
  );

  const actions = {
    getAllBranches: async () => {
      try {
        const response = await getBranchesRequest(
          {},
          {
            sort: "nom",
            paginate: false,
          }
        );
        setAllBranches(response.data);
        setIsLoadingBranches(false);
      } catch (e: any) {
        toast.error(
          "Une erreur est survenue lors de la récupération de toutes les branches."
        );
        setIsLoadingBranches(false);
      }
    },
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page,
      });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
  };

  return {
    allBranches,
    reactHookProps,
    usersProfils,
    isLoadingUsers,
    optionsFilters,
    actions,
    isLoadingBranches,
  };
}

export default usePlatformUserList;
