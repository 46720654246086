import {
  useQuery
} from "react-query";
import {
  getStaticPages,
  getBranchStaticPages
} from '../../services/staticPagesServices'
import {
  useAtom
} from "jotai";
import tools from '../../utils/tools'
import React from "react";
import { useForm } from "react-hook-form";
import { atomWithReset } from "jotai/utils";

export const StaticPageContext = React.createContext({})
export const StaticPageProvider = ({ value, children }: any) => {
  return (
    <StaticPageContext.Provider value={value}>
      {children}
    </StaticPageContext.Provider>
  )
}

export const initialFilters = {
  titre: "",
};

export const filtersAtom = atomWithReset<any>(initialFilters)

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});


function useStaticPageList(type: any) {

  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const reactHookProps = useForm({
    mode: "onBlur",
    defaultValues:filters
  });

  var { data: staticPages, isLoading: isLoadingStaticPages } = useQuery(
    ["staticPages", optionsFilters],
    () => type == 'generales' ? getStaticPages(filters, optionsFilters) : getBranchStaticPages(filters, optionsFilters),
    { retry: 0 }
  )

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    changePage: (page: number) => {
      setOptionsFilters({
        ...optionsFilters,
        page
      });
    },
    changeFilter: (newFilters: any) => {
      setFilters(newFilters);
      setOptionsFilters({ ...optionsFilters, page: 1, searchAt: new Date().toISOString() })
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(',')
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      setOptionsFilters({
        ...optionsFilters,
        sort: sortJoin,
      })
    }
  }

  return {
    staticPages,
    isLoadingStaticPages,
    optionsFilters,
    actions,
    reactHookProps,
  }
}

export default useStaticPageList