import { atom, useAtom } from "jotai";

const newFieldAtom = atom({
  type: "",
  label: "",
  multiple: false,
  options: [],
  fichier_modele: null,
  fichier_modele_url: "",
  fichier_formats: [],
  required: false,
  exported: false,
  statut_candidature: { key: "", value: null, text: "Choisissez..." },
});

const useAddFieldForm = () => {
  const [newField, setNewField]: any = useAtom(newFieldAtom);

  const actions = {
    onChangeNewField: (e: any) => {
      const { name, value } = e.target;
      if (name === "type") {
        actions.onResetNewField(value);

        return;
      }
      if (name == "fichier_modele") {
        const updatedField = {
          ...newField,
          [name]: value[0],
          fichier_modele_deleted: value.length <= 0,
          fichier_modele_url: value.length <= 0 ? "" : newField.fichier_modele_url,
        };
        setNewField((f: any) => updatedField);
        return;
      }
      setNewField((n: any) => ({ ...n, [name]: value }));
    },
    onResetNewField: (type?: any) => {
      setNewField({
        type: type || "",
        label: "",
        multiple: false,
        options: [],
        fichier_modele: null,
        fichier_modele_url: "",
        fichier_formats: [],
        required: false,
        exported: false,
        statut_candidature: { key: "", value: null, text: "Choisissez..." },
      });
    },
    onAddOption: (newOption: any) => {
      setNewField({
        ...newField,
        options: [...newField.options, newOption],
      });
    },
    onUpdateOptions: (newOptions: any) => {
      setNewField({
        ...newField,
        options: newOptions,
      });
    },
  };

  return {
    newField,
    setNewField,
    actions,
  };
};

export default useAddFieldForm;
