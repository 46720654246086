import { useEffect, useState } from "react";
import { PrimaryButton, Stack } from "@fluentui/react";
import useAuth, { disconnectRedirectUrlAtom } from "../../../hooks/useAuth";
import DefinePassword from "../Common/DefinePassword";
import FormPassword from "../Common/FormPassword";
import Loader from "../../../Common/Loader/Loader";
import useQuery from "../../../hooks/useQuery";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";

function CreatePassword() {
  const [hasAcceptedMessage, setHasAcceptedMessage] = useState(false);
  let query = useQuery();
  const [disconnectRedirectUrl, setDisconnectRedirectUrl] = useAtom(disconnectRedirectUrlAtom);

  const {
    isAuth,
    isToken,
    isLoadingResetPassword,
    isErrorResetPassword,
    errorResetPassword,
    actions,
    isLoadingGetUser,
  } = useAuth();

  useEffect(() => {}, []);

  const checkToken = () => {
    setHasAcceptedMessage(true);
    if (!isAuth) {
      actions.checkToken({
        email: query.get("email"),
        token: query.get("token"),
      });
    }
  };

  if (isLoadingGetUser) {
    return (
      <Stack
        style={{ width: "100%", minHeight: "94vh", position: "relative" }}
        horizontalAlign="center"
        verticalAlign="center"
      >
        <Loader />
      </Stack>
    );
  }

  return (
    <Stack
      style={{ width: "100%", minHeight: "90vh", background: "#e8e8e8", position: "relative" }}
      horizontalAlign="center"
      verticalAlign="center"
    >
      <Stack
        className="ms-sm12 ms-lg6 ms-xxl3"
        style={{ backgroundColor: "white", padding: "20px 0px" }}
        horizontalAlign="center"
      >
        <RenderComponent
          hasAcceptedMessage={hasAcceptedMessage}
          isToken={isToken}
          isLoadingResetPassword={isLoadingResetPassword}
          isErrorResetPassword={isErrorResetPassword}
          errorResetPassword={errorResetPassword}
          actions={actions}
          isAuth={isAuth}
          checkToken={checkToken}
          disconnectRedirectUrl={disconnectRedirectUrl}
          setDisconnectRedirectUrl={setDisconnectRedirectUrl}
        />
      </Stack>
    </Stack>
  );
}

const RenderComponent = (props: any) => {
  const location = useLocation();
  if (props.isAuth) {
    if (props.disconnectRedirectUrl === "/") {
      props.setDisconnectRedirectUrl(`${location.pathname}${location.search}`);
    }
    return (
      <>
        <p style={{ padding: 20 }}>
          Vous êtes déjà connecté sur un autre compte, pour créer votre mot de passe, veuillez
          d'abord vous déconnecter.
        </p>
        <PrimaryButton onClick={props.actions.logout}>Se déconnecter</PrimaryButton>
      </>
    );
  }
  if (!props.hasAcceptedMessage) {
    return (
      <>
        <DefinePassword
          title="Création du mot de passe"
          definition="Sur cette page vous allez définir le mot de passe de votre compte utilisateur."
        />
        <PrimaryButton onClick={props.checkToken}>Définir mon mot de passe</PrimaryButton>
      </>
    );
  }

  if (props.isToken) {
    return (
      <>
        <DefinePassword
          title="Création du mot de passe"
          definition="Veuillez saisir votre nouveau mot de passe et le confirmer."
        />

        <FormPassword
          onSubmit={props.actions.resetPassword}
          errorAPI={props.errorResetPassword}
          isErrorAPI={props.isErrorResetPassword}
          isLoadingAPI={props.isLoadingResetPassword}
        />
      </>
    );
  }
  return <Loader />;
};

export default CreatePassword;
