import { DefaultButton, Icon, Label, Stack, TextField } from "@fluentui/react";
import { useState } from "react";
import { commonInputStyles } from "../../styles";
import SelectedItem from "./common/SelectedItem/SelectedItem";
import useTheme from "../useTheme";

function DynamicListEditable({
  label, //label global de l'input
  placeholder, //placeholder du champ de recherche
  addButtonLabel,
  errorMessage, //message d'erreur de l'input
  onChange = () => {}, //appelé lorsque la valeur sélectionnée change
  onBlur = () => {},
  value, //objet ex : {name:""}, valeur de l'input
  name, //nom de l'input
  fieldRef,
  required, //si requis
  defaultValue,
  attr,
  validateItem,
  onAddItem = null,
  onRemoveItem = null,
  onEditItem = null,
}: any) {
  const { primaryColor } = useTheme();
  const [terms, setTerms] = useState("");
  const [errors, setErrors] = useState<any>({});

  const handleChangeTerms = (e: any) => {
    setTerms(e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      onAddTerm();
    }
  };

  const onAddTerm = () => {
    if (!terms) return false;
    const newItem = { [attr]: terms };
    if (onAddItem) {
      onAddItem(newItem);
      setTerms("");
      setErrors({});
      return false;
    }
    const validationResult = validateItem(newItem, value);

    if (validationResult.error) {
      setErrors(validationResult.error);
    } else {
      const addedValue = [...value, { ...defaultValue, ...newItem }];
      onChange({ target: { name, value: addedValue } });
      setTerms("");
      setErrors({});
    }
  };

  const hasValue = () => {
    return value.length > 0;
  };

  const removeItem = (itemIndex: number) => {
    if (onRemoveItem) {
      onRemoveItem(value[itemIndex]);
      return false;
    }
    onChange({
      target: { name, value: [...value.filter((v: any, vk: number) => vk !== itemIndex)] },
    });
  };

  return (
    <div>
      <Label required={required} styles={commonInputStyles.label}>
        {label}
      </Label>

      <Stack>
        {hasValue() &&
          value.map((val: string, valkey: number) => {
            const itemKey = `dynli${valkey}${val[attr]}`;
            return (
              <SelectedItem
                key={itemKey}
                removeItem={() => removeItem(valkey)}
                value={val[attr]}
                editItem={(previousName: any, newName: any) => onEditItem(previousName, newName)}
              />
            );
          })}
      </Stack>

      <div style={{ position: "relative" }}>
        <Icon
          className="d-center"
          iconName="Add"
          style={{
            position: "absolute",
            height: "100%",
            color: "grey",
            left: 5,
          }}
        />
        <TextField
          styles={commonInputStyles.searchField}
          placeholder={placeholder}
          onChange={handleChangeTerms}
          value={terms}
          type="text"
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          errorMessage={errorMessage || errors[attr]}
          componentRef={fieldRef}
          name={attr}
        />
        <DefaultButton
          type="button"
          iconProps={{ iconName: "Add" }}
          text={addButtonLabel}
          onClick={onAddTerm}
          style={{
            position: "absolute",
            right: 0,
            top: 4,
            border: 0,
            background: "transparent",
            color: primaryColor,
          }}
        />
      </div>
    </div>
  );
}

export default DynamicListEditable;
