const formSectionStyles = {
  container: {
    root: {
      borderWidth: "1px",
      background: "white",
      borderRadius: "8px",
      padding: "20px 0px",
      marginTop: 30,
      boxShadow: "rgb(193, 193, 193) 0px 4px 6px -4px",
    },
  },
  sectionTitle: {
    root: {
      lineHeight: 1,
      fontSize: "18px",
      borderRadius: "3px",
      padding: "0px 10px",
      marginLeft: "20px",
      fontStyle: "italic",
      fontWeight: "500",
    },
  },
  formContainer: {
    root: {
      padding: "0px 30px",
    },
  },
};

export default formSectionStyles;
