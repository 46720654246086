import { Label, MessageBar, Pivot, PivotItem, Stack } from "@fluentui/react";
import { useContext } from "react";
import FormSection from "../../../Common/FormSection/FormSection";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import PivotItemContainer from "../../../Common/PivotItemContainer/PivotItemContainer";
import tools from "../../../utils/tools";
import DocumentsContainer from "../DocumentsContainer/DocumentsContainer";
import FileInput from "../../../Common/FileInput/FileInput";
import { commonInputStyles, MessageBarStyles } from "../../../styles";

const DocumentsSection = () => {
  const { conditionnalDisplayVariables, reactHookProps, actions }: any =
    useContext(CertificationContext);
  const { watch, setValue } = reactHookProps;
  const { isUsingExistingReferential, hasOptions } = conditionnalDisplayVariables;

  const getDocumentsInfoMessage = () => {
    if (isUsingExistingReferential) {
      return "Le référentiel de cette certification est issu de celui d’une certification existante. Il ne peut donc pas être modifié.";
    }
    if (hasOptions) {
      return "Ajoutez des documents pour chaque option de cette certification.";
    } else {
      return "Ajoutez des documents pour cette certification";
    }
  };

  return (
    <FormSection sectionTitle="Personnalisation des documents et parchemins">
      <Stack.Item>
        <MessageBar styles={MessageBarStyles}>{getDocumentsInfoMessage()}</MessageBar>
      </Stack.Item>
      {hasOptions && watch("options") && (
        <Pivot aria-label="Basic Pivot Example">
          {watch("options").map((option: any) => {
            var parcheminRef = (
              tools.findIn(watch("document_parchemin_attestation"), option.option, "option") as any
            )?.file;

            var parcheminExistingRef = (
              tools.findIn(watch("document_parchemin_attestation"), option.option, "option") as any
            )?.file_url; //lors du GET/certification, l'API renvoi file_url (url du fichier) et non file

            return (
              <PivotItem key={`pivotoption${option.option}`} headerText={`Option ${option.option}`}>
                <PivotItemContainer>
                  <DocumentsContainer option={option} />
                  <Label required styles={commonInputStyles.label}>
                    Parchemin et attestation :
                  </Label>
                  <FileInput
                    name="document_parchemin_attestation"
                    file={parcheminRef ? parcheminRef : []}
                    onUpdateFile={(newFile: any) => {
                      actions.onUpdateParcheminFile(newFile, option.option);
                    }}
                    existingFileURL={parcheminExistingRef}
                    acceptedFileTypes={["PDF"]}
                  />
                </PivotItemContainer>
              </PivotItem>
            );
          })}
        </Pivot>
      )}
      {!hasOptions && (
        <>
          <Stack.Item>
            <DocumentsContainer />
            <Label required styles={commonInputStyles.label}>
              Parchemin et attestation :
            </Label>
            <FileInput
              name="document_parchemin_attestation"
              nameDisplay="parchemin_attestation.pdf"
              file={watch("document_parchemin_attestation") ? watch("document_parchemin_attestation")[0]?.file : []}
              onUpdateFile={(newFile: any) => {
                actions.onUpdateParcheminFile(newFile, null);
              }}
              onDeleteFile={()=>setValue('document_parchemin_attestation',[])}
              existingFileURL={watch("document_parchemin_attestation")[0]?.file_url}
              acceptedFileTypes={["PDF"]}
            />
          </Stack.Item>
        </>
      )}
    </FormSection>
  );
};

export default DocumentsSection;
