import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";

import useOfList from "../useOfList";

import { useState } from "react";
import FormSection from "../../../Common/FormSection/FormSection";
import TextResult from "../../../Common/TextResult/TextResult";
import { API_URL } from "../../../config/config";
import { stateList, userStateList } from "../../../fakeData/State.data";

function OfListFilters() {
  const [showFilters, setShowFilters] = useState(false);
  const { ofs, isLoadingOfs, actions, reactHookProps, userSelectedBranch } =
    useOfList();

  const totalOfs = ofs?.meta.total || 0;

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => setShowFilters((s) => !s);
  return (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontal
          horizontalAlign="start"
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="raison_sociale"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Raison Sociale :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="siret"
              defaultValue=""
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  label="SIRET :"
                  styles={commonInputStyles.textField}
                  type="text"
                  value={value}
                  onChange={(e: any, newValue: any) => {
                    if (!newValue || newValue.length <= 14) {
                      onChange(e.target.value);
                    }
                  }}
                />
              )}
            />
          </Stack.Item>

          {showFilters && (
            <>
              <Stack.Item
                align="start"
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="OF actif :"
                      selectedKey={value ? (value as any).key : undefined}
                      placeholder="Sélectionner un état"
                      options={stateList}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={stateList[0]}
                  control={control}
                  name="actif"
                />
              </Stack.Item>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <Dropdown
                      label="OF multibranche :"
                      selectedKey={value ? value.key : undefined}
                      placeholder="Sélectionner un état"
                      options={userStateList}
                      calloutProps={{ doNotLayer: true }}
                      onChange={(e: any, options: any) => {
                        setValue(name, options, { shouldValidate: true });
                      }}
                      styles={commonInputStyles.dropdownStyle}
                    />
                  )}
                  defaultValue={userStateList[0]}
                  control={control}
                  name="interbranche"
                />
              </Stack.Item>
              {/* WAIT QUALIOPI API
          <Stack.Item align="start">
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Qualiopi :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionner un état"
                  options={stateList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={stateList[0]}
              control={control}
              name="qualiopi"
            />
          </Stack.Item> */}

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-lg6"
                style={{ paddingRight: 20, marginTop: 20 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Certifications liées"
                      placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                      resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?libelle=${terms}&branche_creatrice_id=${userSelectedBranch.id}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.libelle}
                      renderValue={(item: any) => item.libelle}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      defaultValue={{}}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="certifications"
                />
              </Stack.Item>
            </>
          )}
        </Stack>

        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack horizontal tokens={{ childrenGap: 15 }} wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingOfs} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>
          <TextResult total={totalOfs} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default OfListFilters;
