import Joi from "joi";

export const ofSchema = Joi.object({
    raison_sociale: Joi.string().required().max(150).messages({
        "any.required": "Veuillez renseigner une raison sociale",
        "string.empty": "Veuillez renseigner une raison sociale",
        "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
    }),
    siret: Joi.string().required().length(14).regex(/^\d+$/).messages({
        "any.required": "Veuillez renseigner un numéro SIRET",
        "string.empty": "Veuillez renseigner un numéro SIRET",
        "string.length": "Veuillez renseigner un numéro à 14 chiffres",
        "string.pattern.base": "Le siret ne doit comporter que des chiffres",
    }),
    //a modifier -> when refactor adress for all entities is done
    adresse: Joi.string().required()
        .messages({
            "any.required": "Veuillez indiquer votre adresse",
            "string.empty": "Veuillez renseigner votre adresse",
        }),
    complement_adresse: Joi.string().allow("", null),
    code_postal: Joi.string().required()
        .messages({
            "any.required": "Veuillez indiquer votre code postal",
            "string.empty": "Veuillez renseigner votre code postal",
        }),
    region: Joi.string().required()
        .messages({
            "any.required": "Veuillez indiquer votre région",
            "string.empty": "Veuillez renseigner votre région",
        }),
    ville: Joi.string().required().messages({
        "any.required": "Veuillez indiquer votre ville",
        "string.empty": "Veuillez renseigner votre ville",
    }),
}).unknown(true)