const refProfil: any = {
  //attention valeur type si president supprime
  ADMINISTRATEUR_BRANCHE: null,
  ENTREPRISE: {
    dataRef: [
      {
        type: 10,
        varName: "entrepriseProfile",
        attrName: "entreprise",
        single: true,
      },
    ],
  },
  GROUPE_ENTREPRISE: {
    dataRef: [
      {
        type: 22,
        varName: "groupeEntrepriseProfile",
        attrName: "groupeEntreprise",
        single: true,
      },
    ],
  },
  SOUS_GROUPE_ENTREPRISE: {
    //(creation)
    dataRef: [
      {
        type: 10,
        varName: "sousGroupeEntrepriseProfile",
        attrName: "entreprise",
        single: false,
      },
    ],
    groupeRef: [
      {
        type: 22,
        varName: "sousGroupeEntrepriseProfile",
        attrName: "groupeEntreprise",
      },
    ],
    sousGroupeRef: {
      nom: {
        varName: "sousGroupeEntrepriseProfile",
        attrName: "nomSousGroupeEntreprise",
      },
      uuid: {
        varName: "sousGroupeEntrepriseProfile",
        attrName: "sousGroupeEntreprise",
        type: 27,
      },
    },
  },
  OF: {
    dataRef: [
      { type: 13, varName: "ofProfile", attrName: "ofs", single: false },
    ],
  },
  GROUPE_OF: {
    dataRef: [
      {
        type: 21,
        varName: "groupeOFProfile",
        attrName: "groupOf",
        single: true,
      },
    ],
  },
  SOUS_GROUPE_OF: {
    //(creation)
    dataRef: [
      {
        type: 13,
        varName: "sousGroupeOfProfile",
        attrName: "ofs",
        single: false,
      },
    ],
    groupeRef: [
      { type: 21, varName: "sousGroupeOfProfile", attrName: "groupOf" },
    ],
    sousGroupeRef: {
      nom: { varName: "sousGroupeOfProfile", attrName: "nomSousGroupeOf" },
      uuid: {
        varName: "sousGroupeOfProfile",
        attrName: "sousGroupeOf",
        type: 27,
      },
    },
  },
  ADMINISTRATEUR_DOMAINE: {
    dataRef: [
      {
        type: 36,
        varName: "adminDomaineProfile",
        attrName: "domaine",
        single: true,
      },
    ],
  },
  POSITIONNEUR: {
    dataRef: [
      {
        type: 5,
        varName: "positionneurProfile",
        attrName: "certifications",
        single: false,
      },
      {
        type: 10,
        varName: "positionneurProfile",
        attrName: "entreprise",
        single: false,
      },
      {
        type: 13,
        varName: "positionneurProfile",
        attrName: "ofs",
        single: false,
      },
    ],
  },
  EVALUATEUR: {
    dataRef: [
      {
        type: 5,
        varName: "evaluateurProfile",
        attrName: "certifications",
        single: false,
      },
      {
        type: 10,
        varName: "evaluateurProfile",
        attrName: "entreprise",
        single: false,
      },
      {
        type: 13,
        varName: "evaluateurProfile",
        attrName: "ofs",
        single: false,
      },
    ],
  },
  CANDIDAT: null,
  MEMBRE_JURY: {
    //(creation)
    juryRef: {
      nom: { varName: "membreJuryProfile", attrName: "nouveau_syndicat_nom" },
      id: { varName: "membreJuryProfile", attrName: "syndicat", type: 26 },
      file: { varName: "membreJuryProfile", attrName: "file" },
    },
  },
  CONSULTATION: null,
  CONSULTATION_OF: {
    //(of or groupOf)
    dataRef: [
      {
        type: 13,
        varName: "consultationOfProfile",
        attrName: "ofs",
        single: false,
        value: "of",
      },
      {
        type: 21,
        varName: "consultationOfProfile",
        attrName: "groupOf",
        single: true,
        value: "groupe_of",
      },
    ],
  },
  SECOND_EVALUATEUR: null,
};

const userAdapters = {
  transformForAPI: (data: any, constants: any) => {
    let transformed: any = {
      _method: data._method,
      uuid: data?.uuid,
      nom: data.nom,
      prenom: data.prenom,
      email: data.email,
      telephone_portable: data.telephone_portable,
      profils: [],
    };

    constants.UTILISATEURS.PROFILS.filter(
      (item: any) => data[item.id] === true
    ).map((profilSelected: any) => {
      const entites: any = [];
      const sous_groupe: any = {};
      const syndicat: any = {};
      const extraData: any = {};
      refProfil[profilSelected.id]?.dataRef?.map((refProfilObj: any) => {
        const attributValue = data[refProfilObj.varName][refProfilObj.attrName];
        if (refProfilObj.single) {
          if (attributValue) {
            entites.push({
              id: attributValue["uuid"],
              type: refProfilObj.type,
            });
          }
        } else {
          attributValue?.map((item: any) => {
            entites.push({ id: item.uuid, type: refProfilObj.type });
          });
        }
      });
      refProfil[profilSelected.id]?.groupeRef?.map((groupRefObj: any) => {
        const sousGroupeRef = refProfil[profilSelected.id]["sousGroupeRef"];
        if (data[groupRefObj.varName]["type"] == "nouveau_sous_groupe") {
          sous_groupe.nom =
            data[sousGroupeRef["nom"]["varName"]][
              sousGroupeRef["nom"]["attrName"]
            ];
        } else {
          sous_groupe.uuid =
            data[sousGroupeRef["uuid"]["varName"]][
              sousGroupeRef["uuid"]["attrName"]
            ].uuid;
        }
        sous_groupe.groupe = {
          type: groupRefObj.type,
          id: data[groupRefObj.varName][groupRefObj.attrName].uuid,
        };

        if (entites.length) {
          sous_groupe.entites = entites;
        }
      });

      if (refProfil[profilSelected.id]?.juryRef) {
        const juryRef = refProfil[profilSelected.id]["juryRef"];
        if (data[juryRef.nom.varName]["typeSyndicat"] == "nouveau_syndicat") {
          syndicat.nom =
            data[juryRef["nom"]["varName"]][juryRef["nom"]["attrName"]];
        } else {
          syndicat.id =
            data[juryRef["id"]["varName"]][juryRef["id"]["attrName"]].id;
        }

        if (juryRef.file && data[juryRef.file.varName][juryRef.file.attrName]) {
          extraData.file = data[juryRef.file.varName][juryRef.file.attrName];
        }
        if (
          juryRef.file_deleted &&
          data[juryRef.file_deleted.varName][juryRef.file_deleted.attrName]
        ) {
          extraData.file_deleted =
            data[juryRef.file_deleted.varName][juryRef.file_deleted.attrName];
        }
      }

      refProfil[profilSelected.id]
        ? refProfil[profilSelected.id]["groupeRef"]
          ? transformed.profils.push({ profil: profilSelected.id, sous_groupe })
          : refProfil[profilSelected.id]["juryRef"]
          ? transformed.profils.push({
              profil: profilSelected.id,
              syndicat,
              ...extraData,
            })
          : transformed.profils.push({ profil: profilSelected.id, entites })
        : transformed.profils.push({
            profil: profilSelected.id,
            entites: null,
          });
    });

    if (!data?.uuid) {
      transformed.envoyer_mot_de_passe = data.envoyer_mot_de_passe;
    }
    //retrait du profil candidat qui ne doit pas être renvoyé à l'api (disabled)
    transformed.profils = transformed.profils.filter(
      (p: any) => p.profil !== "CANDIDAT"
    );
    return transformed;
  },
  transformForBO: (data: any, constants: any) => {
    let transformed: any = {
      uuid: data?.uuid,
      nom: data.nom,
      prenom: data.prenom,
      email: data.email,
      telephone_portable: data.telephone_portable,
    };

    data.profils.map((profil: any) => {
      const refProfilForBo = refProfil[profil.profil];
      // if (!refProfil[profil.profil]) return;

      transformed[profil.profil] = true;
      if (refProfilForBo?.sousGroupeRef) {
        //creation de varName et attrName par defaut
        transformed[refProfilForBo.groupeRef[0].varName] = {
          type: "sous_groupe_existant",
        };
        transformed[refProfilForBo.groupeRef[0].varName][
          refProfilForBo.groupeRef[0].attrName
        ] = {
          ...profil.entites[0].attributes.groupe,
        };
        transformed[refProfilForBo.sousGroupeRef.uuid.varName][
          refProfilForBo.sousGroupeRef.uuid.attrName
        ] = {
          uuid: profil.entites[0].id,
          nom: profil.entites[0].attributes.nom,
        };
      } else if (refProfilForBo?.juryRef) {
        if (profil.entites[0].file_url) {
          transformed[refProfilForBo.juryRef.id.varName] = {
            typeSyndicat: "syndicat_existant",
            file_url: profil.entites[0].file_url,
          };
        } else {
          transformed[refProfilForBo.juryRef.id.varName] = {
            typeSyndicat: "syndicat_existant",
          };
        }
        transformed[refProfilForBo.juryRef.id.varName][
          refProfilForBo.juryRef.id.attrName
        ] = {
          id: profil.entites[0].id,
          syndicat: profil.entites[0].attributes.syndicat,
        };
      } else {
        if (profil.entites) {
          //creation de varName et attrName par defaut
          const wichEntites: any = [];
          profil.entites.forEach((e: any) => {
            refProfilForBo?.dataRef.filter((d: any) => {
              if (d.type == e.type) {
                wichEntites.push(d);
              }
            });
          });
          const uniqEntite = Array.from(new Set(wichEntites));

          uniqEntite.map((entiteAttr: any) => {
            if (entiteAttr.value) {
              transformed[entiteAttr.varName] = { ofType: entiteAttr.value };
            } else {
              if (!transformed[entiteAttr.varName]) {
                transformed[entiteAttr.varName] = {};
              }
            }
            if (!entiteAttr.single) {
              transformed[entiteAttr.varName][entiteAttr.attrName] = [];
            }
          });

          profil?.entites.map((entite: any) => {
            const entiteInfoForBo = refProfilForBo?.dataRef.find(
              (item: any) => item.type == entite.type
            );
            const newObject: any = { uuid: entite.id };
            if (entite.attributes) {
              Object.keys(entite.attributes).map((key: any) => {
                newObject[key] = entite.attributes[key];
              });
            }
            if (entiteInfoForBo) {
              if (!entiteInfoForBo.single) {
                transformed[entiteInfoForBo.varName][
                  entiteInfoForBo.attrName
                ].push(newObject);
              } else {
                transformed[entiteInfoForBo.varName][entiteInfoForBo.attrName] =
                  newObject;
              }
            }
          });
        }
      }
    });

    return transformed;
  },
};

export default userAdapters;
