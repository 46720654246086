import { Stack } from "@fluentui/react";
import useTheme from "../useTheme";

function FileDownloadLink({ existingFileURL, file, children }: any) {
  const { primaryColor } = useTheme();

  if (existingFileURL && !file) {
    return (
      <a
        href={existingFileURL}
        download
        style={{
          textDecoration: "none",
          borderRadius: 5,
          backgroundColor: primaryColor,
        }}
        rel="noreferrer"
        target="_blank"
      >
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          style={{
            padding: 20,
            color: "white",
          }}
        >
          {children}
        </Stack>
      </a>
    );
  } else {
    return (
      <Stack
        horizontalAlign="center"
        verticalAlign="center"
        style={{
          padding: 20,
          backgroundColor: primaryColor,
          color: "white",
          borderRadius: 5,
        }}
      >
        {children}
      </Stack>
    );
  }
}

export default FileDownloadLink;
