import { Stack, Text } from "@fluentui/react";
import { TiTick } from "react-icons/ti";
import SectionTitle from "../../../../Common/SectionTitle/SectionTitle";

function OfRecap({ of }: any) {
  //pays obligatoirement FRANCE ?
  const adresse = of ? `${of.adresse?.adresse.toLowerCase()} ${of.adresse?.code_postal} ${
    of.adresse?.ville.charAt(0).toUpperCase() + of.adresse?.ville.slice(1).toLowerCase()
  } - France`: "";

  return of ? (
    <Stack>
      <SectionTitle title="OF LIÉ À LA CANDIDATURE" />
      <Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Text style={{ fontWeight: "bold" }}>{of.raison_sociale}</Text>
          <Text>-</Text>
          <Text className="ms-fontColor-gray110">{of.siret}</Text>

          {/* API QUALIOPI */}
          {/* <Stack horizontal horizontalAlign="center" verticalAlign="start">
                        <Text className="ms-fontColor-green ms-fontWeight-bold">
                            Certification Qualiopi : Oui
                        </Text>
                        <TiTick color="green" size={20} />
                    </Stack> */}
        </Stack>
        <Text>{adresse}</Text>
      </Stack>
    </Stack>
  ): null;
}

export default OfRecap;
