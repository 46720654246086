import { useState } from "react";
import validations from "../../../utils/validations";
import { toast } from "react-toastify";
import useCustomModal from "../../Overlays/CustomModal/useCustomModal";
import LoadButton from "../../LoadButton/LoadButton";
import { DefaultButton, Stack, TextField } from "@fluentui/react";
import formSectionStyles from "../../FormSection/FormSection.styles";
import { commonButtonStyles, commonInputStyles } from "../../../styles";

function AddEvaluateurModal({
  onSave = () => {},
  isSaving = false,
  candidature,
}: any) {
  const [errors, setErrors] = useState<any>({});
  const [user, setUser] = useState({
    nom: "",
    prenom: "",
    email: "",
  });
  const { actions } = useCustomModal();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateEvaluateur = () => {
    let validationErrors: any = {};
    const validationEvaluateur = validations.validateProfil(user);
    if (validationEvaluateur.error) {
      validationEvaluateur.error.details.map((err: any) => {
        validationErrors[err.context.key] = err.message;
      });
    }
    setErrors(validationErrors);
    let isValid = Object.keys(validationErrors).length === 0;
    if (!isValid) {
      toast.error("Le formulaire comporte des erreurs.");
    }
    return isValid;
  };

  const onInviteEvaluateur = async () => {
    if (validateEvaluateur()) {
      try {
        await onSave(user, candidature.uuid);
        actions.closeModal();
      } catch (err: any) {
        toast.error(err.response.data.message);
      }
    }
  };

  return (
    <Stack
      style={{
        ...formSectionStyles.formContainer,
        minWidth: 400,
        paddingBottom: 12,
      }}
      tokens={{ childrenGap: 15 }}
    >
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Nom :"
          type="text"
          placeholder="Ex : Doe"
          name="nom"
          onChange={handleChange}
          value={user.nom}
          errorMessage={errors.nom}
        />
      </Stack.Item>
      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Prénom :"
          type="text"
          placeholder="Ex : John"
          name="prenom"
          onChange={handleChange}
          value={user.prenom}
          errorMessage={errors.prenom}
        />
      </Stack.Item>

      <Stack.Item>
        <TextField
          styles={commonInputStyles.textField}
          required
          label="Email :"
          type="text"
          placeholder="Ex : john.doe@email.Fr"
          name="email"
          onChange={handleChange}
          value={user.email}
          errorMessage={errors.email}
        />
      </Stack.Item>

      <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
        <DefaultButton
          styles={commonButtonStyles.defaultButton}
          onClick={actions.closeModal}
        >
          Annuler
        </DefaultButton>

        <LoadButton
          onClick={onInviteEvaluateur}
          type="button"
          text={"Inviter un évaluateur"}
          isLoading={isSaving}
        />
      </Stack.Item>
    </Stack>
  );
}

export default AddEvaluateurModal;
