import { DefaultSpacing, MessageBar } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";

export const GroupeEntrepriseProfileForm: React.FunctionComponent<
  SubFormsProps
> = ({ watchRoles, userProfil }: SubFormsProps) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  let disabledUUIDs: any = [];

  if (userProfil?.referents?.length > 0) {
    const referentOfCurrentModel = userProfil.referents.filter(
      (r: any) =>
        r.pivot.model_type === "groupe_entreprise" &&
        r.sur_les_branches.find((b: any) => b.id === userSelectedBranch.id)
    );

    disabledUUIDs = referentOfCurrentModel.map((r: any) => r.uuid);
  }

  return (
    <ProfileAddionalInformations
      name="GROUPE_ENTREPRISE"
      sectionTitle="Informations du profil Groupe d'entreprises"
      watchRoles={watchRoles}
    >
      <>
        {disabledUUIDs.length > 0 && (
          <MessageBar
            messageBarType={1}
            messageBarIconProps={{ iconName: "Warning" }}
          >
            Vous ne pouvez pas changer le groupe d'entreprises de ce profil car
            cet utilisateur est référent de ce groupe d'entreprises sur cette
            branche.
          </MessageBar>
        )}
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Groupe d'entreprises rattaché à l'utilisateur :"
              placeholder="Recherchez un groupe d'entreprises par SIREN ou par nom (ex: 123123123 ou Groupe 1)"
              resourceURI={`${API_URL}api/administration/groupe-entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siren}
                      </span>
                    </div>
                    <div
                      style={{
                        paddingLeft: DefaultSpacing.s1,
                      }}
                    >
                      {item.ref_idccs.map((idcc: any) => (
                        <div
                          style={{
                            marginTop: DefaultSpacing.s1,
                            marginBottom: DefaultSpacing.s1,
                          }}
                          className="ms-fontColor-gray110 ms-fontWeight-semilight ms-font-s"
                        >
                          <i>
                            {idcc.idcc} - {idcc.titre_convention}
                          </i>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.raison_sociale}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siren}
                      </span>
                    </div>
                    <div
                      style={{
                        paddingLeft: DefaultSpacing.s1,
                      }}
                    >
                      {item.ref_idccs.map((idcc: any) => (
                        <div
                          style={{
                            marginTop: DefaultSpacing.s1,
                            marginBottom: DefaultSpacing.s1,
                          }}
                          className="ms-fontColor-gray110 ms-fontWeight-semilight ms-font-s"
                        >
                          <i>
                            {idcc.idcc} - {idcc.titre_convention}
                          </i>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={null}
              errorMessage={
                (
                  (errors.groupeEntrepriseProfile as any)
                    ?.groupeEntreprise as any
                )?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              required
              disabled={disabledUUIDs.length > 0}
            />
          )}
          control={control}
          name="groupeEntrepriseProfile.groupeEntreprise"
        />
      </>
    </ProfileAddionalInformations>
  );
};
