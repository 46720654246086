import {
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  Link,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import BooleanValue from "../../Common/BooleanValue/BooleanValue";
import Page from "../../Common/Page/Page";
import Pagination from "../../Common/Pagination/Pagination";
import useConstants from "../../hooks/useConstants";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";
import tools from "../../utils/tools";
import DemandeInscriptionStructureBadge from "./DemandeInscriptionStructureBadge/DemandeInscriptionStructureBadge";
import DemandeInscriptionStructureListFilters from "./DemandeInscriptionStructureListFilters/DemandeInscriptionStructureListFilters";
import useDemandeInscriptionStructureList from "./useDemandeInscriptionStructureList";
import { commonButtonStyles } from "../../styles";
import { userAccessEnum } from "../../config/accessEnum";
import Loader from "../../Common/Loader/Loader";
import LoadLink from "../../Common/LoadLink/LoadLink";
import Can from "../../Common/Can/Can";
import useTheme from "../../Common/useTheme";

enum DetailStructureRoute {
  "Entreprise" = "/admin/entreprises/",
  "Organisme de formation" = "/admin/of/",
  "Groupe Entreprise" = "/admin/groupes-entreprises/",
}

// prettier-ignore
type StructureType =  "Entreprise" | "Organisme de formation" | "Groupe Entreprise";

function DemandeInscriptionStructureList() {
  const { constants } = useConstants();
  const { isAdmin } = useInfoProfilUser();
  const { primaryColor } = useTheme();

  const {
    demandeInscriptionStructureProps,
    actions: actionsPage,
    demandeInscriptionStructure,
    optionsFilters,
  } = useDemandeInscriptionStructureList();

  const items: any = demandeInscriptionStructure?.data || [];
  const columns = [
    {
      key: "1",
      name: "Statut",
      fieldName: "statut",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: any) => (
        <DemandeInscriptionStructureBadge
          statut={
            (
              tools.findIn(
                constants.DEMANDE_INSCRIPTION_STRUCTURE?.STATUTS,
                item.statut,
                "text"
              ) as any
            )?.id
          }
        />
      ),
    },
    {
      key: "2",
      name: "Raison sociale",
      fieldName: "raison_sociale",
      minWidth: 150,
      maxWidth: 200,
      isResizable: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.raison_sociale}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("raison_sociale");
      },
      ...tools.sorted(optionsFilters.sort, "raison_sociale"),
    },
    {
      key: "3",
      name: "SIRET",
      fieldName: "siret",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.siret}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("siret");
      },
      ...tools.sorted(optionsFilters.sort, "siret"),
    },
    {
      key: "4",
      name: "SIREN",
      fieldName: "siren",
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: true,
      showSortIconWhenUnsorted: true,
      onRender: (item: any) => <Text>{item.siren}</Text>,
      onColumnClick: () => {
        actionsPage.sortColumn("siren");
      },
      ...tools.sorted(optionsFilters.sort, "siren"),
    },
    {
      key: "5",
      name: "Multibranche",
      fieldName: "interbranches",
      minWidth: 40,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => <BooleanValue value={item.multibranche} />,
    },
    {
      key: "6",
      name: "Type de structure",
      fieldName: "type_structure",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <Text>{item.type}</Text>,
    },
    {
      key: "7",
      name: "Date de demande d'inscription",
      fieldName: "demande_le",
      minWidth: 120,
      maxWidth: 125,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: () => {
        actionsPage.sortColumn("demande_le");
      },
      onRender: (item: any) => (
        <Text>{tools.reformateDateFromJsDate(item.demande_le)}</Text>
      ),
      showSortIconWhenUnsorted: true,
      ...tools.sorted(optionsFilters.sort, "demande_le"),
    },
    {
      key: "8",
      name: "Responsable",
      fieldName: "responsable",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => (
        <Text>
          {item?.responsable?.nom} {item?.responsable?.prenom}
        </Text>
      ),
    },
    {
      key: "9",
      name: "Commentaire",
      fieldName: "commentaire",
      minWidth: 40,
      maxWidth: 60,
      isResizable: true,
      onRender: (item: any) => <Text>{item?.commentaire}</Text>,
    },
    {
      key: "10",
      name: "Actions",
      fieldName: "actions",
      minWidth: 200,
      isResizable: true,
      onRender: (item: any) => {
        switch (item.statut) {
          case "Validée":
            return isAdmin ? (
              <Stack>
                <Link
                  to={`${DetailStructureRoute[item.type as StructureType]}${
                    item?.structure_liee
                  }`}
                  as={RouterLink}
                  styles={commonButtonStyles.buttonLink}
                >
                  Voir la fiche structure
                </Link>
              </Stack>
            ) : null;
          case "Refusée":
            return null;
          case "En attente":
            return (
              <Stack horizontal wrap>
                {demandeInscriptionStructureProps.isToggleDemandeInscriptionUuid ==
                item.uuid ? (
                  <Loader size={"medium"} />
                ) : (
                  <Can I={userAccessEnum.VALIDER_DEMANDE_INSCRIPTION_STRUCTURE}>
                    <>
                      <LoadLink
                        styles={commonButtonStyles.activeButtonLink}
                        onClick={() =>
                          demandeInscriptionStructureProps.actions.validerDemandeInscription(
                            item
                          )
                        }
                        text={"Valider"}
                      />
                      <LoadLink
                        styles={commonButtonStyles.disableButtonLink}
                        onClick={() =>
                          actionsPage.openRefuserDemandeInscriptionStructure(
                            item
                          )
                        }
                        text={"Refuser"}
                      />
                    </>
                  </Can>
                )}
              </Stack>
            );
          default:
            <></>;
        }
      },
    },
  ];

  return (
    <Stack>
      <Page
        title="Gestion des demandes d'inscriptions des structures"
        explain="Veuillez gérer les demandes d'inscriptions des structures"
      >
        <DemandeInscriptionStructureListFilters />

        {[...items].filter((user: any) => user.statut == "En attente").length >
          1 && (
          <Can I={userAccessEnum.VALIDER_DEMANDE_INSCRIPTION_STRUCTURE}>
            <div style={{ height: 46 }}>
              <DefaultButton
                text="Valider toutes les demandes de la page"
                styles={commonButtonStyles.defaultButton}
                style={{ color: primaryColor, borderColor: primaryColor }}
                onClick={() =>
                  actionsPage.openValiderDemandeInscriptionStructureEnMasse(
                    items
                  )
                }
              />
            </div>
          </Can>
        )}

        <DetailsList
          layoutMode={DetailsListLayoutMode.justified}
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
        <Pagination
          changePage={actionsPage.changePage}
          page={demandeInscriptionStructure?.meta.current_page || 1}
          totalPages={
            Math.ceil(demandeInscriptionStructure?.meta.total / 15) || 1
          }
        />
      </Page>
    </Stack>
  );
}

export default DemandeInscriptionStructureList;
