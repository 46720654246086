import {
  Stack,
  Text,
  TextField,
  PrimaryButton,
  Link,
  Image,
  ImageFit,
} from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useForm, Controller } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { authSchema } from "../../schemas/authSchema";
import { IUser } from "../../interfaces/userInterface";
import loginStyles from "./Login.styles";
import useHome from "./useHome";
import LoadButton from "../../Common/LoadButton/LoadButton";

import Accordion from "../../Common/Accordion/Accordion";
import { commonButtonStyles, commonInputStyles } from "../../styles";
import { atom, useAtom } from "jotai";
import { showHeaderAtom } from "../../Common/Header/Header";
import { useEffect, useState } from "react";
import useTheme from "../../Common/useTheme";
import { getPageGeneralRequest } from "../../services/publicServices";
import accordionStyles from "../../Common/Accordion/Accordion.styles";

const generalDescriptionAtom = atom("");

function Login() {
  const { primaryColor } = useTheme();
  const { branches } = useHome();
  const { actions, isLoadingLogin, isErrorLogin, errorLogin } = useAuth();
  const items = branches?.data || [];
  const [, setShowHeader] = useAtom(showHeaderAtom);
  const [generalDescription, setGeneralDescription] = useAtom(
    generalDescriptionAtom
  );

  useEffect(() => {
    setShowHeader(false);
    getSiteDescription();
    return () => setShowHeader(true);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IUser>({
    mode: "onBlur",
    resolver: joiResolver(authSchema),
  });

  const getSiteDescription = async () => {
    if (!generalDescription) {
      const data = await getPageGeneralRequest("DESCRIPTION");
      setGeneralDescription(data.contenu);
    }
  };

  return (
    <Stack
      style={{ width: "100%", minHeight: "90vh" }}
      horizontalAlign="space-evenly"
      verticalAlign="start"
      horizontal
      wrap
      grow
    >
      <Stack
        style={{ position: "relative", zIndex: "9" }}
        className="ms-sm12 ms-lg6 ms-xl4 ms-xxl3"
      >
        <Stack style={{ padding: "20px 0px" }} horizontalAlign="center">
          <Stack style={{ width: 300 }}>
            <Text
              variant={"xLarge"}
              style={{
                marginBottom: 40,
                fontSize: 30,
                color: primaryColor,
                textShadow: "1px 1px 2px #c7c7c7",
              }}
            >
              Certifi@
            </Text>
            <Text variant={"xLarge"}>CONNEXION</Text>
            <Text
              variant={"mediumPlus"}
              style={{ margin: "20px 0", textAlign: "justify" }}
            >
              Veuillez saisir votre adresse email et votre mot de passe afin de
              vous connecter et accéder à votre espace.
            </Text>
          </Stack>

          {isErrorLogin ? (
            <Text
              variant={"medium"}
              style={{
                marginBottom: "20px",
                color: "red",
                width: "300px",
                textAlign: "center",
              }}
            >
              {(errorLogin as any).response.data.message}
            </Text>
          ) : null}

          <form onSubmit={handleSubmit(actions.login)}>
            <Stack
              tokens={loginStyles.stackTokens}
              style={{ width: 300, marginBottom: 20 }}
            >
              <Stack.Item grow>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Email :"
                      type="email"
                      styles={commonInputStyles.textField}
                      {...field}
                      errorMessage={(errors.email as any)?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="email"
                />
              </Stack.Item>
              <Stack.Item grow>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Mot de passe :"
                      type="password"
                      placeholder=""
                      styles={commonInputStyles.textField}
                      {...field}
                      errorMessage={(errors.password as any)?.message}
                    />
                  )}
                  defaultValue=""
                  control={control}
                  name="password"
                />
              </Stack.Item>
              <LoadButton
                isLoading={isLoadingLogin}
                text="Me connecter"
                style={{ width: "100%" }}
              />
            </Stack>
          </form>

          <Link
            to={`/mot-de-passe-perdu`}
            as={RouterLink}
            style={{ fontSize: 18, fontWeight: 500 }}
          >
            Mot de passe oublié ?
          </Link>
        </Stack>

        <Stack
          horizontalAlign="center"
          style={{
            margin: "20px 0px",
            padding: "10px 0px",
            backgroundColor: "white",
          }}
        >
          <Stack style={{ width: 300 }}>
            <Text variant={"xLarge"}>INSCRIPTION</Text>
            <Text
              variant={"mediumPlus"}
              style={{ margin: "20px 0", textAlign: "justify" }}
            >
              Vous n’avez pas encore de compte ? Créez-en un à l’aide du bouton
              ci-dessous
            </Text>
          </Stack>

          <RouterLink to="/inscription" style={{ margin: "20px 0px" }}>
            <PrimaryButton
              styles={commonButtonStyles.buttonSubmit}
              style={{ width: 300 }}
            >
              Demander la création d'un compte
            </PrimaryButton>
          </RouterLink>
        </Stack>
      </Stack>

      <Stack
        className="ms-sm12 ms-lg5 ms-xl6 branch-list-home"
        styles={loginStyles.branchesList}
        horizontalAlign="center"
        grow
      >
        <Accordion
          title="Informations Certifi@"
          defaultIsOpen={true}
          content={
            <div dangerouslySetInnerHTML={{ __html: generalDescription }} />
          }
          srcImg=""
          altImg={`Logo Certifi@`}
        />

        {items.length > 0 && (
          <Stack
            styles={accordionStyles.container}
            style={{ position: "relative", zIndex: "3" }}
          >
            {items.map((item: any, i: number) => (
              <Stack.Item styles={accordionStyles.headerStatic}>
                <Stack horizontal horizontalAlign={"space-between"}>
                  <Stack
                    horizontal
                    verticalAlign="center"
                    tokens={{ childrenGap: 10 }}
                  >
                    <Image
                      width={40}
                      height={40}
                      src={item.logo_url}
                      maximizeFrame
                      imageFit={ImageFit.contain}
                      alt={`Logo de ${item.nom}`}
                    />
                    <div>{item.nom}</div>
                  </Stack>
                </Stack>
              </Stack.Item>
            ))}
          </Stack>
        )}
        <div className="bubbles">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Stack>
    </Stack>
  );
}

export default Login;
