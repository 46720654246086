const INPUT_SIZE = "42px";
const commonInputStyles = {
  textField: {
    root: {
      selectors: {
        ".ms-TextField-fieldGroup": {
          border: "0px",
          borderBottom: "1px solid #D1D1D1",
          // borderRadius: 3,
          // backgroundColor: "#F4F6F9",
          backgroundColor: "transparent",
          height: INPUT_SIZE,
        },
        ".ms-TextField-field": {
          fontSize: "16px",
        },
        ".ms-Label": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  textFieldPrefilled: {
    root: {
      selectors: {
        ".ms-TextField-fieldGroup": {
          border: "0px",
          borderBottom: "1px solid #D1D1D1",
          // borderRadius: 3,
          // backgroundColor: "#F4F6F9",
          backgroundColor: "#E7F0FE",
          height: INPUT_SIZE,
        },
        ".ms-TextField-field": {
          fontSize: "16px",
        },
        ".ms-Label": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  dataPicker: {
    root: {
      selectors: {
        ".ms-TextField-fieldGroup": {
          border: "0px",
          borderBottom: "1px solid #D1D1D1",
          backgroundColor: "transparent",
          height: INPUT_SIZE,
        },
        ".ms-TextField-field": {
          fontSize: "16px",
        },
      },
    },
  },
  searchField: {
    root: {
      selectors: {
        ".ms-TextField-fieldGroup": {
          border: "0px",
          borderBottom: "1px solid #D1D1D1",
          // borderRadius: 3,
          backgroundColor: "transparent",
          height: INPUT_SIZE,
          paddingLeft: 20,
        },
        ".ms-TextField-field": {
          fontSize: "16px",
        },
        ".ms-Label": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },

  textFieldTypeImg: {
    root: {
      selectors: {
        ".ms-TextField-fieldGroup": {
          border: "none",
        },
        ".ms-TextField-field": {
          padding: "0",
          fontSize: "16px",
        },
        ".ms-Label": {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
  },
  toggleInput: {
    root: {
      ".ms-Label": {
        fontSize: "14px",
        fontWeight: "500",
      },
    },
  },
  dropdownStyle: {
    label: {
      fontSize: "14px",
      fontWeight: "500",
    },
    title: {
      // //borderRadius: 3,
      border: "0px",
      borderBottom: "1px solid #D1D1D1",
      //backgroundColor: "#F4F6F9",
      backgroundColor: "transparent",
      fontSize: "16px",
      fontWeight: "500",
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    dropdownItem: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    dropdownItemSelected: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    caretDownWrapper: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    root: {
      selectors: {
        ".ms-Callout": {
          right: "0px !important",
          left: "0px !important",
          top: "0px !important",
        },
        ".ms-Dropdown-titleIsPlaceHolder": {
          color: "#979797",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
    },
  },
  dropdownStylePrefilled: {
    label: {
      fontSize: "14px",
      fontWeight: "500",
    },
    title: {
      // //borderRadius: 3,
      border: "0px",
      borderBottom: "1px solid #D1D1D1",
      //backgroundColor: "#F4F6F9",
      backgroundColor: "#E7F0FE",
      fontSize: "16px",
      fontWeight: "500",
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    dropdownItem: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    dropdownItemSelected: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    caretDownWrapper: {
      height: INPUT_SIZE,
      minHeight: INPUT_SIZE,
      lineHeight: INPUT_SIZE,
    },
    root: {
      selectors: {
        ".ms-Callout": {
          right: "0px !important",
          left: "0px !important",
          top: "0px !important",
        },
        ".ms-Dropdown-titleIsPlaceHolder": {
          color: "#979797",
          fontSize: "16px",
          fontWeight: "400",
        },
      },
    },
  },
  label: {
    root: {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  checkbox: {
    checkbox: {
      border: "1px solid #D1D1D1",
    },
    root: {
      selectors: {
        ".ms-Checkbox-text": {
          fontSize: "16px",
          fontWeight: "500",
        },
      },
    },
  },
};
export default commonInputStyles;
