import {
  Image,
  ImageFit,
  Link,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import { commonButtonStyles } from "../../../styles";
import useHome from "../../Login/useHome";
import { SignUpContext } from "../useSignUp";

const BranchSelection = () => {
  const { branches } = useHome();

  const { actions }: any = useContext(SignUpContext);
  const items = branches?.data || [];

  const onSelectBranch = (branch: any) => {
    actions.onSubmitBranch(branch);
  };

  return (
    <>
      {items.length > 0 && (
        <Stack>
          {items.map((item: any, i: number) => (
            <Stack.Item
              styles={{
                root: {
                  paddingTop: 4,
                  paddingBottom: 4,
                  borderBottom: "1px solid #f1f1f1",
                  selectors: {
                    ":hover": {
                      backgroundColor: "#f1f1f1", // Change this to your desired color
                    },
                  },
                },
              }}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                verticalAlign="center"
                style={{
                  marginTop: 0,
                }}
                tokens={{ childrenGap: 0 }}
              >
                <Stack
                  verticalAlign="center"
                  horizontal
                  tokens={{ childrenGap: 0 }}
                >
                  <Stack.Item
                    align="center"
                    style={{ maxWidth: 40, height: 40, marginRight: 10 }}
                  >
                    <Image
                      style={{ width: 40, height: 40 }}
                      src={item.logo_url}
                      maximizeFrame
                      imageFit={ImageFit.contain}
                      alt={item.nom}
                    />
                  </Stack.Item>

                  <Stack.Item align="center">
                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                      {item.nom}
                    </Text>
                  </Stack.Item>
                </Stack>

                <Stack.Item align="center">
                  {item.demande_creation_autorisee ? (
                    <PrimaryButton
                      onClick={() => onSelectBranch(item)}
                      styles={commonButtonStyles.buttonSubmit}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      Choisir cette branche
                    </PrimaryButton>
                  ) : (
                    <div
                      style={{
                        fontSize: 14,
                        textAlign: "right",
                        maxWidth: 250,
                      }}
                    >
                      Veuillez déposer une{" "}
                      <Link
                        to={`/demande-aide?branche=${item.id}`}
                        as={RouterLink}
                      >
                        demande d'aide
                      </Link>{" "}
                      pour créer un compte
                    </div>
                  )}
                </Stack.Item>
              </Stack>
            </Stack.Item>
          ))}
        </Stack>
      )}
    </>
  );
};

export default BranchSelection;
