import { DefaultButton, Dropdown, Stack } from "@fluentui/react";
import FormSection from "../../../Common/FormSection/FormSection";
import Loader from "../../../Common/Loader/Loader";
import useCertificationList from "../../../hooks/certifications/useCertificationList";
import useDemandeHabilitationUtilisateurList from "../useDemandeHabilitationUtilisateurList";
import { Controller } from "react-hook-form";
import useConstants from "../../../hooks/useConstants";
import tools from "../../../utils/tools";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import { branchAccessEnum } from "../../../config/accessEnum";
import Can from "../../../Common/Can/Can";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import { API_URL } from "../../../config/config";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import TextResult from "../../../Common/TextResult/TextResult";
import { useEffect } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

function DemandeHabilitationUtilisateurListFilters() {
  const location = useLocation();

  const {
    actions,
    reactHookProps,
    demandeHabilitationsUtilisateur,
    isLoadingDemandeHabilitationsUtilisateur,
  } = useDemandeHabilitationUtilisateurList();
  const { constants } = useConstants();
  const { handleSubmit, setValue, control } = reactHookProps;

  const {
    certificationsFromApi,
    isLoadingCertifications,
    isRefetchingCertifications,
  } = useCertificationList();

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...certificationsFromApi,
  ];

  const demandeHabilitationStatutList = [
    { id: undefined, text: "Tous", key: "all" },
    ...tools.addKeyToObjectInArray(
      constants.DEMANDE_HABILITATION.STATUTS,
      "id"
    ),
  ];

  useEffect(() => {
    const parsed: any = queryString.parse(location.search);
    if (parsed["statut"]) {
      setValue("statut", {
        key: parsed["statut"],
        id: parsed["statut"],
        text: constants.DEMANDE_HABILITATION.STATUTS.find(
          (statut: any) => statut.key == parsed["statut"]
        )?.text,
      });
      handleSubmit(actions.changeFilters)();
    }
  }, []);

  const totalDemandesHabilitationUtilisateur =
    demandeHabilitationsUtilisateur?.meta?.total || 0;

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            align="start"
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => (
                <Dropdown
                  label="Statut de la demande :"
                  selectedKey={value ? value.key : undefined}
                  placeholder="..."
                  options={demandeHabilitationStatutList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={demandeHabilitationStatutList[0]}
              control={control}
              name="statut"
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value, onBlur, ref } }) => {
                return (
                  <Dropdown
                    selectedKey={value ? value.key : undefined}
                    label="Certification :"
                    placeholder="Sélectionnez une certification"
                    options={certificationList}
                    onChange={(e: any, options: any) => {
                      setValue(name, options, { shouldValidate: true });
                    }}
                    styles={commonInputStyles.dropdownStyle}
                  />
                );
              }}
              defaultValue={certificationList[0]}
              control={control}
              name="certification"
            />
          </Stack.Item>

          <Can I={branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR}>
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectFieldAsync
                    label="Positionneur :"
                    placeholder="Recherchez un positionneur"
                    resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?recherche=${terms}&roles[]=POSITIONNEUR&paginate=0`
                    }
                    method="GET"
                    renderItem={(item: any) => item.nom + " " + item.prenom}
                    renderValue={(item: any) => item.nom + " " + item.prenom}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                control={control}
                name="positionneur"
              />
            </Stack.Item>
          </Can>

          <Can I={branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR}>
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <SelectFieldAsync
                    label="Évaluateur :"
                    placeholder="Recherchez un évaluateur"
                    resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
                    addQueryURL={(resourceURI: any, terms: any) =>
                      `${resourceURI}?recherche=${terms}&roles[]=EVALUATEUR&paginate=0`
                    }
                    method="GET"
                    renderItem={(item: any) => item.nom + " " + item.prenom}
                    renderValue={(item: any) => item.nom + " " + item.prenom}
                    dataIndex="uuid"
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    fieldRef={ref}
                    onChange={(e: any) => {
                      setValue(name, e.target.value, {
                        shouldValidate: true,
                      });
                    }}
                    handleResponse={(response: any) => [...response.data.data]}
                  />
                )}
                control={control}
                name="evaluateur"
              />
            </Stack.Item>
          </Can>
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingDemandeHabilitationsUtilisateur}
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalDemandesHabilitationUtilisateur} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default DemandeHabilitationUtilisateurListFilters;
