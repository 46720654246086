import { DefaultButton, Stack } from "@fluentui/react";
import Accordion from "../../../Common/Accordion/Accordion";
import { accordionMenuStyles } from "../../../Common/Accordion/Accordion.styles";
import DynamicField from "../../../Common/DynamicField/DynamicField";
import formSectionStyles from "../../../Common/FormSection/FormSection.styles";
import Loader from "../../../Common/Loader/Loader";
import useTheme from "../../../Common/useTheme";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";
import { commonButtonStyles } from "../../../styles";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";

const FieldList = () => {
  const { primaryColor } = useTheme();
  const {
    dynamicFields,
    actions,
    isLoadingToggleActifUUID,
    STATUTS_CANDIDATURE_ENABLED,
  } = useDynamicFields();
  const { actions: accessActions } = useAccessSettings();

  return (
    <Stack>
      {STATUTS_CANDIDATURE_ENABLED.filter(
        (statut: any) => statut.key !== "avis_second_evaluateur"
      ).map((statut: any) => {
        const fields = dynamicFields.filter(
          (field: any) => field.statut_candidature.key === statut.key
        );

        return (
          <Accordion
            styles={accordionMenuStyles(primaryColor)}
            title={
              <>
                {statut.text} ({fields.length})
              </>
            }
            content={
              <Stack
                styles={formSectionStyles.formContainer}
                tokens={{ childrenGap: 15 }}
              >
                {fields.map((field: any) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "65%" }}>
                      <DynamicField {...field} />
                    </div>
                    <div style={{ display: "flex", margin: 20 }}>
                      <DefaultButton
                        onClick={() => actions.openAddFieldForm(field)}
                        style={{ border: 0, fontSize: 16 }}
                        styles={commonButtonStyles.buttonLink}
                      >
                        Éditer
                      </DefaultButton>
                      {isLoadingToggleActifUUID == field.uuid ? (
                        <Loader />
                      ) : (
                        <DefaultButton
                          onClick={() =>
                            field.actif
                              ? actions.desactiveField(field)
                              : actions.activeField(field)
                          }
                          style={{ border: 0 }}
                          styles={
                            field.actif
                              ? commonButtonStyles.disableButtonLink
                              : commonButtonStyles.activeButtonLink
                          }
                        >
                          {field.actif ? "Désactiver" : "Activer"}
                        </DefaultButton>
                      )}
                    </div>
                  </div>
                ))}
              </Stack>
            }
          />
        );
      })}
    </Stack>
  );
};

export default FieldList;
