import candidatAdapters from "../../adapters/candidatAdapters";
import useCandidatMutations from "./useCandidatMutations";

function useCandidat() {
    const { candidatUuid, setCandidatUuid, mutations } = useCandidatMutations()

    const actions = {
        setUpdateCandidatUuid: (data: any) => {
            setCandidatUuid(data);
        },
        putCandidat: async (candidat:any,userUuid:any)=>{
            const candidatData = candidatAdapters.transformForAPI(candidat)
            mutations.PutCandidat.mutateAsync({candidatData,userUuid})
        }
    }

    const {
        isLoading:isLoadingPutCandidat,
        isError:isErrorPutCandidat,
        error:errorPutCandidat,
    } = mutations.PutCandidat

    return {
        candidatUuid,
        actions,
        isLoadingPutCandidat,
        isErrorPutCandidat,
        errorPutCandidat,
    }
}

export default useCandidat