import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import ImportEntities from "../../Common/ImportEntities/ImportEntities";
import { entityTypes } from "../../Common/ImportEntities/useImportEntities";
import useCustomModal from "../../Common/Overlays/CustomModal/useCustomModal";
import useCandidature from "../../hooks/candidature/useCandidature";
import {
  getCandidaturesRequest,
  getExportCandidatures,
} from "../../services/candidatureService";
import tools from "../../utils/tools";
import DesincriptionConfirmDialog from "./DesincriptionConfirmDialog/DesincriptionConfirmDialog";
import GiveUpConfirmDialog from "./GiveUpConfirmDialog/GiveUpConfirmDialog";
import ExportAsyncMessage from "../../Common/ExportAsyncMessage/ExportAsyncMessage";

export const initialFilters = {
  nom: "",
  prenom: "",
  statuts: [],
  entreprises: [],
  ofs: [],
  certifications: { id: undefined, text: "Toutes", key: "all" },
  en_attente_de_ma_part: false,
  domaines: [],
  jury_associe: "",
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});

function useCandidatureList() {
  const candidatureProps = useCandidature();
  const { actions: modalActions } = useCustomModal();

  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);
  const [isLoadingExportCandidatures, setIsLoadingExportCandidatures] =
    useState(false);
  const [highlightedUuid, setHighLightedUuid]: any = useState([]);

  const reactHookProps = useForm<any>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const { data: candidatures, isLoading: isLoadingCandidatures } = useQuery(
    ["candidatures", optionsFilters, candidatureProps.userSelectedBranch],
    () =>
      getCandidaturesRequest(
        tools.addUnderStageToFilter(filters),
        optionsFilters
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        toast.error(err.message);
      },
    }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    openGiveUpCandidatureModal: (candidature: any) => {
      modalActions.openModal({
        body: (
          <GiveUpConfirmDialog
            candidat={`${candidature?.candidat?.nom} ${candidature?.candidat?.prenom}`}
            giveUpFunction={() =>
              candidatureProps.actions.patchGiveUpCandidature(candidature.uuid)
            }
          />
        ),
      });
    },
    openDesincriptionConfirmModal: (candidature: any) => {
      modalActions.openModal({
        body: (
          <DesincriptionConfirmDialog
            candidat={`${candidature?.candidat?.nom} ${candidature?.candidat?.prenom}`}
            desinscriptionAction={() =>
              candidatureProps.actions.patchDesinscireCandidature(
                candidature.uuid
              )
            }
          />
        ),
      });
    },
    onImportCandidats: () => {
      modalActions.openModal({
        body: <ImportEntities entityType={entityTypes.CANDIDATURE} isAsync />,
        fullWidth: true,
      });
    },
    onExportCandidats: async () => {
      setIsLoadingExportCandidatures(true);
      try {
        const firstResponse: any = await getExportCandidatures(
          tools.addUnderStageToFilter(filters)
        ).catch((e: any) => {
          toast.error(
            "La recherche est trop volumineuse ou ne contient aucune donnée. Veuillez préciser votre recherche."
          );
        });
        if (!firstResponse) return;

        modalActions.openModal({
          body: <ExportAsyncMessage />,
        });

        // const fileUrl = firstResponse.data?.data?.url;
        // const fileName = firstResponse.data?.data?.nom_fichier;
        // if (process.env.REACT_APP_MODE == "development") {
        //   const downloadLink = document.createElement("a");
        //   downloadLink.href = fileUrl;
        //   downloadLink.download = fileName;
        //   downloadLink.target = "_blank";
        //   downloadLink.click();
        //   downloadLink.remove();
        // } else {
        //   //NOT WORKING IN DEV (CORS ERROR)
        //   axios
        //     .get(fileUrl, {
        //       responseType: "blob",
        //     })
        //     .then((response: any) => {
        //       fileDownload(response.data, fileName);
        //     })
        //     .catch((err: any) => {
        //       toast.error(
        //         "Une erreur est survenue lors du téléchargement, veuillez réessayer."
        //       );
        //     });
        //   //--------------------------------
        // }

        setIsLoadingExportCandidatures(false);
      } catch (e: any) {
        setIsLoadingExportCandidatures(false);
      }
    },
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
      setHighLightedUuid([]);
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.onChangeOptionFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    changeStatuts: (actualValue: any, newValue: any) => {
      if (actualValue) {
        if (actualValue.find((v: any) => v.key == newValue.key)) {
          reactHookProps.setValue(
            "statuts",
            actualValue.filter((v: any) => v.key !== newValue.key)
          );
        } else {
          if (newValue.canBeAddedWithOtherStatuts) {
            reactHookProps.setValue(
              "statuts",
              [...actualValue, newValue].filter(
                (opt: any) => opt.canBeAddedWithOtherStatuts
              )
            );
          } else {
            reactHookProps.setValue("statuts", [newValue]);
          }
        }
      } else {
        reactHookProps.setValue("statuts", [newValue]);
      }
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
  };

  return {
    candidatures,
    candidatureProps,
    isLoadingCandidatures,
    isLoadingExportCandidatures,
    optionsFilters,
    modalActions,
    actions,
    reactHookProps,
    highlightedUuid,
    setHighLightedUuid,
  };
}

export default useCandidatureList;
