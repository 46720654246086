import Joi from "joi";

export const competenceBlockSchema = Joi.object({
  libelle: Joi.string().required().max(1000).messages({
    "any.required": "Veuillez renseigner un libellé",
    "string.empty": "Veuillez renseigner un libellé",
    "string.max": "La raison sociale ne doit pas dépasser 150 caractères",
  }),
  actif: Joi.boolean().required().default(false),
  inter_branches: Joi.boolean().required().default(false),
  competences_associees: Joi.array()
    .items(
      Joi.object().keys({
        libelle: Joi.string()
          .required(),
      })
    )
    .allow(null),
  competences: Joi.array()
    .items(
      Joi.object()
        .keys({
          uuid: Joi.string().required(),
        })
        .unknown(true)
    )
    .messages({
      "any.required": "Veuillez choisir au moins une compétence rattachée",
      "array.min": "Veuillez choisir au moins une compétence rattachée",
    }),
  document_evaluation: Joi.object(),
  attestation: Joi.object(),
}).unknown(true);
