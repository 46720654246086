import {
  Dropdown,
  DropdownMenuItemType,
  IDropdownOption,
  Stack,
  Text,
} from "@fluentui/react";
import Loader from "../Loader/Loader";
import CopyCard from "../CopyCard/CopyCard";

function DictionnaireData({ dictionnaire, isLoadingDictionnaire }: any) {
  const items = dictionnaire ?? [];

  const dataGroupByCategorie = (Object as any).groupBy(
    items,
    ({ categorie }: any) => categorie
  );

  return (
    <div className="dictionnaireData">
      {isLoadingDictionnaire ? (
        <Loader />
      ) : (
        <>
          <Stack>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              Dictionnaire de données
            </Text>
            <Text style={{ color: "#6f6f6f", fontSize: 13 }}>
              Veuillez sélectionner la donnée souhaitée puis coller la à
              l'endroit désiré.
            </Text>
          </Stack>
          <Stack style={{ marginTop: 20 }} tokens={{ childrenGap: 20 }}>
            {Object.keys(dataGroupByCategorie).map((categorie: any) => (
              <Stack>
                <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                  {categorie}
                </Text>
                <Stack tokens={{ childrenGap: 5 }} style={{ marginTop: 5 }}>
                  {dataGroupByCategorie[categorie].map((item: any) => (
                    <CopyCard
                      id={item.id}
                      text={item.label}
                      value={item.value}
                    />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
}

export const DictionnaireDataDropdown = ({
  dictionnaire,
  isLoadingDictionnaire,
}: any) => {
  const items = dictionnaire ?? [];

  // Group items by categorie
  const dataGroupByCategorie = (Object as any).groupBy(
    items,
    ({ categorie }: any) => categorie
  );
  // Transform grouped data into dropdown options
  const dropdownOptions: IDropdownOption[] = [];

  Object.keys(dataGroupByCategorie).forEach((categorie: any) => {
    // Add category header
    dropdownOptions.push({
      key: categorie,
      text: categorie,
      itemType: DropdownMenuItemType.Header,
    });

    // Add items under the category
    dataGroupByCategorie[categorie].forEach((item: any) => {
      dropdownOptions.push({
        key: item.id, // Assuming each item has a unique id
        text: item.label, // Assuming each item has a label property
        data: item, // Pass the item data to the option
      });
    });
  });
  // Custom render function for dropdown options
  const onRenderOption = (option?: IDropdownOption): JSX.Element | null => {
    if (option?.itemType === DropdownMenuItemType.Header) {
      return <Text style={{ fontWeight: "bold" }}>{option.text}</Text>;
    }
    if (option?.data) {
      const item = option.data;
      return <CopyCard id={item.id} text={item.label} value={item.value} />;
    }

    return null;
  };
  console.log("dropdownOptions", dropdownOptions);
  return (
    <div className="dictionnaireDataDropdown">
      {isLoadingDictionnaire ? (
        <Loader />
      ) : (
        <Dropdown
          placeholder="Dictionnaire de données"
          options={dropdownOptions}
          styles={{
            dropdown: { width: "100%" },
            dropdownItem: { height: "auto" }, // Adjust the height as needed
            dropdownItemSelected: { height: "auto" },
          }}
          onRenderOption={onRenderOption}
        />
      )}
    </div>
  );
};

export default DictionnaireData;
