const commonPasswordStyles = {
    stackTokens: {
        childrenGap: 25
    },
    inputStyle: {
        root: {
            width: "300px",
            selectors: {
                ".ms-TextField-fieldGroup": {
                    backgroundColor:'#f8f8f8',
                    border: '1px solid #CED5DA',
                    height:'34px',
                },
                ".ms-TextField-field":{
                    fontSize:'16px'
                },
                ".ms-Label":{
                    fontSize:'16px',
                    fontWeight:'500'
                }
            }
        },
    },
}

export default commonPasswordStyles