import { useQuery } from "react-query";
import { getCertificationRequest } from "../../services/certificationServices";

const useCertificationQuery = (certificationProps: any) => {
  const { data: certification, isLoading, isRefetching } = useQuery(
    ["certifications", certificationProps.certificationUuid],
    () => getCertificationRequest(certificationProps.certificationUuid),
    { 
      enabled: !!certificationProps.certificationUuid,
      retry:0,
      refetchOnWindowFocus:false,
    }
  );

  return {
    certification,
    isLoading,
    isRefetching
  };
};

export default useCertificationQuery;
