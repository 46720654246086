import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { putMyStructureRequest } from "../../services/myStructureService";
import { useAtom } from "jotai";
import { authAtom } from "../useAuth";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";

export enum QueryClientStructure {
  "entreprise" = "companies",
  "organisme_de_formation" = "ofs",
  "groupe_entreprise" = "groupCompanies",
  "groupe_of" = "groupOfs",
}

function useMyStructureMutations() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [auth, setAuth] = useAtom(authAtom);

  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const mutations = {
    PutMyStructure: useMutation(putMyStructureRequest, {
      onSuccess: (data, variables) => {
        toast.success("La structure a été mise à jour avec succès.");
        setAuth({
          ...auth,
          user: {
            ...auth.user,
            mes_structures:
              (auth.user?.mes_structures as any)?.map((structure: any) =>
                structure.uuid == data.uuid
                  ? { ...data, branch_id: userSelectedBranch.id }
                  : structure
              ) ?? [],
          },
        });
        queryClient.invalidateQueries(
          QueryClientStructure[variables.structureType]
        );
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la mise à jour de la structure : ${error?.message}`
            );
      },
    }),
  };

  return { mutations };
}

export default useMyStructureMutations;
