import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import SectionTitle from "../../../../Common/SectionTitle/SectionTitle";
import { TransmissionDossierContext } from "../useTransmissionDossier";

function PartiesPrenantesSection() {
  const { candidature }: any = useContext(TransmissionDossierContext);
  const [partiePrenantes, setPartiePrenantes] = useState<any>([]);

  useEffect(() => {
    const partiePrenantesList: any = [];
    if (candidature?.of_lie) {
      partiePrenantesList.push({ ...candidature.of_lie, role: "OF" });
    }
    if (candidature?.entreprise_liee) {
      partiePrenantesList.push({
        ...candidature.entreprise_liee,
        role: "Entreprise",
      });
    }
    if (candidature?.positionneur) {
      partiePrenantesList.push({
        ...candidature.positionneur,
        role: "Positionneur",
      });
    }
    if (candidature?.evaluateur) {
      partiePrenantesList.push({
        ...candidature.evaluateur,
        role: "Évaluateur",
      });
    }
    setPartiePrenantes(partiePrenantesList);
  }, [candidature]);

  const columns = [
    {
      key: "1",
      name: "Dénomination",
      fieldName: "denomination",
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: any) =>
        item?.name ? (
          <Stack>{item.name}</Stack>
        ) : (
          <Stack>{item?.raison_sociale ?? item?.nom}</Stack>
        ),
    },
    {
      key: "2",
      name: "Email",
      fieldName: "email",
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: any) =>
        item?.email ? <Stack>{item.email}</Stack> : <></>,
    },
    // {
    //   key: "3",
    //   name: "Habilitations",
    //   fieldName: "habilitations",
    //   minWidth: 100,
    //   maxWidth: 400,
    //   isResizable: true,
    //   onRender: (item: any) => <Stack>{/* Depend si utilisateur / of / entreprise / ... */}</Stack>,
    // },
    {
      key: "3",
      name: "Rôle",
      fieldName: "role",
      minWidth: 100,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: any) => <Stack>{item.role}</Stack>,
    },
  ];

  return (
    <Stack>
      <SectionTitle title="Parties prenantes" />

      <DetailsList
        layoutMode={DetailsListLayoutMode.justified}
        items={partiePrenantes}
        columns={columns}
        selectionMode={SelectionMode.none}
      />
    </Stack>
  );
}

export default PartiesPrenantesSection;
