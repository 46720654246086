import { Stack } from "@fluentui/react";
import { joiResolver } from "@hookform/resolvers/joi";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiAddFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import BackLink from "../../Common/BackLink/BackLink";
import Can from "../../Common/Can/Can";
import CancelButton from "../../Common/CancelButton/CancelButton";
import DetailLoader from "../../Common/DetailLoader/DetailLoader";
import JuryStatusBadge from "../../Common/JuryStatusBadge/JuryStatusBadge";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import PageMessage from "../../Common/PageMessage/PageMessage";
import juryAdapters from "../../adapters/juryAdapters";
import { userAccessEnum } from "../../config/accessEnum";
import useConstants from "../../hooks/useConstants";
import { jurySchema } from "../../schemas/jurySchema";
import DocumentsJury from "./components/DocumentsJury";
import InformationsPrincipalesFormSection from "./components/InformationsPrincipalesFormSection";
import ParticipantsFormSection from "./components/ParticipantsFormSection";
import useJuryDetail from "./useJuryDetail";
import CommentaireJury from "./components/CommentaireJury";

function JuryDetail() {
  const { uuid } = useParams();
  const { constants } = useConstants();
  const {
    jury,
    actions: actionsPage,
    juryProps,
    isLoadingJury,
    setJuryInfo,
  } = useJuryDetail();

  const { actions, isLoading, hasError, errorPostJury, errorPutJury } =
    juryProps;
  const reactHookProps = useForm<any>({
    resolver: joiResolver(jurySchema),
    mode: "onBlur",
  });

  const { handleSubmit, clearErrors, reset, setError, register } =
    reactHookProps;

  useEffect(() => {
    return () => {
      reset();
      setJuryInfo({});
    };
  }, []);

  useEffect(() => {
    if (uuid !== "creer") {
      actions.setUpdateJuryUuid(uuid);
    }
  }, [uuid]);

  useEffect(() => {
    if (jury?.uuid) {
      reset(juryAdapters.transformForBO(jury, constants));
    }
  }, [jury]);

  useEffect(() => {
    var apiErrors = (errorPostJury as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPostJury]);

  useEffect(() => {
    var apiErrors = (errorPutJury as any)?.response?.data;
    if (apiErrors?.errors) {
      for (var key in apiErrors.errors) {
        setError(key as any, { type: "focus", message: apiErrors.errors[key] });
      }
    }
  }, [errorPutJury]);

  const onSubmit = (e: any) => {
    clearErrors();
    handleSubmit(actionsPage.saveJury)(e);
  };

  return (
    <Stack>
      <BackLink />
      <Page
        title={!jury?.uuid ? "Veuillez créer un jury" : "Modifier un jury"}
        explain={
          !jury?.uuid
            ? "Veuillez créer un nouveau jury de certification"
            : "Veuillez modifier le jury"
        }
      >
        <DetailLoader isLoadingDetail={isLoadingJury}>
          <form onSubmit={onSubmit}>
            <FormProvider {...reactHookProps}>
              <input
                type="hidden"
                {...register("_method", {
                  value: !jury?.uuid ? "POST" : "PUT",
                })}
              />
              {jury?.uuid && <JuryStatusBadge statut={jury.statut} />}
              <InformationsPrincipalesFormSection />
              <ParticipantsFormSection />
              <DocumentsJury />
              {jury?.uuid && <CommentaireJury />}
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginTop: 20 }}
              >
                <CancelButton />

                <Can I={userAccessEnum.CREER_JURYS_CERTIFICATION}>
                  <LoadButton
                    text={!jury?.uuid ? "Créer le jury" : "Modifier le jury"}
                    isLoading={isLoading}
                    icon={<RiAddFill size={38} />}
                    style={{ fontWeight: "bold" }}
                  />
                </Can>
              </Stack>
            </FormProvider>
          </form>
          {hasError && (
            <PageMessage
              message={
                ((errorPostJury as any) || (errorPutJury as any)).response.data
                  .message
              }
              type="error"
            />
          )}
        </DetailLoader>
      </Page>
    </Stack>
  );
}

export default JuryDetail;
