import { FontWeights, Stack, Text } from "@fluentui/react";
import { BsPauseFill } from "react-icons/bs";
import { TiTick, TiTimes } from "react-icons/ti";

enum DecisionsEnum {
    BESOIN_DE_FORMATION,
    VALIDE,
    NON_VALIDE,
    NON_RENSEIGNE,
    MAITRISE,
  }

interface IDecisionProps {
    decision: DecisionsEnum;
    commentTitle?: string;
    comment?: string;
  }

function Decision({ decision, commentTitle, comment }: IDecisionProps) {
    return (
        <Stack horizontal tokens={{ childrenGap: 7 }}>
          <Stack>
            {decision == DecisionsEnum.BESOIN_DE_FORMATION && (
              <BsPauseFill color={"#df793a"} size={25} />
            )}
            {decision == DecisionsEnum.VALIDE && <TiTick color={"#0aa204"} size={25} />}
            {decision == DecisionsEnum.NON_VALIDE && <TiTimes color={"#df793a"} size={25} />}
            {decision == DecisionsEnum.MAITRISE && <TiTick color={"#0aa204"} size={25} />}
          </Stack>
          <Stack>
            <Text variant="mediumPlus" style={{ fontWeight: FontWeights.bold }}>
              {decision == DecisionsEnum.BESOIN_DE_FORMATION && "Besoin de formation"}
              {decision == DecisionsEnum.VALIDE && "Validé"}
              {decision == DecisionsEnum.NON_VALIDE && "Non validé"}
              {decision == DecisionsEnum.MAITRISE && "Maitrisé"}
              {decision == DecisionsEnum.NON_RENSEIGNE && "-"}
            </Text>
            {(comment || commentTitle) && (
              <Stack>
                <Text variant="medium">{commentTitle}</Text>
                <Text variant="small" style={{ color: "#6F6F6F" }}>
                  {comment}
                </Text>
              </Stack>
            )}
          </Stack>
        </Stack>
      );
}

export default Decision