import { Stack, TextField } from '@fluentui/react'
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import subGroupOfAdapters from '../../adapters/subGroupOfAdapters';
import BackLink from '../../Common/BackLink/BackLink'
import Can from '../../Common/Can/Can';
import CancelButton from '../../Common/CancelButton/CancelButton';
import FormSection from '../../Common/FormSection/FormSection';
import LoadButton from '../../Common/LoadButton/LoadButton';
import Page from '../../Common/Page/Page'
import SelectFieldAsync from '../../Common/SelectField/SelectFieldAsync/SelectFieldAsync';
import SelectManyFieldAsync from '../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync';
import { userAccessEnum } from '../../config/accessEnum';
import { API_URL } from '../../config/config';
import { commonInputStyles } from '../../styles';
import useSubGroupOfDetail from './useSubGroupOfDetail';
import DetailLoader from '../../Common/DetailLoader/DetailLoader';

function SubGroupOfDetail() {
    const { sous_groupe_of_uuid } = useParams();
    const { subGroupOf, subGroupOfProps, reactHookProps, isLoadingSubGroupOf } = useSubGroupOfDetail();
    const { actions } = subGroupOfProps;

    useEffect(() => {
        return () => reactHookProps.reset()
      }, [])

    useEffect(() => {
        actions.setUpdateSubGroupOfUuid(sous_groupe_of_uuid);
    }, [sous_groupe_of_uuid]);

    useEffect(() => {
        if (subGroupOf?.uuid) {
            reactHookProps.reset(subGroupOfAdapters.transformForBO(subGroupOf));
        }
    }, [subGroupOf]);

    const onSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        toast.info('En cours de développement')
    }

    return (
        <Stack>
            <BackLink />
            <Page
                title="Détail du sous-groupe"
                explain="Veuillez trouver les informations du sous-groupe"
            >

                <DetailLoader isLoadingDetail={isLoadingSubGroupOf}>
                    <form onSubmit={onSubmit}>

                        <FormSection sectionTitle="Informations principales">
                            <Stack.Item>
                                <Controller
                                    render={({ field }) => (
                                        <TextField
                                            styles={commonInputStyles.textField}
                                            required
                                            label="Nom du sous-groupe :"
                                            type="text"
                                            {...field}
                                            placeholder="Ex: Sous-groupe 1"
                                            errorMessage={(reactHookProps.formState.errors[field.name] as any)?.message}
                                        />
                                    )}
                                    defaultValue=""
                                    control={reactHookProps.control}
                                    name="nom"
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <Controller
                                    render={({ field: { name, value, onBlur, ref } }) => (
                                        <SelectFieldAsync
                                            label="Groupe d'OF du sous-groupe :"
                                            placeholder="Recherchez un groupe d'OF par SIRET ou Raison sociale (ex : 12312312312312 ou Groupe 1)"
                                            resourceURI={`${API_URL}api/administration/groupe-ofs/chercher`}
                                            addQueryURL={(resourceURI: any, terms: any) =>
                                                `${resourceURI}?recherche=${terms}`
                                            }
                                            method="GET"
                                            renderItem={(item: any) => {
                                                return (
                                                    <div>
                                                        {item.raison_sociale}{" "}
                                                        <span className="ms-fontColor-gray110">- {item.siren}</span>
                                                    </div>
                                                );
                                            }}
                                            renderValue={(item: any) => {
                                                return (
                                                    <div>
                                                        {item.raison_sociale}{" "}
                                                        <span className="ms-fontColor-gray110">- {item.siren}</span>
                                                    </div>
                                                );
                                            }}
                                            dataIndex="uuid"
                                            name={name}
                                            value={value}
                                            onBlur={onBlur}
                                            fieldRef={ref}
                                            onChange={(e: any) => {
                                                reactHookProps.setValue(name, e.target.value, {
                                                    shouldValidate: true,
                                                });
                                                reactHookProps.setValue('ofs', [], { shouldValidate: true })
                                            }}
                                            defaultValue={null}
                                            errorMessage={(reactHookProps.formState.errors[name] as any)?.message}
                                            handleResponse={(response: any) => [...response.data.data]}
                                            required
                                        />
                                    )}
                                    control={reactHookProps.control}
                                    name="groupeOf"
                                />
                            </Stack.Item>

                            {reactHookProps.watch('groupeOf') &&
                                <Stack.Item>
                                    <Controller
                                        render={({ field: { name, value, onBlur, ref } }) => (
                                            <SelectManyFieldAsync
                                                label="OFs liés au sous-groupe :"
                                                placeholder="Recherchez des OF par SIRET ou raison sociale (ex : 123123123123123 ou OF1)"
                                                resourceURI={`${API_URL}api/administration/ofs/chercher`}
                                                addQueryURL={(resourceURI: any, terms: any) =>
                                                    `${resourceURI}?recherche=${terms}&groupe=${reactHookProps.watch('groupeOf').uuid}`
                                                }
                                                method="GET"
                                                renderItem={(item: any) => {
                                                    return (
                                                        <div>
                                                            {item.raison_sociale}{" "}
                                                            <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                                                        </div>
                                                    );
                                                }}
                                                renderValue={(item: any) => {
                                                    return (
                                                        <div>
                                                            {item.raison_sociale}{" "}
                                                            <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                                                        </div>
                                                    );
                                                }}
                                                dataIndex="uuid"
                                                name={name}
                                                value={value}
                                                onBlur={onBlur}
                                                fieldRef={ref}
                                                onChange={(e: any) => {
                                                    reactHookProps.setValue(name, e.target.value, {
                                                        shouldValidate: true,
                                                    });
                                                }}
                                                defaultValue={[]}
                                                handleResponse={(response: any) => [...response.data.data]}
                                                errorMessage={(reactHookProps.formState.errors[name] as any)?.message}
                                                required
                                            />
                                        )}
                                        control={reactHookProps.control}
                                        name="ofs"
                                    />
                                </Stack.Item>
                            }

                        </FormSection>

                        <Stack horizontal horizontalAlign="space-between" style={{ marginTop: 20 }}>
                            <CancelButton />
                            <Can I={userAccessEnum.MODIFIER_SOUS_GROUPE}>
                                <LoadButton isLoading={false} text={"Modifier"} />
                            </Can>
                        </Stack>
                    </form>

                </DetailLoader>

            </Page>
        </Stack >
    )
}

export default SubGroupOfDetail