import { useEffect, useState } from "react";
import useOfflineCandidaturePile, {
  SYNCHRO_STATUS,
  SYNCHRO_STATUS_COLORS,
  SYNCHRO_STATUS_ICONS,
  SYNCHRO_STATUS_NAMES,
  TREATMENT_STATUS,
  TREATMENT_STATUS_COLORS,
  TREATMENT_STATUS_ICONS,
} from "../../hooks/offlineCandidature/useOfflineCandidaturePile";
import { atom, useAtom } from "jotai";
import { DefaultButton, IconButton, Stack } from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";
import { toast } from "react-toastify";
import { BsArrowLeft } from "react-icons/bs";
import SyncItem from "./SyncItem/SyncItem";
import SyncList from "./SyncList/SyncList";

export const isWidgetVisibleAtom = atom(true);

const SynchronizationWidget = () => {
  const {
    auth,
    candidaturesFilteredFromPile,
    isSynchronizingCandidatures,
    isPlayingSynchronization,
    setIsPlayingSynchronization,
    actions,
    isOffline,
  } = useOfflineCandidaturePile();
  const [isOpen, setIsOpen] = useState(false);
  const [isWidgetVisible] = useAtom(isWidgetVisibleAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (isPlayingSynchronization && isOffline) {
      toast.warn(
        "La synchronisation s'est arrêtée car vous êtes en mode hors ligne."
      );
      setIsPlayingSynchronization(false);
    }
    if (
      isPlayingSynchronization &&
      !isSynchronizingCandidatures &&
      !isOffline
    ) {
      actions.playSynchronization();
    }
  }, [isPlayingSynchronization, isSynchronizingCandidatures]);

  if (
    !auth.isAuthenticated ||
    candidaturesFilteredFromPile.length == 0 ||
    !isWidgetVisible
  )
    return null;

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  //height: calc(100vh - 80px);
  //width: min(calc(100vw - 80px), 400px);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 15,
        right: 15,
        padding: "5px 10px",
        background: "#fafafa",
        zIndex: "99",
        fontSize: 12,
        borderRadius: 10,
        border: "1px solid #cfcfcf",
        cursor: isOpen ? "initial" : "pointer",
        width: isOpen ? "min(calc(100vw - 80px), 400px)" : "auto",
        height: isOpen ? "calc(100vh - 80px)" : "auto",
        boxShadow: "grey 0px 0px 12px -8px",
        overflowY: "auto",
      }}
      onClick={onOpen}
    >
      <div style={{ marginBottom: 3, fontWeight: "500" }}>
        <Stack
          horizontal
          verticalAlign="center"
          onClick={() => navigate("/admin/offline/candidature-sync")}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <BsArrowLeft style={{ marginRight: 3 }} />
          Candidatures à synchroniser
        </Stack>
      </div>
      <div
        style={{
          margin: 0,
          display: "flex",
          flexDirection: isOpen ? "column" : "row",
          height: "calc(100% - 24px)",
        }}
      >
        {Object.keys(SYNCHRO_STATUS).map((key: any) => {
          return (
            <SyncItem
              key={`statit${key}`}
              text={SYNCHRO_STATUS_NAMES[key]}
              color={SYNCHRO_STATUS_COLORS[key]}
              qty={
                candidaturesFilteredFromPile.filter(
                  (c: any) => c.synchro_status == SYNCHRO_STATUS[key]
                ).length
              }
              icon={SYNCHRO_STATUS_ICONS[key]}
              showText={isOpen}
            />
          );
        })}

        <SyncItem
          text="Traitées"
          qty={`${
            candidaturesFilteredFromPile.filter(
              (c: any) => c.treatment_status == TREATMENT_STATUS.TREATED
            ).length
          }/${candidaturesFilteredFromPile.length}`}
          color={TREATMENT_STATUS_COLORS[TREATMENT_STATUS.TREATED]}
          icon={TREATMENT_STATUS_ICONS[TREATMENT_STATUS.TREATED]}
          showText
        />

        {isOpen && (
          <IconButton
            style={{
              position: "absolute",
              top: 5,
              right: 5,
            }}
            iconProps={{ iconName: "Cancel" }}
            onClick={onClose}
          />
        )}

        {isOpen && (
          <>
            <SyncList items={candidaturesFilteredFromPile} />
            <div
              style={{
                position: "absolute",
                bottom: "4px",
                width: "calc(100% - 20px)",
              }}
            >
              <DefaultButton
                style={{ width: "100%" }}
                onClick={
                  isPlayingSynchronization
                    ? actions.stopSynchronization
                    : actions.onSyncAllCandidaturesFromPile
                }
              >
                <FiRefreshCcw size={18} style={{ marginRight: 10 }} />
                {isPlayingSynchronization
                  ? "Arrêter la synchronisation"
                  : "Synchroniser les candidatures traitées"}
              </DefaultButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SynchronizationWidget;
