import { Label, MessageBar, Stack, TextField } from "@fluentui/react";
import { useAtom } from "jotai";
import { useEffect } from "react";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import candidatureAdapters from "../../../adapters/candidatureAdapters";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow, {
  StatutCandidature,
} from "../../../hooks/candidature/useCandidatureWorkflow";
import useDynamicFields from "../../../hooks/dynamicFields/useDynamicFields";
import useConstants from "../../../hooks/useConstants";
import { MessageBarStyles, commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import BlocSelectionRecap from "../ChoixBlocs/BlocSelectionRecap/BlocSelectionRecap";
import CandidatSection from "../Common/CandidatSection";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";
import CertificationSection from "../Common/CertificationSection";
import OptionsBlocsSection from "../Common/OptionsBlocsSection";
import DocumentAddedDuringCandidature from "./Sections/DocumentAddedDuringCandidature";
import useFetchCandidature from "../useFetchCandidature";
import useUrlParams from "../useUrlParams";
import MemberDecisionBlock from "./MemberDecisionBlock";
import ViewMembersDecisions from "./ViewMembersDecisions";
import { DecisionJuryProvider, useDecisionJury } from "./useDecisionJury";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import { Controller } from "react-hook-form";

const DecisionJury = () => {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const {
    candidature,
    reactHookProps,
    actions,
    candidatureSelectableBlocs,
    conditionnalDisplayVariables,
    generalConditionnalDisplayVariables,
    isSavedAvisJury,
    setIsSavedAvisJury,
    candidatureActions,
    isSaving,
    isConsultCandidaturesList,
    // isViewMembreAsAdmin,
    // setIsViewMembreAsAdmin
  } = useDecisionJury();
  const { dynamicFields } = useDynamicFields();
  const { labels } = useLabelSettings();
  const { constants } = useConstants();
  const { id_candidature } = useUrlParams();
  const {
    isReferentielStillEditable,
    isThisPageStatusBeforeCandidatureStatus,
    isFirstStatutBeforeOrEqualSecondStatus,
  } = useCandidatureWorkflow();
  const { actions: accessActions } = useAccessSettings();
  const { isAdmin } = useInfoProfilUser();
  const { isMembreForThisCandidature, isPresidentForThisCandidature } =
    generalConditionnalDisplayVariables;
  const {
    canRightDecisionPresident,
    canRightDecisionMembre,
    userViewingPageUuid,
    //  isAlreadyGiveAvis,
  } = conditionnalDisplayVariables;

  const fetchCandidature = useFetchCandidature(reactHookProps, (cand: any) => {
    //si la suggestion au president de l'evaluation est active sur la branche &&
    //(si c'est le president de la candidature qui accede a la page && si aujourd'hui est avant la fin de la decision du president ) || si c'est un admin &&
    //si la candidature est au statut decision_jury
    if (
      accessActions.canI({
        action: [branchAccessEnum.SUGGESTION_EVALUATION_PRESIDENT_JURY],
      }).granted &&
      ((isPresidentForThisCandidature &&
        tools.isThisEndOfDateAfterStartOfToday(cand.jury?.date_jury)) ||
        isAdmin) &&
      cand.statut == "decision_jury"
    ) {
      actions.preFillDecisionPresidentJury();
    }
  });

  //acces aux champs pour donner son avis (membre, president ou admin)
  const etapeEnabled =
    isAdmin || canRightDecisionPresident || canRightDecisionMembre;

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, "resultats_jury")
      ? [userAccessEnum.SAISIR_AVIS_JURY, userAccessEnum.CREER_DECISION_JURY]
      : [userAccessEnum.NO_ACCESS_EVEN_ADMIN] //si la candidature n'est plus modifiable même pas un admin, on requiert la permission NO_ACCESS qui bloquera tout le monde
    : [userAccessEnum.SAISIR_AVIS_JURY, userAccessEnum.CREER_DECISION_JURY];

  const JURY_OUVERT_ID = 1;

  //si on a pas les droits pour donner acceder à cette page et remplir les champs ||
  //si la personne est membre pour cette candidature && si la date pour donner un avis en tant que membre est echue ||
  //si la personne est president pour cette candidature && si la date pour donner un avis en tant president est echue ||
  //si la personne est membre pour cette candidature && le membre a deja donne son avis
  //si la personne est admin && le jury est ouvert (1)
  const inputDisabled =
    !accessActions.canI({ action: [...applicableEditPermission] }).granted ||
    (isMembreForThisCandidature &&
      tools.isThisEndOfDateBeforeStartOfToday(candidature.jury?.date_jury)) ||
    (isPresidentForThisCandidature &&
      tools.isThisEndOfDateBeforeStartOfToday(candidature.jury?.date_jury)) ||
    //(isMembreForThisCandidature && isAlreadyGiveAvis) ||
    (isAdmin && candidature.jury?.statut == JURY_OUVERT_ID);

  //message retourne si on a pas encore acces au champs pour donner son avis en fonction du role que l'utilisateur a dans le jury ou autre
  const getMessageForProfil = () => {
    if (isMembreForThisCandidature) {
      return `Les membres de ce jury peuvent laisser leur avis entre le ${tools.reformateDateFromJsDate(
        candidature.jury.date_acces_membres_jury
      )} et le ${tools.reformateDateFromJsDate(
        candidature.jury.date_jury
      )} (compris).`;
    } else if (isPresidentForThisCandidature) {
      return `Le président de ce jury peut renseigner sa décision entre le ${tools.reformateDateFromJsDate(
        candidature.jury.date_acces_membres_jury
      )} et le ${tools.reformateDateFromJsDate(
        candidature.jury.date_jury
      )} (compris).`;
    } else {
      return "Vous n'avez pas accès à l'étape décision du jury";
    }
  };

  const fetchModifiedCandidature = async () => {
    const candidatureModified = await candidatureActions.getCandidatureDetail(
      id_candidature
    );
    reactHookProps.reset(
      candidatureAdapters.transformForBO(
        candidatureModified,
        constants,
        isFirstStatutBeforeOrEqualSecondStatus(
          candidature.statut,
          "identification_parties_prenantes"
        ),
        dynamicFields
      )
    );
  };

  useEffect(() => {
    if (id_candidature && isSavedAvisJury) {
      fetchModifiedCandidature();
    }
  }, [isSavedAvisJury]);

  const onSubmitForValidationAvisMembre = (e: any) => {
    setIsSavedAvisJury(false);
    reactHookProps.clearErrors();
    reactHookProps.handleSubmit(actions.onSaveMembre)(e);
  };

  const onSubmitPresident = (
    e: any,
    isGoToNextCandidature: boolean = false
  ) => {
    setIsSavedAvisJury(false);
    reactHookProps.clearErrors();
    reactHookProps.handleSubmit((data) =>
      actions.onSavePresident(data, isGoToNextCandidature)
    )(e);
  };

  return !candidature.uuid ? null : (
    <DecisionJuryProvider
      value={{
        reactHookProps,
        actions,
        candidature,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection certification={candidature.certification} />
        </CandidatureInfoHeaderWrapper>
        {!etapeEnabled ? (
          <Stack.Item style={{ backgroundColor: "#F2F2F2", marginTop: 20 }}>
            <MessageBar styles={MessageBarStyles}>
              {getMessageForProfil()}
            </MessageBar>
          </Stack.Item>
        ) : (
          <form>
            <OptionsBlocsSection
              candidature={candidature}
              statutCandidature={StatutCandidature.DECISION_JURY}
              reactHookProps={reactHookProps}
              isPresident={isPresidentForThisCandidature || isAdmin}
              disabled={inputDisabled}
              onValidate={actions.onValidate}
              onInvalidate={actions.onInvalidate}
              //(isAdmin && !isViewMembreAsAdmin) ald isAdmin bouton switch vision de l'admin
            />
            {candidature.commentaire_candidat_positionnement && (
              <Can I={userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT}>
                <FormSection sectionTitle="Avis du candidat au positionnement">
                  <Label>
                    Commentaire laissé par le candidat au positionnement :
                  </Label>
                  {candidature.commentaire_candidat_positionnement}
                </FormSection>
              </Can>
            )}

            {candidature.avis_formateur && (
              <FormSection sectionTitle="Avis du formateur">
                <Label>Avis laissé par le fomateur :</Label>
                {candidature.avis_formateur?.avis}
              </FormSection>
            )}
            {candidature.avis_second_evaluateur && (
              <Can I={userAccessEnum.VISUALISER_AVIS_SECOND_EVALUATEUR}>
                <FormSection
                  sectionTitle={labels.AVIS_SECOND_EVALUATEUR_MENTION_AVIS}
                >
                  <Label>Avis laissé par le second évaluateur :</Label>
                  {candidature.avis_second_evaluateur.avis}
                </FormSection>
              </Can>
            )}
            {candidature.commentaire_candidat_evaluation && (
              <Can I={userAccessEnum.VISUALISATION_VALIDATION_EVALUATION}>
                <FormSection sectionTitle="Commentaire du candidat à l'évaluation">
                  <Label>
                    Commentaire laissé par le candidat à l'évaluation :
                  </Label>
                  {candidature.commentaire_candidat_evaluation}
                </FormSection>
              </Can>
            )}
            {isMembreForThisCandidature && (
              //|| (isAdmin && isViewMembreAsAdmin) bouton switch vision
              <MemberDecisionBlock
                decision={
                  (reactHookProps.watch("avis_jury") || []).find(
                    (avis: any) => avis.membre_jury.uuid == userViewingPageUuid
                  ) || { avis: null }
                }
                onChangeDecision={actions.onChangeDecisionMembre}
                disabled={inputDisabled}
              />
            )}
            {(isPresidentForThisCandidature || isAdmin) && (
              //(isAdmin && !isViewMembreAsAdmin) ald isAdmin bouton switch vision de l'admin
              <>
                {candidature.jury?.membres.length > 0 ? (
                  <Stack.Item>
                    <ViewMembersDecisions
                      decisions={candidature.avis_jury ?? []}
                      allMembres={candidature.jury?.membres ?? []}
                    />
                  </Stack.Item>
                ) : (
                  <Stack.Item
                    style={{ backgroundColor: "#F2F2F2", marginTop: 20 }}
                  >
                    <MessageBar styles={MessageBarStyles}>
                      Ce jury ne possède aucun membre.
                    </MessageBar>
                  </Stack.Item>
                )}
                <Stack.Item style={{ marginTop: 24 }}>
                  <BlocSelectionRecap
                    blocs_competences_passes={
                      candidature.blocs_competences_passes
                    }
                    blocs_competences={candidatureSelectableBlocs}
                    nb_blocs_au_choix_a_valider={
                      candidature.certification?.nb_blocs_au_choix_a_valider
                    }
                    blocs_competences_updated={reactHookProps.watch(
                      "blocs_competences_passes"
                    )}
                    showValidation
                  />
                </Stack.Item>
                <FormSection sectionTitle="Commentaire du jury">
                  <Stack.Item
                    className="ms-Grid-col ms-sm12"
                    style={{ paddingRight: 20, marginTop: 10 }}
                  >
                    <Controller
                      render={({ field }) => (
                        <TextField
                          styles={commonInputStyles.textField}
                          label="Commentaire du jury (6000 caractères max) :"
                          placeholder="Ex : commentaire du jury"
                          multiline
                          required={
                            accessActions.canI({
                              action:
                                branchAccessEnum.OBLIGER_COMMENTAIRE_JURY_PAR_CANDIDATURE,
                            }).granted
                          }
                          rows={6}
                          maxLength={6000}
                          {...field}
                          disabled={inputDisabled}
                          errorMessage={
                            (
                              (
                                reactHookProps.formState.errors
                                  .commentaire_jury as any
                              )?.avis as any
                            )?.message
                          }
                        />
                      )}
                      control={reactHookProps.control}
                      name="commentaire_jury"
                    />
                  </Stack.Item>
                </FormSection>
                <Stack.Item>
                  <DynamicFieldForm
                    statut={StatutCandidature.DECISION_JURY}
                    reactHookProps={reactHookProps}
                    disabled={inputDisabled}
                  />
                </Stack.Item>
              </>
            )}
            <DocumentAddedDuringCandidature
              candidature={candidature}
              dynamicFields={dynamicFields}
            />
            <Stack
              horizontal
              horizontalAlign="space-between"
              style={{ marginTop: 20 }}
            >
              <Stack.Item>
                {!isOpenedInModal && <CancelButton text={"Retour"} />}
              </Stack.Item>

              {/* {isAdmin && //bouton switch vision pour l'admin entre president et membre
                <PrimaryButton text={isViewMembreAsAdmin ? "Vision président" : "Vision membre"} styles={commonButtonStyles.buttonSubmit} onClick={() => setIsViewMembreAsAdmin((prev) => !prev)} />
              } */}

              {canRightDecisionMembre && !inputDisabled && (
                <LoadButton
                  text={"Valider mon avis"}
                  isLoading={isSaving}
                  onClick={onSubmitForValidationAvisMembre}
                  type="button"
                />
              )}
              {(canRightDecisionPresident || isAdmin) &&
                !inputDisabled &&
                !isConsultCandidaturesList && (
                  <LoadButton
                    text={"Valider ma decision"}
                    isLoading={isSaving}
                    onClick={onSubmitPresident}
                    type="button"
                  />
                )}

              {(canRightDecisionPresident || isAdmin) &&
                !inputDisabled &&
                isConsultCandidaturesList && (
                  <LoadButton
                    text={"Valider et passer à la candidature suivante"}
                    isLoading={isSaving}
                    onClick={(e: any) => onSubmitPresident(e, true)}
                    type="button"
                  />
                )}
            </Stack>
          </form>
        )}
      </Stack>
    </DecisionJuryProvider>
  );
};

export default DecisionJury;
