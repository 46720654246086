import useTheme from "../useTheme";

const SectionTitle = ({ title, style = {} }: any) => {
  const { primaryColor } = useTheme();
  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "0.4rem" }}
    >
      <h2 style={{ color: primaryColor, ...style }}>{title}</h2>
    </div>
  );
};

export default SectionTitle;
