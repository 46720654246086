import { useQuery } from "react-query";
import { getOfRequest } from "../../services/ofServices";

const useOfQuery = (ofProps: any) => {
  const {
    data: of,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(["ofs", ofProps.ofUuid], () => getOfRequest(ofProps.ofUuid), {
    enabled: !!ofProps.ofUuid,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    of,
    isLoading,
    isRefetching,
    refetch,
  };
};

export default useOfQuery;
