import { Stack, Text } from "@fluentui/react";
import { RiAddFill } from "react-icons/ri";
import Can from "../../../Common/Can/Can";
import CancelButton from "../../../Common/CancelButton/CancelButton";
import DynamicFieldForm from "../../../Common/DynamicFieldForm/DynamicFieldForm";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import { branchAccessEnum, userAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";
import tools from "../../../utils/tools";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import useFetchCandidature from "../useFetchCandidature";
import GeneralInformations from "./FormSection/GeneralInformations";
import useSaisieInformations, {
  InformationsCandidatureContext,
} from "./useSaisieInformations";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import CandidatSection from "../Common/CandidatSection";
import CertificationSection from "../Common/CertificationSection";
import useMiseEnConfirmite from "../Common/MiseEnConformite/useMiseEnConfirmite";
import BoutonDemanderMEC from "../Common/MiseEnConformite/BoutonDemanderMEC";
import BoutonEnregistrerMEC from "../Common/MiseEnConformite/BoutonEnregistrerMEC";
import { useAtom } from "jotai";
import { isOpenedInModalAtom } from "../CandidatureWorkflow";
import CandidatureInfoHeaderWrapper from "../Common/CertificationInfos/CandidatureInfoHeaderWrapper";

function SaisieInformations() {
  const [isOpenedInModal] = useAtom(isOpenedInModalAtom);

  const queryClient = useQueryClient();
  const { labels } = useLabelSettings();
  const { actions: accessActions } = useAccessSettings();
  const {
    conditionnalDisplayVariables,
    reactHookProps,
    candidature,
    actions,
    isSaving,
  } = useSaisieInformations();
  const { isAdditionnalInput } = conditionnalDisplayVariables;
  const {
    isReferentielStillEditable,
    isThisPageStatusBeforeCandidatureStatus,
  } = useCandidatureWorkflow();

  const fetchCandidature = useFetchCandidature(reactHookProps);
  const { isEditMode, mecActions } = useMiseEnConfirmite();

  const onSubmit = (e: any) => {
    reactHookProps.clearErrors();
    if (isEditMode) {
      reactHookProps.handleSubmit(mecActions.onSaveMEC)(e);
    } else {
      reactHookProps.handleSubmit(actions.onSave)(e);
    }
  };

  const applicableEditPermission = isThisPageStatusBeforeCandidatureStatus(
    candidature.statut
  )
    ? isReferentielStillEditable(candidature.statut, [
        "identification_parties_prenantes",
      ])
      ? userAccessEnum.SAISIR_CANDIDATURE
      : userAccessEnum.NO_ACCESS_EVEN_ADMIN //si la candidature n'est plus modifiable même par un admin, on requiert la permission NO_ACCESS_EVEN_ADMIN qui bloquera tout le monde
    : userAccessEnum.SAISIR_CANDIDATURE;

  const inputsDisabled = !accessActions.canI({
    action: [applicableEditPermission],
  }).granted;

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: "certifications_candidature" });
    };
  }, []);

  const editableInputNames = mecActions.onGetEditableInputNames(
    "saisie_informations"
  );

  return !candidature?.uuid ? null : (
    <InformationsCandidatureContext.Provider
      value={{
        conditionnalDisplayVariables,
        reactHookProps,
        candidature,
      }}
    >
      <Stack style={{ marginTop: 0 }}>
        <CandidatureInfoHeaderWrapper>
          <CandidatSection candidat={candidature.candidat} />
          <CertificationSection
            certification={
              reactHookProps.watch("certification") ?? candidature.certification
            }
          />
        </CandidatureInfoHeaderWrapper>

        <Stack style={{ marginTop: 20 }}>
          <Text variant="medium">
            {labels.CANDIDATURE_CREE_MENTIONDATECREATION} :{" "}
            {tools.formatDate(candidature.cree_le)}{" "}
          </Text>
          {/* Modify date dynamic */}
        </Stack>

        {inputsDisabled && (
          <Can I={userAccessEnum.SAISIR_CANDIDATURE}>
            <BoutonDemanderMEC
              statut="saisie_informations"
              statut_candidature={candidature.statut}
              onAfterValidate={fetchCandidature.refrechCandidature}
              createurUuid={candidature.createur?.uuid}
            />
          </Can>
        )}
        <form onSubmit={onSubmit}>
          <GeneralInformations
            inputsDisabled={inputsDisabled}
            enabledInputs={editableInputNames}
          />

          <DynamicFieldForm
            statut={"saisie_informations"}
            reactHookProps={reactHookProps}
            disabled={inputsDisabled && !isEditMode}
          />

          <Stack
            horizontal
            horizontalAlign="space-between"
            style={{ marginTop: 20 }}
          >
            <Stack.Item>
              {!isOpenedInModal && <CancelButton text={"Retour"} />}
            </Stack.Item>
            {!inputsDisabled && (
              <Can I={[userAccessEnum.SAISIR_CANDIDATURE]}>
                <LoadButton
                  text={
                    accessActions.canI({
                      action: branchAccessEnum.INVITER_CANDIDAT_A_L_INSCRIPTION,
                    }).granted
                      ? labels.CANDIDATURE_BOUTONINVITER
                      : "Valider la saisie des informations"
                  }
                  isLoading={isSaving}
                  icon={<RiAddFill size={38} />}
                  style={{ fontWeight: "bold" }}
                />
              </Can>
            )}
            <BoutonEnregistrerMEC
              reactHookProps={reactHookProps}
              onAfterValidate={fetchCandidature.refrechCandidature}
            />
          </Stack>
        </form>
      </Stack>
    </InformationsCandidatureContext.Provider>
  );
}

export default SaisieInformations;
