import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  refuserDemandeInscriptionStructureRequest,
  validerDemandeInscriptionStructureEnMasseRequest,
  validerDemandeInscriptionStructureRequest,
} from "../../services/demandeInscriptionServices";

function useDemandeInscriptionStructureMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [isToggleDemandeInscriptionUuid, setIsToggleDemandeInscriptionUuid] =
    useState(null);

  const mutations = {
    ValiderDemandeInscription: useMutation(
      validerDemandeInscriptionStructureRequest,
      {
        onSuccess: (data) => {
          setIsToggleDemandeInscriptionUuid(null);
          toast.success(
            "L'inscription de la structure a été acceptée avec succès."
          );
          queryClient.invalidateQueries("demandeInscriptionStructure");
        },
        onError: (error: any) => {
          setIsToggleDemandeInscriptionUuid(null);
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors l'acceptation d'inscription de la structure : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    RefuserDemandeInscription: useMutation(
      refuserDemandeInscriptionStructureRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "L'inscription de la structure  a été refusée avec succès."
          );

          queryClient.invalidateQueries("demandeInscriptionStructure");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors du refus d'inscription de la structure : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    ValiderDemandeInscriptionEnMasse: useMutation(
      validerDemandeInscriptionStructureEnMasseRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "L'inscription en masse des structures a été acceptée avec succès."
          );
          queryClient.invalidateQueries("demandeInscriptionStructure");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de l'acceptation d'inscription en masse des structures : ${error?.response?.data?.message}`
              );
        },
      }
    ),
  };
  return {
    setIsToggleDemandeInscriptionUuid,
    isToggleDemandeInscriptionUuid,
    mutations,
  };
}

export default useDemandeInscriptionStructureMutations;
