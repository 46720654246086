import Page from "../../Common/Page/Page";
import { useLocation, useParams } from "react-router-dom";
import { API_URL } from "../../config/config";
import DownloadFileButton from "../../Common/DownloadFileButton/DownloadFileButton";
import queryString from "query-string";

const AsyncFileDownload = () => {
  const location = useLocation();
  const params = useParams();
  const parsed = queryString.parse(location.search);
  const { mediaUuid } = params;
  const { file_name, expires, signature, branchId } = parsed;
  const downloadUrl = `${API_URL}api/media/${mediaUuid}?branche=${branchId}&expires=${expires}&file_name=${file_name}&signature=${signature}`;

  if (!mediaUuid || !file_name || !expires || !signature || !branchId) {
    return <p>Ce lien de téléchargement semble invalide.</p>;
  }

  return (
    <Page
      title="Téléchargement de fichier asynchrone"
      explain="Cliquez sur le bouton ci-dessous pour télécharger le fichier."
    >
      <DownloadFileButton fileUrl={downloadUrl} fileName={file_name} />
    </Page>
  );
};

export default AsyncFileDownload;
