import { axiosInstance } from "../config/axiosConfig";
import { getCSRFCookieRequest } from "./authServices";

import tools from "../utils/tools";

const eCertifApi = axiosInstance;

const competenceURI = "administration/certifications/competences";

export const getCompetencesRequest = async (filters: any, options: any) => {
  const filterType = {
    libelle: { type: "string" },
    active: { type: "select", keyValue: "value", valueNotFilter: undefined },
    competence_cle: {
      type: "select",
      keyValue: "value",
      valueNotFilter: undefined,
    },
    branche_creatrice: { type: "select", keyValue: "id", valueNotFilter: 0 },
    blocs_rattaches: { type: "array", keyValue: "uuid" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${competenceURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const getCompetenceRequest = async (competenceUuid: any) => {
  const response = await eCertifApi.get(`${competenceURI}/${competenceUuid}`);
  return response.data;
};

export const postCompetenceRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${competenceURI}`, data, xsrfHeader);
  return response.data;
};

export const putCompetenceRequest = async (competence: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `${competenceURI}/${competence.uuid}`,
    competence,
    xsrfHeader
  );
  return response.data;
};

export const patchActivateCompetenceRequest = async (competenceUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${competenceURI}/${competenceUuid}/activer`,
    xsrfHeader
  );
  return response.data;
};

export const patchDesactivateCompetenceRequest = async (
  competenceUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.patch(
    `${competenceURI}/${competenceUuid}/desactiver`,
    xsrfHeader
  );
  return response.data;
};

export const deleteCompetenceRequest = async (competenceUuid: string) => {
  const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.delete(
    `${competenceURI}/${competenceUuid}`,
    xsrfHeader
  );
  return response;
};
