import { atom, useAtom } from "jotai";

const isWorkflowOpenAtom = atom(false);
const workflowContentAtom = atom({
  header: null,
  body: null,
  footer: null,
  isDissmiss: true,
  fullWidth: false,
});

function useWorkflowModal() {
  const [isOpen, setIsOpen] = useAtom(isWorkflowOpenAtom);
  const [content, setContent] = useAtom(workflowContentAtom);

  const openModal = (modalContent: any) => {
    document.body.classList.add("modal-open");
    setIsOpen((o) => true);
    setContent((o) => ({
      header: null,
      body: null,
      footer: null,
      isDissmiss: true,
      onPreviousDissmiss: () => {},
      onPreviousCloseModal: () => {},
      ...modalContent,
    }));
  };

  const reOpenModal = (modalContent: any) => {
    console.log("reOpenModal", modalContent);
    setContent((c) => ({
      ...c,
      body: null,
    }));

    setTimeout(() => {
      openModal(modalContent);
    }, 200);
  };

  const closeModal = () => {
    document.body.classList.remove("modal-open");
    setIsOpen(false);
  };

  return {
    isOpen,
    content,
    actions: {
      openModal,
      closeModal,
      reOpenModal,
    },
  };
}

export default useWorkflowModal;
