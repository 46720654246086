import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  patchRefuserDemandeHabilitationOfRequest,
  patchRefuserDemandeHabilitationUtilisateurRequest,
  postDemandeHabilitationOfFromPartiePrenanteRequest,
  postDemandeHabilitationOfRequest,
  postDemandeHabilitationUtilisateurRequest,
  postValiderDemandeHabilitationOfRequest,
  postValiderDemandeHabilitationUtilisateurRequest,
} from "../../services/demandeHabilitationService";

function useDemandeHabilitationMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const mutations = {
    PostDemandeHabilitationOf: useMutation(postDemandeHabilitationOfRequest, {
      onSuccess: (data) => {
        toast.success("La demande d'habilitation a été effectuée avec succès.");
        queryClient.invalidateQueries("demandeHabilitationsOf");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors de la demande d'habilitation : ${error?.response?.data?.message}`
            );
      },
    }),
    PostDemandeHabilitationUtilisateur: useMutation(
      postDemandeHabilitationUtilisateurRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "La demande d'habilitation a été effectuée avec succès."
          );
          queryClient.invalidateQueries("demandeHabilitationsUtilisateur");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PostDemandeHabilitationOfFromPartiePrenante: useMutation(
      postDemandeHabilitationOfFromPartiePrenanteRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "La demande d'habilitation a été effectuée avec succès."
          );
          queryClient.invalidateQueries("demandeHabilitationsOf");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PatchRefuserDemandeHabilitationUtilisateur: useMutation(
      patchRefuserDemandeHabilitationUtilisateurRequest,
      {
        onSuccess: (data) => {
          toast.success("La demande d'habilitation a été refusée avec succès.");
          queryClient.invalidateQueries("demandeHabilitationsUtilisateur");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors du refus de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PostValiderDemandeHabilitationUtilisateur: useMutation(
      postValiderDemandeHabilitationUtilisateurRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "La demande d'habilitation a été acceptée avec succès."
          );
          queryClient.invalidateQueries("demandeHabilitationsUtilisateur");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors l'acceptation de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PatchRefuserDemandeHabilitationOf: useMutation(
      patchRefuserDemandeHabilitationOfRequest,
      {
        onSuccess: (data) => {
          toast.success("La demande d'habilitation a été refusée avec succès.");
          queryClient.invalidateQueries("demandeHabilitationsOf");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors du refus de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
    PostValiderDemandeHabilitationOf: useMutation(
      postValiderDemandeHabilitationOfRequest,
      {
        onSuccess: (data) => {
          toast.success(
            "La demande d'habilitation a été acceptée avec succès."
          );
          queryClient.invalidateQueries("demandeHabilitationsOf");
        },
        onError: (error: any) => {
          !error.response.status || error.response.status >= 500
            ? navigate(`/erreur`)
            : toast.error(
                `Une erreur est survenue lors l'acceptation de la demande d'habilitation : ${error?.response?.data?.message}`
              );
        },
      }
    ),
  };

  return { mutations };
}

export default useDemandeHabilitationMutations;
