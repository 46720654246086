import {
  branchAccessEnum,
  documentsPermissionsLabels,
  userAccessEnum,
} from "../config/accessEnum";
import tools from "../utils/tools";
import useAccessSettings from "./branchSettings/useAccessSettings";
import useConstants from "./useConstants";

function useDocumentsWithMyPermission({ documents = [] }: any) {
  const { actions: accessActions } = useAccessSettings();
  const { constants } = useConstants();

  const actions = {
    getDocumentsWithMyPermission: ({ idDocument }: any) => {
      return documents
        .map((d: any) =>
          accessActions.canI({
            action: documentsPermissionsLabels[d[idDocument]]
              ? documentsPermissionsLabels[d[idDocument]]["permission"]
              : userAccessEnum.NO_ACCESS_EVEN_ADMIN,
          }).granted
            ? {
                id: d[idDocument],
                key: d[idDocument],
                nom: documentsPermissionsLabels[d[idDocument]]["nom"],
                file_url: d.file_url,
              }
            : null
        )
        .filter((d: any) => d);
    },
    getDocumentsJuryWithMyPermission: ({ jury }: any) => {
      const juryDocumentsInfos = [
        {
          id: jury.pv?.collection,
          documentPath: jury.pv?.file_url,
          documentPermission: userAccessEnum.TELECHARGER_PV_JURY,
          documentName: (
            tools.findIn(
              constants.JURY.DOCUMENTS_APRES_CLOTURE,
              jury.pv?.collection,
              "id"
            ) as any
          ).text,
        },
        {
          id: jury.courriers_candidat?.collection,
          documentPath: jury.courriers_candidat?.file_url,
          documentPermission:
            userAccessEnum.TELECHARGER_MODELE_LETTRES_CANDIDAT,
          documentName: (
            tools.findIn(
              constants.JURY.DOCUMENTS_APRES_CLOTURE,
              jury.courriers_candidat?.collection,
              "id"
            ) as any
          ).text,
          branchAccess: branchAccessEnum.CLOTURE_JURY_GENERER_COURRIER_CANDIDAT,
        },
        {
          id: jury.courriers_entreprise?.collection,
          documentPath: jury.courriers_entreprise?.file_url,
          documentPermission:
            userAccessEnum.TELECHARGER_MODELE_LETTRES_ENTREPRISES,
          documentName: (
            tools.findIn(
              constants.JURY.DOCUMENTS_APRES_CLOTURE,
              jury.courriers_entreprise?.collection,
              "id"
            ) as any
          ).text,
          branchAccess:
            branchAccessEnum.CLOTURE_JURY_GENERER_COURRIER_ENTREPRISE,
        },
        {
          id: jury.courriers_of?.collection,
          documentPath: jury.courriers_of?.file_url,
          documentPermission: userAccessEnum.TELECHARGER_MODELE_LETTRES_OF,
          documentName: (
            tools.findIn(
              constants.JURY.DOCUMENTS_APRES_CLOTURE,
              jury.courriers_of?.collection,
              "id"
            ) as any
          ).text,
          branchAccess: branchAccessEnum.CLOTURE_JURY_GENERER_COURRIER_OF,
        },
      ];

      const documentsJuryWithMyPermission = juryDocumentsInfos
        .filter((d: any) =>
          !d.branchAccess
            ? true
            : accessActions.canI({
                action: d.branchAccess,
              }).granted
        )
        .map((d: any) => {
          return accessActions.canI({
            action: d.documentPermission,
          }).granted
            ? {
                id: d.id,
                key: d.id,
                nom: d.documentName,
                file_url: d.documentPath,
              }
            : null;
        })
        .filter((d: any) => d);

      const documentsCanBeDownload = documentsJuryWithMyPermission.filter(
        (d: any) => d.file_url
      );

      return {
        isGenerationTermine:
          documentsJuryWithMyPermission.length == documentsCanBeDownload.length,
        documents: documentsCanBeDownload,
      };
    },
  };

  return { actions };
}

export default useDocumentsWithMyPermission;
