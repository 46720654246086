import { PrimaryButton, Text } from "@fluentui/react";
import useCustomModal from "../../../../Common/Overlays/CustomModal/useCustomModal";
import { commonButtonStyles } from "../../../../styles";

function RegenerateDocumentsModal({ jury, actions }: any) {
  const {
    actions: { closeModal },
  } = useCustomModal();

  const onClickRegenererButton = () => {
    actions.postRegenerateDocuments();
    closeModal();
  };

  return (
    <>
      <Text
        variant="medium"
        style={{ margin: "10px 0px", textAlign: "center" }}
      >
        Voulez-vous vraiment re-générer les documents du jury ? <br />{" "}
        <strong>{jury.libelle}</strong>
      </Text>
      <PrimaryButton
        text="Re-générer"
        onClick={onClickRegenererButton}
        style={{ width: "200px", margin: "10px 0px" }}
        styles={commonButtonStyles.deleteButton}
      />
    </>
  );
}

export default RegenerateDocumentsModal;
