export const scoreGranularite: any = [
  {
    text: "0.25",
    uuid: "1",
    key: "1",
  },
  {
    text: "0.5",
    uuid: "2",
    key: "2",
  },
  {
    text: "1",
    uuid: "3",
    key: "3",
  },
  {
    text: "2",
    uuid: "4",
    key: "4",
  },
  {
    text: "5",
    uuid: "5",
    key: "5",
  },
];
