import { TextField } from "@fluentui/react";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../styles";

function CleProfilInput({
  reactHookProps,
  label,
  placeholder,
  maxLengthCle,
}: any) {
  const { formState } = reactHookProps;
  return (
    <Controller
      render={({ field: { onChange, value, name } }) => (
        <TextField
          styles={commonInputStyles.textField}
          required
          label={label}
          type="text"
          value={value}
          placeholder={placeholder}
          errorMessage={(formState.errors[name] as any)?.message}
          onChange={(e: any, newValue: any) => {
            if (!newValue || newValue.length <= maxLengthCle) {
              onChange(e.target.value);
            }
          }}
        />
      )}
      defaultValue=""
      control={reactHookProps.control}
      name="cle_profil"
    />
  );
}

export default CleProfilInput;
