import { Stack, TextField } from "@fluentui/react";
import FormSection from "../../../../Common/FormSection/FormSection";
import { Controller } from "react-hook-form";
import { commonInputStyles } from "../../../../styles";
import DynamicFieldForm from "../../../../Common/DynamicFieldForm/DynamicFieldForm";
import { StatutCandidature } from "../../../../hooks/candidature/useCandidatureWorkflow";
import useLabelSettings from "../../../LabelSettingsDetail/useLabelSettings";
import { branchAccessEnum } from "../../../../config/accessEnum";

function ValiderSecondeEvaluationForm({ reactHookProps, accessActions }: any) {
  const { labels } = useLabelSettings();

  const doitValiderSecondeEvaluation =
    accessActions.canI({
      action: branchAccessEnum.ACTIVER_PRISE_CONNAISSANCE_EVALUATION,
    }).granted && reactHookProps.watch("second_evaluateur");

  return doitValiderSecondeEvaluation ? (
    <Stack>
      <FormSection sectionTitle={labels.AVIS_SECOND_EVALUATEUR_TITRE}>
        <Controller
          render={({ field }) => (
            <TextField
              styles={commonInputStyles.textField}
              label="Avis du second évaluateur (3000 caractères max) :"
              placeholder="Ex : commentaire"
              multiline
              required
              rows={6}
              maxLength={3000}
              {...field}
              disabled={true}
            />
          )}
          control={reactHookProps.control}
          name="avis"
        />
      </FormSection>

      <DynamicFieldForm
        statut={StatutCandidature.AVIS_SECOND_EVALUATEUR}
        reactHookProps={reactHookProps}
        disabled={true}
      />
    </Stack>
  ) : null;
}

export default ValiderSecondeEvaluationForm;
