import { toast } from "react-toastify";

export let IS_OFFLINE = false;

export const CHANGE_IS_OFFLINE = (val: boolean) => {
  IS_OFFLINE = val;
};

export enum OFFLINE_ROUTE {
  "csrf-cookie" = "csrf",
  "me" = "me",
  "me/mes-entites" = "mesEntites",
  "public/constantes" = "constantes",
  "public/lister-branches-actives" = "branchesActives",
  "public/pages-generales/DESCRIPTION" = "descriptions",
  "administration/parametrage/acces" = "paramAccess",
  "administration/parametrage/branche" = "paramBranch",
  "administration/champs-complementaires/lister" = "dynamicFields",
  "administration/libelles/lister" = "libelles",
}

export const getOfflineStorage = (route: keyof typeof OFFLINE_ROUTE) => {
  const res = localStorage.getItem(OFFLINE_ROUTE[route]);
  return res ? JSON.parse(res) : toast.error("Erreur de chargement des données");
};
