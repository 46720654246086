import { useAtom } from "jotai";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { branchAccessEnum } from "../../../config/accessEnum";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useConstants from "../../../hooks/useConstants";
import { checkCandidatExistsRequest } from "../../../services/candidatureService";
import tools from "../../../utils/tools";
import validations from "../../../utils/validations";

export const CandidatureContext = React.createContext({});
export const CandidatureProvider = ({ value, children }: any) => {
  return (
    <CandidatureContext.Provider value={value}>
      {children}
    </CandidatureContext.Provider>
  );
};

const resetImportProfil: any = {
  candidat: {
    nom_usage: "",
    civilite: 3,
    telephone_portable: "",
    telephone_professionnel: "",
    ne_etranger: false,
    code_postal_naissance: "",
    date_de_naissance: "",
    region_naissance: "",
    ville_naissance: "",
  },
};
const resetImport: any = {
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  region: "",
  ville: "",
};

function useCreationCandidature() {
  const { actions: accessActions } = useAccessSettings();
  const [isSaving, setIsSaving] = useState(false);
  const { candidature, actions: candidatureActions } = useCandidature();
  const [isChecked, setIsChecked] = useState(false);
  const [searchResult, setSearchResult] = useState<any>([]);
  const { constants } = useConstants();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const reactHookProps = useForm({
    mode: "onBlur",
    //resolver: joiResolver(candidatureSchema),
  }); //Penser à écrire le schéma lors du cablage avec l'api

  const typeContratThatNeedLibelle = [12];
  // Type de contrat 12 (Autre)
  const financementThatNeedLibelle = [15];
  // Dispositif de Financement 15 (Autre)

  const conditionnalDisplayVariables = {
    //a modifier -> invitation du candidat a remplir ca candidature
    isCandidateInvitation: accessActions.canI({
      action: branchAccessEnum.INVITER_CANDIDAT_A_L_INSCRIPTION,
    }).granted,
    //a modifier quand les roles seront mis en place -> si l'utilisateur dispose d'un statut qui l'autorise a ajouter une Entreprise au candidat
    isGroupCompanyUser: ["GROUPE_ENTREPRISE", "SOUS_GROUPE_ENTREPRISE"].some(
      (r: any) => userSelectedBranch.profils.includes(r)
    ),
    //a modifier quand les roles seront mis en place -> si l'utilisateur dispose d'un statut qui l'autorise a ajouter un OF au candidat
    isGroupOfUser: ["GROUPE_OF", "SOUS_GROUPE_OF"].some((r: any) =>
      userSelectedBranch.profils.includes(r)
    ),
    //si type de contrat (Autre) alors ajouter un input
    needExtraLibelleTypeContrat: typeContratThatNeedLibelle.includes(
      (reactHookProps.watch("type_contrat") as any)?.id
    ),
    //si dispositif de financement (Autre) alors ajouter un input
    needExtraLibelleFinancement: financementThatNeedLibelle.includes(
      (reactHookProps.watch("dispositif_financement") as any)?.id
    ),
  };

  const actions = {
    setUpdateIsCheck: (value: any) => {
      if (isChecked && !value) {
        const excludedFields = ["nom", "prenom", "email"];
        Object.keys(resetImportProfil.candidat).forEach((field: any) => {
          if (!excludedFields.includes(field)) {
            reactHookProps.setValue(
              `candidat.${field}`,
              resetImportProfil[field]
            );
          }
          reactHookProps.setValue("candidat.utilisateur_uuid", "");
          reactHookProps.setValue("candidat.uuid", "");
        });
        Object.keys(resetImport).forEach((field: any) => {
          if (!excludedFields.includes(field)) {
            reactHookProps.setValue(`${field}`, resetImport[field]);
          }
        });
      }
      setIsChecked(value);
    },
    onKeyDown: (e: any) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();
        reactHookProps.handleSubmit(actions.onVerify)(e);
      }
    },
    //permet de checker si un utilisateur existe avec les informations [nom, prenom, email]
    onVerify: async (data: any) => {
      const profil = {
        nom: data.candidat.nom,
        prenom: data.candidat.prenom,
        email: data.candidat.email,
      };
      const validationProfil = validations.validateProfil(profil);
      if (validationProfil?.error) {
        validationProfil?.error.details.map((err: any) => {
          reactHookProps.setError(`candidat.${err.context.key}`, {
            message: err.message,
          });
        });
      } else {
        setIsChecked(true);
        //a modifier ->  Ajouter un call axios pour set searchResult
        const candidatExists = await checkCandidatExistsRequest(
          data.candidat.email
        );
        setSearchResult(candidatExists);
      }
    },
    //permet de reprendre les informations lié a l'utilisateur selectionne
    onUseExistingProfile: (item: any) => {
      const newCandidat: any = {
        uuid: item.uuid || "",
        utilisateur_uuid: item.utilisateur_uuid || "",
        civilite: item.civilite
          ? tools.findIn(constants["CIVILITE"], item.civilite, "id")
          : { id: 3, text: "Non spécifié", key: 3 },
        nom_usage: item.nom_usage || "",
        telephone_portable: item.telephone_portable || "",
        telephone_professionnel: item.telephone_professionnel || "",
        date_de_naissance: item.date_de_naissance ? item.date_de_naissance : "",
        ne_etranger: item.ne_etranger,
        code_postal_naissance: item.code_postal_naissance || "",
        region_naissance: item.region_naissance || "",
        ville_naissance: item.ville_naissance || "",
        pays_naissance: item.pays_naissance || "",
      };
      Object.keys(newCandidat).forEach((inputKey: any) => {
        reactHookProps.setValue(`candidat.${inputKey}`, newCandidat[inputKey]);
      });
      reactHookProps.setValue("adresse", item.adresse?.adresse || "");
      reactHookProps.setValue(
        "complement_adresse",
        item.adresse?.complement_adresse || ""
      );
      reactHookProps.setValue("code_postal", item.adresse?.code_postal || "");
      reactHookProps.setValue("region", item.adresse?.region || "");
      reactHookProps.setValue("ville", item.adresse?.ville || "");
      setSearchResult([]);
    },
    onSave: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.postCandidature(data, reactHookProps);
      setIsSaving(false);
    },
  };

  return {
    isChecked,
    userSelectedBranch,
    searchResult,
    candidature,
    reactHookProps,
    conditionnalDisplayVariables,
    actions,
    isSaving,
  };
}

export default useCreationCandidature;
