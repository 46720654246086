import { DefaultSpacing, MessageBar } from "@fluentui/react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";

export const EntrepriseProfileForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
  userProfil,
}: SubFormsProps) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();

  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  let disabledUUIDs: any = [];

  if (userProfil?.referents?.length > 0) {
    const referentOfCurrentModel = userProfil.referents.filter(
      (r: any) =>
        r.pivot.model_type === "entreprise" &&
        r.sur_les_branches.find((b: any) => b.id === userSelectedBranch.id)
    );

    disabledUUIDs = referentOfCurrentModel.map((r: any) => r.uuid);
  }
  return (
    <ProfileAddionalInformations
      name="ENTREPRISE"
      sectionTitle="Informations du profil Entreprise"
      watchRoles={watchRoles}
    >
      <>
        {disabledUUIDs.length > 0 && (
          <MessageBar
            messageBarType={1}
            messageBarIconProps={{ iconName: "Warning" }}
          >
            Vous ne pouvez pas changer l'entreprise de ce profil car cet
            utilisateur est référent de cette entreprise sur cette branche.
          </MessageBar>
        )}
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectFieldAsync
              label="Sélectionnez une entreprise liée à cet utilisateur :"
              placeholder="Recherchez une entreprise par SIRET ou par nom (ex: 12312312311111 ou Entreprise 1)"
              resourceURI={`${API_URL}api/administration/entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?recherche=${terms}`
              }
              method="GET"
              renderItem={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              renderValue={(item: any) => {
                return (
                  <div>
                    <div>
                      {item.nom}{" "}
                      <span className="ms-fontColor-gray110">
                        - {item.siret}
                      </span>
                    </div>
                  </div>
                );
              }}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={null}
              errorMessage={
                ((errors.entrepriseProfile as any)?.entreprise as any)?.message
              }
              handleResponse={(response: any) => [...response.data.data]}
              required
              disabled={disabledUUIDs.length > 0}
            />
          )}
          control={control}
          name="entrepriseProfile.entreprise"
        />
      </>
    </ProfileAddionalInformations>
  );
};
