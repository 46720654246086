import { DefaultButton, Dropdown, Stack, TextField } from "@fluentui/react";
import { useAtom } from "jotai";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import TextResult from "../../../Common/TextResult/TextResult";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { API_URL } from "../../../config/config";
import { ETAPES_CANDIDATURE_CONFIG_FILTER } from "../../../hooks/candidature/useCandidatureWorkflow";
import { getCertificationsRequest } from "../../../services/certificationServices";
import { commonButtonStyles, commonInputStyles } from "../../../styles";
import useCandidatureAbandonneeList from "../useCandidatureAbandonneeList";

function CandidatureAbandonneeListFilters() {
  const [showFilters, setShowFilters] = useState(false);
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const {
    candidaturesAbandonnees,
    isLoadingCandidaturesAbandonnees,
    actions,
    reactHookProps,
  } = useCandidatureAbandonneeList();

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () => {
      return getCertificationsRequest(
        {},
        { sort: "libelle", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];
  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...certificationsFromApi,
  ];

  const { handleSubmit, control, setValue } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  const onWrapDefaultLabelRenderer = (
    props: any,
    defaultRender: any
  ): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <span>{defaultRender(props)}</span>
        <Tooltip
          id={"nom-tooltip2"}
          content={"Vous pouvez rechercher par nom d'usage ou nom de naissance"}
        />
      </Stack>
    );
  };

  const total = candidaturesAbandonnees?.meta.total || 0;

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Nom :"
                  styles={commonInputStyles.textField}
                  onRenderLabel={onWrapDefaultLabelRenderer}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Prénom :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Statuts de la candidature :"
                  selectedKeys={value ? value.map((v: any) => v.key) : []}
                  placeholder="Sélectionnez un ou plusieurs statuts"
                  options={ETAPES_CANDIDATURE_CONFIG_FILTER}
                  onChange={(e: any, options: any) =>
                    actions.changeStatuts(value, options)
                  }
                  styles={commonInputStyles.dropdownStyle}
                  multiSelect
                />
              )}
              control={control}
              name="statuts"
            />
          </Stack.Item>

          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Entreprises liées :"
                      placeholder="Recherchez une entreprise (ex : entreprise)"
                      resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?nom=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.nom}
                      renderValue={(item: any) => item.nom}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="entreprises"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Organismes de formation liés :"
                      placeholder="Recherchez un OF (ex : OF)"
                      resourceURI={`${API_URL}api/administration/ofs/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?raison_sociale=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.raison_sociale}
                      renderValue={(item: any) => item.raison_sociale}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="ofs"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        selectedKey={value ? value.key : undefined}
                        label="Certification :"
                        placeholder="Sélectionnez une certification"
                        options={certificationList}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={certificationList[0]}
                  control={control}
                  name="certifications"
                />
              </Stack.Item>
            </>
          )}
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton
                isLoading={isLoadingCandidaturesAbandonnees}
                text="Rechercher"
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={total} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default CandidatureAbandonneeListFilters;
