import { DefaultButton, MessageBar, Stack } from "@fluentui/react";
import useTheme from "../../../../Common/useTheme";

import FormSection from "../../../../Common/FormSection/FormSection";
import { MessageBarStyles, commonButtonStyles } from "../../../../styles";
import FormationList from "../FormationList/FormationList";
import { useContext } from "react";
import { ParcoursCandidatContext } from "../useParcoursCandidat";
import Can from "../../../../Common/Can/Can";

function FormationContinue({
  applicableEditPermission,
  isEditMode = false,
}: any) {
  const { actions, reactHookProps, inputsDisabled }: any = useContext(
    ParcoursCandidatContext
  );
  const { watch } = reactHookProps;
  const { primaryColor } = useTheme();

  return (
    <FormSection sectionTitle="Formation continue">
      <Can I={[applicableEditPermission]}>
        <Stack.Item>
          <DefaultButton
            onClick={() => actions.openAddFormation(null, false)}
            styles={commonButtonStyles.defaultButton}
            style={{ color: primaryColor, borderColor: primaryColor }}
          >
            Ajouter une formation continue
          </DefaultButton>
        </Stack.Item>
      </Can>
      {watch("formations_continues")?.length > 0 ? (
        <FormationList
          formations={watch("formations_continues") || []}
          isInitial={false}
          openFormation={actions.openAddFormation}
        />
      ) : (
        <MessageBar styles={MessageBarStyles}>Aucune entrée</MessageBar>
      )}
    </FormSection>
  );
}

export default FormationContinue;
