import Badge from "../../../Common/Badge/Badge";

function DemandeInscriptionStructureBadge({ statut }: { statut: string }) {
  const statutInfos: any = {
    REFUSEE: {
      text: "Refusée",
      color: "red",
    },
    VALIDEE: {
      text: "Validée",
      color: "green",
    },
    EN_ATTENTE: {
      text: "En attente",
      color: "orange",
    },
  };

  if (!statut) {
    return null;
  }

  return (
    <Badge
      text={statutInfos[statut].text}
      color={statutInfos[statut].color}
      textStyle={{ color: "#ffffff" }}
    />
  );
}

export default DemandeInscriptionStructureBadge;
