import { useAtom } from "jotai"
import { toast } from "react-toastify";
import groupOfAdapters from "../../adapters/groupOfAdapters";
import { userSelectedBranchAtom } from "../../atoms/branchAtom"
import useGroupOfMutations from "./useGroupOfMutations";

function useGroupOf() {
    const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
    const { groupOfUuid, setGroupOfUuid, mutations, isToggleActivationUuid, setIsToggleActivationUuid } = useGroupOfMutations()

    const actions = {
        setUpdateGroupOfUuid: (data: any) => {
            setGroupOfUuid(data)
          },
          postGroupOf: async (groupOf: any) => {
            mutations.PostGroupOf.mutate(groupOfAdapters.transformForAPI(groupOf));
          },
          putGroupOf: async (groupOf: any) => {
            mutations.PutGroupOf.mutate(groupOfAdapters.transformForAPI(groupOf));
          },
          deleteGroupOf: async (groupOfUuid: any) => {
            toast.info('En cours de développement')
            //mutations.DeleteGroupOf.mutate(groupOfUuid)
          },
          toggleGroupOfActivation: (groupOf: any) => {
            setIsToggleActivationUuid(groupOf.uuid)
            if(groupOf.actif){
              mutations.ToggleGroupOfDesactivate.mutate(groupOf.uuid)
            }else{
              mutations.ToggleGroupOfActivate.mutate(groupOf.uuid)
            }
          } 
    }

    const {
        isLoading: isLoadingPostGroupOf,
        isError: isErrorPostGroupOf,
        error: errorPostGroupOf,
        isSuccess: isSuccessPostGroupOf,
      } = mutations.PostGroupOf
    
      const {
        isLoading: isLoadingPutGroupOf,
        isError: isErrorPutGroupOf,
        error: errorPutGroupOf,
        isSuccess: isSuccessPutGroupOf,
      } = mutations.PutGroupOf

    return {
        userSelectedBranch,
        groupOfUuid,
        isLoading: isLoadingPostGroupOf || isLoadingPutGroupOf,
        hasError: isErrorPostGroupOf || isErrorPutGroupOf,
        isToggleActivationUuid,
        errorPostGroupOf,
        isSuccessPostGroupOf,
        errorPutGroupOf,
        isSuccessPutGroupOf,
        actions
    }
}

export default useGroupOf