import { Text, PrimaryButton } from "@fluentui/react"
import { commonButtonStyles } from "../../../styles/index"
import useAuth from "../../../hooks/useAuth"

function ExpiredCookieConfirmDialog() {

    const { actions, modalActions } = useAuth()

    const onClick = () => {
        actions.logout()
        modalActions.closeModal()
    }

    return (
        <>
            <Text variant="medium" style={{ margin: "10px 0px", textAlign: "center" }}>
                Veuillez-vous reconnecter pour continuer.
            </Text>

            <PrimaryButton styles={commonButtonStyles.buttonSubmit} style={{ width: 200, margin: "10px 0px" }} onClick={onClick}>
                Me reconnecter
            </PrimaryButton>

        </>
    )
}

export default ExpiredCookieConfirmDialog