import { DefaultButton, Link, MessageBar, PrimaryButton, Stack, Text, TextField } from '@fluentui/react'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import formSectionStyles from '../../../../Common/FormSection/FormSection.styles'
import useCustomModal from '../../../../Common/Overlays/CustomModal/useCustomModal'
import useTheme from '../../../../Common/useTheme'
//import { verifyUserEmail } from '../../../../services/userServices'
import { commonButtonStyles, commonInputStyles, MessageBarStyles } from '../../../../styles'
import validations from '../../../../utils/validations'

function AddUserFormModal({
    role,
    onAddUser = () => { },
}: any) {
    //const { primaryColor } = useTheme()
    const { actions } = useCustomModal();

    // const [searchResult, setSearchResult]: any = useState([]);
    // const [existenceVerified, setExistenceVerified] = useState(false);
    const [errors, setErrors]: any = useState({});
    const [newUser, setNewUser] = useState({
        nom: "",
        prenom: "",
        email: "",
    });

    // const getBlocsInfoMessage = () => {
    //     if (searchResult.length > 0) {
    //         return "Un utilisateur possédant cet email existe déjà en base de données :";
    //     } else {
    //         return "Aucun utilisateur avec cet email n'a été trouvé en base de données. Vous pouvez en créer un en renseignant les champs ci-dessus.";
    //     }
    // };

    const handleChangeNewUser = (name: any, value: any) => {
        // if (name == "email") {
        //     setExistenceVerified(false);
        // }
        setErrors({ ...errors, [name]: "" });
        setNewUser({ ...newUser, [name]: value });
    };

    // const onVerifyEmail = async (role: any) => {
    //     setErrors({});
    //     try {
    //         const data: any = await verifyUserEmail(newUser.email, role.toUpperCase());
    //         setSearchResult(data.data?.length > 0 ? data.data : []);
    //         setExistenceVerified(true);
    //     } catch (err: any) {
    //         if (err?.response?.data?.message) {
    //             setErrors({ siret: err.response.data.message });
    //         }
    //     }
    // };

    const validateUser = (user: any) => {
        let validationErrors:any = {};
        const validationProfil = validations.validateProfil(user);
        if(validationProfil?.error){
            validationProfil.error.details.map((err:any)=>{
                validationErrors[err.context.key]=err.message
            })
        }
        setErrors(validationErrors);
        let isValid = Object.keys(validationErrors).length === 0;
        if (!isValid) {
            toast.error("Le formulaire comporte des erreurs.");
        }
        return isValid;
    };

    const onSaveUser = (user?: any) => {
        if (validateUser(user ?? newUser)) {
            onAddUser(user || newUser);
            actions.closeModal();
        }
    };

    return (
        <Stack
            style={{ ...formSectionStyles.formContainer, minWidth: 400, paddingBottom: 12 }}
            tokens={{ childrenGap: 15 }}
        >
            <Text style={{ color: "#979797" }}>
                Sélectionnez ou ajoutez un {role} afin de pouvoir y accéder dans les parties prenantes de la
                candidature.
            </Text>

            <Stack.Item>
                <TextField
                    styles={commonInputStyles.textField}
                    type="text"
                    label="Nom :"
                    placeholder="Ex: Dupont"
                    required
                    name="nom"
                    onChange={(e: any) => handleChangeNewUser("nom", e.target.value)}
                    value={newUser.nom}
                    errorMessage={errors.nom}
                />
            </Stack.Item>

            <Stack.Item>
                <TextField
                    styles={commonInputStyles.textField}
                    type="text"
                    label="Prénom :"
                    placeholder="Ex: Pierre"
                    required
                    name="prenom"
                    onChange={(e: any) => handleChangeNewUser("prenom", e.target.value)}
                    value={newUser.prenom}
                    errorMessage={errors.prenom}
                />
            </Stack.Item>

            <Stack.Item>
                <TextField
                    styles={commonInputStyles.textField}
                    type="email"
                    label="Email :"
                    placeholder="Ex: pierre.dupont@exemple.com"
                    required
                    name="email"
                    onChange={(e: any) => handleChangeNewUser("email", e.target.value)}
                    value={newUser.email}
                    errorMessage={errors.email}
                />
            </Stack.Item>

            {/* <Stack.Item>
                <DefaultButton
                    onClick={() => onVerifyEmail(role)}
                    styles={commonButtonStyles.defaultButton}
                    style={{ color: primaryColor, borderColor: primaryColor }}
                >
                    Vérifier l'existence de l'utilisateur
                </DefaultButton>
            </Stack.Item> */}

            {/* <Stack.Item style={{ backgroundColor: "#F2F2F2" }}>
                {existenceVerified && searchResult.length === 0 && (
                    <MessageBar styles={MessageBarStyles}>{getBlocsInfoMessage()}</MessageBar>
                )}
                {searchResult.length > 0 && (
                    <>
                        {searchResult.map((item: any, index: any) => (
                            <Stack
                                horizontal
                                verticalAlign="center"
                                style={{ marginLeft: 30, marginBottom: 10 }}
                                key={`profil${index}`}
                            >
                                <p
                                    style={{
                                        color: "grey",
                                        fontStyle: "italic",
                                        margin: "5px 0px 0px",
                                        fontSize: 16,
                                    }}
                                >
                                    {item.name} - {item.email}
                                </p>
                                <Link
                                    onClick={() => onSaveUser(item)}
                                    styles={commonButtonStyles.buttonLink}
                                    style={{ marginLeft: 20, marginTop: 5 }}
                                >
                                    Cliquez ici pour utiliser cet utilisateur existant
                                </Link>
                            </Stack>
                        ))}
                    </>
                )}
            </Stack.Item> */}

            <Stack.Item style={{ display: "flex", justifyContent: "space-between" }}>
                <DefaultButton styles={commonButtonStyles.defaultButton} onClick={actions.closeModal}>
                    Annuler
                </DefaultButton>

                <PrimaryButton
                    onClick={() => onSaveUser()}
                    styles={commonButtonStyles.buttonSubmit}
                    iconProps={{ iconName: "Add" }}
                >
                    Ajouter
                </PrimaryButton>
            </Stack.Item>
        </Stack>
    )
}

export default AddUserFormModal