import { useAtom } from "jotai";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useCandidature from "../../../hooks/candidature/useCandidature";
import useCandidatureWorkflow from "../../../hooks/candidature/useCandidatureWorkflow";

import { branchRolesSettingsAtom } from "../../RolesManagement/useRolesManagement";

export const InformationsCandidatureContext = React.createContext({});
export const InformationsCandidatureProvider = ({ value, children }: any) => {
  return (
    <InformationsCandidatureContext.Provider value={value}>
      {children}
    </InformationsCandidatureContext.Provider>
  );
};

function useSaisieInformations() {
  const [isSaving, setIsSaving] = useState(false);
  const [branchRolesSettings]: any = useAtom(branchRolesSettingsAtom);
  const { candidature, actions: candidatureActions } = useCandidature();
  const { STATUT_PAGE } = useCandidatureWorkflow();
  const reactHookProps: any = useForm({
    mode: "onBlur",
    //resolver:joiResolver ...
  });

  const typeContratThatNeedLibelle = [12];
  // Type de contrat 12 (Autre)
  const financementThatNeedLibelle = [15];
  // Dispositif de Financement 15 (Autre)

  const conditionnalDisplayVariables = {
    //si type de contrat (Autre) alors ajouter un input
    needExtraLibelleTypeContrat: typeContratThatNeedLibelle.includes(
      (reactHookProps.watch("type_contrat") as any)?.id
    ),
    //si dispositif de financement (Autre) alors ajouter un input
    needExtraLibelleFinancement: financementThatNeedLibelle.includes(
      (reactHookProps.watch("dispositif_financement") as any)?.id
    ),
    //a modifier -> US 198 | il y a t'il des champs supplementaire (true ou false)
    isAdditionnalInput: false,
    //a modifier | faut il obligatoirement mettre un fichier d'attestation de notre dernier diplome (true ou false)
    isFileRequired: false,
    //a modifier | est ce que le positionnement est obligatoire (true ou false)
    isPositionnementMandatory: false,
    positionneurExist: branchRolesSettings.find(
      (r: any) =>
        r.acces == "SAISIR_POSITIONNEMENT" &&
        r.est_accessible &&
        r.est_parametrable
    ),
  };

  const actions = {
    onSave: async (data: any) => {
      setIsSaving(true);
      await candidatureActions.putCandidatureFormData(
        data,
        STATUT_PAGE,
        reactHookProps
      );
      setIsSaving(false);
    },
  };

  return {
    isSaving,
    conditionnalDisplayVariables,
    reactHookProps,
    candidature,
    actions,
  };
}

export default useSaisieInformations;
