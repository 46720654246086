import FormAdress from "../FormAdress/FormAdress";
import FormSection from "../FormSection/FormSection";

function ResidenceInformationFormSection({
  control,
  errors = {},
  varToWatch,
  required = false,
  disabled,
  withContainer = true,
  onPrefillCityAndRegion = () => {},
}: any) {
  const content = (
    <FormAdress
      control={control}
      errors={errors}
      varToWatch={varToWatch}
      required={required}
      disabled={disabled}
      onPrefillCityAndRegion={onPrefillCityAndRegion}
    />
  );
  return withContainer ? (
    <FormSection sectionTitle="Domicile du candidat">{content}</FormSection>
  ) : (
    content
  );
}

export default ResidenceInformationFormSection;
