import { Stack, Text } from "@fluentui/react";
import { Link as RouterLink } from "react-router-dom";
import headerStyles from "./Header.styles";

function HeaderPublic() {
  return (
    <Stack styles={headerStyles.outerPublicStyle} horizontalAlign="center" verticalAlign="center">
      <RouterLink to="/" style={{ textDecoration: "none" }}>
        <Text variant="xxLarge">Certifi@</Text>
      </RouterLink>
    </Stack>
  );
}

export default HeaderPublic;
