import { ChoiceGroup, IChoiceGroupOption, Stack } from "@fluentui/react";
import { FormEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { API_URL } from "../../../config/config";
import ProfileAddionalInformations from "./ProfileAddionalInformations";
import { SubFormsProps } from "./SubFormProps";

export const ConsultationOfRoleForm: React.FunctionComponent<SubFormsProps> = ({
  watchRoles,
}: SubFormsProps) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    reset,
    setValue,
    watch,
    register,
  } = useFormContext();
  const consultationOfRadioOptions: IChoiceGroupOption[] = [
    { key: "groupe_of", text: "Groupe d'OF" },
    { key: "of", text: "OF" },
  ];
  return (
    <ProfileAddionalInformations
      name="CONSULTATION_OF"
      sectionTitle="Informations du profil Consultation OF"
      watchRoles={watchRoles}
    >
      <Stack>
        <Controller
          defaultValue={null}
          name='consultationOfProfile.ofType'
          control={control}
          render={({ field: { name, value } }) => (
            <ChoiceGroup
              selectedKey={value ?? null}
              options={consultationOfRadioOptions}
              label="L'utilisateur consultation est il rattaché à un groupe d'OF ou à un OF ?"
              required={true}
              name={name}
              onChange={(
                ev?: FormEvent<HTMLInputElement | HTMLElement>,
                option?: IChoiceGroupOption
              ) => { 
                option?.key == 'groupe_of' 
                  ? setValue("consultationOfProfile.ofs", [])
                  : setValue("consultationOfProfile.groupOf", null)
                setValue("consultationOfProfile.ofType", option?.key)
              }}
            />
          )}
        />
        {watch("consultationOfProfile.ofType") == "groupe_of" && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectFieldAsync
                label="Sélectionnez un groupe d'OF lié à cet utilisateur :"
                placeholder="Recherchez un groupe d'OF par SIRET ou Raison sociale (ex : 12312312312312 ou Groupe 1)"
                resourceURI={`${API_URL}api/administration/groupe-ofs/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110 ">- {item.siren}</span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110 ">- {item.siren}</span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={null}
                errorMessage={((errors.consultationOfProfile as any)?.groupOf as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                required
              />
            )}
            control={control}
            name="consultationOfProfile.groupOf"
          />
        )}

        {watch("consultationOfProfile.ofType") == "of" && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectManyFieldAsync
                label="Sélectionnez un ou plusieurs OF liés à cet utilisateur :"
                placeholder="Recherchez des OF par SIRET ou raison sociale (ex : 123123123123123 ou OF1)"
                resourceURI={`${API_URL}api/administration/ofs/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?recherche=${terms}`
                }
                method="GET"
                renderItem={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                      </div>
                    </div>
                  );
                }}
                renderValue={(item: any) => {
                  return (
                    <div>
                      <div>
                        {item.raison_sociale}{" "}
                        <span className="ms-fontColor-gray110 ">- {item.siret}</span>
                      </div>
                    </div>
                  );
                }}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={[]}
                errorMessage={((errors.consultationOfProfile as any)?.groupOf as any)?.message}
                handleResponse={(response: any) => [...response.data.data]}
                required
              />
            )}
            control={control}
            name="consultationOfProfile.ofs"
          />
        )}
      </Stack>
    </ProfileAddionalInformations>
  );
};
