const subGroupOfAdapters: any = {
    transformForAPI: (data: any) => {
        const transformed: any = {
            ...data
        }
        return transformed
    },
    transformForBO: (data: any) => {
        const transformed: any = {
            ...data,
            groupeOf:{
                raison_sociale: data.attributes?.groupe?.raison_sociale,
                siren: data.attributes?.groupe?.siren,
                uuid: data.attributes?.groupe?.uuid,
            },
            ofs: data.attributes?.entites?.map((entreprise: any) => {
                return ({
                    raison_sociale:entreprise.raison_sociale,
                    siret:entreprise.siret,
                })
            })
        }
        return transformed
    }
}

export default subGroupOfAdapters