import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  refuserInscriptionRequest,
  validerInscriptionEnMasseRequest,
  validerInscriptionRequest,
} from "../../services/demandeInscriptionServices";
import { useState } from "react";

function useDemandeInscriptionMutations() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();

  const [isToggleInscriptionUuid, setIsToggleInscriptionUuid] = useState(null);

  const mutations = {
    ValiderInscription: useMutation(validerInscriptionRequest, {
      onSuccess: (data) => {
        setIsToggleInscriptionUuid(null);
        toast.success("L'inscription a été acceptée avec succès.");
        queryClient.invalidateQueries("usersInscription");
      },
      onError: (error: any) => {
        setIsToggleInscriptionUuid(null);
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors l'acceptation d'inscription : ${error?.response?.data?.message}`
            );
      },
    }),
    ValiderInscriptionEnMasse: useMutation(validerInscriptionEnMasseRequest, {
      onSuccess: (data) => {
        toast.success("L'inscription en masse a été acceptée avec succès.");
        queryClient.invalidateQueries("usersInscription");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors l'acceptation d'inscription en masse : ${error?.response?.data?.message}`
            );
      },
    }),
    RefuserInscription: useMutation(refuserInscriptionRequest, {
      onSuccess: (data) => {
        toast.success("L'inscription a été refusée avec succès.");

        queryClient.invalidateQueries("usersInscription");
      },
      onError: (error: any) => {
        !error.response.status || error.response.status >= 500
          ? navigate(`/erreur`)
          : toast.error(
              `Une erreur est survenue lors du refus d'inscription : ${error?.response?.data?.message}`
            );
      },
    }),
  };

  return {
    mutations,
    isToggleInscriptionUuid,
    setIsToggleInscriptionUuid,
  };
}

export default useDemandeInscriptionMutations;
