import tools from "../utils/tools";

const branchSettingsAdapters = {
  transformForAPI: (data: any, settings: any) => {
    let transformed: any = {
      parametres_branche: Object.keys(data).map((setting_id: any) => {
        const settingRef: any = tools.findIn(settings, setting_id, "parametre");
        return {
          id: settingRef.id,
          parametre: setting_id,
          valeur: data[setting_id],
        };
      }),
    };
    // {
    //   id: 4,
    //   param_id: 1,
    //   valeur: true,
    //   est_obligatoire: null,
    // },
    // {
    //   id: 5,
    //   param_id: 2,
    //   valeur: false,
    //   est_obligatoire: null,
    // },
    // {
    //   id: 6,
    //   param_id: 3,
    //   valeur: true,
    //   est_obligatoire: null,
    // },

    return transformed;
  },
  transformForBO: (data: any) => {
    return data;
  },
};

export default branchSettingsAdapters;
