import { Stack } from "@fluentui/react";
import { useContext } from "react";
import { Controller } from "react-hook-form";
import FormSection from "../../../Common/FormSection/FormSection";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import { branchAccessEnum } from "../../../config/accessEnum";
import { API_URL } from "../../../config/config";
import useAccessSettings from "../../../hooks/branchSettings/useAccessSettings";
import { OfContext } from "../useOfDetail";

function EntitiesLinkedSection() {
  const { reactHookProps, errors, userSelectedBranch }: any =
    useContext(OfContext);
  const { actions: accesActions } = useAccessSettings();
  const { setValue, control } = reactHookProps;

  const isHabilitationDisabled = !accesActions.canI({
    action: branchAccessEnum.HABILITATION_SIMPLE_OF,
  }).granted;

  return (
    <FormSection sectionTitle="Entités liées à l’OF">
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Entreprises liées à l’OF :"
              placeholder="Recherchez une entreprise en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/administration/entreprises/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom=${terms}&paginate=false`
              }
              method="GET"
              renderItem={(item: any) => item.nom + " - " + item.siret}
              renderValue={(item: any) => item.nom + " - " + item.siret}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              required={
                accesActions.canI({
                  action: branchAccessEnum.OBLIGER_LIAISON_OF_ET_ENTREPRISE,
                }).granted
              }
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="entreprises"
        />
      </Stack.Item>

      {/*UPDATE RESSOURCE URI */}
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Évaluateurs :"
              placeholder="Recherchez un évaluateur en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom=${terms}&roles[]=EVALUATEUR`
              }
              method="GET"
              renderItem={(item: any) => item.nom + " " + item.prenom}
              renderValue={(item: any) => item.nom + " " + item.prenom}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="evaluateurs"
        />
      </Stack.Item>

      {/*UPDATE RESSOURCE URI */}
      <Stack.Item>
        <Controller
          render={({ field: { name, value, onBlur, ref } }) => (
            <SelectManyFieldAsync
              label="Positionneurs :"
              placeholder="Recherchez un positionneur en tapant les premières lettres de son nom"
              resourceURI={`${API_URL}api/utilisateur/utilisateurs/chercher`}
              addQueryURL={(resourceURI: any, terms: any) =>
                `${resourceURI}?nom=${terms}&roles[]=POSITIONNEUR`
              }
              method="GET"
              renderItem={(item: any) => item.nom + " " + item.prenom}
              renderValue={(item: any) => item.nom + " " + item.prenom}
              dataIndex="uuid"
              name={name}
              value={value}
              onBlur={onBlur}
              fieldRef={ref}
              onChange={(e: any) => {
                setValue(name, e.target.value, {
                  shouldValidate: true,
                });
              }}
              defaultValue={{}}
              errorMessage={(errors.ref_idccs as any)?.message}
              handleResponse={(response: any) => [...response.data.data]}
            />
          )}
          control={control}
          name="positionneurs"
        />

        {isHabilitationDisabled && (
          <Controller
            render={({ field: { name, value, onBlur, ref } }) => (
              <SelectManyFieldAsync
                label="Certifications :"
                placeholder="Recherchez une certification en tapant les premières lettres de son nom"
                resourceURI={`${API_URL}api/administration/certifications/certifications/chercher`}
                addQueryURL={(resourceURI: any, terms: any) =>
                  `${resourceURI}?libelle=${terms}&paginate=false&branche_creatrice_id=${userSelectedBranch.id}`
                }
                method="GET"
                renderItem={(item: any) => item.libelle}
                renderValue={(item: any) => item.libelle}
                dataIndex="uuid"
                name={name}
                value={value}
                onBlur={onBlur}
                fieldRef={ref}
                onChange={(e: any) => {
                  setValue(name, e.target.value, { shouldValidate: true });
                }}
                defaultValue={{}}
                handleResponse={(response: any) => [...response.data.data]}
              />
            )}
            control={control}
            name="certifications"
          />
        )}
      </Stack.Item>
    </FormSection>
  );
}

export default EntitiesLinkedSection;
