import { useAtom } from "jotai";
import { atomWithReset } from "jotai/utils";
import { useForm } from "react-hook-form";
import tools from "../../utils/tools";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getCandidaturesAbandonneesRequest } from "../../services/candidatureService";

export const initialFilters = {
  nom: "",
  prenom: "",
  entreprises: [],
  ofs: [],
  statuts: [],
  certifications: { id: undefined, text: "Toutes", key: "all" },
};

export const filtersAtom = atomWithReset<any>(initialFilters);

export const optionsFiltersAtom = atomWithReset({
  sort: "",
  page: 1,
  paginate: true,
  searchAt: "",
});
function useCandidatureAbandonneeList() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);

  const [filters, setFilters] = useAtom(filtersAtom);
  const [optionsFilters, setOptionsFilters] = useAtom(optionsFiltersAtom);

  const reactHookProps = useForm<any>({
    mode: "onBlur",
    defaultValues: filters,
  });

  const {
    data: candidaturesAbandonnees,
    isLoading: isLoadingCandidaturesAbandonnees,
  } = useQuery(
    ["candidaturesAbandonnees", optionsFilters, userSelectedBranch],
    () =>
      getCandidaturesAbandonneesRequest(
        tools.addUnderStageToFilter(filters),
        optionsFilters
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onError: (err: any) => {
        toast.error(err.message);
      },
    }
  );

  const actions = {
    resetFilters: () => {
      reactHookProps.reset({
        ...initialFilters,
      });
    },
    onChangeOptionFilters: (newOptionsFilters: any) => {
      setOptionsFilters(newOptionsFilters);
    },
    changePage: (page: number) => {
      actions.onChangeOptionFilters({ ...optionsFilters, page });
    },
    changeFilters: (newFilters: any) => {
      setFilters(newFilters);
      actions.onChangeOptionFilters({
        ...optionsFilters,
        page: 1,
        searchAt: new Date().toISOString(),
      });
    },
    changeStatuts: (actualValue: any, newValue: any) => {
      if (actualValue) {
        if (actualValue.find((v: any) => v.key == newValue.key)) {
          reactHookProps.setValue(
            "statuts",
            actualValue.filter((v: any) => v.key !== newValue.key)
          );
        } else {
          if (newValue.canBeAddedWithOtherStatuts) {
            reactHookProps.setValue(
              "statuts",
              [...actualValue, newValue].filter(
                (opt: any) => opt.canBeAddedWithOtherStatuts
              )
            );
          } else {
            reactHookProps.setValue("statuts", [newValue]);
          }
        }
      } else {
        reactHookProps.setValue("statuts", [newValue]);
      }
    },
    sortColumn: (name: string) => {
      const sortArray = optionsFilters.sort.split(",");
      const sortJoin = tools.sortColumnRefactoring(sortArray, name);

      actions.onChangeOptionFilters({
        ...optionsFilters,
        sort: sortJoin,
      });
    },
  };
  return {
    actions,
    optionsFilters,
    candidaturesAbandonnees,
    isLoadingCandidaturesAbandonnees,
    reactHookProps,
  };
}

export default useCandidatureAbandonneeList;
