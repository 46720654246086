import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { userSelectedBranchAtom } from "../atoms/branchAtom";

function useAddQueryParamsOnRefresh() {
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userSelectedBranch?.id) {
      // Get the current query parameters from the URL
      const searchParams = new URLSearchParams(location.search);

      // Add or update the branchId parameter
      searchParams.set("branchId", userSelectedBranch.id);

      // Update the URL with the new query parameters
      navigate(location.pathname + "?" + searchParams.toString(), {
        replace: true,
      });
    } else {
      // Get the current query parameters from the URL
      const searchParams = new URLSearchParams(location.search);

      // Add or update the branchId parameter
      searchParams.set("branchId", "");
      navigate(location.pathname + "?" + searchParams.toString(), {
        replace: true,
      });
    }
  }, [userSelectedBranch, location.search, navigate]);
}

export default useAddQueryParamsOnRefresh;
